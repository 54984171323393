import { cmxTypeguards, commonActions, logger } from '@codametrix/ui-common';
import { Action as FSAction } from 'typescript-fsa';
import { Action as ReduxStandardAction } from 'redux';
import { IS_PRODUCTION } from '../environment';
import { setIsActive } from '../net';
import store from '../../store/store';

const activityEvents = new Set<string>([
  `${commonActions.AuthenticatedWindow.ACTIVITY}`
]);

const isBroadcast = (item: ReduxStandardAction) => {
  return (cmxTypeguards.isFSA(item) && item.meta?.broadcast) ?? false;
};

const senderSubscriptions: CMx.ChannelSubscription[] = [
  {
    match: isBroadcast
  },
  {
    observe: (item: FSAction<any>) => {
      isBroadcast(item) && console.log(`Broadcasting: ${item.type}`, item);
    }
  }
];

const ChildWindowActivityObserver = {
  observe: (item: ReduxStandardAction) => {
    if (cmxTypeguards.isFSA(item)) {
      const suppressRedux = item.meta?.suppressRedux ?? false;
      if (suppressRedux) {
        if (activityEvents.has(item.type)) {
          logger.log(`ChildWindowActivityObserver`, item);
          setIsActive(true);
        }
      } else {
        //
        if (item.meta) {
          item.meta.broadcast = false;
        }
        store.dispatch(item as ReduxStandardAction);
      }
      return false;
    }
  }
};

const receiverSubscriptions: CMx.ChannelSubscription[] = [
  ChildWindowActivityObserver
];

if (!IS_PRODUCTION) {
  receiverSubscriptions.push({
    observe: (msg: ReduxStandardAction) => {
      console.log(`message received`, msg);
    }
  });
}

export { senderSubscriptions, receiverSubscriptions };
