import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Divider,
  Flex,
  Input,
  PopoverBody,
  PopoverContent,
  PopoverHeader
} from '@chakra-ui/react';
import { styles } from './my-status.styles';
import theme from '../../theme';

const MyStatus: React.FC<AppProps.MyStatusProps> = props => {
  const { listStatus, updateStatus, user, context } = props;
  const classes = styles();

  let { statusList } = useSelector(
    (state: CMx.ApplicationState) => state.myStatus
  );

  const [statusRows, setStatusRows] = useState(statusList?.rowValues);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const fetchStatusList = async () => {
      await listStatus(context?.activeContext?.tenantId!);
    };
    if (statusRows.length === 0) {
      fetchStatusList();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setStatusRows(statusList?.rowValues);
  }, [statusList]);

  const handleSearch = (e: { target: { value: string } }) => {
    setSearch(e.target.value);
  };

  const handleUpdateStatus = (status: string) => {
    updateStatus(status, user, context);
  };

  return (
    <PopoverContent sx={classes['.statusPopover']}>
      <PopoverHeader sx={classes['.statusHeader']}>
        Change status to:
        <Divider sx={classes['.divider']} />
      </PopoverHeader>
      <PopoverBody sx={classes['.statusPopoverBody']}>
        <Input
          type="text"
          onChange={handleSearch}
          placeholder={'Find a status'}
          sx={classes['.searchInput']}
          _placeholder={{ color: theme.colors.text['lowEmphasis'] }}></Input>
        <Flex direction={'column'}>
          {statusRows?.map(row => {
            if (
              search === '' ||
              row.Reasons.toLowerCase().includes(search.toLowerCase())
            ) {
              return (
                <Flex direction={'row'} sx={classes['.statusRow']}>
                  <span
                    style={{
                      ...classes['.statusCircle'],
                      backgroundColor:
                        row.Color === 'Blue'
                          ? theme.colors.text['lowEmphasis']
                          : row.Color
                    }}></span>
                  <Button
                    variant={'ghost'}
                    onClick={() => handleUpdateStatus(row.Reasons)}
                    style={classes['.statusButton']}>
                    {row.Reasons}
                  </Button>
                </Flex>
              );
            }
            return <></>;
          })}
        </Flex>
      </PopoverBody>
    </PopoverContent>
  );
};

export { MyStatus };
