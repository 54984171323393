import { ColDef, GridOptions } from 'ag-grid-community';
import {
  EndpointData,
  HTTPMethodType
} from '../../components/data-grid/data-grid-utils';

export const groupColDefs: ColDef[] = [
  {
    headerName: 'Name',
    field: 'groupName',
    sortable: true
  },
  {
    headerName: 'Type',
    field: 'groupType',
    sortable: true
  }
];

const groupDetailFields: any = [
  {
    label: 'Name',
    key: 'groupName',
    required: true,
    type: 'text',
    readonly: false
  },
  {
    label: 'Type',
    key: 'groupType',
    required: true,
    type: 'text',
    readonly: false
  }
];

const cellFieldGroup: CMxCommonApp.FieldGroup = {
  label: 'Cells',
  fields: groupDetailFields
};

export const groupDetailForm = {
  fieldGroups: [cellFieldGroup],
  buttons: [{ buttonType: 'submit', buttonText: 'Back' }]
};

export const getEndpointDataByTenantId = (tenantId: string) => {
  const params = [['tenantId', `${tenantId}`]];
  const endPoint: EndpointData = {
    endPoint: '/users/groups/tenant/v1',
    HTTPMethodType: HTTPMethodType.GET,
    params: params
  };
  return endPoint;
};

export const gridOptions: GridOptions = {
  suppressHorizontalScroll: true
};

export enum eligibleGroupTypeRoleIds {
  CODING_MANAGER = 'coding_manager',
  CODER = 'coder',
  CQA_CODER = 'cqa_coder',
  CQA_MANAGER = 'cqa_manager'
}

export type eligibleGroupTypeRoles =
  | eligibleGroupTypeRoleIds.CODING_MANAGER
  | eligibleGroupTypeRoleIds.CODER
  | eligibleGroupTypeRoleIds.CQA_CODER
  | eligibleGroupTypeRoleIds.CQA_MANAGER;

export enum groupTypeIds {
  CODING_GROUP = 'CODING_GROUP',
  CQA_GROUP = 'CQA_GROUP'
}

export const groupTypes = [
  {
    label: 'Coding group',
    value: groupTypeIds.CODING_GROUP
  },
  {
    label: 'CQA group',
    value: groupTypeIds.CQA_GROUP
  }
];
