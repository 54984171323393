import InitialState from './initial-state';
import { cmxReducerFactory } from './reducer-utils';
import { list, getXML } from '../actions/rules-list';

const reducer = cmxReducerFactory(InitialState.rulesList)
  .case(list.async.done, (state, { result }) => {
    return { ...state, decisionDefinitions: result };
  })
  .case(getXML.async.started, (state, decision) => {
    return {
      ...state,
      selectedDecisionName: decision.decisionName,
      selectedDiagramXML: null
    };
  })
  .case(getXML.async.failed, (state, payload) => {
    return {
      ...state,
      selectedDiagramXML: null
    };
  })
  .case(getXML.async.done, (state, { result }) => {
    return { ...state, selectedDiagramXML: result };
  });

export default reducer;
