import {
  listCaseConfigurations,
  saveCaseConfiguration,
  syncActions
} from '../../actions/case-config';
import { buildDispatchable } from '../../actions/_action-utilities';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router';
import { isResourceViewOnly } from '../../core/resources';
import { commonEnums } from '@codametrix/ui-common';
import { CaseConfigForm } from './case-config-form';
const { ResourceId } = commonEnums;

type CaseConfigFormProps = Pick<
  AppProps.CaseConfigFormProps,
  | 'caseConfigs'
  | 'dataObject'
  | 'loadState'
  | 'section'
  | 'serviceLine'
  | 'tenantId'
  | 'activeTenantId'
  | 'section'
  | 'viewOnly'
  | 'serviceLine'
>;
type CaseConfigFormDispatches = Pick<
  AppProps.CaseConfigFormProps,
  'listCaseConfigs' | 'saveCaseConfig' | 'selectConfigSection' | 'navigateBack'
>;

const mapStateToProps = function(
  state: CMx.ApplicationState
): CaseConfigFormProps {
  const { caseConfig, organization, ui } = state;

  const viewOnly = isResourceViewOnly(
    ui.context.activeContext?.resources || [],
    ResourceId.CASE_CONFIGURATION
  );

  return {
    ...caseConfig,
    tenantId: organization.activeOrganization.tenantId,
    activeTenantId: ui.context.activeContext?.tenantId || '',
    viewOnly,
    serviceLine: ui.serviceLine || caseConfig.serviceLine
  };
};

const mapDispatchToProps = (dispatch: any): CaseConfigFormDispatches => {
  const runDispatchable = buildDispatchable(dispatch);

  return {
    listCaseConfigs: (
      tenantId: string,
      activeTenantId: string,
      section: string,
      serviceLine: CMx.ServiceLine
    ) => {
      runDispatchable(() => {
        return dispatch(
          listCaseConfigurations({
            tenantId,
            section,
            activeTenantId,
            serviceLine
          })
        );
      });
    },
    saveCaseConfig: (payload: CMx.SaveCaseConfigPayload) => {
      runDispatchable(() => {
        return dispatch(saveCaseConfiguration(payload));
      });
    },
    selectConfigSection: (payload: string) => {
      runDispatchable(() => {
        return dispatch(syncActions.selectConfigSection(payload));
      });
    },
    navigateBack: () => {
      runDispatchable(() => {
        return dispatch(goBack());
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CaseConfigForm);
