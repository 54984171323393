import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input as ChakraInput,
  InputGroup,
  InputProps,
  InputLeftAddon,
  InputLeftElement,
  InputRightElement
} from '@chakra-ui/react';
import React from 'react';
import IconLabel from '../icon-label/iconlabel';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { SearchIcon } from '@chakra-ui/icons';
import { InputSize, InputVariant } from '../../core/enums';

export type ChakraInputProps = {
  value?: string;
  size?: InputSize;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  classes?: any;
  dataTestId?: string;
  variant?: InputVariant;
  type?: string;
  color?: string;
  addonLeft?: React.ReactNode;
  iconRight?: React.ReactElement;
  iconLeft?: React.ReactElement;
  label?: string;
  textInfo?: string;
  textError?: string;
  isLeftAddon?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  isError?: boolean;
  isInfo?: boolean;
  onKeyUp?: (e?: React.KeyboardEvent<HTMLInputElement>) => void;
} & InputProps;

export const Input: React.FC<ChakraInputProps> = ({
  value,
  size,
  placeholder,
  classes,
  dataTestId,
  variant,
  type,
  color,
  addonLeft,
  iconRight,
  label,
  textInfo,
  isDisabled,
  isReadOnly,
  textError,
  isRequired = variant === InputVariant.SEARCH && false,
  iconLeft = variant === InputVariant.SEARCH && <SearchIcon />,
  isError = variant === InputVariant.SEARCH && false,
  ...props
}) => {
  return (
    <FormControl
      sx={classes && classes.root}
      isRequired={isRequired}
      isDisabled={isDisabled}
      isInvalid={isError}
      isReadOnly={isReadOnly}
      data-testid={dataTestId?.trim() ?? 'input-field'}>
      {label && <FormLabel sx={classes?.formLabel}>{label}</FormLabel>}
      <InputGroup size={size} variant={variant}>
        {addonLeft && <InputLeftAddon role="addonLeft" children={addonLeft} />}
        {iconLeft && (
          <InputLeftElement
            role="iconLeft"
            sx={classes?.leftIcon}
            children={iconLeft}
          />
        )}
        <ChakraInput
          {...props}
          value={value}
          sx={classes?.input}
          placeholder={placeholder}
          type={type}
          color={color}
          isInvalid={isError}
          isReadOnly={isReadOnly}
          isDisabled={isDisabled}></ChakraInput>
        {iconRight && (
          <InputRightElement
            role="iconRight"
            sx={classes?.rightIcon}
            children={iconRight}
          />
        )}
      </InputGroup>
      {textInfo && (
        <FormHelperText>
          <IconLabel
            isLeft={true}
            leftIcon={<InfoOutlineIcon role="infoIcon" />}
            label={textInfo}
          />
        </FormHelperText>
      )}
      {textError && (
        <FormErrorMessage>
          <IconLabel
            isLeft={true}
            leftIcon={<InfoOutlineIcon role="errorIcon" />}
            label={textError}
          />
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

Input.defaultProps = {
  variant: InputVariant.TEXT,
  size: InputSize.MD,
  isDisabled: false,
  isRequired: false,
  textInfo: '',
  placeholder: '',
  isReadOnly: false,
  isError: false
};

Input.displayName = 'Input';

export default Input;
