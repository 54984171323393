/// <reference path="../types.d.ts" />

import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { AccountSettings } from './action-types';
import {
  deletePreferenceValues,
  saveUserPreference,
  updatePreferenceValues
} from './user-preferences';
import { commonEnums } from '@codametrix/ui-common';
import { showFeedback } from './ui';
const { UserPreferenceKey } = commonEnums;

const actionCreator = actionCreatorFactory();
const createAsync = asyncFactory<CMx.AccountSettingsState>(actionCreator);

const toggleIsOpen = actionCreator<boolean>(AccountSettings.TOGGLE_IS_OPEN);

const saveFormData = actionCreator<CMx.AccountDefaultsDataObject>(
  AccountSettings.SAVE_FORM_DATA
);

const updateFormDefinition = actionCreator<CMx.Context>(
  AccountSettings.UPDATE_FORM_DEFINITION
);

const saveDefaults = createAsync<
  CMx.SaveAccountDefaultPayload,
  void,
  CMxCommonApp.SubmitError
>(AccountSettings.SAVE_DEFAULTS, async (payload, dispatch) => {
  const { defaultOrg, defaultServiceLine, defaultHomepage, userId } = payload;

  if (defaultOrg === -1) {
    dispatch(
      deletePreferenceValues({
        userId,
        keyname: UserPreferenceKey.DEFAULT_USER_CONTEXT
      })
    );
  } else if (defaultOrg) {
    const defaultOrgPreference: CMx.PreferencePayload = {
      userId,
      value: defaultOrg.toString(),
      keyname: UserPreferenceKey.DEFAULT_USER_CONTEXT
    };
    dispatch(saveUserPreference(defaultOrgPreference));
  }

  if (defaultServiceLine === -1) {
    dispatch(
      deletePreferenceValues({
        userId,
        keyname: UserPreferenceKey.DEFAULT_USER_SERVICE_LINE
      })
    );
  } else if (defaultServiceLine) {
    const defaultServiceLinePreference: CMx.PreferencePayload = {
      userId,
      value: defaultServiceLine.toString(),
      keyname: UserPreferenceKey.DEFAULT_USER_SERVICE_LINE
    };
    dispatch(saveUserPreference(defaultServiceLinePreference));
  }

  if (defaultHomepage) {
    const defaultHomepagePreference: CMx.PreferencePayload = {
      userId,
      value: defaultHomepage.path,
      keyname: UserPreferenceKey.DEFAULT_HOMEPAGE,
      tenantId: defaultHomepage.tenantId
    };

    dispatch(
      updatePreferenceValues({
        userId,
        keyname: UserPreferenceKey.DEFAULT_HOMEPAGE,
        preferenceValue: defaultHomepagePreference
      })
    );
  }

  dispatch(toggleIsOpen(false));
  dispatch(
    showFeedback({
      id: Date.now(),
      message: `Account settings saved`,
      dismissable: true,
      className: 'feedback-working'
    })
  );
});

export { toggleIsOpen, saveDefaults, saveFormData, updateFormDefinition };
