import { buildDispatchable } from '../../actions/_action-utilities';
import { connect } from 'react-redux';
import SSOLogin from './sso-login';
import { loginViaSSO, exchangeTokenSso } from '../../actions/login.action';

const mapStateToProps = (props: CMx.ApplicationState) => {
  const { login } = props;
  return {
    loading: login.loading
  };
};

const mapDispatchToProps = (dispatch: any) => {
  const runDispatchable = buildDispatchable(dispatch);

  return {
    loginViaSSO: async (item: any) => {
      runDispatchable(() => {
        return dispatch(loginViaSSO(item));
      });
    },
    exchangeTokenSso: async (idToken: string) => {
      runDispatchable(() => {
        return dispatch(exchangeTokenSso(idToken));
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SSOLogin);
