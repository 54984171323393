export const attachDocs: CMx.AttachDocsState = {
  isOpen: false,
  loadingDocuments: false,
  loadingNotes: false,
  currentCaseRun: {} as CMxAPI.CaseRunV2,
  activeNotes: [],
  documentsAvailable: [],
  specialtiesDictionary: [],
  noteTypesDictionary: []
};
