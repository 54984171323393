import { Grid } from '@chakra-ui/react';
import React from 'react';
import { InputVariant } from '../../core/enums';
import Input from '../input/input';
import { Select } from '../select/select';
import { styles } from './search-bar.styles';

export type SearchBarProps = {
  selectOptions?: Array<any>;
  placeholder?: string;
  selectedOption?: string;
  onSelectOptionChange?: (newValue: any) => void;
  onSearchInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSearchKeyPress?: (e?: React.KeyboardEvent<HTMLInputElement>) => void;
};

const SearchBar = (props: SearchBarProps) => {
  const {
    selectOptions,
    selectedOption,
    onSelectOptionChange,
    onSearchInputChange,
    onSearchKeyPress
  } = props;
  const classes = styles();
  return (
    <Grid
      templateColumns={`min-content max-content`}
      sx={classes.searchBarGrid}>
      <Select
        items={selectOptions}
        value={selectedOption}
        onChange={onSelectOptionChange}
        dataTestId={'searchSelect'}
        classes={{ button: classes.select }}
      />
      <Input
        placeholder={'Search'}
        variant={InputVariant.SEARCH}
        label={''}
        onChange={onSearchInputChange}
        onKeyUp={onSearchKeyPress}
        dataTestId={'searchInput'}
        classes={{ input: classes.input, leftIcon: classes.icon }}
      />
    </Grid>
  );
};

export { SearchBar };
