import {
  saveOrganization,
  getOrganization,
  doAssociation,
  addChildOrg,
  toggleEdit,
  navigate,
  addChildOrgType
} from '../../actions/organization';
import { loadDictionaries } from '../../actions/dictionary';
import { syncActions as externalAppSyncActions } from '../../actions/external-applications';
import { buildDispatchable } from '../../actions/_action-utilities';
import { push } from 'connected-react-router';
import { isTabViewOnly, isCaseConfigDeny } from '../../core/resources';
import { commonEnums, objectUtils } from '@codametrix/ui-common';
const { ResourceId } = commonEnums;

export const mapStateToProps = function(state: any) {
  const { organization, ui } = state;

  const viewOnly = isTabViewOnly(ui.AdminTabs, ResourceId.ORGANIZATIONS);

  const caseConfigDisable = isCaseConfigDeny(
    ui.context.user.roles || [],
    ui.context.activeContext?.organizationName
  );

  return {
    ...organization,
    auth: state.login.auth,
    viewOnly,
    caseConfigDisable
  };
};

export const mapDispatchToProps = (dispatch: any) => {
  const runDispatchable = buildDispatchable(dispatch);

  return {
    toggleEdit: (bool: boolean) => dispatch(toggleEdit(bool)),
    addChildOrg: (
      viewOnly: boolean,
      token: CMxCommonApp.BearerToken,
      addChild: boolean | string,
      parentOrg: CMxAPI.Organization,
      isAddChild?: boolean
    ) => {
      runDispatchable(() => {
        return dispatch(
          addChildOrg({
            viewOnly,
            token,
            childOrgType: addChild,
            parentOrg,
            isAddChild
          })
        );
      });
    },
    navigate: (
      token: CMxCommonApp.BearerToken,
      organization: CMxAPI.Organization
    ) => {
      runDispatchable(() => {
        return dispatch(navigate({ token, organization }));
      });
    },
    navigateExternalApplication: (orgId: number) => {
      runDispatchable(() => {
        dispatch(externalAppSyncActions.selectOrgnization(orgId));
        return dispatch(push(`/cmx/admin/orgs/${orgId}/external-applications`));
      });
    },
    navigateCaseConfig: (orgId: number) => {
      runDispatchable(() => {
        return dispatch(push(`/cmx/admin/orgs/${orgId}/case-config`));
      });
    },
    navigateDictionaries: (org: any) => {
      runDispatchable(() => {
        //@ts-ignore
        return dispatch(loadDictionaries(org));
      });
    },
    doAssociation: (
      token: CMxCommonApp.BearerToken,
      edits: CMx.PartialRelationshipEdit[],
      newOrg: CMxAPI.Organization
    ) => {
      runDispatchable(() => {
        return dispatch(doAssociation({ token, edits, newOrg }));
      });
    },
    getOrganization: (payload: {
      params: CMx.GetOrganizationArgs;
      activeTenantId: string;
    }) => {
      runDispatchable(() => {
        return dispatch(getOrganization(payload));
      });
    },
    doSave: (
      token: CMxCommonApp.BearerToken,
      organization: CMxAPI.Organization
    ) => {
      runDispatchable(() => {
        return dispatch(saveOrganization({ token, organization }));
      });
    },
    addChildOrgType: (childOrgType: string | boolean) =>
      dispatch(addChildOrgType(childOrgType))
  };
};

export const convertTimeZones = (items: string[]) =>
  items.map((item: string) => ({
    value: item,
    label: item
  }));

export const findOrganizationRelationship = (
  serviceLines: CMxAPI.OrgServiceLine[],
  partial: Partial<CMxAPI.OrganizationRelationship>
) => {
  let sought: CMxAPI.OrganizationRelationship;

  serviceLines.find(sl => {
    return sl.organizationRelationships.find(orgRel => {
      if (objectUtils.isMatch(orgRel, partial)) {
        sought = orgRel;
        return true;
      }
      return false;
    });
  });

  // @ts-ignore
  return sought;
};
