/** React & Redux imports */
import React from 'react';
import { useDispatch } from 'react-redux';

/** UI and SASS Imports */
import { CMxComponents } from '@codametrix/ui-components/dist/types';
import './dictionary-history-cols.scss';
import { showFeedback } from '../../../actions/ui';

/** Enums Imports */
import { RequestTypeEnum, RequestStatus } from './dictionary-history-enum';

/** Tools imports */
import ClipboardJS from 'clipboard';
import { ColDef } from 'ag-grid-community';

/** declaration and events for clipboard js tool */

const clipboard = new ClipboardJS('.link_uri');

/**
 * Function React component for URI S3 with copy clipboard feature
 * @param param0
 * @returns
 */
const URIS3Link = ({ dictionaryCsvS3Uri }: { dictionaryCsvS3Uri: string }) => {
  const dispatch = useDispatch();

  // events for clipboard control
  clipboard.on('success', function(e) {
    dispatch(
      showFeedback({
        id: Date.now(),
        message: `S3 URI copied.`,
        dismissable: true,
        className: 'feedback-working'
      })
    );
  });

  clipboard.on('error', function(e) {
    dispatch(
      showFeedback({
        id: Date.now(),
        message: `S3 URI can not be copied.`,
        dismissable: true,
        className: 'feedback-error'
      })
    );
  });

  return (
    <a
      href="/#"
      title={dictionaryCsvS3Uri}
      className="link_uri"
      data-clipboard-text={dictionaryCsvS3Uri}>
      copy
    </a>
  );
};

/**
 * Export definition of colums for dictionary history
 */
export default [
  {
    key: 'id',
    displayName: 'Index'
  },
  {
    key: 'requestType',
    displayName: 'Type',
    transform: (rowValue: { requestType: any }) => {
      const { requestType } = rowValue;
      const requestTypeValue: RequestTypeEnum = (RequestTypeEnum as any)[
        requestType
      ];

      return requestTypeValue;
    }
  },
  {
    key: 'requestStatus',
    displayName: 'Status',
    transform: (rowValue: { requestStatus: any }) => {
      const { requestStatus } = rowValue;
      const requestStatusValue: RequestStatus = (RequestStatus as any)[
        requestStatus
      ];

      return requestStatusValue;
    }
  },
  {
    key: 'dictionaryCsvS3Uri',
    displayName: 'S3 URI',
    transform: rowValue => {
      const { dictionaryCsvS3Uri } = rowValue;

      if (dictionaryCsvS3Uri && dictionaryCsvS3Uri.length > 0) {
        return (
          <div className="section_uri">
            <div className="text_uri">{dictionaryCsvS3Uri}</div>
            <URIS3Link dictionaryCsvS3Uri={dictionaryCsvS3Uri} />
          </div>
        );
      }

      return;
    }
  },
  {
    key: 'updatedByFullName',
    displayName: 'Updated By'
  },
  {
    key: 'updatedDate',
    displayName: 'Date Updated'
  }
] as CMxComponents.ColumnDefintion[];

export const dictionaryListcolDefs: ColDef[] = [
  {
    headerName: 'Index',
    field: 'id'
  },
  {
    field: 'requestType',
    headerName: 'Type',
    sortable: false
  },
  {
    field: 'requestStatus',
    headerName: 'Status',
    sortable: false
  },
  {
    field: 'dictionaryCsvS3Uri',
    headerName: 'S3 URI',
    sortable: false
  },
  {
    headerName: 'Download',
    field: 'dictionaryCsvS3Uri',
    sortable: false
  },
  {
    headerName: 'Updated By',
    field: 'updatedByFullName',
    sortable: false
  },
  {
    headerName: 'Date Updated',
    field: 'updatedDate',
    sortable: false
  }
];
