/// <reference path="../../types.d.ts" />

import React, { useState, useEffect } from 'react';
import { Grid, Flex } from '@chakra-ui/react';

import { Checkbox, CheckboxSize } from '../../components/checkbox/checkbox';
import { styles } from './groups-detail-styles';

import { eligibleGroupTypeRoles } from './groups-constants';

type BooleanRecord = Record<string, boolean>;

export type GroupMemberProps = {
  eligibleGroupMembers: CMxAPI.GroupUser[];
  groupTitle: string;
  groupRoleId: eligibleGroupTypeRoles;
  addGroupMember: ({
    groupId,
    groupMembers
  }: AppProps.addGroupMemberParams) => void;
  selectedGroup: CMxAPI.Group;
};

const getCheckboxValues = (eligibleGroupMembers: CMxAPI.GroupUser[]) => {
  const values: { [key: string]: BooleanRecord } = {};
  eligibleGroupMembers.forEach(member => {
    const roleName: string = member.groupRoleName || '';
    if (!values[roleName]) {
      values[roleName] = {};
    }
    values[roleName][member.userUid] = member.member;
  });

  return values;
};

const sortMembersAlphabetical = (members: CMxAPI.GroupUser[]) => {
  // Sorting by last name then first name
  return members.sort((a, b) => {
    const firstNameA = a.firstName?.toUpperCase();
    const firstNameB = b.firstName?.toUpperCase();
    const lastNameA = a.lastName?.toUpperCase();
    const lastNameB = b.lastName?.toUpperCase();
    if (lastNameA && lastNameB && lastNameA < lastNameB) {
      return -1;
    }
    if (lastNameA && lastNameB && lastNameA > lastNameB) {
      return 1;
    }
    if (firstNameA && firstNameB && firstNameA < firstNameB) {
      return -1;
    }
    if (firstNameA && firstNameB && firstNameA > firstNameB) {
      return 1;
    }

    return 0;
  });
};

const sortEligibleMembers = (eligibleGroupMembers: CMxAPI.GroupUser[]) => {
  const selectedMembers = sortMembersAlphabetical(
    eligibleGroupMembers.filter(member => member.member)
  );
  const unselectedMembers = sortMembersAlphabetical(
    eligibleGroupMembers.filter(member => !member.member)
  );
  // Putting all members of group before non-members in list
  return selectedMembers.concat(unselectedMembers);
};

const GroupMembers: React.FC<GroupMemberProps> = props => {
  const classes = styles();
  const { eligibleGroupMembers, addGroupMember, groupRoleId } = props;
  const initialCheckboxValues = getCheckboxValues(eligibleGroupMembers);
  const [checkboxValues, setCheckboxValues] = useState<{
    [key: string]: BooleanRecord;
  }>(initialCheckboxValues);

  let initialSelected = eligibleGroupMembers.filter(user => user.member).length;
  const [numSelected, setNumManagersSelected] = useState<number>(
    initialSelected
  );

  useEffect(() => {
    setNumManagersSelected(
      Object.values(checkboxValues[groupRoleId] || {}).filter(x => x).length
    );
  }, [checkboxValues, groupRoleId]);

  useEffect(() => {
    const checkboxValuesMap = getCheckboxValues(eligibleGroupMembers);
    setCheckboxValues(checkboxValuesMap);
  }, [eligibleGroupMembers]);

  const handleGroupMemberSelection = (
    member: CMxAPI.GroupUser,
    event: React.ChangeEvent<HTMLInputElement>,
    role: eligibleGroupTypeRoles
  ) => {
    const values = { ...checkboxValues };
    values[member.groupRoleName || ''][member.userUid] = event.target.checked;
    setCheckboxValues(values);

    const editedGroupMembers: CMxAPI.GroupUser[] = [];
    editedGroupMembers.push({
      ...member,
      member: event.target.checked,
      groupRoleName: role,
      groupRoleId: null
    });
    const groupId = member.groupId;
    addGroupMember({ groupId, groupMembers: editedGroupMembers });
  };

  return (
    <fieldset style={classes.fieldset}>
      <legend style={classes.legend}>
        {props.groupTitle} ({numSelected} selected)
      </legend>
      <Flex sx={classes.memberinformation}>
        <Flex style={classes.checkBox}>
          <Grid rowGap={3} templateColumns="repeat(3, 1fr)">
            {sortEligibleMembers(eligibleGroupMembers)
              .filter(user => user.groupRoleName === groupRoleId)
              .map((member: CMxAPI.GroupUser) => (
                <div style={classes.memberInfoBox}>
                  <Checkbox
                    label={member.lastName + ', ' + member.firstName}
                    onChange={event =>
                      handleGroupMemberSelection(member, event, groupRoleId)
                    }
                    key={member.userUid}
                    id={member.userUid?.toString()}
                    isChecked={
                      !!(checkboxValues[groupRoleId] || {})[member.userUid]
                    }
                    size={CheckboxSize.LG}
                    dataTestId={member.userUid?.toString()}
                    _className={classes.checkboxSpacing}
                    spacing="5px"
                  />
                  <div style={classes.usernameLabel}>
                    {member.userName ?? 'Username Missing'}
                  </div>
                </div>
              ))}
          </Grid>
        </Flex>
      </Flex>
    </fieldset>
  );
};

GroupMembers.displayName = 'GroupMembers';
export { GroupMembers };
