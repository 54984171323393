import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Location } from 'history';

interface LocationEffectProps {
  onLocationChange: (location: Location) => void;
}

const LocationEffect: React.FC<LocationEffectProps> = ({
  onLocationChange
}) => {
  const location = useLocation();

  useEffect(() => {
    onLocationChange(location);
  }, [location, onLocationChange]);

  return null; // component is just for Effect, not render
};
export default LocationEffect;
