import { Button } from '../../components/button/button';
import { ButtonSize } from '../../core/enums';
import { ButtonVariant } from '../../core/enums';
import { styles } from './forgot-password-styles';
import { Flex } from '@chakra-ui/react';
import theme from '../../theme';
import Input from '../../components/input/input';

import deepEqual from 'deep-equal';
import React, { useEffect, useState } from 'react';
import { LOAD_STATES } from '../../reducers/common';

import './forgot-password.scss';

const ForgotPasswordForm: React.FC<AppProps.ForgotPasswordFormProps> = (
  props: AppProps.ForgotPasswordFormProps
) => {
  const {
    handleUsernameSubmission,
    handleCancel,
    initializeForgotPassword,
    loadState
  } = props;
  const [username, changeUsername] = useState<string>('');

  useEffect(() => {
    initializeForgotPassword();
  }, [initializeForgotPassword]);

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    changeUsername(e.target.value);
  };

  const handleUsernameClick = (e: any) => {
    e.preventDefault();
    handleUsernameSubmission(username);
  };
  const handleCancelClick = (e: any) => {
    e.preventDefault();
    handleCancel();
  };

  const classes = styles();

  let inner;
  if (deepEqual(loadState, LOAD_STATES.done)) {
    inner = (
      <span>
        <h5>Request Submitted </h5>{' '}
        <div>
          <span>
            A password reset email has been sent to the email address of the
            associated account.
          </span>
        </div>
        <Button
          size={ButtonSize.MD}
          label={'Return'}
          backgroundColor={theme.colors.accent[100]}
          color={theme.colors.text['light']}
          name="Return"
          dataTestId="return"
          onClick={handleCancelClick}
        />
      </span>
    );
  } else if (loadState === LOAD_STATES.started) {
    inner = <div className="loading loading-lg"></div>;
  } else {
    inner = (
      <span>
        <h5>Forgot Password</h5>
        <div>
          <span>
            Please enter your username to begin the password reset process.
          </span>
        </div>

        <form className={'forgot-password-form'}>
          <div className="form-group">
            <label className="form-label" htmlFor="input-email-1">
              Username
            </label>
            <Input
              autoFocus
              onChange={handleUsernameChange}
              name={'username'}
              placeholder={''}
              type={'text'}
            />
          </div>
          <Flex className="form-row" sx={classes.btnHeader}>
            <Button
              size={ButtonSize.MD}
              label={'Submit'}
              backgroundColor={theme.colors.accent[100]}
              color={theme.colors.text['light']}
              name="Submit"
              dataTestId="submitResetPass"
              onClick={handleUsernameClick}
              type="submit"
            />
            <Button
              label="Cancel"
              variant={ButtonVariant.LINKS}
              height={'24px'}
              dataTestId="cancel"
              onClick={handleCancelClick}
            />
          </Flex>
        </form>
      </span>
    );
  }
  return <div className={'forgot-password-form-container'}>{inner}</div>;
};
export default ForgotPasswordForm;
