import { IHeaderParams } from 'ag-grid-community';
import './custom-dictionary-value-list-header.scss';

export interface ICustomHeaderParams {
  menuIcon: string;
}

export class CustomDictionaryValueHeader {
  private agParams!: ICustomHeaderParams & IHeaderParams;
  private eGui!: HTMLDivElement;

  init(agParams: ICustomHeaderParams & IHeaderParams) {
    this.agParams = agParams;
    this.eGui = document.createElement('div');
    this.eGui.innerHTML = `
            <div class="customHeaderLabel">${this.agParams.displayName}</div>
            <div class="customHeaderMenuButton">
                <i class="fas ${this.agParams.menuIcon}"></i>
            </div>
        `;
  }

  getGui() {
    return this.eGui;
  }
}
