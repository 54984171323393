import {
  ActivityMonitor,
  sessionManagement,
  commonActions,
  authenticationContext
} from '@codametrix/ui-common';
import store from '../store/store';
import { dismissModal, showModalMessage } from '../actions/ui';
import { onlyRefreshContext } from '../actions/contexts';
import { logoutApp } from '../actions/login.action';
import { push } from 'connected-react-router';
import { Action } from 'redux';

const { activityMonitor } = sessionManagement;

/**
 * Retrieve a new token for a session.
 */
const refreshContext = async () => {
  store.dispatch(dismissModal() as Action);
  const context = await store.dispatch(
    (onlyRefreshContext({
      organizationId: `${authenticationContext.getContextId()}`
    }) as any) as Action
  );
  activityMonitor.shouldRefresh(context);
};

/**
 * Log the user out of the application
 */
const doLogout = () => {
  store.dispatch(push(`/`) as Action);
};
/**
 * Handle inactivity from the user
 */
const handleTimeout = () => {
  store.dispatch(dismissModal() as Action);
  store.dispatch((logoutApp({ showModal: false }) as any) as Action);
};

/**
 * Prompt for user to refresh session.
 */

const handleActivityPrompt = () => {
  store.dispatch(
    showModalMessage({
      title: 'Are you still there?',
      buttonText: 'Yes',
      handler: commonActions.UserInterface.REFRESH
    }) as Action
  );
};

activityMonitor.on(
  ActivityMonitor.events.ACTIVITY_PROMPT,
  handleActivityPrompt
);
activityMonitor.on(ActivityMonitor.events.BEGIN_REFRESH, refreshContext);
activityMonitor.on(ActivityMonitor.events.LOGOUT, doLogout);
activityMonitor.on(ActivityMonitor.events.TIMEOUT, handleTimeout);
