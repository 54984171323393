import { CSSProperties } from 'react';
import theme from '../../theme';

export const styles = (): Record<string, CSSProperties> => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  divider: {
    borderColor: theme.colors.stroke[200]
  }
});
