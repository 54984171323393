import theme from '../../theme';

export const styles = {
  button: {
    width: '100%',
    'text-align': 'left',
    color: theme.colors.text['highEmphasis'],
    'font-family': 'Roboto',
    ...theme.textStyles['Body 1'],
    background: theme.colors.text['light'],
    border: `1px solid ${theme.colors.stroke[200]}`,
    'background-color': theme.colors.text['light'],
    paddingLeft: theme.space[16],
    borderRadius: theme.radii[3],
    'max-width': theme.space[300],
    overflow: 'hidden',
    'min-width': theme.space[120]
  },
  buttonSelected: {
    width: '100%',
    'text-align': 'left',
    color: theme.colors.text['highEmphasis'],
    'font-family': 'Roboto',
    ...theme.textStyles['Body 1'],
    background: theme.colors.text['light'],
    border: `${theme.borders['1px']} ${theme.colors.stroke[200]}`,
    'background-color': theme.colors.text['light'],
    paddingLeft: theme.space[16],
    borderRadius: theme.radii[3],
    'max-width': theme.space[300],
    overflow: 'hidden',
    'min-width': theme.space[120]
  },
  list: {
    padding: '10px',
    'max-height': '400px',
    'over-flow': 'auto'
  },
  item: {
    'margin-left': '0px',
    color: theme.colors.text['mediumEmphasis']
  },
  itemSelected: {
    'margin-left': '0px',
    color: theme.colors.accent[200],
    backgroundColor: theme.colors.accent[50]
  },
  icon: {
    color: theme.colors.foundation[300],
    'font-size': '2em'
  },
  formControl: {
    width: 'auto'
  },
  defaultFormControl: {
    width: '100%'
  }
};
