export const defaultOrg: CMxCommonApp.FieldDefinition = {
  key: 'defaultOrg',
  name: 'defaultOrg',
  label: 'Organization',
  options: [],
  type: 'select',
  placeholder: 'Select',
  labelField: 'organizationName',
  valueField: 'organizationId',
  helpText: 'Automatically login under this organization'
};

export const defaultServiceLine: CMxCommonApp.FieldDefinition = {
  key: 'defaultServiceLine',
  name: 'id',
  label: 'Service Line',
  options: [],
  type: 'select',
  placeholder: 'Select',
  labelField: 'name',
  valueField: 'id',
  helpText: 'Automatically choose this service line'
};

export const defaultHomepage: CMxCommonApp.FieldDefinition = {
  key: 'defaultHomepage',
  name: 'defaultHomepage',
  label: 'Homepage',
  options: [],
  type: 'select',
  placeholder: 'Select',
  labelField: 'label',
  valueField: 'path',
  helpText: 'Automatically open to this page on login'
};

export const noDefaultOrgOption: Partial<CMxAPI.OrganizationContext> = {
  organizationId: -1,
  organizationName: 'None'
};

export const noDefaultServiceLineOption: CMxAPI.ServiceLine = {
  id: -1,
  name: 'None'
};

export const noDefaultHomepageOption = {
  label: 'None',
  path: 'None'
};

export const defaultsFieldGroup: CMxCommonApp.FieldGroup = {
  label: 'Account Defaults',
  fields: [defaultOrg, defaultServiceLine, defaultHomepage]
};
const formDefinition: CMxCommonApp.FormDefintion = {
  fieldGroups: [defaultsFieldGroup],
  buttons: [{ buttonType: 'submit', buttonText: 'Save' }]
};

const formObject = {
  defaultOrg: undefined,
  organizationId: undefined,
  defaultHomepage: undefined
};
export const accountSettings: CMx.AccountSettingsState = {
  isOpen: false,
  accountDefaults: {
    formDefinition,
    formObject
  }
};
