/// <reference path="../../../src/types.d.ts" />

import { USER_STUB } from '../../tests/fixtures/stubs/user';
import { CODAMETRIX } from '../../views/organizations/stub-organization';
import { LOAD_STATES } from '../common';
import { defaultCriteriaFilter } from '../../core/criteria-filter';
import { commonEnums as enums } from '@codametrix/ui-common';
import { AmplifyCore } from '@codametrix/ui-common';
import { formErrors } from '../../stubs/form-errors';

const { paginationUtils } = AmplifyCore;

const { FilterTypes } = enums;

const userListPath: CMx.DisplayablePath = {
  displayName: 'Users',
  path: '/cmx/admin/users/'
};

const userDetailPath: CMx.DisplayablePath = {
  displayName: 'User detail',
  path: '/cmx/admin/users/:userId(\\d+)/',
  getDisplayName: (state: CMx.ApplicationState, value: number) => {
    const {
      users: { selectedUser }
    } = state;
    return selectedUser.firstName + ' ' + selectedUser.lastName;
  }
};

const userAddPath: CMx.DisplayablePath = {
  displayName: 'Add User',
  path: '/cmx/admin/users/new/'
};

export const defaultSortablePageable: CMxCommonApp.SortablePageable<CMxAPI.User> = {
  pageableDefinition: { ...paginationUtils.emptyPageable },
  sortableOptions: [
    {
      sortField: 'lastName',
      sortDirection: 'ASC'
    }
  ],
  filterableOptions: {
    filters: [{ key: 'role', terms: ['true'], type: 'IN' }]
  }
};

const usernameCriteria: CMxCommonApp.FieldDefinition = {
  label: 'Username',
  key: 'username',
  helpText: "The user's username/email address",
  placeholder: 'Username',
  required: true,
  type: FilterTypes.LIKE,
  isSelected: true
};

const lastNameCriteria: CMxCommonApp.FieldDefinition = {
  label: 'Last Name',
  key: 'lastName',
  helpText: "The user's surname",
  placeholder: 'Last Name',
  required: true,
  type: FilterTypes.LIKE,
  isSelected: true
};

export const userCriteriaFilter: AppProps.CriteriaFilter = {
  ...defaultCriteriaFilter,
  filterForms: [],
  searchForms: [usernameCriteria, lastNameCriteria],
  filters: [{ key: 'role', terms: ['true'], type: 'IN' }]
};

export const userPaths: CMx.DisplayablePath[] = [
  userListPath,
  userDetailPath,
  userAddPath
];
export const users: CMx.UserState = {
  label: 'User',
  itemName: 'User',
  users: [],
  selectedUser: USER_STUB,
  availableOrganizations: [],
  availableRoles: [],
  rootContext: CODAMETRIX,
  formErrors: formErrors.noErrors,
  passwordChangeEnabled: false,
  passwordModalOpen: false,
  passwordChangeStatus: LOAD_STATES.initial,
  filterOn: true,
  editPermission: false,
  errors: false,
  sortablePageable: defaultSortablePageable,
  criteriaFilter: userCriteriaFilter,
  items: [],
  loadState: LOAD_STATES.done,
  columnDefs: []
};
