import { commonEnums as enums } from '@codametrix/ui-common';

const { CaseDataTypes, IdentifierType, CaseDataGroup } = enums;

const Name: CMxCommonApp.FieldDefinition = {
  label: `Name`,
  key: `metadataDefinitionName`,
  helpText: 'Usually the name of the column in the Uber Spec.',
  required: true,
  type: 'text'
};

const Group: CMxCommonApp.FieldDefinition = {
  label: `Group`,
  key: `metadataGroup`,
  required: true,
  name: 'metadataGroup',
  placeholder: 'Choose a group',
  helpText: 'What object does this property belong to in the response.',
  type: 'select',
  options: [
    { label: `patient`, value: CaseDataGroup.PATIENT },
    { label: `organization`, value: CaseDataGroup.ORGANIZATION },
    { label: `encounter`, value: CaseDataGroup.ENCOUNTER },
    { label: `order`, value: CaseDataGroup.ORDER },
    { label: `billing`, value: CaseDataGroup.BILLING },
    { label: `cmx`, value: CaseDataGroup.CMX }
  ]
};

export const Service: CMxCommonApp.FieldDefinition = {
  label: `Service`,
  key: `metadataSource.id`,
  name: `metadataSource`,
  required: true,
  type: 'select',
  options: [], // EMPTY LIST, WILL BE POPULATED BY XHR/FETCH
  helpText: 'The microservice responsible for providing this data.',
  scalar: false,
  placeholder: 'Choose a service',
  labelField: 'microserviceServiceName',
  valueField: 'id'
};

const Notation: CMxCommonApp.FieldDefinition = {
  label: `Notation`,
  key: `beanNotation`,
  helpText: 'Java bean notation indicating the path to the property.',
  required: true,
  type: 'text'
};

const DataType: CMxCommonApp.FieldDefinition = {
  label: `Data Type`,
  key: `dataType`,
  name: `dataType`,
  required: false,
  helpText: 'The format of the data in the response.',
  type: 'select',
  placeholder: 'Choose a data type',
  options: [
    { label: `Date`, value: CaseDataTypes.DATE },
    { label: `Boolean`, value: CaseDataTypes.BOOLEAN },
    { label: `Identifier Issuer`, value: CaseDataTypes.IDENTIFIER_ISSUER },
    { label: `Identifier Number`, value: CaseDataTypes.IDENTIFIER_NUMBER }
  ]
};

const IdentifierTypeField: CMxCommonApp.FieldDefinition = {
  label: `Identifier`,
  key: `predicateValue`,
  name: `predicateValue`,
  required: true,
  type: 'select',
  placeholder: 'Choose an identifier',
  options: [
    { label: `MRN`, value: IdentifierType.MRN },
    { label: `Visit number`, value: IdentifierType.VISIT_NUMBER },
    { label: `Appointment number`, value: IdentifierType.APPOINTMENT_NUMBER }
  ]
};

const Automated: CMxCommonApp.FieldDefinition = {
  label: ``,
  key: `automatedFetch`,
  helpText: 'Should this information be fetched automatically.',
  required: false,
  type: 'checkbox'
};

const Description = {
  label: `Description`,
  fields: [Name, Group]
};

const Source = {
  label: `Source`,
  fields: [Service, Notation, DataType, IdentifierTypeField, Automated]
};

const PropertyName: CMxCommonApp.FieldDefinition = {
  label: `Property`,
  key: `metadataVarName`,
  required: true,
  readonly: true,
  type: 'text'
};

const RODescription = {
  label: `Description`,
  fields: [{ ...Name, readonly: true }, PropertyName, Group]
};

const Buttons = [
  { buttonType: 'submit', buttonText: 'Save' },
  { buttonType: 'link', buttonText: 'cancel' }
];

export const AddDefinitionForm = {
  fieldGroups: [Description, Source],
  buttons: Buttons
};

export const EditForm = {
  fieldGroups: [RODescription, Source],
  buttons: Buttons
};
