import { connect } from 'react-redux';
import {
  InvoiceEditor,
  InvoiceEditorProps,
  mapDispatchToInvoiceEditorProps,
  mapStateToInvoiceEditorProps
} from '@codametrix/shared-views';

export const InvoiceEditorComponent: any = connect<InvoiceEditorProps>(
  mapStateToInvoiceEditorProps,
  mapDispatchToInvoiceEditorProps
)(InvoiceEditor as any);
