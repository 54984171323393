export const mergeCases: CMx.MergeCasesState = {
  isOpen: false,
  loadingNotes: false,
  loadingMerging: false,
  loadingMergeableCases: false,
  mergingResult: false,
  activeProcessInstanceGuid: null,
  currentCaseRun: {} as CMxAPI.CaseRunV2,
  mergeableCaseRuns: [],
  activeNotes: [],
  cursorAPIContext: null
};
