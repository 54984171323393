export const noErrors = {
  status: '',
  message: null,
  errors: [],
  fieldErrors: {}
};

export const fieldLevelError = {
  status: 'CONFLICT',
  message: null,
  errors: [],
  fieldErrors: {
    name: ['Name is required']
  }
};

export const formErrors = {
  noErrors,
  fieldLevelError
};
