/// <reference path="../types.d.ts" />

import actionCreatorFactory from 'typescript-fsa';
import asyncFactory from 'typescript-fsa-redux-thunk';
import { api } from '../core/net';
import { putContext } from './contexts';
import { loading, navigateContext } from './ui';
import { ANALYTICS_ACTION_METADATA } from './action-constants';
import { cmxLocation, HttpMethods, commonActions } from '@codametrix/ui-common';
const { Eula } = commonActions;
const actionCreator = actionCreatorFactory();
const createAsync = asyncFactory<CMx.InterfaceState>(actionCreator);

const saveEula = actionCreator<CMxAPI.Eula>(Eula.SAVE_EULA);
const cancelEula = actionCreator<boolean>(
  Eula.CANCEL_EULA,
  ANALYTICS_ACTION_METADATA
);
const acceptInitial = actionCreator<CMxAPI.Eula>(
  Eula.ACCEPT_INIT,
  ANALYTICS_ACTION_METADATA
);
const getEula = async (userId: number, orgId: number) => {
  const eula = await api<CMxAPI.Eula>({
    endpoint: `/users/eula/v1?userId=${userId}&orgId=${orgId}`
  });

  return { ...eula };
};

type AcceptEulaPayload = {
  eula: CMxAPI.Eula;
  context: CMx.Context;
  homepagePreference?: CMxAPI.Preference;
};
const acceptEula = createAsync<
  AcceptEulaPayload,
  CMxAPI.User,
  CMxCommonApp.SubmitError
>(
  Eula.ACCEPT_EULA,
  async (payload, dispatch): Promise<CMxAPI.User> => {
    dispatch(acceptInitial(payload.eula));
    const user = await api<CMxAPI.User>({
      endpoint: '/users/eula/v1',
      init: {
        method: HttpMethods.POST
      },
      body: payload.eula
    });
    dispatch(saveEula(payload.eula));

    if (payload.context.activeContext?.organizationId) {
      const freshContext = await putContext(
        {
          organizationId: payload.context.activeContext?.organizationId.toString()
        },
        dispatch
      );
      cmxLocation.resetLocation();
      dispatch(loading(false));
      await dispatch(
        navigateContext({
          context: freshContext,
          homepagePreference: payload.homepagePreference
        })
      );
    } else {
      cmxLocation.resetLocation();
    }

    return user;
  }
);

export { getEula, saveEula, acceptEula, cancelEula };
