import { theme } from '@codametrix/ui-common';
import { CSSProperties } from 'react';

export const styles: Record<string, CSSProperties> = {
  select: {
    width: '100%',
    textAlign: 'left',
    color: theme.colors.text['highEmphasis'],
    fontWeight: 400,
    background: theme.colors.text['light'],
    border: `${theme.borders['1px']} ${theme.colors.stroke[200]}`,
    backgroundColor: theme.colors.text['light'],
    paddingLeft: theme.space[16],
    maxWidth: theme.space[300],
    overflow: 'hidden',
    minWidth: theme.space[56]
  }
};
