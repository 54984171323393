/// <reference path="../../../src/types.d.ts" />

export const patientDetailDictionaries: {
  [key: string]: { dictionaryName: string; columnName: string };
} = {
  billing_location: { dictionaryName: 'Facility', columnName: 'Facility' },
  patient_type: {
    dictionaryName: 'Patient Class',
    columnName: 'Patient Type'
  },
  em_category: { dictionaryName: 'EM Category', columnName: 'E/M Category' },
  scheduling_department: {
    dictionaryName: 'Department',
    columnName: ''
  },
  note_specialty_designation: {
    dictionaryName: 'Note Specialty',
    columnName: 'Note Specialty'
  }
};

export const emptyPatientEncounter: CMx.PatientEncounterState = {
  facility: [],
  patientClass: [],
  EMCategory: [],
  patientDepartment: [],
  noteSpecialty: []
};

export const caseEdits: CMx.CaseEditsState = {
  caseEdits: {},
  caseRun: undefined,
  patientEncounter: emptyPatientEncounter
};
