import theme from '../../theme';

export const styles = () => ({
  defaultText: {
    ...theme.textStyles['h3'],
    color: theme.colors.text['lowEmphasis']
  },
  activeText: {
    ...theme.textStyles['h3'],
    color: theme.colors.text['interactive']
  },
  separator: {
    ...theme.textStyles['h3'],
    color: theme.colors.foundation[300]
  }
});
