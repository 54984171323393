import { cmxReducerFactory } from './reducer-utils';
import InitialState from '../reducers/initial-state';
import * as CaseConfigActions from '../actions/case-config';
import { loadStates } from '@codametrix/ui-common';
import {
  caseConfigFieldsBySection,
  caseConfigFormLabels
} from './state/case-config/case-config';
import { CMxComponents } from '@codametrix/ui-components/src/types';
import { formErrors } from '../stubs/form-errors';

const { LOAD_STATES } = loadStates;

const reducer = cmxReducerFactory(InitialState.caseConfig)
  .case(CaseConfigActions.listCaseConfigurations.async.started, state => {
    return {
      ...state,
      loadState: LOAD_STATES.initial,
      formErrors: formErrors.noErrors
    };
  })
  .case(CaseConfigActions.listCaseConfigurations.async.failed, state => {
    return {
      ...state,
      loadState: LOAD_STATES.failed
    };
  })
  .case(
    CaseConfigActions.listCaseConfigurations.async.done,
    (state, action) => {
      return {
        ...state,
        dataObject: action.result,
        loadState: LOAD_STATES.done
      };
    }
  )
  .case(
    CaseConfigActions.saveCaseConfiguration.async.failed,
    (state, action) => {
      return {
        ...state,
        formErrors: action.error.errors
      };
    }
  )
  .case(CaseConfigActions.saveCaseConfiguration.async.done, (state, action) => {
    const key = action.result.configurationKey;
    const val = action.result.configurationValues[0].configurationValue;

    let { dataObject } = state;

    dataObject[key] = val;

    return {
      ...state,
      dataObject: dataObject
    };
  })
  .case(CaseConfigActions.syncActions.reduxSaveCaseConfigs, (state, action) => {
    return {
      ...state,
      caseConfigs: action
    };
  })
  .case(CaseConfigActions.syncActions.selectConfigSection, (state, action) => {
    const tabListItems = state.tabListItems.map(tab => {
      return {
        ...tab,
        active: tab.id === action ? true : false
      };
    });
    return {
      ...state,
      section: action,
      tabListItems
    };
  })
  .case(CaseConfigActions.loadCaseConfigOptions.async.done, (state, action) => {
    const { formDef } = state;
    const defaultFields = caseConfigFieldsBySection[action.params.section];

    const fields = defaultFields.map(field => {
      // Set the field's options to its value in its respective dictionary,
      // to its pre-assigned options (if no dictionary exists but the field is a dropdown),
      // or nothing (no dropdown)
      const options = action.result[field.key] || field.options || [];
      return {
        ...field,
        options: options
      };
    });

    const form: CMxComponents.FormDefintion = {
      fieldGroups: [
        {
          label: caseConfigFormLabels[action.params.section],
          fields
        }
      ],

      buttons: formDef.buttons
    };

    return {
      ...state,
      formDef: form
    };
  });

export default reducer;
