import React, { useEffect, useState } from 'react';
import { BreadCrumbsComponent } from '../../../components/breadcrumbs/breadcrumbsComponent';
import { Form } from '../../dictionary/form';

const username: CMxCommonApp.FieldDefinition = {
  label: `Username`,
  key: `username`,
  required: true,
  type: 'text',
  readonly: true
};

const alias: CMxCommonApp.FieldDefinition = {
  label: `Alias`,
  key: `alias`,
  required: true,
  type: 'text',
  readonly: false
};

const UserInfo: CMxCommonApp.FieldGroup = {
  label: `User Information`,
  fields: [username, alias]
};

const buttons = [
  { buttonType: 'submit', buttonText: 'Save' },
  { buttonType: 'link', buttonText: 'cancel' }
];

export const ExternalAppUserForm: React.FC<AppProps.ExternalAppUserFormProps> = props => {
  const { handleCancel, selectedUser, errors, handleSave } = props;

  const [existingUser, setExistingUser] = useState<boolean>();

  const formDefinition = () => {
    const formDef: CMxCommonApp.FormDefintion = {
      fieldGroups: [
        {
          ...UserInfo,
          fields: [{ ...username, readonly: existingUser }, alias]
        }
      ],
      buttons
    };
    return formDef;
  };
  const enableForm = () => {
    return selectedUser.username.length === 0;
  };

  useEffect(() => {
    setExistingUser(selectedUser.alias.length !== 0);
    formDefinition();
    // eslint-disable-next-line
  }, [selectedUser]);

  const header = existingUser ? `Edit ${selectedUser.username}` : `Add User`;
  return (
    <div>
      <BreadCrumbsComponent />
      <Form
        title={header}
        dataItem={selectedUser}
        enable={enableForm}
        errors={errors}
        formDefinition={formDefinition()}
        inline={false}
        onAction={handleCancel}
        onSave={handleSave}
      />
    </div>
  );
};
