import { connect } from 'react-redux';
import {
  CaseCancellation,
  CaseCancellationProps,
  mapDispatchToCaseCancellationProps,
  mapStateToCaseCancellationProps
} from '@codametrix/shared-views';

export const CaseCancellationComponent: any = connect<CaseCancellationProps>(
  mapStateToCaseCancellationProps,
  mapDispatchToCaseCancellationProps
)(CaseCancellation as any);
