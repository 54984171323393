import { Flex, Text, Grid } from '@chakra-ui/react';
import React from 'react';
import { Button } from '../../../components/button/button';
import { ModalComponent } from '../../../components/Modal/modal';
import { ButtonVariant } from '../../../core/enums';
import { styles } from '../dictionary-styles';
import './request-errors.scss';

type RequestErrorsProps = {
  errors: any;
  openModal: boolean;
  handleClose: () => void;
};

/**
 * React component that displays Dictionary Request Errors
 * @param props
 * @returns
 */
const RequestErrors: React.FC<RequestErrorsProps> = (
  props: RequestErrorsProps
) => {
  const { errors = [], openModal, handleClose } = props;

  const classes = styles();

  const requestErrors: CMxAPI.DictionaryRequestErrorDetails[] = Array.isArray(
    errors
  )
    ? errors
    : [];

  const errorMessages = requestErrors.map((error, idx: number) => {
    return (
      <li key={idx}>
        {error.errorMessage}: '{error.valueInError}' from {error.columnInError}{' '}
        in:
        <ul style={{ marginLeft: '10px' }}>
          <li>Organization: {error.mappedDictionaryOrganizationName} </li>
          {error.mappedParentDictionaryOrganizationName && (
            <li>Inherited From Codametrix</li>
          )}
          <li>Dictionary: {error.mappedDictionaryName}</li>
          <li>Column: {error.mappedDictionaryLookupColumn}</li>
        </ul>
      </li>
    );
  });

  const stringifiedErrors = requestErrors.map(error => {
    return `${error.errorMessage}: '${error.valueInError}' from ${
      error.columnInError
    } in:
            Organization: ${error.mappedDictionaryOrganizationName}
            ${error.mappedParentDictionaryOrganizationName &&
              'Inherited From Codametrix'}
            Dictionary: ${error.mappedDictionaryName}
            Column: ${error.mappedDictionaryLookupColumn}
            `;
  });

  const _handleCopyError = () => {
    navigator.clipboard.writeText(stringifiedErrors.join(''));
  };

  return (
    <div>
      <ModalComponent
        size={'xl'}
        showCloseIcon={true}
        isOpen={openModal}
        handleClose={handleClose}
        modalHeader={<Text>Dictionary Request Error</Text>}
        modalContent={
          <Grid sx={classes.errorGrid}>
            <Text sx={classes.errorText}>Error!</Text>
            <Flex
              flexDirection={'column'}
              id="error-list"
              sx={classes.errorList}>
              {errorMessages}
            </Flex>
          </Grid>
        }
        modalFooter={
          <Flex flexDirection={'row'} columnGap={3}>
            <Flex>
              <Button
                label={'Copy Error'}
                onClick={_handleCopyError}
                variant={ButtonVariant.PRIMARY}
              />
            </Flex>
          </Flex>
        }
      />
    </div>
  );
};

export default RequestErrors;
