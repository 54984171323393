/// <reference path="../types.d.ts" />

import actionCreatorFactory from 'typescript-fsa';
import asyncFactory from 'typescript-fsa-redux-thunk';
import { push, replace } from 'connected-react-router';
import { Dispatch, Action } from 'redux';
import { Amplify, Auth as CognitoAuth } from 'aws-amplify';

import { Login, Auth, UserContext } from './action-types';
import CommonActions from './common';
import {
  navigateContext,
  loading,
  setActiveUser,
  setHomepagePreference
} from './ui';
import { fetchContexts, putContext } from './contexts';
import { destroyToken } from './_action-utilities';
import { showModalMessage, logout } from './ui';
import { jumpURL } from './jump';
import { api } from '../core/net';
import { getEula, saveEula } from './eula';
import { ANALYTICS_ACTION_METADATA } from './action-constants';
import {
  authenticationContext,
  cmxLocation,
  HttpMethods,
  commonActionUtils,
  commonActions,
  cmxErrors,
  uiActions
} from '@codametrix/ui-common';
import { saveServiceLine } from './service-lines';
import {
  getDefaultServiceLine,
  getDefaultOrg,
  getHomepagePreference
} from '../core/preferences/preferences';
import { saveFormData } from './account-settings';
import { isSSOUser } from '../core/middleware/auth-middleware';

const USER_NOT_CONFIRMED = 'User is not confirmed.';

const { FormSubmissionError } = cmxErrors;
const { shimContext } = commonActionUtils;
const actionCreator = actionCreatorFactory();
const createAsync = asyncFactory(actionCreator);

const loginFailed = actionCreator<void>(Login.FAILED, { user: true });
const tokenAvailable = actionCreator<CMxCommonApp.BearerToken>(
  Auth.TOKEN_AVAILABLE
);

const toggleSSOUser = actionCreator<boolean>(Auth.TOGGLE_SSO_USER);

export const getProviderToken = async () => {
  let token: string = '';
  try {
    const response = await api<CMxAPI.ProviderDirectoryToken>({
      endpoint: `/directoryServices/apiToken/v1`,
      init: {
        method: HttpMethods.POST
      },
      body: undefined
    });
    token = response.token;
  } catch (e) {
    console.error(e);
  }
  return token;
};

const loginInitial = actionCreator<void>(
  Login.INITIAL,
  ANALYTICS_ACTION_METADATA
);

const changePassword = actionCreator<void>(Login.FORCE_PASSWORD_CHANGE);
const changePasswordInitial = actionCreator<void>(
  Login.CHANGE_PASSWORD_INITIAL
);
const changePasswordSuccess = actionCreator<void>(
  Login.CHANGE_PASSWORD_SUCCESS
);
const changePasswordFailed = actionCreator<CMxCommonApp.FormErrors | undefined>(
  Login.CHANGE_PASSWORD_FAILED
);

const displayContexts = actionCreator<CMxAPI.OrganizationContext[]>(
  UserContext.DISPLAY_CONTEXTS
);

const toggleEmailConfirmationModal = actionCreator<boolean>(
  'TOGGLE_EMAIL_CONFIRMATION_MODAL'
);

const authenticate = createAsync<
  CMx.LoginData,
  void,
  typeof FormSubmissionError
>(Login.AUTHENTICATE, async (loginData, dispatch: any) => {
  dispatch(CommonActions.inProgress());
  dispatch(loginInitial());

  try {
    let response;
    let context;

    const { cognitoConfig } = await dispatch(getGlobalConfig());

    // If Cognito enabled, check the input login against our user pool
    if (cognitoConfig.cognitoEnabled) {
      response = await dispatch(cognitoAuth({ loginData, cognitoConfig }));

      if (response) {
        if (response.status === 200) {
          const token: CMxCommonApp.BearerToken = {
            token: response.headers.get(`Authorization`) || '',
            expiryTimeToLive: null
          };
          await dispatch(tokenAvailable(token));

          // Make sure user is in a Cognito org
          context = await fetchContexts(dispatch);

          const hasCognitoOrg = context.contexts.some(ctx => {
            return ctx.cognitoEnabled;
          });

          if (!hasCognitoOrg) {
            response = undefined;
          }
        } else if (response.message === 'Invalid') {
          dispatch(loginFailed());
          return;
        }
      }
    }
    if (!response || response.status !== 200) {
      // Use our user db as an alternative if Cognito user
      // does not exist or if user is not in an org using Cognito
      const jspringReturn = await dispatch(
        jspringAuth({
          ...loginData,
          cognitoEnabled: cognitoConfig.cognitoEnabled,
          responseMessage: response?.message
        })
      );
      response = jspringReturn?.response;
      context = jspringReturn?.context;
    }
    if (!response || response.status !== 200 || !context) {
      dispatch(loginFailed());
      return;
    }

    const token: CMxCommonApp.BearerToken = {
      token: response.headers.get(`Authorization`) || '',
      expiryTimeToLive: null
    };

    const location = response.headers.get(`location`) || '';

    dispatch(toggleSSOUser(false));
    // Continue with rest of login process
    dispatch(login({ token, location, context }));
  } catch (e) {
    console.log(e);
    dispatch(loginFailed(e as any));
  }
});

type CognitoData = {
  loginData: CMx.LoginData;
  cognitoConfig: {
    cognitoEnabled: boolean;
    region?: string;
    userPoolId?: string;
    clientId?: string;
  };
};

const cognitoAuth = createAsync<CognitoData, Response | undefined, void>(
  Login.COGNITO_AUTH,
  async (cognitoData: CognitoData, dispatch: any) => {
    const { loginData, cognitoConfig } = cognitoData;
    const { username, password } = loginData;
    const { region, userPoolId, clientId } = cognitoConfig;

    let token;

    Amplify.configure({
      Auth: {
        region,
        userPoolId,
        userPoolWebClientId: clientId
      }
    });

    CognitoAuth.configure({ storage: sessionStorage });

    try {
      const user = await CognitoAuth.signIn(username, password);
      token = user.signInUserSession.idToken.jwtToken;

      if (token) {
        const response = await fetch(`/exchangeToken`, {
          method: HttpMethods.POST,
          cache: 'no-cache',
          headers: {
            'Authorization-cognito': token
          },
          referrer: 'no-referrer',
          body: ''
        });

        if (response.status !== 200) {
          dispatch(loginFailed());
          return { message: 'Invalid' };
        } else {
          return response;
        }
      }
    } catch (e) {
      return e;
    }
  }
);

const jspringAuth = createAsync<CMx.LoginData, Response | undefined, void>(
  Login.JSPRING_AUTH,
  async (loginData: CMx.LoginData, dispatch: any) => {
    const { username, password, cognitoEnabled, responseMessage } = loginData;

    // using native fetch here instead of core/net.api
    // in this case, we're not authenticated
    const formData = new URLSearchParams({ username, password } as Record<
      string,
      string
    >);
    const response = await fetch(`j_spring_security_check`, {
      method: HttpMethods.POST,
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      referrer: 'no-referrer',
      body: formData.toString()
    });

    const { status } = response;

    if (status === 200) {
      const token: CMxCommonApp.BearerToken = {
        token: response.headers.get(`Authorization`) || '',
        expiryTimeToLive: null
      };
      dispatch(tokenAvailable(token));

      const context = await fetchContexts(dispatch);

      const hasCognitoOrg = context.contexts.some(ctx => {
        return ctx.cognitoEnabled;
      });

      // If user is in our db but belongs to an org using Cognito,
      // they must be signed up for our user pool and log in through that
      if (cognitoEnabled && hasCognitoOrg) {
        if (responseMessage !== USER_NOT_CONFIRMED) {
          const user = await dispatch(migrate(loginData));

          if (user) {
            dispatch(toggleEmailConfirmationModal(true));
          } else {
            dispatch(loginFailed());
            return;
          }
        } else {
          try {
            await CognitoAuth.resendSignUp(username);
            dispatch(toggleEmailConfirmationModal(true));
            return;
          } catch (err) {
            console.log('error resending code: ', err);
          }
        }
      } else {
        return { response, context };
      }
    }
  }
);

type loginInfo = {
  token?: CMxCommonApp.BearerToken;
  location?: string;
  context?: CMxAPI.ContextAPI;
};

const login = createAsync<loginInfo, void, typeof FormSubmissionError>(
  Login.LOGIN,
  async (loginInfo, dispatch: any) => {
    const { token, location } = loginInfo;
    let { context } = loginInfo;

    if (!token) {
      dispatch(loginFailed());
    } else {
      dispatch(tokenAvailable(token));

      if (!context?.user.id) {
        context = await fetchContexts(dispatch);
      }

      if (location === '/update-password/') {
        //if location indicates that a password update is required then we will navigate
        //to that page rather than making further api calls
        dispatch(changePassword());
        dispatch(push(`/update-password/`));
        return;
      }

      const userDefaults = await api<CMxAPI.Preference[]>({
        endpoint: `/userpreferences/${context.user.id}/v1`
      });
      const defaultOrgId = getDefaultOrg(userDefaults);
      const defaultServiceLine = getDefaultServiceLine(userDefaults);
      const homepagePreference = getHomepagePreference(userDefaults);
      dispatch(setHomepagePreference(homepagePreference));

      const shellHomepage = { path: '', tenantId: '' };

      await dispatch(
        saveFormData({
          defaultOrg: defaultOrgId,
          defaultServiceLine,
          defaultHomepage: shellHomepage
        })
      );
      const defaultContext = context.contexts.find(
        ctx => ctx.organizationId === defaultOrgId
      );

      if (defaultContext && token.token) {
        const jumpArgs = {
          organizationId: defaultContext.organizationId.toString(),
          token: token.token
        };
        context = await putContext(jumpArgs, dispatch);
      }

      dispatch(setActiveUser(context.user));
      const ctx = shimContext(context);
      if (defaultContext && token.token) {
        ctx.activeContext = defaultContext;
      }

      const jumped = await jumpURL(ctx, dispatch);

      if (!jumped) {
        // if there multiple contexts then we'll want to display the choice to the user
        // there may already be an activeContext, but on login we display the choice anyways.
        return dispatch(
          postAuthNavigate({
            ctx,
            defaultContext,
            defaultServiceLine,
            homepagePreference
          })
        );
      }
    }
  }
);

const migrate = createAsync<CMx.LoginData, any, void>(
  'MIGRATE',
  async (loginData: CMx.LoginData, dispatch: any) => {
    const { username, password } = loginData;

    let user;

    const response = await CognitoAuth.signUp({
      username: username,
      password: password,
      attributes: {
        email: username // optional
      },
      autoSignIn: {
        enabled: true
      }
    });
    user = response.user;

    return user;
  }
);

type ConfirmationData = {
  username: string;
  confirmationCode: string;
  token?: CMxCommonApp.BearerToken;
  context: CMx.Context;
};

const confirmEmail = createAsync<
  ConfirmationData,
  void,
  typeof FormSubmissionError
>(
  Login.CONFIRM_EMAIL,
  async (confirmData, dispatch): Promise<void> => {
    const { username, confirmationCode, token, context } = confirmData;

    try {
      await CognitoAuth.confirmSignUp(username, confirmationCode);

      dispatch(toggleEmailConfirmationModal(false));
      dispatch(login({ token, location: '', context }));
    } catch (error) {
      console.log('error confirming sign up', error);
    }
  }
);

const getConfig = async () => {
  let response = await fetch(`/user/config/global/v1`, {
    method: HttpMethods.GET,
    cache: 'no-cache',
    referrer: 'no-referrer'
  });

  const responseObj: CMx.ConfigData = await response.json();

  return responseObj;
};

const getGlobalConfig = createAsync<
  void,
  CMx.ConfigData,
  typeof FormSubmissionError
>(Login.GET_CONFIG, async () => {
  const responseObj = await getConfig();

  const { cognitoConfig } = responseObj;

  if (cognitoConfig.cognitoEnabled) {
    const { region, userPoolId, clientId } = cognitoConfig;

    Amplify.configure({
      Auth: {
        region,
        userPoolId,
        userPoolWebClientId: clientId
      }
    });
  }
  return responseObj;
});

type LogoutArgs = {
  showModal: boolean;
  orgCode?: string;
};

const logoutApp = createAsync<LogoutArgs, any, typeof FormSubmissionError>(
  Login.LOGOUT,
  async (logoutData: CMx.LogoutData, dispatch: Dispatch<Action>) => {
    await destroyToken(authenticationContext.getToken());
    sessionStorage.clear();
    authenticationContext.setToken('');

    const navigateToHome = async (
      event: React.MouseEvent | React.FormEvent | CustomEvent
    ) => {
      event.preventDefault();
      dispatch(logout());
      if (isSSOUser && logoutData.orgCode?.length) {
        dispatch(replace(`/sso/${logoutData.orgCode}`));
      } else {
        dispatch(push(`/`));
      }
    };

    if (logoutData.showModal) {
      dispatch(
        showModalMessage({
          title: 'Successful Logout',
          buttonText: 'Log in',
          closable: false,
          forceSubmitOptions: {
            isForced: true,
            message: 'Navigating to login page in ',
            time: 3
          },
          handler: commonActions.UserInterface.LOGOUT,
          modalParams: {
            orgCode: logoutData.orgCode,
            isSSOUser
          }
        })
      );
    } else {
      navigateToHome(new CustomEvent('no-op'));
    }

    return;
  }
);

const submitNewPassword = createAsync<
  CMx.NewPasswordData,
  void,
  typeof FormSubmissionError
>(
  Login.CHANGE_PASSWORD,
  async (passwords, dispatch): Promise<void> => {
    dispatch(changePasswordInitial());
    try {
      const token = authenticationContext.getToken();
      // using fetch because the auth token is returned in the header
      const response = await fetch(`/users/changePassword/v1`, {
        method: HttpMethods.POST,
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token ?? ''
        },
        referrer: 'no-referrer',
        body: JSON.stringify(passwords)
      });

      const { status, headers } = response;

      if (status !== 200) {
        const responseBody = await response.json();
        if (responseBody.status === 'CONFLICT') {
          dispatch(changePasswordFailed(responseBody));
        } else {
          dispatch(logoutApp({ showModal: false }));
          dispatch(changePasswordFailed(undefined));
        }
      } else {
        dispatch(changePasswordSuccess());

        const token: CMxCommonApp.BearerToken = {
          token: headers.get(`authorization`) || '',
          expiryTimeToLive: null
        };

        dispatch(tokenAvailable(token));
      }
    } catch (res) {
      dispatch(changePasswordFailed(undefined));
    }
  }
);

const handleContext = createAsync<void, void, typeof FormSubmissionError>(
  'handle_context',
  async (arg, dispatch: any) => {
    const context = await fetchContexts(dispatch);
    dispatch(setActiveUser(context.user));
    const ctx = shimContext(context);

    const userDefaults = await api<CMxAPI.Preference[]>({
      endpoint: `/userpreferences/${context.user.id}/v1`
    });
    const defaultOrgId = getDefaultOrg(userDefaults);
    const defaultServiceLine = getDefaultServiceLine(userDefaults);
    const homepagePreference = getHomepagePreference(userDefaults);
    dispatch(setHomepagePreference(homepagePreference));

    const defaultContext = context.contexts.find(
      ctx => ctx.organizationId === defaultOrgId
    );

    const jumped = await jumpURL(ctx, dispatch);

    if (!jumped) {
      return dispatch(
        postAuthNavigate({
          ctx,
          defaultContext,
          defaultServiceLine,
          homepagePreference
        })
      );
    }
  }
);

type jumpArgs = {
  ctx: CMx.Context;
  defaultContext?: CMxAPI.OrganizationContext;
  defaultServiceLine?: number;
  homepagePreference?: CMxAPI.Preference;
};

const postAuthNavigate = createAsync<
  jumpArgs,
  void,
  typeof FormSubmissionError
>('post_auth_navigate', async (args, dispatch: any) => {
  const { ctx, defaultContext, defaultServiceLine, homepagePreference } = args;

  const { contexts } = ctx;

  const hasMultipleContexts = contexts.length > 1;

  const selectContext = () => {
    dispatch(loading(false));
    dispatch(uiActions.chooseContext(ctx));
  };

  if (contexts.length === 1 || defaultContext) {
    selectContext();
    const serviceLines = defaultContext
      ? defaultContext.serviceLines
      : contexts[0].serviceLines;
    dispatch(saveServiceLine(serviceLines[0]));

    if (defaultServiceLine) {
      const sl = ctx.activeContext?.serviceLines.find(
        sl => sl.id === defaultServiceLine
      );
      if (sl) {
        dispatch(saveServiceLine(sl));
      }
    } else if (serviceLines.length > 1) {
      dispatch(push('/service-lines/'));
      return;
    }
    const location = cmxLocation.getLocation();
    if (location === '/eula/') {
      const eulaUpToDate = await getEula(
        ctx.user.id || -1,
        ctx.activeContext?.organizationId || -1
      );
      dispatch(saveEula(eulaUpToDate));
      dispatch(push(location));

      return;
    }

    dispatch(navigateContext({ context: ctx, homepagePreference }));
  } else if (hasMultipleContexts) {
    dispatch(displayContexts(ctx.contexts));
    dispatch(push(`/contexts/`));
    return;
  }
});

type SSOInput = {
  orgCode: string;
};

const loginViaSSO = createAsync<SSOInput, void, typeof FormSubmissionError>(
  Login.SSO_LOGIN,
  async (ssoItems, dispatch: any) => {
    const { orgCode } = ssoItems;

    const orgData = await fetch(`/organization/by-code/${orgCode}/v1`, {
      method: HttpMethods.GET,
      cache: 'no-cache',
      referrer: 'no-referrer'
    });

    const { ssoIndicator, idpProviderName } = await orgData.json();

    const { cognitoConfig } = await dispatch(getGlobalConfig());
    let { cognitoDomain, redirectUri } = cognitoConfig;
    // Amplify.configure adds "https//" so we need to remove it from domain to avoid duplicate
    cognitoDomain =
      cognitoDomain && cognitoDomain.split(':').length > 1
        ? cognitoDomain.split(':')[1]
        : cognitoDomain;

    if (ssoIndicator && idpProviderName) {
      Amplify.configure({
        oauth: {
          domain: cognitoDomain,
          redirectSignIn: redirectUri,
          responseType: 'TOKEN',
          scope: ['email', 'openid']
        }
      });

      try {
        CognitoAuth.federatedSignIn({ customProvider: idpProviderName });
        return;
      } catch (e) {
        dispatch(loginFailed());
      }
    } else {
      dispatch(loginFailed());
    }
  }
);

const exchangeTokenSso = createAsync<string, void, typeof FormSubmissionError>(
  Login.EXCHANGE_TOKEN_SSO,
  async (idToken, dispatch: any) => {
    const response = await fetch(`/exchangeTokenSso`, {
      method: HttpMethods.POST,
      headers: {
        'Authorization-cognito': idToken
      },
      cache: 'no-cache',
      referrer: 'no-referrer',
      body: ''
    });

    const { status, headers } = response;

    if (status !== 200) {
      const { message } = await response.json();
      dispatch(loginFailed(message));
    } else {
      const token: CMxCommonApp.BearerToken = {
        token: headers.get(`Authorization`) || '',
        expiryTimeToLive: null
      };

      dispatch(toggleSSOUser(true));
      dispatch(login({ token }));
    }
  }
);

export const syncActions = {
  loginFailed,
  loginSuccess: tokenAvailable,
  loginInitial,
  displayContexts,
  changePassword,
  changePasswordInitial,
  changePasswordSuccess,
  changePasswordFailed
};

export {
  authenticate,
  login,
  logoutApp,
  submitNewPassword,
  handleContext,
  confirmEmail,
  toggleEmailConfirmationModal,
  getConfig,
  getGlobalConfig,
  loginViaSSO,
  exchangeTokenSso,
  postAuthNavigate
};
