import React from 'react';
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { styles } from './select-styles';
import theme from '../../theme';

export type MenuItemType = {
  label: string | null | number;
  value: string | number;
  disabled?: boolean;
};

export type SelectProps = {
  items?: MenuItemType[];
  value?: string;
  name?: string;
  placeholder?: string;
  onChange?: (newValue: any) => void;
  classes?: any;
  dataTestId?: string;
  disabled?: boolean;
  isMenuButton?: boolean;
};

export const Select = (props: SelectProps) => {
  const generateDisplayedValue = (value: string) => {
    const selectedItem = props?.items?.find(item => value === item.value);
    if (selectedItem) {
      return selectedItem.label;
    }
    if (value.length > 35) {
      let result = value.substring(0, 30) + '...';
      return result;
    }
    return value;
  };

  return (
    <Menu autoSelect={false}>
      <MenuButton
        as={Button}
        disabled={props.disabled}
        sx={
          props.classes?.button ??
          (props.value ? styles.buttonSelected : styles.button)
        }
        rightIcon={<ChevronDownIcon sx={props.classes?.icon ?? styles.icon} />}
        data-testid={props.dataTestId?.trim() ?? 'dropdown'}
        _focus={{ boxShadow: 'none' }}
        _expanded={{
          bg: props.isMenuButton
            ? theme.colors.accent[100]
            : theme.colors.text['light'],
          border: props.isMenuButton
            ? `${theme.borders['1px']} ${theme.colors.accent[100]}`
            : `${theme.borders['1px']} ${theme.colors.foundation[500]}`
        }}
        _hover={{
          border: props.isMenuButton
            ? `${theme.borders['1px']} ${theme.colors.accent[100]}`
            : `${theme.borders['1px']} ${theme.colors.foundation[400]}`,
          bg: props.isMenuButton
            ? theme.colors.accent[100]
            : theme.colors.text['light']
        }}>
        {generateDisplayedValue(props?.value ?? '') || props.placeholder}
      </MenuButton>
      <MenuList sx={styles.list} style={{ overflow: 'auto' }}>
        {props?.items?.map((item, key) => (
          <MenuItem
            sx={
              item?.value === props.value || item.label === props.value
                ? styles.itemSelected
                : styles.item
            }
            key={key}
            value={item?.value}
            name={props.name}
            onClick={props?.onChange}
            data-testid={item?.value?.toString().trim()}>
            {item?.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

Select.defaultProps = {
  data: [],
  value: '',
  placeholder: 'Select',
  name: ''
};

Select.displayName = 'Select';
