import { buildDispatchable } from '../../actions/_action-utilities';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import ForgotPasswordForm from './forgot-password-form';
import {
  handleForgotPassword,
  initializeForgotPassword
} from '../../actions/forgot-password';

const mapStateToProps = (props: CMx.ApplicationState) => {
  const { forgotPassword } = props;
  return {
    ...forgotPassword
  };
};

const mapDispatchToProps = (dispatch: any) => {
  const runDispatchable = buildDispatchable(dispatch);

  return {
    handleUsernameSubmission: async (username: string) => {
      runDispatchable(() => {
        return dispatch(handleForgotPassword(username));
      });
    },

    handleCancel: () => {
      runDispatchable(() => {
        return dispatch(push('/'));
      });
    },

    initializeForgotPassword: () => {
      runDispatchable(() => {
        return dispatch(initializeForgotPassword());
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm);
