import { USER_STUB } from '../../tests/fixtures/stubs/user';

// URL Parameters
const url = new URL(window.location.href);
const params = new URLSearchParams(url.search);

const context: CMx.Context = {
  activeContext: null,
  contexts: [],
  jwtToken: '',
  expiryTimeToLive: 600000,
  expiryDuration: 600000,
  user: USER_STUB,
  activeContextId: null
};

const InitialPHIMask = {
  available: false,
  active: false
};

export const emptyPreference: CMxAPI.Preference = Object.freeze({
  id: -1,
  version: -1,
  section: '',
  keyname: '',
  ownerId: -1,
  values: [],
  firstValue: ''
});

export const ui: CMx.InterfaceState = {
  roles: [],
  context,
  shortcuts: [],
  phiMask: InitialPHIMask,
  activeUser: USER_STUB,
  contexts: [],
  /**
   * In the case of a new window being launched, a token will be transferred to the new window.
   * Until this token is transferred, the new window should not attempt to mount components.
   */
  awaitingTokenTransfer: params.get('channel') ? true : false,
  actionGroups: [],
  loading: true,
  AdminTabs: [],
  propertyConfig: {
    patient: [],
    summary: [],
    expanded: []
  },
  config: {
    mixpanelApiKey: undefined,
    isNewDesign: false,
    apiProxyServiceEnabled: false
  },
  serviceLine: undefined,
  hasAmplifyCaseRunV2Access: false
};
