import { CSSProperties } from 'react';
import theme from '../../../theme';

export const styles = (): Record<string, CSSProperties> => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding:
      theme.space[20] +
      ' ' +
      theme.space[36] +
      ' ' +
      theme.space[36] +
      ' ' +
      theme.space[36],
    backgroundColor: theme.colors.structural[100],
    minHeight: 'calc(100vh - 15px)',
    height: '100%'
  },
  text: {
    ...theme.textStyles['h3'],
    color: theme.colors.text['highEmphasis'],
    paddingBottom: theme.space[20]
  },
  content: {
    backgroundColor: theme.colors.structural[0],
    width: 'inherit',
    border: `0 solid ${theme.colors.structural[0]}`,
    borderRadius: theme.radii[3],
    boxShadow:
      '0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)',
    minHeight: 'calc(100vh - 150px)',
    height: '100%',
    padding: theme.space[20]
  },
  divider: {
    borderColor: theme.colors.stroke[200]
  }
});
