import { buildDispatchable } from '../../actions/_action-utilities';
import externalApplicationActions, {
  syncActions
} from '../../actions/external-applications';
import { push } from 'connected-react-router';

type ExternalApplicationListDispatches = Pick<
  AppProps.ExternalApplicationListProps,
  'list' | 'onSelection'
>;

type ExternalApplicationListProps = Pick<
  AppProps.ExternalApplicationListProps,
  'sortablePageable' | 'items' | 'orgId'
>;

export const mapStateToProps = function(
  state: CMx.ApplicationState
): ExternalApplicationListProps {
  const { externalApplications } = state;

  const items =
    externalApplications.externalApplicationList.sortablePageable
      .pageableDefinition.content;

  const orgId = externalApplications.selectedOrgId || -1;
  return {
    ...externalApplications.externalApplicationList,
    items,
    orgId
  };
};

export const mapDispatchToProps = (
  dispatch: any
): ExternalApplicationListDispatches => {
  const runDispatchable = buildDispatchable(dispatch);

  return {
    list: (payload: AppProps.listParams) => {
      runDispatchable(() => {
        return dispatch(externalApplicationActions.listExternalApps(payload));
      });
    },

    onSelection: (item: CMxAPI.ExternalApplication, path: string) => {
      runDispatchable(() => {
        dispatch(syncActions.selectExternalApplication(item));
        return dispatch(push(`${path}/${item.externalApplicationName}`));
      });
    }
  };
};
