import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Flex, Text } from '@chakra-ui/react';
import { ModalComponent } from '../../../../components/Modal/modal';
import { Button } from '../../../../components/button/button';
import { togglePublishModal } from '../../../../actions/dictionary-values';
import Input from '../../../../components/input/input';
import { InputVariant } from '../../../../core/enums';
import './publish-modal.scss';

export type publishModalProps = {
  publish: (dictionaries: CMxAPI.Dictionary[], jiraTicket: string) => void;
  associatedDictionaries: CMxAPI.Dictionary[];
};

export const PublishModal: React.FC<publishModalProps> = props => {
  const { publish, associatedDictionaries } = props;

  const [jiraTicket, setJiraTicket] = useState<string>('');
  const [publishDisabled, setPublishDisabled] = useState<boolean>(true);
  const [jiraTicketError, setJiraTicketError] = useState<boolean>(false);
  const jiraTicketRegex = /^[a-zA-Z]+-\d+$/;

  useEffect(() => {
    if (jiraTicket.length >= 5 && jiraTicket.length <= 20) {
      setPublishDisabled(false);
    } else {
      setPublishDisabled(true);
    }
  }, [jiraTicket]);

  const dispatch = useDispatch();

  const _handleClose = () => {
    setJiraTicketError(false);
    dispatch(togglePublishModal(false));
  };

  const _handlePublish = () => {
    setJiraTicketError(false);
    if (jiraTicketRegex.test(jiraTicket)) {
      publish(associatedDictionaries, jiraTicket);
      dispatch(togglePublishModal(false));
    } else {
      setJiraTicketError(true);
    }
  };

  return (
    <ModalComponent
      size={'xl'}
      isOpen={true}
      handleClose={_handleClose}
      modalHeader={<Text>Confirm Dictionary Publish</Text>}
      modalContent={
        <div>
          <Text>Enter Jira Ticket associated with this change.</Text>
          {jiraTicketError ? (
            <Text className={'jiraTicketError'}>
              Please enter a valid Jira Ticket (e.g. PROJECT-XXX)
            </Text>
          ) : (
            ''
          )}
          <Input
            className={'jiraTicketField'}
            autoFocus
            variant={InputVariant.TEXT}
            label={''}
            placeholder={'Ticket #'}
            onChange={e => setJiraTicket(e.target.value)}
            dataTestId={'jiraTicketInput'}
          />
        </div>
      }
      modalFooter={
        <Flex columnGap={'8px'}>
          <Button
            label={'Cancel'}
            onClick={_handleClose}
            name={'Cancel'}
            dataTestId={'Cancel'}
          />{' '}
          <Button
            label={'Publish'}
            dataTestId={'publishBtn'}
            name={'Publish'}
            disabled={publishDisabled}
            onClick={() => _handlePublish()}
          />
        </Flex>
      }
    />
  );
};
