import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Divider,
  Flex,
  IconButton,
  Input,
  Select,
  Text
} from '@chakra-ui/react';
import { WarningTwoIcon } from '@chakra-ui/icons';

import { toggleIsOpenCapturedTime } from '../../actions/captured-time';
import { ModalComponent } from '../../components/Modal/modal';
import { styles } from './captured-time.styles';
import {
  getDateOfGivenDayInWeek,
  formatDateStringSlashed
} from '../utils/time-entry-utils';
import { ReactComponent as ArrowLeftIcon } from '../../assets/images/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '../../assets/images/arrow-right.svg';
import { ProtectedTimeList } from './protected-time-list';

const CapturedTime: React.FC<AppProps.CapturedTimeProps> = props => {
  const {
    listCapturedTime,
    listProtectedTime,
    deleteProtectedTimeEntry,
    updateProtectedTimeEntry,
    addProtectedTimeEntry,
    getMaxAllowedBackdate,
    listStatus,
    user,
    context
  } = props;
  const dispatch = useDispatch();
  const classes = styles();

  const dayOfWeekOrder = Object.freeze([
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday'
  ]);

  let {
    capturedTimeWeek,
    protectedTimeWeek,
    maxAllowedBackdate,
    noPastCapturedTimeFlag,
    needRefreshFlag,
    addTimeFailed
  } = useSelector((state: CMx.ApplicationState) => state.capturedTime);

  let { statusList } = useSelector(
    (state: CMx.ApplicationState) => state.myStatus
  );

  const [weeklyTotalHours, setWeeklyTotalHours] = useState(
    capturedTimeWeek.reduce((sum, current) => sum + current.hoursWorked, 0)
  );

  const [selectedDay, setSelectedDay] = useState(
    capturedTimeWeek.find(
      capturedTimeDay =>
        capturedTimeDay.day.toLowerCase() ===
        dayOfWeekOrder[new Date().getDay()]
    )!
  );

  const [addTimeEntryFailed, setAddTimeEntryFailed] = useState(false);

  const [selectedDayOfWeek, setSelectedDayOfWeek] = useState(selectedDay?.day);

  const [weeksAgo, setWeeksAgo] = useState(0);

  const [backdateRemainder, setBackdateRemainder] = useState(
    +maxAllowedBackdate
  );

  const [protectedTimeWeekCurrent, setProtectedTimeWeekCurrent] = useState(
    protectedTimeWeek
  );

  const buildProtectedTimeDayDict = useCallback(() => {
    // build and sort dictionary of protectedTime events for current selectedDay
    const updateDict: { [id: string]: CMxAPI.ProtectedTime } = {};
    // console.log(protectedTimeWeekCurrent)
    // console.log(selectedDay)
    // console.log(selectedDayOfWeek)
    const currentDay = protectedTimeWeekCurrent
      ?.filter(
        protectedTimeEntry =>
          protectedTimeEntry.day.toLowerCase() ===
          selectedDayOfWeek.toLowerCase()
      )
      .sort((entry1, entry2) =>
        new Date(entry1.startTime).getTime() >=
        new Date(entry2.startTime).getTime()
          ? 1
          : -1
      );

    for (const protectedTime of currentDay) {
      updateDict[protectedTime.workLogId] = protectedTime;
    }
    return updateDict;
  }, [protectedTimeWeekCurrent, selectedDayOfWeek]);

  const [protectedTimeDayDict, setProtectedTimeDayDict] = useState(
    buildProtectedTimeDayDict()
  );

  const [disableBackWeek, setDisableBackWeek] = useState(
    noPastCapturedTimeFlag
  );

  const [newProtectedTime, setNewProtectedTime] = useState({
    workLogId: '',
    userUid: user.userId!.toString(),
    tenantId: context.activeContext!.tenantId.toString(),
    name: user.firstName + ' ' + user.lastName,
    day: '',
    startTime: '',
    endTime: '',
    duration: 0,
    reason: ''
  });

  const [dayEditDisabled, setDayEditDisabled] = useState<{
    [key: string]: boolean;
  }>({
    sunday: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false
  });

  const fetchTimeForWeek = useCallback(
    (previousSunday: Date, nextSaturday: Date) => {
      const fetchCapturedTimeList = async () => {
        await listCapturedTime(previousSunday, nextSaturday, user, context);
      };
      // Adding one day to protectedTime call endTime in order to capture all entries on Saturday
      const fetchProtectedTimeList = async () => {
        await listProtectedTime(
          previousSunday,
          new Date(nextSaturday.getTime() + 24 * 60 * 60 * 1000),
          user,
          context
        );
      };
      fetchCapturedTimeList();
      fetchProtectedTimeList();
    },
    [context, user, listCapturedTime, listProtectedTime]
  );

  const refreshProtectedTime = useCallback(() => {
    const searchDay =
      capturedTimeWeek.find(day => day.day.toLowerCase() === 'wednesday') ??
      selectedDay;
    const sunday = getDateOfGivenDayInWeek(
      new Date(searchDay.dateOfEntry),
      'Sunday'
    );
    const saturday = getDateOfGivenDayInWeek(
      new Date(searchDay.dateOfEntry),
      'Saturday'
    );

    fetchTimeForWeek(sunday, saturday);
  }, [capturedTimeWeek, selectedDay, fetchTimeForWeek]);

  useEffect(() => {
    setProtectedTimeDayDict(buildProtectedTimeDayDict());
  }, [selectedDay, protectedTimeWeekCurrent, buildProtectedTimeDayDict]);

  useEffect(() => {
    setAddTimeEntryFailed(addTimeFailed);
  }, [addTimeFailed, setAddTimeEntryFailed]);

  useEffect(() => {
    if (needRefreshFlag) {
      refreshProtectedTime();
    }
  }, [needRefreshFlag, refreshProtectedTime]);

  useEffect(() => {
    setProtectedTimeWeekCurrent(protectedTimeWeek);
  }, [protectedTimeWeek]);

  useEffect(() => {
    // disable editing for days outside the range of the configured backdate maximum
    let backdateCounter = 7 - backdateRemainder;
    let newDayEditDisabled = dayEditDisabled;
    if (backdateRemainder < 7) {
      Object.keys(dayEditDisabled).forEach(day => {
        if (backdateCounter > 0) {
          newDayEditDisabled[day] = true;
          backdateCounter--;
        } else if (backdateCounter <= 0) {
          newDayEditDisabled[day] = false;
        }
        if (backdateRemainder <= 0) {
          newDayEditDisabled[day] = true;
        }
      });
    } else {
      Object.keys(dayEditDisabled).forEach(day => {
        newDayEditDisabled[day] = false;
      });
    }
    setDayEditDisabled(newDayEditDisabled);
  }, [backdateRemainder, dayEditDisabled]);

  useEffect(() => {
    // if no captured time exists for previous week, disable back button, return to last week
    if (noPastCapturedTimeFlag === true) {
      const sunday = getDateOfGivenDayInWeek(
        new Date(new Date(selectedDay.dateOfEntry).getTime()),
        'Sunday'
      );
      const saturday = getDateOfGivenDayInWeek(
        new Date(new Date(selectedDay.dateOfEntry).getTime()),
        'Saturday'
      );

      fetchTimeForWeek(sunday, saturday);
      if (weeksAgo > 0) {
        setWeeksAgo(weeksAgo - 1);
        setBackdateRemainder(backdateRemainder + 7);
      }
      setDisableBackWeek(true);
    }
    // eslint-disable-next-line
  }, [noPastCapturedTimeFlag]);

  useEffect(() => {
    const previousSunday = getDateOfGivenDayInWeek(new Date(), 'Sunday');
    const nextSaturday = getDateOfGivenDayInWeek(new Date(), 'Saturday');

    fetchTimeForWeek(previousSunday, nextSaturday);
    getMaxAllowedBackdate('max_allowed_days', context.activeContext?.tenantId!);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchStatusList = async () => {
      await listStatus(context?.activeContext?.tenantId!);
    };
    fetchStatusList();
  }, [listStatus, context]);

  useEffect(() => {
    selectedDay?.dateOfEntry.length > 0
      ? setSelectedDay(
          capturedTimeWeek.find(
            capturedTimeDay =>
              capturedTimeDay.day.toLowerCase() ===
              selectedDay?.day.toLowerCase()
          )!
        )
      : setSelectedDay(
          capturedTimeWeek.find(
            capturedTimeDay =>
              capturedTimeDay.day.toLowerCase() ===
              dayOfWeekOrder[new Date().getDay()]
          )!
        );
    // eslint-disable-next-line
  }, [capturedTimeWeek, dayOfWeekOrder]);

  useEffect(() => {
    setWeeklyTotalHours(
      capturedTimeWeek.reduce((sum, current) => sum + current.hoursWorked, 0)
    );
  }, [capturedTimeWeek, protectedTimeWeek]);

  useEffect(() => {
    // set dates if captured time is not yet entered for week
    capturedTimeWeek.forEach(capturedTimeDay =>
      capturedTimeDay.dateOfEntry.length === 0
        ? (capturedTimeDay.dateOfEntry = getDateOfGivenDayInWeek(
            new Date(),
            capturedTimeDay.day
          )
            .toISOString()
            .split('T')[0])
        : capturedTimeDay.dateOfEntry
    );
  }, [capturedTimeWeek]);

  useEffect(() => {
    setSelectedDayOfWeek(selectedDay?.day);
  }, [selectedDay]);

  const handleClose = () => {
    dispatch(toggleIsOpenCapturedTime(false));
    setAddTimeEntryFailed(false);
  };

  const handleDayClick = (day: CMxAPI.NormalTimeDay) => {
    setSelectedDay(day);
  };

  const handleWeekBackClick = () => {
    const searchDay =
      capturedTimeWeek.find(day => day.day.toLowerCase() === 'wednesday') ??
      selectedDay;
    const sunday = getDateOfGivenDayInWeek(
      new Date(
        new Date(searchDay.dateOfEntry).getTime() - 7 * 24 * 60 * 60 * 1000
      ),
      'Sunday'
    );
    const saturday = getDateOfGivenDayInWeek(
      new Date(
        new Date(searchDay.dateOfEntry).getTime() - 7 * 24 * 60 * 60 * 1000
      ),
      'Saturday'
    );

    fetchTimeForWeek(sunday, saturday);
    setWeeksAgo(weeksAgo + 1);
    setBackdateRemainder(backdateRemainder - 7);
  };

  const handleWeekForwardClick = () => {
    const searchDay =
      capturedTimeWeek.find(day => day.day.toLowerCase() === 'wednesday') ??
      selectedDay;
    const sunday = getDateOfGivenDayInWeek(
      new Date(
        new Date(searchDay.dateOfEntry).getTime() + 7 * 24 * 60 * 60 * 1000
      ),
      'Sunday'
    );
    const saturday = getDateOfGivenDayInWeek(
      new Date(
        new Date(searchDay.dateOfEntry).getTime() + 7 * 24 * 60 * 60 * 1000
      ),
      'Saturday'
    );

    fetchTimeForWeek(sunday, saturday);
    setWeeksAgo(weeksAgo - 1);
    setBackdateRemainder(backdateRemainder + 7);
    setDisableBackWeek(false);
  };

  const handleUpdateProtectedTime = (
    protectedTime: CMxAPI.ProtectedTime,
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { target } = event;

    const updatedProtectedTimeEntry: CMxAPI.ProtectedTime = {
      ...protectedTime
    };

    if (target.id === 'startTime') {
      const updatedStartTime = new Date(
        selectedDay?.dateOfEntry + 'T12:00:00Z'
      );
      updatedStartTime.setHours(
        +target.value.substring(0, 2),
        +target.value.substring(3, 5)
      );
      updatedProtectedTimeEntry.startTime =
        updatedStartTime.toISOString().substring(0, 19) + 'Z';
    } else if (target.id === 'reason') {
      updatedProtectedTimeEntry.reason = target.value;
    }

    if (
      updatedProtectedTimeEntry.duration === null &&
      updatedProtectedTimeEntry.endTime === null
    ) {
      updatedProtectedTimeEntry.duration = 0;
    }

    setProtectedTimeDayDict({
      ...protectedTimeDayDict,
      [protectedTime.workLogId]: updatedProtectedTimeEntry
    });

    updateProtectedTimeEntry(
      protectedTime.workLogId.toString(),
      updatedProtectedTimeEntry
    );
  };

  const handleDeleteProtectedTime = (protectedTime: CMxAPI.ProtectedTime) => {
    deleteProtectedTimeEntry(protectedTime.workLogId);
  };

  const handleUpdateNewProtectedTimeEntry = (
    event:
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLInputElement>
  ) => {
    const { target } = event;

    const updatedNewProtectedTimeEntry: CMxAPI.ProtectedTime = {
      ...newProtectedTime
    };

    target.id === 'newStartTime'
      ? (updatedNewProtectedTimeEntry.startTime = target.value)
      : target.id === 'newReason'
      ? (updatedNewProtectedTimeEntry.reason = target.value)
      : (updatedNewProtectedTimeEntry.duration = +target.value);

    setNewProtectedTime(updatedNewProtectedTimeEntry);
  };

  const handleAddProtectedTime = () => {
    const newStartTime = new Date(selectedDay?.dateOfEntry + 'T12:00:00Z');
    newStartTime.setHours(
      +newProtectedTime.startTime.substring(0, 2),
      +newProtectedTime.startTime.substring(3, 5)
    );
    const protectedTimeToAdd: CMxAPI.ProtectedTime = {
      ...newProtectedTime,
      day: selectedDayOfWeek,
      startTime: newStartTime.toISOString().substring(0, 19) + 'Z'
    };
    addProtectedTimeEntry(protectedTimeToAdd);
  };

  return (
    <ModalComponent
      size={'m'}
      isOpen={true}
      handleClose={handleClose}
      showCloseIcon={true}
      modalHeader={
        <Flex sx={classes['.capturedTimeHeader']}>Captured Time</Flex>
      }
      modalContent={
        <Flex direction="column">
          <Flex direction="row" sx={classes['.capturedTimeSubheaderContainer']}>
            {weeksAgo === 0 ? (
              <Text sx={classes['.activityLabel']}>This week's activity</Text>
            ) : weeksAgo === 1 ? (
              <Text sx={classes['.activityLabel']}>Last week's activity</Text>
            ) : (
              <Text sx={classes['.activityLabel']}>
                Activity {weeksAgo} weeks ago
              </Text>
            )}
            <Text sx={classes['.weeklyTotal']}>
              Weekly Total: {weeklyTotalHours} hours
            </Text>
          </Flex>
          {disableBackWeek ? (
            <Flex sx={classes['.noPreviousTimeWarning']}>
              <Text>No time entered previous to this week!</Text>
            </Flex>
          ) : (
            ''
          )}
          <Flex direction="column">
            <Flex direction="row" sx={classes['.weekTimelineContainer']}>
              <IconButton
                variant="outline"
                aria-label="back-button"
                onClick={handleWeekBackClick}
                disabled={disableBackWeek}
                sx={classes['.changeWeekButton']}
                icon={<ArrowLeftIcon />}></IconButton>
              {capturedTimeWeek
                ?.sort(
                  (day1, day2) =>
                    dayOfWeekOrder.indexOf(day1.day.toLowerCase()) -
                    dayOfWeekOrder.indexOf(day2.day.toLowerCase())
                )
                .map(capturedTimeDay => {
                  return (
                    <Flex
                      direction="column"
                      sx={
                        selectedDayOfWeek === capturedTimeDay.day
                          ? classes['.dayTimelineContainerSelected']
                          : classes['.dayTimelineContainer']
                      }
                      onClick={() => handleDayClick(capturedTimeDay)}>
                      <Text sx={classes['.dayTimelineDayOfWeek']}>
                        {capturedTimeDay.day}
                      </Text>
                      <Flex sx={classes['.dayTimelineDate']}>
                        {capturedTimeDay.dateOfEntry.substring(8)}
                      </Flex>
                      {selectedDayOfWeek === capturedTimeDay.day && (
                        <Divider sx={classes['.dayTimelineDividerSelected']} />
                      )}
                    </Flex>
                  );
                })}
              <IconButton
                variant="outline"
                aria-label="forward-button"
                onClick={handleWeekForwardClick}
                sx={classes['.changeWeekButton']}
                disabled={weeksAgo === 0}
                icon={<ArrowRightIcon />}></IconButton>
            </Flex>
            <Divider sx={classes['.dayTimelineDivider']} />
          </Flex>
          <Flex direction="row">
            <Text sx={classes['.selectedDayLabel']}>
              {selectedDayOfWeek},{' '}
              {selectedDay?.dateOfEntry.length > 0
                ? formatDateStringSlashed(selectedDay?.dateOfEntry)
                : formatDateStringSlashed(
                    new Date().toISOString().split('T')[0]
                  )}
            </Text>
            <Flex sx={classes['.hoursLabelContainer']}>
              <Flex sx={classes['.expectedHoursLabel']}>
                <Text sx={classes['.hoursLabel']}>Expected: </Text>
                <Text sx={classes['.hoursText']}>
                  {selectedDay?.hoursWorked} hours
                </Text>
              </Flex>
              <Flex sx={classes['.totalHoursLabel']}>
                <Text sx={classes['.hoursLabel']}>Total: </Text>
                <Text sx={classes['.hoursText']}>
                  {protectedTimeWeekCurrent
                    ?.filter(
                      protectedTimeEntry =>
                        protectedTimeEntry.day.toLowerCase() ===
                        selectedDayOfWeek.toLowerCase()
                    )
                    .reduce((sum, current) => sum + current.duration, 0)
                    .toFixed(2)}{' '}
                  hours
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex direction="column">
            {dayEditDisabled[selectedDayOfWeek.toLowerCase()] ? (
              <Flex sx={classes['.noEditWarning']}>
                <Text>
                  No edit access: beyond configured maximum backdate days.
                </Text>
              </Flex>
            ) : (
              ''
            )}
            <ProtectedTimeList
              dayEditDisabled={dayEditDisabled}
              protectedTimeDayDict={protectedTimeDayDict}
              handleUpdateProtectedTime={handleUpdateProtectedTime}
              handleDeleteProtectedTime={handleDeleteProtectedTime}
              setProtectedTimeDayDict={setProtectedTimeDayDict}
              statusList={statusList}></ProtectedTimeList>
          </Flex>
          <Flex direction="row" sx={classes['.addTimeRowContainer']}>
            <Flex sx={classes['.startTimeSelect']}>
              <Input
                type={'time'}
                disabled={dayEditDisabled[selectedDayOfWeek.toLowerCase()]}
                id="newStartTime"
                placeholder="Start time"
                onChange={e => handleUpdateNewProtectedTimeEntry(e)}></Input>
            </Flex>
            <Flex sx={classes['.descriptionSelectContainer']}>
              <Select
                placeholder="Description"
                disabled={dayEditDisabled[selectedDayOfWeek.toLowerCase()]}
                id="newReason"
                sx={classes['.descriptionSelect']}
                onChange={e => handleUpdateNewProtectedTimeEntry(e)}>
                {statusList.rowValues.map(status => {
                  return (
                    <option id="statusReason" value={status.Reasons}>
                      {status.Reasons}
                    </option>
                  );
                })}
              </Select>
            </Flex>
            <Flex sx={classes['.enterHoursSelect']}>
              <Input
                type={'number'}
                disabled={dayEditDisabled[selectedDayOfWeek.toLowerCase()]}
                id="newDuration"
                placeholder="Enter hours"
                onChange={e => handleUpdateNewProtectedTimeEntry(e)}></Input>
            </Flex>
            <Button
              sx={classes['.addTimeButton']}
              disabled={dayEditDisabled[selectedDayOfWeek.toLowerCase()]}
              onClick={handleAddProtectedTime}>
              Add time
            </Button>
          </Flex>
          <ModalComponent
            size={'s'}
            isOpen={addTimeEntryFailed}
            handleClose={() => setAddTimeEntryFailed(false)}
            showCloseIcon={true}
            modalHeader={
              <Flex sx={classes['.timeEntryOverlapErrorHeader']}>
                <WarningTwoIcon /> Time Entry Error
              </Flex>
            }
            modalContent={
              <div>
                The time periods you have entered overlap, please correct your
                time entry and try again.
              </div>
            }></ModalComponent>
        </Flex>
      }
    />
  );
};

export { CapturedTime };
