import {
  reducerWithInitialState,
  ReducerBuilder
} from 'typescript-fsa-reducers';

import * as UIActions from '../actions/ui';
import { jumpContext } from '../actions/contexts';
import { cmxDateTime, objectUtils } from '@codametrix/ui-common';

export function cmxReducerFactory<S>(initialState: S): ReducerBuilder<S> {
  return reducerWithInitialState(initialState).cases(
    [UIActions.logout, jumpContext.async.started],
    state => {
      return { ...initialState };
    }
  );
}

export function loadStateClaim(loadState: CMxCommonApp.Loading, claim: string) {
  return { ...loadState, claim };
}

export function isLoadingClaim(
  loadState: any
): loadState is CMxCommonApp.LoadingClaim {
  return 'claim' in loadState;
}

/**
 * Method to set append appropriate timezone for given patient timeline entry and prop name(with date str)
 * @param entry
 * @param propName
 * @param timeZoneStr
 * @returns
 */
export function setTimeLineEntryPropTimeZone(
  entry: CMxAPI.TimelineEntry,
  propName: string,
  timeZoneStr: CMxAPI.NullableString | undefined
) {
  const propValue = objectUtils.get(entry, propName);
  if (propValue) {
    const parsedDateTime = cmxDateTime.setTimeZoneForDate(
      timeZoneStr,
      propValue
    );
    objectUtils.set(entry, propName, parsedDateTime);
  }
  return entry;
}
