import theme from '../../theme';

export const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    marginTop: theme.space[8]
  },
  tabs: {
    borderRight: `${theme.borders['1px']} ${theme.colors.foundation[100]}`
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: `${theme.space[12]} 0 ${theme.space[12]} ${theme.space[20]}`
  },
  usersContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  }
});
