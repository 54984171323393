/// <reference path="../types.d.ts" />

import actionCreatorFactory from 'typescript-fsa';
import { CapturedTime } from './action-types';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { cmxDateTime, HttpMethods } from '@codametrix/ui-common';
import { api } from '../core/net';
import { showFeedback } from './ui';

const actionCreator = actionCreatorFactory();
const createAsync = asyncFactory<CMx.MyStatusState>(actionCreator);

const toggleIsOpenCapturedTime = actionCreator<boolean>(
  CapturedTime.TOGGLE_IS_OPEN_CAPTURED_TIME
);

const listCapturedTimeForWeek = createAsync<
  {
    startDate: Date;
    endDate: Date;
    user: CMxAPI.User;
    context: CMx.Context;
  },
  CMxAPI.PageableList<CMxAPI.NormalTimeDay>,
  CMxCommonApp.SubmitError
>(CapturedTime.LIST_CAPTURED_TIME_FOR_WEEK, async (payload, dispatch) => {
  const { startDate, endDate, user, context } = payload;

  const searchParams: URLSearchParams = new URLSearchParams({
    userUid: user.userId,
    tenantId: context.activeContext?.tenantId!,
    startDate: cmxDateTime.format(startDate, cmxDateTime.FORMATS.DATE),
    endDate: cmxDateTime.format(endDate, cmxDateTime.FORMATS.DATE)
  });

  try {
    const capturedTimeWeek = await api<
      CMxAPI.PageableList<CMxAPI.NormalTimeDay>
    >({
      endpoint: `/api/coder-activity/work-log/normal/user/tenant/v1?${searchParams.toString()}`,
      init: {
        method: HttpMethods.GET
      }
    });
    return capturedTimeWeek;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

const listProtectedTimeForWeek = createAsync<
  {
    startDate: Date;
    endDate: Date;
    user: CMxAPI.User;
    context: CMx.Context;
  },
  CMxAPI.PageableList<CMxAPI.ProtectedTime>,
  CMxCommonApp.SubmitError
>(CapturedTime.LIST_PROTECTED_TIME_FOR_WEEK, async (payload, dispatch) => {
  const { startDate, endDate, user, context } = payload;

  const searchParams: URLSearchParams = new URLSearchParams({
    userUid: user.userId,
    tenantId: context.activeContext?.tenantId!,
    startDate: cmxDateTime.format(startDate, cmxDateTime.FORMATS.DATE),
    endDate: cmxDateTime.format(endDate, cmxDateTime.FORMATS.DATE)
  });

  try {
    const protectedTimeWeek = await api<
      CMxAPI.PageableList<CMxAPI.ProtectedTime>
    >({
      endpoint: `/api/coder-activity/work-log/protected/user/tenant/v1?${searchParams.toString()}`,
      init: {
        method: HttpMethods.GET
      }
    });
    return protectedTimeWeek;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

const deleteProtectedTimeEntry = createAsync<
  { workLogId: string },
  void,
  CMxCommonApp.SubmitError
>(CapturedTime.DELETE_PROTECTED_TIME_ENTRY, async (payload, dispatch) => {
  const { workLogId } = payload;

  const searchParams = new URLSearchParams({
    workLogId: workLogId
  });

  try {
    await api<void>({
      endpoint: `/api/coder-activity/work-log/protected/edit/v1?${searchParams.toString()}`,
      init: {
        method: HttpMethods.DELETE
      }
    });
  } catch (error) {
    dispatch(
      showFeedback({
        id: Date.now(),
        message: `Time entry was not deleted correctly, error message ${error}`,
        dismissable: true
      })
    );
    return;
  }
});

const updateProtectedTimeEntry = createAsync<
  { workLogId: string; workLog: CMxAPI.ProtectedTime },
  CMxAPI.ProtectedTime,
  CMxCommonApp.SubmitError
>(CapturedTime.UPDATE_PROTECTED_TIME_ENTRY, async (payload, dispatch) => {
  const { workLogId, workLog } = payload;
  try {
    const updatedProtectedTimeEntry = await api<CMxAPI.ProtectedTime>({
      endpoint: `/api/coder-activity/work-log/protected/edit/${workLogId}/v1`,
      init: {
        method: HttpMethods.PUT
      },
      body: workLog
    });
    return updatedProtectedTimeEntry;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

const addProtectedTimeEntry = createAsync<
  { workLogProtectedRequestDto: CMxAPI.ProtectedTime },
  CMxAPI.ProtectedTime,
  CMxCommonApp.SubmitError
>(CapturedTime.ADD_PROTECTED_TIME_ENTRY, async (payload, dispatch) => {
  const { workLogProtectedRequestDto } = payload;

  try {
    const newProtectedTimeEntry = await api<CMxAPI.ProtectedTime>({
      endpoint: `/api/coder-activity/work-log/protected/edit/v1`,
      init: {
        method: HttpMethods.POST
      },
      body: workLogProtectedRequestDto
    });
    return newProtectedTimeEntry;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

const getMaxAllowedBackdate = createAsync<
  {
    key: string;
    tenantId: string;
  },
  CMxAPI.ConfigurationValue[],
  CMxCommonApp.SubmitError
>(CapturedTime.GET_MAX_ALLOWED_BACKDATE, async (payload, dispatch) => {
  const { key, tenantId } = payload;

  const searchParams = new URLSearchParams({
    key: key,
    tenantId: tenantId
  });

  try {
    const maxAllowedBackdateDays = await api<CMxAPI.ConfigurationValue[]>({
      endpoint: `/configuration/value/v1?${searchParams.toString()}`,
      init: {
        method: HttpMethods.GET
      }
    });
    return maxAllowedBackdateDays;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export {
  toggleIsOpenCapturedTime,
  listCapturedTimeForWeek,
  listProtectedTimeForWeek,
  deleteProtectedTimeEntry,
  updateProtectedTimeEntry,
  addProtectedTimeEntry,
  getMaxAllowedBackdate
};
