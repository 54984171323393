import { Breadcrumbs } from './breadcrumbs';
import { CMxComponents } from '@codametrix/ui-components/src/types';
import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import paths from '../../views/breadcumbs/paths';

//exported for testing
export const BreadCrumbsRoutes = (props: AppProps.BreadCrumbsRouteProps) => {
  const { state, path, handleActiveCrumbClick, routes } = props;

  const handleSelect = (data: string) => {
    const parts = path.split('/');

    handleActiveCrumbClick(
      parts.slice(0, data.split('/').length - 1).join('/')
    );
  };
  const breadCrumbs = routes.map(route => {
    return (
      <Route
        exact
        path={route.path}
        render={properties => {
          const crumbs = routes
            //Get parent paths of the current path ie: /cmx/users is parent of /cmx/users/:userId
            .filter(({ path }) => properties.match.path.includes(path))
            //Set display name of some breadcrumbs dynamically
            .map(crumb => {
              const { getDisplayName } = crumb;
              const paths = path.split('/');
              const dataIndex = parseInt(paths[paths.length - 2]);
              return getDisplayName
                ? { ...crumb, displayName: getDisplayName(state, dataIndex) }
                : crumb;
            })
            // Create Breadcrumb objects
            .map((crumb, index, arr) => {
              const { displayName, path } = crumb;
              return {
                displayName,
                data: path,
                display: true,
                link: index + 1 < arr.length ? true : false
              } as CMxComponents.Breadcrumb;
            });
          crumbs && crumbs.shift();
          return (
            <Breadcrumbs
              paths={crumbs}
              onCrumbClick={handleSelect}
              isPrimary={false}
            />
          );
        }}
      />
    );
  });

  return <span className={'qa-bread-crumb-route'}>{breadCrumbs} </span>;
};
export const BreadCrumbsComponent = () => {
  const path = useLocation().pathname;
  const state = useSelector((state: CMx.ApplicationState) => {
    return state;
  });
  const dispatch = useDispatch();

  const handleActiveCrumbClick = (data: string) => {
    data = `${data}`;
    dispatch(push(data));
  };

  return (
    <Switch>
      <BreadCrumbsRoutes
        state={state}
        path={path}
        handleActiveCrumbClick={handleActiveCrumbClick}
        routes={paths}
      />
    </Switch>
  );
};
