/// <reference path="../../types.d.ts" />
import React, { useState } from 'react';
import { Button } from '../../components/button/button';
import { ButtonSize } from '../../core/enums';
import { ButtonVariant } from '../../core/enums';
import { Checkbox } from '@chakra-ui/react';
import theme from '../../theme';
import { Flex } from '@chakra-ui/react';
import { styles } from './context-choice-styles';
import { Select } from '../../components/select/select';

import './context-choice-form.scss';
import { useHistory } from 'react-router';
import { SyntheticEvent } from 'hoist-non-react-statics/node_modules/@types/react';

const ContextChoiceForm: React.FC<CMx.ContextChoiceProps> = (
  props: CMx.ContextChoiceProps
) => {
  const { contexts, handleOrganizationChoice, user } = props;

  const [selectedOrg, selectOrg] = useState<number | null>(null);
  const [selectedOrgName, selectOrgName] = useState<string>('');
  const [isDefault, setIsDefault] = useState<boolean>(false);

  const history = useHistory();

  const classes = styles();

  const handleDefaultCheck = (e: SyntheticEvent<HTMLInputElement>) => {
    setIsDefault(!isDefault);
  };

  const handleSave = (e?: React.MouseEvent<HTMLElement>) => {
    if (e) {
      e.preventDefault();
      if (selectedOrg !== null) {
        handleOrganizationChoice({
          organizationId: selectedOrg,
          setDefault: isDefault,
          userId: user.id || undefined
        });
      }
    }
  };

  const handleOrgSelection = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLInputElement;
    const { value, textContent } = target;
    selectOrg(+value);
    selectOrgName(textContent ?? '');
  };

  const handleBackSelection = (e?: React.MouseEvent<HTMLElement>) => {
    if (e) {
      e.preventDefault();
      history.goBack();
    }
  };

  const convertContexts = (items: CMxAPI.OrganizationContext[]) =>
    items.map((item: CMxAPI.OrganizationContext) => ({
      value: item.organizationId,
      label:
        item.displayName && item.displayName !== ''
          ? item.displayName
          : item.shortDisplayName && item.shortDisplayName !== ''
          ? item.shortDisplayName
          : item.organizationCode
    }));

  return (
    <div className="context-choice-form ">
      <h3>Where are you working today?</h3>
      <div className="form-group">
        <Select
          placeholder="Choose your organization"
          items={convertContexts(contexts)}
          value={selectedOrgName}
          onChange={handleOrgSelection}
          dataTestId="selectOrg"
        />
        <div className="checkbox-container">
          <Checkbox onChange={handleDefaultCheck} />
          <i className="form-icon"></i> Remember my choice
        </div>
      </div>
      <Flex className="form-row" sx={classes.btnHeader}>
        <Button
          label="Back"
          variant={ButtonVariant.LINKS}
          height={'24px'}
          dataTestId="back"
          onClick={handleBackSelection}
        />
        <Button
          size={ButtonSize.MD}
          label={'Continue'}
          backgroundColor={theme.colors.accent[100]}
          color={theme.colors.text['light']}
          disabled={selectedOrg === null}
          name="Continue"
          dataTestId="continue"
          onClick={handleSave}
        />
      </Flex>
    </div>
  );
};

export default ContextChoiceForm;
