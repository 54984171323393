/// <reference path="../../types.d.ts"" />

import React from 'react';
import { connect } from 'react-redux';
import PageableList from '../pageable-list/pageable-list';
import { buildDispatchable } from '../../actions/_action-utilities';
import * as dictionaryActions from '../../actions/dictionary';
import { CMxComponents } from '@codametrix/ui-components/src/types';
import BreadCrumbsComponent from '../breadcumbs/breadcrumbs';

type dictionaryTenantListDispatches = Pick<
  AppProps.DictionaryTenantListProps,
  'list' | 'onSelection'
>;
type dictionaryListStateProps = Pick<
  AppProps.DictionaryTenantListProps,
  | 'items'
  | 'loadState'
  | 'columnDefs'
  | 'sortablePageable'
  | 'itemName'
  | 'orgId'
  | 'showHeader'
>;

const DictionaryTenantList: React.FC<AppProps.DictionaryTenantListProps> = (
  props: AppProps.DictionaryTenantListProps
) => {
  const { list, orgId } = props;

  const handleList = (e: any) => {
    list(orgId);
  };
  return (
    <div>
      <BreadCrumbsComponent />
      <div className="layout-inner-header">
        <div className="container heading">
          <div className="columns col-gapless">
            <div className="column col-11">
              <h4>Dictionaries</h4>
            </div>
          </div>
        </div>
      </div>
      {
        <div className={'qa-dictionary-list-container'}>
          {
            //@ts-ignore
            <PageableList {...props} list={handleList} />
          }
        </div>
      }
    </div>
  );
};

const mapStateToProps = function(
  state: CMx.ApplicationState
): dictionaryListStateProps {
  const { dictionaryTenants, ui } = state;

  let adminTabs: CMxComponents.TabDef[] =
    ui.actionGroups
      .find((actionGroup: any) => {
        return actionGroup.key === 'admin';
      })
      ?.options[0].subActions.filter((tab: CMxComponents.TabDef) => {
        return ui.context.activeContext?.resources.find((resource: any) => {
          return resource.resourceName === tab.resourceId;
        });
      }) ?? [];

  return {
    ...dictionaryTenants,
    columnDefs: dictionaryTenants.columnDefs,
    items: dictionaryTenants.sortablePageable.pageableDefinition.content,
    itemName: 'Organizations',
    orgId: ui.context.activeContext?.organizationId ?? -1,
    showHeader: adminTabs?.length <= 1
  };
};

const mapDispatchToProps = (dispatch: any): dictionaryTenantListDispatches => {
  const runDispatchable = buildDispatchable(dispatch);

  return {
    onSelection: (item: any) => {
      runDispatchable(() => {
        //@ts-ignore
        return dispatch(dictionaryActions.loadDictionaries(item));
      });
    },
    list: (options: number) => {
      runDispatchable(() => {
        return dispatch(dictionaryActions.getTenantList(options));
      });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DictionaryTenantList);
