import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  Text
} from '@chakra-ui/react';
import { CMxComponents } from '@codametrix/ui-components/src/types';
import React from 'react';
import { styles } from './breadcrumbs.styles';

export type BreadCrumbsProps = {
  paths: CMxComponents.Breadcrumb[];
  onCrumbClick: Function;
  isPrimary: boolean;
};

const Breadcrumbs: React.FC<BreadCrumbsProps> = ({
  paths,
  onCrumbClick,
  isPrimary
}) => {
  const classes = styles();

  return (
    <div>
      <Breadcrumb
        spacing={'16px'}
        separator={
          <BreadcrumbSeparator sx={classes.separator}>/</BreadcrumbSeparator>
        }>
        {paths?.map((path, idx) => (
          <BreadcrumbItem>
            <BreadcrumbLink
              style={{ textDecoration: 'none' }}
              isCurrentPage={idx === paths.length - 1}
              onClick={() => {
                if (idx < paths.length - 1 && path.link)
                  onCrumbClick(path?.data);
              }}>
              <Text
                sx={
                  idx < paths.length - 1 || (isPrimary && idx < 2)
                    ? classes.defaultText
                    : classes.activeText
                }>
                {path.displayName}
              </Text>
            </BreadcrumbLink>
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
    </div>
  );
};

Breadcrumbs.displayName = 'New Breadcrumbs';

export { Breadcrumbs };
