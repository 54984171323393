import React from 'react';
import Mousetrap from 'mousetrap';
import { objectUtils } from '@codametrix/ui-common';

const isArrayEquivalent = (x: any[], y: any[]) =>
  objectUtils.isEmpty(objectUtils.xorWith(x, y, objectUtils.isEqual));

class Keypress extends React.Component<AppProps.KeypressView> {
  componentDidUpdate(oldProps: any) {
    const { shortcuts } = oldProps;
    const equivalentArray = isArrayEquivalent(this.props.shortcuts, shortcuts);

    if (!equivalentArray) {
      this.debind(shortcuts);
      this.bindShortcuts(this.props.shortcuts);
    }
  }

  componentDidMount() {
    this.bindShortcuts(this.props.shortcuts);
  }

  private bindShortcuts(shortcuts: CMx.ShortcutBinding[]) {
    shortcuts.forEach((binding: CMx.ShortcutBinding) => {
      binding.keystrokes.forEach(keystroke => {
        Mousetrap.bind(keystroke, this.handleAction.bind(this, binding));
      });
    });
  }

  private debind(shortcuts: CMx.ShortcutBinding[]) {
    shortcuts.forEach((binding: CMx.ShortcutBinding) => {
      binding.keystrokes.forEach(keystroke => {
        Mousetrap.unbind(keystroke);
      });
    });
  }

  handleAction(shortcut: CMx.ShortcutBinding) {
    const binding = { ...shortcut };
    const obj = objectUtils.get(this.props, binding.statePath);

    this.props.dispatcher({
      id: binding.id,
      value: obj
    });
  }

  render() {
    return <div className="keypress">{this.props.children}</div>;
  }
}

export { Keypress };
