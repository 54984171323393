import React, { useMemo, useState } from 'react';
import { Divider, Grid, Text, Tooltip } from '@chakra-ui/react';
import { styles } from './admin-template.styles';
import {
  SecondaryNavigation,
  SecondaryNavigationOrientation,
  SecondaryNavigationVariants
} from '../secondary-navigation/secondary-navigation';
import { useDispatch, useSelector } from 'react-redux';
import theme from '../../theme';
import { navigate } from '../../actions/organization';
import { Breadcrumbs } from '../breadcrumbs/breadcrumbs';
import { CMxComponents } from '@codametrix/ui-components/src/types';
import Admin from '../../views/admin/admin-component';
import { ReactComponent as InfoIcon } from '../../assets/images/info.svg';

export type AdminTemplateProps = {
  items: CMx.TabDef[];
  onSelection: (item: CMx.TabDef) => void;
  title: string;
};
const convertToTabsDto = (tabItems: CMx.TabDef[]) =>
  tabItems.map((item: CMx.TabDef) => ({
    label: item?.displayName ?? ''
  }));
const adminPath: CMxComponents.Breadcrumb = {
  link: false,
  display: false,
  data: {},
  displayName: 'Admin'
};

// Moves organization TabDef to the first index
const unshiftOrg = (arr: CMx.TabDef[]) => {
  const tempArr: CMx.TabDef[] = JSON.parse(JSON.stringify(arr));
  const index = tempArr.findIndex(item => item?.resourceId === 'organizations');
  const obj = tempArr.splice(index, 1)[0];
  tempArr.unshift(obj);
  return tempArr;
};

const AdminTemplate: React.FC<AdminTemplateProps> = props => {
  const { items, title } = props;
  const tabItems = useMemo(() => unshiftOrg(items), [items]);
  const classes: any = styles();
  const dispatch = useDispatch();

  const { path, activeOrganization } = useSelector(
    (state: CMx.ApplicationState) => state.organization
  );

  const { auth } = useSelector((state: CMx.ApplicationState) => state.login);

  // This will return active tab index based on the path
  const getActiveAdminTabIndex = () => {
    if (window.location.toString().includes('users')) {
      return tabItems.findIndex(item => item?.resourceId === 'users');
    } else if (window.location.toString().includes('auditLog')) {
      return tabItems.findIndex(item => item?.resourceId === 'audit_log');
    }
    return 0;
  };

  const [activeTab, setActiveTab] = useState<number>(getActiveAdminTabIndex());

  const handleTabsChange = (e: number) => {
    setActiveTab(e);
    props.onSelection({
      ...tabItems[e],
      path: `${tabItems[e].path}`
    });
  };

  const navigateTo = (
    token: CMxCommonApp.BearerToken,
    organization: CMxAPI.Organization
  ) => {
    dispatch(navigate({ token, organization }) as any);
  };

  return (
    <div style={classes.root}>
      <div style={classes.header}>
        {path?.length && activeTab === 0 ? (
          <Breadcrumbs
            paths={[adminPath, ...path]}
            isPrimary={true}
            onCrumbClick={(data: CMxAPI.Organization) =>
              auth && navigateTo(auth, data)
            }
          />
        ) : (
          <Text className="admin-text-qa" sx={classes.text}>
            {title}
          </Text>
        )}
      </div>
      <div style={classes.content}>
        {path?.length > 1 && activeTab === 0 ? (
          <div>
            <Grid
              display="flex"
              flex-direction="row"
              alignItems={'baseline'}
              columnGap={theme.space[12]}>
              <Text style={classes.headerText}>
                {activeOrganization?.displayName}
              </Text>
              {activeOrganization.address1 && (
                <Tooltip
                  label={
                    <>
                      <div>{activeOrganization.address1}</div>
                      <div>{activeOrganization.address2}</div>
                      <div>
                        {activeOrganization.city}, {activeOrganization.state}{' '}
                        {activeOrganization.zipCode}
                      </div>
                    </>
                  }
                  placement="bottom">
                  <InfoIcon fill={theme.colors.foundation[300]} />
                </Tooltip>
              )}
            </Grid>
            <Divider
              borderColor={theme.colors.foundation[100]}
              orientation="horizontal"
            />
          </div>
        ) : (
          <SecondaryNavigation
            data={convertToTabsDto(tabItems)}
            variant={SecondaryNavigationVariants.LINE}
            orientation={SecondaryNavigationOrientation.HORIZONTAL}
            tabIndex={activeTab}
            handleTabsChange={handleTabsChange}
          />
        )}
        <Admin />
      </div>
    </div>
  );
};

AdminTemplate.displayName = 'Admin Template';
const MemoizedAdminTemplate = React.memo(AdminTemplate);
export { MemoizedAdminTemplate as AdminTemplate };
