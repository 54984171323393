import React, { useState, useRef, useEffect } from 'react';
import { ReactComponent as Logo } from '../../assets/images/cmx-initials.svg';
import { ReactComponent as ServiceLineArrow } from '../../assets/images/expander-closed.svg';
import { Divider } from '../../components/divider/divider';
import { ReactComponent as EditLogo } from '../../assets/images/edit.svg';
import { UserIcon } from '../user-icon/user-icon';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import './topBar.scss';
import {
  TopNav,
  TopNavItem,
  RoundedButton,
  FlexCard
} from '@codametrix/ui-components';
import classNames from 'classnames';
// @ts-ignore
import GitInfo from 'react-git-info/macro';

library.add(faCircle);

interface NavProps {
  context: CMx.Context;
  actionGroups: CMxAPI.NavActionGroup[];
  handleOrganizationChoice: (orgChoice: CMx.OrganizationFormChoice) => void;
  handleServiceLineChoice: (
    serviceLineChoice: CMx.ServiceLine,
    context: CMx.Context,
    actionGroups: CMxAPI.NavActionGroup[]
  ) => void;
  onSelection: (selction: CMxAPI.NavAction) => void;
  onLogout: () => void;
  activeOrg: string;
  activeUser: CMxAPI.User;
  serviceLine?: CMx.ServiceLine;
  openAccountSettings: () => void;
}

const TopBar: React.FC<NavProps> = (props: NavProps) => {
  const {
    onSelection,
    actionGroups,
    onLogout,
    activeOrg,
    handleOrganizationChoice,
    handleServiceLineChoice,
    context: cmxContext,
    activeUser,
    serviceLine,
    context,
    openAccountSettings
  } = props;
  const { activeContext, contexts } = cmxContext;
  const [showSettings, setSettings] = useState(false);
  const [showServiceLine, setServiceLine] = useState(false);

  const gitInfo = GitInfo();
  const serviceLines = activeContext?.serviceLines || [];
  const hasMultipleServiceLines = serviceLines.length > 1;
  let classes = [
    'nav-item-icon',
    'nav-logo',
    'nav-item-right',
    'settings-icon'
  ];

  if (showSettings) {
    classes.push('active-settings-icon');
  }

  const handleOnClick = (event: React.MouseEvent) => {
    event.preventDefault();
    const target =
      (event.target as HTMLDivElement) || document.createElement('div');

    const isOrg = target.classList.contains('item-name');
    let org;

    if (!isOrg) {
      const ancestor = target?.closest('.item-name');
      if (ancestor) {
        org = ancestor;
      }
    } else {
      org = target;
    }
    if (org) {
      const item = org.attributes.getNamedItem('data-index');
      if (item) {
        const organizationId = parseInt(item.value);
        if (organizationId !== cmxContext.activeContextId) {
          handleOrganizationChoice({ organizationId, reload: true });
        }
      }
    }
    setSettings(false);
  };

  const handleServiceLineClick = (event: React.MouseEvent) => {
    event.preventDefault();
    const target =
      (event.target as HTMLDivElement) || document.createElement('div');

    const isServiceLine = target.classList.contains('item-name');
    let sl;

    if (!isServiceLine) {
      const ancestor = target?.closest('.item-name');
      if (ancestor) {
        sl = ancestor;
      }
    } else {
      sl = target;
    }
    if (sl) {
      const item = sl.attributes.getNamedItem('data-index');
      if (item) {
        const serviceLineId = parseInt(item.value);
        if (serviceLineId !== serviceLine?.id) {
          const choice = serviceLines.find(sl => sl.id === serviceLineId);
          if (choice) {
            handleServiceLineChoice(choice, context, actionGroups);
          }
        }
      }
    }
    setServiceLine(false);
  };

  const handleSettingsClick = (event: React.MouseEvent) => {
    event.preventDefault();
    openAccountSettings();
  };
  const wrapperRef = useRef(null);
  const serviceLineRef = useRef(null);

  function useOutsideClickAlert(ref: React.RefObject<HTMLDivElement>) {
    useEffect(() => {
      const handleClickOutside = (e: any) => {
        const target = e.target;
        if (
          (target as HTMLDivElement).classList.contains('settings-button') ||
          (target as HTMLDivElement).classList.contains('settings-icon') ||
          (target as HTMLDivElement).classList.contains(
            'service-line-button-text'
          ) ||
          (target as HTMLDivElement).classList.contains('service-line-icon')
        ) {
          return;
        }
        if (ref.current && !ref.current.contains(e.target)) {
          setSettings(false);
          setServiceLine(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () =>
        document.removeEventListener('mousedown', handleClickOutside);
    }, [ref]);
  }

  useOutsideClickAlert(wrapperRef);
  useOutsideClickAlert(serviceLineRef);
  const settingsClasses = classNames([
    'top-bar-dropdown',
    'settings-dropdown',
    'qa-settings-dropdown',
    { 'muliple-contexts': contexts ? contexts.length > 1 : false }
  ]);

  const serviceLineButtonClasses = classNames([
    'service-line-button',
    'qa-service-line-button',
    { 'active-top-bar-button': showServiceLine },
    { 'service-line-button-disabled': !hasMultipleServiceLines }
  ]);

  const handleServiceLineDropdownClick = (e: any) => {
    if (hasMultipleServiceLines) {
      setServiceLine(!showServiceLine);
      setSettings(false);
    }
  };

  const handleSettingsDropdownClick = () => {
    setSettings(!showSettings);
    setServiceLine(false);
  };

  const userInitials =
    activeUser.firstName && activeUser.lastName
      ? activeUser.firstName?.charAt(0) + activeUser.lastName?.charAt(0)
      : '🧑‍⚕️';

  return (
    <div className="topbar-navigation">
      <div className={'topbar-right-section'}>
        <TopNavItem key="logo-item" path="/cmx/" id="logo" subActions={[]}>
          <Logo
            className="nav-item-icon nav-logo"
            title={`version: ${gitInfo.commit.shortHash}`}
          />
        </TopNavItem>

        {serviceLine?.name.length && (
          <TopNavItem
            key="service-line-item"
            path="/cmx/"
            id="service-line"
            subActions={[]}>
            <div
              onClick={handleServiceLineDropdownClick}
              className={serviceLineButtonClasses}
              data-testid="service-line-button">
              <span
                className="service-line-button-text qa-service-line-button-text"
                data-testid="service-line-button-text">
                {serviceLine?.name}
              </span>
              {hasMultipleServiceLines && (
                <ServiceLineArrow className="service-line-icon" />
              )}
            </div>
          </TopNavItem>
        )}

        <TopNav actionGroups={actionGroups} onSelection={onSelection} />
      </div>

      <TopNavItem
        key="settings-item"
        path="/cmx/"
        id="settings"
        subActions={[]}>
        <div
          onClick={handleSettingsDropdownClick}
          className={
            'settings-button qa-settings-button' +
            (showSettings ? ' active-top-bar-button' : '')
          }
          data-testid="qa-settings-button">
          <span className="settings-button-text">{activeOrg}</span>
          <UserIcon
            initials={userInitials}
            classes={classes}
            iconId="top-bar-icon"
          />
        </div>
      </TopNavItem>

      {showSettings ? (
        <div
          className={settingsClasses}
          data-testid="settings-dropdown"
          ref={wrapperRef}>
          <FlexCard
            header={
              <div className="top-container">
                <div className="columns">
                  <div className="col-4 avatar-column">
                    <UserIcon
                      initials={userInitials}
                      classes={classes}
                      iconId="avatar-icon"
                      textId={'avatar-text'}
                    />
                  </div>

                  <div className="col-8">
                    <div className="user-name">
                      {' '}
                      <span className="name-part">
                        {activeUser?.firstName}
                      </span>{' '}
                      <span className="name-part">{activeUser?.lastName}</span>
                      <RoundedButton
                        className="btn-link qa-settings-button"
                        type="submit"
                        data-testid="settings-button"
                        onClick={handleSettingsClick}>
                        <EditLogo />
                      </RoundedButton>
                    </div>
                    <div className="user-email"> {activeUser?.username}</div>

                    <RoundedButton
                      className="btn-primary qa-logout-button"
                      type="submit"
                      data-testid="logout-button"
                      onClick={onLogout}>
                      Log Out
                    </RoundedButton>
                  </div>
                </div>
                <div className="multi-account-heading">
                  <h6 className="drop-down-heading">Your Accounts</h6>
                  <Divider />
                </div>
              </div>
            }>
            <div className={'settings-container'} ref={wrapperRef}>
              {contexts.length > 1 && (
                <div className="context-display">
                  <div className="item-contexts" onClick={handleOnClick}>
                    {contexts.map(ctx => {
                      const classes = classNames('item-choice', {
                        'item-selected':
                          ctx.organizationId === activeContext?.organizationId
                      });
                      return (
                        <div className={classes} key={ctx.organizationId}>
                          <div className="item-indicator"></div>
                          <div
                            className="item-name"
                            data-index={ctx.organizationId}
                            data-testid="item-name">
                            {ctx.displayName}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </FlexCard>
        </div>
      ) : null}

      {showServiceLine && (
        <div
          className={'top-bar-dropdown service-line-dropdown'}
          ref={serviceLineRef}>
          <FlexCard>
            {serviceLines.map(sl => {
              const classes = classNames('item-choice', 'qa-item-choice', {
                'item-selected': sl.id === serviceLine?.id
              });
              return (
                <div
                  className={classes}
                  key={sl.id}
                  onClick={handleServiceLineClick}>
                  <div className="item-indicator qa-item-indicator"></div>
                  <div className="item-name qa-item-name" data-index={sl.id}>
                    {sl.name}
                  </div>{' '}
                </div>
              );
            })}
          </FlexCard>
        </div>
      )}
    </div>
  );
};

export default TopBar;
