/// <reference path="../types.d.ts" />
import {
  authenticationContext,
  cmxLocation,
  HttpMethods,
  ContentTypes,
  sessionManagement,
  cmxErrors
} from '@codametrix/ui-common';
import * as AppJump from './app-jump';

const {
  FormSubmissionError,
  UnauthenticatedError,
  ServerError,
  PageNotFoundError,
  BadRequestError
} = cmxErrors;
const { activityMonitor } = sessionManagement;

const STOCK_HEADERS: KeyValuePair = {
  'Content-Type': 'application/json'
};

export const setIsActive = (isActive: boolean) => {
  activityMonitor.isActive = isActive;
};

const handleInauthenticated = (endpoint: string) => {
  AppJump.saveJump();
  throw new UnauthenticatedError(`error contacting api ${endpoint}`);
};

const isX = (startInclusive: number, endExclusive: number) => {
  return (statusCode: number) =>
    statusCode >= startInclusive && statusCode < endExclusive;
};

const is500 = isX(500, 600);

const _execRequest = async (
  request: FetchOptions,
  token: string,
  useStockHeaders: boolean = true,
  userBearerTokens: boolean = false
): Promise<any> => {
  const authHeaders = {
    Authorization: userBearerTokens ? `Bearer ${token}` : token
  };
  const headers: KeyValuePair = {
    ...(useStockHeaders ? STOCK_HEADERS : {}),
    ...authHeaders,
    ...request.headers
  };
  const options: RequestInit = {
    method: HttpMethods.GET,
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    headers,
    redirect: 'follow',
    referrer: 'no-referrer',
    ...request.init
  };
  if (request.body) {
    options.body = JSON.stringify(request.body);
  }

  const response = await fetch(request.endpoint, options);

  if (!response.ok) {
    const { status } = response;
    if (is500(status)) {
      throw new ServerError(`error contacting api ${request.endpoint}`);
    } else if (status === 400) {
      const responseBody = await response.json();
      throw new BadRequestError(`error contacting api`, response, responseBody);
    }
    if (status === 403 || status === 401) {
      throw new UnauthenticatedError(
        `error contacting api ${request.endpoint}`
      );
    } else if (status === 409) {
      const responseBody = await response.json();
      throw new FormSubmissionError(
        `error contacting api`,
        response,
        responseBody
      );
    } else if (status === 404) {
      throw new PageNotFoundError(`page not found`);
    }
  }

  const location = response.headers.get(`location`);
  if (location) {
    cmxLocation.setLocation(location);
  }

  switch (response.headers.get('content-type')) {
    case ContentTypes.APPLICATION_CSV:
      const blob = await response.blob();
      const contentDisposition = response.headers.get(`content-disposition`);
      return { blob, contentDisposition };
    case ContentTypes.APPLICATION_JSON:
    case ContentTypes.APPLICATION_JSON_UTF_8:
      return await response.json();
    default:
      return;
  }
};

export const api = async <T>(
  request: FetchOptions,
  useStockHeaders: boolean = true,
  useBearerToken: boolean = false
): Promise<T> => {
  const token = authenticationContext.getToken();

  function isBearerToken(token: string) {
    const bearerPrefix = 'Bearer ';
    return token.startsWith(bearerPrefix);
  }

  if (!token) {
    return handleInauthenticated(request.endpoint);
  }
  return _execRequest(
    request,
    token,
    useStockHeaders,
    useBearerToken && !isBearerToken(token)
  );
};
