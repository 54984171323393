import { CMxComponents } from '@codametrix/ui-components/src/types';

import { LOAD_STATES } from '../common';
import {
  columnMetadataDataItem,
  defaultDictionary,
  defaultDictionaryColumn,
  defaultDictionaryRow
} from '../../tests/fixtures/stubs/dictionary';
import { defaultCriteriaFilter } from '../../core/criteria-filter';
import StatusIcon from '../../assets/images/icon-status.svg';

import { commonEnums } from '@codametrix/ui-common';
import { AmplifyCore } from '@codametrix/ui-common';
import { formErrors } from '../../stubs/form-errors';

const { paginationUtils } = AmplifyCore;

const { DictionaryStatus, DictionaryEditStatus } = commonEnums;

export const defaultSortablePageable: CMxCommonApp.SortablePageable<any> = {
  pageableDefinition: { ...paginationUtils.emptyPageable },
  sortableOptions: [
    {
      sortField: 'rowIndex',
      sortDirection: 'ASC'
    }
  ],
  filterableOptions: {
    filters: [
      { key: 'status', terms: [DictionaryStatus.PUBLISHED], type: 'IN' }
    ]
  }
};

const transformDeleteFilter = (filters: CMxCommonApp.Filter[]) => {
  const shimmedFilters = filters.filter(
    filter => !['edit_status', 'status'].includes(filter.key)
  );

  shimmedFilters.push(
    { key: 'status', terms: ['publish'], type: 'IN' },
    {
      key: 'edit_status',
      terms: [DictionaryEditStatus.DELETE],
      type: 'IN'
    }
  );
  return shimmedFilters;
};

const transformAddFilter = (filters: CMxCommonApp.Filter[]) => {
  const shimmedFilters = filters.filter(
    filter => !['edit_status', 'status'].includes(filter.key)
  );

  shimmedFilters.push(
    { key: 'status', terms: [DictionaryStatus.DRAFT], type: 'IN' },
    {
      key: 'edit_status',
      terms: [DictionaryEditStatus.ADD],
      type: 'IN'
    }
  );
  return shimmedFilters;
};

const transformUpdateFilter = (filters: CMxCommonApp.Filter[]) => {
  const shimmedFilters = filters.filter(
    filter => !['edit_status', 'status'].includes(filter.key)
  );

  shimmedFilters.push(
    { key: 'status', terms: [DictionaryStatus.DRAFT], type: 'IN' },
    {
      key: 'edit_status',
      terms: [DictionaryEditStatus.UPDATE],
      type: 'IN'
    }
  );
  return shimmedFilters;
};

export const filterStatusFields = [
  {
    key: 'published',
    helpText: 'Published',
    required: false,
    type: 'checkbox',
    label: 'Published',
    value: 'published',
    downloadSupported: true
  },
  {
    key: 'draft',
    helpText: 'Draft',
    required: false,
    type: 'checkbox',
    label: 'Draft',
    value: 'draft',
    downloadSupported: true
  },
  {
    key: 'delete',
    helpText: 'Rows to be deleted on publish',
    required: false,
    type: 'checkbox',
    label: 'Queued for deletion',
    value: 'delete',
    transform: transformDeleteFilter,
    downloadSupported: false
  },
  {
    key: 'add',
    helpText: 'Rows to be added on publish',
    required: false,
    type: 'checkbox',
    label: 'Pending additions',
    value: 'add',
    transform: transformAddFilter,
    downloadSupported: false
  },
  {
    key: 'update',
    helpText: 'Rows to be updated on publish',
    required: false,
    type: 'checkbox',
    label: 'Pending updates',
    value: 'update',
    transform: transformUpdateFilter,
    downloadSupported: false
  }
];
const formDefinitionVersion: CMxComponents.FormDefintion = {
  fieldGroups: [
    {
      label: 'Filter Status',
      fields: filterStatusFields
    }
  ]
};

const versionCriteria: AppProps.CriteriaForm = {
  name: 'Status',
  key: 'status',
  icon: StatusIcon,
  formDefinition: { ...formDefinitionVersion },
  isSelect: true
};

const criterialFilter: AppProps.CriteriaFilter = {
  ...defaultCriteriaFilter,
  filterForms: [versionCriteria]
};

const columnDefs: CMxComponents.ColumnDefintion[] = [];

const EffectiveDate: CMxCommonApp.FieldDefinition = {
  label: `Effective Date`,
  key: `effectiveDate`,
  required: true,
  helpText: '',
  type: 'text',
  readonly: true
};

const RowIndex: CMxCommonApp.FieldDefinition = {
  label: `Row Index`,
  key: `rowIndex`,
  required: true,
  helpText: '',
  type: 'text',
  readonly: true
};

export const dictionaryMetadataFieldGroup = {
  label: 'Metadata',
  fields: [RowIndex, EffectiveDate]
};

const defaultColumnEditorState: CMx.ColumnMetadataEditorState = {
  showMetadataEditor: true,
  keyColumnIndex: undefined,
  containsDictionaryMappings: undefined,
  columnMetadata: [],
  loadState: LOAD_STATES.initial,
  activeColumn: defaultDictionaryColumn,
  activeColumnMetadataObj: columnMetadataDataItem
};

const archiveModalState: CMx.ArchiveModalState = {
  selectedRow: defaultDictionaryRow,
  isOpen: false,
  archiveInChildDictionaries: undefined,
  potentialArchives: [],
  archiveResult: [],
  loadState: LOAD_STATES.initial
};

export const createDataGridRows = (
  rows: CMxAPI.DictionaryRow[]
): CMx.DictionaryValueDataGridRow[] => {
  return rows.map(row => {
    const mappedRow: any = {};
    row.cells.forEach((cell: CMxAPI.Cell) => {
      mappedRow[cell.columnName] = cell.value;
      mappedRow[cell.columnName + 'Id'] = cell.id;
    });
    mappedRow['effectiveDate'] = row.effectiveDate;
    mappedRow['endDate'] = row.endDate;
    mappedRow['rowIndex'] = row.rowIndex;
    mappedRow['id'] = row.id;
    return mappedRow;
  });
};

export const dictionaryValues: CMx.DictionaryValuesState = {
  label: 'Dictionary Value',
  itemName: 'Dictionary Values',
  loadState: LOAD_STATES.done,
  sortablePageable: defaultSortablePageable,
  items: [],
  columnDefs,
  columnHeaders: [],
  criteriaFilter: criterialFilter,
  selectedDictionary: defaultDictionary,
  selectedRowIndex: -1,
  rowEditForm: {},
  rowAddForm: {},
  criteriaLoadState: LOAD_STATES.initial,
  formErrors: formErrors.noErrors,
  idField: 'rowIndex',
  columnMetadataEditor: defaultColumnEditorState,
  archiveModal: archiveModalState,
  isDeleteModalOpen: false,
  listDictionaryHistory: [],
  historyLoadState: LOAD_STATES.initial,
  isCorrection: false,
  selectedTenantName: '',
  colErrors: formErrors.noErrors,
  showDraft: false,
  mappedColValues: []
};
