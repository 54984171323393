import { commonEnums } from '@codametrix/ui-common';

export const patientTimeline: CMx.PatientTimelineState = {
  organization: null,
  isDrawerOpen: false,
  isOpen: false,
  activeCaseRun: {} as CMxAPI.CaseRunV2,
  entries: [],
  entriesLoading: false,
  entriesMessageCountsLoading: false,
  entriesFailed: false,
  entriesMessageCounts: [],
  entriesSumCounts: 0,
  entriesLastPage: false,
  filterTypes: [],
  numberOfElements: 0,
  entryPage: 0,
  typesCounts: {
    ADT: 0,
    SIU: 0,
    ORM: 0,
    RDS: 0,
    MDM: 0,
    DFT: 0
  },
  dateFilter: commonEnums.DateFilterLabelEnum.ALL_EVENTS,
  dateFilterStr: '',
  isFirstLoad: false,
  orderTypeStatus: []
};
