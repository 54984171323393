import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveColumnDefinitions,
  syncActions
} from '../../../actions/dictionary-values';
import {
  getTenantList,
  updatePublishedMetadata
} from '../../../actions/dictionary';
import { CMxComponents } from '@codametrix/ui-components/dist/types';
import { goBack, push } from 'connected-react-router';
import { LOAD_STATES } from '../../../reducers/common';
import { commonEnums } from '@codametrix/ui-common';
import { BreadCrumbsComponent } from '../../../components/breadcrumbs/breadcrumbsComponent';
import { DataGrid } from '../../../components/data-grid/data-grid';
import { Checkbox } from '../../../components/checkbox/checkbox';
import {
  Flex,
  FormControl,
  FormHelperText,
  Grid,
  Text,
  Tooltip
} from '@chakra-ui/react';
import { styles } from '../dictionary-styles';
import { ReactComponent as CancelCircle } from '../../../assets/images/cancel-circle.svg';
import theme from '../../../theme';
import { Dispatch, Action } from 'redux';
import { Form } from '../form';
import IconLabel from '../../../components/icon-label/iconlabel';
import RequestErrors from '../request-errors/request-errors';
import { ReactComponent as Info } from '../../../assets/images/info.svg';

const classes = styles();
const { DictionaryStatus } = commonEnums;
const handleRowAction = (
  event: any,
  columnDefinition: CMxAPI.ColumnDefinition,
  dispatch: Dispatch
) => {
  event.stopPropagation();
  dispatch(syncActions.removeColumnMapping(columnDefinition) as Action);
};

export const getColumnDefs = (dispatch: Dispatch) => {
  return [
    {
      field: 'name',
      headerName: 'Column Name'
    },
    {
      field: 'keyColumnIndicator',
      headerName: 'Key Column Indicator'
    },
    {
      field: 'cellReference.organization',
      headerName: 'Mapped Organization Name',
      cellRenderer: (params: any) => {
        const { data = {} } = params;
        if (data.cellReferences?.length) {
          if (data.cellReferences[0].parentOrganizationName) {
            return (
              <Grid display="inline-flex" alignItems="center">
                <Tooltip label="Inherited from CodaMetrix" placement="bottom">
                  <Info
                    fill={theme.colors.foundation[300]}
                    width={theme.space[12]}
                    height={theme.space[16]}
                  />
                </Tooltip>
                <Text sx={classes.orgName}>
                  {data.cellReferences[0].organizationName}
                </Text>
              </Grid>
            );
          }
          return data.cellReferences[0].organizationName;
        }
        return '';
      }
    },
    {
      field: 'cellReference.dictionaryName',
      headerName: 'Mapped Dictionary Name',
      cellRenderer: (params: any) => {
        const { data = {} } = params;
        if (data.cellReferences?.length) {
          return data.cellReferences[0].dictionaryName;
        }
        return '';
      }
    },
    {
      field: 'cellReference.lookupProperty',
      headerName: 'Mapped Property',
      cellRenderer: (params: any) => {
        const { data = {} } = params;
        if (data.cellReferences?.length) {
          return data.cellReferences[0].lookupProperty;
        }
        return '';
      }
    },
    {
      field: 'cellReference.isRefValueList',
      headerName: 'Multiple Values',
      cellRenderer: (params: any) => {
        const { data = {} } = params;
        if (data.cellReferences?.length) {
          return data.cellReferences[0]?.isRefValueList ? (
            <Grid sx={classes.columnIcon}>
              <Checkbox isChecked={true} />
            </Grid>
          ) : (
            <Grid sx={classes.columnIcon}>
              <Checkbox isChecked={false} />
            </Grid>
          );
        }
        return (
          <Grid sx={classes.columnIcon}>
            <Checkbox isChecked={false} />
          </Grid>
        );
      }
    },
    {
      field: 'remove',
      headerName: 'Clear mapping',
      action: 'remove',
      cellRenderer: (params: any) => {
        const { data = {} } = params;
        return (
          <Grid
            sx={classes.columnIcon}
            data-testid="clear-mapping"
            onClick={event => {
              handleRowAction(event, data, dispatch);
            }}>
            <CancelCircle
              fill={theme.colors.foundation[300]}
              data-testid="clear-mapping"
            />
          </Grid>
        );
      }
    }
  ];
};

const crossMapSelectFormButtons = [
  { buttonType: 'submit', buttonText: 'Submit' },
  { buttonType: 'link', buttonText: 'back' }
];

const crossMapSelectForm: CMxComponents.FormDefintion = {
  buttons: crossMapSelectFormButtons
};

const determineStatus = (
  sortablePageable: CMxCommonApp.SortablePageable<any>
) => {
  return (
    sortablePageable.filterableOptions.filters?.find(
      filter => filter.key === 'status'
    )?.terms || [DictionaryStatus.PUBLISHED]
  );
};

export type CrossMapSelectProps = {
  viewOnly: boolean;
};

const CrossMapSelect: React.FC<CrossMapSelectProps> = props => {
  const { viewOnly } = props;

  const {
    columnMetadataEditor: columnEditorState,
    selectedDictionary,
    sortablePageable,
    updatedRows
  } = useSelector((state: CMx.ApplicationState) => {
    return state.dictionaryValues;
  });

  const [openModal, setOpenModal] = useState<boolean>(
    columnEditorState.loadState !== LOAD_STATES.failed
  );

  useEffect(() => {
    if (columnEditorState.loadState !== LOAD_STATES.failed) {
      setOpenModal(false);
    } else {
      setOpenModal(true);
    }
  }, [columnEditorState.loadState]);

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const orgId = useSelector((state: CMx.ApplicationState) => {
    return state.ui.context.activeContext?.organizationId ?? -1;
  });

  const colErrors = useSelector((state: CMx.ApplicationState) => {
    return state.dictionaryValues.colErrors;
  });

  const dictionaryId = selectedDictionary.id;

  const dispatch = useDispatch();

  const handleRowSelection = (dataItem: any) => {
    const targetColumnId = dataItem?.api?.getFocusedCell()?.column.getColId();
    if (viewOnly || targetColumnId === 'remove') {
      return;
    }
    const pathParts = window.location.pathname;
    dispatch((getTenantList(orgId) as any) as Action);
    dispatch(syncActions.selectActiveColumn(dataItem.data));
    dispatch(push(pathParts + `/${dataItem.data.name}/edit`));
  };

  const handleCrossMapSave = () => {
    const pathParts = window.location.pathname.split('/');
    pathParts.pop();
    pathParts.pop();

    const status = determineStatus(sortablePageable)[0];
    const successPath = `${pathParts.join('/')}`;

    if (status === DictionaryStatus.PUBLISHED && updatedRows) {
      dispatch(
        (updatePublishedMetadata({
          dictionary: {
            ...selectedDictionary,
            status: DictionaryStatus.DRAFT,
            rows: updatedRows,
            columnDefinitions: columnEditorState.columnMetadata
          },
          successPath: successPath
        }) as any) as Action
      );
    } else {
      const payload: CMx.dictionaryMetadataSavePayload = {
        dictionaryId,
        succesPath: successPath,
        columnDefinitions: columnEditorState.columnMetadata
      };
      dispatch((saveColumnDefinitions(payload) as any) as Action);
    }
  };

  const handleAction = (event: CustomEvent) => {
    const { detail } = event;

    if (detail.eventType === 'cancel') {
      if (viewOnly) {
        dispatch(goBack());
      } else {
        const pathParts = window.location.pathname.split('/');
        const newRoute = pathParts.splice(pathParts.length);
        newRoute.push('keyColumn');
        dispatch(push(`${newRoute.join('/')}`));
      }
    }
  };

  const formEnabler = () => {
    return !viewOnly;
  };

  return (
    <>
      <Flex>
        <BreadCrumbsComponent />
      </Flex>
      <RequestErrors
        errors={colErrors}
        openModal={openModal}
        handleClose={handleModalClose}></RequestErrors>
      <Form
        formDefinition={crossMapSelectForm}
        onSave={handleCrossMapSave}
        onAction={handleAction}
        enable={formEnabler}
        title={'Column Metadata - Cross Maps'}
        dataItem={{}}
        errors={{
          status: '',
          message: null,
          errors: [],
          fieldErrors: {}
        }}
        inline={false}>
        <fieldset style={classes.fieldset}>
          <legend style={classes.legend}>Cross Maps</legend>
          <Grid sx={classes.datagrid}>
            <DataGrid
              rowData={columnEditorState.columnMetadata}
              columnDefs={getColumnDefs(dispatch)}
              onRowClicked={data => handleRowSelection(data)}
            />
            <FormControl>
              <FormHelperText>
                <IconLabel
                  isLeft={true}
                  label={
                    'Select a column name from the table above to add a cross map'
                  }
                />
              </FormHelperText>
            </FormControl>
          </Grid>
        </fieldset>
      </Form>
    </>
  );
};

CrossMapSelect.displayName = 'CrossMapSelect';
export { CrossMapSelect };
