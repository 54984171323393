/// <reference path="../../../types.d.ts" />

import { connect } from 'react-redux';

import { buildDispatchable } from '../../../actions/_action-utilities';
import externalAppActions from '../../../actions/external-applications';
import { goBack } from 'connected-react-router';
import { ExternalAppUserForm } from './external-app-user-form';

type ExternalAppUserFormDispatches = Pick<
  AppProps.ExternalAppUserFormProps,
  'handleSave' | 'handleCancel'
>;
type ExternalAppUserFormProps = Pick<
  AppProps.ExternalAppUserFormProps,
  'selectedUser' | 'errors'
>;

const mapStateToProps = function(
  state: CMx.ApplicationState
): ExternalAppUserFormProps {
  const { externalApplications } = state;

  return {
    selectedUser: externalApplications.selectedUser,
    errors: externalApplications.userFormErrors
  };
};

const mapDispatchToProps = (dispatch: any): ExternalAppUserFormDispatches => {
  const runDispatchable = buildDispatchable(dispatch);

  return {
    handleSave: (user: CMxAPI.ExternalApplicationUser) => {
      runDispatchable(() => {
        return dispatch(externalAppActions.saveExternalAppUser(user));
      });
    },
    handleCancel: () => {
      runDispatchable(() => {
        return dispatch(goBack());
      });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExternalAppUserForm);
