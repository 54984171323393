/// <reference path="../../types.d.ts" />

import { connect } from 'react-redux';

import { buildDispatchable } from '../../actions/_action-utilities';
import { goBack } from 'connected-react-router';
import { Features } from './features';
import { updateFeatures } from '../../actions/organization';
import { formErrors } from '../../stubs/form-errors';

type featuresDispatches = Pick<
  AppProps.FeaturesFormProps,
  'handleSave' | 'handleCancel'
>;

type featuresStateProps = Pick<
  AppProps.FeaturesFormProps,
  'features' | 'errors'
>;

export const mapStateToProps = function(
  state: CMx.ApplicationState
): featuresStateProps {
  const { organization } = state;

  return {
    features: organization.features ?? [],
    errors: formErrors.noErrors
  };
};

export const mapDispatchToProps = (dispatch: any): featuresDispatches => {
  const runDispatchable = buildDispatchable(dispatch);

  return {
    handleSave: (item: any) => {
      runDispatchable(() => {
        return dispatch(updateFeatures(item));
      });
    },
    handleCancel: () => {
      runDispatchable(() => {
        return dispatch(goBack());
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Features);
