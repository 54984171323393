import { Grid } from '@chakra-ui/react';
import React from 'react';
import { styleOverrides, styles } from './provider-directory.styles';
import { getProviderDirectoryClient } from '../../clients/provider-directory.client';

const classes = styles();
class ProviderDirectory extends React.Component<{}, {}> {
  componentDidMount(): void {
    const xcaliberClient = getProviderDirectoryClient();
    xcaliberClient.create('GRID_WIDGET', 'provider_directory');
  }

  render(): React.ReactNode {
    return (
      <div style={classes.root}>
        {/* @ts-ignore */}
        <Grid css={styleOverrides} id="provider_directory"></Grid>
      </div>
    );
  }
}

export { ProviderDirectory };
