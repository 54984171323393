import React from 'react';
import { cmxDateTime, commonEnums } from '@codametrix/ui-common';
import { ProviderFilters } from './providers';
import {
  StylesProvider,
  useMultiStyleConfig,
  useStyles,
  Flex,
  Box,
  Text,
  Spacer,
  List,
  ListItem,
  HStack,
  Divider
} from '@chakra-ui/react';
import { CMXIconComponent } from '../cmx-icon/cmx-icon';
import { IconNames } from '@codametrix/shared-views';

const filteredEventTypes: { [key: string]: string[] } = {
  ADT: ['A01', 'A02', 'A03', 'A08', 'A31']
};

const eventTypes: { [key: string]: { [key: string]: string } } = {
  ADT: {
    DEFAULT: 'REGISTRATION',
    A01: 'ADMIT A PATIENT',
    A02: 'TRANSFER A PATIENT',
    A03: 'DISCHARGE A PATIENT',
    A04: 'REGISTER A PATIENT',
    A05: 'PREADMIT A PATIENT',
    A06: 'TRANSFER AN OUTPATIENT TO INPATIENT',
    A07: 'TRANSFER AN INPATIENT TO OUTPATIENT',
    A08: 'UPDATE PATIENT INFORMATION',
    A09: 'PATIENT DEPARTING',
    A10: 'PATIENT ARRIVING',
    A11: 'CANCEL ADMIT',
    A12: 'CANCEL TRANSFER',
    A13: 'CANCEL DISCHARGE',
    A14: 'PENDING ADMIT',
    A15: 'PENDING TRANSFER',
    A16: 'PENDING DISCHARGE',
    A17: 'SWAP PATIENTS',
    A18: 'MERGE PATIENT INFORMATION',
    A19: 'PATIENT, QUERY',
    A20: 'NURSING/CENSUS APPLICATION UPDATES',
    A21: 'LEAVE OF ABSENCE - OUT (LEAVING)',
    A22: 'LEAVE OF ABSENCE - IN (RETURNING)',
    A23: 'DELETE A PATIENT RECORD',
    A24: 'LINK PATIENT INFORMATION',
    A25: 'CANCEL PENDING DISCHARGE',
    A26: 'CANCEL PENDING TRANSFER',
    A27: 'CANCEL PENDING ADMIT',
    A28: 'ADD PERSON INFORMATION',
    A29: 'DELETE PERSON INFORMATION',
    A30: 'MERGE PERSON INFORMATION',
    A31: 'UPDATE PERSON INFORMATION',
    A32: 'CANCEL PATIENT ARRIVING',
    A33: 'CANCEL PATIENT DEPARTING',
    A34: 'MERGE PATIENT INFORMATION - PATIENT ID ONLY',
    A35: 'MERGE PATIENT INFORMATION - ACCOUNT NUMBER ONLY',
    A36: 'MERGE PATIENT INFORMATION - PATIENT ID AND ACCOUNT NUMBER',
    A37: 'UNLINK PATIENT INFORMATION'
  },
  MDM: {
    DEFAULT: 'NOTE UPDATE',
    T02: 'NEW NOTE',
    T08: 'NOTE UPDATED',
    T11: 'NOTE CANCELED'
  },
  SIU: {
    DEFAULT: 'SURGERY UPDATE',
    S12: 'SCHEDULED SURGERY',
    S15: 'CANCELLED SURGERY'
  },
  ORM: {
    DEFAULT: 'CONSULT, OBSERVATION'
  },
  RDS: {
    DEFAULT: 'ADMINISTERED'
  },
  DFT: {
    DEFAULT: 'SUBMITTED TO BILLING'
  }
};

////Multipart Component
interface MessageProps {
  size: 'md';
  type: string;
  match: boolean;
  children: any;
}

function MapTypetoVariant(
  type: string,
  match?: boolean
): { variant: string; icon: string } {
  let variant: string;
  let timelineIcon: string;

  switch (type) {
    case 'ADT':
    case 'Registration':
      variant = 'registration';
      timelineIcon = 'BiomeADT';
      break;
    case 'DFT':
    case 'Charge':
      variant = 'charge';
      timelineIcon = 'BiomeDFT';
      break;
    case 'SIU':
    case 'Surgery':
      variant = 'surgery';
      timelineIcon = 'BiomeSIU';
      break;
    case 'ORM':
    case 'Orders':
      variant = 'order';
      timelineIcon = 'BiomeORM';
      break;
    case 'RDS':
    case 'Medication':
      variant = 'medication';
      timelineIcon = 'BiomeRDS';
      break;
    case 'MDM':
    case 'Notes':
      variant = 'note';
      timelineIcon = 'BiomeMDM';
      break;
    default:
      variant = '';
      timelineIcon = 'BiomeCoding';
      break;
  }

  if (match) {
    variant += 'Encounter';
  }

  return { variant: variant, icon: timelineIcon };
}

function Message(props: MessageProps) {
  const { size, type, match, children, ...rest } = props;
  let typeMap = MapTypetoVariant(type, match ?? false);
  let variant = typeMap.variant;
  const styles = useMultiStyleConfig('Messages', { size, variant });
  return (
    <Flex __css={styles.container} aria-label="flex-container" {...rest}>
      <Flex __css={styles.spacing}></Flex>
      <Flex __css={styles.contents}>
        <StylesProvider value={styles}>{children}</StylesProvider>
      </Flex>
    </Flex>
  );
}

interface MessageHeaderProps {
  children: any;
}

function MessageHeader(props: MessageHeaderProps) {
  const { children } = props;
  const styles = useStyles();
  return (
    <Flex aria-label="message-header" __css={styles.header}>
      <StylesProvider value={styles}>{children}</StylesProvider>
    </Flex>
  );
}

interface HeaderContentProps {
  type: string;
  description: string | JSX.Element | null;
}

function HeaderContent(props: HeaderContentProps) {
  const { description, type } = props;
  let typeMap = MapTypetoVariant(type, undefined);
  let typeIcon = typeMap.icon as IconNames;
  const styles = useStyles();
  return (
    <>
      <Flex __css={styles.type}>
        <CMXIconComponent
          size="4x"
          variant="light"
          iconName={typeIcon}></CMXIconComponent>
      </Flex>
      <Box __css={styles.connector}></Box>
      <HStack aria-label="header-content" __css={styles.bar}>
        <Text sx={styles.description}>{description}</Text>
        <Spacer __css={styles.headSpacer}></Spacer>
      </HStack>
    </>
  );
}

interface MessageBodyProps {
  children: any;
}

function MessageBody(props: MessageBodyProps) {
  const { children } = props;
  const styles = useStyles();
  return (
    <Flex aria-label="message-body" __css={styles.body} {...props}>
      <List __css={styles.list}>
        <StylesProvider value={styles}>{children}</StylesProvider>
      </List>
    </Flex>
  );
}

interface EntryItemProps {
  field: string;
  value: string;
  children?: any;
}

function EntryItem(props: EntryItemProps) {
  const { field, value, children } = props;
  const styles = useStyles();
  return (
    <ListItem role="menuitem" as="text" __css={styles.listitem}>
      <Text as="span" sx={styles.field}>
        {field}{' '}
      </Text>
      <Text as="span" sx={styles.value}>
        {value}
      </Text>
      {children}
    </ListItem>
  );
}

function OrderCommentDivider() {
  const styles = useStyles();
  return <Divider role="separator" __css={styles.commentDivider} />;
}

interface OrderCommentProps {
  field: string;
  value: string | null;
  children?: any;
  valueStyleName: string;
}

function OrderComment(props: OrderCommentProps) {
  const { field, value, children, valueStyleName } = props;
  const styles = useStyles();
  return (
    <ListItem aria-label="order-comment" as="text" __css={styles.listitem}>
      <Text as="span" sx={styles.commentField}>
        {field}{' '}
      </Text>
      <Text as="span" sx={styles[valueStyleName]}>
        {value}
      </Text>
      {children}
    </ListItem>
  );
}

interface MessageFooterProps {
  children: any;
}

function MessageFooter(props: MessageFooterProps) {
  const { children } = props;
  const styles = useStyles();
  return (
    <Flex aria-label="message-footer" __css={styles.footer}>
      <StylesProvider value={styles}>{children}</StylesProvider>
    </Flex>
  );
}

interface FooterContentProps {
  messageDate: string | JSX.Element | null;
  encounter: string;
  children?: any;
}

function FooterContent(props: FooterContentProps) {
  const { messageDate, encounter, children } = props;
  const styles = useStyles();
  return (
    <>
      <Text sx={styles.datetime}>{messageDate}</Text>
      <Spacer __css={styles.footSpacer}></Spacer>
      <Text sx={styles.encounter}>{encounter}</Text>
      {children}
    </>
  );
}

////End Multipart Component

type HeaderTemplates = {
  [key: string]: (
    caseRun: CMxAPI.CaseRunV2,
    entry: CMxAPI.TimelineEntry,
    orderTypeStatus?: CMxCommonApp.OrderTypeStatus[]
  ) => string | JSX.Element | CMxCommonApp.OrderTypeStatus[];
};

type FooterTemplates = {
  [key: string]: (
    caseRun: CMxAPI.CaseRunV2,
    entry: CMxAPI.TimelineEntry
  ) => string | JSX.Element;
};

type BodyTemplate<T> = {
  [key: string]: (caseRun: CMxAPI.CaseRunV2, entry: T) => string | JSX.Element;
};

type BodyADTTemplate = BodyTemplate<CMxAPI.ADTEntry>;
type BodySIUTemplate = BodyTemplate<CMxAPI.SIUEntry>;
type BodyORMTemplate = BodyTemplate<CMxAPI.ORMEntry>;
type BodyRDSTemplate = BodyTemplate<CMxAPI.RDSEntry>;
type BodyMDMTemplate = BodyTemplate<CMxAPI.MDMEntry>;
type BodyDFTTemplate = BodyTemplate<CMxAPI.DFTEntry>;

const getOrderStatusFromType = (
  orderTypeStatus: CMxCommonApp.OrderTypeStatus[],
  orderType: string
) => {
  const orderControl = orderTypeStatus?.find(
    item => item?.['Order Control Code'] === orderType
  );
  return orderControl ? orderControl?.['Description'] ?? '' : '';
};

// Define a function for the common parts of the templates
const createCommonTemplate = (
  caseRun: CMxAPI.CaseRunV2,
  entry: CMxAPI.TimelineEntry | CMxAPI.RDSEntry | CMxAPI.ORMEntry,
  messageTypeGetter: string,
  messageActionGetter: string
) => (
  <HeaderContent
    type={messageTypeGetter}
    description={messageActionGetter}></HeaderContent>
);

const createFooterTemplate = (
  caseRun: CMxAPI.CaseRunV2,
  entry: CMxAPI.TimelineEntry | CMxAPI.RDSEntry | CMxAPI.ORMEntry,
  eventDatetime: string
) => (
  <FooterContent
    messageDate={
      eventDatetime
        ? cmxDateTime.format(
            eventDatetime || '',
            cmxDateTime.FORMATS.DATE_TIME_UI
          )
        : 'No date'
    }
    encounter={
      entry?.encounter?.identifier
        ? `(${entry?.encounter.identifier})`
        : 'Encounter ID not available'
    }></FooterContent>
);

const headerTemplates: HeaderTemplates = {
  DEFAULT: (caseRun, entry) =>
    createCommonTemplate(
      caseRun,
      entry,
      commonEnums.EntriesMessageType[
        entry?.message?.type as keyof typeof commonEnums.EntriesMessageType
      ],
      convertToSentenceCaseHelper(entry?.message?.type, entry?.message?.code)
    ),
  RDS: (caseRun, entry) =>
    createCommonTemplate(
      caseRun,
      entry,
      commonEnums.EntriesMessageType[
        entry?.message?.type as CMxAPI.MessageType
      ],
      convertToSentenceCase(entry?.order_action ?? 'unknown')
    ),
  ORM: (caseRun, entry, orderTypeStatus) => {
    const diagnostic_service_category = convertToSentenceCase(
      entry?.diagnostic_service_category ?? 'unknown'
    );
    const orderStatus = getOrderStatusFromType(
      orderTypeStatus ?? [],
      entry?.['order_status']
    );
    const orderStatusLabel = convertToSentenceCase(orderStatus ?? 'unknown');
    const headerLabel = `${diagnostic_service_category}${
      orderStatus ? ' (' + orderStatusLabel + ')' : ''
    }`;
    return createCommonTemplate(
      caseRun,
      entry,
      commonEnums.EntriesMessageType[
        entry?.message?.type as CMxAPI.MessageType
      ],
      headerLabel
    );
  },
  MDM: (caseRun, entry) =>
    createCommonTemplate(
      caseRun,
      entry,
      commonEnums.EntriesMessageType[
        entry?.message?.type as CMxAPI.MessageType
      ],
      convertToSentenceCaseHelper(entry?.message?.type, entry?.message?.code)
    ),
  SIU: (caseRun, entry) =>
    createCommonTemplate(
      caseRun,
      entry,
      commonEnums.EntriesMessageType[
        entry?.message?.type as CMxAPI.MessageType
      ],
      convertToSentenceCaseHelper(entry?.message?.type, entry?.message?.code)
    )
};

const footerTemplates: FooterTemplates = {
  DEFAULT: (caseRun, entry) =>
    createFooterTemplate(caseRun, entry, entry?.meta?.event_datetime),
  RDS: (caseRun, entry) =>
    createFooterTemplate(caseRun, entry, entry?.meta?.event_datetime),
  ORM: (caseRun, entry) =>
    createFooterTemplate(caseRun, entry, entry?.meta?.event_datetime),
  MDM: (caseRun, entry) =>
    createFooterTemplate(caseRun, entry, entry?.note_date),
  SIU: (caseRun, entry) =>
    createFooterTemplate(caseRun, entry, entry?.service_date)
};

const StandardADTTemplate = (
  caseRun: CMxAPI.CaseRunV2,
  entry: CMxAPI.ADTEntry
) => {
  return (
    <>
      <EntryItem
        field="Facility:"
        value={na(entry?.location?.service_facility)}></EntryItem>
      <EntryItem
        field="Unit:"
        value={na(entry?.location?.point_of_care)}></EntryItem>
      <EntryItem
        field="Service:"
        value={na(entry?.hospital_service)}></EntryItem>
      <EntryItem field="Class:" value={na(entry?.patient_class)}></EntryItem>
      {ProviderFilters.attending(entry?.providers)}
      {ProviderFilters.admitting(entry?.providers)}
      <EntryItem
        field="Insurance:"
        value={entry?.insurances
          ?.map(insurance => insurance.company_name)
          .join(', ')}></EntryItem>
    </>
  );
};

const bodyADTTemplate: BodyADTTemplate = {
  DEFAULT: StandardADTTemplate,
  A01: StandardADTTemplate,
  A02: StandardADTTemplate,
  A03: StandardADTTemplate,
  A08: StandardADTTemplate,
  A31: StandardADTTemplate
};

const StandardSIUTemplate = (
  caseRun: CMxAPI.CaseRunV2,
  entry: CMxAPI.SIUEntry
) => {
  return (
    <>
      <EntryItem
        field="Specialty:"
        value={na(entry?.surgical_specialty)}></EntryItem>
      <EntryItem
        field="Description:"
        value={na(entry?.description)}></EntryItem>
      {ProviderFilters.primarySurgeon(entry?.providers)}
    </>
  );
};

const bodySIUTemplate: BodySIUTemplate = {
  DEFAULT: StandardSIUTemplate,
  S12: StandardSIUTemplate,
  S15: StandardSIUTemplate
};

const StandardMDMTemplate = (
  caseRun: CMxAPI.CaseRunV2,
  entry: CMxAPI.MDMEntry
) => {
  return (
    <>
      <EntryItem field="Type:" value={na(entry?.note_type)}></EntryItem>
      <EntryItem
        field="Specialty:"
        value={na(entry?.note_specialty)}></EntryItem>
      {ProviderFilters.authoring(entry?.providers)}
      {ProviderFilters.finalSignProvider(entry?.providers)}
    </>
  );
};

const bodyMDMTemplate: BodyMDMTemplate = {
  DEFAULT: StandardMDMTemplate,
  T02: StandardMDMTemplate,
  T08: StandardMDMTemplate,
  T11: StandardMDMTemplate
};

const domParser = new DOMParser();
const buildOrderComment = (
  htmlString: string,
  orderNoteID: any,
  orderNoteCategory: any
) => {
  let textContent = domParser.parseFromString(htmlString, 'text/html').body
    .textContent;
  if (textContent) {
    let textContentArray = textContent.split('->');
    let name =
      textContentArray[0] +
      (textContentArray[0].slice(-1) === '?' ||
      textContentArray[0].slice(-1) === ':'
        ? ''
        : ':');
    let value = textContentArray[1];
    return (
      <>
        <OrderComment
          key={orderNoteID}
          field={name}
          value={value}
          valueStyleName={
            orderNoteCategory === 'Admission' &&
            textContent.includes('Admitting Provider')
              ? 'value'
              : 'commentValue'
          }></OrderComment>
      </>
    );
  } else {
    return '';
  }
};

const bodyORMTemplate: BodyORMTemplate = {
  DEFAULT: (caseRun: CMxAPI.CaseRunV2, entry: CMxAPI.ORMEntry) => {
    return (
      <>
        <EntryItem
          field="Specialty:"
          value={na(entry?.diagnostic_serv_sect_id)}></EntryItem>
        {ProviderFilters.entering(entry?.providers)}
        {ProviderFilters.ordering(entry?.providers)}
        {ProviderFilters.admitting(entry?.providers)}
        {ProviderFilters.cosigning(entry?.providers)}
        {ProviderFilters.attending(entry?.providers)}
        {entry?.order_notes && entry.order_notes.length > 0 && (
          <>
            <OrderCommentDivider></OrderCommentDivider>
            {entry.order_notes.map((order_note: CMxAPI.OrderNote) =>
              buildOrderComment(
                order_note?.comment,
                order_note.id,
                entry.diagnostic_service_category
              )
            )}
          </>
        )}
      </>
    );
  }
};

const bodyRDSTemplate: BodyRDSTemplate = {
  DEFAULT: (caseRun: CMxAPI.CaseRunV2, entry: CMxAPI.RDSEntry) => {
    return (
      <>
        <EntryItem
          field="Medication:"
          value={na(entry.medication_administered)}></EntryItem>
        {ProviderFilters.medicationOrdering(entry?.providers)}
      </>
    );
  }
};

const bodyDFTTemplate: BodyDFTTemplate = {
  DEFAULT: (caseRun: CMxAPI.CaseRunV2, entry: CMxAPI.DFTEntry) => {
    return (
      <>
        {ProviderFilters.billing(entry?.providers)}
        <EntryItem
          field="Codes:"
          value={entry?.procedures?.join(', ')}></EntryItem>
      </>
    );
  }
};

const resolveBodyTemplate = (
  activeCaseRun: CMxAPI.CaseRunV2,
  entry: CMxAPI.TimelineEntry
) => {
  const messageType: CMxAPI.MessageType = entry.message?.type;
  const messageCode: string = entry.message?.code;

  type TemplateType = {
    ADT: BodyADTTemplate;
    SIU: BodySIUTemplate;
    MDM: BodyRDSTemplate;
    ORM: BodyORMTemplate;
    RDS: BodyRDSTemplate;
    DFT: BodyDFTTemplate;
  };

  const templates: Record<keyof TemplateType, any> = {
    ADT: bodyADTTemplate,
    SIU: bodySIUTemplate,
    MDM: bodyMDMTemplate,
    ORM: bodyORMTemplate,
    RDS: bodyRDSTemplate,
    DFT: bodyDFTTemplate
  };

  if (messageType && messageCode && templates[messageType]) {
    const templateFn =
      templates[messageType][messageCode] || templates[messageType]['DEFAULT'];

    if (typeof templateFn === 'function') {
      return templateFn(activeCaseRun, entry);
    }
  }

  return null;
};

const resolveHeadTemplate = (
  activeCaseRun: CMxAPI.CaseRunV2,
  entry: CMxAPI.TimelineEntry,
  orderTypeStatus?: CMxCommonApp.OrderTypeStatus[]
) => {
  if (typeof headerTemplates[entry?.message?.type] === 'function') {
    return headerTemplates[entry?.message?.type](
      activeCaseRun,
      entry as CMxAPI.ADTEntry,
      orderTypeStatus
    );
  } else if (typeof headerTemplates['DEFAULT'] === 'function') {
    return headerTemplates['DEFAULT'](
      activeCaseRun,
      entry as CMxAPI.ADTEntry,
      orderTypeStatus
    );
  }
  return null;
};

const resolveFootTemplate = (
  activeCaseRun: CMxAPI.CaseRunV2,
  entry: CMxAPI.TimelineEntry
) => {
  if (typeof footerTemplates[entry?.message?.type] === 'function') {
    return footerTemplates[entry?.message?.type](
      activeCaseRun,
      entry as CMxAPI.ADTEntry
    );
  } else if (typeof footerTemplates['DEFAULT'] === 'function') {
    return footerTemplates['DEFAULT'](activeCaseRun, entry as CMxAPI.ADTEntry);
  }
  return null;
};

const convertToSentenceCaseHelper = (
  messageType: string | undefined,
  messageCode: string | undefined
): string => {
  if (!messageType || !messageCode) {
    return '';
  }
  const messageTypeData = eventTypes?.[messageType];
  const messageCodeData =
    messageTypeData?.[messageCode] || messageTypeData?.DEFAULT || '';

  return convertToSentenceCase(messageCodeData);
};

const convertToSentenceCase = (initialText: string) => {
  if (initialText) {
    const converted = initialText?.toLowerCase();
    return converted?.charAt(0).toUpperCase() + converted.slice(1);
  }

  return '';
};

const na = (initial: string | undefined) => {
  return typeof initial !== 'object' ? initial ?? 'N/A' : '';
};

const isInteractionFromCase = (
  entry: CMxAPI.TimelineEntry,
  activeCaseRun: CMxAPI.CaseRun
) => {
  if (
    entry?.encounter?.type === 'HAR' &&
    entry?.encounter?.identifier &&
    activeCaseRun?.encounter_identifier?.number
  ) {
    return (
      entry?.encounter?.identifier ===
      activeCaseRun?.encounter_identifier?.number
    );
  }

  if (
    entry?.encounter?.type === 'CSN' &&
    entry?.encounter?.identifier &&
    activeCaseRun?.visit_number?.number
  ) {
    return entry?.encounter?.identifier === activeCaseRun?.visit_number?.number;
  }

  return false;
};

export {
  eventTypes,
  filteredEventTypes,
  resolveBodyTemplate,
  resolveHeadTemplate,
  resolveFootTemplate,
  isInteractionFromCase,
  EntryItem,
  Message,
  MessageHeader,
  MessageBody,
  MessageFooter
};
