import {
  fetchRequestStatus,
  requestInitiated
} from '../../../actions/dictionary-request.actions';
import { buildDispatchable } from '../../../actions/_action-utilities';

export const mapStateToProps = function(state: CMx.ApplicationState) {
  const dictionaryRequest = { ...state.dictionaryRequest };
  return {
    ...dictionaryRequest
  };
};

export const mapDispatchToProps = (dispatch: any) => {
  const runDispatchable = buildDispatchable(dispatch);
  let handle: any;
  let successRoute: string;

  const pingBackend = async (requestIds: number[]) => {
    const res = await dispatch(
      fetchRequestStatus({ requestIds, successRoute })
    );
    if (res.requestStatus !== 'COMPLETED' && !res.errorStatus) {
      const bound = pingBackend.bind(null, requestIds);
      handle = setTimeout(bound, 5000);
    }
  };

  return {
    onUnmount: () => {
      clearTimeout(handle);
    },
    onMount: (requestIdsStr: string[]) => {
      let requestIds = requestIdsStr.map(id => parseInt(id));
      const routes = window.location.pathname.split('/').slice(0, 6);
      successRoute = routes.join('/');

      runDispatchable(async () => {
        dispatch(requestInitiated({ requestIds, successRoute }));
        pingBackend(requestIds);
      });
    }
  };
};
