import {
  cmxReducerFactory,
  setTimeLineEntryPropTimeZone
} from './reducer-utils';
import InitialState from '../reducers/initial-state';
import { toggleDrawer, toggleDrawerContent } from '../actions/ui';
import {
  codeBenchActions,
  cmxDateTime,
  commonEnums,
  launchPageActions,
  AmplifyCore
} from '@codametrix/ui-common';
import { CheckboxOption } from '../components/checkbox-menu/checkbox-menu';
import { fetchContexts } from '../actions/ui';

const { conditionalUtils } = AmplifyCore;

export const reducer = cmxReducerFactory(InitialState.patientTimeline)
  .case(toggleDrawerContent, (state, isOpen) => {
    return { ...state, isOpen };
  })
  .case(toggleDrawer, (state, isDrawerOpen) => {
    return { ...state, isDrawerOpen };
  })
  .case(fetchContexts.async.started, (state, result) => {
    return {
      ...state,
      isDrawerOpen: false,
      entries: [],
      entriesLoading: false,
      entriesLastPage: false,
      entryPage: 1,
      entriesMessageCounts: [],
      entriesMessageCountsLoading: false,
      entriesSumCounts: 0
    };
  })
  .case(launchPageActions.loadFromURLMatch.async.started, state => {
    return {
      ...state,
      dateFilter: commonEnums.DateFilterLabelEnum.ALL_EVENTS,
      dateFilterStr: '',
      filterTypes: [],
      entries: []
    };
  })
  .case(codeBenchActions.navigatePatient.async.started, (state, caseRun) => {
    return {
      ...state,
      activeCaseRun: caseRun,
      isDrawerOpen: true,
      entriesFailed: false,
      entries: [],
      entriesLoading: false,
      entriesLastPage: false,
      entryPage: 1,
      entriesMessageCounts: [],
      entriesMessageCountsLoading: false,
      entriesSumCounts: 0,
      isFirstLoad: true
    };
  })
  .case(codeBenchActions.navigatePatient.async.done, state => {
    return { ...state, isOpen: true };
  })
  .case(codeBenchActions.getPatientMessageCounts.async.started, state => {
    return {
      ...state,
      entriesMessageCounts: [],
      entriesMessageCountsLoading: true,
      entriesSumCounts: 0
    };
  })
  .case(codeBenchActions.getPatientMessageCounts.async.failed, state => {
    return {
      ...state,
      entriesMessageCounts: [],
      entriesMessageCountsLoading: false,
      entriesSumCounts: 0
    };
  })
  .cases(
    [
      codeBenchActions.getPatientMessageCounts.async.done,
      codeBenchActions.getPatientMessageCountsWithFilter.async.done
    ],
    (state, { result: entriesMessageCounts }) => {
      return {
        ...state,
        entriesMessageCounts,
        entriesMessageCountsLoading: false,
        entriesSumCounts:
          entriesMessageCounts?.length > 0
            ? entriesMessageCounts
                .map(entry => entry.count)
                .reduce(
                  (prevEntryCount, currentEntryCount) =>
                    prevEntryCount + currentEntryCount
                )
            : 0
      };
    }
  )
  .case(codeBenchActions.getCaseEntries.async.started, state => {
    return {
      ...state,
      entriesLoading: true,
      isFirstLoad: false
    };
  })
  .case(codeBenchActions.getCaseEntriesWithFilter.async.started, state => {
    return {
      ...state,
      entriesLoading: true,
      entries: [],
      isFirstLoad: false
    };
  })
  .case(codeBenchActions.caseOrganization, (state, organization) => {
    //@ts-ignore
    return { ...state, organization };
  })
  .cases(
    [
      codeBenchActions.getCaseEntries.async.done,
      codeBenchActions.getCaseEntriesWithFilter.async.done
    ],
    (state, payload) => {
      const { result: timeline, params } = payload;
      if (timeline.success === false) {
        return {
          ...state,
          entriesLoading: false,
          entriesFailed: true
        };
      }

      const timeZonestr =
        state?.organization?.timeZoneFullName ??
        state?.activeCaseRun?.tenant_timezone;

      const updatedTimeline = timeline?.content?.map(entry => {
        let updatedEntry = { ...entry };
        ['meta.event_datetime', 'note_date', 'service_date'].forEach(
          dateProperty => {
            setTimeLineEntryPropTimeZone(
              updatedEntry,
              dateProperty,
              timeZonestr
            );
          }
        );
        return updatedEntry;
      });

      const sortedEntries = [
        ...state.entries,
        ...updatedTimeline
      ]?.sort((groupA: CMxAPI.TimelineEntry, groupB: CMxAPI.TimelineEntry) =>
        cmxDateTime.sortByDate(
          groupA?.message?.type === 'MDM'
            ? groupA?.note_date
            : groupA?.message?.type === 'SIU'
            ? groupA?.service_date
            : groupA?.meta?.event_datetime,
          groupB?.message?.type === 'MDM'
            ? groupB?.note_date
            : groupB?.message?.type === 'SIU'
            ? groupB?.service_date
            : groupB?.meta?.event_datetime
        )
      );

      const isTimelineInfiniteLoadOn = conditionalUtils.capabilityValue(
        params?.timelineInfiniteLoad
      );

      return {
        ...state,
        entries: sortedEntries,
        entryPage: timeline?.pageable?.pageNumber,
        numberOfElements: timeline?.numberOfElements,
        entriesLoading: false,
        entriesLastPage: timeline?.last,
        filterTypes: isTimelineInfiniteLoadOn ? state.filterTypes : [],
        typesCounts: fillTypesCounts(sortedEntries),
        dateFilter: isTimelineInfiniteLoadOn
          ? state.dateFilter
          : commonEnums.DateFilterLabelEnum.ALL_EVENTS
      };
    }
  )
  .case(codeBenchActions.getCaseEntries.async.failed, state => {
    return {
      ...state,
      entries: [],
      entryPage: 1,
      numberOfElements: 0,
      entriesLoading: false,
      entriesLastPage: false,
      filterTypes: []
    };
  })
  .case(codeBenchActions.setFilterTypes, (state, filterTypes) => {
    return {
      ...state,
      filterTypes: filterTypes as CheckboxOption[]
    };
  })
  .case(codeBenchActions.setFilterDates, (state, dateFilter) => {
    return {
      ...state,
      dateFilter: dateFilter?.dateFilter
    };
  })
  .case(codeBenchActions.setFilterTypesInfinite, (state, filterTypes) => {
    return {
      ...state,
      filterTypes: filterTypes as CheckboxOption[],
      entries: []
    };
  })
  .case(
    codeBenchActions.fetchOrderTypeStatus.async.done,
    (state, { result: dictionaryValues }) => {
      return {
        ...state,
        orderTypeStatus: dictionaryValues?.rowValues ?? []
      };
    }
  )
  .case(codeBenchActions.setFilterDatesInfinite, (state, dateFilter) => {
    return {
      ...state,
      dateFilter: dateFilter?.dateFilter,
      dateFilterStr: dateFilter?.dateFilterStr ?? '',
      entries: []
    };
  });

const fillTypesCounts = (entries: CMxAPI.TimelineEntry[]): CMx.TypesCounts => {
  const counts: { [key in CMxAPI.MessageType]: number } = {
    ADT: 0,
    SIU: 0,
    ORM: 0,
    RDS: 0,
    MDM: 0,
    DFT: 0
  };

  for (const key in commonEnums.EntriesMessageType) {
    if (
      Object.prototype.hasOwnProperty.call(commonEnums.EntriesMessageType, key)
    ) {
      const messageType = key as CMxAPI.MessageType;
      counts[messageType] = entries.filter(
        entry => entry?.message?.type === messageType
      ).length;
    }
  }

  return counts;
};

export default reducer;
