import { push } from 'connected-react-router';

const navExport = function(dispatch: any) {
  return (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    const url = new URL((event.target as any).href);
    dispatch(push(`${url.pathname}`));
    // dummy action to clear warnings, feedback etc.
    dispatch({
      type: 'meaningless',
      meta: { user: true }
    });
  };
};

export { navExport };
