import { cmxReducerFactory } from './reducer-utils';
import InitialState from './initial-state';
import externalApplications, {
  syncActions
} from '../actions/external-applications';
import { LOAD_STATES } from './common';
import { externalApplications as externalAppFixtures } from '../stubs/external-applications';

const reducer = cmxReducerFactory(InitialState.externalApplications)
  .case(externalApplications.listExternalApps.async.started, state => {
    return {
      ...state,
      externalApplicationList: {
        ...state.externalApplicationList,
        loadState: LOAD_STATES.initial,
        items: []
      }
    };
  })
  .case(externalApplications.listExternalApps.async.failed, state => {
    return { ...state, loadState: LOAD_STATES.failed };
  })
  .case(
    externalApplications.listExternalApps.async.done,
    (state, { result }) => {
      const { sortablePageable } = state.externalApplicationList;

      const sp = {
        ...sortablePageable,
        pageableDefinition: {
          ...result
        }
      };

      return {
        ...state,
        externalApplicationList: {
          ...state.externalApplicationList,
          sortablePageable: sp,
          loadState: LOAD_STATES.done
        }
      };
    }
  )
  .case(externalApplications.listExternalAppUsers.async.started, state => {
    const {
      selectedExternalApplication: { externalApplicationName }
    } = state;
    return {
      ...state,
      externalApplicationUserList: {
        ...state.externalApplicationUserList,
        loadState: LOAD_STATES.initial,
        items: []
      },
      selectedUser: {
        ...externalAppFixtures.defaultExternalApplicationUser,
        externalApplicationName: externalApplicationName
      }
    };
  })
  .case(externalApplications.listExternalAppUsers.async.failed, state => {
    return { ...state, loadState: LOAD_STATES.failed };
  })
  .case(
    externalApplications.listExternalAppUsers.async.done,
    (state, { result }) => {
      const { sortablePageable } = state.externalApplicationList;

      const sp = {
        ...sortablePageable,
        pageableDefinition: {
          ...result
        }
      };

      return {
        ...state,
        externalApplicationUserList: {
          ...state.externalApplicationUserList,
          sortablePageable: sp,
          loadState: LOAD_STATES.done
        }
      };
    }
  )

  .case(syncActions.selectExternalApplication, (state, param) => {
    return {
      ...state,
      selectedExternalApplication: param
    };
  })
  .case(syncActions.selectExternalAppUser, (state, param) => {
    return {
      ...state,
      selectedUser: param
    };
  })
  .case(externalApplications.saveExternalAppUser.async.started, state => {
    return {
      ...state,
      loadState: LOAD_STATES.initial
    };
  })
  .case(
    externalApplications.saveExternalAppUser.async.failed,
    (state, fail) => {
      console.log(fail);
      return {
        ...state,
        loadState: LOAD_STATES.failed,
        userFormErrors: fail.error.errors
      };
    }
  )
  .case(externalApplications.saveExternalAppUser.async.done, state => {
    return {
      ...state,
      selectedUser: externalAppFixtures.defaultExternalApplicationUser
    };
  })
  .case(syncActions.selectOrgnization, (state, param) => {
    return {
      ...state,
      selectedOrgId: param
    };
  })
  .case(syncActions.setStateToDefault, (state, param) => {
    const {
      selectedExternalApplication: { externalApplicationName }
    } = state;
    return {
      ...state,
      selectedUser: {
        ...param,
        externalApplicationName: externalApplicationName
      }
    };
  });

export default reducer;
