import React from 'react';
import { Flex } from '@chakra-ui/react';
import './working-hours.scss';
import { styles } from './working-hours.styles';
import Input from '../../components/input/input';

const WorkingHours: React.FC<AppProps.WorkingHoursProps> = props => {
  const { hours, activeDays, setHours, setActiveDays } = props;
  const classes = styles();

  const toggleDay = (day: string) => {
    if (hours[day] > 0 && activeDays[day]) {
      hours[day] = 0;
    }
    setActiveDays({
      ...activeDays,
      [day]: !activeDays[day]
    });
  };

  const handleHourChange = (day: string, value: number) => {
    if ((value <= 24 && value > -1) || Number.isNaN(value)) {
      if (Number.isNaN(value)) {
        value = 0;
      }
      setHours({
        ...hours,
        [day]: value
      });
    }
  };

  const calculateTotalHours = () => {
    let total = 0;
    for (const day in hours) {
      if (!Number.isNaN(hours[day]) && hours[day] > 0 && hours[day] <= 24) {
        total += hours[day];
      }
    }
    return total;
  };

  const refreshActiveDays = () => {
    let newActiveDays = activeDays;
    Object.keys(activeDays).forEach(day =>
      hours[day] > 0 && !activeDays[day]
        ? (newActiveDays[day] = true)
        : (newActiveDays[day] = activeDays[day])
    );
    setActiveDays(newActiveDays);
    return newActiveDays;
  };

  return (
    <Flex flexDirection={'column'}>
      <div style={classes.dayContainer}>
        {Object.keys(refreshActiveDays()).map((day: string, index: number) => (
          <label key={index} className="day-checkbox">
            <input
              type="checkbox"
              checked={activeDays[day]}
              onChange={() => toggleDay(day)}
            />
            <span className="checkmark">
              <span className="day-label">{day.substring(0, 1)}</span>
            </span>
          </label>
        ))}
      </div>
      <div>
        {Object.keys(hours).map(
          (day: string) =>
            activeDays[day] && (
              <Flex key={day} sx={classes.hoursRow}>
                <label style={classes.dayInputLabel}>{day} </label>
                <div style={classes.hourInputContainer}>
                  <Input
                    className={'hour-input'}
                    type="number"
                    value={hours[day].toString()}
                    onChange={e =>
                      handleHourChange(day, parseInt(e.target.value))
                    }
                  />
                  <span style={classes.hourInputLabel}>hours</span>
                </div>
              </Flex>
            )
        )}
      </div>
      <Flex>
        <label style={classes.totalLabel}>TOTAL HOURS: </label>
        <span style={classes.totalValue}>{calculateTotalHours()}</span>
      </Flex>
    </Flex>
  );
};

export { WorkingHours };
