import { CSSProperties } from 'react';
import theme from '../../theme';

export const styles = (): Record<string, CSSProperties> => ({
  menuButton: {
    height: theme.space[24],
    paddingRight: theme.space[8]
  },
  activeMenuButton: {
    backgroundColor: theme.colors.structural[200],
    borderRadius: theme.radii[3]
  },
  tag: {
    borderRadius: theme.radii[18],
    padding: `0 ${theme.space[4]}`,
    lineHeight: theme.space[12],
    height: theme.space[12],
    verticalAlign: 'middle',
    backgroundColor: theme.colors.foundation[300],
    marginLeft: `-${theme.space[12]}`
  },
  tagText: {
    fontSize: theme.space[8],
    margin: 'auto'
  },
  menuList: {
    padding: `0 ${theme.space[16]} ${theme.space[12]} ${theme.space[16]}`,
    border: `${theme.borders['1px']} ${theme.colors.stroke[100]}`,
    borderRadius: theme.radii[3],
    maxHeight: theme.space[400],
    overflowY: 'auto'
  },
  menuItem: {
    borderRadius: theme.radii[3]
  },
  labelButton: {
    color: theme.colors.text['highEmphasis'],
    ...theme.textStyles['Caption 2'],
    marginLeft: `-${theme.space[12]}`
  },
  dividerColor: {
    color: theme.colors.foundation[200]
  },
  menuListHeaderText: {
    ...theme.textStyles['Subtitle 2']
  },
  clearButton: {
    ...theme.textStyles['Overline 1'],
    paddingRight: 0
  },
  selectedMenuItem: {
    backgroundColor: theme.colors.opacity.primary
  },
  topDivider: {
    marginBottom: theme.space[12]
  },
  bottomDivider: {
    margin: `${theme.space[8]} 0 ${theme.space[12]} 0`
  },
  footerText: {
    ...theme.textStyles['Subtitle 1'].fontSize
  }
});
