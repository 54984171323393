import {
  authenticationContext,
  cmxConfiguaration,
  cmxURL,
  navigation
} from '@codametrix/ui-common';

const STORAGE_CLEANUP = 2 * 60 * 1000;

const config = cmxConfiguaration.default;
const _Authorization = authenticationContext.getAuthorization();

const isExempted = (location: string) => {
  const partials = config.authenticationContext.partialUrls.some(
    exempted => location.indexOf(exempted) !== -1
  );
  if (partials) {
    return true;
  }
  return config.authenticationContext.exactUrls.some(
    // eslint-disable-next-line
    exempted => location == exempted
  );
};

const cleanUpStorage = () => {
  window.sessionStorage.removeItem('jumpURL');
  window.sessionStorage.removeItem('contextId');
  if (_cleanupStorageTimeout) {
    clearTimeout(_cleanupStorageTimeout);
    _cleanupStorageTimeout = null;
  }
};

let _cleanupStorageTimeout: NodeJS.Timeout | null = null;

export const saveJump = () => {
  const currentURL = new URL(`${window?.top?.location}`);
  const exempt = isExempted(currentURL.pathname);

  if (!exempt) {
    if (navigation.isReload(window)) {
      window.sessionStorage.setItem('jumpURL', cmxURL.pathBuilder(currentURL));
    }
    // someone may have encountered the page as an already
    // logged in users or they may be accessing it as a link
    // without a primed session.
    if (_Authorization.contextId !== null) {
      window.sessionStorage.setItem('contextId', `${_Authorization.contextId}`);
    }
    // only populate sessionStorage for a limite period of time.
    // if someone doesn't log in within two minutes
    _cleanupStorageTimeout = setTimeout(cleanUpStorage, STORAGE_CLEANUP);
  }
};

export const getJump = () => {
  // this function is destructive. you only have one chance to jump.
  const jumpURL = window.sessionStorage.getItem('jumpURL') || false;
  const jumpContext = window.sessionStorage.getItem('contextId');
  const hasContext = jumpContext !== null;

  cleanUpStorage();
  return {
    url: jumpURL,
    hasContext,
    contextId: parseInt(jumpContext ?? '0', 10)
  };
};

// this is really a private function and should not be exposed.
// it is only being exposed here for testing purposes.
export const _handleUnload = (event: Event) => {
  // auth token needs to be present for page refresh.
  if (_Authorization.token) {
    window.sessionStorage.setItem('authToken', `${_Authorization.token}`);
  }
  saveJump();
};

window.addEventListener('unload', _handleUnload);
