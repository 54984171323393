import React, { useEffect, useState } from 'react';
import { Box, Grid, GridItem } from '@chakra-ui/react';
import { styles } from './organization.styles';
import {
  colDefs,
  ORGANIZATION_CONSTANTS,
  organizationTypes,
  DISPLAY_NAMES
} from './organization-constants';
import { DataGrid } from '../../components/data-grid/data-grid';
import { GridOptions } from 'ag-grid-community';
import { OrganizationForm } from './organization-form';
import TopLevelError from './top-level-error';
import { isServiceOrganization } from '../../actions/_action-utilities';
import STUB_ORG from './stub-organization';
import { SubHeader } from './subheader';
import { ButtonVariant } from '../../core/enums';
import { AssociateOrganizationForm } from './associate-organization-form';

const gridOptions: GridOptions = {
  suppressHorizontalScroll: true
};

const getFilteredOrgTypes = (organizations: CMx.OrgChildren) => {
  return organizationTypes.filter(
    orgType => orgType.value && organizations.hasOwnProperty(orgType.value)
  );
};

const Organization: React.FC<AppProps.EditOrg> = props => {
  const classes = styles();
  const [rowData, setRowData] = useState<Array<CMxAPI.Organization>>([]);

  const {
    edit,
    activeOrganization: org,
    formErrors,
    addChild,
    organizations,
    associations,
    relationship,
    orgRelationships,
    serviceLines,
    viewOnly,
    timezones,
    auth,
    addChildOrgType
  } = props;

  const { errors } = formErrors || {};
  const { fieldErrors } = errors || {};
  const orgType = addChild as string;
  const associate = isServiceOrganization(orgType);
  const orgTypeName = DISPLAY_NAMES[orgType] ?? 'Unknown';
  const [openAssociateForm, setOpenAssociateForm] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(25);

  useEffect(() => {
    const {
      match: { params },
      activeOrganization
    } = props;
    if (params.id && activeOrganization) {
      props.getOrganization({
        params: { token: props.auth, id: params.id },
        activeTenantId: activeOrganization.tenantId
      });
    } else if (activeOrganization) {
      props.getOrganization({
        params: {
          token: props.auth,
          id: props.activeOrganization.id
        },
        activeTenantId: activeOrganization.tenantId
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (organizations) {
      let orgs: CMxAPI.Organization[] = [];
      Object.keys(organizations)?.forEach(
        org => (orgs = [...orgs, ...organizations[org]])
      );
      setRowData(orgs);

      for (const [key] of Object.entries(organizations)) {
        /** get associations if org is service organization */
        if (isServiceOrganization(key)) {
          props.addChildOrg(
            props.viewOnly,
            props.auth,
            key,
            props.activeOrganization,
            false
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizations]);

  const handleEditToggle = (event: React.MouseEvent) => {
    event.preventDefault();
    props.toggleEdit(!props.edit);
  };

  const handleCancel = () => {
    if (openAssociateForm) setOpenAssociateForm(false);
    props.toggleEdit(false);
  };

  /**updates existing organization */
  const handleSubmit = (org: CMxAPI.Organization) => {
    props.doSave(props.auth, org);
  };

  /** saves new organization */
  const handleFormSave = (org: CMxAPI.Organization) => {
    const { activeOrganization } = props;
    const organizationType = props.addChild as string;
    const data = {
      ...org,
      parentTenantId: activeOrganization.tenantId,
      parentLevel: activeOrganization.parentLevel + 1,
      organizationType
    };
    props.doSave(props.auth, data);
  };

  const handleAddChildOrg = (orgType: string | boolean) => {
    addChildOrgType && addChildOrgType(orgType);
  };

  const handleAssociation = (
    edits: CMx.PartialRelationshipEdit[],
    orgToSave?: CMxAPI.Organization
  ) => {
    props.doAssociation(props.auth, edits, orgToSave);
  };

  const onRowSelection = (data: CMxAPI.Organization) => {
    const payload: {
      params: CMx.GetOrganizationArgs;
      activeTenantId: string;
    } = {
      params: { token: auth, id: data.id },
      activeTenantId: org.tenantId
    };
    if (data.parent) {
      payload.params.referringOrgId = data.parent.id;
    }
    props.getOrganization(payload);
  };

  const handleAssociateBtnClick = () => {
    addChildOrgType && addChildOrgType('PHYSICIAN_ORGANIZATION');
    setOpenAssociateForm(true);
  };

  const onPageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
  };

  return (
    <Box sx={classes.root}>
      <Grid sx={classes.content}>
        {addChild ? (
          associate ? (
            <AssociateOrganizationForm
              organization={STUB_ORG}
              parentOrg={org}
              orgType={orgType}
              associations={associations}
              serviceLines={serviceLines}
              orgRelationships={orgRelationships}
              relationship={relationship}
              errors={fieldErrors}
              cancel={handleCancel}
              doAssociation={handleAssociation}
              viewOnly={viewOnly}
              timezones={timezones}
              title={
                openAssociateForm
                  ? `Associate ${orgTypeName}`
                  : `Add ${orgTypeName}`
              }
              openAssociateForm={openAssociateForm}
            />
          ) : (
            <div>
              <TopLevelError errors={formErrors && formErrors.errors} />
              <OrganizationForm
                title={`Add ${orgTypeName}`}
                errors={fieldErrors ?? {}}
                relationship={relationship}
                cancel={handleCancel}
                doSave={handleFormSave}
                orgRelationships={orgRelationships}
                serviceLines={serviceLines}
                orgType={addChild}
                viewOnly={viewOnly}
                timezones={timezones}
              />
            </div>
          )
        ) : edit ? (
          isServiceOrganization(org.organizationType) ? (
            <AssociateOrganizationForm
              orgRelationships={orgRelationships}
              organization={org}
              relationship={relationship}
              parentOrg={STUB_ORG}
              associations={associations}
              errors={fieldErrors}
              serviceLines={serviceLines}
              doAssociation={handleAssociation}
              cancel={handleCancel}
              orgType={org.organizationType}
              viewOnly={viewOnly}
              edit
              timezones={timezones}
              title={org.displayName ?? ''}
            />
          ) : (
            <div className="cmx-form">
              <TopLevelError errors={formErrors && formErrors.errors} />
              <OrganizationForm
                title={org.displayName ?? ''}
                errors={fieldErrors ?? {}}
                relationship={relationship}
                cancel={handleCancel}
                doSave={handleSubmit}
                orgRelationships={orgRelationships}
                serviceLines={serviceLines}
                organization={org}
                orgType={org.organizationType}
                viewOnly={viewOnly}
                edit
                timezones={timezones}
              />
            </div>
          )
        ) : (
          <Grid display="flex" flexDirection="column" rowGap={5}>
            <GridItem>
              <SubHeader
                menuButtonName={viewOnly ? '' : 'Add'}
                handleMenuItemClick={handleAddChildOrg}
                menuItems={getFilteredOrgTypes(organizations)}
                title={ORGANIZATION_CONSTANTS.TENANTS}
                href={!props.addChild ? '/org/edit' : ''}
                className={classes.text}
                handleAnchorElementClick={handleEditToggle}
                anchorButtonName={viewOnly ? '' : 'Edit'}
                anchorButtonVariant={ButtonVariant.SECONDARY}
                primaryBtnName={
                  associations.length && !viewOnly ? 'Associate' : ''
                }
                onPrimaryBtnClick={handleAssociateBtnClick}
                pageSize={pageSize}
                onPageSizeChange={onPageSizeChange}
              />
            </GridItem>
            <GridItem display="flex" alignItems="flex-start">
              <DataGrid
                rowData={rowData.filter(org => org.active !== false)}
                columnDefs={colDefs}
                gridOptions={gridOptions}
                onRowClicked={data => onRowSelection(data?.data)}
                paginationPageSize={pageSize}
              />
            </GridItem>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

Organization.displayName = 'Organization';
export { Organization };
