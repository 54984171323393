import React, { useState } from 'react';
import { BreadCrumbsComponent } from '../../components/breadcrumbs/breadcrumbsComponent';
import { Form } from '../dictionary/form';
import { groups } from '../../stubs/groups';

import { groupTypes } from './groups-constants';

const groupName: CMxCommonApp.FieldDefinition = {
  label: `Name`,
  key: `groupName`,
  required: true,
  type: 'text',
  readonly: false
};

const groupTypeOptions = groupTypes;

const groupType: CMxCommonApp.FieldDefinition = {
  label: 'Type',
  key: 'groupType',
  required: true,
  type: 'select',
  name: 'groupType',
  options: groupTypeOptions,
  labelField: 'label',
  valueField: 'value'
};

const members: CMxCommonApp.FieldDefinition = {
  label: 'Members',
  key: 'members',
  readonly: true,
  enabled: false,
  name: 'Members',
  type: 'text',
  helpText: 'Create the group first, then you may add members'
};

const GroupInfo: CMxCommonApp.FieldGroup = {
  label: `Group Information`,
  fields: [groupName, groupType]
};

const buttons = [
  { buttonType: 'submit', buttonText: 'Create' },
  { buttonType: 'link', buttonText: 'cancel' }
];

export const AddGroup: React.FC<AppProps.GroupsAddFormProps> = props => {
  const { handleCancel, errors, handleSave, tenantId } = props;

  const initialGroup = {
    ...groups.defaultGroup,
    tenantId
  };

  let [addGroup, setAddGroup] = useState<CMxAPI.Group>(initialGroup);

  const formDefinition = () => {
    const formDef: CMxCommonApp.FormDefintion = {
      fieldGroups: [
        {
          ...GroupInfo
        }
      ],
      fields: [members],
      buttons
    };
    return formDef;
  };

  const enableForm = () => {
    return addGroup.groupName.length !== 0 && addGroup.groupType.length !== 0;
  };

  const handleFormChange = (prop: string, value: string) => {
    let editedGroup = addGroup;
    if (prop === 'groupName') {
      editedGroup.groupName = value;
    }
    if (prop === 'groupType') {
      editedGroup.groupType = value;
    }
    setAddGroup(editedGroup);
  };

  return (
    <div>
      <BreadCrumbsComponent />
      <Form
        title={'Add Group'}
        formDefinition={formDefinition()}
        dataItem={addGroup}
        enable={enableForm}
        errors={errors}
        inline={false}
        onAction={handleCancel}
        onSave={handleSave}
        onPropChange={handleFormChange}
      />
    </div>
  );
};
