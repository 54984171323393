import { CMxComponents } from '@codametrix/ui-components/src/types';

import { defaultFilterableOptions } from '../../core/default-filterable-options';
import { loadStates } from '@codametrix/ui-common';
import { AmplifyCore } from '@codametrix/ui-common';
import { formErrors } from '../../stubs/form-errors';
import { groups } from '../../stubs/groups';

const { paginationUtils } = AmplifyCore;
const { LOAD_STATES } = loadStates;

export const nameSortableOption: CMxCommonApp.SortableOption = {
  sortField: 'name',
  sortDirection: 'ASC'
};

const sortablePageable: CMxCommonApp.SortablePageable<any> = {
  pageableDefinition: { ...paginationUtils.emptyPageable },
  sortableOptions: [nameSortableOption],
  filterableOptions: defaultFilterableOptions
};

const columnDefs: CMxComponents.ColumnDefintion[] = [
  {
    key: 'name',
    displayName: 'Name'
  },
  {
    key: 'type',
    displayName: 'Type'
  }
];

export const group: CMx.GroupsState = {
  groupsList: {
    loadState: LOAD_STATES.done,
    columnDefs,
    label: 'Group',
    itemName: 'Groups',
    sortablePageable
  },
  groupsFormErrors: formErrors.noErrors,
  items: [],
  selectedOrg: {
    id: -1
  },
  selectedGroup: { ...groups.defaultGroup },
  eligibleGroupMembers: []
};
