import { createBrowserHistory } from 'history';
import {
  applyMiddleware,
  compose,
  createStore,
  Middleware,
  Reducer
} from 'redux';
import logger from 'redux-logger';
import { thunk } from 'redux-thunk';
import * as storage from 'redux-storage';
import { replacer, reviver } from './session-storage-engine'; //createSessionStorageEngine
import InitialState from '../reducers/initial-state';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from '../reducers';
import authMiddleware from '../core/middleware/auth-middleware';
import windowOpenMiddleware from '../core/middleware/window-open-middleware';
import {
  authenticationChannelMiddlewareFactory,
  authenticationChannelReceiverFactory
} from '../core/middleware/authentication-channel-middleware';
import { Auth } from '../actions/action-types';
import { importTableauMiddleware } from '../core/middleware/load-script';
import {
  receiverSubscriptions,
  senderSubscriptions
} from '../core/authentication-channel/parent-window-subscriptions';
import { setIsActive } from '../core/net';
import '../core/receivers/post-message-receiver';
import analyticsMiddleware from '../core/middleware/analytics-middleware';
import newDesignMiddleware from '../core/middleware/new-design-middleware';
import { serviceDeskMiddleware } from '../core/middleware/servicedesk.middleware';
import stateDisinfectantMiddleware from '../core/middleware/state-disinfectant-middleware';
import {
  AmplifyMiddlewares,
  authenticationChannel,
  navigation,
  objectUtils
} from '@codametrix/ui-common';

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// build initial state
let initialState: CMx.ApplicationState = objectUtils.cloneDeep(InitialState);
const serialisedSavedState = window.sessionStorage.getItem('state');
window.sessionStorage.removeItem('state');

if (serialisedSavedState) {
  const savedState: CMx.ApplicationState = JSON.parse(
    serialisedSavedState,
    reviver
  );

  initialState = {
    ...InitialState,
    ...savedState,
    cases: { ...initialState.cases, ...savedState.cases }
  };
}
const token = window.sessionStorage.getItem('authToken'); // sync call - look to make async
window.sessionStorage.removeItem('authToken');
if (token) {
  initialState.login = {
    ...initialState.login,
    auth: { token, expiryTimeToLive: null }
  };
}
//@ts-ignore
delete initialState.router;

export const history = createBrowserHistory();

let middlewares: Middleware[] = [
  routerMiddleware(history),
  thunk,
  authMiddleware,
  importTableauMiddleware,
  windowOpenMiddleware,
  authenticationChannelMiddlewareFactory(senderSubscriptions),
  analyticsMiddleware,
  newDesignMiddleware,
  AmplifyMiddlewares.xcaliberTokenMiddleware,
  serviceDeskMiddleware,
  stateDisinfectantMiddleware
];

const rootReducer = createRootReducer(history);
let reducer: Reducer;
let engine: any;

reducer = rootReducer;

if (!(process.env.JEST_WORKER_ID || process.env.NODE_ENV === 'production')) {
  middlewares.push(logger as Middleware);
}

// --------------------------------
// store configuration
// ---------------------------------
const store = createStore(
  reducer, // root reducer with router state
  initialState,
  composeEnhancers(applyMiddleware(...middlewares))
);
// once store is created, hook up subscriptions
authenticationChannelReceiverFactory(receiverSubscriptions, store);

// private function
const _handleUnload = (event: Event) => {
  try {
    // There are certain properties that don't serialize well.
    // The organization portion of the state tree contains cycles and
    // will cause the stringify call to fail.  The application does
    // not need this to refresh however.
    const {
      cases: { criteriaFilter, sortablePageable, items, roles },
      ...rest
    } = store.getState();

    const result = {
      cases: {
        criteriaFilter,
        sortablePageable,
        items,
        roles,
        refreshList: true
      },
      ...rest
    };
    const serializedState = JSON.stringify(result, replacer);
    window.sessionStorage.setItem('state', serializedState);
  } catch (err) {
    console.error(err);
  }
};

window.addEventListener('beforeunload', _handleUnload);
// authentication channel requires store data to trasnfer ui state from parent window to child window
// in order to access state information store is set here
authenticationChannel.setStore(store);

(async function() {
  // special case to dispatch the auth token
  if (initialState.login.auth) {
    store.dispatch({
      type: Auth.TOKEN_AVAILABLE,
      payload: initialState.login.auth
    });
    setIsActive(true);
  } else {
    try {
      await authenticationChannel.ready;
    } catch (e) {
      console.error(e);
    }
  }

  if (engine) {
    try {
      // if the document is in a reload situation,
      // then we want to rehydrate the store from the
      // storage engine.
      if (navigation.isReload(window)) {
        const load = storage.createLoader(engine);
        await load(store);
      } else {
        engine.clear();
      }
    } catch (e) {
      console.log(e);
    }
  }
})();

export default store;
