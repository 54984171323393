export const isNumber = (variableToCheck: any): variableToCheck is number => {
  try {
    return (
      variableToCheck !== null &&
      (variableToCheck as number).toExponential !== undefined
    );
  } catch (e) {
    return false;
  }
};
