/// <reference path="../types.d.ts" />

import { push } from 'connected-react-router';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { HttpMethods } from '@codametrix/ui-common';

import { api } from '../core/net';

import { isServiceOrganization } from './_action-utilities';
import { STUB_ORG_RELATIONSHIP } from '../views/organizations/stub-organization';
import { inProgress } from './common';

import { syncActions } from './organization';
import { AssigningAuthorities } from '../actions/action-types';

const actionCreator = actionCreatorFactory();
const createAsync = asyncFactory<CMx.AssigningAuthorityState>(actionCreator);

function displayHospitals(activeOrganization: CMxAPI.Organization) {
  var hospitalList: any[] = [];
  let orgStack = [activeOrganization];
  while (orgStack.length > 0) {
    let currOrg = orgStack.pop();

    if (currOrg === undefined) {
      break;
    }

    if (currOrg.organizationType === 'HOSPITAL') {
      hospitalList.push({
        hospitalName: currOrg.organizationName,
        hospitalId: currOrg.id
      });
    }
    currOrg.children.forEach(child => {
      orgStack.push(child);
    });
  }

  return hospitalList;
}

const getHospitals = createAsync<
  number,
  CMxAPI.Organization[],
  CMxCommonApp.SubmitError
>(AssigningAuthorities.GET_HOSPITALS, async (id, dispatch) => {
  dispatch(inProgress());
  const endpoint = isNaN(id as number)
    ? `/organization/tenant/v1?tenantId=${id}`
    : `/organization/id/v1?organizationId=${id}`;
  const orgOpts = {
    endpoint: endpoint
  };

  const organization = await api<CMxAPI.Organization>(orgOpts);
  dispatch(syncActions.organizationSelected(organization));

  if (!isServiceOrganization(organization.organizationType)) {
    dispatch(syncActions.establishRelationship(STUB_ORG_RELATIONSHIP));
  } else {
    const params = new URLSearchParams({
      toOrganizationId: `${organization.id}`,
      fromOrganizationId: `${organization.parentId}`
    });

    const relationship = await api<CMxAPI.OrganizationRelationship>({
      endpoint: `/organization/relationship/v1?${params.toString()}`
    });

    dispatch(syncActions.establishRelationship(relationship));
  }
  dispatch(syncActions.organizationChildren(organization.children));

  return displayHospitals(organization);
});

const loadForm = createAsync<number, any, CMxCommonApp.SubmitError>(
  AssigningAuthorities.LOAD_FORM,
  async (id, dispatch) => {
    const identifier = id || `new`;
    dispatch(push(`/cmx/config/assigning-authority/${identifier}/`));

    const assigningAuthorityGroup = await api<CMxAPI.AssigningAuthorityGroup>({
      endpoint: `/primaryAssigningAuthority/v1?organizationId=` + id,
      init: {
        method: HttpMethods.GET
      }
    });

    return { id, group: assigningAuthorityGroup };
  }
);

const addAuthority = createAsync<
  CMxAPI.AssigningAuthorityGroup,
  any,
  CMxCommonApp.SubmitError
>(AssigningAuthorities.ADD, async (item, dispatch) => {
  await api<CMxAPI.PageableList<CMxAPI.AssigningAuthorityGroup>>({
    endpoint: `/primaryAssigningAuthority/v1`,
    init: {
      method: HttpMethods.POST
    },
    body: item
  });
  dispatch(loadForm(item.organizationId));

  return dispatch;
});

const editAuthority = createAsync<
  CMxAPI.AssigningAuthorityGroup,
  any,
  CMxCommonApp.SubmitError
>(AssigningAuthorities.EDIT, async (item, dispatch) => {
  await api<CMxAPI.PageableList<CMxAPI.AssigningAuthorityGroup>>({
    endpoint: '/primaryAssigningAuthority/' + item.id + '/v1',
    init: {
      method: HttpMethods.PUT
    },
    body: item
  });

  dispatch(loadForm(item.organizationId));

  return dispatch;
});

export { getHospitals, loadForm, addAuthority, editAuthority };
