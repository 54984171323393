import theme from '../../theme';

export const styles = () => ({
  loaderWrapper: {
    position: 'absolute',
    left: `calc(50% - ${theme.space[96]})`,
    top: '42%'
  },
  title: {
    height: theme.space[20],
    ...theme.textStyles['h1'],
    color: theme.colors.text.highEmphasis
  }
});
