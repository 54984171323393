export const providerDetails: CMx.ProviderDetailsState = {
  isDrawerOpen: false,
  isOpen: false,
  providerInfo: null,
  servicesLoading: false,
  isSingleTenantData: false,
  providerInfoInput: {
    providerName: '',
    providerNumber: '',
    tenantCode: ''
  }
};
