/// <reference path="../types.d.ts" />

import InitialState from './initial-state';
import * as dictionaryValues from '../actions/dictionary-values';
import * as dictionaryRequest from '../actions/dictionary-request.actions';
import { LOAD_STATES } from './common';

import { jumpContext } from '../actions/contexts';
import { cmxReducerFactory } from './reducer-utils';
import * as dictionary from '../actions/dictionary';
import { dictionaryMetadata } from '../tests/fixtures/stubs/dictionary';
import { commonEnums } from '@codametrix/ui-common';
import { defaultUploadResponse } from './state/dictionary-request';
import { formErrors } from '../stubs/form-errors';

const { DictionaryEditStatus, DictionaryStatus } = commonEnums;
const replaceFieldOptions = (
  form: CMxCommonApp.FormDefintion,
  fieldKey: string,
  options: any
) => {
  let colMetadtaFormFields = (form as CMxCommonApp.FormDefintion).fields;
  const formIndex = colMetadtaFormFields?.findIndex(
    field => field.key === fieldKey
  );
  if (formIndex !== undefined && colMetadtaFormFields !== undefined) {
    colMetadtaFormFields[formIndex].options = options;

    colMetadtaFormFields = colMetadtaFormFields.map((form, index) => {
      return {
        ...form,
        options: formIndex < index ? [] : form.options
      };
    });
  }
  return colMetadtaFormFields;
};
const reducer = cmxReducerFactory(InitialState.dictionaryRequest)
  .case(dictionaryRequest.uploadDictionary.async.started, (state, res) => {
    return {
      ...state,
      formErrors: formErrors.noErrors,
      loadState: LOAD_STATES.started
    };
  })

  .case(dictionaryRequest.uploadDictionary.async.failed, (state, res) => {
    return {
      ...state,
      formErrors: res.error.errors,
      loadState: LOAD_STATES.failed
    };
  })
  .case(
    dictionaryRequest.uploadDictionary.async.done,
    (state, { result: requestResponse }) => {
      return {
        ...state,
        requestResponse,
        formErrors: formErrors.noErrors,
        loadState: LOAD_STATES.done
      };
    }
  )
  .cases(
    [
      dictionaryRequest.fetchRequestStatus.async.done,
      dictionaryValues.publishDictionary.async.done
    ],
    (state, { result: requestResponse }) => {
      return {
        ...state,
        requestResponse
      };
    }
  )
  .case(dictionaryValues.initUpload.async.started, (state, res) => {
    return {
      ...state,
      loadState: LOAD_STATES.initial,
      requestResponse: {
        ...defaultUploadResponse,
        requestType: 'UPLOAD',
        requestStatus: 'INITIALIZED'
      }
    };
  })
  .case(dictionaryValues.initUpload.async.done, (state, res) => {
    //If a column has multiple cell references this mapping might not suffice.
    const colMetadata: CMxAPI.ColumnDefinitionMetadata[] = res.params.columnDefinitions.map(
      col => {
        return {
          columnName: col.name,
          keyColumnIndicator: col.keyColumnIndicator,
          cellReference: col.cellReferences[0]
        };
      }
    );
    const dictionaryMetadata: CMxAPI.DictionaryMetadata = {
      tenantId: res.params.tenantId.uuid,
      dictionaryName: res.params.name,
      description: res.params.description,
      purpose: res.params.purpose,
      crossMapIndicator: res.params.crossMapIndicator,
      columnDefinitionMetadata: colMetadata
    };
    return {
      ...state,
      loadState: LOAD_STATES.done,
      formErrors: formErrors.noErrors,
      dictionaryMetadata: dictionaryMetadata,
      isUploadCreate: colMetadata.length ? false : true
    };
  })
  .case(
    dictionaryValues.listDictionaryOptions.async.done,
    (state, res: any) => {
      const { columnMetadataForm, dictionaryMetadata } = state;

      const publishedDictionaries = res.result.pageableList.content.filter(
        (res: any) => {
          return (
            res.status !== DictionaryStatus.ARCHIVED.toUpperCase() &&
            res.editStatus !== DictionaryEditStatus.RETIRE.toUpperCase()
          );
        }
      );

      const fields = replaceFieldOptions(
        columnMetadataForm,
        'referenceDictionary',
        publishedDictionaries
      );

      return {
        ...state,
        dictionaryOptions: publishedDictionaries,
        dictionaryMetadata: {
          ...dictionaryMetadata,
          tenantId: res.params.tenantId
        },
        selectedTenantName: res.result.organizationName,
        columnMetadataForm: {
          ...state.columnMetadataForm,
          fields
        }
      };
    }
  )
  .case(dictionaryValues.listColumnOptions.async.done, (state, res) => {
    const { columnMetadataForm } = state;

    const fields = replaceFieldOptions(
      columnMetadataForm,
      'referenceColumn',
      res.result
    );

    return {
      ...state,
      columnMetadataForm: {
        ...state.columnMetadataForm,
        fields
      }
    };
  })
  .case(dictionary.listItems.async.started, (state, res) => {
    return {
      ...state,
      dictionaryMetadata
    };
  })

  .case(dictionaryValues.addColumnMetadata.async.started, (state, res) => {
    return {
      ...state,
      colMetadataLoadState: LOAD_STATES.started
    };
  })
  .case(dictionaryValues.addColumnMetadata.async.done, (state, res) => {
    const { dictionaryMetadata, columnMetadataForm } = state;

    const fields = replaceFieldOptions(
      columnMetadataForm,
      'referenceDictionary',
      []
    );
    return {
      ...state,
      dictionaryMetadata: {
        ...dictionaryMetadata,
        columnDefinitionMetadata: [
          ...dictionaryMetadata.columnDefinitionMetadata,
          res.params
        ]
      },
      columnMetadataForm: {
        ...columnMetadataForm,
        fields
      },
      colMetadataLoadState: LOAD_STATES.initial
    };
  })
  .case(dictionaryValues.removeColumnMetadata, (state, res) => {
    const { dictionaryMetadata } = state;

    return {
      ...state,
      dictionaryMetadata: {
        ...dictionaryMetadata,
        columnDefinitionMetadata: dictionaryMetadata.columnDefinitionMetadata.filter(
          (e: CMxAPI.ColumnDefinitionMetadata) => e !== res
        )
      }
    };
  })

  .case(dictionaryValues.chooseColumnReference, (state, res) => {
    return {
      ...state
    };
  })
  .case(dictionary.getTenantList.async.started, (state, res) => {
    return {
      ...state,
      loadState: LOAD_STATES.started
    };
  })
  .case(dictionary.getTenantList.async.failed, (state, res) => {
    return {
      ...state,
      formErrors: res.error.errors,
      loadState: LOAD_STATES.failed
    };
  })
  .case(dictionary.getTenantList.async.done, (state, { result }) => {
    const { columnMetadataForm } = state;

    const fields = replaceFieldOptions(
      columnMetadataForm,
      'referenceTenant',
      result
    );
    return {
      ...state,
      columnMetadataForm: {
        ...columnMetadataForm,
        fields
      },
      loadState: LOAD_STATES.done
    };
  })
  .cases([jumpContext.async.started], state => {
    return { ...InitialState.dictionaryRequest };
  });

export default reducer;
