import { buildDispatchable } from '../../actions/_action-utilities';
import * as dictionaryActions from '../../actions/dictionary';
import * as dictionaryValueActions from '../../actions/dictionary-values';
import { CMxComponents } from '@codametrix/ui-components/src/types';
import { push } from 'connected-react-router';
import { commonEnums } from '@codametrix/ui-common';
import { isResourceViewOnly } from '../../core/resources';
import { MenuItemType } from '../../components/select/select';
const { ResourceId } = commonEnums;

type dictionaryListDispatches = Pick<
  AppProps.DictionaryListProps,
  'list' | 'onSelection' | 'navigate' | 'resetStateToDefault'
>;
type dictionaryListStateProps = Pick<
  AppProps.DictionaryListProps,
  | 'items'
  | 'loadState'
  | 'columnDefs'
  | 'filterLoadStates'
  | 'selectedOrg'
  | 'showHeader'
  | 'viewOnly'
>;

export const mapStateToProps = function(
  state: CMx.ApplicationState
): dictionaryListStateProps {
  const { dictionary, ui } = state;

  let adminTabs: CMxComponents.TabDef[] =
    ui.actionGroups
      .find((actionGroup: any) => {
        return actionGroup.key === 'admin';
      })
      ?.options[0].subActions.filter((tab: CMxComponents.TabDef) => {
        return ui.context.activeContext?.resources.find((resource: any) => {
          return resource.resourceName === tab.resourceId;
        });
      }) ?? [];

  const viewOnly = isResourceViewOnly(
    ui.context.activeContext?.resources || [],
    ResourceId.DICTIONARY
  );

  return {
    ...dictionary,
    selectedOrg: dictionary.selectedOrg,
    showHeader: adminTabs?.length <= 1,
    viewOnly
  };
};

export const mapDispatchToProps = (dispatch: any): dictionaryListDispatches => {
  const runDispatchable = buildDispatchable(dispatch);

  return {
    onSelection: (item: AppProps.actionParams<CMxAPI.Dictionary>) => {
      runDispatchable(() => {
        return dispatch(dictionaryActions.loadDictionary(item));
      });
    },
    list: (listParams: AppProps.listParams) => {
      runDispatchable(() => {
        return dispatch(dictionaryActions.listItems(listParams));
      });
    },

    navigate: (path: string) => {
      runDispatchable(() => {
        return dispatch(push(path));
      });
    },
    resetStateToDefault: (payload: AppProps.DefaultStateData) => {
      runDispatchable(() => {
        return dispatch(
          dictionaryValueActions.syncActions.updateDictionaryValue(payload)
        );
      });
    }
  };
};

export const optionConverter = (field: CMxCommonApp.FieldDefinition) => {
  const { options, labelField, valueField } = field;
  let opts: MenuItemType[] = [];
  options?.map((item: any) => {
    opts?.push({ label: item[labelField], value: item[valueField] });
    return {};
  });
  return opts;
};
