import { CaseList } from './case-list';
import { CaseDetail } from '@codametrix/shared-views';
import { CaseDetailv2 } from '@codametrix/shared-views';
import React, { useEffect, useState } from 'react';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { appServiceLines, codeBenchActions } from '@codametrix/ui-common';

type DualComponentProps = {
  detailProps: any;
  detailMethods: any;
  listProps: any;
  listMethods: any;
  match: any;
};

const CaseListAndDetail = (props: DualComponentProps) => {
  const dispatch = useDispatch();

  const hasAmplifyCaseRunV2Access = useSelector(
    (state: CMx.ApplicationState) => {
      return state.ui.hasAmplifyCaseRunV2Access;
    }
  );

  const context = useSelector((state: CMx.ApplicationState) => {
    return state.ui.context;
  });

  const { params = {} } = props.match ?? {};

  const detailProps = {
    ...props.detailProps,
    ...props.detailMethods
  };

  const listProps = {
    ...props.listProps,
    context,
    ...props.listMethods
  };

  const [isDetailOpen, setIsDetailOpen] = useState<boolean>(
    params.id !== undefined
  );

  const handleSelection = (item: CMxAPI.CaseRunV2) => {
    const serviceLineConfig = appServiceLines.determineServiceLineConfig({
      name: item.service_line,
      caseIdentifier: `${item.case_uid || item.case_id}`
    });

    const caseId =
      serviceLineConfig.caseIdProp in item
        ? item[serviceLineConfig.caseIdProp]
        : item.case_id;
    dispatch(push(`${caseId}`));
    setIsDetailOpen(true);
  };

  const handleBackClick = (event: any) => {
    dispatch(codeBenchActions.showSideNavBar());
    dispatch(push(`/cmx/cases/${params.serviceLine?.toLowerCase() ?? ''}/`));
    setIsDetailOpen(false);
  };

  useEffect(() => {
    setIsDetailOpen(params.id !== undefined);
  }, [params.id]);

  return (
    <>
      <div style={{ display: isDetailOpen ? 'none' : 'block' }}>
        <CaseList {...listProps} onSelection={handleSelection} />
      </div>

      {isDetailOpen ? (
        hasAmplifyCaseRunV2Access ? (
          <CaseDetailv2
            {...detailProps}
            handleBackClick={handleBackClick}
            match={props.match}
          />
        ) : (
          <CaseDetail
            {...detailProps}
            handleBackClick={handleBackClick}
            match={props.match}
          />
        )
      ) : null}
    </>
  );
};

export { CaseListAndDetail };
