import { Middleware } from 'redux';
import { MiddlewareProcessor, middleWareWrapper } from './middleware-utils';
import { cmxTypeguards } from '@codametrix/ui-common';

const findFunctions = (payload: any) => {
  Object.keys(payload).forEach(prop => {
    if (
      payload[prop] &&
      typeof payload[prop] !== 'string' &&
      Object.keys(payload[prop]).length > 0
    ) {
      findFunctions(payload[prop]);
    } else if (typeof payload[prop] === 'function') {
      console.warn(
        "State is at risk of containing non-JSON objects! If you're reading this, please report this incident to the WEBAPP team."
      );
    }
  });
};

/**
 * Middleware processor that checks the action payload for non-JSON objects and logs a warning if found.
 *
 * This middleware is part of the "state disinfectant" functionality, which aims to ensure the Redux state
 * is comprised of only plain JSON objects. The `findFunctions` helper function is used to recursively
 * traverse the action payload and log a warning if any non-JSON objects (i.e. functions) are found.
 *
 * @param api The Redux middleware API, including the store's dispatch and getState functions.
 * @param next The next middleware in the chain.
 * @param action The current action being processed.
 * @returns The result of calling the next middleware in the chain.
 */
const stateDisinfectantMiddlewareProcessor: MiddlewareProcessor = (
  api,
  next,
  action
) => {
  if (cmxTypeguards.isFSA(action)) {
    const { payload } = action;

    if (payload) {
      findFunctions(payload);
    }
  }

  return next(action);
};
const stateDisinfectantMiddleware: Middleware = middleWareWrapper(
  stateDisinfectantMiddlewareProcessor
);

export default stateDisinfectantMiddleware;
