/// <reference path="../../types.d.ts" />

import { connect } from 'react-redux';

import { buildDispatchable } from '../../actions/_action-utilities';
import { push } from 'connected-react-router';
import { groupActions } from '../../actions/groups';
import { Groups } from './groups';
import { commonEnums } from '@codametrix/ui-common';
import { isResourceViewOnly } from '../../core/resources';
const { ResourceId } = commonEnums;

type groupsListDispatches = Pick<
  AppProps.GroupsListProps,
  'list' | 'onSelection' | 'navigate'
>;

type groupsListStateProps = Pick<
  AppProps.GroupsListProps,
  'sortablePageable' | 'viewOnly' | 'selectedOrg'
>;

export const mapStateToProps = function(
  state: CMx.ApplicationState
): groupsListStateProps {
  const { group, ui, organization } = state;

  const viewOnly = isResourceViewOnly(
    ui.context.activeContext?.resources || [],
    ResourceId.GROUPS
  );

  return {
    ...group,
    sortablePageable: group.groupsList.sortablePageable,
    viewOnly,
    selectedOrg: organization.activeOrganization
  };
};

export const mapDispatchToProps = (dispatch: any): groupsListDispatches => {
  const runDispatchable = buildDispatchable(dispatch);

  return {
    onSelection: (item: AppProps.actionParams<CMxAPI.Group>) => {
      runDispatchable(() => {
        if (item.data.id) {
          return dispatch(groupActions.getGroupDetail(item));
        }
      });
    },
    list: (listParams: AppProps.listParams) => {
      runDispatchable(() => {
        return dispatch(groupActions.listItems(listParams));
      });
    },
    navigate: (path: string) => {
      runDispatchable(() => {
        return dispatch(push(path));
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Groups);
