export const getDateOfGivenDayInWeek = (today: Date, dayOfWeek: string) => {
  today.setHours(0, 0, 0, 0);
  const todayDayIndex = today.getDay();

  const daysOfWeek = Object.freeze([
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ]);
  const dayIndex = daysOfWeek.indexOf(dayOfWeek);

  let targetDay = today;

  let compareDate = dayIndex - todayDayIndex;
  if (compareDate < 0) {
    compareDate = Math.abs(compareDate);
    targetDay.setDate(today.getDate() - (compareDate % 7));
  } else {
    targetDay.setDate(today.getDate() + (compareDate % 7));
  }

  return targetDay;
};

export const formatDateStringSlashed = (dateOfEntry: string) => {
  const year = dateOfEntry.substring(0, 4);
  const month = dateOfEntry.substring(5, 7);
  const day = dateOfEntry.substring(8);

  return month + '/' + day + '/' + year;
};
