import { cmxTypeguards } from '@codametrix/ui-common';
import { Store } from 'redux';
import { Action } from 'typescript-fsa';

export const isSubscriptionTransformer = (
  subscription: CMx.ChannelSubscription
): subscription is CMx.SubscriptionTransformer => {
  return 'match' in subscription;
};

export const isSubscriptionObserver = (
  subscription: CMx.ChannelSubscription
): subscription is CMx.SubscriptionObserver => {
  return 'observe' in subscription;
};

export const passthrough = (action: Action<any>) => action;

export const receiverFactory = (channel: BroadcastChannel | WindowProxy) => {
  return (subscriptions: CMx.ChannelSubscription[], store: Store) => {
    const transformers = subscriptions.filter(isSubscriptionTransformer);
    const observers = subscriptions.filter(isSubscriptionObserver);

    transformers.forEach(receiver => {
      receiver.transform = receiver.transform ?? passthrough;
    });

    channel.addEventListener('message', (message: MessageEvent | Event) => {
      // subscriptions deal with actions instead of MessageEvents
      // if a message comes across the channel that isn't an action
      // it is ignored for now.
      if ('data' in message && cmxTypeguards.isFSA(message.data)) {
        const action = message.data;
        // observers go first.
        observers.forEach(observer => observer.observe(action));

        const dispatchables = transformers.filter(receiver =>
          receiver.match(action)
        );

        dispatchables.forEach(dispatchable => {
          // trans guaranteed to be there from lines above.
          const toDispatch = (dispatchable.transform as CMx.ActionTransformer)({
            ...action
          });
          store.dispatch(toDispatch);
        });
      }
    });
  };
};
