import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CancelledEula from './cancelled-eula';
import AcceptableEula from './acceptable-eula';
import { push } from 'connected-react-router';

export const Eula = () => {
  const { isCancelled, eula } = useSelector(
    (state: CMx.ApplicationState) => state.eula
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (eula.eulaId === null) {
      dispatch(push('/'));
    }
  }, [dispatch, eula]);

  if (!isCancelled) {
    return <AcceptableEula />;
  } else {
    return <CancelledEula />;
  }
};
