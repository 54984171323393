import InitialState from '../reducers/initial-state';
import { cmxReducerFactory } from './reducer-utils';
import { attachDocsActions, documentUtils } from '@codametrix/ui-common';
import { logoutApp, login } from '../actions/login.action';
import { onLocationChange } from '../actions/ui';

export const reducer = cmxReducerFactory(InitialState.attachDocs)
  .case(attachDocsActions.showAttachDocsModal, (state, activeItem) => {
    return {
      ...state,
      isOpen: true,
      currentCaseRun: activeItem,
      loadingNotes: false,
      activeNotes: [],
      documentsAvailable: []
    };
  })
  .case(attachDocsActions.fetchAvailableDocs.async.started, state => {
    return {
      ...state,
      loadingDocuments: true,
      documentsAvailable: []
    };
  })
  .case(attachDocsActions.fetchAvailableDocs.async.failed, state => {
    return {
      ...state,
      loadingDocuments: false,
      documentsAvailable: []
    };
  })
  .case(
    attachDocsActions.fetchAvailableDocs.async.done,
    (state, { result: documentsAvailable }) => {
      return {
        ...state,
        loadingDocuments: false,
        documentsAvailable
      };
    }
  )
  .cases(
    [
      attachDocsActions.hideAttachDocsModal,
      logoutApp.async.started,
      login.async.started,
      onLocationChange
    ],
    state => {
      return {
        ...state,
        isOpen: false,
        loadingNotes: false,
        activeNotes: [],
        documentsAvailable: []
      };
    }
  )
  .case(
    attachDocsActions.fetchSpecialtiesDictionary.async.done,
    (state, { result: specialtiesDictionary }) => {
      return {
        ...state,
        specialtiesDictionary
      };
    }
  )
  .case(
    attachDocsActions.fetchNoteTypeDictionary.async.done,
    (state, { result: noteTypesDictionary }) => {
      return {
        ...state,
        noteTypesDictionary
      };
    }
  )
  .case(attachDocsActions.downloadDocs.async.started, state => {
    return {
      ...state,
      loadingNotes: true
    };
  })
  .case(attachDocsActions.downloadDocs.async.failed, state => {
    return {
      ...state,
      loadingNotes: false
    };
  })
  .case(
    attachDocsActions.downloadDocs.async.done,
    (state, { result: downloadDocsResult }) => {
      const { doc } = downloadDocsResult;
      const { currentCaseRun } = state;

      return {
        ...state,
        loadingNotes: false,
        activeNotes: [
          ...downloadDocsResult.htmlNotes,
          ...downloadDocsResult.plainTextNotes
        ].map(noteContent => {
          const decoratedNote = documentUtils.decoratedNoteFactory({
            description:
              currentCaseRun?.description ?? doc?.documentDescription,
            content: noteContent,
            filename: currentCaseRun?.note_specialty_designation ?? 'Document',
            type: doc?.documentType,
            document_id: doc?.documentId,
            id: doc?.id,
            filetype: doc?.mimeType
          });

          return documentUtils.shimSegmentsIfNeeded(decoratedNote);
        })
      };
    }
  );

export default reducer;
