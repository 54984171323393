import { Box } from '@chakra-ui/react';
import theme from '../../theme';
import { CMxComponents } from '@codametrix/ui-components/src/types';
import { ColDef } from 'ag-grid-community';
import React, { useState } from 'react';
import { DataGrid } from '../../components/data-grid/data-grid';
import {
  EndpointData,
  HTTPMethodType
} from '../../components/data-grid/data-grid-utils';
import { SubHeader } from '../organizations/subheader';
import { gridOptions } from '../users/user-list-utils';
import { styles } from './external-app-users/external-app.styles';

const endpoint = (orgId: number) => {
  const params = [['organizationId', `${orgId}`]];
  const endPoint: EndpointData = {
    endPoint: `/externalApplication/list/v1`,
    HTTPMethodType: HTTPMethodType.GET,
    params: params
  };
  return endPoint;
};

export const updatedColDefs = (columnDefs: CMxComponents.ColumnDefintion[]) => {
  const cols: ColDef[] = columnDefs.map(
    (col: CMxComponents.ColumnDefintion) => {
      let column: ColDef = {
        ...col,
        headerName: col.displayName,
        field: col.key,
        sortable: false
      };
      return column;
    }
  );
  return cols;
};

export const ExternalApplicationList: React.FC<AppProps.ExternalApplicationListProps> = props => {
  const { onSelection, orgId, columnDefs, sortablePageable } = props;
  const classes = styles();
  const [pageSize, setPageSize] = useState<number>(25);

  const handleSelect = (rowData: any) => {
    onSelection(rowData.data, window.location.pathname);
  };

  const onPageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
  };

  return (
    <div>
      <SubHeader
        title={'External applications'}
        className={classes.text}
        pageSize={pageSize}
        onPageSizeChange={onPageSizeChange}
      />
      <Box paddingTop={theme.space[16]}>
        <DataGrid
          serverSideInfiniteScroll
          columnDefs={updatedColDefs(columnDefs)}
          gridOptions={gridOptions}
          filterableOptions={sortablePageable.filterableOptions}
          endpointData={endpoint(orgId)}
          onRowClicked={item => {
            handleSelect(item);
          }}
          paginationPageSize={pageSize}
        />
      </Box>
    </div>
  );
};
