import theme from '../../theme';

export const styles = () => {
  const protectedTimeRowContainer = {
    marginBottom: theme.space[10],
    justifyContent: 'left'
  };
  const startTimePast = {
    marginRight: theme.space[10],
    width: '22%',
    borderColor: theme.colors.foundation[200]
  };
  const reasonPastContainer = {
    marginRight: theme.space[10],
    width: '34%',
    borderColor: theme.colors.foundation[200]
  };
  const reasonPastSelect = {
    borderColor: theme.colors.foundation[200]
  };
  const durationPast = {
    marginRight: theme.space[16],
    width: '30%',
    borderColor: theme.colors.foundation[200]
  };
  const deleteButton = {
    borderColor: theme.colors.foundation[200]
  };
  const durationOverlapWarning = {
    backgroundColor: theme.colors.warning[500],
    color: 'white',
    borderRadius: 3,
    marginBottom: theme.space[16],
    height: theme.space[32],
    width: '96%',
    alignItems: 'center'
  };
  const durationOverlapWarningText = {
    marginLeft: theme.space[8]
  };

  return {
    '.protectedTimeRowContainer': protectedTimeRowContainer,
    '.startTimePast': startTimePast,
    '.reasonPastContainer': reasonPastContainer,
    '.reasonPastSelect': reasonPastSelect,
    '.durationPast': durationPast,
    '.deleteButton': deleteButton,
    '.durationOverlapWarning': durationOverlapWarning,
    '.durationOverlapWarningText': durationOverlapWarningText
  };
};
