export const RelationshipEditType = {
  REMOVE: 'RELATIONSHIP_REMOVE',
  ADD: 'RELATIONSHIP_ADD',
  NOOP: 'RELATIONSHIP_NOOP'
};

export enum InputVariant {
  TEXT = 'text',
  SEARCH = 'search'
}

export enum InputSize {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg'
}

export enum ButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  LINKS = 'links'
}

export enum TextareaVariant {
  OUTLINE = 'outline',
  FLUSHED = 'flushed',
  FILLED = 'filled',
  UNSTYLED = 'unstyled'
}

export enum TextareaSize {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg'
}

export enum ButtonSize {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg'
}

export enum ToastVariants {
  SUCCESS = 'SUCCESS',
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR'
}

export enum ToastPositions {
  TOP = 'top',
  BOTTOM = 'bottom',
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right'
}
