import { CSSProperties } from 'react';
import { ToastVariants } from '../../core/enums';
import theme from '../../theme';

const getColor = (variant: string) => {
  switch (variant) {
    case ToastVariants.SUCCESS:
      return theme.colors.foundation[500];
    case ToastVariants.INFO:
      return theme.colors.progress[500];
    case ToastVariants.WARNING:
      return theme.colors.warning[500];
    case ToastVariants.ERROR:
      return theme.colors.alert[500];
    default:
      break;
  }
};
export const styles = (variant: string): Record<string, CSSProperties> => ({
  container: {
    borderRadius: theme.radii[3],
    borderColor: getColor(variant),
    backgroundColor: getColor(variant),
    display: 'flex',
    height: theme.space[52],
    minWidth: theme.space[320],
    width: 'fit-content',
    alignItems: 'center',
    marginBottom: theme.space[52],
    padding: `${theme.space[16]} ${theme.space[12]}`
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.space[8]
  },
  text: {
    color: theme.colors.structural[0],
    fontSize: theme.space[16],
    fontWeight: 500
  },
  icon: {
    color: theme.colors.structural[0],
    marginLeft: 'auto',
    cursor: 'pointer',
    paddingLeft: theme.space[12]
  }
});
