import { Box } from '@chakra-ui/react';
import React from 'react';
import { AmplifyCore } from '@codametrix/ui-common';
import { styleOverrides, styles } from './service-desk.styles';
import { getServiceDeskClient } from '../../clients/service-desk.client';

const { conditionalUtils } = AmplifyCore;
const classes = styles();
class ServiceDesk extends React.Component<AppProps.ServiceDeskView, {}> {
  static defaultProps = {
    viewType: 'GRID_WIDGET_WORKLIST_SETTINGS'
  };

  componentDidMount(): void {
    const onWorkItemSelectHandler = (workitem: any, worklistContext: any) => {
      this.props.openAmplifyCase({
        serviceLine: workitem.service,
        caseId: workitem.cmx_case_uid,
        processId: workitem.cmx_process_id,
        workitemId: workitem.case_id,
        servicedeskMode: true,
        worklistContext,
        isCodingQuality: worklistContext?.isCodingQuality,
        isQualityAssessment: worklistContext?.isQualityAssessment,
        hasAmplifyCaseRunV2Access: this.props.hasAmplifyCaseRunV2Access,
        qaCollectionUuid: workitem.qa_collection_uuid
      });
    };

    const { capabilities } = this.props;

    const widgetOptions = {
      onWorkItemSelected: onWorkItemSelectHandler,
      prodTrackerActive: conditionalUtils.capabilityValue(
        capabilities?.capabilities.productivityTracker?.on
      ),
      childRef: this.props?.childRef,
      userContext: this.props?.userContext
    };

    const xcaliberClient = getServiceDeskClient();
    xcaliberClient.create(this.props.viewType, 'service_desk', widgetOptions);
  }

  render(): React.ReactNode {
    return (
      <div style={classes.root}>
        {/* @ts-ignore */}
        <Box css={styleOverrides} id="service_desk"></Box>
      </div>
    );
  }
}

export { ServiceDesk };
