// import libraries
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
//import classnames from 'classnames';

// import components
import { Button } from '../button/button';
import { ButtonVariant } from '../../core/enums';
import { GenericRelatedModal } from '../generic-related-modal/generic-related-modal';
import { DatePickerComponent } from '../../components/datepicker-component/datepicker-component';
import { attachDocsActions } from '@codametrix/ui-common';
import { CMXIcon, Conditional } from '@codametrix/shared-views';
import { DataGrid, LoadingSlugs } from '@codametrix/ui-components';
import { CheckboxMenu } from '../../components/checkbox-menu/checkbox-menu';
import { showFeedbackError } from '../../actions/ui';
import { CheckboxOption } from '../../components/checkbox-menu/checkbox-menu';

// import styles
import './attach-docs-modal.scss';

/**
 * Definitions for columns in the mergeable cases grid.
 * @constant
 * @type {Array<Object>}
 */
const docsColsDefs = [
  {
    key: 'dateOfService',
    displayName: `Date`,
    isSortable: false
  },
  {
    key: 'documentType',
    displayName: `Type`,
    isSortable: false
  },
  {
    key: 'specialty', // TODO: add specialty key
    displayName: `Specialty`,
    isSortable: false
  },
  {
    key: 'providerNumber',
    displayName: `Provider`,
    isSortable: false
  }
];

/**
 * AttachDocsModal functional component.
 * @component
 * @returns {React.ReactNode} - The rendered component.
 */
export const AttachDocsModal: React.FC = () => {
  const dispatch = useDispatch();
  const {
    isOpen,
    currentCaseRun,
    loadingNotes,
    activeNotes,
    documentsAvailable,
    specialtiesDictionary,
    noteTypesDictionary,
    loadingDocuments
  } = useSelector((state: CMx.ApplicationState) => {
    return state.attachDocs;
  });

  const [providersFilter, setProvidersFilter] = useState<CheckboxOption[]>([]);
  const [specialtiesFilter, setSpecialtiesFilter] = useState<CheckboxOption[]>(
    []
  );
  const [documentsTypeFilter, setDocumentTypeFilter] = useState<
    CheckboxOption[]
  >([]);
  const [startDateFilter, setStartDateFilter] = useState<Date | null>(
    new Date(new Date().setFullYear(new Date().getFullYear() - 1))
  );
  const [endDateFilter, setEndDateFilter] = useState<Date | null>(new Date());
  const [idDocSelected, setIdDocSelected] = useState<number>(0);

  /**
   * Close the merge cases modal.
   * @function
   */
  const handleClose = () => {
    dispatch(attachDocsActions.hideAttachDocsModal());
  };

  /**
   * Handle selection of a doc
   * @function
   * @param {any} data - The data associated with the selected row.
   * @param {any} ev - The event object.
   */
  const handleDocSelection = (data: any) => {
    dispatch(attachDocsActions.downloadDocs(data) as any);
    setIdDocSelected(data.id);
  };

  /**
   * Handle date clear
   */
  const handleDateClear = () => {
    setStartDateFilter(null);
    setEndDateFilter(null);
  };

  /**
   * Fetch docs for the current MRN
   * @function
   */
  useEffect(() => {
    if (isOpen) {
      try {
        dispatch(attachDocsActions.fetchAvailableDocs(currentCaseRun) as any);
      } catch (error) {
        dispatch(
          showFeedbackError({
            message: 'There were issues getting documents.',
            dismissable: true,
            className: 'feedback-error'
          })
        );
      }

      try {
        dispatch(
          attachDocsActions.fetchSpecialtiesDictionary(currentCaseRun) as any
        );
      } catch (error) {
        dispatch(
          showFeedbackError({
            message: 'There were issues getting specialty dictionary.',
            dismissable: true,
            className: 'feedback-error'
          })
        );
      }

      try {
        dispatch(
          attachDocsActions.fetchNoteTypeDictionary(currentCaseRun) as any
        );
      } catch (error) {
        dispatch(
          showFeedbackError({
            message: 'There were issues getting note type dictionary.',
            dismissable: true,
            className: 'feedback-error'
          })
        );
      }
    }

    // eslint-disable-next-line
  }, [isOpen]);

  const filteredDocumentsAvailable = documentsAvailable.filter(docAvailable => {
    let providerFilter = true;
    let specialtyFilter = true;
    let documentTypeFilter = true;
    let dateFilter = true;
    const specialty = docAvailable.specialty?.toString() ?? 'NOT_FOUND';

    if (providersFilter.length > 0) {
      providerFilter = providersFilter
        .map(providerFilter => providerFilter.label)
        .includes(docAvailable.providerNumber.toString());
    }

    if (specialtiesFilter.length > 0) {
      specialtyFilter = specialtiesFilter
        .map(specialtyFilter => specialtyFilter.label)
        .includes(specialty);
    }

    if (documentsTypeFilter.length > 0) {
      documentTypeFilter = documentsTypeFilter
        .map(documentTypeFilter => documentTypeFilter.label)
        .includes(docAvailable.documentType.toString());
    }

    if (docAvailable?.dateOfService) {
      const dateOfService: Date = new Date(docAvailable.dateOfService);

      dateFilter =
        !!startDateFilter && !!endDateFilter
          ? dateOfService >= startDateFilter && dateOfService <= endDateFilter
          : true;
    }

    return (
      providerFilter && specialtyFilter && documentTypeFilter && dateFilter
    );
  });

  return (
    <>
      <GenericRelatedModal
        data-test-id="attach-docs-modal"
        className="attach-docs-modal"
        isOpen={isOpen}
        handleClose={handleClose}
        showCloseIcon={true}
        includePatientEncounter={true}
        notes={activeNotes}
        loadingNotes={loadingNotes}
        modalHeader={<p>Attach documentation to this case.</p>}
        modalContent={
          <>
            <h3 className="attach-docs-title">
              These are the documents we found for MRN{' '}
              <b>{currentCaseRun?.patient_identifier?.number}</b>
            </h3>

            <div className="attach-docs-filters">
              <div className="attach-docs-date-filter">
                <DatePickerComponent
                  startDate={startDateFilter}
                  endDate={endDateFilter}
                  label={''}
                  showRange={true}
                  onSelection={(startDate, endDate) => {
                    if (startDate && endDate) {
                      setStartDateFilter(startDate);
                      setEndDateFilter(endDate);
                    }
                    if (!startDate && !endDate) {
                      handleDateClear();
                    }
                  }}
                  selectsRange={true}
                />
              </div>

              <div className="divider"></div>

              <div className="note-type-filter-sec">
                <CheckboxMenu
                  options={noteTypesDictionary.map(noteType => ({
                    label: noteType['Note Description'],
                    value: noteType['Note Description']
                  }))}
                  label="Document Type"
                  onApplyFilters={docs => setDocumentTypeFilter(docs)}
                  selectedOptions={documentsTypeFilter}
                  withTypeAhead={true}
                  menuIcon={
                    <CMXIcon
                      svgTitleText="Filter by document type"
                      svgIconName="BiomeReports"
                      svgIconSize="20"
                    />
                  }
                />
              </div>

              <div className="divider"></div>

              <div className="note-specialty-filter-sec">
                <CheckboxMenu
                  options={specialtiesDictionary.map(specialtyDictionary => ({
                    label: specialtyDictionary['Note Specialty'],
                    value: specialtyDictionary['Note Specialty']
                  }))}
                  label="Specialty"
                  onApplyFilters={specialties =>
                    setSpecialtiesFilter(specialties)
                  }
                  selectedOptions={specialtiesFilter}
                  withTypeAhead={true}
                  menuIcon={
                    <CMXIcon
                      svgTitleText="Filter by document type"
                      svgIconName="BiomeBriefCase"
                      svgIconSize="20"
                    />
                  }
                />
              </div>

              <div className="divider"></div>

              <div className="note-provider-filter-sec">
                <CheckboxMenu
                  options={documentsAvailable.map(docAvailable => ({
                    label: docAvailable?.providerNumber?.toString(),
                    value: docAvailable?.providerNumber?.toString()
                  }))}
                  label="Provider"
                  onApplyFilters={providers => setProvidersFilter(providers)}
                  selectedOptions={providersFilter}
                  withTypeAhead={true}
                  menuIcon={
                    <CMXIcon
                      svgTitleText="Filter by document type"
                      svgIconName="BiomeUserPlus"
                      svgIconSize="20"
                    />
                  }
                />
              </div>
            </div>

            <DataGrid
              idField="id"
              idSelected={idDocSelected}
              onRowSelection={handleDocSelection}
              items={filteredDocumentsAvailable}
              columnDefs={docsColsDefs}
              selectable={true}
              theme={'chakra'}
            />

            <Conditional
              every={[documentsAvailable.length === 0, !loadingDocuments]}>
              <div className="note-unavaialable">
                There are no documents available to attach.
              </div>
            </Conditional>

            <Conditional
              every={[
                filteredDocumentsAvailable.length === 0,
                documentsAvailable.length > 0,
                !loadingDocuments
              ]}>
              <div className="note-unavaialable">
                There are no documents available to attach with the current
                filter criteria.
              </div>
            </Conditional>

            <Conditional on={loadingDocuments}>
              <LoadingSlugs numberItems={7} />
            </Conditional>
          </>
        }
        modalFooter={
          <div className="action-buttons">
            <Button
              variant={ButtonVariant.SECONDARY}
              dataTestId="cancelBtn"
              label="Cancel"
              onClick={handleClose}
            />

            <Button
              dataTestId="mergeBtn"
              className="merge-btn"
              label="Attach"
              onClick={() => {}}
            />
          </div>
        }
      />
    </>
  );
};
