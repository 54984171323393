import { connect } from 'react-redux';
import { ExternalApplicationList } from './external-application-list';
import {
  mapDispatchToProps,
  mapStateToProps
} from './external-application-list-utils';

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExternalApplicationList);
