import { connect } from 'react-redux';
import { Keypress } from './keypress';
import * as ShortcutDb from '../../core/shortcuts/database';

const mapStateToProps = function(
  state: CMx.ApplicationState
): AppProps.KeypressProps {
  return { ...state, shortcuts: state.ui.shortcuts };
};

const mapDispatchToProps = (dispatch: any): AppProps.KeypressMethods => {
  return {
    dispatcher: (keypressAction: AppProps.KeypressAction) => {
      const { transformer, action } = ShortcutDb.fetch(keypressAction.id);
      const value = transformer(keypressAction.value);
      dispatch(action(value));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Keypress);
