/// <reference path="../../types.d.ts" />
import React from 'react';
import { LoadingSlugs, RoundedButton } from '@codametrix/ui-components';
import '../../apps/login/login.scss';
import PasswordChangeForm from './password-change-form';
const PasswordChange: React.FC<CMx.PasswordChangeProps> = (
  props: CMx.PasswordChangeProps
) => {
  const {
    handleNewPassword,
    handleContinue,
    handleNavigateHome,
    error,
    submitting,
    forcePasswordChange,
    formErrors
  } = props;

  if (forcePasswordChange === undefined) {
    handleNavigateHome();
  }

  if (submitting === true) {
    return <LoadingSlugs numberItems={5} />;
  }
  return (
    <div>
      {!forcePasswordChange ? (
        <div className={'password-change-success'}>
          <h5>Password change successful</h5>
          <div className={'mx-2'}>
            <span>You may now proceed to the application.</span>
          </div>
          <RoundedButton
            className="btn-primary qa-continue-to-app-button mx-2 mt-2"
            type="submit"
            onClick={handleContinue}>
            Continue
          </RoundedButton>
        </div>
      ) : (
        <div>
          <h5>Choose your password</h5>
          <div className={'mx-2'}>
            <span>
              Your organizations administrator has requested that you change
              your password
            </span>
          </div>
          <PasswordChangeForm
            includeOldPassword={true}
            handleNewPassword={handleNewPassword}
            handleContinue={handleContinue}
            formErrors={formErrors}
            error={error}
            submitting={submitting}
          />
        </div>
      )}
    </div>
  );
};

export default PasswordChange;
