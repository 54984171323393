/// <reference path="../../types.d.ts" />

import { connect } from 'react-redux';

import { buildDispatchable } from '../../actions/_action-utilities';
import { goBack } from 'connected-react-router';
import { AddGroup } from './groups-add-form';
import { groupActions } from '../../actions/groups';

type GroupsAddFormProps = Pick<
  AppProps.GroupsAddFormProps,
  'tenantId' | 'errors'
>;
type GroupsAddFormDispatches = Pick<
  AppProps.GroupsAddFormProps,
  'handleSave' | 'handleCancel'
>;

const mapStateToProps = function(
  state: CMx.ApplicationState
): GroupsAddFormProps {
  const { group, organization } = state;

  return {
    ...group,
    errors: group.groupsFormErrors,
    tenantId: organization.activeOrganization.tenantId
  };
};

const mapDispatchToProps = (dispatch: any): GroupsAddFormDispatches => {
  const runDispatchable = buildDispatchable(dispatch);

  return {
    handleSave: (group: CMxAPI.Group) => {
      runDispatchable(() => {
        return dispatch(groupActions.saveGroup(group));
      });
    },
    handleCancel: () => {
      runDispatchable(() => {
        return dispatch(goBack());
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddGroup);
