import theme from '../../theme';

export const styles = () => ({
  horizontal: {
    '*': {
      marginTop: 'auto'
    },
    flexDirection: 'row'
  },
  buttonGroup: {
    marginLeft: 'auto',
    button: {
      marginLeft: '10px'
    }
  },
  noMargin: {
    margin: '100px'
  },
  legend: {
    marginLeft: theme.space[12],
    padding: `0  ${theme.space[8]}`
  },
  fieldset: {
    border: `${theme.borders['1px']} ${theme.colors.foundation[200]}`,
    borderRadius: theme.radii[3],
    marginBottom: theme.space[16],
    width: '50%'
  },
  fieldsets: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.radii[3],
    border: `${theme.borders['1px']} ${theme.colors.foundation[200]}`,
    height: '100%',
    overflowY: 'auto',
    marginTop: theme.space[20],
    padding: `${theme.space[16]} ${theme.space[20]} 0 ${theme.space[20]}`
  },
  memberInfoBox: {
    width: '230px',
    overflow: 'hidden'
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  text: {
    ...theme.textStyles['h4'],
    color: theme.colors.text['highEmphasis']
  },
  groupinformation: {
    width: '33%',
    flexDirection: 'column',
    rowGap: theme.space[16],
    marginBottom: theme.space[20]
  },
  memberinformation: {
    flexDirection: 'column',
    marginLeft: theme.space[20],
    marginRight: theme.space[40],
    marginBottom: theme.space[20],
    rowGap: theme.space[16]
  },
  checkBox: {
    columnGap: theme.space[12]
  },
  checkboxSpacing: {
    h: '40px',
    px: '12px',
    w: '100%',
    borderRadius: theme.radii[6],
    transition: 'all 150ms',
    _checked: {
      bg: theme.colors.orange[10]
    },
    "span[class*='chakra-checkbox__control']:not([data-disabled])": {
      borderColor: theme.colors.orange[10],
      borderRadius: theme.radii[3],
      _checked: {
        bg: theme.colors.orange[10],
        borderColor: theme.colors.orange[10]
      }
    }
  },
  usernameLabel: {
    ...theme.textStyles['Subtitle 1'],
    color: theme.colors.text['mediumEmphasis'],
    marginLeft: '12px',
    width: '100%'
  }
});
