import { connect } from 'react-redux';
import './case-list.scss';
import '../../../styles/grid-common.scss';
import '../../../styles/cmx.scss';
import { CaseListAndDetail } from './case-list-and-detail';
import {
  mapDispatchToProps as caseListDispatcher,
  mapStateToProps as caseListStateMap
} from './case-list-utils';
import {
  mapDispatchToProps as caseDetailDispatcher,
  mapStateToProps as caseDetailStateMap
} from '../../case-detail-utils';

const stateMapper = (state: CMx.ApplicationState) => {
  return {
    listProps: caseListStateMap(state),
    detailProps: caseDetailStateMap(state)
  };
};
const dualDispatchMapper = (dispatch: any) => {
  return {
    listMethods: caseListDispatcher(dispatch),
    detailMethods: caseDetailDispatcher(dispatch)
  };
};

export default connect(stateMapper, dualDispatchMapper)(CaseListAndDetail);
