import { cmxReducerFactory } from './reducer-utils';
import InitialState from '../reducers/initial-state';
import { mergeCasesActions, AmplifyCore } from '@codametrix/ui-common';
import { logoutApp, login } from '../actions/login.action';
import { onLocationChange } from '../actions/ui';

const { benchEditable: benchEditableCore } = AmplifyCore;

export const reducer = cmxReducerFactory(InitialState.mergeCases)
  .case(
    mergeCasesActions.toggleMergeableCaseSelection,
    (state, processInstanceGuid) => {
      return {
        ...state,
        mergeableCaseRuns: state.mergeableCaseRuns.map(mergeableCaseRun => {
          if (mergeableCaseRun?.process_instance_guid === processInstanceGuid) {
            return {
              ...mergeableCaseRun,
              selected: !mergeableCaseRun.selected
            };
          }

          return mergeableCaseRun;
        })
      };
    }
  )
  .case(
    mergeCasesActions.setActiveCaseRun,
    (state, activeProcessInstanceGuid) => {
      return {
        ...state,
        activeProcessInstanceGuid
      };
    }
  )
  .case(
    mergeCasesActions.showMergeCasesModal,
    (state, { caseRun: activeItem, cursorAPIContext }) => {
      const primaryActiveItem = { ...activeItem, primary: true };

      return {
        ...state,
        isOpen: true,
        loadingNotes: false,
        loadingMerging: false,
        loadingMergeableCases: false,
        mergingResult: false,
        activeHTMLNotes: [],
        activeNotes: [],
        mergeableCaseRuns: [],
        activeProcessInstanceGuid: primaryActiveItem.process_instance_guid,
        currentCaseRun: addNoteType(primaryActiveItem),
        cursorAPIContext
      };
    }
  )
  .cases(
    [
      mergeCasesActions.hideMergeCasesModal,
      logoutApp.async.started,
      login.async.started,
      onLocationChange
    ],
    state => {
      return {
        ...state,
        isOpen: false,
        loadingNotes: false,
        loadingMerging: false,
        loadingMergeableCases: false,
        mergingResult: false,
        activeProcessInstanceGuid: null,
        currentCaseRun: {} as CMxAPI.CaseRunV2,
        activeHTMLNotes: [],
        activeNotes: []
      };
    }
  )
  .case(mergeCasesActions.requestMergeCases.async.started, state => {
    return {
      ...state,
      loadingMerging: true
    };
  })
  .case(mergeCasesActions.requestMergeCases.async.failed, state => {
    return {
      ...state,
      loadingMerging: false
    };
  })
  .case(mergeCasesActions.requestMergeCases.async.done, state => {
    return {
      ...state,
      loadingMerging: false,
      mergingResult: true
    };
  })
  .case(mergeCasesActions.fetchMergeableCases.async.started, state => {
    return {
      ...state,
      loadingMergeableCases: true
    };
  })
  .case(mergeCasesActions.fetchMergeableCases.async.failed, state => {
    return {
      ...state,
      loadingMergeableCases: false
    };
  })
  .case(
    mergeCasesActions.fetchMergeableCases.async.done,
    (state, { result: mergeableCaseRuns }) => {
      const { roles, userContext } = AmplifyCore.state.codeBenchState;

      return {
        ...state,
        loadingMergeableCases: false,
        mergeableCaseRuns: mergeableCaseRuns
          ? mergeableCaseRuns
              .map(mergeableCaseRun =>
                addNoteType({
                  ...mergeableCaseRun,
                  selected: false,
                  primary: false,
                  tenant_name: state.currentCaseRun.tenant_name,
                  processStatus: benchEditableCore.determineProcessStatus(
                    {
                      isChargeable:
                        mergeableCaseRun?.coding_status?.is_chargeable,
                      processInstanceStatus:
                        mergeableCaseRun?.coding_status
                          ?.process_instance_status,
                      holdReason: mergeableCaseRun?.coding_status?.hold_status,
                      holdComment:
                        mergeableCaseRun?.coding_status?.hold_comment,
                      qaStatus: mergeableCaseRun?.coding_status?.qa_status,
                      cancelled: mergeableCaseRun?.coding_status?.is_cancelled,
                      roles,
                      userContext
                    },
                    mergeableCaseRun?.coding_status !== null,
                    mergeableCaseRun?.parent_caserun_uid ?? null
                  )
                })
              )
              .filter(
                mergeableCaseRun =>
                  mergeableCaseRun.process_instance_guid !==
                  state.currentCaseRun.process_instance_guid
              )
          : [] // remove current case
      };
    }
  )
  .case(mergeCasesActions.getDocumentsByCaseRun.async.started, state => {
    return {
      ...state,
      loadingNotes: true,
      activeHTMLNotes: [],
      activeNotes: []
    };
  })
  .case(
    mergeCasesActions.getDocumentsByCaseRun.async.done,
    (state, { result: documentsResults }) => {
      return {
        ...state,
        loadingNotes: false,
        activeHTMLNotes: documentsResults.htmlNotes,
        activeNotes: documentsResults.htmlNotes
      };
    }
  )
  .case(mergeCasesActions.getDocumentsByCaseRun.async.failed, state => {
    return {
      ...state,
      loadingNotes: false,
      activeHTMLNotes: [],
      activeNotes: []
    };
  });

const addNoteType = (activeItem: CMxAPI.CaseRunV2) => {
  const noteType = activeItem?.notes[0] ?? {};
  return {
    ...activeItem,
    note_type: (noteType as CMxAPI.Note)?.description ?? ''
  };
};

export default reducer;
