import { actionCreatorFactory } from 'typescript-fsa';
import { ShortcutAction } from '../../actions/action-types';
import { detailTogglePHI, openWindow } from '../../actions/ui';
import { toggleForcePasswordOption } from '../../actions/users';
import { showProcessingTimeline } from '../../actions/cases.action';
import { caseDetailPopup } from '@codametrix/shared-views';

const passthrough = <T>(object: T) => object;
const actionCreator = actionCreatorFactory();

const noActionDefined = actionCreator<void>(ShortcutAction.NOT_FOUND);

export const KeypressTransformers: CMx.ShortcutTransformers = {
  detailTogglePHI: (mask: CMx.PHIMask) => {
    const active = !mask.active;
    return { ...mask, active };
  },
  showCaseJSON: (info: any) => {
    const params = { caseId: info.case_id };
    return { ...caseDetailPopup, params };
  },
  toggleForcePasswordOption: (info: boolean) => {
    const active = !info;
    return active;
  },
  showProcessingTimeline: (info: boolean) => {
    const active = !info;
    return active;
  }
};

export const ShortcutActions: CMx.ShortcutActions = {
  detailTogglePHI: detailTogglePHI,
  showCaseJSON: openWindow,
  toggleForcePasswordOption: toggleForcePasswordOption,
  showProcessingTimeline: showProcessingTimeline
};

export const fetch = (key: string): CMx.ShortcutOperation => {
  const transformer = KeypressTransformers[key] || passthrough;
  const action = ShortcutActions[key] || noActionDefined;

  return { transformer, action };
};
