import InitialState from './initial-state';
import * as ForgotPassword from '../actions/forgot-password';
import { cmxReducerFactory } from './reducer-utils';
import { LOAD_STATES } from './common';
import { formErrors } from '../stubs/form-errors';

const reducer = cmxReducerFactory(InitialState.forgotPassword)
  .case(ForgotPassword.handleForgotPassword.async.started, (state, props) => {
    return {
      ...state,
      loadState: LOAD_STATES.started,
      formErrors: formErrors.noErrors
    };
  })
  .case(ForgotPassword.handleForgotPassword.async.failed, (state, props) => {
    return {
      ...state,
      loadState: LOAD_STATES.failed,
      formErrors: props.error.errors
    };
  })
  .case(ForgotPassword.handleForgotPassword.async.done, (state, props) => {
    return {
      ...state,
      loadState: LOAD_STATES.done
    };
  })
  .case(ForgotPassword.reduxSavePasswordDto, (state, props) => {
    return {
      ...state,
      passwordResetDto: props
    };
  })
  .case(ForgotPassword.submitNewPassword.async.started, (state, params) => {
    return {
      ...state,
      loadState: LOAD_STATES.started
    };
  })
  .case(
    ForgotPassword.submitNewPassword.async.failed,
    (state, passedErrors) => {
      return {
        ...state,
        formErrors: passedErrors.error ?? formErrors.noErrors,
        loadState: LOAD_STATES.failed
      };
    }
  )
  .case(ForgotPassword.submitNewPassword.async.done, (state, params) => {
    return {
      ...params.result,
      ...state,
      loadState: LOAD_STATES.done
    };
  })
  .case(ForgotPassword.initializeForgotPassword, state => {
    return {
      ...state,
      loadState: LOAD_STATES.initial,
      formErrors: formErrors.noErrors
    };
  });

export default reducer;
