/// <reference path="../../types.d.ts" />
import classnames from 'classnames';
import React from 'react';
import './lifeline.scss';

type ActionToastProps = {
  message: string;
  onAction: (arg: any) => void;
  actionText: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const ActionToast = (props: ActionToastProps) => {
  const { children, className, onAction, actionText, ...spreadable } = props;
  const backMessageStyles = classnames(props, `cmx-lifeline`, 'qa-ActionToast');

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onAction(window.location.pathname);
  };

  return (
    <div {...spreadable} className={classnames(backMessageStyles)}>
      {props.message}{' '}
      <span className="c-hand qa-action-link " onClick={handleClick}>
        <b>{actionText}</b>
      </span>
    </div>
  );
};
