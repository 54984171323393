/// <reference path="../../../src/types.d.ts" />
import { Login } from '../../actions/action-types';
import { formErrors } from '../../stubs/form-errors';

export const login: CMx.LoginState = {
  contexts: [],
  status: Login.INITIAL,
  error: false,
  submitting: false,
  auth: undefined,
  forcePasswordChange: undefined,
  formErrors: formErrors.noErrors,
  emailConfirmationModalOpen: false,
  loading: false,
  config: undefined
};
