export const dashboardOptions: {
  [resourceName: string]: { name: string; dashboards: string[] };
} = {
  manager_productivity_reports: {
    name: 'CMX Insights',
    dashboards: ['Case Analytics', 'Operational Analytics']
  },
  'pre-live_insights': {
    name: 'Pre-Live Analytics',
    dashboards: [
      'Code Benchmarking Tool',
      'Hospital Benchmarking Analysis',
      'Provider Productivity'
    ]
  }
};
