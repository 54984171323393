import React, { useState } from 'react';
import { Box, Grid, Text } from '@chakra-ui/react';
import { STUB_ORG_RELATIONSHIP } from './stub-organization';
import { findOrganizationRelationship } from './organization-utils';
import { OrganizationForm } from './organization-form';
import TopLevelError from './top-level-error';
import { ORGANIZATION_CONSTANTS } from './organization-constants';
import { OrgDefs } from './org-defs';
import { SubHeader } from './subheader';
import { Select } from '../../components/select/select';
import { styles } from './organization.styles';
import { ServiceLines } from './service-line-selection';

export type AssociateOrganizationFormProps = {
  associations: CMxAPI.Organization[];
  cancel: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  doAssociation: (
    edits: CMx.PartialRelationshipEdit[],
    org?: CMxAPI.Organization
  ) => void;
  edit?: boolean;
  errors: CMxCommonApp.FieldErrors;
  handleFormSave?: (org: CMxAPI.Organization) => {};
  organization: CMxAPI.Organization;
  orgRelationships: CMxAPI.OrganizationRelationship[];
  orgType: string;
  parentOrg: CMxAPI.Organization;
  relationship: CMxAPI.OrganizationRelationship;
  serviceLines: CMxAPI.ServiceLine[];
  timezones: string[];
  title: string | null;
  viewOnly: boolean;
  openAssociateForm?: boolean;
};

type AssociationForm = {
  toOrganizationId: number | null;
  fromOrganizationId: number | null;
  serviceLineId: number | null;
};
type AssociateOrgState = {
  associateForm: AssociationForm;
};

const BASE_ORGANIZATION = {
  toOrganizationId: null,
  fromOrganizationId: null,
  serviceLineId: null
};

const convertAssociationsToMenuItemDto = (
  associations: CMxAPI.Organization[]
) =>
  associations.map(association => ({
    label: association.organizationName,
    value: association.organizationCode
  }));

const AssociateOrganizationForm: React.FC<AssociateOrganizationFormProps> = props => {
  const {
    associations,
    cancel,
    doAssociation,
    edit,
    errors,
    openAssociateForm,
    organization,
    orgRelationships,
    orgType,
    parentOrg,
    relationship,
    serviceLines,
    timezones,
    title,
    viewOnly
  } = props;

  const classes = styles();
  const orgTypeName =
    orgType && OrgDefs[orgType] ? OrgDefs[orgType].title : 'Unknown';

  const initialState = {
    associateForm: { ...BASE_ORGANIZATION }
  };

  const [associateOrg, setAssociateOrg] = useState<AssociateOrgState>(
    initialState
  );
  const [selectedServiceLines, setSelectedServiceLines] = useState<
    CMx.PartialRelationshipEdit[]
  >([]);
  const [selectedOrgName, setSelectedOrgName] = useState<string>('');

  const _buildRelationshipEdits = (
    incomingEdits: CMx.PartialRelationshipEdit[],
    organization: Partial<CMxAPI.Organization>
  ) => {
    const _organizationRelationshipType = _getOrgRelationshipType();
    const serviceLines = organization.serviceLines ?? [];

    const relationshipEdits: CMx.PartialRelationshipEdit[] = incomingEdits.map(
      edit => {
        const { organizationRelationship } = edit;
        const rel = organizationRelationship;
        const fromOrganizationId =
          rel.fromOrganizationId ??
          (organization.referringOrgId || organization.parentId);
        const toOrganizationId = rel.toOrganizationId ?? organization.id;
        const organizationRelationshipType =
          rel.organizationRelationshipType || _organizationRelationshipType;

        const searchCandidate = {
          ...organizationRelationship,
          fromOrganizationId,
          toOrganizationId,
          organizationRelationshipType
        };
        const findOrgRel = findOrganizationRelationship(
          serviceLines,
          searchCandidate
        );

        if (findOrgRel !== undefined) {
          return { ...edit, organizationRelationship: { ...findOrgRel } };
        }
        const _organizationRelationship = {
          ...STUB_ORG_RELATIONSHIP,
          ...searchCandidate
        };
        return { ...edit, organizationRelationship: _organizationRelationship };
      }
    );

    return relationshipEdits;
  };

  const _getOrgRelationshipType = () => {
    return orgType === ORGANIZATION_CONSTANTS.PHYSICIAN_ORGANIZATION
      ? 'PHYSICIAN_SERVICE'
      : 'BILLING';
  };

  const handleFormSave = (
    org: CMxAPI.Organization,
    incomingEdits: CMx.PartialRelationshipEdit[]
  ) => {
    // define the org
    const orgToSave = { ...org };

    if (!orgToSave.parentTenantId) {
      orgToSave.parentTenantId = parentOrg.tenantId;
      orgToSave.parentId = parentOrg.id;
      orgToSave.organizationType = orgType;
    }
    // create org relationships
    const relationshipEdits = _buildRelationshipEdits(incomingEdits, orgToSave);
    doAssociation(relationshipEdits, orgToSave);
  };

  const handleCancel = () => {
    setAssociateOrg((prevState: AssociateOrgState) => ({
      ...prevState,
      associateView: true
    }));
    cancel();
  };

  const handleAssociateSave = () => {
    const { associateForm } = associateOrg;
    const orgToSave = { ...organization };
    let serviceLineEdits = selectedServiceLines ?? [];

    serviceLineEdits = serviceLineEdits.map(sle => {
      sle.organizationRelationship.fromOrganizationId =
        organization.referringOrgId || associateForm.fromOrganizationId;
      sle.organizationRelationship.toOrganizationId =
        associateForm.toOrganizationId;
      return sle;
    });
    const relationshipEdits = _buildRelationshipEdits(
      serviceLineEdits,
      orgToSave
    );
    doAssociation(relationshipEdits);
  };

  const handleOrgSelection = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { target } = event;
    const orgCode = target.value;
    const orgTo = associations.find(
      assoc => assoc.organizationCode === orgCode
    );

    if (!orgTo) {
      console.warn(`no org handleOrgSelection`, associations, target, orgCode);
    } else {
      setAssociateOrg((prevState: AssociateOrgState) => ({
        ...prevState,
        associateForm: {
          ...prevState.associateForm,
          toOrganizationId: orgTo.id,
          fromOrganizationId: parentOrg.id
        }
      }));
      setSelectedOrgName(orgTo?.displayName ?? orgTo?.organizationName);
    }
  };

  const handleServiceLinesSelection = (
    edits: CMx.PartialRelationshipEdit[]
  ) => {
    if (edits) setSelectedServiceLines(edits);
  };

  return (
    <Box>
      {openAssociateForm ? (
        <Grid>
          <SubHeader
            actionName={'Submit'}
            linkButtonName={'Cancel'}
            title={title}
            onLinkButtonClick={handleCancel}
            onActionClick={handleAssociateSave}
          />
          <Grid sx={classes.associationGrid}>
            <Grid
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              sx={classes.input}
              rowGap={3}>
              <Text sx={classes.label}>{`Choose Existing ${orgTypeName}`}</Text>
              <Select
                items={convertAssociationsToMenuItemDto(associations)}
                placeholder={'Choose...'}
                onChange={handleOrgSelection}
                value={selectedOrgName}
                name="association"
              />
            </Grid>
            <ServiceLines
              orgRelationships={orgRelationships}
              serviceLines={serviceLines}
              relationship={relationship}
              onChange={handleServiceLinesSelection}
            />
          </Grid>
        </Grid>
      ) : (
        <div>
          <TopLevelError errors={errors && errors.errors} />
          <OrganizationForm
            orgRelationships={orgRelationships}
            relationship={relationship}
            serviceLines={serviceLines}
            errors={errors}
            doSave={handleFormSave}
            cancel={handleCancel}
            organization={organization}
            orgType={orgType}
            viewOnly={viewOnly}
            edit={edit}
            timezones={timezones}
            title={title}
            showServiceLines
          />
        </div>
      )}
    </Box>
  );
};

AssociateOrganizationForm.displayName = 'AssociateOrganizationForm';
export { AssociateOrganizationForm };
