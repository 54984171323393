/// <reference path="../../../src/types.d.ts" />

import { cmxDateTime } from '@codametrix/ui-common';

export const codeLookup: CMx.CodeLookupState = {
  searchRequest: {
    searchTerm: '',
    codeType: 'CPT',
    effectiveDate: cmxDateTime.format(new Date(), cmxDateTime.FORMATS.DATE)
  },
  searchResult: {
    content: [
      {
        codeType: '',
        code: '',
        description: '',
        shortDescription: '',
        tenantDefined: false,
        effectiveDate: ''
      }
    ]
  }
};
