import { css } from '@emotion/react';
import { CSSProperties } from 'react';
import { gridStyles } from '../../components/data-grid/data-grid.styles';
import theme from '../../theme';

export const styleOverrides = css`
  width: 100%;
  padding: 20px;

  & .xc-card-details {
    width: 59vw;
    border-radius: 3px;
  }
  & .xc-card-updates {
    width: 59vw;
    border-radius: 3px;
    padding-bottom: 20px;
  }
  & .xc-card-notes {
    width: 28vw;
    margin-left: 28px;
    margin-top: 108px;
    border-radius: 3px;
  }
  ${gridStyles}
`;

export const styles = (): Record<string, CSSProperties> => ({
  root: {
    backgroundColor: theme.colors.structural[100],
    display: 'flex',
    flex: '1',
    width: '100%'
  }
});
