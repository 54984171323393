import { ColDef } from 'ag-grid-community';
import theme from '../../theme';

export const colDefs: ColDef[] = [
  {
    headerName: 'Name',
    field: 'displayName',
    cellStyle: { color: theme.colors.accent[200] }
  },
  {
    headerName: 'Organization Type',
    field: 'organizationType',
    valueFormatter: params => {
      return organizationSwitch(params.value);
    }
  }
];

export const ORGANIZATION_CONSTANTS = {
  EXTERNAL_APPS: 'External apps',
  CASE_CONFIG: 'Case config',
  DICTIONARIES: 'Dictionaries',
  HEALTH_SYSTEM: 'HEALTH_SYSTEM',
  HOSPITAL: 'HOSPITAL',
  TENANTS: 'Tenants',
  PHYSICIAN_ORGANIZATION: 'PHYSICIAN_ORGANIZATION',
  GROUPS: 'Groups',
  FEATURES: 'Features'
};

export type DisplayNamesMap = {
  [key: string]: string;
};

export const DISPLAY_NAMES: DisplayNamesMap = {
  HEALTH_SYSTEM: 'Health System',
  HOSPITAL: 'Hospital',
  PHYSICIAN_ORGANIZATION: 'Physician Organization'
};

export const organizationTabs = [
  {
    label: ORGANIZATION_CONSTANTS.TENANTS
  },
  {
    label: ORGANIZATION_CONSTANTS.GROUPS
  },
  {
    label: ORGANIZATION_CONSTANTS.FEATURES
  },
  {
    label: ORGANIZATION_CONSTANTS.CASE_CONFIG,
    children: [
      {
        label: 'General'
      },
      {
        label: 'Automation'
      },
      {
        label: 'HL7'
      },
      {
        label: 'Route'
      }
    ]
  },
  {
    label: ORGANIZATION_CONSTANTS.DICTIONARIES
  },
  {
    label: ORGANIZATION_CONSTANTS.EXTERNAL_APPS
  }
];

export const organizationTypes = [
  {
    label: DISPLAY_NAMES.HEALTH_SYSTEM,
    value: ORGANIZATION_CONSTANTS.HEALTH_SYSTEM
  },
  {
    label: DISPLAY_NAMES.HOSPITAL,
    value: ORGANIZATION_CONSTANTS.HOSPITAL
  },
  {
    label: DISPLAY_NAMES.PHYSICIAN_ORGANIZATION,
    value: ORGANIZATION_CONSTANTS.PHYSICIAN_ORGANIZATION
  }
];

export const states = [
  {
    value: 'Alabama',
    label: 'Alabama'
  },
  {
    value: 'Alaska',
    label: 'Alaska'
  },
  {
    value: 'Arizona',
    label: 'Arizona'
  },
  {
    value: 'Arkansas',
    label: 'Arkansas'
  },
  {
    value: 'California',
    label: 'California'
  },
  {
    value: 'Colorado',
    label: 'Colorado'
  },
  {
    value: 'Connecticut',
    label: 'Connecticut'
  },
  {
    value: 'Delaware',
    label: 'Delaware'
  },
  {
    value: 'District Of Columbia',
    label: 'District Of Columbia'
  },
  {
    value: 'Florida',
    label: 'Florida'
  },
  {
    value: 'Georgia',
    label: 'Georgia'
  },
  {
    value: 'Hawaii',
    label: 'Hawaii'
  },
  {
    value: 'Idaho',
    label: 'Idaho'
  },
  {
    value: 'Illinois',
    label: 'Illinois'
  },
  {
    value: 'Indiana',
    label: 'Indiana'
  },
  {
    value: 'Iowa',
    label: 'Iowa'
  },
  {
    value: 'Kansas',
    label: 'Kansas'
  },
  {
    value: 'Kentucky',
    label: 'Kentucky'
  },
  {
    value: 'Louisiana',
    label: 'Louisiana'
  },
  {
    value: 'Maine',
    label: 'Maine'
  },
  {
    value: 'Maryland',
    label: 'Maryland'
  },
  {
    value: 'Massachusetts',
    label: 'Massachusetts'
  },
  {
    value: 'Michigan',
    label: 'Michigan'
  },
  {
    value: 'Minnesota',
    label: 'Minnesota'
  },
  {
    value: 'Mississippi',
    label: 'Mississippi'
  },
  {
    value: 'Missouri',
    label: 'Missouri'
  },
  {
    value: 'Montana',
    label: 'Montana'
  },
  {
    value: 'Nebraska',
    label: 'Nebraska'
  },
  {
    value: 'Nevada',
    label: 'Nevada'
  },
  {
    value: 'New Hampshire',
    label: 'New Hampshire'
  },
  {
    value: 'New Jersey',
    label: 'New Jersey'
  },
  {
    value: 'New Mexico',
    label: 'New Mexico'
  },
  {
    value: 'New York',
    label: 'New York'
  },
  {
    value: 'North Carolina',
    label: 'North Carolina'
  },
  {
    value: 'North Dakota',
    label: 'North Dakota'
  },
  {
    value: 'Ohio',
    label: 'Ohio'
  },
  {
    value: 'Oklahoma',
    label: 'Oklahoma'
  },
  {
    value: 'Oregon',
    label: 'Oregon'
  },
  {
    value: 'Pennsylvania',
    label: 'Pennsylvania'
  },
  {
    value: 'Rhode Island',
    label: 'Rhode Island'
  },
  {
    value: 'South Carolina',
    label: 'South Carolina'
  },
  {
    value: 'South Dakota',
    label: 'South Dakota'
  },
  {
    value: 'Tennessee',
    label: 'Tennessee'
  },
  {
    value: 'Texas',
    label: 'Texas'
  },
  {
    value: 'Utah',
    label: 'Utah'
  },
  {
    value: 'Vermont',
    label: 'Vermont'
  },
  {
    value: 'Virginia',
    label: 'Virginia'
  },
  {
    value: 'Washington',
    label: 'Washington'
  },
  {
    value: 'West Virginia',
    label: 'West Virginia'
  },
  {
    value: 'Wisconsin',
    label: 'Wisconsin'
  },
  {
    value: 'Wyoming',
    label: 'Wyoming'
  }
];

const organizationSwitch = (label: string) => {
  switch (label) {
    case 'HEALTH_SYSTEM': {
      return 'Health System';
    }
    case 'HOSPITAL': {
      return 'Hospital';
    }
    case 'PHYSICIAN_ORGANIZATION': {
      return 'Physician Organization';
    }
    default:
      return label;
  }
};
