import { commonActions } from '@codametrix/ui-common';
import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory();
export const inProgress = actionCreator<void>(
  commonActions.CMxApplication.IN_PROGRESS,
  {
    user: true
  }
);

const exportable = { inProgress };

export default exportable;
