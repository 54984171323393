import { connect } from 'react-redux';
import TableauDashboard from './tableau-dashboard';
import {
  instantiateDashboard,
  fetchTableauToken
} from '../../actions/analytics';
import {
  authenticationChannel,
  commonActions,
  sessionManagement
} from '@codametrix/ui-common';

const { activityMonitor } = sessionManagement;

const mapStateToProps = function(
  state: CMx.ApplicationState
): AppProps.DashboardProperties {
  const { analytics, ui } = state;
  return {
    ...analytics,
    serviceLine: ui.serviceLine,
    activeHealthSystemCode: ui.context.activeHealthSystemCode ?? ''
  };
};

const mapDispatchToProps = (dispatch: any): AppProps.DashboardMethods => {
  return {
    instantiateDashboard: (serviceLine?: CMx.ServiceLine) => {
      dispatch(fetchTableauToken(serviceLine));
      dispatch(instantiateDashboard());
    },
    registerActivity: () => {
      activityMonitor.isActive = true;
      authenticationChannel.postMessage({
        type: commonActions.AuthenticatedWindow.ACTIVITY,
        payload: true,
        meta: {
          suppressRedux: true
        }
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableauDashboard);
