import { cmxReducerFactory } from './reducer-utils';
import InitialState from '../reducers/initial-state';
import { toggleDrawer, toggleDrawerContent } from '../actions/ui';
import { createCaseActions } from '@codametrix/ui-common';

export const reducer = cmxReducerFactory(InitialState.createCase)
  .case(toggleDrawerContent, (state, isOpen) => {
    return { ...state, isOpen };
  })
  .case(toggleDrawer, (state, isDrawerOpen) => {
    return { ...state, isDrawerOpen };
  })
  .case(
    createCaseActions.navigateCreateCase.async.started,
    (state, caseRun) => {
      return {
        ...state,
        activeCaseRun: caseRun,
        isDrawerOpen: true
      };
    }
  )
  .case(createCaseActions.navigateCreateCase.async.done, state => {
    return { ...state, isOpen: true };
  })
  .case(createCaseActions.fetchNotesSpecialty.async.started, state => {
    return {
      ...state,
      notesSpecialty: [],
      notesSpecialtyLoading: true
    };
  })
  .case(
    createCaseActions.fetchNotesSpecialty.async.done,
    (state, { result: dictionaryValues }) => {
      return {
        ...state,
        notesSpecialty: dictionaryValues?.rowValues ?? [],
        notesSpecialtyLoading: false
      };
    }
  );

export default reducer;
