import theme from '../../theme';

export const styles = () => {
  const capturedTimeHeader = {
    ...theme.textStyles.h2
  };
  const activityLabel = {
    ...theme.textStyles.h4,
    textAlign: 'left'
  };
  const weeklyTotal = {
    marginLeft: 'auto'
  };
  const selectedDayLabel = {
    ...theme.textStyles['Subtitle 1'],
    marginTop: theme.space[20],
    marginBottom: theme.space[16]
  };
  const hoursLabelContainer = {
    marginLeft: 'auto'
  };
  const expectedHoursLabel = {
    marginTop: theme.space[20],
    marginBottom: theme.space[16]
  };
  const totalHoursLabel = {
    marginTop: theme.space[20],
    marginBottom: theme.space[16],
    marginLeft: theme.space[16]
  };
  const weekTimelineContainer = {
    justifyContent: 'space-between'
  };
  const dayTimelineContainer = {
    marginRight: theme.space[12],
    color: theme.colors.text['lowEmphasis']
  };
  const dayTimelineContainerSelected = {
    marginRight: theme.space[12],
    color: theme.colors.text['interactive']
  };
  const dayTimelineDayOfWeek = {
    ...theme.textStyles['Body 1'],
    justifyContent: 'center',
    marginBottom: theme.space[8]
  };
  const dayTimelineDate = {
    ...theme.textStyles.h4,
    justifyContent: 'center',
    marginBottom: theme.space[8]
  };
  const capturedTimeSubheaderContainer = {
    marginBottom: theme.space[16]
  };
  const dayTimelineDividerSelected = {
    border: '2px solid',
    borderColor: theme.colors.text['interactive'],
    marginTop: 'auto',
    borderRadius: '1px'
  };
  const dayTimelineDivider = {
    border: '0.5px solid',
    marginTop: 'auto',
    width: '85%',
    marginLeft: '7%'
  };
  const changeWeekButton = {
    color: theme.colors.text['lowEmphasis'],
    marginTop: 'auto'
  };
  const addTimeRowContainer = {
    justifyContent: 'left',
    marginBottom: theme.space[10]
  };
  const startTimeSelect = {
    marginRight: theme.space[10],
    width: '22%',
    color: theme.colors.text['lowEmphasis']
  };
  const enterHoursSelect = {
    marginRight: theme.space[16],
    width: '30%',
    borderColor: theme.colors.foundation[200],
    color: theme.colors.text['lowEmphasis']
  };
  const descriptionSelect = {
    borderColor: theme.colors.foundation[200],
    color: theme.colors.text['lowEmphasis']
  };
  const descriptionSelectContainer = {
    marginRight: theme.space[10],
    width: '34%'
  };
  const addTimeButton = {
    backgroundColor: theme.colors.accent[100],
    color: theme.colors.text['light'],
    fontSize: '10px',
    width: '8%'
  };
  const protectedTimeRowContainer = {
    marginBottom: theme.space[10],
    justifyContent: 'left'
  };
  const startTimePast = {
    marginRight: theme.space[10],
    width: '22%',
    borderColor: theme.colors.foundation[200]
  };
  const reasonPastContainer = {
    marginRight: theme.space[10],
    width: '34%',
    borderColor: theme.colors.foundation[200]
  };
  const reasonPastSelect = {
    borderColor: theme.colors.foundation[200]
  };
  const durationPast = {
    marginRight: theme.space[16],
    width: '30%',
    borderColor: theme.colors.foundation[200]
  };
  const hoursLabel = {
    ...theme.textStyles['Subtitle 1'],
    marginRight: theme.space[4]
  };
  const hoursText = {
    lineHeight: theme.space[16]
  };
  const deleteButton = {
    borderColor: theme.colors.foundation[200]
  };
  const durationOverlapWarning = {
    backgroundColor: theme.colors.warning[500],
    color: 'white',
    borderRadius: 3,
    marginBottom: theme.space[16],
    height: theme.space[32],
    width: '96%',
    alignItems: 'center'
  };
  const durationOverlapWarningText = {
    marginLeft: theme.space[8]
  };
  const noPreviousTimeWarning = {
    backgroundColor: theme.colors.warning[500],
    color: 'white',
    borderRadius: 3,
    marginBottom: theme.space[16],
    height: theme.space[32],
    width: '40%',
    alignItems: 'center',
    justifyContent: 'center'
  };
  const noEditWarning = {
    backgroundColor: theme.colors.warning[500],
    color: 'white',
    borderRadius: 3,
    marginBottom: theme.space[16],
    height: theme.space[32],
    width: '70%',
    alignItems: 'center',
    justifyContent: 'center'
  };
  const timeEntryOverlapErrorHeader = {
    ...theme.textStyles.h2,
    color: theme.colors.alert[500]
  };

  return {
    '.capturedTimeHeader': capturedTimeHeader,
    '.activityLabel': activityLabel,
    '.weeklyTotal': weeklyTotal,
    '.selectedDayLabel': selectedDayLabel,
    '.hoursLabelContainer': hoursLabelContainer,
    '.expectedHoursLabel': expectedHoursLabel,
    '.totalHoursLabel': totalHoursLabel,
    '.weekTimelineContainer': weekTimelineContainer,
    '.dayTimelineContainer': dayTimelineContainer,
    '.dayTimelineContainerSelected': dayTimelineContainerSelected,
    '.dayTimelineDayOfWeek': dayTimelineDayOfWeek,
    '.dayTimelineDate': dayTimelineDate,
    '.capturedTimeSubheaderContainer': capturedTimeSubheaderContainer,
    '.dayTimelineDividerSelected': dayTimelineDividerSelected,
    '.dayTimelineDivider': dayTimelineDivider,
    '.changeWeekButton': changeWeekButton,
    '.addTimeRowContainer': addTimeRowContainer,
    '.startTimeSelect': startTimeSelect,
    '.descriptionSelectContainer': descriptionSelectContainer,
    '.descriptionSelect': descriptionSelect,
    '.enterHoursSelect': enterHoursSelect,
    '.addTimeButton': addTimeButton,
    '.protectedTimeRowContainer': protectedTimeRowContainer,
    '.startTimePast': startTimePast,
    '.reasonPastContainer': reasonPastContainer,
    '.reasonPastSelect': reasonPastSelect,
    '.durationPast': durationPast,
    '.hoursLabel': hoursLabel,
    '.hoursText': hoursText,
    '.deleteButton': deleteButton,
    '.durationOverlapWarning': durationOverlapWarning,
    '.durationOverlapWarningText': durationOverlapWarningText,
    '.noPreviousTimeWarning': noPreviousTimeWarning,
    '.noEditWarning': noEditWarning,
    '.timeEntryOverlapErrorHeader': timeEntryOverlapErrorHeader
  };
};
