import { push, replace } from 'connected-react-router';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { AsyncDictionaryRequest, DictionaryUpload } from './action-types';
import {
  showFeedback,
  dismissFeedback,
  showFeedbackError,
  loading
} from './ui';
import { api } from '../core/net';

import { HttpMethods, cmxErrors, commonEnums } from '@codametrix/ui-common';
import { loadDictionary } from './dictionary';
import { toggleDeleteModal } from './dictionary-values';
import { isNewDesign } from '../core/middleware/new-design-middleware';

const { BadRequestError } = cmxErrors;
const actionCreator = actionCreatorFactory();
const createAsync = asyncFactory<CMxAPI.DictionaryUpload>(actionCreator);

type RequestStatus = {
  requestIds: number[];
  successRoute: string;
};

const requestInitiated = actionCreator<RequestStatus>(
  AsyncDictionaryRequest.ASYNC_REQUEST_INIT
);

const requestUpdate = actionCreator<RequestStatus>(
  AsyncDictionaryRequest.ASYNC_REQUEST_PROGRESS
);

const selectedDictionary = actionCreator<{ dictionaryId: number }>(
  AsyncDictionaryRequest.SELECT_DICTIONARY
);

const fetchRequestStatus = createAsync<
  RequestStatus,
  CMxAPI.DictionaryRequestResponse,
  CMxCommonApp.SubmitError
>(
  AsyncDictionaryRequest.FETCH_REQUEST_STATUS,
  async (requestInfo, dispatch: any) => {
    const { requestIds, successRoute } = requestInfo;
    const responseList: CMxAPI.DictionaryRequestResponse[] = await Promise.all(
      requestIds.map(requestId => {
        const endpoint = `/dictionary/request/${requestId}/v2`;
        isNewDesign && dispatch(loading(true));
        const uploadProcessing = api<CMxAPI.DictionaryRequestResponse>(
          { endpoint },
          false
        ).finally(() => {
          isNewDesign && dispatch(loading(false));
        });
        return uploadProcessing;
      })
    );

    let requestType = responseList[0].requestType;
    if (requestType)
      requestType =
        requestType?.charAt(0) +
        requestType?.slice(1, requestType.length).toLowerCase();

    const hasError = responseList.some(response => response.errorStatus);
    if (hasError) {
      // might not need to do anything here.
      dispatch(
        showFeedback({
          id: Date.now(),
          message: `Dictionary ${requestType} Failed`,
          dismissable: true,
          className: 'feedback-working'
        })
      );
    } else if (
      responseList.every(
        res =>
          res.requestStatus === commonEnums.DictionaryRequestStatus.COMPLETED
      )
    ) {
      if (requestType !== 'Delete') {
        const soleResponse = responseList[0];
        // redirect to the right spot.
        if (soleResponse.dictionaryId !== null) {
          isNewDesign && dispatch(loading(true));
          dispatch(
            selectedDictionary({ dictionaryId: soleResponse.dictionaryId })
          );
          isNewDesign && dispatch(loading(true));
          const dictionary = await api<CMxAPI.Dictionary>({
            endpoint: `/dictionary/${soleResponse.dictionaryId}/filters/v2?pageSize=0`,
            init: {
              method: HttpMethods.POST
            },
            body: []
          }).finally(() => {
            isNewDesign && dispatch(loading(false));
          });
          isNewDesign && dispatch(loading(true));
          await dispatch(
            loadDictionary({
              data: dictionary,
              path: successRoute + `/dictionary/${soleResponse.dictionaryName}`
            })
          ).finally(() => {
            isNewDesign && dispatch(loading(false));
          });
        }
      } else {
        dispatch(replace(successRoute));

        dispatch(toggleDeleteModal(false));

        dispatch(
          showFeedback({
            id: Date.now(),
            message: `Dictionary Delete Success`,
            dismissable: true,
            className: 'feedback-working'
          })
        );
      }
    }
    return responseList[0];
  }
);

const uploadDictionary = createAsync<
  CMx.dictionaryUploadPayload,
  CMxAPI.DictionaryRequestResponse,
  CMxCommonApp.SubmitError
>(DictionaryUpload.UPLOAD_DICTIONARY, async (payload, dispatch: any) => {
  const formData = new FormData();

  const {
    dictionaryCSV,
    successRoute,
    isCreate,
    tenantId,
    dictionaryName,
    description,
    purpose,
    crossMapIndicator,
    columnDefinitionMetadata,
    isCorrection,
    jiraTicket
  } = payload;

  const metadata = {
    tenantId,
    dictionaryName,
    description,
    purpose,
    crossMapIndicator,
    columnDefinitionMetadata,
    jiraTicket
  };

  var blob = new Blob([dictionaryCSV], { type: 'text/csv' });

  formData.append('dictionaryCSV', blob);
  formData.append('metadata', JSON.stringify(metadata));

  let endpoint = `/dictionary/csv`;
  if (!isCreate) {
    if (isCorrection) {
      endpoint += `/correct`;
    } else {
      endpoint += `/update`;
    }
  }
  endpoint += `/v2`;
  isNewDesign && dispatch(loading(true));
  const res: CMxAPI.DictionaryRequestResponse = await api<
    CMxAPI.DictionaryRequestResponse
  >(
    {
      endpoint,
      init: {
        method: HttpMethods.POST,
        body: formData
      }
    },
    false
  )
    .catch(e => {
      if (e instanceof BadRequestError) {
        dispatch(dismissFeedback(false));
        dispatch(
          showFeedbackError({
            id: Date.now(),
            message: `Dictionary Upload Unsuccessful`,
            dismissable: true
          })
        );
      }
      throw e;
    })
    .finally(() => {
      isNewDesign && dispatch(loading(false));
    });

  let navParams = new URLSearchParams({
    requestId: res.id.toString()
  });

  // redirect to the request uploading page
  dispatch(push(successRoute + `/request?${navParams.toString()}`));
  return res;
});

export {
  uploadDictionary,
  selectedDictionary,
  requestInitiated,
  fetchRequestStatus,
  requestUpdate
};
