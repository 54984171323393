/// <reference path="../../../src/types.d.ts" />

type RulesState = {
  decisionDefinitions: CMxAPI.DecisionDefinitionDto[];
  selectedDecisionName: string;
  selectedDiagramXML: string;
};

const rulesListPath: CMx.DisplayablePath = {
  displayName: 'Rules',
  path: '/cmx/admin/rules/'
};

const dmnViewerPath: CMx.DisplayablePath = {
  displayName: 'Decision Definition DMN',
  path: '/cmx/admin/rules/:taskId/'
};

export const rulesPaths: CMx.DisplayablePath[] = [rulesListPath, dmnViewerPath];

export const rulesList: RulesState = {
  decisionDefinitions: [],
  selectedDecisionName: '',
  selectedDiagramXML: ''
};
