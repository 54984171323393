import { Button } from '../../components/button/button';
import { ButtonSize } from '../../core/enums';
import theme from '../../theme';

import { LoadingSlugs } from '@codametrix/ui-components';
import deepEqual from 'deep-equal';
import React from 'react';
import { LOAD_STATES } from '../../reducers/common';
import UpdatePasswordForm from '../password-change/password-change-form';

class ResetPassword extends React.Component<AppProps.ResetPasswordProps> {
  componentDidMount() {
    const token = new URL(window.location.href).searchParams.get('token') || '';
    const expiry =
      new URL(window.location.href).searchParams.get('expiry') || '';
    const firstName =
      new URL(window.location.href).searchParams.get('firstName') || '';
    const lastName =
      new URL(window.location.href).searchParams.get('lastName') || '';
    const username =
      new URL(window.location.href).searchParams.get('username') || '';

    const passwordDto: CMxAPI.PasswordReset = {
      token,
      expiry: parseInt(expiry),
      firstName,
      lastName,
      username,
      userId: '',
      id: null
    };
    this.props.savePasswordResetParameters(passwordDto);
  }

  render() {
    const {
      passwordResetDto,
      save,
      handleNavigateHome,
      loadState,
      formErrors,
      isValidToken
    } = this.props;

    const handleSubmit = (newPassword: CMx.NewPasswordData) => {
      save({
        ...passwordResetDto,
        password: newPassword.newPassword,
        confirmPassword: newPassword.newPasswordConfirm
      });
    };
    const isLoading = deepEqual(loadState, LOAD_STATES.started);
    let inner;
    if (deepEqual(loadState, LOAD_STATES.done)) {
      inner = (
        <div>
          <h5>Password Reset Success</h5>
          <p>
            Please return to the login page and enter your new credentials.
          </p>{' '}
          <Button
            size={ButtonSize.MD}
            label={'Return'}
            backgroundColor={theme.colors.accent[100]}
            color={theme.colors.text['light']}
            name="Return"
            dataTestId="returnResetPass"
            onClick={handleNavigateHome}
          />
        </div>
      );
    } else if (
      deepEqual(loadState, LOAD_STATES.started) &&
      isValidToken === false
    ) {
      inner = <LoadingSlugs numberItems={5} />;
    } else if (loadState.error === true && isValidToken === false) {
      inner = (
        <div>
          <div className="error-msg">
            <i className="fa fa-times-circle"></i>
            &nbsp;
            {this.props.formErrors.fieldErrors.confirmPassword}
          </div>
          <p>
            Your password recovery email may also no longer be valid. Please try
            again or contact a CodaMetrix administrator for further assistance.
          </p>
          <Button
            size={ButtonSize.MD}
            label={'Return'}
            backgroundColor={theme.colors.accent[100]}
            color={theme.colors.text['light']}
            name="Return"
            dataTestId="returnResetPass"
            onClick={handleNavigateHome}
          />
        </div>
      );
    } else {
      inner = (
        <div>
          <h5>
            Welcome back {passwordResetDto.firstName}{' '}
            {passwordResetDto.lastName}
          </h5>
          <p>
            You have requested to reset your password. <br />
            Please enter and confirm your new password below.
          </p>
          <UpdatePasswordForm
            {...this.props}
            handleNewPassword={handleSubmit}
            handleContinue={() => {}}
            submitting={isLoading}
            error={formErrors.status !== ''}
          />
        </div>
      );
    }
    return (
      <div className={'reset-password-form qa-reset-password-form'}>
        {inner}
      </div>
    );
  }
}

export default ResetPassword;
