import { commonEnums } from '@codametrix/ui-common';

const { UserPreferenceKey } = commonEnums;

const getDefaultServiceLine = (
  preferences: CMxAPI.Preference[]
): number | undefined => {
  const res = preferences?.find(
    pref => pref.keyname === UserPreferenceKey.DEFAULT_USER_SERVICE_LINE
  )?.firstValue;

  if (res?.length) {
    return parseInt(res);
  }
};

const getDefaultOrg = (preferences: CMxAPI.Preference[]) => {
  const res = preferences?.find(
    pref => pref.keyname === UserPreferenceKey.DEFAULT_USER_CONTEXT
  )?.firstValue;

  if (res?.length) {
    return parseInt(res);
  }
};

const getHomepagePreference = (preferences: CMxAPI.Preference[]) => {
  const preference = preferences?.find(
    pref => pref.keyname === UserPreferenceKey.DEFAULT_HOMEPAGE
  );
  return preference;
};

const getContextHomepage = (
  tenantId: string = 'DOES_NOT_EXIST',
  preference?: CMxAPI.Preference
) => {
  const value = preference?.values.find(value => {
    return value.tenantId && value.tenantId === tenantId;
  });

  if (value && value.tenantId) {
    return { path: value.value, tenantId: value.tenantId };
  }
};
export {
  getDefaultServiceLine,
  getDefaultOrg,
  getHomepagePreference,
  getContextHomepage
};
