import {
  cmxTypeguards,
  codeBenchActions,
  commonEnums
} from '@codametrix/ui-common';
import { getPrevOrNextCase } from '../../actions/cases.action';
import { Middleware } from 'redux';
import { MiddlewareProcessor, middleWareWrapper } from './middleware-utils';

const { loadNextOrPreviousCase } = codeBenchActions;

const serviceDeskProcessor: MiddlewareProcessor = (api, next, action) => {
  if (
    cmxTypeguards.isFSA(action) &&
    action.type === loadNextOrPreviousCase.type &&
    action.payload
  ) {
    const status = action.payload.status?.toUpperCase();
    const cursorAPIPayload = action.payload?.cursorAPIPayload;
    const cursorAPIContext = cursorAPIPayload?.cursorAPIContext;
    if (status === commonEnums.FeedbackMeta.SUCCESS) {
      if (cursorAPIContext?.servicedeskMode && cursorAPIContext?.workitemId) {
        api.dispatch(getPrevOrNextCase(cursorAPIPayload) as any);
      }
    }
  }
  return next(action);
};
export const serviceDeskMiddleware: Middleware = middleWareWrapper(
  serviceDeskProcessor
);
