import React, { useRef } from 'react';
import { Button } from '../../components/button/button';
import { Grid, Text } from '@chakra-ui/react';
import { ButtonVariant } from '../../core/enums';
import { styles } from './organization.styles';
import { Select } from '../../components/select/select';
import { RowsPerPage } from '../../components/rows-per-page/rows-per-page';

export type SubHeaderProps = {
  actionName?: string;
  className?: any;
  handleMenuItemClick?: (orgType: string | boolean) => void;
  isActionDisabled?: boolean;
  linkButtonName?: string;
  menuButtonName?: string;
  menuItems?: any;
  onLinkButtonClick?: () => void;
  onActionClick?: () => void;
  primaryBtnName?: string;
  isPrimaryBtnDisabled?: boolean;
  onPrimaryBtnClick?: (event: any) => void;
  title: string | null;
  href?: string;
  handleAnchorElementClick?: (event: any) => void;
  anchorButtonName?: string;
  anchorButtonVariant?: ButtonVariant;
  pageSize?: number;
  onPageSizeChange?: (pageSize: number) => void;
};

const SubHeader: React.FC<SubHeaderProps> = props => {
  const classes = styles();

  const {
    title,
    linkButtonName,
    actionName,
    className,
    onLinkButtonClick,
    onActionClick,
    isActionDisabled,
    handleMenuItemClick,
    menuButtonName,
    menuItems,
    href,
    handleAnchorElementClick,
    anchorButtonName,
    anchorButtonVariant,
    primaryBtnName,
    isPrimaryBtnDisabled,
    onPrimaryBtnClick,
    pageSize,
    onPageSizeChange
  } = props;

  const anchorRef = useRef<any>(null);

  const anchorButtonClick = () => {
    anchorRef.current.click();
  };

  const handleMenuItemSelection = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.currentTarget as HTMLButtonElement;
    handleMenuItemClick && handleMenuItemClick(target.value);
  };

  return (
    <Grid
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between">
      <Grid display="flex" alignItems="center">
        <Text sx={className}>{title}</Text>
      </Grid>
      <Grid display="flex" flexDirection="row" columnGap={3}>
        {pageSize && onPageSizeChange && (
          <RowsPerPage
            pageSize={pageSize}
            handlePageSize={onPageSizeChange}
            className={classes.select}
          />
        )}
        {linkButtonName && (
          <Button
            label={linkButtonName}
            variant={ButtonVariant.LINKS}
            onClick={onLinkButtonClick}
            name={linkButtonName}
            dataTestId={`${linkButtonName}Btn`}
          />
        )}
        {href && anchorButtonVariant && anchorButtonName && (
          <>
            <Button
              label={anchorButtonName ?? ''}
              name={anchorButtonName ?? ''}
              variant={anchorButtonVariant}
              onClick={anchorButtonClick}
            />
            <a
              hidden
              ref={anchorRef}
              href={href}
              data-testid={anchorButtonName}
              onClick={handleAnchorElementClick}
              style={{
                display: 'block',
                textAlign: 'center',
                paddingTop: '10px',
                color: '#E5927D',
                textDecoration: 'none',
                outline: 'none'
              }}>
              {anchorButtonName ?? ''}
            </a>
          </>
        )}
        {actionName && (
          <Button
            label={actionName}
            variant={ButtonVariant.PRIMARY}
            onClick={onActionClick}
            disabled={isActionDisabled}
            name={actionName}
            dataTestId={`${actionName}Btn`}
          />
        )}
        {primaryBtnName && (
          <Button
            label={primaryBtnName}
            variant={ButtonVariant.PRIMARY}
            onClick={onPrimaryBtnClick}
            disabled={isPrimaryBtnDisabled}
            name={primaryBtnName}
          />
        )}
        {menuButtonName && menuItems.length > 0 && (
          <Select
            items={menuItems}
            value={menuButtonName}
            onChange={handleMenuItemSelection}
            classes={{ button: classes.button, icon: classes.icon }}
            dataTestId={`${menuButtonName}Btn`}
            isMenuButton={true}
          />
        )}
      </Grid>
    </Grid>
  );
};

SubHeader.displayName = 'SubHeader';
export { SubHeader };
