import {
  Avatar,
  Divider,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text
} from '@chakra-ui/react';
import React from 'react';
import { styles } from './user-profile.styles';
import { ReactComponent as SettingsIcon } from '../../assets/images/profile-preferences.svg';
import { ReactComponent as LogoutIcon } from '../../assets/images/logout.svg';
import { ReactComponent as Calendar } from '../../assets/images/calendar-large.svg';
import './user-profile.scss';
import { useSelector } from 'react-redux';
import { MyStatus } from '../../views/time-entry/my-status';

export type UserProfileProps = {
  userInitials: any;
  onLogout: () => void;
  openAccountSettings: () => void;
  openCapturedTime: () => void;
  profile_src?: string;
  activeUser: CMxAPI.User;
  listStatus: (activeTenantId: string) => void;
  updateStatus: (
    status: string,
    user: CMxAPI.User,
    context: CMx.Context
  ) => void;
};

export const UserProfile: React.FC<UserProfileProps> = props => {
  const {
    userInitials,
    onLogout,
    openAccountSettings,
    openCapturedTime,
    listStatus,
    updateStatus,
    profile_src,
    activeUser
  } = props;
  const classes = styles();
  const ui = useSelector((state: CMx.ApplicationState) => state.ui);
  const resources = ui.context.activeContext?.resources ?? [];
  const timeEntryResource = new Set(['protected_time']);
  const timeEntry = resources.some(resource =>
    timeEntryResource.has(resource.resourceName)
  );

  return (
    <PopoverContent sx={classes['.mainGrid']} className="popOverStyle">
      <PopoverHeader sx={classes['.headerGrid']}>
        <Flex sx={classes['.headerFlex']}>
          <Avatar
            name={userInitials}
            src={profile_src}
            sx={classes['.usernNameAvatar']}
          />
          <Flex sx={classes['.userHeader']}>
            <Flex sx={classes['.userName']}>
              <Text className="text">{`${activeUser?.firstName} ${activeUser?.lastName}`}</Text>
            </Flex>
            <Flex>
              <Text sx={classes['.userNameText']}>{activeUser?.username}</Text>
            </Flex>
          </Flex>
        </Flex>
        <Divider sx={classes['.divider']} />
      </PopoverHeader>
      <PopoverBody>
        {timeEntry && (
          <>
            <Popover
              id="my-status-popover"
              closeOnBlur={true}
              placement="right"
              trigger="hover">
              <PopoverTrigger>
                <Flex
                  sx={classes['.switchItem']}
                  className={`hoverCursor`}
                  data-testid={'availabilityTracker'}>
                  <Calendar />
                  <Text className="text" sx={classes['.textStyles']}>
                    My Status
                  </Text>
                </Flex>
              </PopoverTrigger>
              <MyStatus
                listStatus={listStatus}
                updateStatus={updateStatus}
                user={ui.activeUser}
                context={ui.context}></MyStatus>
            </Popover>
          </>
        )}
        {timeEntry && (
          <Flex
            sx={classes['.switchItem']}
            className={`hoverCursor`}
            data-testid={'normalTime'}
            onClick={openCapturedTime}>
            <Calendar />
            <Text className="text" sx={classes['.textStyles']}>
              Captured Time
            </Text>
          </Flex>
        )}
        <Flex
          sx={classes['.switchItem']}
          className={`hoverCursor`}
          data-testid={'accountSettings'}
          onClick={openAccountSettings}>
          <SettingsIcon />
          <Text className="text" sx={classes['.textStyles']}>
            Account Settings
          </Text>
        </Flex>
        <Flex
          sx={classes['.switchItem']}
          className={`hoverCursor`}
          data-testid={'logOut'}
          onClick={onLogout}>
          <LogoutIcon />
          <Text className="text" sx={classes['.textStyles']}>
            Log out
          </Text>
        </Flex>
      </PopoverBody>
    </PopoverContent>
  );
};
