/// <reference path="../types.d.ts" />

import InitialState from '../reducers/initial-state';
import { cmxReducerFactory } from './reducer-utils';
import * as NormalTimeActions from '../actions/normal-time';
import { LOAD_STATES } from './common';

const reducer = cmxReducerFactory(InitialState.workingHours)
  .case(NormalTimeActions.saveNormalTime.async.started, state => {
    return {
      ...state,
      loadState: LOAD_STATES.initial
    };
  })
  .case(NormalTimeActions.saveNormalTime.async.failed, state => {
    return {
      ...state,
      loadState: LOAD_STATES.failed
    };
  })
  .case(NormalTimeActions.saveNormalTime.async.done, (state, { result }) => {
    const workingHours: { [day: string]: number } = state.savedHours;
    if (result) {
      result.map(day => (workingHours[day.day] = day.hoursWorked));
    }
    return {
      ...state,
      loadState: LOAD_STATES.done,
      savedHours: workingHours
    };
  })
  .case(
    NormalTimeActions.listNormalTime.async.started,
    (state, updatedCapturedTime) => {
      return {
        ...state
      };
    }
  )
  .case(
    NormalTimeActions.listNormalTime.async.failed,
    (state, updatedCapturedTime) => {
      return {
        ...state
      };
    }
  )
  .case(
    NormalTimeActions.listNormalTime.async.done,
    (state, updatedCapturedTime) => {
      const workingHours: { [day: string]: number } = state.savedHours;
      if (updatedCapturedTime.result) {
        updatedCapturedTime.result.map(
          day => (workingHours[day.day] = day.hoursWorked)
        );
      }
      return {
        ...state,
        savedHours: workingHours
      };
    }
  );

export default reducer;
