import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';

export interface IconLabelProps {
  className?: string;
  color?: string;
  rightIcon?: React.ReactElement;
  leftIcon?: React.ReactElement;
  label: string;
  isLeft?: boolean;
  isRight?: boolean;
  gap?: string;
  alignItems?: string;
}
export const IconLabel: React.FC<IconLabelProps> = ({
  className,
  color,
  rightIcon,
  leftIcon,
  label,
  isLeft,
  isRight,
  alignItems,
  gap,
  ...props
}) => {
  return (
    <Flex alignItems={alignItems} gap={gap}>
      {isLeft && leftIcon}
      <Box>
        <Text>{label}</Text>
      </Box>
      {isRight && rightIcon}
    </Flex>
  );
};

IconLabel.defaultProps = {
  className: '',
  gap: '8',
  alignItems: 'center'
};

IconLabel.displayName = 'IconLabel';

export default IconLabel;
