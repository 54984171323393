import Input from '../../components/input/input';
import { Button } from '../../components/button/button';
import { ButtonSize } from '../../core/enums';
import theme from '../../theme';
import React, { useState, FormEvent } from 'react';
import { cmxErrors } from '@codametrix/ui-common';
const { getFieldErrorMessage } = cmxErrors;

const UpdatePasswordForm: React.FC<CMx.PasswordChangeFormProps> = (
  props: CMx.PasswordChangeFormProps
) => {
  const {
    handleNewPassword,
    error,
    submitting,
    includeOldPassword,
    formErrors
  } = props;

  const [existingPassword, changeOldPassword] = useState<string>('');
  const [newPassword, changeNewPassword] = useState<string>('');
  const [newPasswordConfirm, changeNewPasswordConfirm] = useState<string>('');

  const newPasswordErrorMessage = getFieldErrorMessage(formErrors, [
    'newPassword',
    'password'
  ]);
  const newPasswordConfirmErrorMessage = getFieldErrorMessage(formErrors, [
    'newPasswordConfirm',
    'confirmPassword'
  ]);
  const existingPasswordErrorMessage = getFieldErrorMessage(formErrors, [
    'existingPassword'
  ]);

  const submitEnabled =
    (existingPassword.length || !includeOldPassword) &&
    newPassword.length &&
    newPasswordConfirm.length
      ? false
      : true;

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    const passwordData = {
      existingPassword,
      newPassword,
      newPasswordConfirm
    };

    handleNewPassword(passwordData);
  };

  return (
    <form className="password-change-form" onSubmit={handleSubmit}>
      <fieldset disabled={submitting}>
        {error && (
          <div className="error-msg">
            <i className="fa fa-times-circle"></i>
            &nbsp; There was an issue changing your password. Please check that
            your input is correct.
          </div>
        )}

        <div className="form-group">
          {includeOldPassword ? (
            <div className="form-row">
              <label className="form-label" htmlFor="input-email-1">
                Current Password
                <span className="required-indicator">*</span>
              </label>
              <Input
                autoFocus
                onChange={e => {
                  changeOldPassword(e.target.value);
                }}
                name={'existingPassword'}
                placeholder={''}
                type={'password'}
                className={error ? 'form-input is-error' : 'form-input'}
              />
              <span className={'error-text'}>
                {existingPasswordErrorMessage}
              </span>
            </div>
          ) : null}

          <div className="form-row">
            <label className="form-label" htmlFor="input-email-1">
              New Password
              <span className="required-indicator">*</span>
            </label>
            <Input
              autoFocus
              onChange={e => {
                changeNewPassword(e.target.value);
              }}
              name={'newPassword'}
              placeholder={''}
              type={'password'}
              className={error ? 'form-input is-error' : 'form-input'}
            />
            <span className={'error-text'}>{newPasswordErrorMessage}</span>
          </div>

          <div className="form-row">
            <label className="form-label" htmlFor="input-email-1">
              Confirm New Password
              <span className="required-indicator">*</span>
            </label>

            <Input
              autoFocus
              onChange={e => {
                changeNewPasswordConfirm(e.target.value);
              }}
              name={'newPasswordConfirm'}
              placeholder={''}
              type={'password'}
              className={error ? 'form-input is-error' : 'form-input'}
            />
            <span className={'error-text'}>
              {newPasswordConfirmErrorMessage}
            </span>
          </div>
          <div className="form-row">
            <Button
              size={ButtonSize.MD}
              disabled={submitEnabled}
              label={'Submit'}
              backgroundColor={theme.colors.accent[100]}
              className="qa-submit-password-button"
              color={theme.colors.text['light']}
              type="submit"
            />
          </div>
        </div>
      </fieldset>
    </form>
  );
};

export default UpdatePasswordForm;
