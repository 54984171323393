import React from 'react';
import { styles } from './protected-time-list.styles';
import { Flex, IconButton, Input, Select, Text } from '@chakra-ui/react';
import { ReactComponent as DeleteIcon } from '../../assets/images/deleteV2.svg';

type ProtectedTimeListProps = {
  dayEditDisabled: {
    [key: string]: boolean;
  };
  protectedTimeDayDict: { [id: string]: CMxAPI.ProtectedTime };
  handleUpdateProtectedTime: (
    protectedTime: CMxAPI.ProtectedTime,
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => void;
  handleDeleteProtectedTime: (protectedTime: CMxAPI.ProtectedTime) => void;
  setProtectedTimeDayDict: React.Dispatch<
    React.SetStateAction<{
      [id: string]: CMxAPI.ProtectedTime;
    }>
  >;
  statusList: CMxAPI.RowData;
};

const ProtectedTimeList: React.FC<ProtectedTimeListProps> = props => {
  const {
    dayEditDisabled,
    protectedTimeDayDict,
    handleUpdateProtectedTime,
    handleDeleteProtectedTime,
    setProtectedTimeDayDict,
    statusList
  } = props;
  const classes = styles();

  const checkProtectedTimeOverlap = (
    protectedTimeEntry: CMxAPI.ProtectedTime
  ) => {
    // check if startTime + duration = endTime falls after startTime of any other event
    const endTime = new Date(protectedTimeEntry.startTime);
    endTime.setTime(
      endTime.getTime() + protectedTimeEntry.duration * 60 * 60 * 1000
    );

    return Object.values(protectedTimeDayDict).some(
      protectedTime =>
        new Date(protectedTimeEntry.startTime) <
          new Date(protectedTime.startTime) &&
        new Date(protectedTime.startTime) < endTime
    );
  };

  const updateDurationProtectedTimeEntry = (
    protectedTime: CMxAPI.ProtectedTime,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { target } = event;

    const updatedProtectedTimeEntry: CMxAPI.ProtectedTime = {
      ...protectedTime
    };

    if (Number.isNaN(target.value)) {
      updatedProtectedTimeEntry.duration = 0;
    } else {
      updatedProtectedTimeEntry.duration = +target.value;
    }

    setProtectedTimeDayDict({
      ...protectedTimeDayDict,
      [protectedTime.workLogId]: updatedProtectedTimeEntry
    });
  };

  return (
    <Flex direction="column">
      {Object.keys(protectedTimeDayDict).map(protectedTimeKey => {
        return (
          <Flex direction="column">
            <Flex direction="row" sx={classes['.protectedTimeRowContainer']}>
              <Flex sx={classes['.startTimePast']}>
                <Input
                  type={'time'}
                  id="startTime"
                  disabled={
                    dayEditDisabled[
                      protectedTimeDayDict[protectedTimeKey].day.toLowerCase()
                    ]
                  }
                  value={new Date(
                    protectedTimeDayDict[protectedTimeKey].startTime
                  ).toLocaleTimeString([], {
                    hour12: false,
                    hour: '2-digit',
                    minute: '2-digit'
                  })}
                  onChange={e =>
                    handleUpdateProtectedTime(
                      protectedTimeDayDict[protectedTimeKey],
                      e
                    )
                  }></Input>
              </Flex>
              <Flex sx={classes['.reasonPastContainer']}>
                <Select
                  id="reason"
                  disabled={
                    dayEditDisabled[
                      protectedTimeDayDict[protectedTimeKey].day.toLowerCase()
                    ]
                  }
                  onChange={e =>
                    handleUpdateProtectedTime(
                      protectedTimeDayDict[protectedTimeKey],
                      e
                    )
                  }
                  sx={classes['.reasonPastSelect']}
                  value={protectedTimeDayDict[protectedTimeKey].reason}>
                  {statusList.rowValues.map(status => {
                    return (
                      <option id="statusReason" value={status.Reasons}>
                        {status.Reasons}
                      </option>
                    );
                  })}
                </Select>
              </Flex>
              <Flex sx={classes['.durationPast']}>
                <Input
                  type={'number'}
                  disabled={
                    dayEditDisabled[
                      protectedTimeDayDict[protectedTimeKey].day.toLowerCase()
                    ]
                  }
                  id="duration"
                  value={protectedTimeDayDict[protectedTimeKey].duration ?? 0}
                  onBlur={e => {
                    handleUpdateProtectedTime(
                      protectedTimeDayDict[protectedTimeKey],
                      e
                    );
                  }}
                  onChange={e =>
                    updateDurationProtectedTimeEntry(
                      protectedTimeDayDict[protectedTimeKey],
                      e
                    )
                  }></Input>
              </Flex>
              <IconButton
                variant="outline"
                disabled={
                  dayEditDisabled[
                    protectedTimeDayDict[protectedTimeKey].day.toLowerCase()
                  ]
                }
                aria-label="delete-icon"
                sx={classes['.deleteButton']}
                icon={<DeleteIcon />}
                onClick={() =>
                  handleDeleteProtectedTime(
                    protectedTimeDayDict[protectedTimeKey]
                  )
                }></IconButton>
            </Flex>
            {checkProtectedTimeOverlap(
              protectedTimeDayDict[protectedTimeKey]
            ) ? (
              <Flex sx={classes['.durationOverlapWarning']}>
                <Text sx={classes['.durationOverlapWarningText']}>
                  It looks like this block of time overlaps another one.
                </Text>
              </Flex>
            ) : (
              ''
            )}
          </Flex>
        );
      })}
    </Flex>
  );
};

export { ProtectedTimeList };
