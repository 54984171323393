import theme from '../../theme';
import { CSSProperties } from 'react';

export const styles = (): Record<string, CSSProperties> => ({
  checkboxWrapper: {
    display: 'flex',
    height: theme.space[32],
    paddingLeft: theme.space[12],
    cursor: 'pointer'
  }
});
