/// <reference path="../types.d.ts" />

import { cmxDateTime, HttpMethods } from '@codametrix/ui-common';
import { api } from '../core/net';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { MyStatus } from './action-types';

const actionCreator = actionCreatorFactory();
const createAsync = asyncFactory<CMx.MyStatusState>(actionCreator);

const toggleIsOpenMyStatus = actionCreator<boolean>(
  MyStatus.TOGGLE_IS_OPEN_MY_STATUS
);

const updateStatus = createAsync<
  {
    status: string;
    user: CMxAPI.User;
    context: CMx.Context;
  },
  void,
  CMxCommonApp.SubmitError
>(MyStatus.UPDATE_STATUS, async (payload, dispatch) => {
  const { status, user, context } = payload;

  const endpoint = `/api/coder-activity/work-log/protected/v1`;

  const userId: string = user.userId;
  const fullName = user.firstName + ' ' + user.lastName;
  const tenantId = context.activeContext?.tenantId;
  const dateToday = new Date();

  const body = {
    userUid: userId,
    tenantId: tenantId,
    name: fullName,
    statusChangeTime: dateToday.toISOString().split('.')[0] + 'Z',
    reason: status
  };

  const newStatus = await api<CMxAPI.ProtectedTime[]>({
    endpoint: endpoint,
    init: {
      method: HttpMethods.POST
    },
    body: body
  });

  return newStatus;
});

const listStatus = createAsync<
  { activeTenantId: string },
  CMxAPI.RowData,
  CMxCommonApp.SubmitError
>(MyStatus.LIST_STATUS, async (params, dispatch: any) => {
  const { activeTenantId } = params;

  const dictionaryInfo: { dictionaryName: string; columnName: string } = {
    dictionaryName: 'Protected Time Reasons',
    columnName: 'Reasons, Color'
  };

  const searchParams = new URLSearchParams({
    tenantId: activeTenantId,
    derivedIndicator: 'true',
    effectiveDate: cmxDateTime.format(new Date(), cmxDateTime.FORMATS.DATE),
    dictionaryName: dictionaryInfo.dictionaryName,
    columnName: dictionaryInfo.columnName
  });

  let status;
  status = await api<CMxAPI.RowData>({
    endpoint: `/dictionary/row/unrestricted/v2?${searchParams.toString()}`,
    init: {
      method: HttpMethods.GET
    }
  });

  return status;
});

export { toggleIsOpenMyStatus, updateStatus, listStatus };
