export const PasswordResetDTO = {
  password: '',
  confirmPassword: '',
  id: -1,
  token: '',
  userId: '',
  firstName: '',
  lastName: '',
  expiry: -1,
  username: ''
};
