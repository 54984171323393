import theme from '../../../theme';

export const styles = () => ({
  legend: {
    marginLeft: theme.space[12],
    padding: `0 ${theme.space[8]}`
  },
  fieldset: {
    border: `${theme.borders['1px']} ${theme.colors.foundation[200]}`,
    borderRadius: theme.radii[3],
    marginBottom: theme.space[16]
  },
  fieldsets: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.radii[3],
    border: `${theme.borders['1px']} ${theme.colors.foundation[200]}`,
    height: 'auto',
    overflowY: 'auto',
    marginTop: theme.space[20],
    padding: `${theme.space[16]} ${theme.space[20]} 0 ${theme.space[20]}`
  },
  contentSpacing: {
    padding: `${theme.space[4]} ${theme.space[16]} ${theme.space[16]} ${theme.space[16]}`
  },
  fileName: {
    paddingLeft: '10px'
  }
});
