import { Middleware } from 'redux';
import { Analytics } from '../../actions/action-types';
import { MiddlewareProcessor, middleWareWrapper } from './middleware-utils';
import { cmxTypeguards } from '@codametrix/ui-common';

let fns = new Map();

const importScript = (resourceUrl: string, id: string) => {
  if (document.getElementById(id)) {
    return fns.get(id);
  } else {
    const script = document.createElement('script');
    script.src = resourceUrl;
    script.async = false;
    script.id = id;
    script.type = 'module';
    document.body.appendChild(script);

    const removerFn = () => {
      const scriptTag = document.getElementById(id);
      if (scriptTag) {
        document.body.removeChild(scriptTag);
      }
    };
    fns.set(id, removerFn);
    return removerFn;
  }
};

/**
 * Middleware processor that imports the Tableau embedding API script when the `Analytics.GET_TABLEAU_TOKEN_STARTED` action is dispatched.
 *
 * @param api The Redux store API.
 * @param next The next middleware function in the chain.
 * @param action The action being processed.
 * @returns The result of calling the next middleware function.
 */
const tableauImportProcessor: MiddlewareProcessor = (api, next, action) => {
  if (
    cmxTypeguards.isFSA(action) &&
    action.type === Analytics.GET_TABLEAU_TOKEN + '_STARTED'
  ) {
    importScript(
      'https://prod-useast-b.online.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js',
      'tableau-embed-lib'
    );
  }

  return next(action);
};

const importTableauMiddleware: Middleware = middleWareWrapper(
  tableauImportProcessor
);

export { importScript, importTableauMiddleware };
