import React, { useState, SyntheticEvent } from 'react';
import './filter-modal.scss';
import { RoundedButton, CheckList } from '@codametrix/ui-components';
import { Divider } from '../divider/divider';
import { commonEnums as enums } from '@codametrix/ui-common';
import { LoadingSlugs } from '@codametrix/ui-components';
const { FilterKeys } = enums;

const FilterModal: React.FC<AppProps.FilterModalProps> = (
  props: AppProps.FilterModalProps
) => {
  const {
    criterialFilter,
    sortablePageable,
    cancel,
    getTenants,
    activeOrgId,
    list,
    loadState
  } = props;

  const extraFilters = criterialFilter.filterForms.filter(
    filter => filter.isExtra
  );
  const defaultAppliedFilters = criterialFilter.filters.filter(filter => {
    return extraFilters.find(extraFilter => {
      return extraFilter.key === filter.key;
    });
  });

  const [activeFilter, changeActiveFilter] = useState(extraFilters[0]);
  const [appliedFilters, changeAppliedFilters] = useState<
    CMxCommonApp.Filter[]
  >(defaultAppliedFilters);

  React.useEffect(() => {
    switch (activeFilter.key) {
      case 'tenant_code':
        getTenants(activeOrgId);
    }
  }, [activeFilter.key, getTenants, activeOrgId]);

  const submitFilters = () => {
    var currFilters = sortablePageable.filterableOptions.filters
      ?.filter((filter: CMxCommonApp.Filter) => {
        return (
          filter.type !== 'GREATER_THAN_EQUAL' &&
          filter.type !== 'LESS_THAN_EQUAL'
        );
      })
      .filter((filter: CMxCommonApp.Filter) => {
        return extraFilters.find(extraFilter => {
          return filter.key !== extraFilter.key;
        });
      });

    var listOptions = sortablePageable;
    if (currFilters?.length && appliedFilters !== undefined) {
      listOptions.filterableOptions.filters = [
        ...currFilters,
        ...appliedFilters
      ];
    } else {
      listOptions.filterableOptions.filters = [...appliedFilters];
    }

    listOptions.filterableOptions.dateFilter =
      sortablePageable.filterableOptions.dateFilter;
    listOptions.pageableDefinition.pageable.pageNumber = 0;
    listOptions.pageableDefinition.number = 0;
    listOptions.pageableDefinition.first = true;
    listOptions.pageableDefinition.last = false;
    listOptions.pageableDefinition.pageable.offset = 0;

    list(listOptions);
    cancel();
  };

  const filterSelectHandler = (e: SyntheticEvent) => {
    const target = e.target;
    const key = (target as HTMLSpanElement).getAttribute('id');
    const filter = extraFilters.find(filter => {
      return filter.key === key;
    });

    switch (key) {
      case FilterKeys.TENANT:
        getTenants(activeOrgId);
    }

    if (filter) {
      changeActiveFilter(filter);
    }
  };

  const recursiveGetProperty = (
    field: CMxCommonApp.FieldDefinition,
    property: string
  ) => {
    if (field.children !== undefined) {
      let res: string[] = [];

      field.children.forEach((child: CMxCommonApp.FieldDefinition) => {
        res.push(...recursiveGetProperty(child, property));
      });

      return [field[property], ...res];
    }

    return [field.key];
  };

  const flattenFieldDefintitons = (field: CMxCommonApp.FieldDefinition) => {
    if (field.children !== undefined) {
      let res: CMxCommonApp.FieldDefinition[] = [];

      field.children.forEach((child: CMxCommonApp.FieldDefinition) => {
        res.push(...flattenFieldDefintitons(child));
      });

      return [field, ...res];
    }

    return [field];
  };

  const filterChangeHandler = (e: CustomEvent) => {
    var fieldList = e.detail.value.checkboxes;

    let terms: string[] = [];
    Object.keys(fieldList).forEach(key => {
      if (fieldList[key] === true) {
        terms.push(key);
      }
    });

    const unchangedFilters =
      appliedFilters?.filter(filter => {
        return filter.key !== activeFilter.key;
      }) || [];

    if (terms.length > 0) {
      changeAppliedFilters([
        ...unchangedFilters,
        { key: activeFilter.key, terms, type: 'IN' }
      ]);
    } else {
      changeAppliedFilters([...unchangedFilters]);
    }
  };

  const filterSelectionList = extraFilters.map(filter => {
    let isActive = false;
    if (filter.key === activeFilter.key) {
      isActive = true;
    }

    return (
      <div
        className={
          'qa-selectable-filter-container selectable-filter-container' +
          (isActive ? ' active' : '')
        }
        id={filter.key}
        onClick={e => filterSelectHandler(e)}>
        {isActive ? (
          <span className={'filter-spacer'}>
            <Divider isVertical={true} />
          </span>
        ) : (
          <span className={'filter-spacer'} />
        )}

        <div className={'selectable-filter'}>{filter.name}</div>
      </div>
    );
  });

  const defaults: { [key: string]: boolean } = {};
  if (activeFilter.formDefinition.fieldGroups?.length) {
    const match = appliedFilters?.find(filter => {
      return filter.key === activeFilter.key;
    })?.terms;

    activeFilter.formDefinition.fieldGroups.forEach(group => {
      group.fields.forEach(field => {
        const keysOfChildren = recursiveGetProperty(field, 'key');
        keysOfChildren.forEach(key => {
          if (match?.includes(key)) {
            defaults[key] = true;
          } else {
            defaults[key] = false;
          }
        });
      });
    });
  }

  var CurrentSelection = loadState.loading ? (
    <LoadingSlugs numberItems={5} />
  ) : (
    <CheckList
      FieldDefinitions={
        activeFilter?.formDefinition?.fieldGroups
          ? activeFilter?.formDefinition?.fieldGroups[0].fields
          : []
      }
      value={defaults}
      onChange={e => filterChangeHandler(e)}
    />
  );

  const AppliedFilterStyled = appliedFilters?.map((filter, index) => {
    var criteria = null;

    const match = criterialFilter.filterForms.find(match => {
      return match.key === filter.key;
    });

    let matchedFields: CMxCommonApp.FieldDefinition[] = [];
    if (match?.formDefinition.fieldGroups) {
      matchedFields = match?.formDefinition?.fieldGroups[0].fields;
    }

    let fieldDefintionsFlattened: CMxCommonApp.FieldDefinition[] = [];

    matchedFields.forEach(matchedField => {
      fieldDefintionsFlattened.push(...flattenFieldDefintitons(matchedField));
    });

    if (filter.terms.length >= fieldDefintionsFlattened.length) {
      criteria = 'All Selected';
    } else if (filter.terms.length > 1 || match === undefined) {
      criteria = filter.terms.length + ' selected';
    } else {
      criteria = filter.terms.map(term => {
        return fieldDefintionsFlattened.find(filter => {
          return term === filter.key;
        })?.label;
      });
    }

    return (
      <span>
        <span
          className="navigation-link qa-navigation-link"
          id={match?.key}
          onClick={e => {
            filterSelectHandler(e);
          }}>
          {match?.name}

          {':  '}
          <span className="criteria-text qa-criteria-text">{criteria}</span>
        </span>

        {index + 1 !== appliedFilters.length ? ' | ' : ''}
      </span>
    );
  });

  return (
    <div className="modal-container filter-modal-container qa-filter-modal-container">
      <div className="columns">
        <div className="column col-12">
          <div className="filter-container-applied">
            {'SELECTED FILTERS:  '}
            {AppliedFilterStyled}
          </div>
        </div>
        <div className="column col-4">
          <div className="filter-container-select">{filterSelectionList}</div>
        </div>
        <div className="column col-8">
          <div className="filter-container-toggle">{CurrentSelection}</div>
        </div>
      </div>
      <div className="extra-filter-divider">
        <Divider />
      </div>
      <div className="buttons-container">
        <RoundedButton
          type="submit"
          disabled={false}
          onClick={() => {
            cancel();
          }}
          className="btn-secondary">
          Cancel
        </RoundedButton>
        <RoundedButton
          type="submit"
          disabled={
            appliedFilters.length || defaultAppliedFilters.length ? false : true
          }
          onClick={() => {
            submitFilters();
          }}
          className="btn-primary submit-filter-btn qa-submit-filter">
          Apply Filters
        </RoundedButton>
      </div>
    </div>
  );
};

export default FilterModal;
