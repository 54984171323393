import { Box } from '@chakra-ui/react';
import { DataGrid } from '../../components/data-grid/data-grid';
import React, { useEffect, useState } from 'react';
import { SubHeader } from '../organizations/subheader';
import { styles } from './dictionary-styles';
import {
  dictionaryListcolDefs,
  getEndpointDataByTenantId,
  gridOptions
} from './dictionary-list-constants';
import { defaultDictionary } from '../../tests/fixtures/stubs/dictionary';

export const DictionaryList: React.FC<AppProps.DictionaryListProps> = props => {
  const classes = styles();
  const {
    navigate,
    onSelection,
    viewOnly,
    sortablePageable,
    selectedOrg,
    resetStateToDefault
  } = props;

  const [pageSize, setPageSize] = useState<number>(25);

  useEffect(() => {
    const defaultData: any = {
      selectedDictionary: defaultDictionary,
      selectedRowIndex: -1
    };
    resetStateToDefault && resetStateToDefault(defaultData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddDictionary = () => {
    if (navigate) {
      const path = window.location.pathname;
      navigate(path + `/dictionary/upload/new`);
    }
  };

  const handleSelection = (item: CMxAPI.Dictionary) => {
    const path = window.location.pathname;
    onSelection({
      data: item,
      path: path + `/dictionary/${item.name}`
    });
  };

  const onPageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
  };

  return (
    <Box>
      <SubHeader
        title={'Dictionaries'}
        className={classes.text}
        actionName={viewOnly ? '' : 'Create Dictionary'}
        onActionClick={handleAddDictionary}
        pageSize={pageSize}
        onPageSizeChange={onPageSizeChange}
      />
      <Box paddingTop={5}>
        <DataGrid
          columnDefs={dictionaryListcolDefs}
          gridOptions={gridOptions}
          endpointData={getEndpointDataByTenantId(selectedOrg.key)}
          serverSideInfiniteScroll
          filterableOptions={sortablePageable.filterableOptions}
          onRowClicked={item => {
            handleSelection(item?.data);
          }}
          paginationPageSize={pageSize}
        />
      </Box>
    </Box>
  );
};
