import React from 'react';
import './loader-overlay.scss';
import theme from '../../theme';
import { Grid, GridItem, Text } from '@chakra-ui/react';
import spinner from '../../assets/images/spinner_cmx.svg';

export type LoadingOverlayProps = {
  loadingMsg?: string;
};

const LoadingOverlay: React.FC<LoadingOverlayProps> = props => {
  const { loadingMsg } = props;
  return (
    <Grid display="table" justifyContent="center" className="loader-wrapper">
      <Grid gridGap={0} className="loading-indicator">
        <GridItem className="loading-indicator-inner">
          <object
            data={spinner}
            aria-label="in progress"
            className="loading-indicator-graphic"
          />
        </GridItem>
        {loadingMsg && (
          <GridItem className="loading-indicator-inner">
            <Text
              color={theme.colors.text['highEmphasis']}
              textStyle={theme.textStyles['h1']}
              className="text">
              {loadingMsg}
            </Text>
          </GridItem>
        )}
      </Grid>
    </Grid>
  );
};
export default LoadingOverlay;
