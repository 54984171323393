/// <reference path="../types.d.ts" />

import InitialState from '../reducers/initial-state';
import { cmxReducerFactory } from './reducer-utils';
import * as CapturedTimeActions from '../actions/captured-time';
import { LOAD_STATES } from './common';

const reducer = cmxReducerFactory(InitialState.capturedTime)
  .case(
    CapturedTimeActions.toggleIsOpenCapturedTime,
    (state, capturedTimeIsOpen) => {
      return {
        ...state,
        capturedTimeIsOpen
      };
    }
  )
  .case(CapturedTimeActions.listCapturedTimeForWeek.async.started, state => {
    return {
      ...state,
      loadState: LOAD_STATES.started,
      addTimeFailed: false
    };
  })
  .case(CapturedTimeActions.listCapturedTimeForWeek.async.failed, state => {
    return {
      ...state,
      loadState: LOAD_STATES.failed
    };
  })
  .case(
    CapturedTimeActions.listCapturedTimeForWeek.async.done,
    (state, result) => {
      let newCapturedTimeWeek;
      let newNoPastCapturedTimeFlag = state.noPastCapturedTimeFlag;
      if (result.result.content.length === 0) {
        newCapturedTimeWeek = state.capturedTimeWeek;
        newNoPastCapturedTimeFlag = true;
      } else {
        newCapturedTimeWeek = result.result.content;
        newNoPastCapturedTimeFlag = false;
      }
      return {
        ...state,
        capturedTimeWeek: newCapturedTimeWeek,
        loadState: LOAD_STATES.done,
        noPastCapturedTimeFlag: newNoPastCapturedTimeFlag
      };
    }
  )
  .case(CapturedTimeActions.listProtectedTimeForWeek.async.started, state => {
    return {
      ...state,
      loadState: LOAD_STATES.started
    };
  })
  .case(CapturedTimeActions.listProtectedTimeForWeek.async.failed, state => {
    return {
      ...state,
      loadState: LOAD_STATES.failed
    };
  })
  .case(
    CapturedTimeActions.listProtectedTimeForWeek.async.done,
    (state, result) => {
      const newProtectedTimeWeek = state.noPastCapturedTimeFlag
        ? state.protectedTimeWeek
        : result.result.content;
      return {
        ...state,
        protectedTimeWeek: newProtectedTimeWeek,
        loadState: LOAD_STATES.done,
        needRefreshFlag: false
      };
    }
  )
  .case(CapturedTimeActions.deleteProtectedTimeEntry.async.started, state => {
    return {
      ...state,
      loadState: LOAD_STATES.started
    };
  })
  .case(CapturedTimeActions.deleteProtectedTimeEntry.async.failed, state => {
    return {
      ...state,
      loadState: LOAD_STATES.failed
    };
  })
  .case(CapturedTimeActions.deleteProtectedTimeEntry.async.done, state => {
    return {
      ...state,
      loadState: LOAD_STATES.done,
      needRefreshFlag: true
    };
  })
  .case(
    CapturedTimeActions.updateProtectedTimeEntry.async.started,
    (state, result) => {
      return {
        ...state,
        loadState: LOAD_STATES.started
      };
    }
  )
  .case(
    CapturedTimeActions.updateProtectedTimeEntry.async.failed,
    (state, result) => {
      return {
        ...state,
        loadState: LOAD_STATES.failed
      };
    }
  )
  .case(
    CapturedTimeActions.updateProtectedTimeEntry.async.done,
    (state, result) => {
      return {
        ...state,
        loadState: LOAD_STATES.done,
        needRefreshFlag: true
      };
    }
  )
  .case(
    CapturedTimeActions.addProtectedTimeEntry.async.started,
    (state, result) => {
      return {
        ...state,
        loadState: LOAD_STATES.started,
        addTimeFailed: false
      };
    }
  )
  .case(
    CapturedTimeActions.addProtectedTimeEntry.async.failed,
    (state, result) => {
      return {
        ...state,
        addTimeFailed: true,
        loadState: LOAD_STATES.failed
      };
    }
  )
  .case(
    CapturedTimeActions.addProtectedTimeEntry.async.done,
    (state, result) => {
      return {
        ...state,
        loadState: LOAD_STATES.done,
        needRefreshFlag: true,
        addTimeFailed: false
      };
    }
  )
  .case(
    CapturedTimeActions.getMaxAllowedBackdate.async.started,
    (state, result) => {
      return {
        ...state,
        loadState: LOAD_STATES.started
      };
    }
  )
  .case(
    CapturedTimeActions.getMaxAllowedBackdate.async.failed,
    (state, result) => {
      return {
        ...state,
        loadState: LOAD_STATES.failed
      };
    }
  )
  .case(
    CapturedTimeActions.getMaxAllowedBackdate.async.done,
    (state, result) => {
      return {
        ...state,
        loadState: LOAD_STATES.done,
        maxAllowedBackdate: result.result[0].value
      };
    }
  );

export default reducer;
