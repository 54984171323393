import theme from '../../theme';

export const styles = {
  lengthIndicator: {
    color: theme.colors.text['lowEmphasis']
  },
  textarea: {
    flexDirection: 'column',
    gap: theme.space[8],
    width: '100%'
  }
};
