import { commonEnums } from '@codametrix/ui-common';
const { CustomerPhase } = commonEnums;

const goLiveDate: CMxCommonApp.FieldDefinition = {
  label: `Go Live Date`,
  key: `go_live_date`,
  helpText: 'go_live_date',
  required: false,
  type: 'date'
};

const maxCaseAge: CMxCommonApp.FieldDefinition = {
  label: 'Max Case Age',
  key: 'max_age_in_days',
  helpText: 'max_age_in_days',
  required: false,
  type: 'text'
};

const customerPhaseOptions = [
  {
    label: CustomerPhase.ACTIVE,
    value: CustomerPhase.ACTIVE
  },
  {
    label: CustomerPhase.TESTING,
    value: CustomerPhase.TESTING
  },
  {
    label: CustomerPhase.LIVE,
    value: CustomerPhase.LIVE
  },
  {
    label: CustomerPhase.PARALLEL,
    value: CustomerPhase.PARALLEL
  }
];
const customerPhase: CMxCommonApp.FieldDefinition = {
  label: 'Customer Phase',
  key: 'phase',
  name: 'phase',
  required: false,
  type: 'select',
  options: customerPhaseOptions,
  labelField: 'label',
  valueField: 'value',
  helpText: 'phase'
};

const endDate: CMxCommonApp.FieldDefinition = {
  label: `End Date`,
  key: `end_date`,
  helpText: `end_date`,
  required: false,
  type: 'date'
};

const generalFields = [goLiveDate, maxCaseAge, customerPhase, endDate];

export default generalFields;
