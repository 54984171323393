import { userPaths } from '../../reducers/state/users';
import { dictionaryPaths } from '../../reducers/state/dictionary';
import { orgPath } from '../../reducers/state/organization';
import { externalAppPaths } from '../../reducers/state/external-application';
import { caseConfigPaths } from '../../reducers/state/case-config/case-config';
import { rulesPaths } from '../../reducers/state/rules-list';
import { auditLogListPath } from '../../reducers/state/audit-log';
const paths = [
  ...userPaths,
  orgPath,
  ...dictionaryPaths,
  ...externalAppPaths,
  ...caseConfigPaths,
  ...rulesPaths,
  auditLogListPath
];
export default paths;
