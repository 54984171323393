import React from 'react';

import { buildDispatchable } from '../../actions/_action-utilities';
import { getDetail, addUser, list, toggleFilterOn } from '../../actions/users';
import { CMxComponents } from '@codametrix/ui-components/src/types';
import { ColDef, GridOptions } from 'ag-grid-community';
import theme from '../../theme';
import {
  EndpointData,
  HTTPMethodType
} from '../../components/data-grid/data-grid-utils';
export const mapStateToProps = function (state: any) {
  const { users, ui } = state;

  let adminTabs: CMxComponents.TabDef[] = ui.actionGroups
    .find((actionGroup: any) => {
      return actionGroup.key === 'admin';
    })
    ?.options[0].subActions.filter((tab: CMxComponents.TabDef) => {
      return ui.context.activeContext.resources.find((resource: any) => {
        return resource.resourceName === tab.resourceId;
      });
    });

  return {
    ...users,
    rootContext: ui.context.activeContext,
    auth: state.login.auth,
    showHeader: !(adminTabs?.length > 1),
    activeUser: ui.activeUser
  };
};

export const mapDispatchToProps = (dispatch: any) => {
  const runDispatchable = buildDispatchable(dispatch);

  return {
    addUser: (
      token: CMxCommonApp.BearerToken,
      orgContext: CMxAPI.OrganizationContext
    ) => {
      runDispatchable(() => {
        return dispatch(addUser({ token, orgContext }));
      });
    },
    getList: (sp: CMxCommonApp.SortablePageable<CMxAPI.User>) => {
      runDispatchable(() => {
        //@ts-ignore
        return dispatch(list(sp));
      });
    },
    getDetail: (
      token: CMxCommonApp.BearerToken,
      user: CMxAPI.User,
      orgContext: CMxAPI.OrganizationContext
    ) => {
      runDispatchable(() => {
        return dispatch(getDetail({ token, user, orgContext }));
      });
    },
    toggleFilterOn: (filterOn: boolean) => {
      runDispatchable(() => {
        return dispatch(toggleFilterOn(filterOn));
      });
    }
  };
};

export const userListEndpoint = `/users/list/v1`;

export const endPoint: EndpointData = {
  endPoint: userListEndpoint,
  HTTPMethodType: HTTPMethodType.POST
};

export const generateOrganizationsList = (user: CMxAPI.User) => {
  let orgName: string[] = [];
  user?.roles.forEach((role, index) => {
    orgName.push(role.organizationName);
  });
  return orgName.join(',');
};

export const userListColumnDefs: ColDef[] = [
  {
    headerName: 'Name',
    field: 'firstName',
    cellStyle: { color: theme.colors.accent[200] },
    sortable: true,
    cellRenderer: (params: any) => {
      const name = params.data?.lastName + ', ' + params.data?.firstName + ' ';
      return params.data?.locked ? (
        <div>
          {name}
          <i className="fas fa-lock lock-color"></i>
        </div>
      ) : (
        name
      );
    }
  },
  { headerName: 'Username', field: 'username', sortable: true },
  {
    headerName: 'Organizations',
    field: 'organizationName',
    sortable: false,
    valueGetter: (params) => {
      return generateOrganizationsList(params.data);
    }
  }
];

export const gridOptions: GridOptions = {
  suppressHorizontalScroll: true
};
