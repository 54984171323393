/// <reference path="../types.d.ts" />

import InitialState from '../reducers/initial-state';
import { cmxReducerFactory } from './reducer-utils';
import * as MyStatusActions from '../actions/my-status';
import { LOAD_STATES } from './common';

const reducer = cmxReducerFactory(InitialState.myStatus)
  .case(MyStatusActions.toggleIsOpenMyStatus, (state, myStatusIsOpen) => {
    return {
      ...state,
      myStatusIsOpen
    };
  })
  .case(MyStatusActions.updateStatus.async.started, (state, newStatus) => {
    return {
      ...state,
      newStatus
    };
  })
  .case(MyStatusActions.updateStatus.async.failed, (state, newStatus) => {
    return {
      ...state,
      newStatus
    };
  })
  .case(MyStatusActions.updateStatus.async.done, (state, newStatus) => {
    return {
      ...state,
      newStatus
    };
  })
  .case(MyStatusActions.listStatus.async.started, state => {
    return {
      ...state,
      loadState: LOAD_STATES.started
    };
  })
  .case(MyStatusActions.listStatus.async.failed, state => {
    return {
      ...state,
      loadState: LOAD_STATES.failed
    };
  })
  .case(MyStatusActions.listStatus.async.done, (state, { result }) => {
    return {
      ...state,
      statusList: result,
      loadState: LOAD_STATES.done
    };
  });

export default reducer;
