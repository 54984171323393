import React, { useState } from 'react';
import {
  groupColDefs,
  gridOptions,
  getEndpointDataByTenantId
} from './groups-constants';
import { styles } from './groups-styles';
import { BreadCrumbsComponent } from '../../components/breadcrumbs/breadcrumbsComponent';

import { Box } from '@chakra-ui/react';
import { SubHeader } from '../organizations/subheader';
import { DataGrid } from '../../components/data-grid/data-grid';

const Groups: React.FC<AppProps.GroupsListProps> = props => {
  const classes = styles();
  const {
    navigate,
    onSelection,
    viewOnly,
    selectedOrg,
    sortablePageable
  } = props;

  const [pageSize, setPageSize] = useState<number>(25);

  const handleAddGroup = () => {
    if (navigate) {
      const path = window.location.pathname;
      navigate(path + `/add`);
    }
  };

  const handleSelection = (item: CMxAPI.Group) => {
    const path = window.location.pathname;
    onSelection({
      data: item,
      path: path + `/group/${item.id}`
    });
  };

  const onPageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
  };

  return (
    <div>
      <BreadCrumbsComponent />
      <Box>
        <SubHeader
          title={'Groups'}
          className={classes.text}
          actionName={viewOnly ? '' : 'Add Group'}
          onActionClick={handleAddGroup}
          pageSize={pageSize}
          onPageSizeChange={onPageSizeChange}
        />
        <Box paddingTop={5}>
          <DataGrid
            columnDefs={groupColDefs}
            gridOptions={gridOptions}
            endpointData={getEndpointDataByTenantId(selectedOrg.tenantId)}
            serverSideInfiniteScroll
            filterableOptions={sortablePageable.filterableOptions}
            onRowClicked={item => {
              handleSelection(item?.data);
            }}
            paginationPageSize={pageSize}
          />
        </Box>
      </Box>
    </div>
  );
};

Groups.displayName = 'Groups';
export { Groups };
