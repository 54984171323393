import InitialState from './../reducers/initial-state';
import { cmxReducerFactory } from './reducer-utils';
import { cancelEula, saveEula } from '../actions/eula';
const reducer = cmxReducerFactory(InitialState.eula)
  .case(saveEula, (state, action) => {
    return { ...state, eula: action };
  })
  .case(cancelEula, (state, action) => {
    return { ...state, isCancelled: action };
  });

export default reducer;
