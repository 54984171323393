import { CSSProperties } from 'react';
import theme from '../../theme';

export const styles = (): Record<string, CSSProperties> => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: theme.space[16],
    marginBottom: theme.space[20]
  },
  icon: {
    cursor: 'pointer'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    width: '80%',
    justifyContent: 'space-between'
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    columnGap: theme.space[12]
  },
  input: {
    height: theme.space[24],
    border: 'none',
    color: theme.colors.text['highEmphasis'],
    ...theme.textStyles['Caption 2'],
    paddingLeft: theme.space[12],
    caretColor: 'transparent',
    cursor: 'pointer'
  },
  selectedInput: {
    height: theme.space[24],
    border: 'none',
    color: theme.colors.text['highEmphasis'],
    ...theme.textStyles['Caption 2'],
    paddingLeft: theme.space[12],
    caretColor: 'transparent',
    cursor: 'pointer',
    backgroundColor: '#F0FBFF'
  },
  clearBtn: {
    fontSize: theme.space[8],
    marginLeft: theme.space[16],
    height: theme.space[12],
    width: theme.space[24]
  },
  disabledBtn: {
    fontSize: theme.space[8],
    marginLeft: theme.space[16],
    height: theme.space[12],
    width: theme.space[24],
    color: theme.colors.text['lowEmphasis'],
    pointerEvents: 'none'
  },
  defaultText: {
    ...theme.textStyles['Caption 2'],
    color: theme.colors.text['highEmphasis']
  },
  formLabel: { marginBottom: 0 },
  leftIcon: { height: theme.space[24], marginBottom: '1px' },
  inputPlaceholder: {
    display: 'flex',
    gap: theme.space[8],
    height: theme.space[24],
    alignItems: 'center',
    cursor: 'pointer'
  },
  placeholderCalendar: {
    marginBottom: theme.space[2]
  }
});
