import theme from '../../theme';

export const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%'
    // marginTop: spacing('8')
  },
  tabs: {
    borderRight: `${theme.borders['1px']} ${theme.colors.foundation[100]}`
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
    // margin: spacing('10', '0', '10', '20')
    // marginLeft: spacing('20')
  },
  name: {
    display: 'flex',
    alignItems: 'center'
  },
  legend: {
    marginLeft: theme.space[12],
    padding: `0 ${theme.space[8]}`
  },
  fieldset: {
    border: `${theme.borders['1px']} ${theme.colors.foundation[200]}`,
    borderRadius: theme.radii[3],
    marginBottom: theme.space[16]
  },
  grid: {
    width: '32%',
    padding:
      theme.space[4] +
      ' ' +
      theme.space[16] +
      ' ' +
      theme.space[16] +
      ' ' +
      theme.space[16]
  },
  fieldsets: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.radii[3],
    border: `${theme.borders['1px']} ${theme.colors.foundation[200]}`,
    height: '100%',
    overflowY: 'auto',
    marginTop: theme.space[20],
    padding: `${theme.space[16]} ${theme.space[20]} 0 ${theme.space[20]}`
  },
  label: {
    color: theme.colors.text['highEmphasis'],
    ...theme.textStyles['Body 2']
  },
  input: {
    marginTop: theme.space[12]
  },
  checkbox: {
    marginTop: theme.space[12]
  },
  text: {
    ...theme.textStyles['h4'],
    color: theme.colors.text['highEmphasis']
  },
  textarea: {
    padding: `${theme.space[4]} ${theme.space[12]}`,
    borderColor: theme.colors.foundation[200],
    borderRadius: theme.radii[3],
    color: theme.colors.foundation[200],
    _focus: {
      borderColor: theme.colors.stroke[400],
      color: theme.colors.text['highEmphasis']
    },
    _hover: {
      borderColor: theme.colors.stroke[300]
    },
    _active: {
      borderColor: theme.colors.stroke[200],
      color: theme.colors.text['highEmphasis']
    },
    marginTop: theme.space[8]
  },
  requiredIndicator: {
    color: theme.colors.success[500]
  },
  button: {
    width: 'auto',
    backgroundColor: theme.colors.accent[100],
    color: theme.colors.text['light'],
    borderRadius: theme.radii[3],
    border: `${theme.borders['1px']} ${theme.colors.accent[100]}`,
    paddingLeft: theme.space[12],
    height: theme.space[40],
    _active: {
      backgroundColor: theme.colors.accent[100],
      color: theme.colors.text['light']
    },
    _focus: {
      boxShadow: 'none'
    },
    _hover: {
      backgroundColor: theme.colors.accent[100],
      color: theme.colors.text['light'],
      boxShadow: 'none'
    },
    '.chakra-button__icon': {
      marginLeft: 0
    }
  },
  icon: {
    color: theme.colors.text['light'],
    fontSize: theme.space[24]
  },
  associationGrid: {
    width: '32%',
    marginTop: theme.space[20]
  },
  subtitle: {
    ...theme.textStyles['Body 2'],
    color: theme.colors.text['highEmphasis']
  },
  select: {
    height: theme.space[40],
    width: '100%',
    textAlign: 'left',
    color: theme.colors.text['highEmphasis'],
    ...theme.textStyles['Body 2'],
    background: theme.colors.text['light'],
    border: `1px solid ${theme.colors.stroke[200]}`,
    backgroundColor: theme.colors.text['light'],
    paddingLeft: theme.space[16],
    maxWidth: theme.space[300],
    overflow: 'hidden',
    minWidth: theme.space[56]
  }
});
