import { push, replace } from 'connected-react-router';

import { buildDispatchable } from '../actions/_action-utilities';
import {
  featureSelection,
  getDetail,
  detailToggleArtifacts,
  setActiveProcess,
  featureActivation,
  importToAmplify,
  setActiveNote
} from '../actions/cases.action';
import { caseEditsActions } from '../actions/case-edits';
import { openWindow } from '../actions/ui';
import { codeBenchActions } from '@codametrix/ui-common';

export const mapStateToProps = function(
  state: CMx.ApplicationState
): CMxCommonApp.CaseDetailData {
  const { cases, ui, caseEdits, capabilities, processInstance } = state;
  return {
    ...cases,
    processInstance,
    capabilities,
    propertyConfig: ui.propertyConfig,
    caseEdits: caseEdits.caseEdits,
    tenantId: ui.context.activeContext?.tenantId,
    hasAmplifyCaseRunV2Access: ui.hasAmplifyCaseRunV2Access
  };
};

export const mapDispatchToProps = (
  dispatch: any
): CMxCommonApp.CaseDetailMethods => {
  const runDispatchable = buildDispatchable(dispatch);

  return {
    onCaseAction: (action: any) => {
      runDispatchable(() => {
        return dispatch(importToAmplify(action));
      });
    },
    openChildWindow: (windowOpenArgs: CMxCommonApp.WindowOpenArguments) => {
      dispatch(openWindow(windowOpenArgs));
    },
    openAmplifyCase: (amplifyLaunchConfig: any) => {
      dispatch(
        push(
          `/cmx/worklist/${amplifyLaunchConfig.serviceLine}/case/${
            amplifyLaunchConfig.caseId
          }/case-run/${amplifyLaunchConfig.id}${
            amplifyLaunchConfig?.hasAmplifyCaseRunV2Access ? '/v2' : ''
          }/`
        )
      );
    },
    toggleArtifacts: (showAll: boolean) => {
      dispatch(detailToggleArtifacts(showAll));
    },
    onNavigate: (
      caseId: string,
      pushAction?: boolean,
      serviceLine?: string
    ) => {
      if (pushAction) {
        dispatch(push(`${caseId}`));
      }
      return dispatch(
        getDetail({
          caseId: caseId,
          serviceLine
        })
      );
    },
    onFeatureActivation: (selection: CMxCommonApp.ChargeSessionSelection) => {
      dispatch(featureActivation(selection));
    },
    onFeatureSelection: (selection: CMxArtifacts.FeatureSelection) => {
      dispatch(featureSelection(selection));
    },
    setActiveProcess: (activeProcessPayload: CMx.ActiveProcessPayload) => {
      dispatch(setActiveProcess(activeProcessPayload));
    },
    showList: (serviceLine: string, refreshList?: boolean) => {
      runDispatchable(() => {
        if (refreshList) {
          return dispatch(replace(`/cmx/cases/${serviceLine.toLowerCase()}/`));
        } else {
          return dispatch(
            replace(`/cmx/cases/${serviceLine.toLowerCase()}/?back=true`)
          );
        }
      });
    },
    updateValue: (updatedFieldAndValue: any) => {
      dispatch(caseEditsActions.updateValue(updatedFieldAndValue));
    },
    loadAllPatientDetailOptions: async (tenantId: string) => {
      await dispatch(caseEditsActions.loadAllPatientDetailOptions(tenantId));
    },
    updateCase: (updatesAndInfo: any) => {
      dispatch(caseEditsActions.updateCase(updatesAndInfo));
    },
    setActiveNote: (activeNote: CMxCommonApp.DecoratedNote) => {
      dispatch(setActiveNote(activeNote));
    },
    hideSideNavBar: () => {
      dispatch(codeBenchActions.hideSideNavBar());
    },
    showCoderNotesInfo: (coderNotesParams: CMxCommonApp.CoderNotesParams) => {
      dispatch(codeBenchActions.navigateCoderNotes(coderNotesParams));
    }
  };
};
