import theme from '../../theme';

export const styles = () => {
  const statusPopover = {
    background: theme.colors.text['light'],
    color: theme.colors.text['highEmphasis'],
    ...theme.textStyles['Body 1'],
    borderColor: theme.colors['stroke']['200'],
    borderRadius: theme.radii[3],
    transform: `translateX(-8px) !important`,
    width: 'max-content',
    maxWidth: theme.space[400],
    maxHeight: theme.space[400],
    marginTop: theme.space[44],
    minHeight: theme.space[152],
    minWidth: theme.space[240]
  };
  const statusCircle = {
    marginLeft: theme.space[4],
    marginRight: theme.space[12],
    marginTop: theme.space[12],
    borderRadius: theme.radii[50],
    height: theme.space[10],
    width: theme.space[10],
    minWidth: theme.space[10]
  };
  const statusRow = {
    marginBottom: theme.space[2],
    marginTop: theme.space[2]
  };
  const searchInput = {
    color: theme.colors.text['highEmphasis'],
    borderColor: theme.colors.text['lowEmphasis'],
    marginTop: theme.space[8]
  };
  const statusHeader = {
    marginTop: theme.space[4],
    maringBottom: theme.space[4],
    borderBottomWidth: 0,
    marginRight: theme.space[8],
    ...theme.textStyles.h4
  };
  const statusButton = {
    ...theme.textStyles['Body 1'],
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    textAlign: 'left'
  };
  const statusPopoverBody = {
    overflowY: 'auto',
    marginRight: theme.space[16]
  };
  const divider = {
    borderTop: `${theme.borders['1px']} ${theme.colors.text['lowEmphasis']}`,
    marginTop: theme.space[8]
  };
  return {
    '.statusPopover': statusPopover,
    '.statusCircle': statusCircle,
    '.statusRow': statusRow,
    '.searchInput': searchInput,
    '.statusHeader': statusHeader,
    '.statusButton': statusButton,
    '.statusPopoverBody': statusPopoverBody,
    '.divider': divider
  };
};
