/// <reference path="../../types.d.ts" />

import React, { useState, useRef, useEffect } from 'react';
import './pop-up-filters.scss';

import '../../styles/grid-common.scss';
import '../../styles/cmx.scss';
import '../pageable-list/pageable-list.scss';

import { ReactComponent as DropdownClosed } from './../../assets/images/dropdown-arrow-closed.svg';
import { ReactComponent as DropdownOpen } from './../../assets/images/dropdown-arrow-open.svg';
import DateIcon from '../../assets/images/icon-date.svg';
import DateFilter from './date-filter';
import GenericFilter from './generic-filter';
import FilterModal from '../../components/filter-modal/filter-modal';
import { LOAD_STATES } from '../../reducers/common';
import SelectFilter from './select-filter';
import { commonEnums } from '@codametrix/ui-common';

const { FilterKeys } = commonEnums;

const PopUpFilter: React.FC<AppProps.PopUpFilterProps> = (
  props: AppProps.PopUpFilterProps
) => {
  const {
    criteriaFilter,
    sortablePageable,
    list,
    getDictionary,
    getStatuses,
    getTenants,
    clearFilters,
    activeOrgId,
    loadStates
  } = props;

  const [selectedForm, changeSelectedForm] = useState<string | undefined>(
    undefined
  );
  const [filterFormOpen, changeFilterFormOpen] = useState<boolean>(false);

  const [modalVisible, toggleModalVisibilty] = useState<boolean>(false);
  const handleClickOutside = (e: any) => {};

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  function _handleFilterCategoryClick(event: React.MouseEvent) {
    event.preventDefault();

    const target = event.target;
    const categoryIndex = (target as HTMLElement).getAttribute('id');

    if (typeof categoryIndex === 'string') {
      if (selectedForm === categoryIndex) {
        changeSelectedForm(undefined);
      } else {
        changeSelectedForm(categoryIndex);
      }

      changeFilterFormOpen(false);
    }

    let apiKey = (target as HTMLElement)?.getAttribute('api-key');
    switch (apiKey) {
      case 'procedure_type_category':
        getDictionary({
          categoryName: 'Procedure Type Categories',
          key: 'procedure_type_category'
        });
        break;
      case 'case_status':
        getStatuses({
          categoryName: 'casestatus',
          key: 'case_status'
        });
        break;
    }
  }

  const _handleClearAllFilters = () => {
    clearFilters!();
    let listOptions = sortablePageable;
    listOptions.filterableOptions.dateFilter = undefined;
    listOptions.filterableOptions.filters = listOptions.filterableOptions.filters?.filter(
      filter =>
        ['primary_clinical_identifier', 'patient_mrn', 'id'].includes(
          filter.key
        )
    );
    listOptions.pageableDefinition.pageable.pageNumber = 0;
    listOptions.pageableDefinition.number = 0;
    listOptions.pageableDefinition.first = true;
    listOptions.pageableDefinition.last = false;
    listOptions.pageableDefinition.pageable.offset = 0;
    list(listOptions);
  };

  let classes = ['pageable-list-view'];
  if (filterFormOpen || selectedForm !== undefined) {
    classes.push('filter-open');
  }

  const filterDropdowns = criteriaFilter.filterForms
    .filter(form => {
      return !form.disabled && !form.isDate && !form.isExtra;
    })
    .map(form => {
      var infoText: any = form.name;
      const loadState = loadStates?.get(form.key) || LOAD_STATES.done;

      criteriaFilter.filters?.forEach(filter => {
        if (filter.key === form.key) {
          infoText = (
            <span className="info-text">{filter.terms.join(', ')}</span>
          );
        }
      });

      if (form.isSelect === true) {
        return (
          <SelectFilter
            loadState={loadState}
            form={form}
            criteriaFilter={criteriaFilter}
            list={list}
            sortablePageable={sortablePageable}></SelectFilter>
        );
      }

      return (
        <div className="filter-container" key={form.name}>
          <span
            className={
              'filter-dropdown qa-filter-dropdown' +
              (selectedForm === form.key ? ' selected-dropdown' : '')
            }
            id={form.key}
            api-key={form.key}
            onClick={_handleFilterCategoryClick}>
            <div className="dropdown-info">
              {form.icon && <img src={form.icon} alt={'Drop Down Form Icon'} />}
              {infoText}
            </div>
            {selectedForm === form.key ? (
              <DropdownOpen className={'dropdown'} />
            ) : (
              <DropdownClosed className={'dropdown'} />
            )}
          </span>

          <div
            className={
              'filter-checklist' +
              (selectedForm === form.key ? ' selected' : '')
            }>
            <GenericFilter
              loadState={loadState}
              form={form}
              criteriaFilter={criteriaFilter}
              list={list}
              sortablePageable={sortablePageable}
            />
          </div>
        </div>
      );
    });

  var filteredDateFilter = criteriaFilter.dateFilter;

  var startDate;
  var endDate;

  if (filteredDateFilter) {
    startDate = filteredDateFilter.from;
    endDate = filteredDateFilter.to;
  }

  var isDateFormSelected;
  if (selectedForm) {
    isDateFormSelected = selectedForm === 'dates';
  } else {
    isDateFormSelected = false;
  }

  var infoText: any = 'Service Date';

  if (startDate && endDate) {
    infoText = (
      <span className="info-text">{startDate + ' to ' + endDate}</span>
    );
  }

  const dateDropdown = (
    <div className="filter-container">
      <span
        className={
          'date-dropdown filter-dropdown qa-date-dropdown qa-filter-dropdown' +
          (isDateFormSelected ? ' selected-dropdown' : '')
        }
        id={'dates'}
        onClick={_handleFilterCategoryClick}>
        <div className="dropdown-info">
          <img src={DateIcon} alt={'Drop Down Form Icon'} />
          {infoText}
        </div>
        {isDateFormSelected ? (
          <DropdownOpen className={'dropdown'} />
        ) : (
          <DropdownClosed className={'dropdown'} />
        )}
      </span>

      {isDateFormSelected ? (
        <div
          className={
            'filter-checklist' + (isDateFormSelected ? ' selected' : '')
          }>
          <DateFilter
            criteriaFilter={criteriaFilter}
            list={list}
            sortablePageable={sortablePageable}
          />
        </div>
      ) : null}
    </div>
  );

  const wrapperRef = useRef(null);

  function useOutsideClickAlert(ref: React.RefObject<HTMLDivElement>) {
    useEffect(() => {
      const handleClickOutside = (e: any) => {
        if (ref.current && !ref.current.contains(e.target)) {
          changeSelectedForm(undefined);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () =>
        document.removeEventListener('mousedown', handleClickOutside);
    }, [ref]);
  }

  useOutsideClickAlert(wrapperRef);

  const hasExtraFilters = criteriaFilter.filterForms.find(form => {
    return form.isExtra;
  });

  const dateFilterIndex = criteriaFilter.filterForms.findIndex(form => {
    return form.isDate;
  });

  const hasMultipleFilters = criteriaFilter.filterForms.length > 1;

  return (
    <div ref={wrapperRef} className={'filters-row'}>
      {dateFilterIndex !== -1 ? dateDropdown : false}
      {filterDropdowns}

      {hasExtraFilters ? (
        <>
          <button
            className={'more-filters-button'}
            onClick={() => toggleModalVisibilty(!modalVisible)}>
            More Filters
          </button>
        </>
      ) : null}

      {hasMultipleFilters ? (
        <button
          className={'clear-filters-button'}
          onClick={_handleClearAllFilters}>
          Clear All
        </button>
      ) : null}

      {modalVisible ? (
        <div>
          <span className={'opaque-overlay'} />{' '}
          <FilterModal
            criterialFilter={criteriaFilter}
            list={list}
            cancel={() => {
              toggleModalVisibilty(false);
            }}
            getTenants={getTenants}
            activeOrgId={activeOrgId}
            sortablePageable={sortablePageable}
            loadState={
              loadStates.get(FilterKeys.TENANT) as CMxCommonApp.Loading
            }
          />
        </div>
      ) : null}
    </div>
  );
};

PopUpFilter.defaultProps = {
  getDictionary: (categoryParams: AppProps.CategoryParams) => {},
  getStatuses: (categoryParams: AppProps.CategoryParams) => {}
};

export default PopUpFilter;
