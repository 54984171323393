import DateIcon from '../../assets/images/icon-date.svg';

const fieldDefinitionPublish: CMxCommonApp.FieldDefinition = {
  key: 'PUBLISH_DICTIONARY',
  helpText: 'Publish',
  required: false,
  type: 'checkbox',
  value: 'publish',
  isSelected: true
};

const fieldDefinitionUpdate: CMxCommonApp.FieldDefinition = {
  key: 'UPDATE_DICTIONARY',
  helpText: 'Update',
  required: false,
  type: 'checkbox',
  value: 'update',
  isSelected: true
};

const fieldDefinitionCreate: CMxCommonApp.FieldDefinition = {
  key: 'CREATE_DICTIONARY',
  helpText: 'Create',
  required: false,
  type: 'checkbox',
  value: 'create',
  isSelected: true
};

const fieldDefinitionDelete: CMxCommonApp.FieldDefinition = {
  key: 'DELETE_DICTIONARY',
  helpText: 'Delete',
  required: false,
  type: 'checkbox',
  value: 'delete',
  isSelected: true
};

const fieldDefinitionAddRow: CMxCommonApp.FieldDefinition = {
  key: 'ADD_ROW',
  helpText: 'Add Row',
  required: false,
  type: 'checkbox',
  value: 'addRow',
  isSelected: true
};

const fieldDefinitionUpdateRow: CMxCommonApp.FieldDefinition = {
  key: 'UPDATE_ROW',
  helpText: 'Update Row',
  required: false,
  type: 'checkbox',
  value: 'updateRow',
  isSelected: true
};

const fieldDefinitionPublishRow: CMxCommonApp.FieldDefinition = {
  key: 'PUBLISH_ROW',
  helpText: 'Publish Row',
  required: false,
  type: 'checkbox',
  value: 'publishRow',
  isSelected: true
};

const fieldDefinitionArchiveRow: CMxCommonApp.FieldDefinition = {
  key: 'ARCHIVE_ROW',
  helpText: 'Archive Row',
  required: false,
  type: 'checkbox',
  value: 'archiveRow',
  isSelected: true
};

const fieldDefinitionDeleteRow: CMxCommonApp.FieldDefinition = {
  key: 'DELETE_ROW',
  helpText: 'Delete Row',
  required: false,
  type: 'checkbox',
  value: 'deleteRow',
  isSelected: true
};

const fieldDefinitionUpdateDictionaryMetadata: CMxCommonApp.FieldDefinition = {
  key: 'UPDATE_DICTIONARY_METADATA',
  helpText: 'Update Dictionary Metadata',
  required: false,
  type: 'checkbox',
  value: 'updateDictionaryMetadata',
  isSelected: true
};

const fieldDefinitionCreateOrganization: CMxCommonApp.FieldDefinition = {
  key: 'CREATE_ORGANIZATION',
  helpText: 'Create Organization',
  required: false,
  type: 'checkbox',
  value: 'createOrganization',
  isSelected: true
};

const fieldDefinitionUpdateOrganization: CMxCommonApp.FieldDefinition = {
  key: 'UPDATE_ORGANIZATION',
  helpText: 'Update Organization',
  required: false,
  type: 'checkbox',
  value: 'updateOrganization',
  isSelected: true
};

const fieldDefinitionDeleteOrganization: CMxCommonApp.FieldDefinition = {
  key: 'DELETE_ORGANIZATION',
  helpText: 'Delete Organization',
  required: false,
  type: 'checkbox',
  value: 'deleteOrganization',
  isSelected: true
};

const fieldDefinitionCreateOrganizationRelationship: CMxCommonApp.FieldDefinition = {
  key: 'CREATE_ORGANIZATION_RELATIONSHIP',
  helpText: 'Create Organization Relationship',
  required: false,
  type: 'checkbox',
  value: 'createOrganizationRelationship',
  isSelected: true
};

const fieldDefinitionUpdateOrganizationRelationship: CMxCommonApp.FieldDefinition = {
  key: 'UPDATE_ORGANIZATION_RELATIONSHIP',
  helpText: 'Update Organization Relationship',
  required: false,
  type: 'checkbox',
  value: 'updateOrganizationRelationship',
  isSelected: true
};

const fieldDefinitionDelteOrganizationRelationship: CMxCommonApp.FieldDefinition = {
  key: 'DELETE_ORGANIZATION_RELATIONSHIP',
  helpText: 'Delete Organization Relationship',
  required: false,
  type: 'checkbox',
  value: 'deleteOrganizationRelationship',
  isSelected: true
};

const fieldDefinitionCreateServiceLine: CMxCommonApp.FieldDefinition = {
  key: 'CREATE_SERVICE_LINE',
  helpText: 'Create Service Line',
  required: false,
  type: 'checkbox',
  value: 'createServiceLine',
  isSelected: true
};

const fieldDefinitionUpdateServiceLine: CMxCommonApp.FieldDefinition = {
  key: 'UPDATE_SERVICE_LINE',
  helpText: 'Update Service Line',
  required: false,
  type: 'checkbox',
  value: 'updateServiceLine',
  isSelected: true
};

const fieldDefinitionDeleteServiceLine: CMxCommonApp.FieldDefinition = {
  key: 'DELETE_SERVICE_LINE',
  helpText: 'Delete Service Line',
  required: false,
  type: 'checkbox',
  value: 'deleteServiceLine',
  isSelected: true
};

const fieldDefinitionCreateEULA: CMxCommonApp.FieldDefinition = {
  key: 'CREATE_EULA',
  helpText: 'Create EULA',
  required: false,
  type: 'checkbox',
  value: 'createEULA',
  isSelected: true
};

const fieldDefinitionUpdateEULA: CMxCommonApp.FieldDefinition = {
  key: 'UPDATE_EULA',
  helpText: 'Update EULA',
  required: false,
  type: 'checkbox',
  value: 'updateEULA',
  isSelected: true
};

const formDefinitionDictionaryAction: CMxCommonApp.FormDefintion = {
  fieldGroups: [
    {
      label: 'Filter Dictionary Action',
      fields: [
        fieldDefinitionPublish,
        fieldDefinitionUpdate,
        fieldDefinitionDelete,
        fieldDefinitionCreate,
        fieldDefinitionAddRow,
        fieldDefinitionUpdateRow,
        fieldDefinitionPublishRow,
        fieldDefinitionArchiveRow,
        fieldDefinitionDeleteRow,
        fieldDefinitionUpdateDictionaryMetadata
      ]
    }
  ]
};

const formDefinitionOrganizationAction: CMxCommonApp.FormDefintion = {
  fieldGroups: [
    {
      label: 'Filter Organization Action',
      fields: [
        fieldDefinitionCreateOrganization,
        fieldDefinitionDeleteOrganization,
        fieldDefinitionUpdateOrganization,
        fieldDefinitionCreateOrganizationRelationship,
        fieldDefinitionDelteOrganizationRelationship,
        fieldDefinitionUpdateOrganizationRelationship,
        fieldDefinitionCreateServiceLine,
        fieldDefinitionDeleteServiceLine,
        fieldDefinitionUpdateServiceLine,
        fieldDefinitionCreateEULA,
        fieldDefinitionUpdateEULA
      ]
    }
  ]
};
const fieldDefinitionOrganization: CMxCommonApp.FieldDefinition = {
  key: 'Organization Logs',
  helpText: 'Organization Logs',
  required: false,
  type: 'checkbox',
  value: 'organizationLogs',
  isSelected: true
};

const fieldDefinitionDictionary: CMxCommonApp.FieldDefinition = {
  key: 'Dictionary Logs',
  helpText: 'Dictionary Logs',
  required: false,
  type: 'checkbox',
  value: 'dictionaryLogs',
  isSelected: true
};

const formDefinitionLogType: CMxCommonApp.FormDefintion = {
  fieldGroups: [
    {
      label: 'Filter Log Type',
      fields: [fieldDefinitionDictionary, fieldDefinitionOrganization]
    }
  ]
};

const formDefinitionDate: CMxCommonApp.FormDefintion = {
  fieldGroups: [
    {
      label: 'Filter Service',
      fields: []
    }
  ]
};

export const dateCriteria: AppProps.CriteriaForm = {
  name: 'Service Date',
  key: 'date_of_service',
  formDefinition: formDefinitionDate,
  icon: DateIcon,
  isDate: true
};

export const logTypeCriteria: AppProps.CriteriaForm = {
  name: 'Log Type',
  key: 'log_type',
  formDefinition: formDefinitionLogType,
  isColored: true
};

export const dictionaryActionCriteria: AppProps.CriteriaForm = {
  name: 'Dictionary Event',
  key: 'dictionary_action',
  formDefinition: formDefinitionDictionaryAction,
  isColored: true
};

export const organizationActionCriteria: AppProps.CriteriaForm = {
  name: 'Organization Event',
  key: 'organization_action',
  formDefinition: formDefinitionOrganizationAction,
  isColored: true
};
