import { connect } from 'react-redux';
import { ServiceDesk } from './service-desk';
import { launchWorkitemInAmplify } from '../../actions/cases.action';
import { codeBenchActions } from '@codametrix/ui-common';

const mapStateToProps = function(state: any): AppProps.ServiceDeskProps {
  return {
    viewType: 'GRID_WIDGET_CASES',
    capabilities: state.capabilities,
    userContext: { ...state.ui.context, serviceLine: state.ui.serviceLine },
    hasAmplifyCaseRunV2Access: state.ui.hasAmplifyCaseRunV2Access
  };
};

const mapDispatchToProps = (dispatch: any): AppProps.ServiceDeskMethods => {
  return {
    openAmplifyCase: (launchConfig: CMxCommonApp.LaunchWorkItemConfig) => {
      dispatch(launchWorkitemInAmplify(launchConfig));
      if (launchConfig.hasAmplifyCaseRunV2Access) {
        dispatch(codeBenchActions.hideSideNavBar());
      }
    }
  };
};

const mapQueueStateToProps = function(state: any): AppProps.ServiceDeskProps {
  return {
    viewType: 'GRID_WIDGET_WORKLIST_SETTINGS',
    userContext: { ...state.ui.context, serviceLine: state.ui.serviceLine }
  };
};

const mapQualityStateToProps = function(state: any): AppProps.ServiceDeskProps {
  return {
    viewType: 'GRID_CODING_QUALITY',
    userContext: { ...state.ui.context, serviceLine: state.ui.serviceLine }
  };
};

const mapQualityAssessmentStateToProps = function(
  state: any
): AppProps.ServiceDeskProps {
  return {
    viewType: 'GRID_QUALITY_ASSESSMENT',
    userContext: { ...state.ui.context, serviceLine: state.ui.serviceLine }
  };
};

const QueueSettings = connect(
  mapQueueStateToProps,
  mapDispatchToProps
)(ServiceDesk);
const WorkList = connect(mapStateToProps, mapDispatchToProps)(ServiceDesk);

const CodingQuality = connect(
  mapQualityStateToProps,
  mapDispatchToProps
)(ServiceDesk);

const QualityAssessment = connect(
  mapQualityAssessmentStateToProps,
  mapDispatchToProps
)(ServiceDesk);

export {
  QueueSettings,
  WorkList,
  CodingQuality,
  QualityAssessment,
  mapStateToProps as worklistMapStateToProps,
  mapDispatchToProps as worklistMapDispatchToProps
};
