import {
  STUB_ORG_RELATIONSHIP,
  CODAMETRIX
} from '../../views/organizations/stub-organization';

export const orgPath: CMx.DisplayablePath = {
  displayName: 'Organization',
  path: '/cmx/admin/orgs/:orgId(\\d+)',
  getDisplayName: (state: CMx.ApplicationState, value: number) => {
    const {
      organization: { activeOrganization }
    } = state;
    return activeOrganization.organizationName;
  }
};

const formErrors = {
  status: '',
  message: null,
  errors: [],
  fieldErrors: {}
};

export const organization: CMx.OrganizationState = {
  organizations: {},
  path: [],
  activeOrganization: CODAMETRIX,
  addChild: false,
  associations: [],
  serviceLines: [],
  organizationRelationships: [],
  relationship: STUB_ORG_RELATIONSHIP,
  edit: false,
  editable: false,
  formErrors,
  timezones: []
};
