import { theme } from '@codametrix/ui-common';

export const Drawer = {
  baseStyle: {
    //overlay: {},
    //dialogContainer: {},
    //dialog: {},
    header: {
      px: theme.space[40],
      pt: theme.space[40],
      pb: theme.space[20],
      ...theme.textStyles['h3']
    },
    closeButton: {
      top: theme.space[28],
      insetEnd: theme.space[28]
    },
    body: {
      px: theme.space[40],
      pt: 0,
      pb: theme.space[40]
    }
    //footer: {}
  }
};
