import React from 'react';

import { ColDef, GridOptions } from 'ag-grid-community';
import {
  EndpointData,
  HTTPMethodType
} from '../../components/data-grid/data-grid-utils';

export const dictionaryListcolDefs: ColDef[] = [
  {
    headerName: 'Dictionary Name',
    field: 'name',
    sort: 'asc'
  },
  {
    headerName: 'Status',
    field: 'status',
    valueGetter: params => {
      const targetString =
        params.data?.status.charAt(0) +
        params.data?.status.slice(1).toLowerCase();
      return targetString;
    },
    sortable: false
  },
  {
    headerName: 'Security',
    field: 'security',
    cellRenderer: (params: any) => {
      return params.data?.unrestrictedIndicator ? (
        <div>
          <i className="fas fa-unlock lock-color"></i>
        </div>
      ) : null;
    },
    sortable: false
  },
  {
    headerName: 'Updated By',
    field: 'updatedByFullName',
    sortable: false
  },
  {
    headerName: 'Date Updated',
    field: 'updatedDate',
    sortable: false
  }
];

export const getEndpointDataByTenantId = (tenantId: string) => {
  const params = [
    ['tenantId', `${tenantId}`],
    ['derivedIndicator', 'false'],
    ['uniqueByName', 'true']
  ];
  const endPoint: EndpointData = {
    endPoint: '/dictionary/v2',
    HTTPMethodType: HTTPMethodType.GET,
    params: params
  };
  return endPoint;
};

export const gridOptions: GridOptions = {
  suppressHorizontalScroll: true
};
