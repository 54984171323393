/// <reference path="../../../src/types.d.ts" />

import { LOAD_STATES } from '../common';
import {
  cmxDateTime,
  commonEnums as enums,
  cmxArtifacts
} from '@codametrix/ui-common';
import StatusIcon from '../../assets/images/icon-status.svg';
import DateIcon from '../../assets/images/icon-date.svg';
import { defaultFilterableOptions } from '../../core/default-filterable-options';
import { CODAMETRIX } from '../../views/organizations/stub-organization';
import { USER_STUB } from '../../tests/fixtures/stubs/user';
import { AmplifyCore } from '@codametrix/ui-common';

const { paginationUtils } = AmplifyCore;

const { FilterTypes, FilterKeys, CustomerPhase } = enums;
const { artifactSets } = cmxArtifacts;

const context: CMx.Context = {
  activeContext: null,
  contexts: [],
  jwtToken: '',
  expiryTimeToLive: 600000,
  expiryDuration: 600000,
  user: USER_STUB,
  activeContextId: null
};

const emptyCase: CMxAPI.Case = Object.freeze({
  id: 0,
  data: {},
  createdDate: null,
  caseRuns: [],
  processInstances: [],
  caseStatus: null,
  uiStatus: 'Dormant'
});

const defaultSortableOptions: CMxCommonApp.SortableOption[] = [
  {
    sortField: 'dateOfService',
    sortDirection: 'DESC'
  },
  { sortField: 'id', sortDirection: 'DESC' }
];

const defaultDateFilter: CMxCommonApp.DateFilter = {
  key: `date_of_service`,
  type: `BETWEEN`,
  from: cmxDateTime.format(new Date(), cmxDateTime.FORMATS.DATE, {
    throw: false,
    localized: true,
    offset: {
      action: cmxDateTime.DateManipulateOperation.SUBTRACT,
      delta: [2, cmxDateTime.DateManipulateUnit.DAY]
    }
  }),
  to: cmxDateTime.format(new Date(), cmxDateTime.FORMATS.DATE)
};

const sortablePageable: CMxCommonApp.SortablePageable<any> = {
  pageableDefinition: { ...paginationUtils.emptyPageable },
  sortableOptions: defaultSortableOptions,
  filterableOptions: {
    dateFilter: defaultDateFilter,
    ...defaultFilterableOptions
  }
};

const formDefinitionStatus: CMxCommonApp.FormDefintion = {
  fieldGroups: [
    {
      label: 'Filter Status',
      fields: []
    }
  ]
};

const formDefinitionServiceDate: CMxCommonApp.FormDefintion = {
  fieldGroups: [
    {
      label: 'Filter Service',
      fields: []
    }
  ]
};

const formDefinitionTenant: CMxCommonApp.FormDefintion = {
  fieldGroups: [
    {
      label: 'Filter Tenant',
      fields: []
    }
  ]
};

const fieldDefinitionLive: CMxCommonApp.FieldDefinition = {
  key: CustomerPhase.LIVE,
  helpText: 'Live',
  required: false,
  type: 'checkbox',
  value: 'live',
  isSelected: true
};

const fieldDefinitionActive: CMxCommonApp.FieldDefinition = {
  key: CustomerPhase.ACTIVE,
  helpText: 'Active',
  required: false,
  type: 'checkbox',
  value: 'active',
  isSelected: true
};

const fieldDefinitionParallel: CMxCommonApp.FieldDefinition = {
  key: CustomerPhase.PARALLEL,
  helpText: 'Parallel',
  required: false,
  type: 'checkbox',
  value: 'parallel',
  isSelected: true
};

const fieldDefinitionTest: CMxCommonApp.FieldDefinition = {
  key: CustomerPhase.TESTING,
  helpText: 'Testing',
  required: false,
  type: 'checkbox',
  value: 'testing',
  isSelected: true
};

const formDefinitionCustomerPhase: CMxCommonApp.FormDefintion = {
  fieldGroups: [
    {
      label: 'Filter Customer Phase',
      fields: [
        fieldDefinitionTest,
        fieldDefinitionParallel,
        fieldDefinitionActive,
        fieldDefinitionLive
      ]
    }
  ]
};

const customerPhaseCriteria: AppProps.CriteriaForm = {
  name: 'Customer Phase',
  key: 'customer_phase',
  formDefinition: formDefinitionCustomerPhase,
  isColored: true
};

const tenantCriteria: AppProps.CriteriaForm = {
  name: 'Location',
  key: FilterKeys.TENANT,
  formDefinition: formDefinitionTenant,
  isExtra: true
};

/*
const procedureCriteria: AppProps.CriteriaForm = {
  name: 'Procedures',
  key: 'procedure_type_category',
  formDefinition: formDefinitionProcedure,
  icon: ProcedureIcon,
  isDate: false
};
*/
const statusCriteria: AppProps.CriteriaForm = {
  name: 'Case Status',
  key: 'case_status',
  formDefinition: formDefinitionStatus,
  icon: StatusIcon,
  isDate: false,
  isColored: true
};

const serviceDateCriteria: AppProps.CriteriaForm = {
  name: 'Service Date',
  key: 'date_of_service',
  formDefinition: formDefinitionServiceDate,
  icon: DateIcon,
  isDate: true
};

const patientMRNCriteria: CMxCommonApp.FieldDefinition = {
  label: 'Patient MRN',
  key: 'patient_mrn',
  helpText: 'The patient medical record number',
  placeholder: 'Patient MRN',
  required: true,
  type: FilterTypes.EQ,
  isSelected: false
};

const accessionCriteria: CMxCommonApp.FieldDefinition = {
  label: 'Accession',
  key: 'primary_clinical_identifier',
  helpText: 'The accession number',
  placeholder: 'Case #',
  required: true,
  type: FilterTypes.EQ,
  isSelected: true
};

const caseIdCriteria: CMxCommonApp.FieldDefinition = {
  label: 'Case ID',
  key: 'id',
  helpText: 'The case ID number',
  placeholder: 'Case #',
  required: true,
  type: FilterTypes.EQ,
  isSelected: false
};

const HARCriteria: CMxCommonApp.FieldDefinition = {
  label: 'Hospital Account Record',
  key: 'hospital_account_record',
  helpText: 'The hospital acount record',
  placeholder: 'HAR',
  required: true,
  type: FilterTypes.EQ,
  isSelected: false
};

const orderNumberCriteria: CMxCommonApp.FieldDefinition = {
  label: 'Order Number',
  key: 'placer_order_number',
  helpText: 'The order number',
  placeholder: 'Order #',
  required: true,
  type: FilterTypes.EQ,
  isSelected: false
};

const criteriaFilter: AppProps.CriteriaFilter = {
  filters: [],
  dateFilter: defaultDateFilter,
  filterForms: [
    serviceDateCriteria,
    statusCriteria,
    tenantCriteria,
    customerPhaseCriteria
  ],
  searchForms: [
    accessionCriteria,
    HARCriteria,
    orderNumberCriteria,
    patientMRNCriteria,
    caseIdCriteria
  ]
};

const remoteFilters = [
  'procedure_type_category',
  FilterKeys.TENANT,
  'case_status'
];
const localFilters = ['date_of_service', 'date_of_automation'];

const filterLoadStates = new Map<string, CMxCommonApp.Loading>();

remoteFilters.forEach(key => {
  filterLoadStates.set(key, LOAD_STATES.initial);
});
localFilters.forEach(key => {
  filterLoadStates.set(key, LOAD_STATES.done);
});

const formErrors: CMxCommonApp.FormErrors = {
  status: '',
  message: null,
  errors: [],
  fieldErrors: {}
};

export const DefaultGroupedCases: CMxCommonApp.GroupedCases = {
  groupType: 'none',
  caseRuns: []
};

export const cases: CMx.CaseState = {
  apiResponse: {
    items: [],
    activeItem: emptyCase
  },
  activations: {
    panel: {},
    property: {},
    spans: {}
  },
  caseOrganizationContext: CODAMETRIX,
  context: context as CMx.Context,
  contexts: [],
  activeItem: {} as CMxAPI.CaseRun,
  submittedItem: {} as CMxAPI.CaseRun,
  simplifiedInvoices: [],
  items: [],
  codeDescriptions: {},
  codeEdits: {},
  groupedCases: DefaultGroupedCases,
  loadState: LOAD_STATES.initial,
  artifactLoadState: LOAD_STATES.initial,
  billingGroupLoadState: LOAD_STATES.done,
  relatedCasesLoadState: LOAD_STATES.done,
  refreshList: true,
  filterLoadStates,
  sortablePageable,
  pageable: paginationUtils.emptyPageable,
  artifactSet: artifactSets.defaultSet,
  artifactShowAll: false,
  itemName: 'Cases',
  idField: 'process_instance_id',
  notes: [],
  artifactList: [],
  stages: [],
  criteriaFilter,
  formErrors,
  displayTimeline: false,
  roles: [],
  runTypes: ['manual', 'prediction', 'CQA', 'Addendum', 'Dft'],
  caseRuns: {},
  actionsState: {
    importAmplify: {
      enabled: false,
      visible: false
    },
    launchAmplify: {
      enabled: false,
      visible: false
    },
    bpmSteps: {
      enabled: true,
      visible: true
    },
    jsonView: {
      enabled: true,
      visible: true
    },
    caseDocuments: {
      enabled: true,
      visible: true
    }
  },
  documentMetadata: [],
  activeNote: {} as CMxCommonApp.DecoratedNote,
  showOrderNumber: false
};
