/// <reference path="../types.d.ts" />

import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { api } from '../core/net';
import { push } from 'connected-react-router';
import { RulesList } from './action-types';
import { showFeedbackError } from './ui';

const actionCreator = actionCreatorFactory();
const createAsync = asyncFactory<CMx.CaseJSONState>(actionCreator);

const list = createAsync<
  void,
  CMxAPI.DecisionDefinitionDto[],
  CMxCommonApp.SubmitError
>(RulesList.LIST_RULES, async () => {
  const decisionDefinitions: CMxAPI.DecisionDefinitionDto[] = await api<
    CMxAPI.DecisionDefinitionDto[]
  >({
    endpoint: '/rules/list/v1'
  });

  return decisionDefinitions;
});

const getXML = createAsync<
  CMxAPI.DecisionDefinitionDto,
  string,
  CMxCommonApp.SubmitError
>(RulesList.GET_XML, async (decision, dispatch) => {
  const { taskId, decisionKey } = decision;

  const fileObject: CMxAPI.DecisionModelDto = await api<any>({
    endpoint: `/rules/${taskId}/v1`
  }).catch(e => {
    dispatch(
      showFeedbackError({
        id: Date.now(),
        message: `Decision Definition Not Found`,
        dismissable: true
      })
    );
    throw e;
  });

  dispatch(push(`/cmx/admin/rules/${decisionKey}/`));
  return fileObject.file;
});

export { list, getXML };
