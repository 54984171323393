import React, { useEffect, useRef, useState } from 'react';
import { CodeBenchv2 } from '@codametrix/shared-views';
import { ServiceDesk } from './service-desk';
import { useSelector, useDispatch } from 'react-redux';
import { uiActions } from '@codametrix/ui-common';

type DualComponentProps = {
  detailProps: any;
  detailMethods: any;
  listProps: any;
  listMethods: any;
  match: any;
};

const ServiceDeskAndCodeBenchv2 = (props: DualComponentProps) => {
  const { params = {} } = props.match ?? {};

  const { UIView } = useSelector((state: CMx.ApplicationState) => {
    return state.ampUI;
  });
  const dispatch = useDispatch();
  const childRef = useRef<any>(null);

  const detailProps = {
    UIView,
    ...props.detailProps,
    ...props.detailMethods
  };

  const listProps = {
    ...props.listProps,
    ...props.listMethods
  };

  const [isDetailOpen, setIsDetailOpen] = useState<boolean>(true);

  const handleSelection = (item: CMxAPI.CaseRunV2) => {
    setIsDetailOpen(true);
  };

  useEffect(() => {
    if (params.id === undefined && childRef?.current?.refreshContent) {
      childRef.current.refreshContent();
    }
    setIsDetailOpen(params.id !== undefined);
  }, [params.id]);

  useEffect(() => {
    dispatch(uiActions.setAmplifyView());
    dispatch(uiActions.setStandaloneMode());
    return () => {
      dispatch(uiActions.setAutomateView());
    };
  }, [dispatch]);

  return (
    <>
      <div style={{ display: isDetailOpen ? 'none' : 'block' }}>
        <ServiceDesk
          {...listProps}
          childRef={childRef}
          onSelection={handleSelection}
        />
      </div>
      {isDetailOpen ? (
        <CodeBenchv2 {...detailProps} match={props.match} />
      ) : null}
    </>
  );
};

export { ServiceDeskAndCodeBenchv2 };
