/// <reference path="../types.d.ts" />

import InitialState from '../reducers/initial-state';
import * as UIActions from '../actions/ui';
import { LocationChangePayload } from 'connected-react-router';
import { CMxComponents } from '@codametrix/ui-components/src/types';
import { retrieveGroups, NAV_OPTIONS } from '../core/navigation';
import { displayContexts, tokenAvailable } from '../actions/contexts';
import { checkResources } from '../core/resources';
import { cmxReducerFactory } from './reducer-utils';
import {
  configAvailable,
  setHomepagePreference,
  setBackToWorklistPath
} from '../actions/ui';
import { shortcuts as ShortcutSet } from './keypress/shortcuts';
import {
  commonEnums,
  PatientEncounterDashboardColumns,
  PatientEncounterDashboardColumnsv2,
  appServiceLines,
  uiActions,
  commonEnums as enums
} from '@codametrix/ui-common';
import { buildRoleSet } from './users/roles';
import * as ServiceLineActions from '../actions/service-lines';

const { SystemRole } = commonEnums;
const { PermissionLevel, ServiceLines } = commonEnums;

const highlightChanges = (
  state: CMx.InterfaceState,
  locationChange: LocationChangePayload
) => {
  const { actionGroups } = state;
  return {
    ...state,
    actionGroups: _highlightChanges(
      actionGroups,
      locationChange.location.pathname
    )
  };
};

const _highlightChanges = (
  actionGroups: CMxAPI.NavActionGroup[],
  pathname: string
) => {
  actionGroups.forEach(group => {
    group.options.forEach(opt => {
      if (!opt.path) {
        opt.active = false;
      } else {
        opt.active = pathname.indexOf(opt.path) !== -1;
      }
    });
  });
  return actionGroups;
};

// modify action groups based on configured feature flags
const configureActions = (
  actionGroups: CMxAPI.NavActionGroup[],
  config: CMx.InterfaceConfig
) => {
  actionGroups.map(group => {
    return group.options.map(option => {
      if (option?.subActions?.length) {
        //set the first enabled subAction as the active subaction and default option
        const activeSubaction = option.subActions.findIndex(
          action => action.enabled
        );
        if (activeSubaction !== -1) {
          option.subActions[activeSubaction].active = true;
        } else {
          option.enabled = false;
        }
      }
      return option;
    });
  });

  return actionGroups;
};

const getPHIMask = (roleSet: Set<string>) => {
  if (roleSet.has(SystemRole.DEMO)) {
    return {
      available: true,
      active: true
    };
  }

  return {
    available: false,
    active: false
  };
};

const filterShortcutBindings = (
  shorcutBindings: CMx.ShortcutBinding[],
  roleSet: Set<string>
) => {
  const shortcuts = shorcutBindings.filter(shortcut =>
    shortcut.roles.some(r => roleSet.has(r))
  );
  return shortcuts;
};

const handleContextChoice = (
  state: CMx.InterfaceState,
  context: CMx.Context
) => {
  const { config } = state;

  if (!context.jwtToken && state?.context?.jwtToken) {
    context.jwtToken = state?.context?.jwtToken;
  }

  context.contexts.forEach(context => {
    const resourceMap = new Map<number, CMxAPI.Resource>();

    context.resources.forEach(resource => {
      resourceMap.set(resource.resourceId ?? -1, resource);
    });

    const filtered = context.resources.filter(
      resource =>
        resource.parentId === null || resourceMap.has(resource.parentId!)
    );
    context.resources = filtered;
  });

  let actionGroups: CMxAPI.NavActionGroup[] = [];
  let AdminTabs: CMxComponents.TabDef[] = [];
  if (context.activeContext === null) {
    actionGroups = [];
    AdminTabs = [];
  } else {
    const availableActions = retrieveGroups(
      context.activeContext,
      state.serviceLine?.name ?? ''
    );
    actionGroups = _highlightChanges(
      availableActions,
      window.location.pathname
    );
    actionGroups = configureActions(actionGroups, config);

    const adminActionGroups = actionGroups.find((actionGroup: any) => {
      return actionGroup.key === 'admin';
    });

    if (adminActionGroups !== undefined) {
      AdminTabs =
        adminActionGroups.options[0].subActions
          .filter(tab => {
            return tab.enabled;
          })
          .filter((tab: CMxComponents.TabDef) => {
            const resourceForTab = context.activeContext?.resources.some(
              (resource: any) => {
                return resource.resourceName === tab.resourceId;
              }
            );

            return resourceForTab;
          })
          .map(tab => {
            const resourceForTab = context.activeContext?.resources.find(
              (resource: any) => {
                return resource.resourceName === tab.resourceId;
              }
            );

            return {
              ...tab,
              permissionLevel:
                resourceForTab?.permissionLevel || PermissionLevel.DENY
            };
          }) || [];
    }
  }
  const roleSet = new Set(buildRoleSet(context));
  const phiMask = getPHIMask(roleSet);
  const shortcuts = filterShortcutBindings(ShortcutSet, roleSet);

  const tenantId = context.activeContext?.tenantId;
  const { activeUser } = state;
  const org = activeUser.roles.find(
    (persona: any) => persona.tenantId === tenantId
  );
  const roles = org ? org.roles : [];
  const hasAmplifyCaseRunV2Access =
    context.activeContext?.resources.some(
      resource => resource.resourceName === enums.ResourceId.AMPLIFY_CASE_RUN_V2
    ) ?? false;

  return {
    ...state,
    roles,
    context: {
      ...context,
      expiryTimestamp: Date.now(),
      serviceLineName: state.serviceLine?.name
    },
    phiMask,
    shortcuts,
    AdminTabs,
    actionGroups: actionGroups,
    hasResources: checkResources(actionGroups),
    hasAmplifyCaseRunV2Access
  };
};

const removeModal = (state: CMx.InterfaceState) => {
  const nextState = { ...state };
  delete nextState.modal;
  return nextState;
};

const getNavOptionByKey = (key: string) => {
  return NAV_OPTIONS.find(option => {
    return option.key === key;
  });
};

const reducer = cmxReducerFactory(InitialState.ui)
  .case(UIActions.replaceUIState, (state, newState) => {
    const jwtToken = state?.context?.jwtToken ?? newState?.context.jwtToken;

    return {
      ...state,
      ...newState,
      awaitingTokenTransfer: false,
      context: {
        ...newState.context,
        jwtToken
      }
    };
  })
  .case(uiActions.resetCommonState, () => {
    return { ...InitialState.ui };
  })
  .case(uiActions.chooseContext, handleContextChoice)
  .case(displayContexts, (state, contexts) => {
    return { ...state, contexts, loading: false };
  })
  .case(UIActions.loading, (state, loading) => {
    return { ...state, loading };
  })
  .case(UIActions.loadingAction, (state, loadingAction) => {
    return { ...state, loadingState: loadingAction };
  })
  .case(UIActions.dismissModal, removeModal)
  .case(UIActions.showModalMessage, (state, modal) => ({ ...state, modal }))
  .case(UIActions.onLocationChange, highlightChanges)
  .case(UIActions.navigate, (state, action) => {
    const actionGroups: CMxAPI.NavActionGroup[] = state.actionGroups;
    const orignalActionGroups = actionGroups.map(actionGroup => {
      return getNavOptionByKey(actionGroup.key);
    });
    const AdminTabs = state.AdminTabs.map((tab, index) => {
      if (index === 0) {
        return { ...tab, active: true };
      } else {
        return { ...tab, active: false };
      }
    });

    return { ...state, orignalActionGroups, AdminTabs };
  })
  .case(UIActions.changeAdminTab, (state, result) => {
    return { ...state, AdminTabs: result };
  })
  .case(UIActions.detailTogglePHI, (state, phiMask) => {
    return { ...state, phiMask };
  })
  .case(configAvailable, (state, params) => {
    const { config } = state;

    config.mixpanelApiKey = params?.userAnalytics?.enabled
      ? params?.userAnalytics.apiKey
      : undefined;
    config.grid = params?.grid;
    config.isNewDesign = params?.newDesign?.enabled ?? false;
    config.apiProxyServiceEnabled =
      params?.apiProxyServiceEnabled?.enabled ?? false;

    return { ...state };
  })
  .case(UIActions.setActiveUser, (state, details) => {
    return { ...state, activeUser: details };
  })
  .case(ServiceLineActions.chooseServiceLine.async.started, state => {
    return {
      ...state,
      loading: true
    };
  })
  .case(ServiceLineActions.chooseServiceLine.async.done, state => {
    return {
      ...state,
      loading: false
    };
  })
  .case(ServiceLineActions.saveServiceLine, (state, choice) => {
    const { config, context } = state;
    let actionGroups;
    const choiceName = choice?.name ?? '';

    if (context.activeContext) {
      const availableActions = retrieveGroups(
        context.activeContext,
        choiceName
      );
      actionGroups = _highlightChanges(
        availableActions,
        window.location.pathname
      );
      actionGroups = configureActions(actionGroups, config);
    } else {
      actionGroups = state.actionGroups;
    }

    if (choice === undefined) {
      return {
        ...state
      };
    }

    const serviceLineGroups = actionGroups.map(group => {
      if (group.options[0].id === 'cases') {
        return {
          ...group,
          options: [
            { ...group.options[0], path: `cases/${choiceName.toLowerCase()}/` }
          ]
        };
      }
      return group;
    });

    let configName = choiceName;
    if (appServiceLines.isIPC(choiceName)) {
      configName = ServiceLines.IPC_LONG_NAME;
    }

    const slName: string = `${configName}`.toLowerCase().replace(/\s/g, '');

    const encounterColumns = state.hasAmplifyCaseRunV2Access
      ? PatientEncounterDashboardColumnsv2
      : PatientEncounterDashboardColumns;

    // Check if slName exists in the chosen object
    const propertyConfig =
      slName in encounterColumns
        ? encounterColumns[slName]
        : state.propertyConfig;

    const activeContext = state.context.activeContext
      ? {
          ...state.context.activeContext,
          serviceLineName: choiceName
        }
      : null;

    return {
      ...state,
      propertyConfig,
      serviceLine: choice,
      actionGroups: serviceLineGroups,
      context: {
        ...state.context,
        activeContext,
        serviceLineName: choiceName
      }
    };
  })
  .case(ServiceLineActions.navigateServiceLine.async.started, state => {
    return {
      ...state,
      loading: true
    };
  })
  .case(ServiceLineActions.navigateServiceLine.async.done, state => {
    return {
      ...state,
      loading: false
    };
  })
  .case(setHomepagePreference, (state, homepagePreference) => {
    return {
      ...state,
      homepagePreference
    };
  })
  .case(setBackToWorklistPath, (state, actionGroupOption) => {
    return {
      ...state,
      backToWorklistPath: actionGroupOption.path
    };
  })
  .case(tokenAvailable, (state, token) => {
    return {
      ...state,
      awaitingTokenTransfer: false,
      context: {
        ...state.context,
        jwtToken: token.token ?? undefined
      }
    };
  });

export default reducer;
