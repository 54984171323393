import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './user-icon.scss';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

interface userIconProps {
  initials: string;
  classes: string[];
  iconId?: string;
  textId?: string;
}

library.add(faCircle);

export const UserIcon = (props: userIconProps) => {
  return (
    <div className="avatar-icon">
      <span className="nav-item-icon nav-log nav-item-right fa-layers fa-fw">
        <FontAwesomeIcon
          icon="circle"
          className={
            props.classes.join(' ') + ' fa-circle highlight-circle fa-layers'
          }
        />
        <div className="main-icon-group">
          <FontAwesomeIcon
            id={props.iconId}
            icon="circle"
            className="fa-circle main-icon"
          />
          <span id={props.textId} className="fa-layers-text main-text">
            <span>{props.initials.toUpperCase()}</span>
          </span>
        </div>
      </span>
    </div>
  );
};
