import React from 'react';
import { BiomeNavIcon, NavIconName } from '@codametrix/verdigris';

export const navComponents: { [key: string]: JSX.Element } = {
  Admin: <BiomeNavIcon iconName={NavIconName.Admin} titleText="Admin" />,
  Cases: <BiomeNavIcon iconName={NavIconName.CaseList} titleText="Cases" />,
  'Cases (Classic)': (
    <BiomeNavIcon iconName={NavIconName.CaseList} titleText="Cases (Classic)" />
  ),

  Analytics: (
    <BiomeNavIcon iconName={NavIconName.Insights} titleText="Analytics" />
  ),
  Insights: (
    <BiomeNavIcon iconName={NavIconName.Insights} titleText="Insights" />
  ),
  'Provider Directory': (
    <BiomeNavIcon
      iconName={NavIconName.Providers}
      titleText="Provider Directory"
    />
  ),
  'Queue Settings': (
    <BiomeNavIcon
      iconName={NavIconName.WorklistConfig}
      titleText="Queue Settings"
    />
  ),
  'Coding Quality': (
    <BiomeNavIcon
      iconName={NavIconName.CodingQuality}
      titleText="Coding Quality"
    />
  ),
  'Quality Assessment': (
    <BiomeNavIcon
      iconName={NavIconName.Classfication}
      titleText="Quality Assessment"
    />
  ),
  Worklist: (
    <BiomeNavIcon iconName={NavIconName.Worklist} titleText="Worklist" />
  ),
  Search: <BiomeNavIcon iconName={NavIconName.Search} titleText="Search" />
};
