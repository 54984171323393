/// <reference path="../types.d.ts" />

import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { api } from '../core/net';
import { NormalTime } from './action-types';
import { HttpMethods } from '@codametrix/ui-common';

const actionCreator = actionCreatorFactory();
const createAsync = asyncFactory<CMxAPI.NormalTimeDay>(actionCreator);

const listNormalTime = createAsync<
  {
    dateToday: Date;
    userId: string;
    tenantId: string;
  },
  CMxAPI.NormalTimeDay[],
  CMxCommonApp.SubmitError
>(NormalTime.LIST_NORMAL_TIME, async (params, dispatch) => {
  const { dateToday, userId, tenantId } = params;
  const startDate = getDateOfEntry(
    'Sunday',
    dateToday,
    dateToday.toLocaleDateString('en-us', { weekday: 'long' })
  );
  const endDate = getDateOfEntry(
    'Saturday',
    dateToday,
    dateToday.toLocaleDateString('en-us', { weekday: 'long' })
  );

  const listParams = `userUid=${userId}&tenantId=${tenantId}&startDate=${startDate}&endDate=${endDate}`;

  let normalTimeWeek = await api<CMxAPI.PageableList<CMxAPI.NormalTimeDay>>({
    endpoint: `/api/coder-activity/work-log/normal/user/tenant/v1?${listParams}`,
    init: {
      method: HttpMethods.GET
    }
  });

  return normalTimeWeek.content;
});

// Getting date of DayOfWeek in current week relative to today's date
const getDateOfEntry = (
  dayOfWeek: string,
  dateToday: Date,
  dayOfWeekToday: string
) => {
  const daysOfWeek = Object.freeze([
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ]);
  const dayIndex = daysOfWeek.indexOf(dayOfWeek);
  const todayDayIndex = daysOfWeek.indexOf(dayOfWeekToday);

  let targetDay = new Date();

  let compareDate = dayIndex - todayDayIndex;
  if (compareDate < 0) {
    compareDate = Math.abs(compareDate);
    targetDay.setDate(dateToday.getDate() - (compareDate % 7));
  } else {
    targetDay.setDate(dateToday.getDate() + (compareDate % 7));
  }

  return targetDay.toISOString().split('T')[0];
};

const saveNormalTime = createAsync<
  {
    hours: { [key: string]: number };
    user: CMxAPI.User;
    context: CMx.Context;
  },
  CMxAPI.NormalTimeDay[],
  CMxCommonApp.SubmitError
>(NormalTime.SAVE_NORMAL_TIME, async (value, dispatch: any) => {
  const endpoint = `/api/coder-activity/work-log/normal/v1`;

  const hours = value.hours;
  const userId: string = value.user.userId;
  const fullName = value.user.firstName + ' ' + value.user.lastName;
  const tenantId = value.context.activeContext?.tenantId;
  const workingHoursList = [];
  const dateToday = new Date();
  const dayOfWeekToday = new Date().toLocaleDateString('en-us', {
    weekday: 'long'
  });

  for (const key in hours) {
    const hoursWorked = hours[key];

    workingHoursList.push({
      userUid: userId,
      tenantId: tenantId,
      name: fullName,
      dateOfEntry: getDateOfEntry(key, dateToday, dayOfWeekToday),
      day: key,
      hoursWorked: hoursWorked
    });
  }

  const res = await api<CMxAPI.NormalTimeDay[]>({
    endpoint: endpoint,
    init: {
      method: HttpMethods.POST
    },
    body: workingHoursList
  });

  return res;
});

export { listNormalTime, saveNormalTime };
