/// <reference path="../../../src/types.d.ts" />

import { LOAD_STATES } from '../common';

export const analytics: CMx.AnalyticsState = {
  loadState: LOAD_STATES.started,
  enabled: null,
  dashboardURL: null,
  tableauInfo: undefined,
  activeDashboard: '',
  showDashboardOptions: false
};
