import { cmxReducerFactory } from './reducer-utils';
import InitialState from '../reducers/initial-state';
import { toggleDrawer, toggleDrawerContent } from '../actions/ui';
import { codeBenchActions } from '@codametrix/ui-common';

export const reducer = cmxReducerFactory(InitialState.providerDetails)
  .case(toggleDrawerContent, (state, isOpen) => {
    return { ...state, isOpen };
  })
  .case(toggleDrawer, (state, isDrawerOpen) => {
    return { ...state, isDrawerOpen };
  })
  .case(
    codeBenchActions.navigateProvider.async.started,
    (state, providerInfoInput) => {
      return {
        ...state,
        isDrawerOpen: true,
        servicesLoading: true,
        isSingleTenantData: !!providerInfoInput.tenantCode,
        providerInfoInput
      };
    }
  )
  .case(
    codeBenchActions.navigateProvider.async.done,
    (state, { result: providerInfo }) => {
      return { ...state, isOpen: true, providerInfo, servicesLoading: false };
    }
  );

export default reducer;
