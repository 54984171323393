import theme from '../../theme';

export const styles = () => ({
  container: {
    // height: '100%',
    templateRows: 'repeat(2, 1fr)',
    gap: theme.space[16]
  },
  searchGrid: {
    gap: 4
  },
  searchOptionGridItem: {
    colSpan: 1,
    paddingTop: '7px'
  },
  inputGridItem: {
    colStart: 2,
    colEnd: 3,
    alignItems: 'center'
  },
  filterGrid: {
    gap: 4
  },
  textGridItem: {
    display: 'flex',
    alignItems: 'center'
  },
  addUserButtonGrid: {
    justifySelf: 'flex-end'
  }
});
