import React, { useEffect, useState, useMemo } from 'react';
import { Box, Grid, Text } from '@chakra-ui/react';
import { styles } from './organization.styles';
import Input from '../../components/input/input';
import { InputSize } from '../../core/enums';
import { Checkbox, CheckboxSize } from '../../components/checkbox/checkbox';
import { states } from './organization-constants';
import { SubHeader } from './subheader';
import STUB_ORG, { STUB_ORG_RELATIONSHIP } from './stub-organization';
import { Select } from '../../components/select/select';
import { convertTimeZones } from './organization-utils';
import { ServiceLines } from './service-line-selection';

export type OrganizationFormProps = {
  cancel: () => void;
  doSave: (
    org: CMxAPI.Organization,
    edits: CMx.PartialRelationshipEdit[]
  ) => void;
  edit?: boolean;
  errors: CMxCommonApp.FieldErrors;
  organization?: CMxAPI.Organization;
  orgRelationships: CMxAPI.OrganizationRelationship[];
  orgServiceLines?: CMxAPI.OrgServiceLine[];
  orgType: boolean | string;
  relationship: CMxAPI.OrganizationRelationship;
  serviceLines: CMxAPI.ServiceLine[];
  timezones: string[];
  viewOnly: boolean;
  showServiceLines?: boolean;
  title: string | null;
};

export type OrgFormState = {
  customTenantId: boolean;
  edits: CMx.PartialRelationshipEdit[];
  organization: CMxAPI.Organization;
  orgRelationships: CMx.RelationshipEdit[];
};

const initialOrgForm: OrgFormState = {
  customTenantId: true,
  edits: [],
  organization: STUB_ORG,
  orgRelationships: [
    { type: 'ADD', organizationRelationship: STUB_ORG_RELATIONSHIP }
  ]
};

const hasError = (key: string, errors: any) => {
  return errors && key in errors;
};

const getInlineError = (errors: string[]) => {
  return errors.join(' ');
};

const displayErrorIfPresent = (
  key: string,
  errors: CMxCommonApp.FieldErrors
) => {
  return hasError(key, errors) ? getInlineError(errors[key]) : '';
};

const OrganizationForm: React.FC<OrganizationFormProps> = props => {
  const classes = styles();
  const {
    title,
    organization,
    relationship,
    errors,
    edit,
    timezones,
    viewOnly,
    serviceLines,
    showServiceLines,
    orgRelationships
  } = props;

  const [organizationDetails, setOrganizationDetails] = useState<OrgFormState>(
    initialOrgForm
  );
  const orgState = useMemo(() => {
    return { ...STUB_ORG, ...organization };
  }, [organization]);

  useEffect(() => {
    setOrganizationDetails({
      organization: orgState,
      edits: [],
      orgRelationships: [
        { type: 'ADD', organizationRelationship: { ...relationship } }
      ],
      customTenantId: true
    });
  }, [relationship, orgState]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLElement>
  ) => {
    const target = event.target as HTMLInputElement;
    const { value, name } = target;
    setOrganizationDetails((prevState: OrgFormState) => ({
      ...prevState,
      organization: {
        ...prevState.organization,
        [name]: value
      }
    }));
  };

  const toggleCustomTenantId = () => {
    setOrganizationDetails((prevState: OrgFormState) => ({
      ...prevState,
      customTenantId: !prevState.customTenantId
    }));
  };

  const toggleActiveOrganization = () => {
    setOrganizationDetails((prevState: OrgFormState) => ({
      ...prevState,
      organization: {
        ...prevState.organization,
        active: !prevState.organization.active
      }
    }));
  };

  const toggleAWSCognitoIndicator = () => {
    setOrganizationDetails((prevState: OrgFormState) => ({
      ...prevState,
      organization: {
        ...prevState.organization,
        awsCognitoIndicator: !prevState.organization.awsCognitoIndicator
      }
    }));
  };

  const toggleSSOIndicator = () => {
    setOrganizationDetails((prevState: OrgFormState) => ({
      ...prevState,
      organization: {
        ...prevState.organization,
        ssoIndicator: !prevState.organization.ssoIndicator
      }
    }));
  };

  const handleSubmit = () => {
    props.doSave(organizationDetails.organization, organizationDetails.edits);
  };

  const handleEditToggle = () => {
    props.cancel();
  };

  const handleServiceLine = (edits: CMx.PartialRelationshipEdit[]) => {
    setOrganizationDetails((prevState: OrgFormState) => ({
      ...prevState,
      edits
    }));
  };

  return (
    <Box>
      <SubHeader
        linkButtonName={'Cancel'}
        actionName={'Submit'}
        title={title}
        onLinkButtonClick={handleEditToggle}
        onActionClick={handleSubmit}
        isActionDisabled={viewOnly}
        className={classes.text}
      />
      <Grid sx={classes.fieldsets}>
        <fieldset style={classes.fieldset}>
          <legend style={classes.legend}>Info</legend>
          <Grid sx={classes.grid}>
            <Input
              placeholder={'Enter name'}
              label={'Name'}
              isRequired={true}
              size={InputSize.MD}
              onChange={handleInputChange}
              name="organizationName"
              textError={displayErrorIfPresent('organizationName', errors)}
              isError={hasError('organizationName', errors)}
              value={organizationDetails?.organization?.organizationName}
              dataTestId="organizationName"
            />
            <Input
              placeholder={'Enter display Name'}
              label={'Display Name'}
              size={InputSize.MD}
              classes={{ root: classes.input }}
              onChange={handleInputChange}
              name="displayName"
              textError={displayErrorIfPresent('displayName', errors)}
              isError={hasError('displayName', errors)}
              value={organizationDetails.organization.displayName ?? ''}
              dataTestId="displayName"
            />
            <Input
              placeholder={'Enter short display name'}
              label={'Short Display Name'}
              size={InputSize.MD}
              classes={{ root: classes.input }}
              onChange={handleInputChange}
              name="shortDisplayName"
              textError={displayErrorIfPresent('shortDisplayName', errors)}
              isError={hasError('shortDisplayName', errors)}
              value={organizationDetails.organization.shortDisplayName ?? ''}
              dataTestId="shortDisplayName"
            />
            <Input
              label={'Code'}
              placeholder={'Enter code'}
              isRequired={true}
              size={InputSize.MD}
              classes={{ root: classes.input }}
              onChange={handleInputChange}
              name="organizationCode"
              textError={displayErrorIfPresent('organizationCode', errors)}
              isError={hasError('organizationCode', errors)}
              value={organizationDetails?.organization?.organizationCode}
              dataTestId="organizationCode"
            />
            {!edit && (
              <Grid sx={classes.checkbox}>
                <Checkbox
                  label={'Generate a Tenant ID for me'}
                  size={CheckboxSize.MD}
                  isChecked={!organizationDetails?.customTenantId}
                  onChange={toggleCustomTenantId}
                  name="tenantCheckbox"
                  dataTestId="tenantId"
                />
              </Grid>
            )}
            {(organizationDetails.customTenantId || edit) && (
              <Input
                label={'Tenant ID'}
                size={InputSize.MD}
                name="tenantId"
                textError={displayErrorIfPresent('tenantId', errors)}
                isError={hasError('tenantId', errors)}
                value={organizationDetails?.organization?.tenantId}
                isDisabled={edit || viewOnly}
                classes={{ root: edit ? classes.input : classes.checkbox }}
                dataTestId="tenantId-input"
              />
            )}
            {showServiceLines && (
              <ServiceLines
                serviceLines={serviceLines}
                relationship={relationship}
                orgRelationships={orgRelationships}
                onChange={handleServiceLine}
              />
            )}
            {
              <Grid sx={classes.checkbox}>
                <Checkbox
                  label={'Active'}
                  size={CheckboxSize.MD}
                  isChecked={organizationDetails?.organization?.active}
                  onChange={toggleActiveOrganization}
                  name="activeCheckbox"
                  dataTestId="tenantId"
                />
              </Grid>
            }
          </Grid>
        </fieldset>
        {!showServiceLines && (
          <>
            <fieldset style={classes.fieldset}>
              <legend style={classes.legend}>Address</legend>
              <Grid sx={classes.grid}>
                <Input
                  label={'Address Line 1'}
                  placeholder={'Enter address line 1'}
                  size={InputSize.MD}
                  onChange={handleInputChange}
                  name="address1"
                  textError={displayErrorIfPresent('address1', errors)}
                  isError={hasError('address1', errors)}
                  value={organizationDetails?.organization?.address1 ?? ''}
                  dataTestId="address1"
                />
                <Input
                  label={'Address Line 2'}
                  placeholder={'Enter address line 2'}
                  size={InputSize.MD}
                  onChange={handleInputChange}
                  name="address2"
                  classes={{ root: classes.input }}
                  textError={displayErrorIfPresent('address2', errors)}
                  isError={hasError('address2', errors)}
                  value={organizationDetails?.organization?.address2 ?? ''}
                  dataTestId="address2"
                />
                <Grid templateColumns={'repeat(2, 1fr)'} columnGap={3}>
                  <Input
                    placeholder={'Enter city'}
                    label={'City'}
                    size={InputSize.MD}
                    onChange={handleInputChange}
                    name="city"
                    textError={displayErrorIfPresent('city', errors)}
                    isError={hasError('city', errors)}
                    classes={{ root: classes.input }}
                    value={organizationDetails?.organization?.city ?? ''}
                    dataTestId="city"
                  />
                  <Grid
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    sx={classes.input}>
                    <Text sx={classes.label}>State</Text>
                    <Select
                      items={states}
                      placeholder="State"
                      name="state"
                      value={organizationDetails?.organization?.state ?? ''}
                      onChange={handleInputChange}
                      dataTestId="state"
                    />
                  </Grid>
                  <Input
                    placeholder={'Enter zip'}
                    label={'Zip'}
                    size={InputSize.MD}
                    onChange={handleInputChange}
                    name="zipCode"
                    textError={displayErrorIfPresent('zipCode', errors)}
                    isError={hasError('zipCode', errors)}
                    classes={{ root: classes.input }}
                    value={organizationDetails?.organization?.zipCode ?? ''}
                    dataTestId="zipCode"
                  />
                </Grid>
                <Grid
                  style={{ width: '50%' }}
                  rowGap={3}
                  display="flex"
                  flexDirection="column"
                  sx={classes.input}>
                  <Text sx={classes.label}>Timezone</Text>
                  <Select
                    items={convertTimeZones(timezones)}
                    placeholder="Timezone"
                    onChange={handleInputChange}
                    name={'timeZoneFullName'}
                    value={organizationDetails?.organization?.timeZoneFullName}
                    dataTestId="timeZoneFullName"
                  />
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style={classes.fieldset}>
              <legend style={classes.legend}>Authentication</legend>
              <Grid sx={classes.grid}>
                <Grid sx={classes.checkbox}>
                  <Checkbox
                    label={'AWS Cognito Flag'}
                    size={CheckboxSize.MD}
                    isChecked={
                      organizationDetails?.organization?.awsCognitoIndicator
                    }
                    onChange={toggleAWSCognitoIndicator}
                    name="cognitoCheckbox"
                    dataTestId="cognitoFlag"
                  />
                  <Checkbox
                    label={'Cognito SSO Flag'}
                    size={CheckboxSize.MD}
                    isChecked={organizationDetails?.organization?.ssoIndicator}
                    onChange={toggleSSOIndicator}
                    name="ssoCheckbox"
                    dataTestId="ssoFlag"
                  />
                </Grid>
              </Grid>
            </fieldset>
            <fieldset style={classes.fieldset}>
              <legend style={classes.legend}>Contact</legend>
              <Grid sx={classes.grid}>
                <Input
                  placeholder={'Enter name'}
                  label={'Name'}
                  size={InputSize.MD}
                  onChange={handleInputChange}
                  name="contactName"
                  textError={displayErrorIfPresent('contactName', errors)}
                  isError={hasError('contactName', errors)}
                  value={organizationDetails?.organization?.contactName ?? ''}
                  dataTestId="contactName"
                />
                <Input
                  placeholder={'Enter phone number'}
                  label={'Phone'}
                  size={InputSize.MD}
                  onChange={handleInputChange}
                  name="phone"
                  textError={displayErrorIfPresent('phone', errors)}
                  isError={hasError('phone', errors)}
                  classes={{ root: classes.input }}
                  value={organizationDetails?.organization?.phone ?? ''}
                  dataTestId="phone"
                />
              </Grid>
            </fieldset>
          </>
        )}
      </Grid>
    </Box>
  );
};

OrganizationForm.displayName = 'OrganizationForm';
export { OrganizationForm };
