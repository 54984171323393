import React, { useState } from 'react';
import {
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  useTab,
  useMultiStyleConfig,
  Flex,
  Box,
  Stack
} from '@chakra-ui/react';
import {
  childTabStyles,
  disabledStyles,
  styles
} from './secondary-navigation.styles';
import { ReactComponent as ChevronDown } from '../../assets/images/chevron-down.svg';
import { ReactComponent as ChevronUp } from '../../assets/images/chevron-up.svg';
import theme from '../../theme';

export enum SecondaryNavigationVariants {
  LINE = 'line',
  ENCLOSED = 'enclosed',
  ENCLOSED_COLORED = 'enclosed-colored',
  SOFT_ROUNDED = 'soft-rounded',
  SOLID_ROUNDED = 'solid-rounded',
  UNSTYLED = 'unstyled'
}

export enum SecondaryNavigationOrientation {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical'
}

export type TabData = {
  label: string;
  content?: any;
  children?: TabData[];
  disabled?: boolean;
};

export type SecondaryNavigationProps = {
  data: TabData[];
  tabIndex?: number;
  variant?: SecondaryNavigationVariants;
  orientation?: SecondaryNavigationOrientation;
  handleTabsChange?: (index: number) => void;
  handleNestedTabClick?: (index: number, parentLabel: string) => void;
  activeTabColor?: string;
  tabColor?: string;
  childTabIndex?: number;
};

const SecondaryNavigation: React.FC<SecondaryNavigationProps> = props => {
  const {
    data,
    orientation,
    handleTabsChange,
    handleNestedTabClick,
    tabIndex,
    activeTabColor,
    tabColor,
    childTabIndex
  } = props;
  const [_childTabIndex, setChildTabIndex] = useState<number>(
    childTabIndex ?? 0
  );

  const handleChildTabsChange = (
    childTabIndex: number,
    parentLabel: string
  ) => {
    setChildTabIndex(childTabIndex);
    handleNestedTabClick && handleNestedTabClick(childTabIndex, parentLabel);
  };

  const onTabsChange = (tabIndex: number) => {
    setChildTabIndex(0);
    handleTabsChange && handleTabsChange(tabIndex);
  };

  const CustomTab = (props: any) => {
    const tabProps = useTab(props);
    const { label, disabled } = props.children;
    const tabStyles = useMultiStyleConfig('Tabs', tabProps);
    const classes = styles(
      tabStyles,
      orientation ?? SecondaryNavigationOrientation.HORIZONTAL,
      activeTabColor,
      tabColor
    );
    const [isOpen, setIsOpen] = useState<boolean>(label === props.parentTab);

    const handleChevronClick = () => {
      setIsOpen(prevState => !prevState);
    };

    const handleChildTabsChange = (e: number) => {
      props.handleChildTabsChange(e, label);
    };

    return (
      <>
        <Flex
          sx={Object.assign(
            {},
            classes,
            disabled && disabledStyles,
            props.parentTab === undefined ? childTabStyles.tabs : {}
          )}
          {...tabProps}
          flexDirection="column"
          alignItems="baseline"
          data-testid={`${label.trim()}tab`}>
          <Flex
            flexDirection="row"
            alignItems="center"
            onClick={handleChevronClick}
            data-testid={`${label.trim()}`}>
            <Box
              ml={
                orientation === SecondaryNavigationOrientation.VERTICAL
                  ? 0
                  : '2'
              }
              mr="2">
              {label}
            </Box>
            {orientation === SecondaryNavigationOrientation.VERTICAL &&
              props.children?.children && (
                <>
                  {isOpen ? (
                    <ChevronUp
                      fill={
                        label === props.parentTab
                          ? theme.colors.accent[200]
                          : theme.colors.foundation[300]
                      }
                      height={theme.space[8]}
                      width={theme.space[12]}
                    />
                  ) : (
                    <ChevronDown
                      fill={
                        label === props.parentTab
                          ? theme.colors.accent[200]
                          : theme.colors.foundation[300]
                      }
                      height={theme.space[8]}
                      width={theme.space[12]}
                    />
                  )}
                </>
              )}
          </Flex>
          <Flex>
            {isOpen && (
              <Tabs
                index={props.childTabIndex}
                onChange={handleChildTabsChange}
                orientation={SecondaryNavigationOrientation.VERTICAL}
                variant={SecondaryNavigationVariants.UNSTYLED}
                alignItems="baseline">
                <Stack spacing={theme.space[16]} alignContent="center">
                  <TabList>
                    {props.children?.children?.map(
                      (childTab: TabData, index: number) => (
                        <CustomTab key={`child-tab${index}`}>
                          {childTab}
                        </CustomTab>
                      )
                    )}
                  </TabList>
                </Stack>
              </Tabs>
            )}
          </Flex>
        </Flex>
      </>
    );
  };

  return (
    <Tabs
      index={tabIndex ?? 0}
      onChange={onTabsChange}
      orientation={props.orientation}
      variant={props.variant}
      alignItems="baseline">
      <TabList
        gap={
          orientation === SecondaryNavigationOrientation.HORIZONTAL ? '8px' : 0
        }>
        {data.map((tab: TabData, index: number) => (
          <>
            <CustomTab
              key={index}
              id={index}
              handleNestedTabClick={handleNestedTabClick}
              parentTab={tabIndex === index ? tab.label : ''}
              childTabIndex={_childTabIndex}
              handleChildTabsChange={handleChildTabsChange}>
              {tab}
            </CustomTab>
          </>
        ))}
      </TabList>
      <TabPanels>
        {data.map((tab: TabData, index: number) => (
          <TabPanel p={4} key={index}>
            {tab.content}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

SecondaryNavigation.defaultProps = {
  orientation: SecondaryNavigationOrientation.HORIZONTAL,
  variant: SecondaryNavigationVariants.LINE,
  tabColor: theme.colors.text['mediumEmphasis'],
  activeTabColor: theme.colors.accent[200],
  tabIndex: 0
};

SecondaryNavigation.displayName = 'SecondaryNavigation';
export { SecondaryNavigation };
