import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Organization from '../organizations/organization-component';
import UserList from '../users/user-list-component';
import ExternalApplicationUserList from '../external-application/external-app-users/external-app-user-list-component';
import ExternalAppUserForm from '../external-application/external-app-users/external-app-user-form-component';
import CaseConfigForm from '../case-config/case-config-form-component';
import './admin.scss';

import DictionaryTenantList from '../dictionary/dictionary-tenant-list-component';
import DictionaryHistory from '../dictionary/dictionary-history/dictionary-history-component';
import UserDetail from '../users/user-detail-component';
import DictionaryList from '../dictionary/dictionary-list-component';
import { Box, Grid } from '@chakra-ui/react';
import {
  SecondaryNavigation,
  SecondaryNavigationOrientation,
  SecondaryNavigationVariants
} from '../../components/secondary-navigation/secondary-navigation';
import {
  organizationTabs,
  ORGANIZATION_CONSTANTS
} from '../organizations/organization-constants';
import { styles } from './Admin-Styles';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from '../../actions/organization';
import DictionaryValueList from '../dictionary/dictionary-value-list/dictionary-value-list-component';
import { DictionaryUpload } from '../dictionary/dictionary-upload/dictionary-upload';
import RequestInProgress from '../dictionary/request-in-progress/request-in-progress.component';
import { DictionaryValueDetail } from '../dictionary/dictionary-value-detail';
import { KeyColumnSelection } from '../dictionary/column-metadata/key-column-select';
import { CrossMapSelect } from '../dictionary/column-metadata/cross-map-select';
import { CrossMapEdit } from '../dictionary/column-metadata/cross-map-edit';
import ExternalApplicationListV2 from '../external-application/external-application-list-component';
import { listCaseConfigurations, syncActions } from '../../actions/case-config';
import { caseConfig } from '../../reducers/state/case-config/case-config';
import AuditLog from '../audit-log/audit-log-component';
import { isCaseConfigDeny, hasResource } from '../../core/resources';
import Groups from '../groups/groups-component';
import AddGroup from '../groups/groups-add-form-component';
import GroupsDetail from '../groups/groups-detail-component';
import Features from '../features/features-component';
import { Action } from 'redux';

const getOrganizationTabs = (
  context: CMx.Context,
  caseConfigDisable: boolean
) => {
  let orgTabs = [...organizationTabs];
  return orgTabs?.map(tab => {
    if (tab.label === ORGANIZATION_CONSTANTS.CASE_CONFIG) {
      return { ...tab, disabled: caseConfigDisable };
    } else if (tab.label === ORGANIZATION_CONSTANTS.GROUPS) {
      return {
        ...tab,
        disabled: hasResource(context, ORGANIZATION_CONSTANTS.GROUPS)
      };
    }
    return tab;
  });
};

export const Admin: React.FC<AppProps.AdminScreenProps> = (props: any) => {
  const {
    navigateExternalApplication,
    navigateDictionaries,
    navigateCaseConfig,
    navigateGroups,
    navigateFeatures
  } = props;

  const ui = useSelector((state: CMx.ApplicationState) => state.ui);

  const { section, tabListItems } = useSelector(
    (state: CMx.ApplicationState) => state.caseConfig
  );

  const { context } = useSelector((state: CMx.ApplicationState) => state.ui);

  const selectedChildTab = tabListItems.find(tab => tab.id === section)
    ?.displayName;
  const getChildTab = organizationTabs
    .find(tab => tab.label === 'Case Config')
    ?.children?.findIndex(child => child.label === selectedChildTab);

  const caseConfigDisable = isCaseConfigDeny(
    ui.context.user.roles || [],
    ui.context.activeContext?.organizationName ?? ''
  );

  const [activeTab, setActiveTab] = useState<number>(0);
  const classes = styles();
  const history = useLocation();
  const isOrg = history?.pathname?.includes('orgs');
  const dispatch = useDispatch();

  useEffect(() => {
    if (history?.pathname) {
      const { pathname } = history;
      if (pathname.includes('groups')) {
        setActiveTab(1);
      } else if (pathname.includes('features')) {
        setActiveTab(2);
      } else if (pathname.includes('case-config')) {
        setActiveTab(3);
      } else if (pathname.includes('dictionaries')) {
        setActiveTab(4);
      } else if (pathname.includes('external-applications')) {
        setActiveTab(5);
      } else {
        setActiveTab(0);
      }
    }
  }, [history]);

  const handleExternalAppClick = () => {
    navigateExternalApplication(props.activeOrganization.id);
  };

  const handleCaseConfigClick = () => {
    navigateCaseConfig(props.activeOrganization.id);
    dispatch(syncActions.selectConfigSection(caseConfig.tabListItems[0].id));
  };

  const handleDictionariesClick = () => {
    const activeOrg = props.activeOrganization;

    const tenantItem: any = {
      tenantName: activeOrg.organizationName,
      key: activeOrg.tenantId,
      id: activeOrg.id,
      organizationCode: activeOrg.organizationCode
    };

    navigateDictionaries(tenantItem);
  };

  const handleSubOrganizationsClick = () => {
    const organization: CMxAPI.Organization = props.activeOrganization;
    const token: CMxCommonApp.BearerToken = props.auth;
    dispatch((navigate({ token, organization }) as any) as Action);
  };

  const handleGroupsClick = () => {
    navigateGroups(props.activeOrganization.id);
  };

  const handleFeaturesClick = () => {
    navigateFeatures('coder_config', props.activeOrganization.id);
  };

  const handleTabsChange = (e: number) => {
    setActiveTab(e);
    console.log(e, organizationTabs[e]);
    switch (organizationTabs[e]?.label) {
      case ORGANIZATION_CONSTANTS.EXTERNAL_APPS: {
        handleExternalAppClick();
        break;
      }
      case ORGANIZATION_CONSTANTS.DICTIONARIES: {
        handleDictionariesClick();
        break;
      }
      case ORGANIZATION_CONSTANTS.CASE_CONFIG: {
        handleCaseConfigClick();
        break;
      }
      case ORGANIZATION_CONSTANTS.TENANTS: {
        handleSubOrganizationsClick();
        break;
      }
      case ORGANIZATION_CONSTANTS.GROUPS: {
        handleGroupsClick();
        break;
      }
      case ORGANIZATION_CONSTANTS.FEATURES: {
        handleFeaturesClick();
        break;
      }
      default:
        console.log(
          `Selected tabIndex ${e} is not present in organizations tabs constants`
        );
        break;
    }
  };

  const handleNestedTabClick = (childIndex: number, parentLabel: string) => {
    const listItem = caseConfig.tabListItems[childIndex];
    if (listItem) {
      navigateCaseConfig(props.activeOrganization.id);
      dispatch(syncActions.selectConfigSection(listItem.id));
      const tenantId = props.activeOrganization.tenantId;
      const section = listItem.id;
      const serviceLine = props.serviceLine ?? { name: '', id: -1 };
      dispatch(
        (listCaseConfigurations({
          tenantId,
          section,
          activeTenantId: tenantId,
          serviceLine
        }) as any) as Action
      );
    }
  };

  return (
    <Box sx={classes.root}>
      {isOrg && (
        <Box sx={classes.tabs}>
          <SecondaryNavigation
            data={getOrganizationTabs(context, caseConfigDisable)}
            variant={SecondaryNavigationVariants.UNSTYLED}
            orientation={SecondaryNavigationOrientation.VERTICAL}
            tabIndex={activeTab}
            handleTabsChange={handleTabsChange}
            handleNestedTabClick={handleNestedTabClick}
            childTabIndex={getChildTab}
          />
        </Box>
      )}
      <Grid sx={isOrg ? classes.content : classes.usersContent}>
        <Switch>
          <Route path="/cmx/admin/orgs/" exact component={Organization} />
          <Route
            path="/cmx/admin/orgs/:id/(edit)"
            exact
            component={Organization}
          />
          <Route path="/cmx/admin/orgs/:id/groups" exact component={Groups} />
          <Route
            path="/cmx/admin/orgs/:id/groups/add"
            exact
            component={AddGroup}
          />
          <Route
            path="/cmx/admin/orgs/:id/groups/group/:id"
            exact
            component={GroupsDetail}
          />
          <Route
            path="/cmx/admin/orgs/:id/features"
            exact
            component={Features}
          />
          <Route
            path="/cmx/admin/orgs/:id/external-applications"
            exact
            component={ExternalApplicationListV2}
          />
          <Route
            path="/cmx/admin/orgs/:id/case-config"
            exact
            component={CaseConfigForm}
          />
          <Route
            path="/cmx/admin/orgs/:id/external-applications/:app"
            exact
            component={ExternalApplicationUserList}
          />
          <Route
            path="/cmx/admin/orgs/:id/external-applications/:app/users/add"
            exact
            component={ExternalAppUserForm}
          />
          <Route
            path="/cmx/admin/orgs/:id/external-applications/:app/users/edit"
            exact
            component={ExternalAppUserForm}
          />

          <Route
            path="/cmx/admin/orgs/:id/dictionaries/tenants"
            exact
            component={DictionaryTenantList}
          />
          <Route
            path="/cmx/admin/orgs/:id/dictionaries"
            exact
            component={DictionaryList}
          />
          <Route
            path="/cmx/admin/orgs/:id/dictionaries/dictionary/upload/new"
            exact
            component={DictionaryUpload}
          />
          <Route
            path="/cmx/admin/orgs/:id/dictionaries/dictionary/:id"
            exact
            component={DictionaryValueList}
          />
          <Route
            path="/cmx/admin/orgs/:id/dictionaries/dictionary/:id/metadata/keyColumn"
            exact
            component={KeyColumnSelection}
          />
          <Route
            path="/cmx/admin/orgs/:id/dictionaries/dictionary/:id/metadata/crossMaps"
            exact
            render={props => <CrossMapSelect {...props} viewOnly={false} />}
          />
          <Route
            path="/cmx/admin/orgs/:id/dictionaries/dictionary/:id/metadata/crossMaps/view_only"
            exact
            render={props => <CrossMapSelect {...props} viewOnly={true} />}
          />
          <Route
            path="/cmx/admin/orgs/:id/dictionaries/dictionary/:id/history"
            exact
            render={() => <DictionaryHistory />}
          />
          <Route
            path="/cmx/admin/orgs/:id/dictionaries/dictionary/:id/metadata/crossMaps/:columnName/(edit)"
            exact
            component={CrossMapEdit}
          />
          <Route
            path="/cmx/admin/orgs/:id/dictionaries/dictionary/:id/upload"
            exact
            component={DictionaryUpload}
          />
          <Route
            path="/cmx/admin/orgs/:id/dictionaries/:requestIds"
            exact
            component={RequestInProgress}
          />
          <Route
            path="/cmx/admin/orgs/:id/dictionaries/dictionary/:id/row/:caseId/(edit)"
            exact
            component={() => <DictionaryValueDetail addRow={false} />}
          />
          <Route
            path="/cmx/admin/orgs/:id/dictionaries/dictionary/:id/row/add"
            exact
            component={() => <DictionaryValueDetail addRow={true} />}
          />

          <Route path="/cmx/admin/users/" exact component={UserList} />
          <Route path="/cmx/admin/users/:id/" exact component={UserDetail} />
          <Route path="/cmx/admin/auditLog/" exact component={AuditLog} />
        </Switch>
      </Grid>
    </Box>
  );
};
