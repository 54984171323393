/// <reference path="../types.d.ts"

import { cmxLocation } from '@codametrix/ui-common';
import { push } from 'connected-react-router';
import actionCreatorFactory from 'typescript-fsa';
import asyncFactory from 'typescript-fsa-redux-thunk';
import { ServiceLine } from './action-types';
import { applyDefaultFilters, clearAllFilters } from './cases.action';
import { getEula, saveEula } from './eula';
import { navigateContext } from './ui';
import { fetchTableauToken } from './analytics';

const actionCreator = actionCreatorFactory();
const createAsync = asyncFactory(actionCreator);

const saveServiceLine = actionCreator<CMx.ServiceLine>(
  ServiceLine.SAVE_SERVICE_LINE
);

const chooseServiceLine = createAsync<
  CMx.chooseServiceLinePayload,
  void,
  Error
>(ServiceLine.CHOOSE_SERVICE_LINE, async (payload, dispatch: any) => {
  const { serviceLine, context, homepagePreference } = payload;
  dispatch(saveServiceLine(serviceLine));

  const location = cmxLocation.getLocation();
  if (location === '/eula/') {
    const eulaUpToDate = await getEula(
      context.user.id || -1,
      context.activeContext?.organizationId || -1
    );
    dispatch(saveEula(eulaUpToDate));
    dispatch(push(location));

    return;
  }

  dispatch(navigateContext({ context, homepagePreference }));
});

const navigateServiceLine = createAsync<
  CMx.navigateServiceLinePayload,
  void,
  Error
>(ServiceLine.NAVIGATE_SERVICE_LINE, async (payload, dispatch: any) => {
  const { choice, context, actionGroups } = payload;
  dispatch(saveServiceLine(choice));
  dispatch(clearAllFilters());
  dispatch(applyDefaultFilters(context));

  if (actionGroups.find(ag => ag.options[0].active === true)?.key === 'cases') {
    dispatch(push(`/cmx/cases/${choice.name.toLowerCase()}/`));
  } else if (
    actionGroups.find(ag => ag.options[0].active === true)?.key ===
    'tableau-dashboard'
  ) {
    dispatch(fetchTableauToken(choice));
    dispatch(push(`/cmx/tableau/`));
  }
});

export { chooseServiceLine, saveServiceLine, navigateServiceLine };
