import theme from '../../theme';

export const styles = () => ({
  searchBarGrid: {
    gap: '8px'
  },
  searchOptionGridItem: {
    colSpan: 1,
    paddingTop: '7px'
  },
  inputGridItem: {
    colStart: 2,
    colEnd: 3,
    alignItems: 'center'
  },
  select: {
    height: theme.space[40],
    width: '100%',
    textAlign: 'left',
    color: theme.colors.text['highEmphasis'],
    ...theme.textStyles['Body 1'],
    background: theme.colors.text['light'],
    border: `${theme.borders['1px']} ${theme.colors.stroke[200]}`,
    backgroundColor: theme.colors.text['light'],
    paddingLeft: theme.space[16],
    'max-width': theme.space[300],
    overflow: 'hidden',
    'min-width': theme.space[20]
  },
  input: {
    height: theme.space[40],
    paddingLeft: theme.space[32]
  },
  icon: {
    height: theme.space[40]
  }
});
