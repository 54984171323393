import { useToast, Box, Text, Grid } from '@chakra-ui/react';
import React from 'react';
import { useEffect } from 'react';
import { styles } from './toast.styles';
import { ReactComponent as CheckCircle } from '../../assets/images/check-circle.svg';
import { ReactComponent as Cancel } from '../../assets/images/cancel.svg';
import { ReactComponent as CancelCircle } from '../../assets/images/cancel-circle.svg';
import { ReactComponent as Info } from '../../assets/images/info.svg';
import { ToastPositions, ToastVariants } from '../../core/enums';
import theme from '../../theme';

export type ToastProps = {
  duration?: number;
  position?: ToastPositions;
  status?: string;
  feedback: CMxCommonApp.Feedback;
};

const Toast: React.FC<ToastProps> = props => {
  const { feedback, duration = 5000, position = ToastPositions.BOTTOM } = props;

  const toast = useToast();
  const classes = styles(feedback.status ?? ToastVariants.SUCCESS);

  const getIcon = (variant: string) => {
    switch (variant) {
      case ToastVariants.SUCCESS: {
        return (
          <CheckCircle
            width={theme.space[12]}
            height={theme.space[12]}
            fill={theme.colors.structural[0]}
          />
        );
      }
      case ToastVariants.INFO:
      case ToastVariants.WARNING: {
        return <Info fill={theme.colors.structural[0]} />;
      }
      case ToastVariants.ERROR: {
        return <CancelCircle fill={theme.colors.structural[0]} />;
      }
      default: {
        console.warn('Invalid variant ', variant);
        break;
      }
    }
  };

  useEffect(() => {
    if (feedback.message) {
      toast({
        duration: duration,
        position: position,
        status: 'error',
        isClosable: true,
        render: ({ onClose }) => {
          return (
            <Box sx={classes?.container}>
              <Grid sx={classes?.grid}>
                {getIcon(feedback.status ?? ToastVariants.SUCCESS)}
                <Text sx={classes?.text}>{feedback.message}</Text>
              </Grid>
              <Grid sx={classes?.icon} data-testId="close">
                <Cancel onClick={onClose} />
              </Grid>
            </Box>
          );
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedback]);

  return null;
};

Toast.displayName = 'Toast';
export { Toast };
