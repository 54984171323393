import theme from '../../../../theme';

export const styles = () => ({
  footer: {
    'padding-right': theme.space[16]
  },
  warning: {
    background: theme.colors.alert[500],
    'border-radius': '5px',
    color: 'white',
    padding: '10px 0px 10px 10px',
    'margin-bottom': '10px'
  },
  selected: {
    display: 'flex',
    alignItems: 'flex-start',
    background: theme.colors.accent[50],
    padding: '5px 5px 5px 5px',
    'border-radius': '3px',
    marginBottom: '10px'
  },
  unselected: {
    display: 'flex',
    marginBottom: '10px'
  },
  radioDescription: {
    flex: 'left'
  },
  radioButton: {
    marginLeft: '5px',
    marginRight: '10px',
    marginTop: '5px'
  },
  radioButtonContainer: {
    flex: 'left'
  },
  radioLabel: {
    fontSize: theme.textStyles.h4.fontSize,
    marginBottom: '5px'
  }
});
