import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ChakraProvider } from '@chakra-ui/react';
import theme from '../../theme';
// cmx
import CMx from '../cmx/cmx-component';
import Login from '../login/login-component';
import store, { history } from '../../store/store';
import '../../core/session-management';

import { isNewDesign } from '../../core/middleware/new-design-middleware';
// assets
import '@fontsource/roboto';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@codametrix/shared-views/dist/main.css';
import '../../styles/cmx.scss';

import { getConfig } from '../../actions/login.action';

function Main() {
  const [isNewDesign2, setIsNewDesign2] = useState(false);

  useEffect(() => {
    const fetchConfig = async () => {
      const { newDesign } = await getConfig();
      setIsNewDesign2(newDesign?.enabled);
    };
    fetchConfig();
  }, [isNewDesign2]);

  const routableApp = (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/cmx" component={CMx} />
          <Route
            exact
            path={[
              '/',
              '/contexts/',
              '/update-password/',
              '/forgot-password/',
              '/forgot-password/reset/',
              '/eula/',
              '/service-lines/',
              '/sso/:orgCode?'
            ]}
            component={Login}
          />
        </Switch>
      </ConnectedRouter>
    </Provider>
  );

  if (isNewDesign || isNewDesign2) {
    return <ChakraProvider theme={theme}>{routableApp}</ChakraProvider>;
  }
  return routableApp;
}

export default Main;
