import { Flex } from '@chakra-ui/react';
import { CMxComponents } from '@codametrix/ui-components/dist/types';
import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { syncActions } from '../../../actions/dictionary-values';
import { BreadCrumbsComponent } from '../../../components/breadcrumbs/breadcrumbsComponent';
import { LOAD_STATES } from '../../../reducers/common';
import { Form } from '../form';

const keyColumnField: CMxComponents.FieldDefinition = {
  label: `KeyColumn`,
  key: `columnIndex`,
  name: 'keyColumn',
  helpText:
    'The key column of the dictionary is used to identify a row as unique',
  placeholder: '',
  required: true,
  type: 'select',
  option: [],
  labelField: 'name',
  valueField: 'columnIndex'
};

const keyColumnFormButtons = [
  { buttonType: 'submit', buttonText: 'Next' },
  { buttonType: 'link', buttonText: 'cancel' }
];

const keyColumnForm: CMxComponents.FormDefintion = {
  fieldGroups: [
    {
      label: 'Select Key Column',
      fields: [keyColumnField]
    }
  ],
  buttons: keyColumnFormButtons
};

const insertKeyColumnOptions = (cols: CMxAPI.ColumnDefinition[]) => {
  keyColumnField.options = cols.map(col => {
    return {
      ...col,
      columnIndex: col.columnIndex.toString()
    };
  });
  return keyColumnForm;
};

const KeyColumnSelection = () => {
  const columnEditorState = useSelector((state: CMx.ApplicationState) => {
    return state.dictionaryValues.columnMetadataEditor;
  });

  const dispatch = useDispatch();

  const subformEnabler = () => {
    return columnEditorState.loadState !== LOAD_STATES.started;
  };

  const navigateNext = () => {
    const pathParts = window.location.pathname.split('/');
    const newRoute = pathParts.splice(pathParts.length);
    newRoute.push('crossMaps');
    dispatch(push(`${newRoute.join('/')}`));
  };

  const handleAction = (event: CustomEvent) => {
    const { detail } = event;
    if (detail.eventType === 'cancel') {
      const pathParts = window.location.pathname.split('/');
      pathParts.pop();
      pathParts.pop();
      dispatch(push(`${pathParts.join('/')}`));
    }
  };

  const handlePropChange = (prop: string, value: string) => {
    dispatch(syncActions.selectKeyColumn(parseInt(value)));
  };

  const columnIndex = columnEditorState.columnMetadata
    .find(col => col.keyColumnIndicator === true)
    ?.columnIndex.toString();

  const dataItem = { columnIndex };

  return (
    <>
      <Flex>
        <BreadCrumbsComponent />
      </Flex>
      <Form
        formDefinition={insertKeyColumnOptions(
          columnEditorState.columnMetadata
        )}
        dataItem={dataItem}
        title={'Column Metadata - Key Column'}
        inline={false}
        onPropChange={handlePropChange}
        enable={subformEnabler}
        onSave={navigateNext}
        onAction={handleAction}
        errors={{
          status: '',
          message: null,
          errors: [],
          fieldErrors: {}
        }}
      />
    </>
  );
};

export { KeyColumnSelection };
