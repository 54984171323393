/// <reference path="../types.d.ts" />

import InitialState from './initial-state';
import * as dictionary from '../actions/dictionary';
import { syncActions } from '../actions/dictionary';
import { LOAD_STATES } from './common';

import { jumpContext } from '../actions/contexts';
import { cmxReducerFactory } from './reducer-utils';
import * as dictionaryValueActions from '../actions/dictionary-values';
import * as dictionaryRequest from '../actions/dictionary-request.actions';

const reducer = cmxReducerFactory(InitialState.dictionary)
  .case(dictionary.listItems.async.started, state => {
    return {
      ...state,
      items: [],
      loadState: LOAD_STATES.initial
    };
  })
  .case(dictionary.listItems.async.failed, state => {
    return { ...state, loadState: LOAD_STATES.failed };
  })
  .case(dictionary.listItems.async.done, (state, { result }) => {
    const { content } = result;

    const { sortablePageable } = state;
    const sp = { ...sortablePageable, pageableDefinition: result };

    return {
      ...state,
      loadState: LOAD_STATES.done,
      items: content,
      sortablePageable: sp
    };
  })
  .case(syncActions.chooseOrg, (state, item) => {
    return { ...state, selectedOrg: item };
  })
  .case(syncActions.chooseDictionary, (state, item) => {
    return { ...state, selectedDictionaryId: item };
  })
  .case(
    dictionaryRequest.fetchRequestStatus.async.done,
    (state, { result: requestResponse }) => {
      return {
        ...state,
        selectedDictionaryId: requestResponse.dictionaryId ?? undefined
      };
    }
  )
  .case(dictionary.loadDictionaries.async.started, state => {
    return {
      ...state,
      sortablePageable: { ...InitialState.dictionary.sortablePageable },
      items: [],
      loadState: LOAD_STATES.initial
    };
  })
  .case(dictionary.loadDictionaries.async.failed, state => {
    return { ...state, loadState: LOAD_STATES.failed };
  })
  .case(dictionary.loadDictionaries.async.done, (state, { params }) => {
    let item: any = params;
    const path = [
      {
        displayName: item.tenantName,
        display: true,
        link: false,
        data: `/cmx/admin/orgs/${item.id}/dictionary/${item.organizationCode}/`
      }
    ];

    return { ...state, path, selectedOrg: params };
  })

  .case(dictionary.loadDictionary.async.started, state => {
    return { ...state, items: [], loadState: LOAD_STATES.started };
  })
  .case(dictionary.loadDictionary.async.failed, state => {
    return { ...state, loadState: LOAD_STATES.failed };
  })
  .case(dictionary.loadDictionary.async.done, (state, { result }) => {
    return { ...state, loadState: LOAD_STATES.done };
  })

  .cases([jumpContext.async.started], state => {
    return { ...InitialState.dictionary };
  })

  .case(dictionaryRequest.uploadDictionary.async.started, state => {
    return {
      ...state,
      loadState: LOAD_STATES.started
    };
  })
  // .case(
  //   dictionaryUpload.selectedDictionary,
  //   (state: CMx.DictionaryState, { dictionaryId }) => {
  //     return {
  //       ...state,
  //       loadState: LOAD_STATES.done,
  //       selectedDictionaryId: dictionaryId
  //     };
  //   }
  // )
  .case(
    dictionaryValueActions.listAssociatedDictionaries.async.done,
    (state, { result: associatedDictionaries }) => {
      return {
        ...state,
        associatedDictionaries
      };
    }
  )
  .case(syncActions.saveAssociatedDictionaries, (state, params) => {
    return {
      ...state,
      associatedDictionaries: params
    };
  });

export default reducer;
