// Runtime overrides are arguments/flags passed in as a query parameter.
// The structure of the runtimeOverrides is a JSON object that is converted
// to a string and encoded via the encodeURIComponent
// Example encoding process:
//  const runtimeConfig = { fileSystemId: 'c730b61e-38a4-4eb7-b2f6-f6f805ac03c0' }
//  const param = encodeURIComponent(JSON.stringify(runtimeConfig))
//  -> '%7B%22fileSystemId%22%3A%22c730b61e-38a4-4eb7-b2f6-f6f8058b03c0%22%7D'
// Used in URL:
//  <url-goes-here>?runtimeOverrides=%7B%22fileSystemId%22%3A%22c730b61e-38a4-4eb7-b2f6-f6f8058b03c0%22%7D

export const RUNTIME_OVERRIDE_KEY = 'runtimeOverrides';

const parseProps = (props: string, values: object = {}) => {
  try {
    const decoded = decodeURIComponent(props);
    const parsed = JSON.parse(decoded);
    values = { ...values, ...parsed };
  } catch (e) {
  } finally {
    return values;
  }
};

/**
 * Function to run once on page load to add runtime overrides.
 * Has a side effect that it will store runtime overrides in session
 * storage for page refreshes.  If you want to override the overrides,
 * you just set the value again in the URL and the latest value will take
 * effect.
 * @param searchString {string} - the query string from the URL.
 * @returns
 */
const getRuntimeOverrides = (searchString: string) => {
  // first hydrate runtime overrides with session storage values.
  // if session storage is null, the runtime overrides will simply be
  // empty.
  let runtimeOverrides: any = parseProps(
    `${sessionStorage.getItem(RUNTIME_OVERRIDE_KEY)}`
  );
  // second, allow any newly passed properties to take precedence.
  const search = new URLSearchParams(searchString);
  if (search.has(RUNTIME_OVERRIDE_KEY)) {
    runtimeOverrides = parseProps(
      `${search.get(RUNTIME_OVERRIDE_KEY)}`,
      runtimeOverrides
    );
  }
  return runtimeOverrides;
};

// In the application this is run once at page load.
const runtimeOverrides = getRuntimeOverrides(window.location.search.slice(1));
// we need to make this available to included libraries
//@ts-ignore
window.runtimeOverrides = runtimeOverrides;

export { runtimeOverrides, getRuntimeOverrides };
