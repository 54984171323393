import React from 'react';

const TopLevelError: React.FC<CMx.InlineErrorProps> = (
  props: CMx.InlineErrorProps
) => {
  return props.errors && props.errors.length ? (
    <div className="toast toast-error"> {props.errors.join(' ')} </div>
  ) : (
    <span></span>
  );
};

export default TopLevelError;
