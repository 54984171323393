import { CMxComponents } from '@codametrix/ui-components/src/types';

import { defaultSortableOption } from '../../core/pageable-options';
import { defaultFilterableOptions } from '../../core/default-filterable-options';
import { LOAD_STATES } from '../common';

import { AmplifyCore } from '@codametrix/ui-common';
import { formErrors } from '../../stubs/form-errors';

const { paginationUtils } = AmplifyCore;

const sortablePageable: CMxCommonApp.SortablePageable<any> = {
  pageableDefinition: { ...paginationUtils.emptyPageable },
  sortableOptions: [{ ...defaultSortableOption }],
  filterableOptions: defaultFilterableOptions
};

const columnDefs: CMxComponents.ColumnDefintion[] = [
  {
    key: 'hospitalName',
    displayName: 'HOSPITAL NAME',
    isSortable: false,
    defaultSort: 'desc'
  }
];

export const assigningAuthorities: CMx.AssigningAuthorityState = {
  formErrors: formErrors.noErrors,
  label: 'Assigning Authorities',
  formDef: {},
  itemName: 'Assigning Authority',
  loadState: LOAD_STATES.done,
  pageable: paginationUtils.emptyPageable,
  sortablePageable,
  items: [],
  activeItem: [],
  columnDefs
};
