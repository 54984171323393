/** React/Redux imports */
import { connect } from 'react-redux';
import { buildDispatchable } from '../../../actions/_action-utilities';

/** Component imports */
import * as dictionaryValues from '../../../actions/dictionary-values';
import DictionaryHistory from './dictionary-history';

type dictionaryListDispatches = Pick<
  AppProps.DictionaryHistoryProps,
  'listHistory' | 'downloadDictionaryByRequestID'
>;

const mapDispatchToProps = (dispatch: any): dictionaryListDispatches => {
  const runDispatchable = buildDispatchable(dispatch);

  return {
    listHistory: (dictionaryId: Number) => {
      runDispatchable(() => {
        return dispatch(dictionaryValues.listDictionaryHistory(dictionaryId));
      });
    },
    downloadDictionaryByRequestID: (requestId: string) => {
      runDispatchable(() => {
        return dispatch(
          dictionaryValues.downloadDictionaryByRequestID({ requestId })
        );
      });
    }
  };
};

export default connect(() => {}, mapDispatchToProps)(DictionaryHistory);
