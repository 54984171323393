import { Box, Grid } from '@chakra-ui/react';
import { CMxComponents } from '@codametrix/ui-components/src/types';
import React, { useEffect } from 'react';
import LoaderOverlay from '../../components/loader-overlay/loader-overlay';
import { Form } from '../dictionary/form';
import { styles } from './case-config-form.styles';

const getDisplayName = (tabsList: CMxComponents.TabDef[], section: string) =>
  tabsList.find(tab => tab.id === section)?.displayName;

const CaseConfigForm: React.FC<AppProps.CaseConfigFormProps> = props => {
  const classes = styles();
  const {
    activeTenantId,
    formDef,
    dataObject,
    loadState,
    formErrors,
    tabListItems,
    caseConfigs,
    listCaseConfigs,
    navigateBack,
    tenantId,
    serviceLine,
    section,
    viewOnly
  } = props;

  useEffect(() => {
    listCaseConfigs(tenantId, activeTenantId, section, serviceLine);
  }, [activeTenantId, listCaseConfigs, section, serviceLine, tenantId]);

  const onSave = (data: { [key: string]: string }) => {
    const currentData = dataObject;
    let updatedConfigs: CMxAPI.CaseConfiguration[] = [];
    for (const [fieldKey, value] of Object.entries(data)) {
      const matchingConfig = caseConfigs.find(config => {
        return config.configurationKey === fieldKey;
      });

      if (
        currentData[fieldKey] !== value &&
        matchingConfig &&
        matchingConfig.configurationValues.length === 1
      ) {
        updatedConfigs.push({
          ...matchingConfig,
          configurationValues: [
            {
              ...matchingConfig.configurationValues[0],
              configurationValue: value.length ? value : null
            }
          ]
        });
      }
    }

    props.saveCaseConfig({
      tenantId: tenantId,
      caseConfigs: updatedConfigs,
      serviceLine: serviceLine
    });
  };

  const formEnabler = (data: any) => {
    if (viewOnly) {
      return false;
    }
    const currentData = dataObject;
    for (const [fieldKey, value] of Object.entries(data)) {
      if (currentData[fieldKey] !== value) {
        return true;
      }
    }
    return false;
  };

  return (
    <Box>
      {loadState.loading ? (
        <Grid sx={classes.loaderWrapper}>
          <LoaderOverlay />
        </Grid>
      ) : (
        <Form
          _className={classes.title}
          formDefinition={formDef}
          dataItem={dataObject}
          onAction={navigateBack}
          onSave={onSave}
          errors={formErrors}
          enable={formEnabler}
          inline={false}
          title={`Case config - ${getDisplayName(tabListItems, section)}`}
          showFieldSet={false}></Form>
      )}
    </Box>
  );
};

CaseConfigForm.displayName = 'CaseConfigForm';
export { CaseConfigForm };
