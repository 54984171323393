/**
 * Single run script to auto-adjust iframe content heights
 * with a special class name of "autosize-iframe-content".
 * When adding an iframe that should be auto-adjusted, add the
 * class name.
 */

const RESIZED_CLASSNAME = 'autosize-iframe-content';

const resizeIframe = (iframe: HTMLIFrameElement) => {
  // set the height to be the same as content.
  iframe.height = iframe?.contentWindow?.document.body.scrollHeight + 'px';
};

const adjustNewIframeContent = () => {
  // Callback function to handle mutations
  const callback: MutationCallback = mutationsList => {
    for (let mutation of mutationsList) {
      if (mutation.type === 'childList') {
        for (let node of mutation.addedNodes ?? []) {
          if (node instanceof HTMLElement) {
            // iframes are not being caught by the mutation observer,
            // so we need to check for them by querySelectorAll
            (node.querySelectorAll(`iframe.${RESIZED_CLASSNAME}`) as NodeListOf<
              HTMLIFrameElement
            >).forEach(resizeIframe);
          }
        }
      }
    }
  };

  // Options for the observer (which mutations to observe)
  const config: MutationObserverInit = { childList: true, subtree: true };

  // Create an observer instance linked to the callback function
  const observer = new MutationObserver(callback);

  // Start observing the target node for configured mutations
  // For this example, observe the entire document
  observer.observe(document.body, config);

  // Later, if you want to disconnect the observer
  // observer.disconnect();
};

document.addEventListener('DOMContentLoaded', adjustNewIframeContent);

// exported so that this file is a module
export { adjustNewIframeContent, resizeIframe };
