import theme from '../../theme';

export const styles = () => {
  const mainGrid = {
    background: theme.colors.text['highEmphasis'],
    color: theme.colors.text['light'],
    ...theme.textStyles['Body 1'],
    borderColor: theme.colors['stroke']['200'],
    borderRadius: theme.radii[3],
    transform: `translateX(-8px) !important`,
    width: 'max-content',
    '.chakra-popover__body': {
      pr: 0
    },
    marginTop: theme.space[44],
    minHeight: theme.space[152],
    minWidth: theme.space[240]
  };

  const divider = {
    borderTop: `${theme.borders['1px']} ${theme.colors['stroke']['300']}`
  };

  const orgChevronBox = {
    color: theme.colors['foundation']['300'],
    borderRadius: theme.radii[3],
    background: theme.colors.opacity['secondary'],
    w: theme.space[16],
    h: theme.space[16],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };
  const switchItem = {
    alignItems: 'center',
    pb: theme.space[8],
    pl: theme.space[8],
    pr: theme.space[8],
    h: theme.space[32]
  };

  const footerButton = {
    ml: theme.space[12],
    w: theme.space[80],
    h: theme.space[32],
    '.chakra-popover__close-btn': {
      position: `inherit !important`,
      right: 'auto'
    }
  };

  const popoverHeader = {
    '.chakra-popover__header': {
      borderBottomWidth: 0
    }
  };

  const headerGrid = {
    borderBottomWidth: 0,
    pt: theme.space[12],
    pl: theme.space[12]
  };
  const usernNameAvatar = {
    w: theme.space[32],
    h: theme.space[32],
    bg: theme.colors.foundation[400],
    borderColor: theme.colors.stroke[100],
    border: `${theme.borders['1px']}`,
    color: theme.colors.text['light'],
    '.chakra-avatar__initials': {
      ...theme.textStyles['Subtitle 1']
    }
  };

  const headerFlex = {
    alignItems: 'center',
    marginBottom: theme.space[21]
  };

  const userHeader = {
    flexDirection: 'column',
    marginLeft: theme.space[8]
  };

  const userName = {
    marginBottom: theme.space[4]
  };

  const nameText = {
    ...theme.textStyles['Body 1'],
    color: theme.colors.text['light']
  };

  const userNameText = {
    ...theme.textStyles['Caption 2'],
    color: theme.colors.text['lowEmphasis']
  };

  const textStyles = {
    paddingLeft: theme.space[12]
  };

  return {
    '.mainGrid': mainGrid,
    '.divider': divider,
    '.orgChevronBox': orgChevronBox,
    '.switchItem': switchItem,
    '.footerButton': footerButton,
    '.popoverHeader': popoverHeader,
    '.headerGrid': headerGrid,
    '.usernNameAvatar': usernNameAvatar,
    '.headerFlex': headerFlex,
    '.userHeader': userHeader,
    '.userName': userName,
    '.userNameText': userNameText,
    '.nameText': nameText,
    '.textStyles': textStyles
  };
};
