import React from 'react';
import {
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react';
import theme from '../../theme';
import { styles } from './modal.styles';
type ModalProps = {
  modalHeader?: React.ReactChild;
  modalContent?: React.ReactChild;
  modalFooter?: React.ReactChild;
  size?: string;
  isOpen: boolean;
  showCloseIcon?: boolean;
  handleClose: () => void;
  width?: string;
  className?: string;
};

export const ModalComponent = (props: ModalProps) => {
  const {
    modalFooter,
    modalHeader,
    modalContent,
    size,
    isOpen,
    handleClose,
    showCloseIcon,
    width,
    className
  } = props;
  const classes = styles();
  return (
    <div>
      <Modal
        size={size}
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={handleClose}
        isCentered>
        <ModalOverlay />
        <ModalContent className={className} maxW={width ? width : '56rem'}>
          <ModalHeader margin={3} sx={classes.title}>
            {modalHeader}
          </ModalHeader>
          {showCloseIcon && <ModalCloseButton sx={classes.closeIcon} />}
          <ModalBody>{modalContent}</ModalBody>
          <Divider color={theme.colors.foundation[100]} />
          <ModalFooter margin={3}>{modalFooter}</ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

ModalComponent.displayName = 'ModalComponent';
