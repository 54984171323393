import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  Text,
  FormErrorMessage,
  NumberInput,
  NumberInputField
} from '@chakra-ui/react';
import theme from '../../theme';
import { CMxComponents } from '@codametrix/ui-components/src/types';
import React, { useState, useEffect } from 'react';
import { Checkbox } from '../../components/checkbox/checkbox';
import { DatePickerComponent } from '../../components/datepicker-component/datepicker-component';
import IconLabel from '../../components/icon-label/iconlabel';
import { Input } from '../../components/input/input';
import { Select } from '../../components/select/select';
import { InputSize } from '../../core/enums';
import { SubHeader } from '../organizations/subheader';
import { optionConverter } from './dictionary-utils';
import { styles } from './dictionary-styles';
import { ReactComponent as CalendarIcon } from '../../assets/images/calendar.svg';
import { cmxDateTime } from '@codametrix/ui-common';
import { InfoOutlineIcon } from '@chakra-ui/icons';

const hasError = (key: string, errors: any) => {
  return errors && key in errors;
};

const getInlineError = (errors: string[]) => {
  return errors.join(' ');
};

const displayErrorIfPresent = (
  key: string,
  errors: CMxCommonApp.FieldErrors
) => {
  return hasError(key, errors) ? getInlineError(errors[key]) : '';
};

const buildInlineMsg = (
  isError: boolean,
  field: CMxComponents.FieldDefinition,
  errors: CMxCommonApp.FieldErrors
) => {
  return (
    <FormControl isInvalid={isError}>
      {field.helpText && (
        <FormHelperText>
          <IconLabel
            leftIcon={<InfoOutlineIcon />}
            isLeft={true}
            label={field.helpText}
          />
        </FormHelperText>
      )}
      {isError && (
        <FormErrorMessage>
          <IconLabel
            isLeft={true}
            leftIcon={<InfoOutlineIcon />}
            label={displayErrorIfPresent(field.key, errors)}
          />
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

export type FormProps = {
  _className?: any;
  dataItem: CMxComponents.DataItem;
  enable: (state: CMxComponents.DataItem) => boolean;
  errors: CMxCommonApp.FormErrors;
  formDefinition: CMxComponents.FormDefintion;
  inline: boolean;
  onAction?: (event: CustomEvent) => void;
  onPropChange?: (prop: string, value: any) => void;
  onSave?: (data: any) => void;
  showFieldSet?: boolean;
  title?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const Form: React.FC<FormProps> = props => {
  const {
    children,
    _className,
    dataItem = {},
    errors,
    formDefinition,
    enable,
    onAction,
    onPropChange,
    onSave,
    showFieldSet = true,
    title
  } = props;

  const { fieldGroups = [], buttons = [], fields } = formDefinition;
  const classes = styles();
  const [data, setData] = useState<CMxComponents.DataItem>(dataItem);
  const errs = errors.fieldErrors;

  useEffect(() => {
    if (dataItem) setData(dataItem);
  }, [dataItem]);

  const handleInputChange = (
    event:
      | React.FormEvent<HTMLInputElement>
      | CustomEvent
      | React.ChangeEvent<HTMLInputElement>
  ) => {
    const src = event.target as HTMLInputElement;
    const { name = '', value = '' } = src;

    let val: string | boolean = value;
    if (src.getAttribute('type') === 'checkbox') {
      val = src.checked;
    }
    setData(prevState => ({
      ...prevState,
      [name]: val
    }));
    onPropChange && onPropChange(name, value);
  };

  const handleDateChange = (date: Date | null, name: string) => {
    setData(prevState => ({
      ...prevState,
      [name]: date
    }));
  };

  const handleNumberChange = (number: string | null, name: string) => {
    setData(prevState => ({
      ...prevState,
      [name]: number
    }));

    onPropChange && onPropChange(name, number);
  };

  const buildFormContent = (fieldGroup: CMxComponents.FieldGroup) =>
    fieldGroup?.fields?.map((field: CMxComponents.FieldDefinition) => {
      return buildField(field);
    });

  const buildField = (field: CMxComponents.FieldDefinition) => {
    const isError = hasError(field.key, errs);
    switch (field.type) {
      case 'text':
        return (
          <Input
            label={field.label}
            placeholder={field.label}
            size={InputSize.MD}
            isRequired={field.required}
            value={data[field.key]}
            isReadOnly={field.readonly}
            name={field.key}
            classes={{ root: classes.input, input: classes.inputField }}
            dataTestId={field.label}
            textInfo={field.helpText}
            onChange={handleInputChange}
            isError={hasError(field.key, errs)}
            textError={displayErrorIfPresent(field.key, errs)}
            isDisabled={field.isDisabled}
          />
        );
      case 'select':
        return (
          <Grid sx={classes.input}>
            {field.label && <Text sx={classes.label}>{field.label}</Text>}
            <Select
              items={optionConverter(field)}
              name={field.name}
              dataTestId={field.name}
              classes={{
                root: classes.input,
                button: classes.selectField
              }}
              value={data[field.key]}
              onChange={handleInputChange}
            />
            {buildInlineMsg(isError, field, errs)}
          </Grid>
        );
      case 'checkbox':
        return (
          <Grid sx={classes.input}>
            <Checkbox
              label={field.label ?? ''}
              name={field.key}
              isChecked={data[field.key] !== false}
              onChange={handleInputChange}
              value={data[field.key]}
              dataTestId={field.label}
              disabled={field.isDisabled}
            />
            {buildInlineMsg(isError, field, errs)}
          </Grid>
        );
      case 'date':
        return (
          <Grid sx={classes.input}>
            {field.label && <Text sx={classes.label}>{field.label}</Text>}
            <DatePickerComponent
              startDate={
                data[field.key]
                  ? new Date(
                      cmxDateTime.format(
                        data[field.key],
                        cmxDateTime.FORMATS.DATE
                      )
                    )
                  : null
              }
              endDate={null}
              label={field.label}
              showRange={false}
              classNames={{
                input: classes.datepickerInput,
                rightIcon: classes.inputField
              }}
              onSelection={date => {
                date && handleDateChange(date, field.key);
              }}
              selectsRange={false}
              iconRight={
                <CalendarIcon
                  height={theme.space[16]}
                  width={theme.space[16]}
                />
              }
            />
            {buildInlineMsg(isError, field, errs)}
          </Grid>
        );
      case 'number':
        const additionalProperties = { ...field.additionalProperties };
        return (
          <Grid sx={classes.input}>
            {field.label && <Text sx={classes.label}>{field.label}</Text>}
            <NumberInput
              {...additionalProperties}
              defaultValue={parseInt(field.placeholder ?? '')}
              size={InputSize.MD}
              isRequired={field.required}
              value={data[field.key]}
              isReadOnly={field.readonly}
              name={field.key}
              onChange={numAsString =>
                handleNumberChange(numAsString, field.key)
              }
              isDisabled={field.isDisabled}>
              <NumberInputField />
            </NumberInput>
          </Grid>
        );
      default:
        console.warn('Field type not supported');
        break;
    }
  };

  const handleLinkButtonClick = () => {
    onAction &&
      onAction(new CustomEvent('click', { detail: { eventType: 'cancel' } }));
  };

  const handleActionClick = () => {
    onSave && onSave(data);
  };

  const getActionBtnName = buttons.find(
    (btn: any) => btn.buttonType === 'submit'
  )?.buttonText;

  const getLinkBtnName =
    buttons.find((btn: any) => btn.buttonType === 'link')?.buttonText ?? '';

  return (
    <Box>
      {buttons && (
        <SubHeader
          actionName={getActionBtnName}
          onActionClick={handleActionClick}
          isActionDisabled={!enable(data)}
          linkButtonName={
            getLinkBtnName?.charAt(0).toUpperCase() + getLinkBtnName?.slice(1)
          }
          onLinkButtonClick={handleLinkButtonClick}
          title={title ?? ''}
          className={_className}
        />
      )}
      <Grid sx={classes.fieldsets}>
        {fieldGroups?.map((fieldGroup: CMxComponents.FieldGroup) =>
          showFieldSet ? (
            <fieldset style={classes.fieldset}>
              <legend style={classes.legend}>{fieldGroup.label}</legend>
              <Grid sx={classes.grid}>{buildFormContent(fieldGroup)}</Grid>
            </fieldset>
          ) : (
            <Grid sx={classes.formContent}>{buildFormContent(fieldGroup)}</Grid>
          )
        )}
        {fields?.map((field: CMxComponents.FieldDefinition) => {
          return buildField(field);
        })}
        {children}
      </Grid>
    </Box>
  );
};

Form.displayName = 'Form';
export { Form };
