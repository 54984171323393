import { connect } from 'react-redux';
import {
  worklistMapDispatchToProps,
  worklistMapStateToProps
} from './service-desk-component';
import {
  mapUpdatedDispatchToCodeBenchProps,
  mapUpdatedStateToCodeBenchProps
} from '../../views/accelerate/code-bench-component';
import { ServiceDeskAndCodeBenchv2 } from './service-desk-and-codebenchv2';

const stateMapper = (state: CMx.ApplicationState) => {
  return {
    listProps: worklistMapStateToProps(state),
    detailProps: mapUpdatedStateToCodeBenchProps(state)
  };
};
const dualDispatchMapper = (dispatch: any) => {
  return {
    listMethods: worklistMapDispatchToProps(dispatch),
    detailMethods: mapUpdatedDispatchToCodeBenchProps(dispatch)
  };
};

export const ConnectedServiceDeskAndCodeBenchv2 = connect(
  stateMapper,
  dualDispatchMapper
)(ServiceDeskAndCodeBenchv2);
