import { HttpMethods } from '@codametrix/ui-common';
import { goBack } from 'connected-react-router';
import actionCreatorFactory from 'typescript-fsa';
import asyncFactory from 'typescript-fsa-redux-thunk';
import { api } from '../core/net';
import { ExternalApplications } from './action-types';
import { showFeedback } from './ui';
import { AmplifyCore } from '@codametrix/ui-common';

const { paginationUtils } = AmplifyCore;

const actionCreator = actionCreatorFactory();
const createAsync = asyncFactory<CMxAPI.ExternalApplication>(actionCreator);

const selectExternalApplication = actionCreator<CMxAPI.ExternalApplication>(
  ExternalApplications.SELECT_EXTERNAL_APP
);

const selectExternalAppUser = actionCreator<CMxAPI.ExternalApplicationUser>(
  ExternalApplications.SELECT_EXTERNAL_APP_USER
);

const selectOrgnization = actionCreator<number>(
  ExternalApplications.SELECT_ORG_EXTERNAL_APP
);

const setStateToDefault = actionCreator<CMxAPI.ExternalApplicationUser>(
  ExternalApplications.SET_STATE_TO_DEFAULT
);

const listExternalApps = createAsync<
  AppProps.listParams,
  CMxAPI.PageableList<CMxAPI.ExternalApplication>,
  CMxCommonApp.SubmitError
>(ExternalApplications.LIST_EXTERNAL_APPS, async (params, dispatch) => {
  const { sortablePageable, id } = params;

  var searchParams = paginationUtils.searchParamsFactory(sortablePageable);

  const pageableList = await api<
    CMxAPI.PageableList<CMxAPI.ExternalApplication>
  >({
    endpoint: `/externalApplication/list/v1?organizationId=${id}&${searchParams.toString()}`,
    init: {
      method: HttpMethods.GET
    }
  });

  return pageableList;
});

const listExternalAppUsers = createAsync<
  AppProps.listParams,
  CMxAPI.PageableList<CMxAPI.ExternalApplicationUser>,
  CMxCommonApp.SubmitError
>(ExternalApplications.LIST_EXTERNAL_APP_USERS, async (params, dispatch) => {
  const { sortablePageable, id } = params;

  var searchParams = paginationUtils.searchParamsFactory(sortablePageable);
  const pageableList = await api<
    CMxAPI.PageableList<CMxAPI.ExternalApplicationUser>
  >({
    endpoint: `/externalApplication/users/list/v1?externalApplicationName=${id}&${searchParams.toString()}`,
    init: {
      method: HttpMethods.GET
    }
  });

  return pageableList;
});

const saveExternalAppUser = createAsync<
  CMxAPI.ExternalApplicationUser,
  CMxAPI.ExternalApplicationUser,
  CMxCommonApp.SubmitError
>(ExternalApplications.SAVE_EXTERNAL_APP_USER, async (params, dispatch) => {
  const saved = await api<CMxAPI.ExternalApplicationUser>({
    endpoint: `/externalApplication/assign/v1`,
    init: {
      method: HttpMethods.POST
    },
    body: params
  });

  dispatch(
    showFeedback({
      id: Date.now(),
      message: `User Saved.`,
      dismissable: true,
      className: 'feedback-working'
    })
  );

  dispatch(goBack());
  return saved;
});

export const syncActions = {
  selectExternalApplication,
  selectExternalAppUser,
  selectOrgnization,
  setStateToDefault
};
const actions = { listExternalApps, listExternalAppUsers, saveExternalAppUser };
export default actions;
