import React, { useState, useRef } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { SubHeader } from '../organizations/subheader';
import { Button } from '../../components/button/button';
import { ButtonSize } from '../../core/enums';
import { ButtonVariant } from '../../core/enums';
import { Grid } from '@chakra-ui/react';

import { useSelector } from 'react-redux';
import { BreadCrumbsComponent } from '../../components/breadcrumbs/breadcrumbsComponent';
import Input from '../../components/input/input';
import { styles } from './groups-detail-styles';
import { styles as dictionaryStyles } from '../dictionary/dictionary-styles';

import { GroupMembers } from './group-member-selection';
import { Select } from '../../components/select/select';
import { Confirm } from '@codametrix/shared-views';

import {
  eligibleGroupTypeRoleIds,
  groupTypeIds,
  groupTypes
} from './groups-constants';

const GroupsDetail: React.FC<AppProps.GroupsDetailProps> = props => {
  const classes = styles();
  const dictionaryClasses = dictionaryStyles();
  const {
    saveGroup,
    deleteGroup,
    dispatchNavigation,
    eligibleGroupMembers,
    addGroupMember
  } = props;

  const item: CMxAPI.Group = useSelector((appState: CMx.ApplicationState) => {
    return appState.group.selectedGroup;
  });

  const [selectedGroup, setSelectedGroup] = useState(item);
  const [deleteModalDisplayed, setDeleteDialogDisplayed] = useState(false);

  const handleInputChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.MouseEvent<HTMLElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const target = event.target as HTMLInputElement | HTMLSelectElement;
    const { value, name } = target;
    setSelectedGroup({ ...selectedGroup, [name]: value });
  };

  const _handleFormSubmit = () => {
    saveGroup(selectedGroup);
  };

  const _confirmDelete = () => {
    if (selectedGroup.id) {
      deleteGroup(selectedGroup.id);
    } else {
      throw new Error(
        'User attempted to delete group but group has not been created and delete button should not be displayed!'
      );
    }
  };

  const _handleDelete = () => {
    setDeleteDialogDisplayed(true);
  };

  const anchorRef = useRef<any>(null);

  const anchorButtonClick = () => {
    anchorRef.current.click();
  };

  const eligibleGroupMemberList = () => {
    const isCqaGroup = selectedGroup.groupType === groupTypeIds.CQA_GROUP;

    return [
      {
        groupTitle: isCqaGroup ? 'CQA Members' : 'Coding Members',
        groupRoleId: isCqaGroup
          ? eligibleGroupTypeRoleIds.CQA_CODER
          : eligibleGroupTypeRoleIds.CODER
      },
      {
        groupTitle: isCqaGroup ? 'CQA Managers' : 'Coding Managers',
        groupRoleId: isCqaGroup
          ? eligibleGroupTypeRoleIds.CQA_MANAGER
          : eligibleGroupTypeRoleIds.CODING_MANAGER
      }
    ];
  };

  return (
    <Grid sx={classes.root}>
      <Confirm
        onConfirm={_confirmDelete}
        onDismiss={() => {
          setDeleteDialogDisplayed(false);
        }}
        display={deleteModalDisplayed}
        strings={{ proceed: 'Delete', dismiss: 'Cancel' }}
        title="Are you sure you want to permanently delete this group?"
      />
      <Flex>
        <Button
          size={ButtonSize.MD}
          name={'back'}
          variant={ButtonVariant.SECONDARY}
          label={'Return to group list'}
          onClick={anchorButtonClick}
        />
        <a
          hidden
          ref={anchorRef}
          href={'/cmx/admin/orgs/:id/groups'}
          data-testid={'back'}
          onClick={dispatchNavigation}
          style={{
            display: 'block',
            textAlign: 'left',
            paddingTop: '5px',
            color: '#E5927D',
            textDecoration: 'none',
            outline: 'none'
          }}>
          {'Back to group list'}
        </a>
      </Flex>
      <Flex marginBottom={5}>
        <BreadCrumbsComponent />
      </Flex>
      <Flex sx={classes.horizontal}>
        <SubHeader title={'Edit Group'} className={classes.text} />
        <Flex sx={classes.buttonGroup}>
          {selectedGroup.id && (
            <Button
              size={ButtonSize.MD}
              name={'save'}
              variant={ButtonVariant.PRIMARY}
              label={'Save'}
              onClick={_handleFormSubmit}
            />
          )}
          {selectedGroup.id && (
            <Button
              size={ButtonSize.MD}
              name={'back'}
              variant={ButtonVariant.SECONDARY}
              label={'Delete This Group'}
              onClick={_handleDelete}
            />
          )}
        </Flex>
      </Flex>
      <Grid sx={classes.fieldsets}>
        <Flex sx={classes.groupinformation}>
          <Input
            isRequired={true}
            onChange={handleInputChange}
            label={'Group Name'}
            placeholder={'Enter group name'}
            name={'groupName'}
            dataTestId={'groupNameInput'}
            value={selectedGroup.groupName}
            isReadOnly={false}
          />
          <Grid>
            <Text sx={dictionaryClasses.label}>Group Type</Text>
            <Select
              onChange={handleInputChange}
              name={'groupType'}
              dataTestId={'grouptypeInput'}
              items={groupTypes}
              value={selectedGroup.groupType}
              disabled={selectedGroup !== undefined}
            />
          </Grid>
        </Flex>
        <Flex style={{ flexDirection: 'column' }}>
          {eligibleGroupMemberList().map(eligibleGroupMember => {
            return (
              <GroupMembers
                eligibleGroupMembers={eligibleGroupMembers}
                addGroupMember={addGroupMember}
                selectedGroup={selectedGroup}
                groupTitle={eligibleGroupMember.groupTitle}
                groupRoleId={eligibleGroupMember.groupRoleId}
              />
            );
          })}
        </Flex>
      </Grid>
    </Grid>
  );
};

GroupsDetail.displayName = 'GroupsDetail';
export { GroupsDetail };
