import { connect } from 'react-redux';
import {
  worklistMapDispatchToProps,
  worklistMapStateToProps
} from './service-desk-component';
import {
  mapUpdatedDispatchToCodeBenchProps,
  mapUpdatedStateToCodeBenchProps
} from '../../views/accelerate/code-bench-component';
import { ServiceDeskAndCodeBench } from './service-desk-and-codebench';

const stateMapper = (state: CMx.ApplicationState) => {
  return {
    listProps: worklistMapStateToProps(state),
    detailProps: mapUpdatedStateToCodeBenchProps(state)
  };
};
const dualDispatchMapper = (dispatch: any) => {
  return {
    listMethods: worklistMapDispatchToProps(dispatch),
    detailMethods: mapUpdatedDispatchToCodeBenchProps(dispatch)
  };
};

export const ConnectedServiceDeskAndCodeBench = connect(
  stateMapper,
  dualDispatchMapper
  //@ts-ignore mapUpdatedStateToCodeBenchProps does not completely map to Accelerate.CodeBenchPanelData;
)(ServiceDeskAndCodeBench);
