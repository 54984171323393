import { Box, Flex, Grid } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { BreadCrumbsComponent } from '../../../components/breadcrumbs/breadcrumbsComponent';
import { SubHeader } from '../../organizations/subheader';
import { styles } from './dictionary-upload.styles';
import Input from '../../../components/input/input';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'redux';
import { buildDispatchable } from '../../../actions/_action-utilities';
import { uploadDictionary } from '../../../actions/dictionary-request.actions';
import { goBack } from 'connected-react-router';
import { initUpload } from '../../../actions/dictionary-values';
import './dictionary-upload.scss';
import { LOAD_STATES } from '../../../reducers/common';
import { Button } from '../../../components/button/button';
import { CMxComponents } from '@codametrix/ui-components/src/types';

const DictionaryNameField: CMxCommonApp.FieldDefinition = {
  label: `Dictionary Name`,
  key: `dictionaryName`,
  helpText: 'The name of the dictionary',
  placeholder: '',
  required: true,
  type: 'text'
};

const DictionaryDescriptionField: CMxCommonApp.FieldDefinition = {
  label: `Dictionary Description`,
  key: `description`,
  helpText: 'Description of the dictionary',
  placeholder: '',
  required: false,
  type: 'text'
};

const DictionaryPurposeField: CMxCommonApp.FieldDefinition = {
  label: `Dictionary Purpose`,
  key: `purpose`,
  helpText: 'Purpose of the dictionary',
  placeholder: '',
  required: false,
  type: 'text'
};

const jiraTicketField: CMxCommonApp.FieldDefinition = {
  label: `Jira Ticket`,
  key: `jiraTicket`,
  helpText: 'Jira Ticket associated with this change',
  placeholder: '',
  required: true,
  type: 'text'
};

export const defaultMetadataForm: CMxCommonApp.FormDefintion = {
  fieldGroups: [
    {
      label: 'Dictionary Metadata',
      fields: [
        DictionaryNameField,
        DictionaryDescriptionField,
        DictionaryPurposeField
      ]
    }
  ],
  buttons: [
    {
      className: 'qa-add-button qa-upload-button',
      buttonType: 'submit',
      buttonText: 'Submit'
    },
    { buttonType: 'link', buttonText: 'cancel', link: '/cmx/admin/users' }
  ]
};

export const metadataFormJiraField: CMxCommonApp.FormDefintion = {
  fieldGroups: [
    {
      label: 'Dictionary Metadata',
      fields: [
        DictionaryNameField,
        DictionaryDescriptionField,
        DictionaryPurposeField,
        jiraTicketField
      ]
    }
  ],
  buttons: [
    {
      className: 'qa-add-button qa-upload-button',
      buttonType: 'submit',
      buttonText: 'Submit'
    },
    { buttonType: 'link', buttonText: 'cancel', link: '/cmx/admin/users' }
  ]
};

export const DictionaryUpload = () => {
  const classes = styles();

  const dictionaryValuesState = useSelector((state: CMx.ApplicationState) => {
    return state.dictionaryValues;
  });

  const dictionaryRequestState = useSelector((state: CMx.ApplicationState) => {
    return state.dictionaryRequest;
  });

  const loadState = useSelector((state: CMx.ApplicationState) => {
    return state.dictionaryRequest.loadState;
  });

  const chosenTenantId: CMxAPI.Guid = useSelector(
    (state: CMx.ApplicationState) => {
      return { uuid: state.dictionary.selectedOrg.key };
    }
  );

  const dispatch = useDispatch();
  const runDispatchable = buildDispatchable(dispatch);
  const [file, changeFile] = useState<File | undefined>(undefined);
  const [dictionaryData, setDictionaryData] = useState<{
    [key: string]: string;
  }>({
    description: dictionaryRequestState.dictionaryMetadata.description,
    purpose: dictionaryRequestState.dictionaryMetadata.purpose,
    dictionaryName: dictionaryRequestState.dictionaryMetadata.dictionaryName
  });
  const inputRef = useRef<any>(null);

  useEffect(() => {
    dispatch(
      (initUpload({
        ...dictionaryValuesState.selectedDictionary,
        tenantId: { uuid: chosenTenantId.uuid }
      }) as any) as Action
    );
  }, [dispatch, chosenTenantId.uuid, dictionaryValuesState.selectedDictionary]);

  useEffect(() => {
    setDictionaryData({
      description: dictionaryRequestState.dictionaryMetadata.description,
      purpose: dictionaryRequestState.dictionaryMetadata.purpose,
      dictionaryName: dictionaryRequestState.dictionaryMetadata.dictionaryName
    });
  }, [dictionaryRequestState]);

  const onFileInput = (fileEvent: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = fileEvent.target;
    if (files !== null && files.length && files[0]) {
      changeFile(files[0]);
    }
  };

  const onSubmit = () => {
    if (file !== undefined) {
      runDispatchable(() => {
        return dispatch(
          (uploadDictionary({
            dictionaryCSV: file,
            successRoute: window.location.pathname
              .split('/')
              .slice(0, 6)
              .join('/'),
            isCreate: dictionaryRequestState.isUploadCreate,
            ...dictionaryRequestState.dictionaryMetadata,
            description: dictionaryData.description,
            purpose: dictionaryData.purpose,
            dictionaryName: dictionaryData.dictionaryName,
            columnDefinitionMetadata:
              dictionaryRequestState.dictionaryMetadata
                .columnDefinitionMetadata,
            isCorrection: dictionaryValuesState.isCorrection,
            jiraTicket: dictionaryData.jiraTicket ?? null
          }) as any) as Action
        );
      });
    }
  };

  const handleModalInputChange = (
    event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLElement>
  ) => {
    const target = event.target as HTMLInputElement;
    const { value, name } = target;
    console.log('Changing input:', name, 'to:', value); // Debugging line
    setDictionaryData({ ...dictionaryData, [name]: value });
  };

  const handleAction = () => {
    dispatch(goBack());
  };

  const validateJiraTicket = (jiraTicket: string) => {
    const jiraTicketRegex = /^[a-zA-Z]+-\d+$/;
    return dictionaryValuesState.isCorrection
      ? !jiraTicket && !jiraTicketRegex.test(jiraTicket)
      : false;
  };

  const enabler = () => {
    return (
      dictionaryData?.dictionaryName === '' ||
      file === undefined ||
      loadState === LOAD_STATES.started ||
      loadState === LOAD_STATES.initial ||
      validateJiraTicket(dictionaryData?.jiraTicket)
    );
  };

  const header = !dictionaryValuesState.isCorrection
    ? 'Upload Dictionary'
    : 'Make a Correction';

  const fields = dictionaryValuesState.isCorrection
    ? metadataFormJiraField.fieldGroups
    : defaultMetadataForm.fieldGroups;

  const fileUpload = () => {
    inputRef.current.click();
  };

  return (
    <Box>
      <BreadCrumbsComponent />
      <SubHeader
        title={header}
        linkButtonName={'Cancel'}
        onLinkButtonClick={handleAction}
        actionName={'Submit'}
        onActionClick={onSubmit}
        isActionDisabled={enabler()}
      />
      <Grid sx={classes.fieldsets}>
        {fields?.map(item => {
          return (
            <fieldset style={classes.fieldset}>
              <legend style={classes.legend}>{item.label}</legend>
              <Flex
                flexDirection={'column'}
                rowGap={5}
                width={'33%'}
                sx={classes.contentSpacing}>
                {item.fields.map((field: CMxComponents.FieldDefinition) => {
                  return (
                    <Input
                      label={field.label}
                      type={'text'}
                      onChange={handleModalInputChange}
                      placeholder={`Enter ${field.label}`}
                      name={field.key}
                      textInfo={field.helpText ?? ''}
                      isRequired={field.required}
                      dataTestId={field.label}
                      value={dictionaryData[field.key]}
                    />
                  );
                })}
              </Flex>
            </fieldset>
          );
        })}
        <fieldset style={classes.fieldset}>
          <legend style={classes.legend}>File</legend>
          <Flex sx={classes.contentSpacing} alignItems={'center'}>
            <Button label={'Choose File'} onClick={fileUpload} />
            <input
              hidden
              ref={inputRef}
              type="file"
              onChange={onFileInput}
              multiple={false}
            />
            <div style={classes.fileName}>{file?.name}</div>
          </Flex>
        </fieldset>
      </Grid>
    </Box>
  );
};
