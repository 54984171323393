import { Flex, Stack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CheckboxOption } from '../../components/checkbox-menu/checkbox-menu';
import { styles } from '../../components/checkbox-menu/checkbox-menu.styles';
import { styles as treeStyles } from './checkbox-tree.styles';
import { Checkbox } from '../../components/checkbox/checkbox';
import theme from '../../theme';

type CheckboxTreeProps = {
  options: CheckboxOption;
  handleCheckboxChange: (option: CheckboxOption) => void;
  selected: CheckboxOption[];
  handleSelectAllChildren: (option: CheckboxOption) => void;
  handleDeselectAllChildren: (option: CheckboxOption) => void;
};

const CheckboxTree = (props: CheckboxTreeProps) => {
  const classes = styles();
  const treeClasses = treeStyles();
  const {
    options,
    handleCheckboxChange,
    selected,
    handleSelectAllChildren,
    handleDeselectAllChildren
  } = props;

  const [parentChecked, setParentChecked] = useState(false);

  useEffect(() => {
    if (selected.length === 0 && parentChecked) {
      setParentChecked(prevState => !prevState);
    }
  }, [parentChecked, selected]);

  const getCheckboxItem = (item: CMxCommonApp.FieldDefinition) => {
    return {
      label: item.helpText ?? '',
      value: item.key ?? ''
    };
  };

  return (
    <>
      <div style={{ cursor: 'pointer' }} data-testid="parentCheckbox">
        <Checkbox
          isChecked={parentChecked}
          label={options.label}
          iconColor={theme.colors.opacity.primary}
          checkboxColor={
            parentChecked
              ? theme.colors.accent[100]
              : theme.colors.foundation[300]
          }
          textColor={theme.colors.text['mediumEmphasis']}
          onClick={() => {
            parentChecked
              ? handleDeselectAllChildren(options)
              : handleSelectAllChildren(options);

            setParentChecked(prevState => !prevState);
          }}
        />
      </div>
      <Stack pl={5} mt={1} spacing={1} alignContent="center">
        {options?.children?.map(getCheckboxItem)?.map((option, idx) => (
          <Flex
            key={idx}
            sx={Object.assign(
              {},
              classes.MenuItem,
              treeClasses.checkboxWrapper,
              selected.find(o => o.value === option.value) !== undefined
                ? classes.selectedMenuItem
                : {}
            )}
            data-testid={`childCheckbox${idx}`}>
            <Checkbox
              isChecked={
                selected.find(o => o.value === option.value) !== undefined
              }
              onClick={() => {
                if (parentChecked) setParentChecked(false);
                handleCheckboxChange(option);
              }}
              label={option.label ?? ''}
              iconColor={theme.colors.opacity.primary}
              checkboxColor={
                selected.find(o => o.value === option.value) !== undefined
                  ? theme.colors.accent[100]
                  : theme.colors.foundation[300]
              }
              textColor={theme.colors.text['mediumEmphasis']}
            />
          </Flex>
        ))}
      </Stack>
    </>
  );
};

CheckboxTree.displayName = 'CheckboxTree';

export { CheckboxTree };
