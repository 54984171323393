import { Middleware, MiddlewareAPI } from 'redux';

/**
 * Defines a middleware function that takes the middleware API, the next middleware function, and the current action, and returns a value.
 *
 * @param api The middleware API, which provides access to the store's dispatch and getState methods.
 * @param next The next middleware function in the chain.
 * @param action The current action being processed.
 * @returns The result of the middleware function.
 */
export type MiddlewareProcessor = (
  api: MiddlewareAPI,
  next: Function,
  action: any
) => any;

/**
 * Wraps a middleware processor function in a Redux middleware function.
 *
 * The returned middleware function takes the Redux middleware API, the next middleware function in the chain, and the current action, and passes them to the provided middleware processor function.
 *
 * @param fn The middleware processor function to wrap.
 * @returns A Redux middleware function that calls the provided middleware processor function.
 */
export const middleWareWrapper = (fn: MiddlewareProcessor): Middleware => {
  return (api: MiddlewareAPI) => (next: Function) => (action: any) => {
    return fn(api, next, action);
  };
};
