import {
  Box,
  Flex,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  Portal,
  Stack,
  Text
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { ButtonVariant } from '../../core/enums';
import { Button } from '../button/button';
import { styles } from './org-servicelines.styles';
import './org-servicelines.scss';

export type OrganizationServiceLineProps = {
  context: CMx.Context;
  actionGroups: CMxAPI.NavActionGroup[];
  handleOrganizationChoice: (orgChoice: CMx.OrganizationFormChoice) => void;
  handleServiceLineChoice: (
    serviceLineChoice: CMx.ServiceLine,
    context: CMx.Context,
    actionGroups: CMxAPI.NavActionGroup[]
  ) => void;
  onSelection: (selction: CMxAPI.NavAction) => void;
  activeOrg: string;
  activeUser: CMxAPI.User;
  serviceLine?: CMx.ServiceLine;
  openAccountSettings: () => void;
  setSwitcherOpened: React.Dispatch<React.SetStateAction<boolean>>;
};

export const OrganizationServiceline: React.FC<OrganizationServiceLineProps> = ({
  onSelection,
  actionGroups,
  activeOrg,
  handleOrganizationChoice,
  handleServiceLineChoice,
  context: cmxContext,
  activeUser,
  serviceLine,
  context,
  openAccountSettings,
  setSwitcherOpened,
  ...props
}) => {
  const classes = styles();

  const { activeContext, contexts } = cmxContext;

  const [serviceLines, setServiceLines] = useState(
    activeContext?.serviceLines || []
  );

  const [selectedOrgItem, setSelectedOrgItem] = useState<number>(
    activeContext?.organizationId ?? -1
  );

  const [selectedServicelineItem, setSelectedServicelineItem] = useState<
    number
  >(serviceLine?.id ?? -1);
  const UNSELECTED_ORG = -1;
  const UNSELECTED_SERVICE_LINE = -1;

  const orgSelectionWrapper = (orgId: number = UNSELECTED_ORG) => {
    setSelectedOrgItem(orgId);
    setSelectedServicelineItem(-1);
    contexts.forEach(ctx => {
      if (ctx.organizationId === orgId) {
        setServiceLines(ctx.serviceLines);
      }
    });
  };

  const servicelineSelectionWrapper = (
    serviceLineId: number = UNSELECTED_SERVICE_LINE
  ) => {
    setSelectedServicelineItem(serviceLineId);
  };

  const handleCancel = () => {
    setSelectedOrgItem(activeContext?.organizationId ?? UNSELECTED_ORG);
    setServiceLines(activeContext?.serviceLines || []);
    setSelectedServicelineItem(serviceLine?.id ?? UNSELECTED_SERVICE_LINE);
  };

  const handleApply = async () => {
    let choice: CMx.OrganizationFormChoice;
    if (selectedOrgItem !== UNSELECTED_ORG) {
      if (selectedServicelineItem !== UNSELECTED_SERVICE_LINE) {
        choice = {
          organizationId: selectedOrgItem,
          servicelineId: selectedServicelineItem,
          reload: true
        };
      } else {
        choice = {
          organizationId: selectedOrgItem,
          reload: true
        };
      }
      handleOrganizationChoice(choice);
      setSwitcherOpened(false);
    }
  };

  const isOrgSelected = (
    currentOrgId: number,
    selectedOrganizationItem: number,
    index: any
  ): string => {
    const currentOrgSelectionCheck =
      currentOrgId === selectedOrganizationItem ? 'selectedItem' : '';
    const firstOrgCheck = index === 0 ? 'selectedItem' : '';
    return selectedOrganizationItem !== UNSELECTED_ORG
      ? currentOrgSelectionCheck
      : firstOrgCheck;
  };

  const isServicelineSelected = (
    currentServicelineId: number,
    selectedServicelineItemId: number,
    index: any
  ): string => {
    const currentServicelineCheck =
      currentServicelineId === selectedServicelineItemId ? 'selectedItem' : '';
    const firstServicelineCheck = index === 0 ? 'selectedItem' : '';
    return selectedServicelineItemId !== UNSELECTED_SERVICE_LINE
      ? currentServicelineCheck
      : firstServicelineCheck;
  };

  return (
    <Portal>
      <PopoverContent sx={classes['.mainGrid']} className="popOverStyle">
        <PopoverHeader sx={classes['.headerGrid']}>
          <Flex alignItems="center">
            <Text>
              {contexts.length === 1
                ? activeContext?.displayName
                : 'My Organizations'}
            </Text>
          </Flex>
        </PopoverHeader>
        <PopoverBody sx={classes['.bodyGrid']}>
          <Stack direction="row">
            {contexts.length > 1 ? (
              <Box sx={classes['.popoverOrgBox']}>
                {contexts.map((ctx, index) => {
                  return (
                    <Flex
                      sx={classes['.switchItem']}
                      key={ctx.organizationId}
                      onClick={() => {
                        orgSelectionWrapper(ctx.organizationId);
                      }}
                      className={`hoverCursor ${isOrgSelected(
                        ctx.organizationId,
                        selectedOrgItem,
                        index
                      )}`}>
                      <Text>
                        {ctx.displayName && ctx.displayName !== ''
                          ? ctx.displayName
                          : ctx.shortDisplayName && ctx.shortDisplayName !== ''
                          ? ctx.shortDisplayName
                          : ctx.organizationCode}
                      </Text>
                    </Flex>
                  );
                })}
              </Box>
            ) : (
              <></>
            )}
            {serviceLines.length !== 0 ? (
              <Box sx={classes['.popoverServicelineBox']}>
                {serviceLines.map((sl, index) => {
                  return (
                    <Flex
                      sx={classes['.switchItem']}
                      key={sl.id}
                      onClick={() => {
                        servicelineSelectionWrapper(sl.id);
                      }}
                      className={`hoverCursor ${isServicelineSelected(
                        sl.id,
                        selectedServicelineItem,
                        index
                      )}`}>
                      <Text>{sl.name}</Text>
                    </Flex>
                  );
                })}
              </Box>
            ) : (
              <Box
                sx={classes['.popoverServicelineBox']}
                display="flex"
                justifyContent="center"
                alignItems="center">
                <Text>No service line available</Text>
              </Box>
            )}
          </Stack>
        </PopoverBody>
        <PopoverFooter sx={classes['.popoverFooter']}>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <PopoverCloseButton
              sx={classes['.footerButton']}
              position="inherit"
              right="auto"
              p="auto">
              <Button
                label="Cancel"
                variant={ButtonVariant.SECONDARY}
                sx={classes['.footerButton']}
                onClick={handleCancel}></Button>
            </PopoverCloseButton>
            <Button
              label="Apply"
              sx={classes['.footerButton']}
              onClick={handleApply}
            />
          </Box>
        </PopoverFooter>
      </PopoverContent>
    </Portal>
  );
};

OrganizationServiceline.displayName = 'OrganizationServiceline';
