import {
  authenticationContext,
  sessionManagement,
  ActivityMonitor
} from '@codametrix/ui-common';

const { activityMonitor } = sessionManagement;

export const getServiceDeskClient = () => {
  //@ts-ignore
  const xcaliberClient = XcaliberServiceDesk.init({
    theme: {},
    bffURL: '/api/service-desk/bff',
    getBearerToken: () => {
      const token = authenticationContext.getToken();
      //Check if token already has text Bearer, appened only if it not present
      if (token && token.indexOf('Bearer') !== -1) {
        return Promise.resolve(`${token}`);
      }
      return Promise.resolve(`Bearer ${token}`);
    }
  });

  activityMonitor.on(ActivityMonitor.events.DID_REFRESH, async () => {
    if (xcaliberClient) {
      xcaliberClient.refreshToken();
    }
  });
  return xcaliberClient;
};
