import React, { useEffect, useState } from 'react';
import { Flex, Input, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, RoundedButton } from '@codametrix/ui-components';

import { ModalComponent } from '../../../components/Modal/modal';
import { Button } from '../../../components/button/button';

import {
  toggleEmailConfirmationModal,
  confirmEmail,
  getConfig
} from '../../../actions/login.action';
import { styles } from './confirm-email-modal.styles';
import './confirm-email-modal.scss';
import { isNewDesign } from '../../../core/middleware/new-design-middleware';
import { Action } from 'redux';

export const ConfirmEmailModal = () => {
  const classes = styles();
  const dispatch = useDispatch();

  const [isNewDesign2, setIsNewDesign2] = useState<boolean>(isNewDesign);

  const { username, auth } = useSelector(
    (state: CMx.ApplicationState) => state.login
  );

  const { context } = useSelector((state: CMx.ApplicationState) => state.ui);

  const [confirmationCode, editConfirmationCode] = useState<string>('');

  const _handleClose = () => {
    dispatch(toggleEmailConfirmationModal(false));
  };

  const _handleSubmit = () => {
    const loginData = {
      username: username ?? '',
      confirmationCode,
      token: auth,
      context
    };
    dispatch((confirmEmail(loginData) as any) as Action);
  };

  useEffect(() => {
    const fetchConfig = async () => {
      const { newDesign } = await getConfig();
      setIsNewDesign2(newDesign?.enabled);
    };
    fetchConfig();
  }, [isNewDesign2]);

  return isNewDesign ? (
    <ModalComponent
      data-test-id="confirm-email-modal"
      size={'xl'}
      isOpen={true}
      handleClose={_handleClose}
      showCloseIcon={true}
      modalHeader={<p>Account Migration Required</p>}
      modalContent={
        <Flex flexDirection={'column'}>
          <Text sx={classes.label}>
            Existing accounts need to be migrated to our new authentication
            platform.
          </Text>
          <Text sx={classes.label}>
            You should receive an email with a confirmation code, please enter
            it here. This should be a one-time process.
          </Text>
          <div className="form-group">
            <Input
              sx={classes.input}
              className="form-input confirmation-code-field"
              maxLength={6}
              type="text"
              name="confirmation-code"
              onChange={e => editConfirmationCode(e.target.value)}
            />
          </div>
        </Flex>
      }
      modalFooter={
        <>
          <Button
            dataTestId="saveBtn"
            label="Confirm"
            onClick={_handleSubmit}
          />
        </>
      }
    />
  ) : (
    <Modal onClose={_handleClose} title="Account Migration Required">
      <div className="confirm-email-modal">
        <p>
          Existing accounts need to be migrated to our new authentication
          platform.
        </p>
        <p>
          You should receive an email with a confirmation code, please enter it
          here.
        </p>
        <div className="form-group">
          <div className="input">
            <input
              className="form-input confirmation-code-field"
              data-test-id="confirm-email-modal"
              maxLength={6}
              type="text"
              name="confirmation-code"
              onChange={e => editConfirmationCode(e.target.value)}
            />
          </div>
          <div className="buttons">
            <RoundedButton
              className="btn-sm active confirm-btn"
              type="submit"
              title="Confirm"
              onClick={_handleSubmit}>
              Confirm
            </RoundedButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};
