import { commonActions, appServiceLines } from '@codametrix/ui-common';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { api } from '../core/net';

const { Case } = commonActions;

const actionCreator = actionCreatorFactory();
const createAsync = asyncFactory<CMx.CaseState>(actionCreator);

type caseAndCurrentProcess = {
  caseDetail: CMxAPI.Case;
  processId: number;
};

type BPMStepIds = {
  caseId: number | string;
  processInstanceId: number | string;
};

const bpmTasksAvailable = actionCreator<Record<string, CMxAPI.BPMStep[]>>(
  Case.BPM_EVIDENCE
);

const caseDetailAvailable = actionCreator<caseAndCurrentProcess>(Case.DETAIL);

const activeProcessId = actionCreator<number>(Case.SET_ACTIVE_PROCESS_ID);

const getBPMDetails = createAsync<BPMStepIds, void, CMxCommonApp.SubmitError>(
  Case.DETAIL,
  async (step, dispatch) => {
    const url = new URL(window.location.toString());
    const serviceLine = url.searchParams.get('serviceLine') ?? '';
    const serviceLineConfig = appServiceLines.determineServiceLineConfig({
      name: serviceLine
    });

    const caseParams = new URLSearchParams(serviceLineConfig.caseDetailParms);

    const expandedCaseDetail: CMxAPI.Case = await api<CMxAPI.Case>({
      endpoint: `${serviceLineConfig.path}/caseinstance/${
        step.caseId
      }/details/${serviceLineConfig.caseDetailVersion}?${caseParams.toString()}`
    });

    const processId =
      typeof step.processInstanceId === 'string'
        ? parseInt(step.processInstanceId)
        : step.processInstanceId;

    const caseAndProcessId = {
      caseDetail: expandedCaseDetail,
      processId: processId
    };

    dispatch(caseDetailAvailable(caseAndProcessId));

    const evidence: Record<string, CMxAPI.BPMStep[]> = await api<
      Record<string, CMxAPI.BPMStep[]>
    >({
      endpoint: `${serviceLineConfig.path}/caseinstance/${step.caseId}/v1/evidence/process`
    });

    dispatch(bpmTasksAvailable(evidence));
  }
);

export {
  bpmTasksAvailable,
  activeProcessId,
  getBPMDetails,
  caseDetailAvailable
};
