/// <reference path="../types.d.ts" />

import InitialState from '../reducers/initial-state';
import * as analytics from '../actions/analytics';
import { refreshContext } from '../actions/contexts';
import { LOAD_STATES } from './common';
import { configAvailable } from '../actions/ui';
import { cmxReducerFactory } from './reducer-utils';

const reducer = cmxReducerFactory(InitialState.analytics)
  .case(analytics.instantiateDashboard, (state, params) => {
    return {
      ...state,
      loadState: LOAD_STATES.started
    };
  })
  .case(configAvailable, (state, userConfig) => {
    const { tableauInfo } = userConfig;
    return {
      ...state,
      tableauInfo
    };
  })
  .cases(
    [refreshContext.async.started, analytics.fetchTableauToken.async.started],
    state => {
      return {
        ...state
      };
    }
  )
  .cases([analytics.fetchTableauToken.async.failed], (state, loaded) => {
    return {
      ...state,
      loadState: LOAD_STATES.failed
    };
  })
  .case(
    analytics.fetchTableauToken.async.done,
    (state, { result: tableauToken }) => {
      return {
        ...state,
        tableauToken
      };
    }
  )
  .case(analytics.setActiveDashboard, (state, boardName) => {
    return {
      ...state,
      activeDashboard: boardName
    };
  });

export default reducer;
