export const buildRoleSet = (context: CMx.Context) => {
  const {
    user: { roles }
  } = context;
  const roleSet: string[] = [];
  (roles ?? []).forEach(role => {
    return role.roles.forEach(r => {
      if (context.activeContext?.tenantId === role.tenantId)
        roleSet.push(r.roleName);
    });
  });
  return roleSet;
};
