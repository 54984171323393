import React, {
  useEffect,
  useCallback,
  useState,
  useMemo,
  useRef
} from 'react';
import { Grid, GridItem } from '@chakra-ui/react';
import theme from '../../../theme';
import './dictionary-value-list.scss';
import { ReactComponent as DeleteIcon } from '../../../assets/images/deleteV2.svg';
import {
  createDataGridRows,
  filterStatusFields
} from '../../../reducers/state/dictionary-values';
import { DataGrid } from '../../../components/data-grid/data-grid';
import { ColDef } from 'ag-grid-community';
import { CMxComponents } from '@codametrix/ui-components/src/types';
import * as dictionaryValueUtils from './dictionary-value-utils';
import { faUpload, faLink } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { DictionaryValueListHeader } from './dictionary-value-list-header';
import { styles } from '../dictionary-styles';
import { commonEnums as enums } from '@codametrix/ui-common';
import { InfoModal } from './infoModal/info-modal';
import { SecurityModal } from './securityModal/security-modal';
import { DeleteModal } from './deleteModal/delete-modal';
import {
  getDictionaryFilters,
  listColumnValues
} from '../../../actions/dictionary-values';
import { CustomDictionaryValueHeader } from './custom-dictionary-value-list-header';

import { ArchiveModal } from './archiveModal/archive-modal';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'redux';
import { PublishModal } from './publishModal/publish-modal';

const { FilterTypes } = enums;

library.add(faUpload, faLink);

const classes = styles();

const updatedColDefs = (columnDefs: CMxComponents.ColumnDefintion[]) => {
  const cols: ColDef[] = columnDefs.map(
    (col: CMxComponents.ColumnDefintion) => {
      let column: ColDef = {
        ...col,
        headerName: col.displayName,
        field: col.key,
        sortable: false
      };
      if (col.key === 'remove') {
        column = {
          ...column,
          cellRenderer: () => {
            return (
              <div style={classes.columnIcon}>
                <DeleteIcon />
              </div>
            );
          }
        };
      }
      return column;
    }
  );
  return cols;
};

const DictionaryValueList: React.FC<AppProps.DictionaryValueListProps> = props => {
  const {
    onSelection,
    columnDefs,
    sortablePageable,
    list,
    dictionaryId,
    associatedDictionaries,
    resetStateToDefault,
    viewOnly,
    selectedDictionary,
    criteriaFilter,
    navigate,
    toggleInfoModal,
    isInfoModalOpen,
    toggleUploadType,
    publish,
    download,
    toggleDeleteModal,
    isDeleteModalOpen,
    isArchiveModalOpen,
    selectArchiveRow,
    toggleSecurityModal,
    isSecurityModalOpen,
    togglePublishModal,
    isPublishModalOpen
  } = props;

  const dispatch = useDispatch();

  const [colDefs, setColDefs] = useState<ColDef[]>([]);
  const [filterableOptions, setFilterableOptions] = useState<
    CMxCommonApp.FilterableOption
  >(sortablePageable.filterableOptions);
  const [activeDictionaryId, setActiveDictionaryId] = useState<number>();
  const [isUnrestrictedDictionary, setIsUnrestrictedDictionary] = useState<
    boolean
  >(selectedDictionary.unrestrictedIndicator);

  const sortablePageableRef = useRef<CMxCommonApp.SortablePageable<any>>(
    sortablePageable
  );

  useEffect(() => {
    setIsUnrestrictedDictionary(selectedDictionary.unrestrictedIndicator);
  }, [selectedDictionary.unrestrictedIndicator]);

  useEffect(() => {
    sortablePageableRef.current = sortablePageable;
  }, [sortablePageable]);

  useEffect(() => {
    if (selectedDictionary.crossMapIndicator) {
      let mappedCols = selectedDictionary.columnDefinitions.filter(
        colDef => colDef.cellReferences?.length
      );
      mappedCols.forEach(
        colDef => dispatch(listColumnValues(colDef) as any) as Action
      );
    }
  }, [selectedDictionary, dispatch]);

  const showDraft = useSelector((appState: CMx.ApplicationState) => {
    return appState.dictionaryValues.showDraft;
  });

  useEffect(() => {
    // if should show draft rows, set filter
    if (showDraft && criteriaFilter.filters) {
      criteriaFilter.filters[0].terms[0] = 'draft';
    }
  }, [showDraft, criteriaFilter]);

  const searchForms = useMemo(
    () =>
      criteriaFilter?.searchForms.map(filterForm => {
        return {
          label: filterForm.label,
          value: filterForm.key
        };
      }),
    [criteriaFilter]
  );
  const [selectedOption, setSelectedOption] = useState<string>(
    searchForms?.[0]?.label ?? ''
  );
  const [searchText, setSearchText] = useState<string>('');
  const [pageSize, setPageSize] = useState<number>(25);

  const {
    filterStatusToStatus,
    determineStatus,
    DictionaryStatus,
    endpoint,
    uploadItems
  } = dictionaryValueUtils;

  useEffect(() => {
    //clean up - clear columnDefs of the dictionary value in the redux state when component unmounts
    return () => {
      const defaultData: any = {
        colDefs: []
      };
      resetStateToDefault && resetStateToDefault(defaultData);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (columnDefs?.length) {
      const coldefs = updatedColDefs(columnDefs);
      setColDefs(coldefs);
    }
  }, [columnDefs]);

  useEffect(() => {
    setSelectedOption(searchForms?.[0]?.label ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchForms?.[0]?.label]);

  const generateNewFilterList = () => {
    const newFilterableOptions: CMxCommonApp.FilterableOption = {};
    let newFilterList: CMxCommonApp.Filter[] = [];
    const selectedOptionKey: string =
      searchForms.find(searchForm => searchForm.label === selectedOption)
        ?.value ?? '';

    newFilterList = criteriaFilter.filters.filter(filter => {
      return filter.type === FilterTypes.IN;
    });

    const SearchFormMatch = criteriaFilter.searchForms.find(filter => {
      return filter.key === selectedOptionKey;
    });

    if (SearchFormMatch) {
      const newFilter: CMxCommonApp.Filter = {
        key: selectedOptionKey,
        terms: [searchText.trim()],
        type: SearchFormMatch.type
      };
      newFilterList.push(newFilter);
    }

    newFilterableOptions.filters = newFilterList;
    return newFilterableOptions;
  };

  const handleSearchKeyPress = (
    event?: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event?.keyCode === 13) {
      const filterableOption: CMxCommonApp.FilterableOption = generateNewFilterList();
      setFilterableOptions(filterableOption);
    }
  };

  const handleSearchOptionChange = (event?: React.MouseEvent<HTMLElement>) => {
    const target = event?.currentTarget as HTMLButtonElement;
    setSelectedOption(target?.value);
  };

  const handleUnrestrictChange = () => {
    setIsUnrestrictedDictionary(true);
  };

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchText(event?.target?.value);
  };

  const handleList = useCallback(
    (sp: CMxCommonApp.SortablePageable<any>) => {
      const statusFilter =
        filterStatusToStatus[
          determineStatus(sortablePageableRef.current)[0] ||
            DictionaryStatus.PUBLISHED
        ];
      const activeDictionaryId =
        associatedDictionaries.find(dictionary => {
          return dictionary.status === statusFilter;
        })?.id || dictionaryId;

      const listParams: AppProps.listParams = {
        sortablePageable: sp,
        id: activeDictionaryId?.toString() || ''
      };
      setActiveDictionaryId(activeDictionaryId);
      return listParams;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [associatedDictionaries, dictionaryId, sortablePageable]
  );

  /** useEffect to trigger the columnDefs api */
  useEffect(() => {
    const params = handleList({ ...sortablePageableRef.current });
    list(params);
    getDictionaryFilters(Number(params.id) ?? -1, {
      ...sortablePageableRef.current
    }).then(data => {
      setFilterableOptions({ filters: data });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortablePageable]);

  const handleRowSelection = (rowItem: any) => {
    const targetColumnId = rowItem?.api?.getFocusedCell()?.column.getColId();
    if (targetColumnId === 'remove') {
      selectArchiveRow(rowItem.data.id);
    } else {
      const params: AppProps.actionParams<number> = {
        path: window.location.pathname,
        data: rowItem.data.rowIndex
      };
      onSelection(params);
    }
  };

  const hasUnsupportedStatusFilter = (filters: CMxCommonApp.Filter[]) => {
    const unsupportedFieldKeys = filterStatusFields
      .filter(field => field.downloadSupported === false)
      .map(field => field.key);
    const statusFilter = filters.find(filter => filter.key === 'status');

    const unsupportedFilters = statusFilter?.terms.filter(term =>
      unsupportedFieldKeys.includes(term)
    );

    if (unsupportedFilters && unsupportedFilters.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const hasNoDraftFilter = (filters: CMxCommonApp.Filter[]) => {
    return (
      filters.filter(filter => filter.terms.includes('draft')).length === 0
    );
  };

  const hasKeyColumn: boolean =
    selectedDictionary?.columnDefinitions?.find(
      col => col.keyColumnIndicator === true
    ) !== undefined;

  const downloadDisabled =
    hasUnsupportedStatusFilter(
      sortablePageableRef.current?.filterableOptions.filters || []
    ) || sortablePageableRef.current.pageableDefinition.content.length === 0;

  const publishDisabled =
    sortablePageableRef.current.pageableDefinition.content.length === 0 ||
    !hasKeyColumn ||
    viewOnly ||
    hasNoDraftFilter(
      sortablePageableRef.current?.filterableOptions.filters || []
    );

  const metadataDisabled =
    sortablePageableRef.current.pageableDefinition.content.length === 0 ||
    viewOnly ||
    hasUnsupportedStatusFilter(
      sortablePageableRef.current?.filterableOptions.filters || []
    );

  /*
   * Method used for navigate to a history for a directory
   * @return void
   */
  const historyNavigation = () => {
    if (navigate) {
      navigate(window.location.pathname + '/history');
    }
  };

  const handleDownload = () => {
    if (selectedDictionary !== undefined) {
      const isDraftFilter =
        determineStatus(sortablePageable)?.includes(DictionaryStatus.DRAFT) ||
        false;

      const payload: CMx.dictionaryDownloadPayload = {
        dictionaryName: selectedDictionary.name,
        tenantId: selectedDictionary.tenantId.uuid,
        withDraft: isDraftFilter,
        effectiveDate: selectedDictionary.effectiveDate ?? '',
        updatedDate: selectedDictionary.updatedDate ?? ''
      };

      download(payload);
    }
  };

  const handleDeleteSelect = () => {
    toggleDeleteModal(!isDeleteModalOpen);
  };

  const metadataNavigation = () => {
    const path = viewOnly
      ? window.location.pathname + '/metadata/crossMaps/view_only'
      : window.location.pathname + '/metadata/keyColumn';
    if (navigate) {
      navigate(path);
    }
  };
  const handleApiResponse = useCallback((result: any) => {
    const dataGridRows = createDataGridRows(result.content);
    const sp = {
      ...sortablePageableRef.current,
      pageableDefinition: {
        ...result,
        items: dataGridRows
      }
    };
    const data = {
      items: dataGridRows,
      pageable: result
    };
    resetStateToDefault && resetStateToDefault(data);
    sortablePageableRef.current = sp;
    return dataGridRows;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
  };

  return (
    <>
      <Grid
        flexDirection={'row'}
        gridTemplateRows={'min-content min-content min-content'}
        gap={theme.space[12]}>
        <GridItem>
          <DictionaryValueListHeader
            uploadItems={uploadItems}
            deleteDisabled={viewOnly}
            downloadDisabled={downloadDisabled}
            metadataDisabled={metadataDisabled}
            publishDisabled={publishDisabled}
            handleDownload={handleDownload}
            handleDeleteSelect={handleDeleteSelect}
            uploadDisabled={viewOnly}
            handleSearchKeyPress={handleSearchKeyPress}
            handleSearchOptionChange={handleSearchOptionChange}
            handleSearchInputChange={handleSearchInputChange}
            searchForms={searchForms}
            selectedOption={selectedOption}
            handleHistoryClick={historyNavigation}
            handleMetadataClick={metadataNavigation}
            toggleInfoModal={toggleInfoModal}
            isInfoModalOpen={isInfoModalOpen}
            togglePublishModal={togglePublishModal}
            isPublishModalOpen={isPublishModalOpen}
            toggleSecurityModal={toggleSecurityModal}
            isSecurityModalOpen={isSecurityModalOpen}
            viewOnly={viewOnly}
            toggleUploadType={toggleUploadType}
            navigate={navigate}
            criteriaFilter={criteriaFilter}
            sortablePageable={sortablePageable}
            setFilterableOptions={setFilterableOptions}
            isUnrestrictedDictionary={isUnrestrictedDictionary}
            pageSize={pageSize}
            onPageSizeChange={onPageSizeChange}
          />
        </GridItem>
        <GridItem>
          {colDefs.length > 0 && (
            <DataGrid
              columnDefs={colDefs}
              endpointData={endpoint(activeDictionaryId)}
              serverSideInfiniteScroll
              filterableOptions={filterableOptions}
              onRowClicked={item => {
                handleRowSelection(item);
              }}
              contentFilter={handleApiResponse}
              paginationPageSize={pageSize}
              customHeader={CustomDictionaryValueHeader}
            />
          )}
        </GridItem>
      </Grid>
      {isInfoModalOpen ? (
        <InfoModal
          selectedDictionary={selectedDictionary}
          viewOnly={viewOnly}
        />
      ) : null}
      {isSecurityModalOpen ? (
        <SecurityModal
          isDictionaryUnrestricted={isUnrestrictedDictionary}
          onUnrestrictChange={handleUnrestrictChange}
          dictionaryId={selectedDictionary.id}
        />
      ) : null}
      {isDeleteModalOpen ? <DeleteModal /> : null}
      {isArchiveModalOpen ? <ArchiveModal /> : null}
      {isPublishModalOpen ? (
        <PublishModal
          publish={publish}
          associatedDictionaries={associatedDictionaries}
        />
      ) : null}
    </>
  );
};

DictionaryValueList.displayName = 'DictionaryValueList';
export { DictionaryValueList };
