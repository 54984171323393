import React, { useEffect } from 'react';
import BreadCrumbsComponent from '../breadcumbs/breadcrumbs';
import { Form } from '../dictionary/form';
import { objectUtils } from '@codametrix/ui-common';

const includeHolds: CMxCommonApp.FieldDefinition = {
  key: 'include_cases_on_holds',
  name: 'include_cases_on_holds',
  label: 'Include Cases on Hold',
  type: 'checkbox',
  isSelected: false
};

const agedCasesDefinition: CMxCommonApp.FieldDefinition = {
  key: 'cases_older_than',
  name: 'cases_older_than',
  label: 'Definition of Aged Cases',
  placeholder: '0',
  required: true,
  type: 'number',
  readonly: false
};

const maxAllowedDays: CMxCommonApp.FieldDefinition = {
  key: 'max_allowed_days',
  name: 'max_allowed_days',
  label: 'Max Allowed Backdate Days',
  placeholder: '0',
  required: true,
  type: 'number',
  readonly: false,
  additionalProperties: {
    defaultValue: 30,
    min: 0,
    max: 90
  }
};

const buttons = [
  { buttonType: 'submit', buttonText: 'Save' },
  { buttonType: 'link', buttonText: 'cancel' }
];

const defaultSettings: { [key: string]: any } = {
  include_cases_on_holds: false,
  cases_older_than: '',
  allow_backdating: false,
  max_allowed_days: 0
};

type FeaturesProps = {
  features: CMxAPI.ConfigurationValue[] | undefined;
  handleCancel: () => void;
  handleSave: (item: any) => void;
  errors: CMxCommonApp.FormErrors;
};

export const Features = (props: FeaturesProps) => {
  const { handleCancel, errors, handleSave, features } = props;

  const buildData = (features: CMxAPI.ConfigurationValue[]) => {
    let featuresData: { [keyname: string]: any } = {};
    let value;
    features?.forEach(feature => {
      const { configurationKeyname } = feature;
      if (['include_cases_on_holds'].includes(configurationKeyname)) {
        // Convert string booleans to just boolean
        value = feature.value === 'true';
      } else {
        value = feature.value;
      }
      featuresData[configurationKeyname] = value;
    });
    return featuresData;
  };

  const initialSettings =
    features && features.length > 0 ? buildData(features) : defaultSettings;

  const [selectedSettings, setSelectedSettings] = React.useState(
    initialSettings
  );

  const _handleSave = () => {
    let configurationValues: CMxAPI.ConfigurationValue[] = [];
    Object.keys(selectedSettings).forEach(keyname => {
      const configValue = features?.find(
        val => val.configurationKeyname === keyname
      );
      if (configValue && configValue.value !== selectedSettings[keyname]) {
        if ((configValue.value === 'true') !== selectedSettings[keyname]) {
          configurationValues.push({
            ...configValue,
            value: selectedSettings[keyname]
          });
        }
      }
    });

    handleSave(configurationValues);
  };

  useEffect(() => {
    setSelectedSettings(
      features && features.length > 0 ? buildData(features) : defaultSettings
    );
  }, [features]);

  const ProductivityTracker = {
    label: `ProductivityTracker`,
    fields: [includeHolds, agedCasesDefinition]
  };

  const TimeEntry = {
    label: `Time Entry`,
    fields: [maxAllowedDays]
  };

  const formDefinition = () => {
    const formDef: CMxCommonApp.FormDefintion = {
      fieldGroups: [ProductivityTracker, TimeEntry],
      buttons
    };
    return formDef;
  };

  const enableForm = (item: any) => {
    return (
      (item.productivity_tracker_enabled
        ? item.cases_older_than?.length > 0
        : true) && !objectUtils.isEqual(item, initialSettings)
    );
  };

  const handleFormChange = (prop: string, value: string) => {
    let settings = { ...selectedSettings };
    if (prop === 'cases_older_than') {
      settings['cases_older_than'] = value;
    } else if (prop === 'max_allowed_days') {
      settings['max_allowed_days'] = value;
    } else {
      settings[prop] = value !== 'true';
    }
    setSelectedSettings(settings);
  };

  return (
    <div>
      <BreadCrumbsComponent />
      <Form
        title={'Features'}
        formDefinition={formDefinition()}
        dataItem={selectedSettings}
        enable={enableForm}
        errors={errors}
        inline={false}
        onAction={handleCancel}
        onSave={_handleSave}
        onPropChange={handleFormChange}
      />
    </div>
  );
};
