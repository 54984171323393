import React, { useEffect, useCallback, useState } from 'react';
import { CMxComponents } from '@codametrix/ui-components/src/types';
import { ModalComponent } from '../Modal/modal';
import { Button } from '../button/button';
import { modalSubmit } from '../../actions/ui';
import { useDispatch } from 'react-redux';
import { Action } from 'redux';

export type modalState = {
  timeLeft: number;
  countdownSet: number | null;
};

export const LogoutModal: React.FC<CMxComponents.ModalProps> = (
  props: CMxComponents.ModalProps
) => {
  const {
    children,
    closable,
    title,
    footerChildren,
    handler,
    forceSubmitOptions,
    icon,
    buttonText,
    modalParams
  } = props;

  const dispatch = useDispatch();

  const [modalState, setModalState] = useState<modalState>({
    timeLeft: forceSubmitOptions?.time ?? 60,
    countdownSet: null
  });
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (forceSubmitOptions?.isForced && modalState.timeLeft <= 0) {
        const event = new CustomEvent('');
        handler && _handleClick(event);
      } else if (
        forceSubmitOptions?.isForced &&
        modalState.timeLeft > 0 &&
        modalState.countdownSet === null
      ) {
        setModalState({ ...modalState, timeLeft: modalState.timeLeft - 1 });
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  const _handleClick = async (
    event: React.MouseEvent | React.FormEvent | CustomEvent
  ) => {
    event.preventDefault();
    if (handler) {
      dispatch(
        (modalSubmit({ name: handler, params: modalParams }) as any) as Action
      );
    }
  };

  const forcedSubmitMessage =
    forceSubmitOptions && forceSubmitOptions?.message + modalState.timeLeft;

  const handleUnload = useCallback(
    event => {
      if (handler) {
        dispatch(
          (modalSubmit({ name: handler, params: modalParams }) as any) as Action
        );
      }
    },
    [handler, dispatch, modalParams]
  );

  useEffect(() => {
    window.addEventListener('unload', handleUnload);
    window.addEventListener('popstate', handleUnload);

    return () => {
      window.removeEventListener('unload', handleUnload);
      window.removeEventListener('popstate', handleUnload);
    };
  }, [handleUnload]);

  return (
    <ModalComponent
      size={'lg'}
      isOpen={isModalOpen}
      handleClose={() => {
        setIsModalOpen(false);
      }}
      showCloseIcon={closable}
      modalHeader={
        <div>
          {icon && <span>{icon}</span>}
          {title && <div>{title}</div>}
        </div>
      }
      modalContent={
        <div>
          {forceSubmitOptions?.isForced ? forcedSubmitMessage : children}
        </div>
      }
      modalFooter={
        <div onClick={_handleClick}>
          <Button dataTestId="submitButton" label={buttonText ?? ''} />{' '}
          {footerChildren && <div>{footerChildren} </div>}
        </div>
      }
    />
  );
};

LogoutModal.defaultProps = {
  closable: true,
  maximize: false,
  forceSubmitOptions: {
    isForced: false,
    time: 60,
    message: 'Submitting Automatically in '
  }
};
