/// <reference path="../types.d.ts" />
import { commonEnums } from '@codametrix/ui-common';
const { PermissionLevel } = commonEnums;

export const hasResource = (cmxContext: CMx.Context, resourceName: string) => {
  const { contexts } = cmxContext;
  return contexts.some(ctx => {
    return ctx.resources.some(
      resource => resource.resourceName === resourceName
    );
  });
};

export const checkResources = (actionGroups: CMxAPI.NavActionGroup[]) => {
  return actionGroups.some(group =>
    group.options.some(option => option.enabled)
  );
};

export const isTabViewOnly = (tabs: CMx.TabDef[], resourceName: string) => {
  return (
    tabs.find(tab => tab.resourceId === resourceName)?.permissionLevel ===
    PermissionLevel.READ_ONLY
  );
};

export const isResourceViewOnly = (
  resources: CMxAPI.Resource[],
  resourceName: string
) => {
  return (
    resources.find(resources => resources.resourceName === resourceName)
      ?.permissionLevel === PermissionLevel.READ_ONLY
  );
};

export const isCaseConfigDeny = (
  userRoles: CMxAPI.UserRoles[],
  contextOrgName: string
) => {
  return !userRoles
    .find(role => role.organizationName === contextOrgName)
    ?.roles.find(
      role =>
        role.roleName === 'support_engineer' ||
        role.roleName === 'professional_services'
    );
};
