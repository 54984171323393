import { Box } from '@chakra-ui/react';
import theme from '../../../theme';
import React, { useEffect, useState } from 'react';
import { BreadCrumbsComponent } from '../../../components/breadcrumbs/breadcrumbsComponent';
import { DataGrid } from '../../../components/data-grid/data-grid';
import {
  EndpointData,
  HTTPMethodType
} from '../../../components/data-grid/data-grid-utils';
import { SubHeader } from '../../organizations/subheader';
import { updatedColDefs } from '../external-application-list';

const endpoint = (appName: string) => {
  const params = [['externalApplicationName', `${appName}`]];
  const endPoint: EndpointData = {
    endPoint: `/externalApplication/users/list/v1`,
    HTTPMethodType: HTTPMethodType.GET,
    params: params
  };
  return endPoint;
};

export const defaultExternalApplicationUser = {
  alias: '',
  userId: undefined,
  username: ''
};

export const ExternalAppUserList: React.FC<AppProps.ExternalApplicationUserListProps> = props => {
  const {
    onSelection,
    onAddUser,
    columnDefs,
    sortablePageable,
    selectedAppName,
    setStateToDefault
  } = props;
  const [pageSize, setPageSize] = useState<number>(25);
  const handleAddUserClick = () => {
    onAddUser(window.location.pathname);
  };

  const handleSelect = (rowData: CMxAPI.ExternalApplicationUser) => {
    onSelection(rowData, window.location.pathname);
  };

  useEffect(() => {
    setStateToDefault && setStateToDefault(defaultExternalApplicationUser);
    // eslint-disable-next-line
  }, []);

  const onPageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
  };

  return (
    <div>
      <BreadCrumbsComponent />
      <Box paddingTop={theme.space[16]}>
        <SubHeader
          title={selectedAppName}
          actionName={'Add User'}
          onActionClick={handleAddUserClick}
          pageSize={pageSize}
          onPageSizeChange={onPageSizeChange}
        />
        <Box paddingTop={theme.space[16]}>
          <DataGrid
            serverSideInfiniteScroll
            columnDefs={updatedColDefs(columnDefs)}
            endpointData={endpoint(selectedAppName)}
            filterableOptions={sortablePageable.filterableOptions}
            onRowClicked={item => {
              handleSelect(item.data);
            }}
            paginationPageSize={pageSize}
          />
        </Box>
      </Box>
    </div>
  );
};

ExternalAppUserList.defaultProps = {
  selectedAppName: 'External app'
};
