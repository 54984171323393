import React, { useEffect, useRef, useState } from 'react';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Grid,
  GridItem,
  Divider,
  Tag
} from '@chakra-ui/react';
import { Checkbox } from '../checkbox/checkbox';
import { Button } from '../button/button';
import { ButtonVariant } from '../../core/enums';
import { styles } from './checkbox-menu.styles';
import theme from '../../theme';
import { CheckboxTree } from './checkbox-tree';
import { Conditional } from '@codametrix/shared-views';
import Input from '../../components/input/input';
import { objectUtils } from '@codametrix/ui-common';

export type CheckboxOption = {
  label: string;
  value: string;
  children?: CMxCommonApp.FieldDefinition[];
};

export type CheckboxMenuProps = {
  label: string;
  options: CheckboxOption[];
  onApplyFilters: (selected: CheckboxOption[]) => void;
  selectedOptions: CheckboxOption[];
  menuIcon?: JSX.Element;
  menuRightIcon?: JSX.Element;
  isTree?: boolean;
  withTypeAhead?: boolean;
};
const classes = styles();

const CheckboxMenu: React.FC<CheckboxMenuProps> = props => {
  const {
    label,
    options,
    onApplyFilters,
    selectedOptions,
    menuIcon,
    menuRightIcon,
    isTree,
    withTypeAhead
  } = props;
  const [selected, setSelected] = useState<CheckboxOption[]>(selectedOptions);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [filteredOptions, setFilteredOptions] = useState<any[]>([]);

  const prevSelectedOptions = useRef<CheckboxOption[]>([]);

  // Handle search text not supported for tree searching yet.
  const handleSearchText = (ev: any) => {
    setSearchText(ev.target.value);
    if (ev.target.value) {
      setFilteredOptions(
        options.filter(option =>
          option?.label?.toLowerCase().includes(ev.target.value?.toLowerCase())
        )
      );
    } else {
      setFilteredOptions(options);
    }
  };

  // set options by default
  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const handleCheckboxChange = (option: CheckboxOption) => {
    if (selected?.some(o => o.value === option.value)) {
      setSelected(selected.filter(o => o.value !== option.value));
    } else {
      setSelected([...selected, option]);
    }
  };

  const getCheckboxItem = (item: CMxCommonApp.FieldDefinition) => {
    return {
      label: item.helpText ?? '',
      value: item.key ?? ''
    };
  };

  const handleSelectAllChildren = (selectedOption: CheckboxOption) => {
    let tempSelected: CheckboxOption[] = JSON.parse(JSON.stringify(selected));
    selectedOption?.children?.forEach(option => {
      if (!tempSelected?.some(o => o.value === option.key)) {
        tempSelected.push(getCheckboxItem(option));
      }
    });
    if (!(tempSelected.length > 0)) {
      tempSelected.push({
        label: selectedOption.label,
        value: selectedOption.value
      });
    }
    setSelected(tempSelected);
  };

  const handleDeselectAllChildren = (selectedOption: CheckboxOption) => {
    let tempSelected: CheckboxOption[] = JSON.parse(JSON.stringify(selected));

    tempSelected = tempSelected
      ?.filter(selected => {
        const isNotChild = selectedOption?.children
          ?.map(getCheckboxItem)
          ?.find(o => o.value === selected.value);
        return isNotChild === undefined;
      })
      ?.filter(el => el.value !== selectedOption.value);

    setSelected(tempSelected);
  };

  const handleClear = () => {
    setSearchText('');
    setSelected([]);
    setFilteredOptions(options);
  };

  const handleCancelClick = () => {
    setIsOpen(false);
  };

  // checks if the options are equal even if the indexes are different
  const isOptionsChanged = () => {
    return (
      selected?.length === selectedOptions?.length &&
      selectedOptions?.every(elem =>
        selected.some(elem2 => objectUtils.isEqual(elem, elem2))
      )
    );
  };

  useEffect(() => {
    if (
      JSON.stringify(selectedOptions) !==
      JSON.stringify(prevSelectedOptions.current)
    ) {
      setSelected(selectedOptions);
      prevSelectedOptions.current = selectedOptions;
    }
  }, [selectedOptions]);

  const handleApplyClick = () => {
    onApplyFilters(selected);
    setIsOpen(false);
  };

  useEffect(() => {
    if (!isOpen) {
      setSelected(selectedOptions);
    }
  }, [isOpen, selectedOptions]);

  return (
    <Menu
      closeOnSelect={false}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}>
      <MenuButton
        role="menubutton"
        onClick={() => setIsOpen(true)}
        sx={Object.assign(
          {},
          classes.menuButton,
          isOpen ? classes.activeMenuButton : {}
        )}>
        <Button
          leftIcon={menuIcon}
          rightIcon={menuRightIcon}
          variant={ButtonVariant.LINKS}
          label={selected?.length ? selected[0].label : label}
          sx={classes.labelButton}
        />

        {selected?.length > 1 && (
          <Tag role="tag" size={'xs'} variant="solid" sx={classes.tag}>
            <Text sx={classes.tagText}>+{selected?.length - 1}</Text>
          </Tag>
        )}
      </MenuButton>

      <MenuList role="menulist" sx={classes.menuList}>
        <Grid
          flexDirection={'column'}
          templateColumns={'1fr 1fr'}
          alignItems={'center'}>
          <GridItem>
            <Text sx={classes.menuListHeaderText}>{label}</Text>
          </GridItem>
          <GridItem justifySelf={'flex-end'}>
            <Button
              variant={ButtonVariant.LINKS}
              label="clear"
              onClick={handleClear}
              sx={classes.clearButton}
              _hover={{ background: 'none' }}
              _disabled={{ color: theme.colors.text['lowEmphasis'] }}
            />
          </GridItem>
        </Grid>

        <Conditional every={[withTypeAhead === true]}>
          <Input
            value={searchText}
            autoFocus
            onChange={handleSearchText}
            name={'typeAhead'}
            placeholder={'Search for...'}
            type={'text'}
            className={'form-input'}
          />
        </Conditional>

        <Divider
          sx={Object.assign({}, classes.dividerColor, classes.topDivider)}
        />
        {isTree &&
          options.map((option, idx) => (
            <CheckboxTree
              key={idx}
              options={option}
              handleCheckboxChange={handleCheckboxChange}
              selected={selected}
              handleSelectAllChildren={handleSelectAllChildren}
              handleDeselectAllChildren={handleDeselectAllChildren}
            />
          ))}
        {!isTree &&
          filteredOptions.map(option => (
            <MenuItem
              sx={Object.assign(
                {},
                classes.MenuItem,
                selected?.find(o => o.value === option.value) !== undefined
                  ? classes.selectedMenuItem
                  : {}
              )}>
              <Checkbox
                key={option.value}
                isChecked={
                  selected?.find(o => o.value === option.value) !== undefined
                }
                onChange={() => {
                  handleCheckboxChange(option);
                }}
                label={option.label}
                iconColor={theme.colors.opacity.primary}
                checkboxColor={
                  selected?.find(o => o.value === option.value) !== undefined
                    ? theme.colors.accent[100]
                    : theme.colors.foundation[300]
                }
                textColor={theme.colors.text['mediumEmphasis']}
                onClick={() => handleCheckboxChange(option)}
              />
            </MenuItem>
          ))}
        <Divider
          sx={Object.assign({}, classes.dividerColor, classes.bottomDivider)}
        />
        <Grid flexDirection={'column'} templateColumns={'auto 1fr'}>
          <Grid
            display="flex"
            alignItems={'center'}
            justifyContent="flex-start">
            {isTree && options?.[0]?.children?.length && (
              <>
                <Text
                  sx={classes.footerText}
                  color={theme.colors.text['mediumEmphasis']}>
                  {selected?.length}
                </Text>
                &nbsp;
                <Text
                  sx={classes.footerText}
                  color={theme.colors.text['lowEmphasis']}>
                  {`of ${options?.[0]?.children?.length}`}
                </Text>
              </>
            )}
          </Grid>
          <Grid justifyContent="end" display="flex" gap="20px">
            <GridItem>
              <Button
                label={'Cancel'}
                variant={ButtonVariant.SECONDARY}
                onClick={handleCancelClick}
              />
            </GridItem>
            <GridItem>
              <Button
                label={'Apply'}
                disabled={isOptionsChanged()}
                onClick={handleApplyClick}
              />
            </GridItem>
          </Grid>
        </Grid>
      </MenuList>
    </Menu>
  );
};

CheckboxMenu.displayName = 'CheckboxMenu';

export { CheckboxMenu };
