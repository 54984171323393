import theme from '../../theme';

export const styles = (tabColor?: string) => {
  const _tabColor = tabColor ?? theme.colors.text['light'];

  const mainVStack = {
    width: theme.space[96],
    background: theme.colors.text['highEmphasis'],
    color: _tabColor,
    h: '100%'
  };

  const secondVStack = {
    mt: theme.space[32],
    fill: _tabColor,
    '.chakra-text': {
      marginTop: theme.space[4],
      ...theme.textStyles['Subtitle 2']
    },
    '.css-qmjt37': {
      ...theme.textStyles['Subtitle 2']
    },
    '.action-group-spacing': {
      wordSpacing: '100vw'
    }
  };

  const dividerVertical = {
    borderColor: theme.colors.text['highEmphasis'],
    border: `${theme.borders['1px']}`,
    borderLeftRadius: `${theme.radii[3]}`,
    opacity: 1
  };

  const navLogo = {
    w: theme.space[60],
    h: theme.space[24],
    mb: theme.space[28],
    mt: 'auto',
    justifySelf: 'center',
    display: 'flex'
  };

  const usernNameAvatar = {
    w: theme.space[40],
    h: theme.space[40],
    bg: theme.colors.foundation[400],
    borderColor: theme.colors.stroke[100],
    border: `${theme.borders['1px']}`,
    color: _tabColor,
    '.chakra-avatar__initials': {
      ...theme.textStyles['Subtitle 1']
    }
  };

  const selectedUsernNameAvatar = {
    w: theme.space[40],
    h: theme.space[40],
    bg: theme.colors.foundation[400],
    borderColor: theme.colors.accent[100],
    border: `${theme.borders['1px']} ${theme.colors.accent[100]}`,
    color: _tabColor,
    '.chakra-avatar__initials': {
      ...theme.textStyles['Subtitle 1']
    }
  };

  const avatarVstack = {
    w: '100%'
  };

  const avatarBox = {
    w: '100%',
    justifyContent: 'center'
  };

  const accordionItem = {
    pl: theme.space[8],
    pr: theme.space[8],
    pt: theme.space[4],
    pb: theme.space[4]
  };

  const mainGrid = {
    background: theme.colors.text['highEmphasis'],
    color: theme.colors.text['light'],
    ...theme.textStyles['Body 1'].fontSize,
    borderColor: theme.colors.stroke['200'],
    borderRadius: theme.radii[3]
  };

  const orgBox = {
    alignItems: 'center',
    width: '100%',
    h: theme.space[44],
    pl: theme.space[8],
    pr: theme.space[8],
    pt: theme.space[8],
    pb: theme.space[8],
    borderBottom: `${theme.borders['1px']}`,
    borderColor: theme.colors.stroke['300']
  };

  const navItemVStack = {
    mr: `-${theme.space[4]}`,
    w: '100%'
  };

  const orgServiceText = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '65px'
  };

  const iconWrapper = {
    margin: theme.space[4]
  };

  const insightsBox = {
    alignItems: 'center',
    pl: theme.space[10]
  };

  const insightsCarat = {
    pl: theme.space[12],
    pr: theme.space[2]
  };

  return {
    '.mainVStack': mainVStack,
    '.navLogo': navLogo,
    '.secondVStack': secondVStack,
    '.usernNameAvatar': usernNameAvatar,
    '.selectedUserNameAvatar': selectedUsernNameAvatar,
    '.avatarVstack': avatarVstack,
    '.avatarBox': avatarBox,
    '.accordionItem': accordionItem,
    '.dividerVertical': dividerVertical,
    '.mainGrid': mainGrid,
    '.orgBox': orgBox,
    '.navItemVStack': navItemVStack,
    '.orgServiceText': orgServiceText,
    '.iconWrapper': iconWrapper,
    '.insightsBox': insightsBox,
    '.insightsCarat': insightsCarat
  };
};
