export type RoleOptions = {
  value: string;
  label: string;
};

export type OrganizationOptions = {
  value: string;
  label: string;
};

export type GridRowData = {
  organizationName: string;
  tenantId: string;
  displayName: string;
  id: number;
  roleName: string;
  roleDescription: string;
  primaryRole: string | null;
};

export type RoleOption = {
  roleName: string;
  id: number;
};

export type OrgOption = {
  orgName: string;
  tenantId: string;
};

export type PasswordModalData = {
  password: string;
  confirmPassword: string;
};

export const FORCE_PASSWORD_CHANGE = 'Force Password Change';

export const PASSWORD_CHANGE_DESC =
  'Should the user choose a new password when they log in. Required when creating an account for someone else.';

export const CHANGE_PASSWORD = 'Change Password';

export const ROLES = 'Roles';

export const PASSWORD_OPTIONS = 'Password Options';

export const LOCKED = 'Locked';

export const ACTIVE = 'Active';

export const ACCOUNT_INFORMATION = 'Account Information';
