import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import cases from './cases.reducer';
import login from './login';
import organization from './organization';
import cmx from './cmx-application';
import users from './users';
import ui from './ui';
import assigningAuthorities from './assigning-authorities';
import analytics from './analytics';
import dictionary from './dictionary';
import dictionaryValues from './dictionary-values';
import dictionaryTenants from './dictionary-tenants';
import forgotPassword from './forgot-password';
import eula from './eula';
import dictionaryRequest from './dictionary-request';
import codeLookup from './code-lookup';
import accountSettings from './account-settings';
import myStatus from './my-status';
import capturedTime from './captured-time';
import externalApplications from './external-application';
import caseConfig from './case-config';
import rulesList from './rules-list';
import auditLog from './audit-log/audit-log';
import group from './groups';
import patientTimeline from './patient-timeline.reducer';
import providerDetails from './provider-details.reducer';
import createCase from './create-case.reducer';
import mergeCases from './merge-cases.reducer';
import attachDocs from './attach-docs.reducer';
import { AmplifyReducers } from '@codametrix/ui-common';
import caseEdits from './case-edits';
import normalTime from './normal-time';
import coderNotes from './coder-notes.reducer';

const reducers = (history: History) =>
  combineReducers({
    analytics,
    auditLog,
    cases,
    login,
    organization,
    cmx,
    ui,
    users,
    assigningAuthorities,
    dictionary,
    dictionaryValues,
    dictionaryTenants,
    forgotPassword,
    eula,
    dictionaryRequest,
    codeLookup,
    accountSettings,
    myStatus,
    capturedTime,
    externalApplications,
    group,
    caseConfig,
    rulesList,
    caseEdits,
    patientTimeline,
    providerDetails,
    createCase,
    mergeCases,
    attachDocs,
    coderNotes,
    ampUI: AmplifyReducers.ampUI,
    codeBench: AmplifyReducers.codeBench,
    invoiceMetadata: AmplifyReducers.invoiceMetadata,
    capabilities: AmplifyReducers.capabilities,
    improvements: AmplifyReducers.improvements,
    workingHours: normalTime,
    gradeBenchPanel: AmplifyReducers.gradeBenchPanel,
    router: connectRouter(history)
  });

export default reducers;
