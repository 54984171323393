/// <reference path="../../types.d.ts" />

import { connect } from 'react-redux';
import { buildDispatchable } from '../../actions/_action-utilities';
import { groupActions } from '../../actions/groups';
import { GroupsDetail } from './groups-detail';
import * as dispatchNavigation from '../../actions/navigation';

type groupsDetailDispatches = Pick<
  AppProps.GroupsDetailProps,
  'dispatchNavigation' | 'addGroupMember' | 'saveGroup' | 'deleteGroup'
>;

type groupsDetailStateProps = Pick<
  AppProps.GroupsDetailProps,
  'eligibleGroupMembers'
>;

export const mapStateToProps = function(
  state: CMx.ApplicationState
): groupsDetailStateProps {
  const { group } = state;

  return group;
};

export const mapDispatchToProps = (dispatch: any): groupsDetailDispatches => {
  const runDispatchable = buildDispatchable(dispatch);

  return {
    dispatchNavigation: dispatchNavigation.navExport(dispatch),
    addGroupMember: (params: AppProps.addGroupMemberParams) => {
      runDispatchable(() => {
        return dispatch(groupActions.addGroupMember(params));
      });
    },
    saveGroup: async (group: CMxAPI.Group) => {
      runDispatchable(() => {
        return dispatch(groupActions.saveGroup(group));
      });
    },
    deleteGroup: async (groupId: number) => {
      runDispatchable(() => {
        return dispatch(groupActions.deleteGroup(groupId));
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupsDetail);
