/// <reference path="../../../types.d.ts" />

export const USER_STUB: CMxAPI.User = {
  id: null,
  userId: '',
  username: '',
  password: null,
  confirmPassword: null,
  firstName: '',
  lastName: '',
  active: true,
  ignoreLockout: false,
  locked: false,
  passwordExpired: null,
  forcePasswordChange: false,
  entityId: null,
  entityType: null,
  roles: []
};

export const EXISTING_USER_STUB: CMxAPI.User = {
  id: 1,
  userId: '',
  username: '',
  password: null,
  confirmPassword: null,
  firstName: '',
  lastName: '',
  active: true,
  ignoreLockout: false,
  locked: false,
  passwordExpired: null,
  forcePasswordChange: false,
  entityId: null,
  entityType: null,
  roles: []
};

export const SAMPLE_ROLES: CMxAPI.UserRoles[] = [
  {
    tenantId: '44DF939F-C08E-45EC-A1E0-811378DBABB3',
    organizationName: 'Pentucket Medical Associates',
    roles: [
      {
        id: 6,
        roleName: 'external_system',
        roleDescription: 'External System',
        primaryRole: null
      },
      {
        id: 7,
        roleName: 'external_system',
        roleDescription: 'External System',
        primaryRole: null
      }
    ],
    displayName: ''
  }
];
