import React, { useEffect } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleDrawer, toggleDrawerContent } from '../../actions/ui';

const CMXDrawer = () => {
  // isDrawerOpen used for show or not the Drawer
  const { isDrawerOpen, drawerSize } = useSelector(
    (state: CMx.ApplicationState) => {
      if (state.providerDetails.isDrawerOpen) {
        return {
          isDrawerOpen: state.providerDetails.isDrawerOpen,
          drawerSize: 'lg'
        };
      }
      if (state.coderNotes.isDrawerOpen) {
        return {
          isDrawerOpen: state.coderNotes.isDrawerOpen,
          drawerSize: 'lg'
        };
      }
      if (state?.createCase?.isDrawerOpen) {
        return {
          isDrawerOpen: state.createCase.isDrawerOpen,
          drawerSize: 'md'
        };
      } else {
        return {
          isDrawerOpen: state.patientTimeline.isDrawerOpen,
          drawerSize: 'xl'
        };
      }
    }
  );
  const dispatch = useDispatch();

  // popstate event is used here for close the Drawer when users clicks a back button
  useEffect(() => {
    window.addEventListener('popstate', ev => {
      if (isDrawerOpen) {
        dispatch(toggleDrawer(false));
        dispatch(toggleDrawerContent(false));
      }
    });
  });

  return (
    <>
      <Drawer
        isOpen={isDrawerOpen}
        size={drawerSize}
        blockScrollOnMount={false}
        placement="right"
        onClose={() => {
          dispatch(toggleDrawer(false));
          dispatch(toggleDrawerContent(false));
        }}>
        <DrawerOverlay overflow="scroll" />
        {/* @ts-ignore */}
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader id="cmx-drawer-header"></DrawerHeader>
          <DrawerBody id="cmx-drawer-body"></DrawerBody>
          <DrawerFooter id="cmx-drawer-footer"></DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

CMXDrawer.displayName = 'CMXDrawer';

export { CMXDrawer };
