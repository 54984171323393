import { connect } from 'react-redux';
import {
  CodeBench,
  mapDispatchToCodeBenchProps
} from '@codametrix/shared-views';
import { getPrevOrNextCase } from '../../actions/cases.action';
import { AmplifyCore } from '@codametrix/ui-common';

//state = CMx.ApplicationState but not exactly
export const mapStateToCodeBenchProps = function(
  state: any
): Accelerate.BenchStateData {
  const location_state = state?.router?.location?.state;
  const cursorAPIContext = location_state
    ? { ...location_state }
    : { ...AmplifyCore.state.capabilities.cursorAPIContext };

  return {
    ...state.codeBench,
    ...state.ampUI,
    capabilities: state.capabilities,
    improvements: state.improvements,
    caseEdits: state.caseEdits?.caseEdits,
    cursorAPIContext,
    htmlNotes: state?.cases?.htmlNotes ?? state?.codeBench?.htmlNotes,
    roles: state?.cases?.roles ?? [],
    caseRun: state?.cases?.activeItem,
    caseAccessTime: state?.codeBench?.caseAccessTime,
    customClaimsRelatedCases: state?.codeBench?.customClaimsRelatedCases,
    customClaimsRelatedCasesLoadState:
      state?.codeBench?.customClaimsRelatedCasesLoadState,
    backToWorklistPath: state.ui.backToWorklistPath,
    propertyConfigv2: state?.codeBench?.propertyConfig
  };
};

export const mapUpdatedStateToCodeBenchProps = (
  state: CMx.ApplicationState
) => {
  const values = mapStateToCodeBenchProps(state);
  return {
    ...values,
    loadAlwaysFromURL: true
  };
};

export const mapUpdatedDispatchToCodeBenchProps = (
  dispatch: any
): Accelerate.CodeBenchPanelMethods => {
  const values = mapDispatchToCodeBenchProps(dispatch);
  return {
    ...values,
    onCursorAction: async (cursorAPIPayload: CMxCommonApp.CursorAPIPayload) => {
      dispatch(getPrevOrNextCase(cursorAPIPayload));
    }
  };
};

export const ConnectedCodeBench = connect<
  Accelerate.BenchStateData,
  Accelerate.CodeBenchPanelMethods
>(
  //@ts-ignore Code Bench from amplify props do not exactly line up
  mapUpdatedStateToCodeBenchProps,
  mapUpdatedDispatchToCodeBenchProps
  //@ts-ignore
)(CodeBench);
