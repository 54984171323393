/// <reference path="../../types.d.ts" />

import React from 'react';
import {
  CheckList,
  Card,
  LoadingSlugs,
  RoundedButton
} from '@codametrix/ui-components';

import '../../styles/grid-common.scss';
import '../../styles/cmx.scss';
import '../pageable-list/pageable-list.scss';
import { commonEnums as enums } from '@codametrix/ui-common';
import './generic-filter.scss';
const { FilterTypes } = enums;

const GenericFilter: React.FC<AppProps.GenericFilterProps> = (
  props: AppProps.GenericFilterProps
) => {
  const { sortablePageable, list, criteriaFilter, form, loadState } = props;

  function _handleFilterSubmit(event: CustomEvent) {
    event.preventDefault();

    var fieldList = event.detail.value.checkboxes;

    var state: { key: string; [key: string]: any } = {
      key: form.key
    };

    let filters: CMxCommonApp.Filter[] = [];

    if (criteriaFilter.filters) {
      filters = criteriaFilter.filters
        .filter(filter => {
          return (
            filter.type !== 'GREATER_THAN_EQUAL' &&
            filter.type !== 'LESS_THAN_EQUAL'
          );
        })
        .filter(filter => {
          return filter.key !== state.key;
        });
    }

    let newFilter: CMxCommonApp.Filter;
    let terms: string[] = [];
    let keyName: string;

    const keys = Object.keys(state);
    keys.forEach(key => {
      if (key !== 'key') {
        if (state[key] === true) {
          terms.push(key);
        }
      } else {
        keyName = state[key];
      }

      terms = [];

      Object.keys(fieldList).forEach(key => {
        if (fieldList[key] === true) {
          terms.push(key);
        }
      });

      if (keyName !== undefined) {
        newFilter = {
          key: keyName,
          terms: terms,
          type: FilterTypes.IN
        };

        if (terms.length !== 0) {
          filters.push(newFilter);
        }

        var listOptions = sortablePageable;
        listOptions.filterableOptions.filters = filters;
        listOptions.filterableOptions.dateFilter =
          sortablePageable.filterableOptions.dateFilter;
        listOptions.pageableDefinition.pageable.pageNumber = 0;
        listOptions.pageableDefinition.number = 0;
        listOptions.pageableDefinition.first = true;
        listOptions.pageableDefinition.last = false;
        listOptions.pageableDefinition.pageable.offset = 0;

        list(listOptions);
      }
    });
  }

  var defaults: { [key: string]: any } = {};
  let activeFilter = false;
  if (form.formDefinition.fieldGroups) {
    form.formDefinition.fieldGroups.forEach(group => {
      group.fields.forEach(field => {
        let tempKey = field.key;

        const filterMatch = criteriaFilter.filters
          ?.find(filter => {
            return filter.key === form.key;
          })
          ?.terms.find(term => {
            return term === field.key;
          });

        if (filterMatch) {
          defaults[tempKey] = true;
          activeFilter = true;
        } else {
          defaults[tempKey] = false;
        }
      });
    });
  }

  function _handleFilterClear() {
    let filters = criteriaFilter.filters.filter(filter => {
      return filter.key !== form.key;
    });

    var listOptions = sortablePageable;
    listOptions.filterableOptions.filters = filters;
    listOptions.filterableOptions.dateFilter =
      sortablePageable.filterableOptions.dateFilter;
    listOptions.pageableDefinition.pageable.pageNumber = 0;
    listOptions.pageableDefinition.number = 0;
    listOptions.pageableDefinition.first = true;
    listOptions.pageableDefinition.last = false;
    listOptions.pageableDefinition.pageable.offset = 0;

    list(listOptions);
  }

  return (
    <div className={`dropdown-${form.key}`}>
      <Card className={'filter-card loading-card'}>
        {loadState.loading || loadState.initial ? (
          <LoadingSlugs numberItems={3} />
        ) : (
          <CheckList
            isColored={form.isColored}
            value={defaults}
            onChange={_handleFilterSubmit}
            FieldDefinitions={
              form.formDefinition.fieldGroups?.length
                ? form.formDefinition.fieldGroups[0].fields
                : []
            }
          />
        )}

        <div className="bottomless-divider"></div>

        <RoundedButton
          className={'clear-btn qa-clear-btn btn-link '}
          onClick={_handleFilterClear}
          disabled={!activeFilter}>
          Clear Filter
        </RoundedButton>
      </Card>
    </div>
  );
};

export default GenericFilter;
