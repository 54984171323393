import React, { useState, ChangeEvent, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Flex } from '@chakra-ui/react';
import { createPortal } from 'react-dom';
import { toggleDrawer, toggleDrawerContent } from '../../actions/ui';
import { LoadingSlugs } from '@codametrix/ui-components';
import validationMessagesConfig from './validationMessagesConfig';
import { ButtonVariant } from '../../core/enums';
import { Button } from '../../components/button/button';
import DatePicker from 'react-datepicker';
import { ReactComponent as CalendarIcon } from '../../assets/images/calendar.svg';
import { InputVariant } from '../../core/enums';
import Input from '../../components/input/input';
import './create-case-styles.scss';
import 'react-datepicker/dist/react-datepicker.css';

import {
  Conditional,
  launchAmplifyCase,
  CMXIcon,
  MenuButton,
  MenuItem
} from '@codametrix/shared-views';
import {
  createCaseActions,
  cmxDateTime,
  caseUtils,
  codeBenchActions
} from '@codametrix/ui-common';
import { Action } from 'redux';

const { openWindow } = codeBenchActions;
const { getChildWindowParams } = caseUtils;

const fallbackElement = document.createElement('div');

const CreateCase = () => {
  const initialCreateCaseState: CMxCommonApp.CreateCase = {
    notesSpecialty: '',
    dateOfService: '',
    comments: '',
    alternate_visit_id: ''
  };

  const initialValidationMessage = {
    notesSpecialty: '',
    dateOfService: '',
    apiError: ''
  };
  const [createCase, setCreateCase] = useState<CMxCommonApp.CreateCase>(
    initialCreateCaseState
  );
  const [validationMessage, setValidationMessage] = useState(
    initialValidationMessage
  );
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const dispatch = useDispatch();
  const cancelCircleicon = (
    <CMXIcon
      svgIconName="BiomeCancelCircle"
      svgIconClass="create-case-menu-button"
      svgIconSize="16"
    />
  );

  const {
    activeCaseRun,
    isOpen,
    notesSpecialty,
    notesSpecialtyLoading,
    isDrawerOpen
  } = useSelector((state: CMx.ApplicationState) => state.createCase);

  const tenantId = useSelector(
    (state: CMx.ApplicationState) =>
      state.codeBench?.processInstance?.healthSystemTenantId?.uuid
  );

  useEffect(() => {
    if (isOpen && activeCaseRun) {
      dispatch(
        (createCaseActions.fetchNotesSpecialty(tenantId!) as any) as Action
      );
      const newAlternateVisitId =
        activeCaseRun.encounter_identifier?.number || '';
      setCreateCase(prevCreateCase => ({
        ...prevCreateCase,
        alternate_visit_id: newAlternateVisitId
      }));
    }
    // eslint-disable-next-line
  }, [activeCaseRun, isOpen]);

  useEffect(() => {
    setCreateCase(initialCreateCaseState);
    setValidationMessage(initialValidationMessage);
    setSelectedDate(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDrawerOpen]);

  const handleTextAreaChange = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = event.target;
    setCreateCase(prevCreateCase => ({
      ...prevCreateCase,
      [name]: value
    }));
  };

  const handleMenuItemSelect = (event: any, name: string) => {
    const value = event.currentTarget.textContent;

    if (name && value) {
      setCreateCase(prevCreateCase => ({
        ...prevCreateCase,
        [name]: value
      }));
    }
  };

  const formatCaseDate = (
    tenant_timezone: string | null | undefined,
    timestamp: string | undefined
  ): string | null => {
    if (timestamp && tenant_timezone) {
      const formattedDate = cmxDateTime.setTimeZoneForDate(
        tenant_timezone,
        timestamp
      );
      return formattedDate
        ? cmxDateTime.format(formattedDate, cmxDateTime.FORMATS.DATE_OF_SERVICE)
        : null;
    }
    return null;
  };

  const removeTimeZone = (
    tenant_timezone: string | null | undefined,
    timestamp: string
  ): string => {
    const shortCodeTZ = cmxDateTime.getTZShortCode(tenant_timezone, timestamp);
    if (shortCodeTZ) {
      return timestamp.replace(shortCodeTZ, '');
    }
    return timestamp;
  };

  const parseAndFormatDateToYMD = (date: string): string => {
    const [mm, dd, yyyy] = date.split('/');
    // Padding month and day with leading zeros if necessary
    const formattedMonth = mm.padStart(2, '0');
    const formattedDay = dd.padStart(2, '0');
    return `${yyyy}-${formattedMonth}-${formattedDay}`;
  };

  const formatDateToMDY = (date: Date): string => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${month}/${day}/${year}`;
  };

  const calculateLengthOfStay = (
    admissionDate: string,
    dischargeDate: string
  ): number => {
    const admission = new Date(admissionDate);
    const discharge = new Date(dischargeDate);
    const differenceMs = discharge.getTime() - admission.getTime();
    const days = Math.ceil(differenceMs / (1000 * 60 * 60 * 24)) + 1;
    return days;
  };

  const handleFormSubmit = async () => {
    // Validation
    const newValidationMessages = initialValidationMessage;
    if (createCase.notesSpecialty.trim() === '') {
      newValidationMessages.notesSpecialty =
        validationMessagesConfig.requiredNotesSpecialty;
    } else if (createCase.dateOfService.trim() === '') {
      newValidationMessages.dateOfService =
        validationMessagesConfig.requiredDateOfService;
    }
    setValidationMessage(newValidationMessages);

    if (Object.values(newValidationMessages).some(message => message !== '')) {
      return;
    }

    try {
      const startDate =
        activeCaseRun.admission_datetime || activeCaseRun.earliest_datetime;

      const dateOfService =
        createCase.dateOfService ===
        formatCaseDate(activeCaseRun?.tenant_timezone, startDate)
          ? removeTimeZone(activeCaseRun?.tenant_timezone, startDate)
          : parseAndFormatDateToYMD(createCase.dateOfService);

      const formattedCreateCase = {
        ...createCase,
        dateOfService
      };
      const response = await dispatch(
        (createCaseActions.submitCreateCase({
          createCase: formattedCreateCase,
          activeCaseRun
        }) as any) as Action
      );
      dispatch(toggleDrawer(false));
      dispatch(toggleDrawerContent(false));
      setCreateCase(initialCreateCaseState);
      setSelectedDate(null);
      // redirect to new case
      handleOpenVersion(response as any);
    } catch (error) {
      setValidationMessage(prevState => ({
        ...prevState,
        apiError: (error as Error)?.message
      }));
    }
  };

  const handleOpenVersion = (processInstance: AmplifyAPI.ProcessInstance) => {
    if (processInstance) {
      const childWindowParams: CMxCommonApp.WindowOpenArguments = getChildWindowParams(
        processInstance?.caseUid,
        processInstance?.guid.uuid,
        processInstance?.serviceLine,
        launchAmplifyCase
      );
      dispatch(openWindow(childWindowParams));
    }
  };

  const cancelCreateCase = () => {
    setCreateCase(initialCreateCaseState);
    setValidationMessage(initialValidationMessage);
    setSelectedDate(null);
    dispatch(toggleDrawer(false));
    dispatch(toggleDrawerContent(false));
  };

  return (
    <Conditional on={isOpen}>
      {notesSpecialtyLoading ? (
        <>
          {createPortal(
            <>
              <LoadingSlugs width="25" key="loading-slugs" numberItems={1} />
              <LoadingSlugs width="25" key="loading-slugs" numberItems={1} />
              <LoadingSlugs width="80" key="loading-slugs" numberItems={1} />
            </>,
            document.getElementById('cmx-drawer-header') || fallbackElement
          )}

          {createPortal(
            <>
              <LoadingSlugs key="loading-slugs" numberItems={3} />
              <br />
              <LoadingSlugs width="60" key="loading-slugs" numberItems={5} />
              <br />
              <LoadingSlugs width="60" key="loading-slugs" numberItems={5} />
              <br />
              <LoadingSlugs width="60" key="loading-slugs" numberItems={5} />
            </>,
            document.getElementById('cmx-drawer-body') || fallbackElement
          )}
        </>
      ) : (
        <>
          <Conditional on={!!document.getElementById('cmx-drawer-header')}>
            <>
              {createPortal(
                <div className="create-case-header-section">
                  <div
                    className="create-case-header"
                    data-testid="create-case-header">
                    Add case
                  </div>
                  <div className="patient-encounter-section">
                    <div>{activeCaseRun.patient_name}</div>
                    <div className="patient-encounter-section-secondary">
                      <div>
                        {activeCaseRun.patient_identifier?.number} (src:{' '}
                        {activeCaseRun.patient_identifier?.issuer})
                      </div>
                      <div className="vertical-divider"></div>
                      <div>
                        {' '}
                        {activeCaseRun?.date_of_birth
                          ? cmxDateTime.format(
                              activeCaseRun.date_of_birth,
                              cmxDateTime.FORMATS.BIRTH_DATE
                            )
                          : 'N/A'}
                      </div>
                      <div className="vertical-divider"></div>
                      <div>{activeCaseRun.patient_gender}</div>
                    </div>
                  </div>
                </div>,
                document.getElementById('cmx-drawer-header') || fallbackElement
              )}
            </>
          </Conditional>
          <Conditional on={!!document.getElementById('cmx-drawer-body')}>
            <>
              {createPortal(
                <div className="create-case-body">
                  {validationMessage.apiError && (
                    <span className="validation-message">
                      {validationMessage.apiError}
                    </span>
                  )}
                  <div>
                    <span className="field-label">Primary Insurance</span>
                    <span className="field-data">
                      {activeCaseRun?.primary_insurance || 'N/A'}
                    </span>
                  </div>
                  <div className="horizontal-divider"></div>
                  <div>
                    <span className="field-label">Hospital Account Record</span>
                    <span className="field-data">
                      {activeCaseRun?.encounter_identifier?.number}
                    </span>
                  </div>
                  <div>
                    <div>
                      <span className="field-label">Length of stay</span>
                      <span className="field-data">
                        {activeCaseRun?.admission_datetime &&
                        activeCaseRun?.discharge_datetime
                          ? `${calculateLengthOfStay(
                              activeCaseRun.admission_datetime,
                              activeCaseRun.discharge_datetime
                            )} days`
                          : 'N/A'}
                      </span>
                    </div>
                  </div>
                  <div>
                    <span className="field-label">Admission Date</span>
                    <span className="field-data">
                      {activeCaseRun?.admission_datetime
                        ? formatCaseDate(
                            activeCaseRun?.tenant_timezone,
                            activeCaseRun?.admission_datetime
                          )
                        : 'N/A'}
                    </span>
                  </div>
                  <div>
                    <span className="field-label">Discharge Date</span>
                    <span className="field-data">
                      {activeCaseRun?.discharge_datetime
                        ? formatCaseDate(
                            activeCaseRun?.tenant_timezone,
                            activeCaseRun.discharge_datetime
                          )
                        : 'N/A'}
                    </span>
                  </div>
                  <div className="select-section">
                    <span className="field-label">Specialty *</span>
                    <span>
                      <MenuButton
                        label={createCase?.notesSpecialty}
                        icon={
                          createCase?.notesSpecialty !== ''
                            ? cancelCircleicon
                            : undefined
                        }
                        defaultLabel="Specialty">
                        {notesSpecialty
                          ?.filter(
                            (specialty: CMxCommonApp.NotesSpecialtyRow) =>
                              specialty?.Facility === activeCaseRun?.facility
                          )
                          .map((specialty: CMxCommonApp.NotesSpecialtyRow) => (
                            <MenuItem
                              key={specialty?.Key}
                              onSelect={event =>
                                handleMenuItemSelect(event, 'notesSpecialty')
                              }>
                              {specialty?.['Note Specialty']}
                            </MenuItem>
                          ))}
                      </MenuButton>
                      {validationMessage.notesSpecialty && (
                        <span className="validation-message">
                          {validationMessage.notesSpecialty}
                        </span>
                      )}
                    </span>
                  </div>
                  <div className="select-section">
                    <span className="field-label">Date of Service *</span>
                    <span>
                      <DatePicker
                        minDate={
                          activeCaseRun?.admission_datetime ||
                          activeCaseRun?.earliest_datetime
                            ? new Date(
                                formatCaseDate(
                                  activeCaseRun?.tenant_timezone,
                                  activeCaseRun?.admission_datetime ??
                                    activeCaseRun?.earliest_datetime
                                ) || new Date()
                              )
                            : new Date()
                        }
                        maxDate={
                          activeCaseRun?.discharge_datetime
                            ? new Date(
                                formatCaseDate(
                                  activeCaseRun?.tenant_timezone,
                                  activeCaseRun?.discharge_datetime
                                ) || new Date()
                              )
                            : new Date()
                        }
                        onChange={(date: Date | null) => {
                          setSelectedDate(date);
                          if (date) {
                            const formattedDate = formatDateToMDY(date);
                            setCreateCase(prevCreateCase => ({
                              ...prevCreateCase,
                              dateOfService: formattedDate
                            }));
                          }
                        }}
                        selected={selectedDate}
                        className="date-of-service"
                        showPopperArrow={false}
                        customInput={
                          <Flex alignItems={'center'}>
                            <CalendarIcon />
                            <Input
                              autoComplete="off"
                              variant={InputVariant.TEXT}
                              value={
                                selectedDate
                                  ? formatDateToMDY(selectedDate)
                                  : 'Select Date'
                              }
                            />
                          </Flex>
                        }
                      />
                      {validationMessage.dateOfService && (
                        <span className="validation-message">
                          {validationMessage.dateOfService}
                        </span>
                      )}
                    </span>
                  </div>

                  <div>
                    <span className="field-label">Comments</span>
                    <span>
                      <textarea
                        className="comments-textarea"
                        rows={4}
                        placeholder="Enter comments(optional)"
                        name="comments"
                        data-testid="comments"
                        value={createCase.comments}
                        maxLength={1024}
                        onChange={handleTextAreaChange}></textarea>
                    </span>
                  </div>
                  <div className="button-section">
                    <Button
                      variant={ButtonVariant.SECONDARY}
                      dataTestId="cancelBtn"
                      label="Cancel"
                      onClick={cancelCreateCase}
                      className="cancel-button"
                    />
                    <Button
                      variant={ButtonVariant.PRIMARY}
                      dataTestId="addCaseBtn"
                      label="Add Case"
                      onClick={handleFormSubmit}
                    />
                  </div>

                  {/* @ts-ignore */}
                </div>,

                document.getElementById('cmx-drawer-body') || fallbackElement
              )}
            </>
          </Conditional>
        </>
      )}
    </Conditional>
  );
};
CreateCase.displayName = 'CreateCase';

export { CreateCase };
