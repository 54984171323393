import { CSSProperties } from 'react';
import theme from '../../theme';

export const styles = (): Record<string, CSSProperties> => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding:
      theme.space[40] +
      ' ' +
      theme.space[36] +
      ' ' +
      theme.space[36] +
      ' ' +
      theme.space[36],
    backgroundColor: theme.colors.structural[100],
    minHeight: 'calc(100vh - 15px)',
    height: '100%'
  },
  content: {
    backgroundColor: theme.colors.structural[0],
    width: 'inherit',
    border: `${theme.space[20]} solid ${theme.colors.structural[0]}`,
    borderRadius: theme.radii[3],
    boxShadow:
      '0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)',
    minHeight: 'calc(100vh - 150px)',
    height: '100%',
    gridTemplateRows: 'min-content auto',
    display: 'grid'
  },
  header: {
    marginLeft: theme.space['35'],
    paddingBottom: theme.space[12]
  },
  text: {
    ...theme.textStyles['h2'],
    color: theme.colors.text['highEmphasis']
  },
  headerText: {
    fontSize: theme.textStyles['h3'],
    color: theme.colors.text['highEmphasis'],
    paddingBottom: '10px'
  }
});
