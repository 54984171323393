import { Flex, Radio, RadioGroup, Stack } from '@chakra-ui/react';
import React, { useState } from 'react';
import { Action } from 'redux';
import { useDispatch } from 'react-redux';
import {
  toggleSecurityModal,
  unrestrictDictionary
} from '../../../../actions/dictionary-values';
import { Button } from '../../../../components/button/button';
import { ModalComponent } from '../../../../components/Modal/modal';
import { ButtonVariant } from '../../../../core/enums';
import { styles } from './security-modal.styles';

export type securityModalProps = {
  isDictionaryUnrestricted: boolean;
  onUnrestrictChange: () => void;
  dictionaryId: Number;
};

export const SecurityModal: React.FC<securityModalProps> = props => {
  const { isDictionaryUnrestricted, onUnrestrictChange, dictionaryId } = props;
  const dispatch = useDispatch();
  const [unrestrictedSelected, updateUnrestrictedSelected] = useState<boolean>(
    isDictionaryUnrestricted === null ? false : isDictionaryUnrestricted
  );

  const _handleClose = () => {
    dispatch(toggleSecurityModal(false));
  };

  const _handleToggleRestricted = () => {
    updateUnrestrictedSelected(!unrestrictedSelected);
  };

  const _handleApply = () => {
    onUnrestrictChange();
    dispatch((unrestrictDictionary(dictionaryId) as any) as Action);
    _handleClose();
  };

  const classes = styles();

  return (
    <ModalComponent
      size={'xl'}
      showCloseIcon={true}
      isOpen={true}
      handleClose={_handleClose}
      modalHeader={<div>Security Settings</div>}
      modalContent={
        <Flex flexDirection={'column'} rowGap={5}>
          <div>
            These settings control which parts of the application are
            accessible. By default, dictionaries are restricted.
          </div>
          <RadioGroup
            name="restricted"
            onChange={_handleToggleRestricted}
            defaultValue={unrestrictedSelected ? 'Unrestricted' : 'Restricted'}>
            <Stack>
              <div
                style={
                  !unrestrictedSelected ? classes.selected : classes.unselected
                }>
                <div style={classes.radioButtonContainer}>
                  <Radio
                    size="md"
                    value="Restricted"
                    colorScheme="orange"
                    style={classes.radioButton}
                  />
                </div>
                <div style={classes.radioDescription}>
                  <div style={classes.radioLabel}>Restricted</div>
                  <div>
                    By default, dictionaries are restricted. Restricted
                    dictionaries serve as lists with read and write access to
                    core application processes.
                  </div>
                </div>
              </div>
              <div
                style={
                  unrestrictedSelected ? classes.selected : classes.unselected
                }>
                <div style={classes.radioButtonContainer}>
                  <Radio
                    size="md"
                    value="Unrestricted"
                    colorScheme="orange"
                    style={classes.radioButton}
                  />
                </div>
                <div style={classes.radioDescription}>
                  <div style={classes.radioLabel}>Unrestricted</div>
                  <div>
                    Unrestricted dictionaries allow read-only access to the user
                    interface of the application. Use this if you need to show
                    values in a dropdown or other UI control.
                  </div>
                </div>
              </div>
            </Stack>
          </RadioGroup>
          {unrestrictedSelected ? (
            <div style={classes.warning}>Warning: This cannot be reversed.</div>
          ) : null}
        </Flex>
      }
      modalFooter={
        unrestrictedSelected ? (
          <Flex flexDirection={'row'} columnGap={3} sx={classes.footer}>
            <Flex>
              <Button
                label={'Cancel'}
                onClick={_handleClose}
                variant={ButtonVariant.SECONDARY}
              />
            </Flex>
            <Flex>
              <Button label={'Apply'} onClick={_handleApply} />
            </Flex>
          </Flex>
        ) : (
          <Flex flexDirection={'row'} columnGap={3} sx={classes.footer}>
            <Flex>
              <Button
                label={'Close'}
                onClick={_handleClose}
                variant={ButtonVariant.SECONDARY}
              />
            </Flex>
          </Flex>
        )
      }
    />
  );
};
