import { defaultFilterableOptions } from '../../core/default-filterable-options';
import { defaultSortableOption } from '../../core/pageable-options';
import { LOAD_STATES } from '../common';
import { AmplifyCore } from '@codametrix/ui-common';

const { paginationUtils } = AmplifyCore;

const tenantListColdDefs = [
  {
    key: 'tenantName',
    displayName: 'Organization Name',
    isSortable: false,
    defaultSort: 'none'
  }
];

const sortablePageable: CMxCommonApp.SortablePageable<any> = {
  pageableDefinition: { ...paginationUtils.emptyPageable },
  sortableOptions: [{ ...defaultSortableOption }],
  filterableOptions: defaultFilterableOptions
};

export const dictionaryTenants: CMx.DictionaryTenantsState = {
  label: 'Tenants',
  itemName: 'Tenant',
  loadState: LOAD_STATES.initial,
  columnDefs: tenantListColdDefs,
  sortablePageable: sortablePageable
};
