/// <reference path="../../types.d.ts" />

import { connect } from 'react-redux';
import { buildDispatchable } from '../../actions/_action-utilities';
import * as dispatchNavigation from '../../actions/navigation';
import {
  submitNewPassword,
  reduxSavePasswordDto
} from '../../actions/forgot-password';

import ResetPassword from './reset-password';
import { push } from 'connected-react-router';

const mapStateToProps = function(state: CMx.ApplicationState) {
  const { forgotPassword } = state;

  return {
    ...forgotPassword
  };
};

const mapDispatchToProps = (dispatch: any) => {
  const runDispatchable = buildDispatchable(dispatch);

  return {
    dispatchNavigation: dispatchNavigation.navExport(dispatch),
    savePasswordResetParameters: async (token: CMxAPI.PasswordReset) => {
      runDispatchable(() => {
        return dispatch(reduxSavePasswordDto(token));
      });
    },
    save: async (newPasswordData: CMxAPI.PasswordReset) => {
      runDispatchable(() => {
        return dispatch(submitNewPassword(newPasswordData));
      });
    },
    handleNavigateHome: () => {
      runDispatchable(() => {
        return dispatch(push('/'));
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
