import { USER_STUB } from '../../tests/fixtures/stubs/user';

export const coderNotes: CMx.CoderNotesState = {
  isDrawerOpen: false,
  isOpen: false,
  servicesLoading: false,
  coderNotesParams: {},
  activeUser: USER_STUB,
  coderNotesList: [],
  coderNote: ''
};
