import theme from '../../../../theme';

export const styles = () => ({
  editMode: {
    margin: `0 ${theme.space[16]} ${theme.space[12]}  ${theme.space[16]}`
  },
  viewMode: {
    margin: `0 ${theme.space[16]}`
  },
  footer: {
    'padding-right': theme.space[16]
  },
  contentSpacing: {
    padding: `${theme.space[16]} 0`
  }
});
