import { Middleware } from 'redux';
import { runtimeOverrides } from '../runtime-overrides';
import { MiddlewareProcessor, middleWareWrapper } from './middleware-utils';

export let isNewDesign: boolean;

/**
 * Middleware that checks the application configuration and runtime overrides to determine if the new admin design should be used.
 *
 * @param api The Redux middleware API, including the current application state.
 * @returns A middleware function that checks the new design configuration and sets the `isNewDesign` flag.
 */
const newDesignMiddlewareProcessor: MiddlewareProcessor = (
  api,
  next,
  action
) => {
  const {
    ui: { config }
  }: CMx.ApplicationState = api.getState();

  isNewDesign =
    (config.isNewDesign || runtimeOverrides?.newAdminDesign === 'true') &&
    runtimeOverrides?.newAdminDesign !== 'false';

  return next(action);
};
const newDesignMiddleware: Middleware = middleWareWrapper(
  newDesignMiddlewareProcessor
);

export default newDesignMiddleware;
