import React from 'react';
import {
  VStack,
  Text,
  Grid,
  Flex,
  Spacer,
  PopoverTrigger,
  Popover
} from '@chakra-ui/react';
import { navComponents } from './nav-icons';
import { ReactComponent as ChevronRightIcon } from '../../assets/images/chevron-right.svg';
import { styles } from './primary-global-navigation.styles';
import theme from '../../theme';

export interface SubNavProps {
  tabColor?: string;
  selectionOpened: boolean;
  setSelectionOpened: React.Dispatch<React.SetStateAction<boolean>>;
  childComponent: React.ComponentType<any>;
  actionGroupOptionLabel: string;
}

export const SubNav: React.FC<SubNavProps> = ({
  tabColor,
  selectionOpened,
  setSelectionOpened,
  childComponent,
  actionGroupOptionLabel
}) => {
  const classes = styles(tabColor);

  const SelectionComponent = childComponent;

  return (
    <Popover
      closeOnBlur={true}
      placement="right"
      isOpen={selectionOpened}
      onClose={() => {
        setSelectionOpened(false);
      }}
      trigger="hover">
      <PopoverTrigger>
        <Flex
          sx={classes['.insightsBox']}
          className={`hoverCursor ${selectionOpened ? 'navItem' : ''}`}
          onMouseEnter={() => setSelectionOpened(!selectionOpened)}>
          <Flex direction="row" sx={classes['.insightsBox']}>
            <VStack spacing={theme.space[2]} sx={classes['.navItemVStack']}>
              <Grid
                sx={classes['.iconWrapper']}
                onClick={() => setSelectionOpened(!selectionOpened)}>
                {navComponents.Insights}
              </Grid>
              <Text className="action-group-spacing" align={'center'}>
                {actionGroupOptionLabel}
              </Text>
            </VStack>

            <Spacer />
            <Grid sx={classes['.insightsCarat']}>
              <ChevronRightIcon
                width={theme.space[8]}
                height={theme.space[10]}
              />
            </Grid>
          </Flex>
        </Flex>
      </PopoverTrigger>
      <SelectionComponent dashboardSelectionOpened={selectionOpened} />
    </Popover>
  );
};
