import React from 'react';
import { Icon, useStyleConfig } from '@chakra-ui/react';
import { IconNames, Icons } from '@codametrix/shared-views';

interface CMXIconProps {
  iconName: IconNames;
  variant?: string;
  size: string;
  fidelity?: string;
}

function CreateViewBox(fidelity: string): string {
  let viewBoxSettings: string;

  switch (fidelity) {
    case 'detailed':
      viewBoxSettings = '0 0 32 32';
      break;
    case 'standard':
      viewBoxSettings = '0 0 20 20';
      break;
    default:
      viewBoxSettings = '0 0 20 20';
      break;
  }
  return viewBoxSettings;
}

function CMXIconComponent(props: CMXIconProps) {
  const { iconName, size, variant, fidelity, ...rest } = props;
  let viewBoxSettings = CreateViewBox(fidelity || 'standard');

  const styles = useStyleConfig('CMXIconStyles', { size, variant });
  return (
    <Icon __css={styles} viewBox={viewBoxSettings} role="img" {...rest}>
      {Icons[iconName]()}
    </Icon>
  );
}

export { CMXIconComponent };
