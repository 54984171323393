export const defaultGroup: CMxAPI.Group = {
  id: undefined,
  tenantId: '',
  groupName: '',
  groupType: '',
  active: false
};

export const groups = {
  defaultGroup
};
