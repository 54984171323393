import theme from '../../theme';

export const styles = () => ({
  btnHeader: {
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'row',
    columnGap: theme.space[120]
  }
});
