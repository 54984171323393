/// <reference path="../types.d.ts" />
import InitialState from '../reducers/initial-state';
import { showFeedback, dismissFeedback } from '../actions/ui';
import { AnyAction } from 'redux';
import { cmxReducerFactory } from './reducer-utils';

function handleDefault(state: any, { meta }: AnyAction) {
  if (meta && (meta as any).user) {
    return { ...state, feedback: null };
  }
  // for the moment, all actions are removing application feedback.
  // this may not be tenable in the long run.
  return { ...state };
}

const reducer = cmxReducerFactory(InitialState.cmx)
  .case(showFeedback, (state, feedback) => ({ ...state, feedback }))
  .case(dismissFeedback, (state, dismiss) => ({ ...state, feedback: null }))
  .default(handleDefault);

export default reducer;
