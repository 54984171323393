/// <reference path="../../types.d.ts" />

import { connect } from 'react-redux';

import { default as LoginView } from './login';
import { authenticate, submitNewPassword } from '../../actions/login.action';
import { selectContext } from '../../actions/contexts';
import { push } from 'connected-react-router';
import { saveUserPreference } from '../../actions/user-preferences';
import { commonEnums } from '@codametrix/ui-common';
const { UserPreferenceKey } = commonEnums;

const mapStateToProps = function(state: CMx.ApplicationState) {
  return { ...state.login, user: state.ui.activeUser };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    handleOrganizationChoice: (choice: CMx.OrganizationFormChoice) => {
      dispatch(selectContext(choice));
      if (choice.setDefault && choice.userId && choice.organizationId) {
        dispatch(
          saveUserPreference({
            userId: choice.userId,
            value: choice.organizationId?.toString(),
            keyname: UserPreferenceKey.DEFAULT_USER_CONTEXT
          })
        );
      }
    },
    handlePasswordChange: (choice: CMx.NewPasswordData) => {
      dispatch(submitNewPassword(choice));
    },
    doLogin: (data: CMx.LoginData) => dispatch(authenticate(data)),
    handleForgotPassword: () => {
      dispatch(push(`/forgot-password/`));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);
