/// <reference path="../types.d.ts" />

import InitialState from './initial-state';
import { groupActions } from '../actions/groups';
import { LOAD_STATES } from './common';

import { cmxReducerFactory } from './reducer-utils';
import { groups } from '../stubs/groups';

const reducer = cmxReducerFactory(InitialState.group)
  .case(groupActions.listItems.async.started, state => {
    return {
      ...state,
      items: [],
      loadState: LOAD_STATES.initial
    };
  })
  .case(groupActions.listItems.async.failed, state => {
    return { ...state, loadState: LOAD_STATES.failed };
  })
  .case(groupActions.listItems.async.done, (state, { result }) => {
    const { content } = result;

    const { sortablePageable } = state.groupsList;
    const sp = { ...sortablePageable, pageableDefinition: result };

    return {
      ...state,
      loadState: LOAD_STATES.done,
      items: content,
      sortablePageable: sp,
      selectedGroup: {
        ...groups.defaultGroup
      }
    };
  })
  .case(groupActions.saveGroup.async.started, state => {
    return {
      ...state,
      loadState: LOAD_STATES.started
    };
  })
  .case(groupActions.getGroupDetail.async.started, state => {
    return { ...state, loadState: LOAD_STATES.started };
  })
  .case(groupActions.getGroupDetail.async.failed, state => {
    return { ...state, loadState: LOAD_STATES.failed };
  })
  .case(groupActions.getGroupDetail.async.done, (state, { result }) => {
    return { ...state, loadState: LOAD_STATES.done };
  })
  .case(groupActions.chooseGroup, (state, item) => {
    return { ...state, selectedGroup: item };
  })
  .case(groupActions.listEligibleGroupMembers, (state, groupMembers) => {
    return {
      ...state,
      eligibleGroupMembers: groupMembers
    };
  });

export default reducer;
