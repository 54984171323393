import React from 'react';
import './divider.scss';

interface DividerProps {
  isVertical?: Boolean;
}
export const Divider = (props: DividerProps) => {
  return (
    <div
      className={'divider-component' + (props?.isVertical ? ' vertical' : '')}>
      <div className="divider-component-begin"></div>
      <div className="divider-component-mid"></div>
      <div className="divider-component-end"></div>
    </div>
  );
};
