import { Card } from '@codametrix/ui-components';
import { push } from 'connected-react-router';
import { objectUtils } from '@codametrix/ui-common';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

const ServiceLineMessage = () => {
  const { serviceLine } = useSelector(
    (state: CMx.ApplicationState) => state.ui
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const path = history.location.pathname;
  const actionGroups = useSelector(
    (state: CMx.ApplicationState) => state.ui.actionGroups
  );
  const activeActionGroupKey = actionGroups.find(
    ag => ag.options[0].active === true
  )?.key;
  useEffect(() => {
    if (serviceLine) {
      dispatch(push(`${path}${serviceLine.name.toLowerCase()}/`));
    }
  }, [serviceLine, dispatch, path]);

  if (!serviceLine) {
    return (
      <Card className={'content-vacant qa-service-line-message'}>
        <h2>
          <b>No service line available</b>
        </h2>
        <h4>
          {objectUtils.startCase(activeActionGroupKey)} requires a service line.
          Please contact an administrator to add one.
        </h4>
        <p> support@codametrix.com </p>{' '}
      </Card>
    );
  } else {
    return <div className={'qa-empty-service-line-message'}></div>;
  }
};

export default ServiceLineMessage;
