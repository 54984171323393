import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { archiveRow, syncActions } from '../../../../actions/dictionary-values';
import { createDataGridRows } from '../../../../reducers/state/dictionary-values';
import { commonEnums } from '@codametrix/ui-common';
import './archive-modal.scss';
import { isResourceViewOnly } from '../../../../core/resources';
import { ModalComponent } from '../../../../components/Modal/modal';
import { Divider, Flex, Text } from '@chakra-ui/react';
import { DataGrid } from '../../../../components/data-grid/data-grid';
import { ColDef } from 'ag-grid-community';
import { CMxComponents } from '@codametrix/ui-components/src/types';
import { Button } from '../../../../components/button/button';
import LoadingOverlay from '../../../../components/loader-overlay/loader-overlay';
import { CustomDictionaryValueHeader } from '../custom-dictionary-value-list-header';
import { Action } from 'redux';
const { ResourceId } = commonEnums;

const shimTenantColumn = (
  rows: CMxAPI.DictionaryRow[],
  tenants: CMxCommonApp.FieldDefinition[]
) => {
  const dataGridRows = createDataGridRows(rows);
  return rows.map((row, index) => {
    const tenantMatch = tenants.find(
      tenant => tenant.key === row.tenantId.uuid
    );

    return {
      ...dataGridRows[index],
      organizationCode: tenantMatch?.organizationCode
    };
  });
};

const updatedColDefs = (columnDefs: CMxComponents.ColumnDefintion[]) => {
  const cols: ColDef[] = columnDefs.map(
    (col: CMxComponents.ColumnDefintion) => {
      let column: ColDef = {
        ...col,
        headerName: col.displayName,
        field: col.key
      };
      return column;
    }
  );
  return cols;
};

export const ArchiveModal = () => {
  const dispatch = useDispatch();

  const {
    archiveModal: { potentialArchives, selectedRow, loadState, archiveResult },
    sortablePageable,
    selectedDictionary,
    columnDefs
  } = useSelector((state: CMx.ApplicationState) => state.dictionaryValues);

  const tenants = useSelector(
    (state: CMx.ApplicationState) =>
      state.dictionaryTenants.sortablePageable.pageableDefinition.content
  );

  const viewOnly = useSelector((state: CMx.ApplicationState) =>
    isResourceViewOnly(
      state.ui.context.activeContext?.resources || [],
      ResourceId.DICTIONARY
    )
  );

  const gridRows = shimTenantColumn([selectedRow], tenants);
  const potentialArchiveGridRows = shimTenantColumn(potentialArchives, tenants);
  const archiveResultGridRows = shimTenantColumn(archiveResult, tenants);
  const defaultColumnKeys = ['effectiveDate', 'endDate', 'remove'];
  const [colDefs, setColDefs] = useState<ColDef[]>([]);

  const submitArchive = (archiveChildren: boolean) => {
    const payload: CMx.archiveRowPayload = {
      archiveChildren,
      row: selectedRow,
      dictionary: selectedDictionary,
      sortablePageable: sortablePageable
    };
    dispatch((archiveRow(payload) as any) as Action);
  };

  const closeArchiveModal = () => {
    dispatch(syncActions.toggleArchiveModal(false));
  };

  const inFlight = loadState.loading;
  const tenatIsCodametrix =
    selectedRow.tenantId.uuid === 'BB4D9AA3-3047-4542-8FB1-8C9BD6E4D75C';

  const showPotentialArchives =
    !tenatIsCodametrix && potentialArchives.length > 0;

  const archiveChildrenButtonDisabled =
    potentialArchives.length === 0 || viewOnly;

  const archiveButtonDisabled = viewOnly;

  useEffect(() => {
    if (columnDefs?.length) {
      const colDefsWithTenant = [
        ...columnDefs,
        { key: 'organizationCode', displayName: 'Organization' }
      ].filter(colDef => !defaultColumnKeys.includes(colDef.key));
      const coldefs = updatedColDefs(colDefsWithTenant);
      setColDefs(coldefs);
    }
    // eslint-disable-next-line
  }, [columnDefs]);

  console.log(archiveButtonDisabled, archiveChildrenButtonDisabled);

  return (
    <ModalComponent
      size={'xl'}
      isOpen={true}
      handleClose={closeArchiveModal}
      showCloseIcon={true}
      modalHeader={<Text>Archive Row</Text>}
      width={'80rem'}
      modalContent={
        <div style={{ padding: '16px' }}>
          {!inFlight ? (
            <div>
              {!archiveResult.length ? (
                <div>
                  <Text style={{ paddingBottom: '8px' }}>
                    You have chosen to archive row.
                  </Text>{' '}
                  <DataGrid
                    columnDefs={colDefs}
                    rowData={gridRows}
                    customHeader={CustomDictionaryValueHeader}
                  />{' '}
                  {showPotentialArchives ? (
                    <div>
                      <Divider />
                      {potentialArchives.length > 1 ? (
                        <Text style={{ paddingBottom: '8px' }}>
                          There are {potentialArchives.length} matching rows for
                          dictionary <b>{selectedRow.dictionaryName}</b> in the
                          following child organizations.{' '}
                        </Text>
                      ) : (
                        <Text style={{ paddingBottom: '8px' }}>
                          There is a matching row for dictionary{' '}
                          <b>{selectedRow.dictionaryName}</b> in a child
                          organization.{' '}
                        </Text>
                      )}
                      <DataGrid
                        columnDefs={colDefs}
                        rowData={potentialArchiveGridRows}
                        customHeader={CustomDictionaryValueHeader}
                      />
                    </div>
                  ) : null}
                </div>
              ) : (
                <div>
                  <Text>The following rows have been archived</Text>
                  <DataGrid
                    columnDefs={colDefs}
                    rowData={archiveResultGridRows}
                    customHeader={CustomDictionaryValueHeader}
                  />
                </div>
              )}
            </div>
          ) : (
            <Flex height={'250px'}>
              <LoadingOverlay />
            </Flex>
          )}
        </div>
      }
      modalFooter={
        <div>
          {!inFlight && !archiveResult.length ? (
            <Flex gap={'16px'}>
              <Button
                label={'Archive Row'}
                name={'Archive Row'}
                dataTestId={'Archive Row'}
                onClick={() => submitArchive(false)}
                disabled={archiveButtonDisabled}
              />
              {!tenatIsCodametrix ? (
                <Button
                  disabled={archiveChildrenButtonDisabled}
                  onClick={() => submitArchive(true)}
                  label={'Archive with children'}
                  name={'Archive with children'}
                  dataTestId={'Archive with children'}
                />
              ) : null}
            </Flex>
          ) : null}
        </div>
      }
    />
  );
};
