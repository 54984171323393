import React, { useEffect, useState } from 'react';
import { Select } from '../../components/select/select';
import { Button } from '../../components/button/button';
import theme from '../../theme';
import { Grid, GridItem, Text } from '@chakra-ui/react';
import {
  options,
  filterOptions,
  ALL_USERS,
  HIDE_EXTERNAL_USERS
} from './user-list-constants';
import { styles } from './user-list-styles';
import { ButtonSize } from '../../core/enums';
import { DataGrid } from '../../components/data-grid/data-grid';
import { gridOptions, userListColumnDefs, endPoint } from './user-list-utils';
import { SearchBar } from '../../components/search-bar/search-bar';
import { RowsPerPage } from '../../components/rows-per-page/rows-per-page';

export const UserList: React.FC<AppProps.UserListProps> = props => {
  const { auth, rootContext, addUser, editPermission } = props;
  const classes = styles();

  const [selectedValue, setSelectedValue] = useState<string>(options[1].value);
  const [selectedFilter, setSelectedFilter] = useState<string>(
    filterOptions[1].value
  );
  const [searchText, setSearchText] = useState<string>('');
  const [pageSize, setPageSize] = useState<number>(25);
  const [filterableOptions, setFilterableOptions] = useState<
    CMxCommonApp.FilterableOption
  >();

  const onOptionChange = (e: any) => {
    setSelectedValue(e?.target?.textContent);
  };

  const onSearchInputChange = (e: any) => {
    setSearchText(e?.target?.value);
  };

  const onFilterOptionChange = (e: any) => {
    setSelectedFilter(e?.target?.textContent);
  };

  const onAddUserClick = (event: any) => {
    event.preventDefault();
    addUser(auth, rootContext);
  };

  const generateNewFilterList = () => {
    const newFilterableOptions: CMxCommonApp.FilterableOption = {};
    const newFilterList: CMxCommonApp.Filter[] = [];
    if (selectedFilter === filterOptions[0].value) {
      newFilterList.push(ALL_USERS);
    } else {
      newFilterList.push(HIDE_EXTERNAL_USERS);
    }

    if (searchText.trim().length) {
      if (selectedValue === options[0].value) {
        newFilterList.push({
          key: 'username',
          terms: [searchText.trim()],
          type: 'LIKE'
        });
      } else {
        newFilterList.push({
          key: 'lastName',
          terms: [searchText.trim()],
          type: 'LIKE'
        });
      }
    }
    newFilterableOptions.filters = newFilterList;
    return newFilterableOptions;
  };

  const onSearchKeyPress = (event: any) => {
    if (event.keyCode === 13) {
      const SortablePageable: CMxCommonApp.FilterableOption = generateNewFilterList();
      setFilterableOptions(SortablePageable);
    }
  };

  const handleRowSelection = (rowdata: CMxAPI.User) => {
    props.getDetail(auth, rowdata, rootContext);
  };

  useEffect(() => {
    const SortablePageable: CMxCommonApp.FilterableOption = generateNewFilterList();
    setFilterableOptions(SortablePageable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter]);

  const onPageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
  };

  return (
    <Grid sx={classes.container}>
      <Grid
        flexDirection={'row'}
        gridTemplateColumns={`1fr ${theme.space[100]}`}>
        <GridItem>
          <SearchBar
            selectOptions={options}
            selectedOption={selectedValue}
            onSelectOptionChange={onOptionChange}
            onSearchInputChange={onSearchInputChange}
            onSearchKeyPress={onSearchKeyPress}
          />
        </GridItem>
        <GridItem sx={classes.addUserButtonGrid}>
          <Button
            size={ButtonSize.MD}
            label={'Add User'}
            backgroundColor={theme.colors.accent[100]}
            color={theme.colors.text['light']}
            onClick={onAddUserClick}
            dataTestId={'createUserButton'}
          />
        </GridItem>
      </Grid>
      {editPermission && (
        <Grid
          display={'flex'}
          flexDirection="row"
          justifyContent={'space-between'}>
          <Grid
            templateColumns={`${theme.space[80]} 0.5fr 1fr 1fr`}
            sx={classes.filterGrid}>
            <GridItem sx={classes.textGridItem}>
              <Text>User Filters: </Text>
            </GridItem>
            <GridItem>
              <Select
                value={selectedFilter}
                items={filterOptions}
                onChange={onFilterOptionChange}
                dataTestId={'userFilterSelect'}
              />
            </GridItem>
          </Grid>
          <Grid>
            <RowsPerPage
              pageSize={pageSize}
              handlePageSize={onPageSizeChange}
            />
          </Grid>
        </Grid>
      )}
      <GridItem>
        <DataGrid
          columnDefs={userListColumnDefs}
          gridOptions={gridOptions}
          serverSideInfiniteScroll
          filterableOptions={filterableOptions}
          endpointData={endPoint}
          onRowClicked={item => handleRowSelection(item?.data)}
          paginationPageSize={pageSize}
        />
      </GridItem>
    </Grid>
  );
};
