import { commonEnums } from '@codametrix/ui-common';
const { SystemRole } = commonEnums;

// PLAIN OLD JSON ONLY
const togglePHIMask = {
  keystrokes: ['p h i'],
  id: 'detailTogglePHI',
  label: 'Show PHI',
  statePath: 'ui.phiMask',
  roles: [
    SystemRole.DEMO,
    SystemRole.COORDINATOR,
    SystemRole.SUPER_ADMIN,
    SystemRole.ADMIN
  ]
};

const showCaseJson = {
  keystrokes: ['j s o n'],
  id: 'showCaseJSON',
  label: 'Show Case JSON',
  statePath: 'cases.activeItem',
  roles: [SystemRole.COORDINATOR, SystemRole.SUPER_ADMIN, SystemRole.ADMIN]
};

const showForceOption = {
  keystrokes: ['p a s s'],
  id: 'toggleForcePasswordOption',
  label: 'Show Force Password Change Option',
  statePath: 'users.passwordChangeEnabled',
  roles: [SystemRole.USER_MANAGER, SystemRole.SUPER_ADMIN, SystemRole.ADMIN]
};

const showProcessingTimeline = {
  keystrokes: ['t i m e'],
  id: 'showProcessingTimeline',
  label: 'Show Processing Time Line',
  statePath: 'cases.displayTimeline',
  roles: [
    SystemRole.COORDINATOR,
    SystemRole.DEMO,
    SystemRole.SUPER_ADMIN,
    SystemRole.ADMIN
  ]
};

const shortcuts: CMx.ShortcutBinding[] = [
  togglePHIMask,
  showCaseJson,
  showForceOption,
  showProcessingTimeline
];

export { shortcuts };
