/// <reference path="../types.d.ts" />

import InitialState from './initial-state';
import * as dictionary from '../actions/dictionary';
import { LOAD_STATES } from './common';
import { cmxReducerFactory } from './reducer-utils';

const reducer = cmxReducerFactory(InitialState.dictionaryTenants)
  .case(dictionary.getTenantList.async.started, (state, params) => {
    return { ...state, loadState: LOAD_STATES.started };
  })
  .case(dictionary.getTenantList.async.done, (state, { result }) => {
    const {
      sortablePageable: { pageableDefinition }
    } = state;
    pageableDefinition.numberOfElements = result.length;
    pageableDefinition.content = result;
    pageableDefinition.totalElements = result.length;

    return {
      ...state,
      loadState: LOAD_STATES.done,
      sortablePageable: { ...state.sortablePageable, pageableDefinition }
    };
  });

export default reducer;
