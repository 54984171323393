export const capturedTime: CMx.CapturedTimeState = {
  capturedTimeIsOpen: false,
  capturedTimeWeek: [
    {
      userId: 0,
      tenantId: 0,
      name: '',
      dateOfEntry: '',
      day: 'Sunday',
      hoursWorked: 0
    },
    {
      userId: 0,
      tenantId: 0,
      name: '',
      dateOfEntry: '',
      day: 'Monday',
      hoursWorked: 0
    },
    {
      userId: 0,
      tenantId: 0,
      name: '',
      dateOfEntry: '',
      day: 'Tuesday',
      hoursWorked: 0
    },
    {
      userId: 0,
      tenantId: 0,
      name: '',
      dateOfEntry: '',
      day: 'Wednesday',
      hoursWorked: 0
    },
    {
      userId: 0,
      tenantId: 0,
      name: '',
      dateOfEntry: '',
      day: 'Thursday',
      hoursWorked: 0
    },
    {
      userId: 0,
      tenantId: 0,
      name: '',
      dateOfEntry: '',
      day: 'Friday',
      hoursWorked: 0
    },
    {
      userId: 0,
      tenantId: 0,
      name: '',
      dateOfEntry: '',
      day: 'Saturday',
      hoursWorked: 0
    }
  ],
  protectedTimeWeek: [],
  maxAllowedBackdate: '30',
  noPastCapturedTimeFlag: false,
  needRefreshFlag: false,
  addTimeFailed: false
};
