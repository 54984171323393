import theme from '../../../theme';

export const styles = () => ({
  label: {
    paddingTop: theme.space[8],
    color: theme.colors.text['meduimEmphasis'],
    ...theme.textStyles['h1']
  },
  input: theme.components.Input.variants.text
});
