import Splash1 from '../../assets/backgrounds/cmx-splash-1.jpg';
import Splash2 from '../../assets/backgrounds/cmx-splash-2.jpg';

const key = 'TIMESTAMP';
export const getStyledBackgroundImage = () => {
  var backgroundStyle = {
    backgroundImage: `url(${Splash1.toString()})`,
    backgroundRepeat: 'no-repeat center fixed',
    backgroundPosition: 'center'
  };
  const timestamp = sessionStorage.getItem(key) || Date.now().toString();
  sessionStorage.setItem(key, timestamp);

  if (parseInt(timestamp) % 2 === 0) {
    return {
      ...backgroundStyle,
      backgroundImage: `url(${Splash1.toString()})`
    };
  } else {
    return {
      ...backgroundStyle,
      backgroundImage: `url(${Splash2.toString()})`
    };
  }
};
