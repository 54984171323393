/// <reference path="../types.d.ts" />

import InitialState from '../reducers/initial-state';
import { LOAD_STATES } from './common';

import * as assigningAuthorityActions from '../actions/assigning-authorities';
import { jumpContext } from '../actions/contexts';
import { cmxReducerFactory } from './reducer-utils';

const reducer = cmxReducerFactory(InitialState.assigningAuthorities)
  .cases([jumpContext.async.started], state => {
    return { ...InitialState.assigningAuthorities };
  })
  .case(
    assigningAuthorityActions.getHospitals.async.started,
    (state, hospitalList) => {
      return { ...state, hospitalList, loadState: LOAD_STATES.started };
    }
  )
  .case(
    assigningAuthorityActions.getHospitals.async.failed,
    (state, hospitalList) => {
      return { ...state, hospitalList, loadState: LOAD_STATES.failed };
    }
  )
  .case(
    assigningAuthorityActions.getHospitals.async.done,
    (state, hospitalList) => {
      var list: any[] = [];

      (hospitalList.result as any[]).forEach(element => {
        list.push({
          id: element.hospitalId,
          hospitalName: element.hospitalName
        });
      });

      state.items = list;
      return { ...state, loadState: LOAD_STATES.done };
    }
  )
  .case(
    assigningAuthorityActions.loadForm.async.started,
    (state, assigningAuthorityGroup) => {
      return { ...state, loadState: LOAD_STATES.started };
    }
  )
  .case(
    assigningAuthorityActions.loadForm.async.failed,
    (state, assigningAuthorityGroup) => {
      return { ...state, loadState: LOAD_STATES.failed };
    }
  )

  .case(assigningAuthorityActions.loadForm.async.done, (state, { result }) => {
    return {
      ...state,
      activeItem: result.group,
      currentHositalId: result.id,
      loadState: LOAD_STATES.done
    };
  });

export default reducer;
