import React from 'react';
import {
  Checkbox as ChakraCheckbox,
  CheckboxProps,
  Grid,
  Text
} from '@chakra-ui/react';
import { getIconColor, styles } from './checkbox.styles';
import theme from '../../theme';

export enum CheckboxVariant {
  OUTLINED = 'outlined',
  CONTAINED = 'contained',
  CIRCULAR_OUTLINED = 'circularOutlined',
  CIRCULAR_CONTAINED = 'circularContained'
}

export enum CheckboxSize {
  SM = 'sm',
  MD = 'md',
  LG = 'lg'
}

export type CMXCheckboxProps = {
  label?: string;
  isChecked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  variant?: CheckboxVariant;
  disabled?: boolean;
  defaultChecked?: boolean;
  _className?: any;
  checkboxColor?: string;
  size?: CheckboxSize;
  dataTestId?: string;
  onClick?: () => void;
} & CheckboxProps;

const Checkbox: React.FC<CMXCheckboxProps> = props => {
  const {
    label,
    isChecked,
    disabled,
    onChange,
    variant,
    checkboxColor,
    size,
    _className,
    dataTestId,
    onClick,
    textColor
  } = props;

  const classes = styles(checkboxColor, variant);
  const iconColor = getIconColor(checkboxColor, variant);

  const handleGridClick = (e: React.MouseEvent) => {
    if (onClick) {
      e.preventDefault();
      onClick();
    }
  };

  return (
    <Grid
      display="flex"
      flexDirection="row"
      alignItems="center"
      onClick={e => {
        handleGridClick(e);
      }}>
      <ChakraCheckbox
        isChecked={isChecked}
        isDisabled={disabled}
        size={size}
        sx={{ ...classes, ..._className }}
        iconColor={iconColor}
        onChange={onChange}
        data-testid={dataTestId?.trim() ?? 'checkbox'}
        {...props}>
        <Text
          noOfLines={1}
          color={textColor ?? theme.colors.text['highEmphasis']}>
          {label}
        </Text>
      </ChakraCheckbox>
    </Grid>
  );
};

Checkbox.defaultProps = {
  disabled: false,
  className: '',
  variant: CheckboxVariant.CONTAINED,
  isChecked: false,
  defaultChecked: false,
  checkboxColor: theme.colors.foundation[300],
  size: CheckboxSize.MD
};

Checkbox.displayName = 'Checkbox';

export { Checkbox };
