import { CMxComponents } from '@codametrix/ui-components/src/types';
import { commonEnums, appServiceLines } from '@codametrix/ui-common';
import { navComponents } from '../components/primary-global-navigation/nav-icons';
const { isIPC } = appServiceLines;
const { PermissionLevel } = commonEnums;

export const AdminTabs: CMxComponents.TabDef[] = [
  {
    displayName: 'Organizations',
    active: false,
    resourceId: 'organizations',
    id: 'organization',
    path: 'admin/orgs/',
    enabled: true,
    permissionLevel: PermissionLevel.DENY
  },
  {
    displayName: 'Users',
    active: false,
    id: 'users',
    resourceId: 'users',
    path: 'admin/users/',
    enabled: true,
    permissionLevel: PermissionLevel.DENY
  },
  {
    displayName: 'Audit Log',
    active: false,
    resourceId: 'audit_log',
    id: 'audit_log',
    path: 'admin/auditLog/',
    enabled: true,
    permissionLevel: PermissionLevel.DENY
  },
  {
    displayName: 'Rules',
    active: false,
    id: 'rules',
    resourceId: 'rules',
    path: 'admin/rules/',
    enabled: true,
    permissionLevel: PermissionLevel.DENY
  }
];

export const AdminAction = {
  enabled: true,
  label: 'Admin',
  icon: navComponents.Admin,
  id: 'users',
  path: 'admin/',
  active: false,
  subActions: AdminTabs
};

export const WorklistAction = {
  enabled: true,
  id: 'worklist',
  icon: navComponents.Worklist,
  label: 'Worklist',
  path: 'worklist/',
  active: false,
  subActions: []
};

export const QueueSettingsAction = {
  enabled: true,
  id: 'worklist_settings',
  icon: navComponents['Queue Settings'],
  label: 'Queue Settings',
  path: 'queue-settings/',
  active: false,
  subActions: []
};

export const analytics = {
  enabled: true,
  id: 'dashboard',
  icon: navComponents.Analytics,
  label: 'Analytics',
  path: 'analytics/',
  active: false,
  subActions: [],
  onSelection: (action: CMxAPI.NavAction) => {
    // this no-op is here to stop an active selection by the user.
    // when we implement the analytics handler, we'll remove this function.
  }
};

export const tableauDashboard = {
  enabled: true,
  id: 'tableau_dashboard',
  icon: navComponents.Insights,
  label: 'Insights',
  path: 'tableau/',
  active: false,
  subActions: [],
  navSubResources: ['manager_productivity_reports', 'pre-live_insights']
};

export const providerDirectory = {
  enabled: true,
  id: 'PROVIDER_DIRECTORY',
  icon: navComponents['Provider Directory'],
  label: 'Provider Directory',
  path: 'provider_directory/',
  active: false,
  subActions: []
};

export const CodingQualityAction = {
  enabled: true,
  id: 'coding_quality',
  icon: navComponents['Coding Quality'],
  label: 'Coding Quality',
  path: 'coding-quality/',
  active: false,
  subActions: []
};

export const QualityAssessmentAction = {
  enabled: true,
  id: 'quality_assessment',
  icon: navComponents['Quality Assessment'],
  label: 'Quality Assessment',
  path: 'quality-assessment/',
  active: false,
  subActions: []
};

export const NAV_OPTIONS: CMxAPI.NavActionGroup[] = [
  {
    key: 'tableau',
    options: [tableauDashboard]
  },
  {
    key: 'cases',
    options: [
      {
        enabled: true,
        id: 'cases',
        icon: navComponents.Cases,
        label: 'Cases',
        path: 'cases/',
        active: false,
        subActions: []
      }
    ]
  },
  {
    key: 'caselist_old',
    options: [
      {
        enabled: true,
        id: 'caselist_old',
        icon: navComponents['Cases (Classic)'],
        label: 'Cases (Classic)',
        path: 'cases-old/',
        active: false,
        subActions: []
      }
    ]
  },
  {
    key: 'worklist',
    options: [WorklistAction]
  },
  {
    key: 'worklist_settings',
    options: [QueueSettingsAction]
  },
  {
    key: 'coding_quality',
    options: [CodingQualityAction]
  },
  {
    key: 'quality_assessment',
    options: [QualityAssessmentAction]
  },
  {
    key: 'admin',
    options: [AdminAction]
  },
  // TODO: show this conditionally once the new resources are added
  {
    key: 'provider_directory',
    options: [providerDirectory]
  }
];

export const retrieveGroups = (
  context: CMxAPI.OrganizationContext,
  serviceLine?: string
) => {
  const availableActions: string[] = [];
  let serviceLineName = isIPC(serviceLine ?? '')
    ? 'Inpatient Professional Coding'
    : serviceLine;

  // Add resources either if it is allowed at all service lines
  // or if the user is enrolled in the resource's corresponding service lines
  context.resources.forEach(resource => {
    if (
      resource.serviceLines?.includes('*') ||
      resource.serviceLines?.includes(serviceLineName ?? '')
    ) {
      availableActions.push(resource.resourceName);
    }
  });

  const navOptions: CMxAPI.NavActionGroup[] = JSON.parse(
    JSON.stringify(NAV_OPTIONS)
  );

  navOptions.forEach(navOption => {
    navOption.options.forEach(option => {
      option.subActions?.forEach(subAction => {
        subAction.enabled = availableActions.some(
          resourceName => resourceName === subAction.resourceId
        );
      });
      option.enabled = availableActions.some(resourceName => {
        if (option.subActions.length > 0) {
          return option.subActions.find(
            subAction => resourceName === subAction.resourceId
          );
        } else {
          return option.id === resourceName;
        }
      }); // add ` || true` to turn on items for testing.
    });
  });
  return navOptions;
};
