import { css } from '@emotion/react';
import { CSSProperties } from 'react';
import theme from '../../theme';

export const gridStyles = css`
  & .ag-header {
    background-color: ${theme.colors.structural[100]};
  }
  & .ag-row {
    border-color: ${theme.colors.foundation[100]};
    background-color: ${theme.colors.text['light']};
    border-top: none;
  }
  & .ag-row:hover {
    background-color: ${theme.colors.structural[300]};
    cursor: pointer;
  }
  & .ag-cell {
    height: 30px;
    color: ${theme.colors.text['highEmphasis']};
    font-size: ${theme.textStyles['Body 2'].fontSize};
    font-weight: ${theme.textStyles['Body 2'].fontWeight};
    font-family: Roboto;
  }
  & .ag-center-cols-viewport {
    width: 100%;
  }
  & .ag-root-wrapper {
    border: none;
  }
  .cellStyle {
    color: ${theme.colors.accent[100]};
  }
  & .ag-header-cell-text {
    color: ${theme.colors.text['mediumEmphasis']};
    font-size: ${theme.textStyles['Body 2'].fontSize};
    line-height: ${theme.textStyles['Body 2'].lineHeight};
    font-weight: 600;
    font-family: Roboto;
  }
  & .ag-center-cols-clipper,
  .ag-center-cols-container {
    min-height: 100px !important;
  }
  & .ag-menu {
    background-color: #f8f8f8;
  }
`;

export const styles = (): Record<string, CSSProperties> => ({
  root: {
    height: '100%',
    width: '100%'
  }
});
