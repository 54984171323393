/// <reference path="../../../src/types.d.ts" />

import { CODAMETRIX } from '../../views/organizations/stub-organization';
import { LOAD_STATES } from '../common';
import { defaultFilterableOptions } from '../../core/default-filterable-options';
import { commonEnums as enums, cmxDateTime } from '@codametrix/ui-common';

import {
  logTypeCriteria,
  dateCriteria
} from '../audit-log/audit-log-field-definitions';
import { AmplifyCore } from '@codametrix/ui-common';

const { paginationUtils } = AmplifyCore;

const { FilterTypes } = enums;

export const auditLogListPath: CMx.DisplayablePath = {
  displayName: 'Audit Log',
  path: '/cmx/admin/auditLog/'
};

const defaultDateFilter: CMxCommonApp.DateFilter = {
  key: `date_of_service`,
  type: `BETWEEN`,
  from: cmxDateTime.format(new Date(), cmxDateTime.FORMATS.DATE, {
    throw: false,
    localized: true,
    offset: {
      action: cmxDateTime.DateManipulateOperation.SUBTRACT,
      delta: [2, cmxDateTime.DateManipulateUnit.DAY]
    }
  }),
  to: cmxDateTime.format(new Date(), cmxDateTime.FORMATS.DATE)
};

export const defaultSortablePageable: CMxCommonApp.SortablePageable<
  CMxAPI.DictionaryAction | CMxAPI.OrganizationAction
> = {
  pageableDefinition: { ...paginationUtils.emptyPageable },
  sortableOptions: [
    {
      sortField: 'eventDate',
      sortDirection: 'DESC'
    }
  ],
  filterableOptions: {
    dateFilter: defaultDateFilter,
    ...defaultFilterableOptions
  }
};

const tenantIdCriteria: CMxCommonApp.FieldDefinition = {
  label: 'Tenant ID',
  key: 'tenantId',
  helpText: '',
  placeholder: 'Tenant Id',
  required: true,
  type: FilterTypes.LIKE,
  isSelected: true
};

const dictionaryCriteria: CMxCommonApp.FieldDefinition = {
  label: 'Dictionary',
  key: 'dictionary',
  helpText: '',
  placeholder: 'Dictionary',
  required: true,
  type: FilterTypes.LIKE,
  isSelected: true
};

const organizationNameCriteria: CMxCommonApp.FieldDefinition = {
  label: 'Organization',
  key: 'organizationName',
  helpText: '',
  placeholder: 'Organization',
  required: true,
  type: FilterTypes.LIKE,
  isSelected: true
};

const usernameCriteria: CMxCommonApp.FieldDefinition = {
  label: 'Username',
  key: 'username',
  helpText: '',
  placeholder: 'Username',
  required: true,
  type: FilterTypes.LIKE,
  isSelected: true
};

export const auditLogCriteriaFilter: AppProps.CriteriaFilter = {
  filters: [],
  dateFilter: defaultDateFilter,
  filterForms: [logTypeCriteria, dateCriteria],
  searchForms: [
    usernameCriteria,
    dictionaryCriteria,
    organizationNameCriteria,
    tenantIdCriteria
  ]
};

const remoteFilters: any[] = [];
const localFilters: any[] = [];

const filterLoadStates = new Map<string, CMxCommonApp.Loading>();

remoteFilters.forEach(key => {
  filterLoadStates.set(key, LOAD_STATES.initial);
});
localFilters.forEach(key => {
  filterLoadStates.set(key, LOAD_STATES.done);
});

export const auditLog: CMx.AuditLogState = {
  label: 'audit_log',
  itemName: 'audit_log',
  auditLogs: [],
  rootContext: CODAMETRIX,
  sortablePageable: defaultSortablePageable,
  loadState: LOAD_STATES.done,
  columnDefs: [],
  criteriaFilter: auditLogCriteriaFilter,
  filterLoadStates
};
