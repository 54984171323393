/// <reference path="../types.d.ts" />
import {
  authenticationContext,
  commonEnums as enums,
  HttpMethods,
  cmxErrors,
  commonActions
} from '@codametrix/ui-common';
import { api } from '../core/net';
import { showModalMessage, dismissFeedback, showFeedbackError } from './ui';
import { Dispatch, Action } from 'redux';
import { Lifeline } from '../views/ui/lifeline';
import { AmplifyCore } from '@codametrix/ui-common';
import { buildRoleSet } from '../reducers/users/roles';

const { paginationUtils } = AmplifyCore;

const { UnauthenticatedError, FormSubmissionError, ServerError } = cmxErrors;

const { ORGS } = enums;
export const EMPTY_PAYLOAD = Object.freeze({});

const associationSet: Set<string> = new Set([ORGS.PHYSICIAN_ORGANIZATION]);

export const fetchConfig = () => {
  return api<CMxAPI.UserConfig>({
    endpoint: `/user/config/v1`
  });
};

export const shimContext = (
  context: CMxAPI.ContextAPI
): CMxCommonApp.Context => {
  // the API returns a data structure that includes an activeContextId, but
  // not an activeContext as an object.  this shim adds an activeContext so
  // that UI code can expect a full object and not have to hunt for the object from the id.
  const { activeContextId } = context;
  if (activeContextId === null) {
    return { ...context, activeContext: null };
  } else {
    const { contexts } = context;
    if (contexts.length === 1) {
      return { ...context, activeContext: contexts[0] };
    } else {
      return {
        ...context,
        activeContext:
          contexts.find(
            ctx => ctx.organizationId === (context as any).activeContextId
          ) || null
      };
    }
  }
};

const isServiceOrganization = (orgType: string): boolean => {
  return associationSet.has(orgType);
};

const destroyToken = async (token: string | null) => {
  // using browser provided fetch as opposed to our net.api
  // if this fails, we don't want more unauthenticated errors.
  if (!token) {
    return Promise.resolve();
  }

  try {
    return await fetch(`/logout`, {
      method: HttpMethods.POST,
      cache: 'no-cache',
      referrer: 'no-referrer',
      redirect: 'manual',
      headers: {
        Authorization: `${token}`
      }
    });
  } catch (e) {
    return Promise.resolve(true);
  }
};

const handleError = async (e: Error, dispatch: Dispatch<Action>) => {
  if (e instanceof FormSubmissionError) {
    // squelch for the time being.
  } else if (e instanceof ServerError) {
    dispatch(
      showFeedbackError({
        message: Lifeline({
          message: `CodaMetrix has encountered an issue.`,
          onAction: () => {
            dispatch(dismissFeedback(true));
          }
        }),
        dismissable: true,
        className: 'feedback-error'
      })
    );
  } else if (e instanceof UnauthenticatedError) {
    await destroyToken(authenticationContext.getToken());

    dispatch(
      showModalMessage({
        title: 'You have been logged out.',
        buttonText: 'Log in',
        closable: false,
        handler: commonActions.UserInterface.LOGOUT,
        forceSubmitOptions: {
          isForced: true,
          message: 'Navigating to login page in ',
          time: 10
        }
      })
    );
  } else {
    // since this error is not an authentication issue
    // we want to re-raise the exception so that other code can
    // more appropriately handle it.
    // throw e;
  }
};

export const buildDispatchable = (dispatch: Dispatch) => {
  return async (fn: () => any) => {
    try {
      return await fn();
    } catch (e) {
      handleError(e as Error, dispatch);
    }
  };
};

/**
 * Turn any list into a pageable implementation.
 * By necessity, this list will be a single page
 * as the number of items must be the totality.
 * @param items
 */
export const onePagePageable = <T>(items: T[]) => {
  const numItems = items.length;
  const defPageable = {
    ...paginationUtils.emptyPageable.pageable,
    totalElements: numItems,
    pageSize: Math.max(50, numItems)
  };

  return {
    ...paginationUtils.emptyPageable,
    content: items,
    size: numItems,
    numberOfElements: numItems,
    totalElements: numItems,
    totalPages: 1,
    last: true,
    pageable: defPageable
  };
};

const bodyParamsFactory = (params: CMxCommonApp.SortablePageable<any>) => {
  return {
    page: params.pageableDefinition.pageable.pageNumber,
    size: params.pageableDefinition.pageable.pageSize
  };
};

const roleShim = (context: CMx.Context, roles: string[]) => {
  const hasRoles = context?.user?.roles?.length > 0;
  const roleList = hasRoles ? buildRoleSet(context) : roles;
  return roleList ?? null;
};

export { isServiceOrganization, destroyToken, bodyParamsFactory, roleShim };
