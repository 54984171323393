import theme from '../../theme';
import { CheckboxVariant } from './checkbox';

export const styles = (propColor?: string, variant?: string) => {
  const color = propColor ?? theme.colors.foundation[300];

  const outlineStyles = {
    borderColor: color,
    _checked: {
      backgroundColor: theme.colors.structural[0],
      borderColor: color,
      _focus: {
        border: 'none'
      },
      _hover: {
        borderColor: color,
        backgroundColor: theme.colors.structural[0]
      }
    },
    _focus: {
      boxShadow: 'none'
    },
    marginRight: theme.space[8]
  };

  const containedStyles = {
    borderColor: color,
    _checked: {
      backgroundColor: color,
      borderColor: color,
      _focus: {
        border: 'none'
      },
      _hover: {
        borderColor: color,
        backgroundColor: color
      }
    },
    _focus: {
      boxShadow: 'none'
    },
    marginRight: theme.space[8]
  };

  switch (variant) {
    case 'outlined':
      return {
        '.chakra-checkbox__control': outlineStyles
      };
    case 'contained':
      return {
        '.chakra-checkbox__control': containedStyles
      };
    case 'circularOutlined':
      return {
        '.chakra-checkbox__control': {
          ...outlineStyles,
          rounded: 'full'
        }
      };
    case 'circularContained':
      return {
        '.chakra-checkbox__control': {
          ...containedStyles,
          rounded: 'full'
        }
      };
    default:
      return {
        '.chakra-checkbox__control': containedStyles
      };
  }
};

export const getIconColor = (color?: string, variant?: string) => {
  let iconColor = theme.colors.structural[0];
  if (
    variant === CheckboxVariant.OUTLINED ||
    variant === CheckboxVariant.CIRCULAR_OUTLINED
  ) {
    iconColor = color ?? theme.colors.foundation[300];
  }
  return iconColor;
};
