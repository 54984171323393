/// <reference path="../../types.d.ts" />

import { connect } from 'react-redux';

import { default as PasswordForm } from './password-change';
import { submitNewPassword, handleContext } from '../../actions/login.action';
import { push } from 'connected-react-router';
import { buildDispatchable } from '../../actions/_action-utilities';

const mapStateToProps = function(state: CMx.ApplicationState) {
  return { ...state.login };
};

const mapDispatchToProps = (dispatch: any) => {
  const runDispatchable = buildDispatchable(dispatch);

  return {
    handleNewPassword: async (choice: CMx.NewPasswordData) => {
      runDispatchable(() => {
        return dispatch(submitNewPassword(choice));
      });
    },

    handleContinue: () => {
      runDispatchable(() => {
        return dispatch(handleContext());
      });
    },
    handleNavigateHome: () => {
      runDispatchable(() => {
        return dispatch(push('/'));
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordForm);
