import { loadStates } from '@codametrix/ui-common';
import { CMxComponents } from '@codametrix/ui-components/src/types';
import generalFields from './general-fields';
import automationFields from './automation-fields';
import routeFields from './route-fields';
import hl7Fields from './hl7-fields';
import { formErrors } from '../../../stubs/form-errors';
const { LOAD_STATES } = loadStates;
export const caseConfigFieldsBySection: {
  [key: string]: CMxCommonApp.FieldDefinition[];
} = {
  GENERAL: generalFields,
  AUTOMATION: automationFields,
  HL7: hl7Fields,
  ROUTE: routeFields
};

export const generalConfigDictionaries: {
  [key: string]: { dictionaryName: string; columnName: string };
} = {
  phase: { dictionaryName: 'Customer Phases', columnName: 'Customer Phases' }
};

export const automationConfigDictionaries: {
  [key: string]: { dictionaryName: string; columnName: string };
} = {
  ml_function: {
    dictionaryName: 'Automation Configuration Values',
    columnName: 'ML Function'
  },
  algorithm: {
    dictionaryName: 'Automation Configuration Values',
    columnName: 'Algorithm'
  },
  ml_version: {
    dictionaryName: 'Automation Configuration Values',
    columnName: 'Version'
  }
};

export const sectionDictionaries: any = {
  GENERAL: generalConfigDictionaries,
  AUTOMATION: automationConfigDictionaries
};
export const caseConfigFormLabels: any = {
  GENERAL: 'General Fields',
  AUTOMATION: 'Automation Fields',
  ROUTE: 'Route Fields',
  HL7: 'HL7 Fields'
};

const generalFieldGroup: CMxCommonApp.FieldGroup = {
  label: `General Fields`,
  fields: generalFields
};

const buttons = [
  { buttonType: 'submit', buttonText: 'Save' },
  { buttonType: 'link', buttonText: 'cancel' }
];

const formDef: CMxComponents.FormDefintion = {
  fieldGroups: [generalFieldGroup],
  buttons
};

const tabListItems: CMxComponents.TabDef[] = [
  {
    id: 'GENERAL',
    resourceId: '',
    displayName: 'General',
    active: true,
    enabled: true,
    permissionLevel: 'N/A'
  },
  {
    id: 'AUTOMATION',
    resourceId: '',
    displayName: 'Automation',
    active: false,
    enabled: true,
    permissionLevel: 'N/A'
  },
  {
    id: 'HL7',
    resourceId: '',
    displayName: 'HL7',
    active: false,
    enabled: true,
    permissionLevel: 'N/A'
  },
  {
    id: 'ROUTE',
    resourceId: '',
    displayName: 'Route',
    active: false,
    enabled: true,
    permissionLevel: 'N/A'
  }
];

export const caseConfig: CMx.CaseConfigState = {
  section: 'GENERAL',
  serviceLine: {
    id: -1,
    name: ''
  },
  dataObject: {},
  formDef: formDef,
  loadState: LOAD_STATES.done,
  caseConfigs: [],
  formErrors: formErrors.noErrors,
  tabListItems
};

const caseConfigPath: CMx.DisplayablePath = {
  displayName: 'Case configuration',
  path: '/cmx/admin/orgs/:orgId(\\d+)/case-config/'
};

export const caseConfigPaths = [caseConfigPath];
