const options = [
  {
    label: 'true',
    value: 'true'
  },
  {
    label: 'false',
    value: 'false'
  }
];

const postManualCharges: CMxCommonApp.FieldDefinition = {
  label: 'Post Manual Charges',
  key: 'post_manual_charges',
  name: 'post_manual_charges',
  required: false,
  type: 'select',
  options,
  labelField: 'label',
  valueField: 'value',
  helpText: 'post_manual_charges'
};

const preventSendToCoder: CMxCommonApp.FieldDefinition = {
  label: 'Prevent Send to Coder',
  key: 'prevent_send_to_coder_indicator',
  name: 'prevent_send_to_coder_indicator',
  required: false,
  type: 'select',
  options,
  labelField: 'label',
  valueField: 'value',
  helpText: 'prevent_send_to_coder_indicator'
};

const preventSendToBilling: CMxCommonApp.FieldDefinition = {
  label: 'Prevent Send to Billing',
  key: 'prevent_billing_indicator',
  name: 'prevent_billing_indicator',
  required: false,
  type: 'select',
  options,
  labelField: 'label',
  valueField: 'value',
  helpText: 'prevent_billing_indicator'
};

const routeFields = [
  postManualCharges,
  preventSendToCoder,
  preventSendToBilling
];

export default routeFields;
