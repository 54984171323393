/// <reference path="../types.d.ts" />

import { commonEnums, HttpMethods } from '@codametrix/ui-common';
import actionCreatorFactory from 'typescript-fsa';
import asyncFactory from 'typescript-fsa-redux-thunk';
import { api } from '../core/net';
import {
  getDefaultOrg,
  getDefaultServiceLine,
  getHomepagePreference
} from '../core/preferences/preferences';
import { saveFormData } from './account-settings';
import { UserPreferences } from './action-types';
import { showFeedbackWarning, setHomepagePreference } from './ui';

const { UserPreferenceSection } = commonEnums;
const actionCreator = actionCreatorFactory();
const createAsync = asyncFactory<void>(actionCreator);

const deletePreferenceValues = createAsync<
  CMx.DeletePreferenceValuesPayload,
  void,
  CMxCommonApp.SubmitError
>(UserPreferences.DELETE_PREFERENCE_VALUE, async (payload, dispatch) => {
  await api<void>({
    endpoint: `/userpreferences/${payload.userId}/${payload.keyname}/v1`,
    init: {
      method: HttpMethods.DELETE
    }
  });
});

const saveUserPreference = createAsync<
  CMx.PreferencePayload,
  void,
  CMxCommonApp.SubmitError
>(UserPreferences.SAVE_PREFERENCE, async (payload, dispatch) => {
  const preferences = await api<CMxAPI.UserPreference[]>({
    endpoint: `/userpreferences/${payload.userId}/v1`,
    init: {
      method: HttpMethods.GET
    }
  });

  if (preferences?.filter(p => p.keyname === payload.keyname).length > 0) {
    await dispatch(deletePreferenceValues(payload));
  }

  const body: Partial<CMxAPI.UserPreference> = {
    section: UserPreferenceSection.USER_PREFERENCE,
    keyname: payload.keyname,
    ownerId: payload.userId,
    values: [
      {
        value: payload.value,
        tenantId: payload.tenantId ?? null,
        preferenceId: null,
        version: null,
        id: null
      }
    ]
  };

  await api<CMxAPI.UserPreference>({
    endpoint: `/userpreferences/v1`,
    init: {
      method: HttpMethods.POST
    },
    body: body
  }).catch(e => {
    dispatch(
      showFeedbackWarning({
        id: Date.now(),
        message: `Unable to save preference`,
        dismissable: true
      })
    );
  });

  dispatch(loadPreferences(payload.userId));
  return;
});

const loadPreferences = createAsync<number, void, CMxCommonApp.SubmitError>(
  UserPreferences.LOAD_USER_PREFERENCES,
  async (userId, dispatch): Promise<void> => {
    const userDefaults = await api<CMxAPI.Preference[]>({
      endpoint: `/userpreferences/${userId}/v1`
    });
    const defaultOrgId = getDefaultOrg(userDefaults);
    const defaultServiceLine = getDefaultServiceLine(userDefaults);
    const homepagePreference = getHomepagePreference(userDefaults);

    dispatch(setHomepagePreference(homepagePreference));

    const shellHomepage = { path: '', tenantId: '' };

    dispatch(
      saveFormData({
        defaultOrg: defaultOrgId,
        defaultServiceLine,
        defaultHomepage: shellHomepage
      })
    );
  }
);

const updatePreferenceValues = createAsync<
  CMx.UpdatePrefenceValuesPayload,
  void,
  CMxCommonApp.SubmitError
>(UserPreferences.UPDATE_PREFERENCE_VALUE, async (payload, dispatch) => {
  const body = {
    id: null,
    preferenceId: null,
    tenantId: payload.preferenceValue.tenantId,
    value: payload.preferenceValue.value,
    version: null
  };

  await api<void>({
    endpoint: `/userpreferences/${payload.userId}/${payload.keyname}/v1`,
    init: {
      method: HttpMethods.PUT
    },
    body
  });

  dispatch(loadPreferences(payload.userId));
});

export {
  saveUserPreference,
  deletePreferenceValues,
  loadPreferences,
  updatePreferenceValues
};
