/// <reference path="../../types.d.ts" />

import React from 'react';
import { LoadingSlugs, SelectList } from '@codametrix/ui-components';

import '../../styles/grid-common.scss';
import '../../styles/cmx.scss';
import '../pageable-list/pageable-list.scss';
import { commonEnums as enums } from '@codametrix/ui-common';
import './generic-filter.scss';
const { FilterTypes } = enums;

const SelectFilter: React.FC<AppProps.GenericFilterProps> = (
  props: AppProps.GenericFilterProps
) => {
  const { sortablePageable, list, criteriaFilter, form, loadState } = props;

  function _handleFilterSubmit(event: CustomEvent) {
    event.preventDefault();

    const { detail } = event;

    let filters: CMxCommonApp.Filter[] = [];

    filters = criteriaFilter.filters
      .filter(filter => {
        return (
          filter.type !== 'GREATER_THAN_EQUAL' &&
          filter.type !== 'LESS_THAN_EQUAL'
        );
      })
      .filter(filter => {
        return filter.key !== detail.name;
      });

    let filterType: CMxCommonApp.FilterTypes = FilterTypes.IN;
    if (form.formDefinition.fieldGroups?.length) {
      filterType =
        form.formDefinition.fieldGroups[0].fields.find(
          field => field.key === detail.name
        )?.filterType || filterType;
    }

    const newFilter = {
      key: detail.name,
      terms: [detail.value],
      type: filterType
    };

    filters.push(newFilter);

    var listOptions = sortablePageable;
    listOptions.filterableOptions.filters = filters;
    listOptions.filterableOptions.dateFilter =
      sortablePageable.filterableOptions.dateFilter;
    listOptions.pageableDefinition.pageable.pageNumber = 0;
    listOptions.pageableDefinition.number = 0;
    listOptions.pageableDefinition.first = true;
    listOptions.pageableDefinition.last = false;
    listOptions.pageableDefinition.pageable.offset = 0;

    list(listOptions);
  }

  var defaultVal: string = '';

  if (form.formDefinition.fieldGroups) {
    form.formDefinition.fieldGroups.forEach(group => {
      group.fields.forEach(field => {
        const filterMatch = criteriaFilter.filters
          ?.find(filter => {
            return filter.key === form.key;
          })
          ?.terms.find(term => {
            return term === field.value;
          });

        if (filterMatch) {
          defaultVal = filterMatch;
        }
      });
    });
    if (defaultVal.length === 0) {
      defaultVal = form.formDefinition?.fieldGroups[0].fields[0].key;
    }
  }

  return (
    <div className={`dropdown-${form.key}`}>
      {loadState.loading || loadState.initial ? (
        <LoadingSlugs numberItems={3} />
      ) : (
        <span>
          {form.name + ':'}
          <SelectList
            options={
              form.formDefinition.fieldGroups?.length
                ? form.formDefinition.fieldGroups[0].fields
                : []
            }
            value={defaultVal}
            onChange={_handleFilterSubmit}
            name={form.key}></SelectList>
        </span>
      )}
    </div>
  );
};

export default SelectFilter;
