import { CMxComponents } from '@codametrix/ui-components/src/types';
import { defaultFilterableOptions } from '../../core/default-filterable-options';
import { defaultSortableOption } from '../../core/pageable-options';
import { LOAD_STATES } from '../common';
import { externalApplications as externalAppFixtures } from '../../stubs/external-applications';
import { AmplifyCore } from '@codametrix/ui-common';
import { formErrors } from '../../stubs/form-errors';

const { paginationUtils } = AmplifyCore;

const externalAppsListPath: CMx.DisplayablePath = {
  displayName: 'External Applications',
  path: '/cmx/admin/orgs/:orgId(\\d+)/external-applications/'
};

const externalAppDetailPath: CMx.DisplayablePath = {
  displayName: 'External Applications',
  path: '/cmx/admin/orgs/:orgId(\\d+)/external-applications/:externalApp/',
  getDisplayName: (state: CMx.ApplicationState) => {
    const { externalApplications: selectedExternalApplication } = state;
    return selectedExternalApplication.selectedExternalApplication
      .externalApplicationName;
  }
};

const externalAppUserForm: CMx.DisplayablePath = {
  displayName: 'User Form',
  path:
    '/cmx/admin/orgs/:orgId(\\d+)/external-applications/:externalApp/users/:form',
  getDisplayName: (state: CMx.ApplicationState) => {
    const {
      externalApplications: { selectedUser }
    } = state;
    const displayName = selectedUser.username.length ? `Edit` : `Add`;
    return displayName;
  }
};

export const externalAppPaths = [
  externalAppsListPath,
  externalAppDetailPath,
  externalAppUserForm
];

const sortablePageable: CMxCommonApp.SortablePageable<any> = {
  pageableDefinition: { ...paginationUtils.emptyPageable },
  sortableOptions: [{ ...defaultSortableOption }],
  filterableOptions: defaultFilterableOptions
};

const appColumnDefs: CMxComponents.ColumnDefintion[] = [
  {
    key: 'externalApplicationName',
    displayName: 'Name',
    transform: (data: CMxAPI.ExternalApplication) => {
      const { externalApplicationName } = data;
      return externalApplicationName;
    }
  },
  {
    key: 'organizationName',
    displayName: 'Organization Name',
    transform: (data: CMxAPI.ExternalApplication) => {
      const { organizationName } = data;
      return organizationName;
    }
  },
  {
    key: 'isActive',
    displayName: 'Active',
    transform: (data: CMxAPI.ExternalApplication) => {
      const { isActive } = data;
      return isActive?.toString() ?? '';
    }
  }
];

const userColumnDefs: CMxComponents.ColumnDefintion[] = [
  {
    key: 'username',
    displayName: 'Username'
  },
  {
    key: 'alias',
    displayName: 'alias'
  }
];

export const externalApplications: CMx.ExternalApplicationsState = {
  externalApplicationList: {
    loadState: LOAD_STATES.done,
    columnDefs: appColumnDefs,
    label: 'External Application',
    itemName: 'External Applications',
    sortablePageable
  },
  externalApplicationUserList: {
    loadState: LOAD_STATES.done,
    columnDefs: userColumnDefs,
    label: 'External Application Users',
    itemName: 'External Application Users',
    sortablePageable
  },
  selectedExternalApplication: externalAppFixtures.defaultExternalApplication,
  selectedUser: externalAppFixtures.defaultExternalApplicationUser,
  selectedOrgId: undefined,
  userFormErrors: formErrors.noErrors
};
