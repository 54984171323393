import { Modal } from '@codametrix/ui-components';
import React from 'react';
import { Action } from 'redux';
import { useDispatch } from 'react-redux';
import { cancelEula } from '../../actions/eula';
import { logoutApp } from '../../actions/login.action';

const CancelledEula = () => {
  const dispatch = useDispatch();
  const submitClick = (e: any) => {
    dispatch(cancelEula(false));
    dispatch((logoutApp({ showModal: false }) as any) as Action);
  };
  return (
    <div className={'qa-cancelled-eula'}>
      <Modal
        title={'You must accept the EULA to use the application'}
        buttonText={'Return'}
        closable={false}
        onSubmit={submitClick}
        forceSubmitOptions={{
          isForced: true,
          time: 10,
          message: 'Returning to the login screen '
        }}>
        <p>Please review the EULA and try again.</p>
      </Modal>
    </div>
  );
};

export default CancelledEula;
