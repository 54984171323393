import React from 'react';
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react';
import { MenuItemType } from '../select/select';
import { styles } from './menu-icon.styles';

export type MenuIconProps = {
  items: MenuItemType[];
  classes?: any;
  dataTestId?: string;
  icon: React.ReactElement;
  name?: string;
  onChange?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  placeholder?: string;
  value?: string;
};

const MenuIcon: React.FC<MenuIconProps> = props => {
  const { classes, dataTestId, icon, items, name, onChange, value } = props;

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        sx={classes?.button ?? styles.button}
        icon={icon}
        data-testid={dataTestId?.trim() ?? 'menu-icon'}></MenuButton>
      <MenuList sx={styles.list}>
        {items.map((item, key) => (
          <MenuItem
            sx={item.value === value ? styles.itemSelected : styles.item}
            key={key}
            value={item.value}
            name={name ?? item.value.toString()}
            onClick={onChange}
            isDisabled={item.disabled}
            data-testid={item.value?.toString().trim()}>
            {item.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

MenuIcon.displayName = 'MenuIcon';
export { MenuIcon };
