/// <reference path="../types.d.ts" /
export const eula: CMxCommonApp.EulaState = {
  eula: {
    userId: -1,
    acceptedDate: null,
    organizationId: -1,
    eulaId: null,
    text: ''
  },
  isCancelled: false
};
