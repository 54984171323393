import { CMxComponents } from '@codametrix/ui-components/src/types';

import { LOAD_STATES } from '../common';
import { AddDefinitionForm } from '../../core/definitions/definition-form-definition';
import { defaultFilterableOptions } from '../../core/default-filterable-options';
import { defaultDictionary } from '../../tests/fixtures/stubs/dictionary';
import { AmplifyCore } from '@codametrix/ui-common';
import { formErrors } from '../../stubs/form-errors';

const { paginationUtils } = AmplifyCore;

const dictionaryListPath: CMx.DisplayablePath = {
  displayName: 'Dictionaries',
  path: '/cmx/admin/orgs/:orgId(\\d+)/dictionaries/'
};

const dictionaryValueListPath: CMx.DisplayablePath = {
  displayName: 'Dictionary',
  path: '/cmx/admin/orgs/:orgId(\\d+)/dictionaries/dictionary/:dictionaryName/',
  getDisplayName: (state: CMx.ApplicationState, value: number) => {
    const { dictionaryValues } = state;
    return dictionaryValues.selectedDictionary.name;
  }
};

const dictionaryValueDetailPath: CMx.DisplayablePath = {
  displayName: 'Edit',
  path:
    '/cmx/admin/orgs/:orgId(\\d+)/dictionaries/dictionary/:dictionaryName/row/:rowId/(edit)'
};

const dictionaryUploadlCreatePath: CMx.DisplayablePath = {
  displayName: 'Create Dictionary',
  path: '/cmx/admin/orgs/:orgId(\\d+)/dictionaries/dictionary/upload/new'
};

const dictionaryUploadlPath: CMx.DisplayablePath = {
  displayName: 'Upload Dictionary',
  path:
    '/cmx/admin/orgs/:orgId(\\d+)/dictionaries/dictionary/:dictionaryName/upload'
};

const dictionaryInProgressPath: CMx.DisplayablePath = {
  displayName: 'Upload Dictionary',
  path: '/cmx/admin/orgs/:orgId(\\d+)/dictionaries/request/:requestId/'
};

const dictionaryMetadataKeyColumnPath: CMx.DisplayablePath = {
  displayName: 'Edit Metadata - Key Column',
  path:
    '/cmx/admin/orgs/:orgId(\\d+)/dictionaries/dictionary/:dictionaryName/metadata/keyColumn'
};

const dictionaryHistoryPath: CMx.DisplayablePath = {
  displayName: 'Dictionary History',
  path:
    '/cmx/admin/orgs/:orgId(\\d+)/dictionaries/dictionary/:dictionaryName/history'
};

const dictionaryCrossmapSelectPath: CMx.DisplayablePath = {
  displayName: 'Edit Metadata - Cross Maps',
  path:
    '/cmx/admin/orgs/:orgId(\\d+)/dictionaries/dictionary/:dictionaryName/metadata/crossMaps/'
};

const dictionaryCrossmapEditPath: CMx.DisplayablePath = {
  displayName: 'Edit Cross Maps',
  path:
    '/cmx/admin/orgs/:orgId(\\d+)/dictionaries/dictionary/:dictionaryName/metadata/crossMaps/:columnName/(edit)',
  getDisplayName: (state: CMx.ApplicationState, value: number) => {
    const { dictionaryValues } = state;
    return dictionaryValues.columnMetadataEditor.activeColumn.name;
  }
};

export const dictionaryPaths = [
  dictionaryListPath,
  dictionaryValueListPath,
  dictionaryInProgressPath,
  dictionaryValueDetailPath,
  dictionaryUploadlCreatePath,
  dictionaryUploadlPath,
  dictionaryMetadataKeyColumnPath,
  dictionaryCrossmapSelectPath,
  dictionaryCrossmapEditPath,
  dictionaryHistoryPath
];

export const nameSortableOption: CMxCommonApp.SortableOption = {
  sortField: 'name',
  sortDirection: 'ASC'
};

const sortablePageable: CMxCommonApp.SortablePageable<any> = {
  pageableDefinition: { ...paginationUtils.emptyPageable },
  sortableOptions: [nameSortableOption],
  filterableOptions: defaultFilterableOptions
};

const columnDefs: CMxComponents.ColumnDefintion[] = [
  {
    key: 'name',
    displayName: 'Dictionary Name'
  },
  {
    key: 'status',
    displayName: 'Status',
    transform: (dict: CMxAPI.Dictionary) => {
      if (dict.editStatus === 'UPDATE') {
        return 'Published with Draft';
      }

      return (
        dict.status.charAt(0).toUpperCase() + dict.status.slice(1).toLowerCase()
      );
    }
  },
  {
    key: 'updatedByFullName',
    displayName: 'Updated By'
  },
  {
    key: 'updatedDate',
    displayName: 'Date Updated'
  }
];
const filterLoadStates = new Map<string, CMxCommonApp.Loading>();

export const dictionary: CMx.DictionaryState = {
  formErrors: formErrors.noErrors,
  label: 'Dictionary',
  formDef: AddDefinitionForm,
  itemName: 'Dictionaries',
  loadState: LOAD_STATES.initial,
  pageable: paginationUtils.emptyPageable,
  sortablePageable,
  items: [],
  activeItem: defaultDictionary,
  columnDefs,
  filterLoadStates,
  selectedOrg: {
    id: -1
  },
  associatedDictionaries: []
};
