/**
 * Enum for request type
 */
export enum RequestTypeEnum {
  CREATE = 'Create',
  UPDATE = 'Update',
  PUBLISH = 'Publish',
  DELETE = 'Delete'
}

/**
 * Enum for request status
 */
export enum RequestStatus {
  INITIALIZED = 'Initialized',
  COMPLETED = 'Completed',
  CELL_REFERENCE_VALIDATION_COMPLETED = 'Cell Reference Validation Completed',
  COLUMN_UPDATE_COMPLETED = 'Column Update Completed',
  CSV_PARSE_COMPLETED = 'CSV Parse Completed',
  ROW_UPDATE_COMPLETED = 'Row Update Completed'
}
