/// <reference path="../../types.d.ts" />

import { objectUtils } from '@codametrix/ui-common';
import { Grid, Text } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { RelationshipEditType } from '../../core/enums';
import { isNumber } from '../../core/type-guards';
import { styles } from './organization.styles';
import './service-line.scss';
import { Checkbox, CheckboxSize } from '../../components/checkbox/checkbox';

type BooleanRecord = Record<string, boolean>;

export type ServiceLinesProps = {
  onChange: (edits: CMx.PartialRelationshipEdit[]) => void;
  orgRelationships: CMxAPI.OrganizationRelationship[];
  relationship: CMxAPI.OrganizationRelationship;
  serviceLines: CMxAPI.ServiceLine[];
};

const getInitialCheckboxValues = (serviceLines: CMxAPI.ServiceLine[]) => {
  const values: BooleanRecord = {};
  serviceLines.forEach(sl => {
    values[`${sl.id}`] = false;
  });
  return values;
};

const orgRelationshipsToCheckListValues = (
  orgRelationships: CMxAPI.OrganizationRelationship[],
  serviceLines: CMxAPI.ServiceLine[]
) => {
  const values: BooleanRecord = {};

  serviceLines.forEach(sl => {
    values[`${sl.id}`] = false;
  });
  orgRelationships.forEach(relationship => {
    values[`${relationship.serviceLineId}`] = true;
  });
  return values;
};

const ServiceLines: React.FC<ServiceLinesProps> = props => {
  const classes = styles();
  const { onChange, orgRelationships, serviceLines } = props;

  const initialCheckboxValues = getInitialCheckboxValues(serviceLines);
  const [checkboxValues, setCheckboxValues] = useState<BooleanRecord>(
    initialCheckboxValues
  );
  useEffect(() => {
    const checkboxValuesMap = orgRelationshipsToCheckListValues(
      orgRelationships,
      serviceLines
    );
    setCheckboxValues(checkboxValuesMap);
  }, [orgRelationships, serviceLines]);

  const handleServiceLineSelection = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // 1. update checkboxvalues state with new value
    const values = { ...checkboxValues };
    values[event.target.id] = event.target.checked;
    setCheckboxValues(values);

    // 2. turn the boolean map into an array so we can work with it as a set.
    const serviceIds = Object.entries(values)
      .filter(([, value]) => {
        return value;
      })
      .map(([key]) => {
        return (key as any) >> 0;
      })
      .flat();
    const serviceIdsSet = new Set(serviceIds);

    // 3. create the already provided set for symmetry with serviceIds
    const alreadyProvided: number[] = objectUtils.uniq(
      orgRelationships
        .map(rel => {
          return rel.serviceLineId;
        })
        .flat()
        .filter(isNumber)
    );
    const alreadyProvidedSet = new Set(alreadyProvided);

    // 4. Create a set of edits.  Also include non-updates for clarity.
    const toRemove = alreadyProvided.filter(
      provided =>
        !serviceIdsSet.has(provided) && alreadyProvidedSet.has(provided)
    );
    const noUpdates = serviceIds.filter(serviceId =>
      alreadyProvidedSet.has(serviceId)
    );
    const toAdd = serviceIds.filter(
      serviceId => !alreadyProvidedSet.has(serviceId)
    );

    // 5. Assemble our PartialRelationshipEdits
    const edits: CMx.PartialRelationshipEdit[] = [];
    toRemove.forEach(edit => {
      edits.push({
        type: RelationshipEditType.REMOVE,
        organizationRelationship: {
          serviceLineId: edit
        }
      });
    });

    toAdd.forEach(edit => {
      edits.push({
        type: RelationshipEditType.ADD,
        organizationRelationship: {
          serviceLineId: edit
        }
      });
    });

    noUpdates.forEach(edit => {
      edits.push({
        type: RelationshipEditType.NOOP,
        organizationRelationship: {
          serviceLineId: edit
        }
      });
    });
    onChange(edits);
  };

  return (
    <Grid sx={classes.input} rowGap={3}>
      <Text sx={classes.subtitle}>Apply to:</Text>
      <Grid>
        {serviceLines.map((serviceLine: CMxAPI.ServiceLine) => (
          <Checkbox
            label={serviceLine.name}
            onChange={handleServiceLineSelection}
            key={serviceLine.id}
            id={serviceLine.id.toString()}
            isChecked={checkboxValues[`${serviceLine.id}`]}
            size={CheckboxSize.MD}
            dataTestId={serviceLine.id.toString()}
          />
        ))}
      </Grid>
    </Grid>
  );
};

ServiceLines.displayName = 'ServiceLines';
export { ServiceLines };
