import theme from '../../theme';

export const styles = {
  button: {
    color: theme.colors.text['highEmphasis'],
    width: 'fit-content',
    ...theme.textStyles['Body 2'],
    background: 'none',
    _focus: {
      boxShadow: 'none'
    },
    _active: {
      background: 'none'
    },
    _hover: {
      background: 'none'
    }
  },
  list: {
    padding: theme.space[12],
    maxHeight: theme.space[400],
    overFlowY: 'auto'
  },
  item: {
    color: theme.colors.text['mediumEmphasis'],
    ...theme.textStyles['Body 1'],
    _hover: {
      color: theme.colors.text['interactive'],
      background: theme.colors.opacity.primary
    }
  },
  itemSelected: {
    color: theme.colors.accent[200],
    ...theme.textStyles['Body 1']
  }
};
