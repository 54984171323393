import { AuditLog } from './action-types';

import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { api } from '../core/net';
import { dismissFeedback, showFeedbackWorking } from './ui';

import { HttpMethods, AmplifyCore } from '@codametrix/ui-common';

const { paginationUtils } = AmplifyCore;

const actionCreator = actionCreatorFactory();

const createAsync = asyncFactory<CMx.AuditLogState>(actionCreator);

const clearAllFilters = actionCreator<void>(AuditLog.CLEAR_ALL_FILTERS);

const auditLogList = actionCreator<
  CMxAPI.PageableList<CMxAPI.DictionaryAction | CMxAPI.OrganizationAction>
>(AuditLog.LIST_AUDIT_LOG);

const applyFilters = actionCreator<CMxCommonApp.FilterableOption>(
  AuditLog.FILTER
);

const setAuditLogType = actionCreator<string>(AuditLog.SET_AUDIT_LOG_TYPE);

/** async actions */

const listAuditLogs = createAsync<
  CMxCommonApp.SortablePageable<
    CMxAPI.DictionaryAction | CMxAPI.OrganizationAction
  >,
  CMxAPI.PageableList<CMxAPI.DictionaryAction | CMxAPI.OrganizationAction>,
  CMxCommonApp.SubmitError
>(AuditLog.LIST_AUDIT_LOG, async (params, dispatch) => {
  const sortablePageable = params;
  const filterableOptions = params.filterableOptions;

  const searchParams = paginationUtils.searchParamsFactory(sortablePageable);

  dispatch(
    showFeedbackWorking({
      message: `Loading audit logs...`,
      dismissable: false
    })
  );

  let body: any = {
    searchType: 'AND'
  };

  let listDict = true;
  let listOrg = true;

  if (sortablePageable.filterableOptions.filters) {
    sortablePageable.filterableOptions.filters.forEach(filter => {
      switch (filter.key) {
        case 'log_type':
          listOrg = false;
          listDict = false;
          if (filter.terms.includes('Organization Logs')) {
            listOrg = true;
          }
          if (filter.terms.includes('Dictionary Logs')) {
            listDict = true;
          }
          break;
        case 'dictionary_action':
          listOrg = false;
          body = {
            ...body,
            dictionaryEventType: filter.terms[0]
          };
          break;
        case 'organization_action':
          listDict = false;
          body = {
            ...body,
            organizationEventType: filter.terms[0]
          };
          break;
        case 'tenantId':
          if (filter.terms[0] === '') {
            body = {
              ...body
            };
            break;
          }
          body = {
            ...body,
            tenantId: {
              uuid: filter.terms[0]
            }
          };
          break;
        case 'organizationName':
          listDict = false;
          body = {
            ...body,
            organizationName: filter.terms[0]
          };
          break;
        case 'dictionary':
          listOrg = false;
          body = {
            ...body,
            dictionaryName: filter.terms[0]
          };
          break;
        case 'username':
          body = {
            ...body,
            username: filter.terms[0]
          };
          break;
        default:
          throw new Error('Invalid Filter Type');
      }
    });
  }

  if (filterableOptions.dateFilter) {
    body = {
      ...body,
      eventDate: filterableOptions.dateFilter?.to
    };
  }

  let dictionaryAuditLogs:
    | CMxAPI.PageableList<CMxAPI.DictionaryAction>
    | undefined;
  if (listDict) {
    dictionaryAuditLogs = await api<
      CMxAPI.PageableList<CMxAPI.DictionaryAction>
    >({
      endpoint: `/dictionary/action/v2?${searchParams.toString()}`,
      init: {
        method: HttpMethods.POST
      },
      body: body
    });
  }

  let organizationAuditLogs:
    | CMxAPI.PageableList<CMxAPI.OrganizationAction>
    | undefined;
  if (listOrg) {
    organizationAuditLogs = await api<
      CMxAPI.PageableList<CMxAPI.OrganizationAction>
    >({
      endpoint: `/organization/action/v2?${searchParams.toString()}`,
      init: {
        method: HttpMethods.POST
      },
      body: body
    });

    organizationAuditLogs.content.forEach(
      element =>
        (element.eventDate = new Date(element.eventDate!).toISOString())
    );

    if (listDict) {
      organizationAuditLogs.content.forEach(
        element =>
          (element.id = element.id + dictionaryAuditLogs!.totalElements)
      );
    }
  }

  if (
    listOrg &&
    listDict &&
    organizationAuditLogs !== undefined &&
    dictionaryAuditLogs !== undefined
  ) {
    const combinedAuditLogs: CMxAPI.PageableList<
      CMxAPI.DictionaryAction | CMxAPI.OrganizationAction
    > = {
      content: [
        ...dictionaryAuditLogs.content,
        ...organizationAuditLogs.content
      ].sort((a, b) => b.eventDate!.localeCompare(a.eventDate!)),
      pageable: dictionaryAuditLogs.pageable,
      sort: dictionaryAuditLogs.sort,
      totalElements:
        dictionaryAuditLogs.totalElements + organizationAuditLogs.totalElements,
      totalPages:
        (dictionaryAuditLogs.totalElements +
          organizationAuditLogs.totalElements) /
        dictionaryAuditLogs.size,
      last: false,
      size: dictionaryAuditLogs.size,
      number: 0,
      numberOfElements:
        dictionaryAuditLogs.numberOfElements +
        organizationAuditLogs.numberOfElements,
      first: true,
      empty: false
    };

    dispatch(auditLogList(combinedAuditLogs));
    dispatch(setAuditLogType('All'));
    dispatch(dismissFeedback(true));
    return combinedAuditLogs;
  }

  if (listOrg && organizationAuditLogs !== undefined) {
    dispatch(auditLogList(organizationAuditLogs));
    dispatch(setAuditLogType('Organization'));
    dispatch(dismissFeedback(true));
    return organizationAuditLogs;
  }
  if (listDict && dictionaryAuditLogs !== undefined) {
    dispatch(auditLogList(dictionaryAuditLogs));
    dispatch(setAuditLogType('Dictionary'));
    dispatch(dismissFeedback(true));
    return dictionaryAuditLogs;
  }
  throw new Error('Invalid Log Type');
});

export const actions = {
  auditLogList,
  listAuditLogs,
  clearAllFilters,
  setAuditLogType,
  applyFilters
};
export {
  auditLogList,
  listAuditLogs,
  clearAllFilters,
  setAuditLogType,
  applyFilters
};
