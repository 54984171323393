import InitialState from '../initial-state';
import { actions } from '../../actions/audit-log';

import { cmxReducerFactory } from '../reducer-utils';
import {
  dateCriteria,
  logTypeCriteria,
  dictionaryActionCriteria,
  organizationActionCriteria
} from './audit-log-field-definitions';

const reducer = cmxReducerFactory(InitialState.auditLog)
  .case(actions.auditLogList, (state, auditLogs) => {
    const { sortablePageable, criteriaFilter } = state;
    const auditLogs_content = auditLogs.content;

    const sp = {
      ...sortablePageable,
      items: auditLogs_content,
      pageableDefinition: {
        ...auditLogs,
        content: auditLogs_content
      }
    };

    const cFilter = {
      ...criteriaFilter,
      filters: sp.filterableOptions.filters ?? criteriaFilter.filters
    };

    return {
      ...state,
      sortablePageable: sp,
      auditLogs: auditLogs_content,
      criteriaFilter: cFilter
    };
  })
  .case(actions.setAuditLogType, (state, logType) => {
    let filterFormList = [dateCriteria, logTypeCriteria];
    switch (logType) {
      case 'Organization':
        filterFormList = [...filterFormList, organizationActionCriteria];
        break;
      case 'Dictionary':
        filterFormList = [...filterFormList, dictionaryActionCriteria];
        break;
      case 'All':
        filterFormList = [dateCriteria, logTypeCriteria];
        break;
      default:
        break;
    }

    return {
      ...state,
      criteriaFilter: {
        ...state.criteriaFilter,
        filterForms: filterFormList
      }
    };
  })
  .case(actions.clearAllFilters, (state, action) => {
    return {
      ...state,
      filterLoadStates: InitialState.cases.filterLoadStates,
      criteriaFilter: {
        ...state.criteriaFilter,
        filters: [],
        dateFilter: undefined
      },
      sortablePageable: {
        ...state.sortablePageable,
        filterableOptions: {
          ...state.sortablePageable.filterableOptions,
          dateFilter: undefined,
          filters: []
        },
        dateFilter: undefined
      }
    };
  })
  .case(actions.applyFilters, (state, filterableOptions) => {
    const { criteriaFilter } = state;
    return {
      ...state,
      criteriaFilter: {
        ...state.criteriaFilter,
        filters: filterableOptions?.filters ?? criteriaFilter?.filters,
        dateFilter: filterableOptions?.dateFilter ?? criteriaFilter?.dateFilter
      },
      sortablePageable: {
        ...state.sortablePageable,
        filterableOptions: {
          ...state.sortablePageable.filterableOptions,
          filters: filterableOptions?.filters ?? criteriaFilter.filters,
          dateFilter: filterableOptions?.dateFilter
        }
      }
    };
  });

export default reducer;
