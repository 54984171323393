import theme from '../../theme';
import { SecondaryNavigationOrientation } from './secondary-navigation';
import { CSSProperties } from 'react';

export const styles = (
  styles: any,
  orientation: string,
  activeTabColor?: string,
  tabColor?: string
): Record<string, CSSProperties> => {
  const _tabColor = tabColor ?? theme.colors.text['mediumEmphasis'];
  const _activeTabColor = activeTabColor ?? theme.colors.accent[200];

  const tabStyles = {
    ...styles.tab,
    cursor: 'pointer',
    outline: 'none',
    fontStyle: 'normal',
    textAlign: 'center',
    ...theme.textStyles['Subtitle 1'],
    _focus: {
      zIndex: 1,
      boxShadow: 'none'
    },
    _selected: {
      color: _activeTabColor,
      borderBottom: `${theme.space['4']} solid ${_activeTabColor}`
    },
    color: _tabColor,
    width: 'max-content',
    padding: `${theme.space[8]} ${theme.space[20]}`
  };

  if (orientation === SecondaryNavigationOrientation.VERTICAL) {
    return {
      ...tabStyles,
      ...theme.textStyles['h1'],
      border: 'none',
      _selected: {
        color: _activeTabColor,
        border: 'none'
      },
      paddingLeft: 0,
      ...theme.textStyles['Body 1'],
      padding:
        0 +
        ' ' +
        theme.space[20] +
        ' ' +
        theme.space[20] +
        ' ' +
        theme.space[20],
      marginBottom: 0
    };
  }
  return tabStyles;
};

export const childTabStyles = {
  tabs: {
    padding: `${theme.space[16]} ${theme.space[20]} 0 ${theme.space[20]}`,
    fontSize: theme.textStyles['Subtitle 2'].fontSize
  }
};

export const disabledStyles = {
  opacity: '0.4',
  cursor: 'default',
  pointerEvents: 'none'
};
