import React from 'react';
import { EntryItem } from './patient-timeline-entries';
import { cmxDateTime, objectUtils } from '@codametrix/ui-common';

/**
 * Enum representing different author types.
 * @enum {string}
 */
enum AuthorType {
  ADMITTING = 'admitting',
  ATTENDING = 'attending',
  AUTHORING = 'authoring',
  BILLING = 'billing',
  ENTERING = 'entering',
  MEDICATION_ORDERING = 'medicationOrdering',
  ORDERING = 'ordering',
  PRIMARY_AUTHENTICATOR = 'primaryAuthenticator',
  SCHEDULING = 'scheduling',
  VERIFYING = 'verifying',
  FINAL_SIGN_PROVIDER = 'finalSignProvider',
  PRIMARY_SURGEON = 'primarySurgeon',
  COSIGNING = 'cosigning'
}

/**
 * Labels for different provider types.
 * @type {Object.<string, string>}
 */
const providerLabels = {
  admitting: 'Admitting provider',
  attending: 'Attending provider',
  authoring: 'Note author',
  billing: 'Billing provider',
  entering: 'Entered by',
  medicationOrdering: 'Ordering provider',
  ordering: 'Ordering provider',
  verifying: 'Verified by',
  scheduling: 'Scheduled',
  finalSignProvider: 'Final sign provider',
  primaryAuthenticator: 'Primary authenticator',
  primarySurgeon: 'Primary surgeon',
  cosigning: 'Cosigning provider'
};

/**
 * Function type to filter providers.
 * @callback ProviderFilterFunction
 * @param {CMxAPI.ProviderInfo} provider
 * @returns {boolean}
 */
type ProviderFilterFunction = (provider: CMxAPI.ProviderInfo) => boolean;

/**
 * Type representing the keys of the provider labels.
 * @typedef {keyof typeof providerLabels} ProviderType
 */
type ProviderType = keyof typeof providerLabels;

/**
 * Filters providers based on a filter function.
 *
 * @param {ProviderFilterFunction[]} fns - The filter functions.
 * @param {CMxAPI.ProviderInfo[]} providers - List of providers.
 * @returns {CMxAPI.ProviderInfo[]} - Filtered list of providers.
 */
const providerFilter = (
  fns: ProviderFilterFunction[],
  providers: CMxAPI.ProviderInfo[]
): CMxAPI.ProviderInfo[] => {
  if (!providers) {
    return [];
  }
  fns.forEach(fn => {
    providers = providers.filter(fn);
  });

  return providers;
};

/**
 * Creates a simple filter function based on author type.
 *
 * @param {AuthorType} authorType - The author type to filter.
 * @returns {ProviderFilterFunction} - The filter function.
 */
const simpleFilter = (authorType: AuthorType): ProviderFilterFunction => {
  return (provider: CMxAPI.ProviderInfo) => provider.type === authorType;
};

/**
 * Creates a filter function to check if primary
 *
 * @returns {ProviderFilterFunction} - The filter function.
 */
const primaryFilter = (): ProviderFilterFunction => {
  return (provider: CMxAPI.ProviderInfo) => !!provider?.is_primary;
};

/**
 * Generates a display name for a provider.
 *
 * @param {CMxAPI.ProviderInfo} provider - The provider information.
 * @returns {string} - The formatted provider name.
 */
const displayProviderName = (provider: CMxAPI.ProviderInfo): string => {
  if (!provider.given_name && !provider.family_name) {
    return 'N/A';
  }
  return `${provider.family_name}, ${provider.given_name} ${
    provider?.type === AuthorType.MEDICATION_ORDERING
      ? ` ${getProviderSpecialties(provider?.provider_specialty)}`
      : ''
  }
  `;
};

/**
 * Generates a display name for a billing provider.
 *
 * @param {CMxAPI.ProviderInfo} provider - The provider information.
 * @returns {string} - The formatted provider name.
 */
const displayBillingProviderName = (provider: CMxAPI.ProviderInfo): string => {
  if (!provider.given_name && !provider.family_name) {
    return 'N/A';
  }
  return `${provider.family_name}, ${
    provider.given_name
  } ${getProviderSpecialties(provider?.provider_specialty)}`;
};

/**
 * Displays a list of providers with labels.
 *
 * @param {CMxAPI.ProviderInfo[]} providers - List of providers.
 * @param {string} label - The label for the provider type.
 * @returns {JSX.Element[]} - Array of React elements representing the display.
 */
const displayProviders = (providers: CMxAPI.ProviderInfo[], label: string) => {
  const billingProviders = providers.filter(
    provider => provider.type === AuthorType.BILLING
  );
  const finalSignerProviders = providers.filter(
    provider => provider.type === AuthorType.FINAL_SIGN_PROVIDER
  );
  let otherProviders = providers.filter(
    provider =>
      provider.type !== AuthorType.BILLING &&
      provider.type !== AuthorType.FINAL_SIGN_PROVIDER
  );

  // billing provider for DFT
  const billingProvidersGrouped: {
    [key: string]: CMxAPI.ProviderInfo[];
  } = objectUtils.groupBy(billingProviders, 'identifier');
  const billingProvidersReduced: CMxAPI.ProviderInfo[] = [];

  Object.keys(billingProvidersGrouped).forEach((identifier: string) => {
    billingProvidersReduced.push(
      billingProvidersGrouped[identifier].reduce(
        (
          reducedProvider: CMxAPI.ProviderInfo,
          currentProvider: CMxAPI.ProviderInfo
        ) => {
          if (!reducedProvider.identifier) {
            return currentProvider;
          }

          return {
            ...reducedProvider,
            provider_specialty: `${reducedProvider.provider_specialty}, ${currentProvider.provider_specialty}`
          };
        },
        {
          identifier: '',
          given_name: '',
          family_name: '',
          provider_specialty: '',
          type: 'billing'
        }
      )
    );
  });

  // final sign provider keeping the provider with the last date
  const finalSignProvidersWithDate = finalSignerProviders
    .filter(provider => provider?.custom_properties?.datetime)
    .sort((provA, provB) =>
      cmxDateTime.sortByDate(
        provA?.custom_properties.datetime,
        provB?.custom_properties.datetime
      )
    );
  const newestFinalSignProvidersWithDate =
    finalSignProvidersWithDate.length > 0
      ? [finalSignProvidersWithDate[0]]
      : [];

  const finalSignProvidersWithNoDate = finalSignerProviders.filter(
    provider => !provider?.custom_properties?.datetime
  );

  return (
    <>
      {[
        ...otherProviders,
        ...billingProvidersReduced,
        ...newestFinalSignProvidersWithDate,
        ...finalSignProvidersWithNoDate
      ].map(provider => (
        <EntryItem
          key={`provider-${provider.identifier}`}
          field={label + ':'}
          value={
            provider?.type === AuthorType.BILLING
              ? displayBillingProviderName(provider)
              : displayProviderName(provider)
          }></EntryItem>
      ))}
    </>
  );
};

/**
 * Creates a provider filter based on author type.
 *
 * @param {AuthorType} authorType - The author type to filter.
 * @param {AuthorType} labelType - The label of type to filter. optional
 * @returns {function(CMxAPI.ProviderInfo[]): JSX.Element[]} - The provider filter function.
 */
const createProviderFilter = (
  authorType: AuthorType,
  labelType?: AuthorType
) => {
  return (providers: CMxAPI.ProviderInfo[]) => {
    const filtersArray = [];
    filtersArray.push(simpleFilter(authorType));

    if (labelType === AuthorType.PRIMARY_SURGEON) {
      filtersArray.push(primaryFilter());
    }

    const providerLabel = providerLabels[labelType ?? authorType];
    return displayProviders(
      providerFilter(filtersArray, providers),
      providerLabel
    );
  };
};

const getProviderSpecialties = (specialties: string | string[] | undefined) => {
  const specialtiesArray: string[] = Array.isArray(specialties)
    ? specialties
    : [specialties as string];

  return specialties ? `(${specialtiesArray?.join(', ')})` : '';
};

/**
 * Object containing provider filters for each provider type.
 * @type {Object.<ProviderType, function(CMxAPI.ProviderInfo[]): JSX.Element[]>}
 */
const ProviderFilters: Record<
  ProviderType,
  (providers: CMxAPI.ProviderInfo[]) => JSX.Element
> = {
  admitting: createProviderFilter(AuthorType.ADMITTING),
  attending: createProviderFilter(AuthorType.ATTENDING),
  billing: createProviderFilter(AuthorType.BILLING),
  authoring: createProviderFilter(AuthorType.AUTHORING),
  entering: createProviderFilter(AuthorType.ENTERING),
  medicationOrdering: createProviderFilter(AuthorType.MEDICATION_ORDERING),
  ordering: createProviderFilter(AuthorType.ORDERING),
  primaryAuthenticator: createProviderFilter(AuthorType.PRIMARY_AUTHENTICATOR),
  scheduling: createProviderFilter(AuthorType.SCHEDULING),
  verifying: createProviderFilter(AuthorType.VERIFYING),
  finalSignProvider: createProviderFilter(AuthorType.FINAL_SIGN_PROVIDER),
  primarySurgeon: createProviderFilter(
    AuthorType.SCHEDULING,
    AuthorType.PRIMARY_SURGEON
  ),
  cosigning: createProviderFilter(AuthorType.COSIGNING)
};

export { providerLabels, displayProviders, ProviderFilters, AuthorType };
