import theme from '../../theme';

export const styles = () => ({
  title: {
    color: theme.colors.text['highEmphasis']
  },
  closeIcon: {
    color: theme.colors.text['mediumEmphasis']
  }
});
