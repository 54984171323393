import { replace } from 'connected-react-router';
import * as AppJump from '../core/app-jump';
import { loading } from './ui';
import { putContext } from './contexts';
import {
  authenticationContext,
  uiActions,
  objectUtils
} from '@codametrix/ui-common';

export const jumpURL = async (ctx: CMx.Context, dispatch: any) => {
  const { contexts } = ctx;
  const hasContext = contexts.length === 1;
  const hasMultipleContexts = contexts.length > 1;
  // after a successful login we check to see if the user has a jump url specified.
  const jumpInfo = AppJump.getJump();
  const hasJump = jumpInfo && objectUtils.isString(jumpInfo.url);
  let jumped = false;
  const jump = () => {
    if (hasJump) {
      dispatch(replace(`${jumpInfo.url}`));
    }
    dispatch(loading(false));
    jumped = true;
  };

  const selectContext = () => {
    dispatch(loading(false));
    dispatch(uiActions.chooseContext(ctx));
  };

  if (hasContext) {
    const containsContext = contexts.find(ctx => {
      return ctx.organizationId === jumpInfo.contextId;
    });

    if (containsContext) {
      // work around.  there's an issue with the activeContextId.
      if (ctx.activeContextId !== jumpInfo.contextId) {
        const jumpChoice = {
          organizationId: `${jumpInfo.contextId}`,
          token: `${authenticationContext.getToken()}`
        };
        await putContext(jumpChoice, dispatch);
      } else {
        // the user may be a different user than the one who
        // originally logged in.  if this is the case, do not jump.
        selectContext();
      }
      jump();
    }
  } else if (!hasMultipleContexts) {
    selectContext();
    jump();
  }
  return jumped;
};
