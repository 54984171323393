import InitialState from './initial-state';
import * as codeLookupValues from '../actions/code-lookup';
import { cmxReducerFactory } from './reducer-utils';

const reducerBuilder = cmxReducerFactory(InitialState.codeLookup);

export const reducer = reducerBuilder.case(
  codeLookupValues.codeLookup.async.done,
  (state, res) => {
    return {
      ...state,
      searchResult: {
        content: res.result.content
      },
      searchRequest: {
        searchTerm: res.params.searchTerm,
        codeType: res.params.codeType,
        effectiveDate: res.params.effectiveDate
      }
    };
  }
);

export default reducer;
