/// <reference path="../../../types.d.ts"" />

import { buildDispatchable } from '../../../actions/_action-utilities';
import * as dictionaryValueActions from '../../../actions/dictionary-values';
import { push } from 'connected-react-router';
import { commonEnums } from '@codametrix/ui-common';
import { isResourceViewOnly } from '../../../core/resources';
import {
  EndpointData,
  HTTPMethodType
} from '../../../components/data-grid/data-grid-utils';
import { filterStatusFields } from '../../../reducers/state/dictionary-values';
import { MenuItemType } from '../../../components/select/select';

const { ResourceId } = commonEnums;

type dictionaryListDispatches = Pick<
  AppProps.DictionaryValueListProps,
  | 'list'
  | 'onSelection'
  | 'onAddItem'
  | 'navigate'
  | 'publish'
  | 'download'
  | 'selectArchiveRow'
  | 'deleteDictionary'
  | 'updateDictionaryDescription'
  | 'toggleDeleteModal'
  | 'toggleUploadType'
  | 'toggleInfoModal'
  | 'toggleSecurityModal'
  | 'togglePublishModal'
  | 'resetStateToDefault'
>;
type dictionaryValueListStateProps = Pick<
  AppProps.DictionaryValueListProps,
  | 'items'
  | 'loadState'
  | 'dictionaryId'
  | 'associatedDictionaries'
  | 'viewOnly'
  | 'isArchiveModalOpen'
  | 'isDeleteModalOpen'
  | 'isInfoModalOpen'
  | 'isSecurityModalOpen'
  | 'isPublishModalOpen'
>;

export const mapStateToProps = function(
  state: CMx.ApplicationState
): dictionaryValueListStateProps {
  const { dictionaryValues, dictionary, ui } = state;

  const viewOnly = isResourceViewOnly(
    ui.context.activeContext?.resources || [],
    ResourceId.DICTIONARY
  );

  return {
    ...dictionaryValues,
    dictionaryId: dictionary.selectedDictionaryId || 1,
    associatedDictionaries: dictionary.associatedDictionaries,
    viewOnly,
    isArchiveModalOpen: dictionaryValues.archiveModal.isOpen,
    isDeleteModalOpen: dictionaryValues.isDeleteModalOpen,
    isInfoModalOpen: dictionaryValues.isInfoModalOpen,
    isSecurityModalOpen: dictionaryValues.isSecurityModalOpen
  };
};

export const mapDispatchToProps = (dispatch: any): dictionaryListDispatches => {
  const runDispatchable = buildDispatchable(dispatch);

  // let handle: any;
  // let successRoute: string;

  // const pingBackend = async (requestId: number) => {
  //   const res = await dispatch(fetchRequestStatus({ requestId, successRoute }));
  //   if (res.requestStatus !== 'COMPLETED') {
  //     const bound = pingBackend.bind(null, requestId);
  //     handle = setTimeout(bound, 5000);
  //   }
  // };

  return {
    onAddItem: (item: number) => {},
    onSelection: (item: AppProps.actionParams<number>) => {
      runDispatchable(() => {
        return dispatch(dictionaryValueActions.loadValue(item));
      });
    },
    list: (options: AppProps.listParams) => {
      runDispatchable(() => {
        return dispatch(dictionaryValueActions.loadDictionary(options));
      });
    },
    publish: (dictionaryGroup: CMxAPI.Dictionary[], jiraTicket: string) => {
      runDispatchable(async () => {
        return await dispatch(
          dictionaryValueActions.publishDictionary({
            assocDictionaries: dictionaryGroup,
            jiraTicket
          })
        );
      });
    },
    navigate: (path: string) => {
      runDispatchable(() => {
        return dispatch(push(`${path}`));
      });
    },
    download: (payload: CMx.dictionaryDownloadPayload) => {
      runDispatchable(() => {
        return dispatch(dictionaryValueActions.downloadDictionary(payload));
      });
    },
    selectArchiveRow: (payload: number) => {
      runDispatchable(() => {
        return dispatch(dictionaryValueActions.selectArchiveRow(payload));
      });
    },
    toggleDeleteModal: (showWarning: boolean) => {
      runDispatchable(() => {
        return dispatch(dictionaryValueActions.toggleDeleteModal(showWarning));
      });
    },
    toggleInfoModal: (payload: boolean) => {
      runDispatchable(() => {
        return dispatch(dictionaryValueActions.toggleInfoModal(payload));
      });
    },
    toggleSecurityModal: (payload: boolean) => {
      runDispatchable(() => {
        return dispatch(dictionaryValueActions.toggleSecurityModal(payload));
      });
    },
    togglePublishModal: (payload: boolean) => {
      runDispatchable(() => {
        return dispatch(dictionaryValueActions.togglePublishModal(payload));
      });
    },
    deleteDictionary: (payload: any) => {
      runDispatchable(() => {
        return dispatch(dictionaryValueActions.deleteDictionary(payload));
      });
    },
    toggleUploadType: (isCorrection: boolean) => {
      runDispatchable(() => {
        return dispatch(dictionaryValueActions.toggleUploadType(isCorrection));
      });
    },
    updateDictionaryDescription: (payload: any) => {
      runDispatchable(() => {
        return dispatch(
          dictionaryValueActions.updateDictionaryDescription(payload)
        );
      });
    },
    resetStateToDefault: (payload: AppProps.DefaultStateData) => {
      runDispatchable(() => {
        return dispatch(
          dictionaryValueActions.syncActions.updateDictionaryValue(payload)
        );
      });
    }
  };
};

export const { DictionaryStatus, DictionaryEditStatus } = commonEnums;

export const determineStatus = (
  sortablePageable: CMxCommonApp.SortablePageable<any>
) => {
  return (
    sortablePageable.filterableOptions.filters?.find(
      filter => filter.key === 'status'
    )?.terms || [DictionaryStatus.PUBLISHED]
  );
};

export const filterStatusToStatus: { [key: string]: string } = {
  [DictionaryStatus.PUBLISHED]: DictionaryStatus.PUBLISHED.toUpperCase(),
  [DictionaryEditStatus.DELETE]: DictionaryStatus.PUBLISHED.toUpperCase(),
  [DictionaryStatus.DRAFT]: DictionaryStatus.DRAFT.toUpperCase(),
  [DictionaryEditStatus.ADD]: DictionaryStatus.DRAFT.toUpperCase(),
  [DictionaryEditStatus.UPDATE]: DictionaryStatus.DRAFT.toUpperCase()
};

export const getFilterableOptions = (
  sortablePageable: CMxCommonApp.SortablePageable<any>
) => {
  const {
    filterableOptions: { filters = [] }
  } = sortablePageable;

  const statusTerm = filters.find(
    (f: CMxCommonApp.Filter) => f.key === 'status'
  )?.terms[0];

  // Filtering for Deleted Rows, Added Rows, Or updated rows requires shimming in an editStatus filter.
  const shimEditStatusFilter = filterStatusFields.find(
    field => field.key === statusTerm
  )?.transform;

  let shimmedFilters = filters;
  if (shimEditStatusFilter) {
    shimmedFilters = shimEditStatusFilter(filters);
  }
  return shimmedFilters || [];
};

export const endpoint = (dictionaryId: any) => {
  const endPoint: EndpointData = {
    endPoint: `/dictionary/${dictionaryId}/row/v2`,
    HTTPMethodType: HTTPMethodType.POST,
    params: [
      ['listType', 'column'],
      ['sort', 'rowIndex']
    ]
  };
  return endPoint;
};

export const uploadItems = [
  {
    key: 'add',
    isCorrection: false,
    label: 'New Version',
    value: 'New Version'
  },
  {
    key: 'correct',
    isCorrection: true,
    label: 'Make a Correction',
    value: 'Make a Correction'
  }
];

export const dictionaryValueMenuItems = [
  {
    label: 'Info',
    value: 'Info',
    disabled: false
  },
  {
    label: 'Make a Correction',
    value: 'Make a Correction',
    disabled: false,
    isCorrection: true
  },
  {
    label: 'Upload New Version',
    value: 'Upload New Version',
    disabled: false,
    isCorrection: false
  },
  {
    label: 'Download',
    value: 'Download',
    disabled: false
  },
  {
    label: 'Security',
    value: 'Security',
    disabled: false
  },
  {
    label: 'History',
    value: 'History',
    disabled: false
  },
  {
    label: 'Metadata',
    value: 'Metadata',
    disabled: false
  },
  {
    label: 'Delete',
    value: 'Delete',
    disabled: false
  }
];

export const getDictionaryValueItems = (
  deleteDisabled: boolean = false,
  metaDataDisabled: boolean = false,
  downloadDisabled: boolean = false
) => {
  return dictionaryValueMenuItems.map((item: MenuItemType) => {
    if (item.value === 'Delete') {
      return { ...item, disabled: deleteDisabled };
    }
    if (item.value === 'Download') {
      return { ...item, disabled: downloadDisabled };
    }
    if (item.value === 'Metadata') {
      return { ...item, disabled: metaDataDisabled };
    }
    return item;
  });
};
