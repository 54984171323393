const cptFieldNumber: CMxCommonApp.FieldDefinition = {
  label: `FT1 CPT Field Number`,
  key: `ft1_cpt_field_number`,
  helpText: 'ft1_cpt_field_number',
  required: false,
  type: 'text'
};

const diagnosisFieldNumber: CMxCommonApp.FieldDefinition = {
  label: `FT1 Diagnosis Field Number`,
  key: `ft1_diagnosis_field_number`,
  helpText: 'ft1_diagnosis_field_number',
  required: false,
  type: 'text'
};

const modifierFieldNumber: CMxCommonApp.FieldDefinition = {
  label: `FT1 Modifier Field Number`,
  key: `ft1_modifier_field_number`,
  helpText: 'ft1_modifier_field_number',
  required: false,
  type: 'text'
};

const transactionIDFieldNumber: CMxCommonApp.FieldDefinition = {
  label: `FT1 Transaction ID Field Number`,
  key: `ft1_txn_id_field_number`,
  helpText: 'ft1_txn_id_field_number',
  required: false,
  type: 'text'
};

const transactionTypeFieldNumber: CMxCommonApp.FieldDefinition = {
  label: `FT1 Transaction Type Field Number`,
  key: `ft1_txn_type_field_number`,
  helpText: 'ft1_txn_type_field_number',
  required: false,
  type: 'text'
};

const unitsFieldNumber: CMxCommonApp.FieldDefinition = {
  label: `FT1 Units Field Number`,
  key: `ft1_units_field_number`,
  helpText: 'ft1_units_field_number',
  required: false,
  type: 'text'
};

const hl7Fields = [
  cptFieldNumber,
  diagnosisFieldNumber,
  modifierFieldNumber,
  transactionIDFieldNumber,
  transactionTypeFieldNumber,
  unitsFieldNumber
];

export default hl7Fields;
