export const styles = () => ({
  dayContainer: {
    marginBottom: '30px',
    textAlign: 'center' as const
  },
  dayCheckbox: {
    display: 'inline-block',
    position: 'relative' as const,
    marginRight: '30px'
  },
  dayInputLabel: {
    flexGrow: 1,
    marginLeft: '20px'
  },
  hourInputContainer: {
    display: 'flex'
  },
  hourInputLabel: {
    flex: 'right',
    color: '#a9d5e5',
    marginLeft: '-58px',
    marginTop: '7px',
    zIndex: 10
  },
  hoursRow: {
    marginBottom: '15px'
  },
  totalLabel: {
    flexGrow: 1,
    fontWeight: 700,
    marginLeft: '20px',
    marginBottom: '20px'
  },
  totalValue: {
    fontWeight: 700,
    marginRight: '20px',
    marginBottom: '20px'
  }
});
