import { Box, Grid } from '@chakra-ui/react';
import { goBack } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { match } from 'react-router-dom';
import { Button } from '../../../components/button/button';
import LoaderOverlay from '../../../components/loader-overlay/loader-overlay';
import { ModalComponent } from '../../../components/Modal/modal';
import { styles } from '../dictionary-styles';

export type RequestInProgressProps = {
  onMount: (ids: any) => void;
  onUnmount: (ids: any) => void;
  requestResponse: CMxAPI.DictionaryRequestResponse;
  match: match<{
    requestIds: string;
  }>;
};

type SimpleMap = {
  [key: string]: string;
};

const RequestTypes: SimpleMap = {
  PUBLISH: 'Publishing',
  UPLOAD: 'Uploading',
  UPDATE: 'Updating',
  DELETE: 'Deleting'
};

export const RequestInProgress: React.FC<RequestInProgressProps> = props => {
  const { onMount, onUnmount, requestResponse } = props;

  useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const requestIds = params.getAll('requestId');
    onMount(requestIds);
    return () => {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      const requestIds = params.getAll('requestId');
      onUnmount(requestIds);
    };
    // eslint-disable-next-line
  }, []);

  const errors = requestResponse.errors?.map(error => {
    return <p>{error.errorMessage}</p>;
  });
  const dispatch = useDispatch();
  const handleAction = () => {
    dispatch(goBack());
  };

  const dictionaryName = requestResponse.dictionaryName ?? 'dictionary';
  const requestType: string = requestResponse.requestType ?? '';
  const action: string =
    requestType in RequestTypes
      ? RequestTypes[requestType]
      : RequestTypes.UPDATE;

  const classes = styles();

  return (
    <Box>
      <div>
        {requestResponse.errorStatus ? (
          <div>
            <ModalComponent
              size={'xl'}
              isOpen={true}
              handleClose={() => {}}
              modalHeader={<div>Error</div>}
              modalContent={<div style={{ padding: '16px' }}>{errors}</div>}
              modalFooter={<Button label={'Go Back'} onClick={handleAction} />}
            />
          </div>
        ) : (
          <Grid sx={classes.loaderWrapper}>
            <LoaderOverlay loadingMsg={`${action} ${dictionaryName}...`} />
          </Grid>
        )}
      </div>
    </Box>
  );
};
