/// <reference path="../../types.d.ts" />

import React from 'react';
import { Card } from '@codametrix/ui-components';
import { DatePicker } from '../../components/date-picker/datepicker';

import '../../styles/grid-common.scss';
import '../../styles/cmx.scss';
import '../pageable-list/pageable-list.scss';
import { cmxDateTime, commonEnums as enums } from '@codametrix/ui-common';

const { FilterTypes } = enums;

const DateFilter: React.FC<AppProps.DateFilterProps> = (
  props: AppProps.DateFilterProps
) => {
  const { sortablePageable, list, criteriaFilter } = props;

  function _handleDateFilterSubmit(event: CustomEvent) {
    event.preventDefault();

    const dateOption = event.detail.value.dateOption;
    if (dateOption === undefined) {
      // error : cannot find selected form

      return;
    }

    var startDate = event.detail.value.range[0].startDate;
    var endDate = event.detail.value.range[0].endDate;

    let newFilter: CMxCommonApp.DateFilter | undefined;

    if (event.detail.value.clear) {
      newFilter = undefined;
    } else if (startDate && endDate) {
      newFilter = {
        key: dateOption,
        from: cmxDateTime.format(startDate, cmxDateTime.FORMATS.DATE),
        to: cmxDateTime.format(endDate, cmxDateTime.FORMATS.DATE),
        type: FilterTypes.BETWEEN
      };
    } else {
      return;
    }

    var listOptions = sortablePageable;
    listOptions.filterableOptions.filters = sortablePageable.filterableOptions.filters?.filter(
      filter => {
        return (
          filter.type !== 'GREATER_THAN_EQUAL' &&
          filter.type !== 'LESS_THAN_EQUAL'
        );
      }
    );
    listOptions.filterableOptions.dateFilter = newFilter;
    listOptions.pageableDefinition.pageable.pageNumber = 0;
    listOptions.pageableDefinition.number = 0;
    listOptions.pageableDefinition.first = true;
    listOptions.pageableDefinition.last = false;
    listOptions.pageableDefinition.pageable.offset = 0;

    list(listOptions);
  }

  const dateOptions = criteriaFilter.filterForms
    .filter(form => {
      return form.isDate;
    })
    .map(form => {
      const currentFilterKey = criteriaFilter.dateFilter?.key;

      return {
        id: form.key,
        name: form.name,
        isSelected: form.key === currentFilterKey
      };
    });

  const filteredDateFilter = criteriaFilter.dateFilter;

  return (
    <Card className={'filter-card date-filter-card'}>
      <DatePicker
        onSelection={_handleDateFilterSubmit}
        startDate={
          filteredDateFilter?.from
            ? new Date(
                cmxDateTime.format(
                  filteredDateFilter?.from,
                  cmxDateTime.FORMATS.DATE,
                  {
                    throw: false,
                    localized: true,
                    offset: {
                      action: cmxDateTime.DateManipulateOperation.ADD,
                      delta: [1, cmxDateTime.DateManipulateUnit.DAY]
                    }
                  }
                )
              )
            : undefined
        }
        endDate={
          filteredDateFilter?.to
            ? new Date(
                cmxDateTime.format(
                  filteredDateFilter?.to,
                  cmxDateTime.FORMATS.DATE,
                  {
                    throw: false,
                    localized: true,
                    offset: {
                      action: cmxDateTime.DateManipulateOperation.ADD,
                      delta: [1, cmxDateTime.DateManipulateUnit.DAY]
                    }
                  }
                )
              )
            : undefined
        }
        options={dateOptions}
      />
    </Card>
  );
};

export default DateFilter;
