import { cmxReducerFactory } from './reducer-utils';
import InitialState from './initial-state';
import { toggleDrawer, toggleDrawerContent } from '../actions/ui';
import { codeBenchActions } from '@codametrix/ui-common';
import * as UIActions from '../actions/ui';

export const reducer = cmxReducerFactory(InitialState.coderNotes)
  .case(toggleDrawerContent, (state, isOpen) => {
    return { ...state, isOpen };
  })
  .case(toggleDrawer, (state, isDrawerOpen) => {
    let updatedState = { ...state, isDrawerOpen };
    if (!isDrawerOpen)
      updatedState = {
        ...InitialState.coderNotes,
        activeUser: state.activeUser,
        isDrawerOpen: isDrawerOpen
      };
    return updatedState;
  })
  .case(UIActions.setActiveUser, (state, details) => {
    return { ...state, activeUser: details };
  })
  .case(
    codeBenchActions.navigateCoderNotes.async.started,
    (state, coderNotesParams) => {
      return {
        ...state,
        isDrawerOpen: true,
        servicesLoading: true,
        coderNotesParams
      };
    }
  )
  .case(codeBenchActions.navigateCoderNotes.async.done, state => {
    return {
      ...state,
      isOpen: true,
      servicesLoading: false
    };
  })
  .case(
    codeBenchActions.fetchCoderNotes.async.done,
    (state, { result: coderNotesList }) => {
      return {
        ...state,
        coderNotesList
      };
    }
  )
  .case(
    codeBenchActions.addCoderNote.async.started,
    (state, coderNoteinput) => {
      return {
        ...state,
        coderNote: coderNoteinput.noteContent ?? ''
      };
    }
  )
  .case(codeBenchActions.addCoderNote.async.done, state => {
    return {
      ...state,
      coderNote: ''
    };
  })
  .case(codeBenchActions.addCoderNote.async.done, state => {
    return {
      ...state,
      coderNote: ''
    };
  });

export default reducer;
