import actionCreatorFactory from 'typescript-fsa';
import asyncFactory from 'typescript-fsa-redux-thunk';
import { api } from '../core/net';
import { CodeLookup } from '../actions/action-types';
import { HttpMethods } from '@codametrix/ui-common';

const actionCreator = actionCreatorFactory();
const createAsync = asyncFactory<any>(actionCreator);

export const codeLookup = createAsync<
  AppProps.CodeLookupProps,
  CMx.CodeLookupResult,
  CMxCommonApp.SubmitError
>(CodeLookup.LOOKUP_CODES, async (params, dispatch) => {
  const lookupParams = new URLSearchParams([['tenantId', params.tenantId]]);

  const values = await api<CMx.CodeLookupResult>({
    endpoint: `/dictionary/code/v2?${lookupParams.toString()}`,
    init: {
      method: HttpMethods.POST
    },
    body: {
      searchTerm: params.searchTerm,
      codeType: params.codeType,
      effectiveDate: params.effectiveDate
    }
  });
  return values;
});
