import { connect } from 'react-redux';
import {
  worklistMapDispatchToProps,
  worklistMapStateToProps
} from './service-desk-component';
import {
  mapUpdatedDispatchToCodeBenchProps,
  mapUpdatedStateToCodeBenchProps
} from '../../views/accelerate/code-bench-component';
import { ServiceDeskAndGradeBench } from './service-desk-and-gradebench-component';
import { gradeBenchPanelActions } from '@codametrix/ui-common';

const stateMapper = (state: CMx.ApplicationState) => {
  return {
    listProps: worklistMapStateToProps(state),
    detailProps: {
      ...mapUpdatedStateToCodeBenchProps(state),
      gradeableInvoices: state.gradeBenchPanel.invoices,
      fieldDefinitions: state.gradeBenchPanel.fieldDefinitions,
      qaRuleCollection: {
        sampleReason: state.gradeBenchPanel.qaRule.data.sample_reason,
        guideline: state.gradeBenchPanel.qaRule.data.guideline
      }
    }
  };
};
const dualDispatchMapper = (dispatch: any) => {
  return {
    listMethods: worklistMapDispatchToProps(dispatch),
    detailMethods: {
      ...mapUpdatedDispatchToCodeBenchProps(dispatch),
      onGradeUpdate: (update: Accelerate.GradeUpdate) => {
        dispatch(gradeBenchPanelActions.gradeUpdate(update));
      },
      fetchGradePanelConfigurations: () => {
        dispatch(gradeBenchPanelActions.fetchRiskCategories());
      },
      onCodeSearchSelection: (update: Accelerate.CodeInfo) => {
        dispatch(gradeBenchPanelActions.addCode(update));
      },
      onGradeRemove: (update: Accelerate.GradeUpdate) => {
        dispatch(gradeBenchPanelActions.removeCode(update));
      },
      fetchQaRule: (update: Accelerate.FetchQaRuleParams) => {
        dispatch(gradeBenchPanelActions.fetchQaRule(update));
      }
    }
  };
};

export const ConnectedServiceDeskAndGradeBench = connect(
  stateMapper,
  dualDispatchMapper
)(ServiceDeskAndGradeBench);
