import React, {
  ReactElement,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect
} from 'react';
import {
  Text,
  Textarea as ChakraTextarea,
  TextareaProps as ChakraTextareaProps,
  Flex,
  HStack
} from '@chakra-ui/react';
import { TextareaVariant, TextareaSize } from '../../core/enums';
import { styles } from './textarea.styles';

export type TextareaProps = {
  size?: TextareaSize;
  disabled?: boolean;
  readonly?: boolean;
  required?: boolean;
  variant?: TextareaVariant;
  className?: string;
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
  placeholder?: string;
  value?: string;
  maxLength?: number;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
} & ChakraTextareaProps;

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      size,
      disabled,
      variant,
      readonly,
      className,
      leftIcon,
      rightIcon,
      placeholder,
      required,
      value,
      maxLength,
      onChange,
      ...props
    },
    ref
  ) => {
    const localRef = useRef<HTMLTextAreaElement>(null);
    const [textLength, setTextLength] = React.useState(value?.length ?? 0);

    // Expose the local ref to the parent component
    useImperativeHandle(ref, () => localRef.current as HTMLTextAreaElement);

    useEffect(() => {
      if (localRef.current) {
        localRef.current.focus();
        localRef.current.selectionStart = localRef.current.value.length;
        localRef.current.selectionEnd = localRef.current.value.length;
      }
    }, []);

    return (
      <HStack>
        <div className="left-icon">{leftIcon}</div>
        <Flex sx={styles.textarea}>
          <ChakraTextarea
            {...props}
            size={size}
            isDisabled={disabled}
            isReadOnly={readonly}
            isRequired={required}
            variant={variant}
            className={className}
            placeholder={placeholder}
            value={value}
            maxLength={maxLength}
            ref={localRef}
            onChange={e => {
              setTextLength(e.target.value.length);
              onChange?.(e);
            }}
          />
          <Text sx={styles.lengthIndicator}>
            {textLength}/{maxLength}
          </Text>
        </Flex>
        <div className="right-icon">{rightIcon}</div>
      </HStack>
    );
  }
);

Textarea.defaultProps = {
  variant: TextareaVariant.OUTLINE,
  size: TextareaSize.MD,
  className: '',
  disabled: false,
  maxLength: 2048
};

Textarea.displayName = 'Textarea';
