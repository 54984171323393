import React from 'react';
import { styles } from '../org-serviceline/org-servicelines.styles';

import {
  PopoverBody,
  Stack,
  Box,
  Flex,
  Text,
  PopoverContent,
  Portal,
  Heading
} from '@chakra-ui/react';

import { dashboardOptions } from './dashboard-options';

type DashBoardSelectProps = {
  dashboardSelectionOpened: boolean;
  activeDashboard: string;
  handleDashboardSelection: (boardName: string) => void;
  setActiveDashboardByName: (boardName: string) => void;
  resources: CMxAPI.Resource[];
};

export const DashboardSelection: React.FC<DashBoardSelectProps> = ({
  dashboardSelectionOpened,
  activeDashboard,
  resources,
  handleDashboardSelection,
  setActiveDashboardByName,
  ...props
}) => {
  const classes = styles();

  if (activeDashboard.length === 0) {
    resources.forEach(resource => {
      if (dashboardOptions[resource.resourceName]) {
        setActiveDashboardByName(
          dashboardOptions[resource.resourceName].dashboards[0]
        );
      }
    });
  }

  return (
    <Portal>
      <Flex className="dashboardSelection">
        <PopoverContent sx={classes['.mainGrid']} className="popOverStyle">
          <PopoverBody sx={classes['.bodyGrid']}>
            <Stack direction="row">
              <Box sx={classes['.popoverInsightsBox']}>
                {dashboardSelectionOpened &&
                  resources.map(resource => {
                    if (dashboardOptions[resource.resourceName]) {
                      const board = dashboardOptions[resource.resourceName];
                      const { name, dashboards } = board;

                      return (
                        <Flex
                          sx={classes['.popoverHeader']}
                          key={name + 'header'}
                          flexDirection="column">
                          <Heading size="md" height="10">
                            {name}
                          </Heading>
                          {dashboards.map(dashboard => {
                            return (
                              <Flex
                                sx={classes['.switchItem']}
                                key={dashboard}
                                onClick={() => {
                                  handleDashboardSelection(dashboard);
                                }}
                                className={`hoverCursor ${
                                  activeDashboard ===
                                  dashboard.split(' ')[0].toLowerCase()
                                    ? 'selectedItem'
                                    : ''
                                }`}>
                                <Text>{dashboard}</Text>
                              </Flex>
                            );
                          })}
                        </Flex>
                      );
                    } else {
                      return null;
                    }
                  })}
              </Box>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Flex>
    </Portal>
  );
};
