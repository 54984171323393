/// <reference path="../../../types.d.ts"" />

import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from './dictionary-value-utils';
import { DictionaryValueList } from './dictionary-value-list';

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DictionaryValueList);
