export const dictionaryMetadata: CMxAPI.DictionaryMetadata = {
  tenantId: '',
  dictionaryName: '',
  description: '',
  purpose: '',
  columnDefinitionMetadata: [],
  crossMapIndicator: false
};

export const columnDefinitionMetadata: CMxAPI.ColumnDefinitionMetadata = {
  columnName: '',
  keyColumnIndicator: false,
  cellReference: {
    id: -1,
    dictionaryName: '',
    lookupProperty: '',
    organizationName: '',
    parentOrganizationName: null,
    columnDefinitionId: -1,
    isRefValueList: false
  }
};

export const columnMetadataDataItem: CMx.ColumnMetadataDataItem = {
  keyColumnIndicator: false,
  columnName: '',
  referenceColumn: undefined,
  referenceDictionary: undefined,
  referenceTenant: undefined,
  isRefValueList: false
};

const commonDictionary: CMxAPI.DictionaryCommon = {
  tenantId: {
    uuid: ''
  },
  effectiveDate: '',
  endDate: null,
  name: '',
  description: '',
  purpose: '',
  status: '',
  id: -1,
  editStatus: '',
  updatedByFullName: 'user',
  updatedDate: '2022-03-23'
};

export const draftDictionary: CMxAPI.Dictionary = {
  ...commonDictionary,
  id: 1,
  dictionaryMetadata: [],
  dictionaryVersionMajor: -1,
  dictionaryVersionMinor: -1,
  version: -1,
  crossMapIndicator: false,
  rows: [],
  columnDefinitions: [],
  status: 'DRAFT',
  updatedByFullName: 'user',
  updatedDate: '2022-03-23',
  unrestrictedIndicator: false
};

export const publishedDictionary: CMxAPI.Dictionary = {
  ...commonDictionary,
  id: 2,
  dictionaryMetadata: [],
  dictionaryVersionMajor: -1,
  dictionaryVersionMinor: -1,
  version: -1,
  crossMapIndicator: false,
  rows: [],
  columnDefinitions: [],
  status: 'PUBLISHED',
  editStatus: '',
  updatedByFullName: 'user',
  updatedDate: '2022-03-23',
  unrestrictedIndicator: false
};

export const publishUpdatedDictionary: CMxAPI.Dictionary = {
  ...commonDictionary,
  id: 3,
  dictionaryMetadata: [],
  dictionaryVersionMajor: -1,
  dictionaryVersionMinor: -1,
  version: -1,
  crossMapIndicator: false,
  rows: [],
  columnDefinitions: [],
  status: 'DRAFT',
  editStatus: 'UPDATE',
  updatedByFullName: 'user',
  updatedDate: '2022-03-23',
  unrestrictedIndicator: false
};

export const defaultDictionary: CMxAPI.Dictionary = {
  ...commonDictionary,
  dictionaryMetadata: [],
  dictionaryVersionMajor: -1,
  dictionaryVersionMinor: -1,
  version: -1,
  crossMapIndicator: false,
  rows: [],
  columnDefinitions: [],
  updatedByFullName: 'user',
  updatedDate: '2022-03-23',
  unrestrictedIndicator: false
};

export const defaultDictionaryRow: CMxAPI.DictionaryRow = {
  ...commonDictionary,

  dictionaryId: -1,
  effectiveDate: '',
  endDate: null,
  cells: [],
  rowIndex: -1,
  dictionary: defaultDictionary,
  dictionaryName: defaultDictionary.name
};

export const defaultDictionaryColumn: CMxAPI.ColumnDefinition = {
  ...commonDictionary,
  cells: [],
  columnIndex: -1,
  dictionary: defaultDictionary,
  dictionaryId: -1,
  dictionaryName: defaultDictionary.name,
  keyColumnIndicator: false,
  cellReferences: []
};

export const procedureTypesColDefs = [
  {
    dictionaryPropertyId: 3,
    name: 'Procedure Types'
  }
];

export const sortablePageable = {
  pageableDefinition: {
    content: [
      {
        id: 3906,
        propertyGroupId: null,
        dictionaryValuesId: 3906,
        effectiveDate: '2020-09-08',
        endDate: null,
        columns: [
          {
            id: 3906,
            propertyGroupValueId: null,
            dictionaryPropertyName: 'Procedure Types',
            value: 'Computed Tomography 2',
            abbrev: 'CTT',
            dictionaryPropertyId: 3,
            dictionaryValueId: 3906,
            mappedValues: [],
            codeMaps: []
          }
        ]
      },
      {
        id: 3901,
        propertyGroupId: null,
        dictionaryValuesId: 3901,
        effectiveDate: '2020-09-08',
        endDate: null,
        columns: [
          {
            id: 3901,
            propertyGroupValueId: null,
            dictionaryPropertyName: 'Procedure Types',
            value: 'Computed Tomography',
            abbrev: 'CTT',
            dictionaryPropertyId: 3,
            dictionaryValueId: 3901,
            mappedValues: [],
            codeMaps: []
          }
        ]
      },
      {
        id: 19,
        propertyGroupId: null,
        dictionaryValuesId: 19,
        effectiveDate: '1970-01-01',
        endDate: null,
        columns: [
          {
            id: 19,
            propertyGroupValueId: null,
            dictionaryPropertyName: 'Procedure Types',
            value: 'Bone Density',
            abbrev: 'DXA',
            dictionaryPropertyId: 3,
            dictionaryValueId: 19,
            mappedValues: [
              {
                id: null,
                dictionaryId: 2,
                dictionaryName: 'Procedure Type Categories',
                dictionaryPropertyId: 2,
                dictionaryPropertyName: 'Procedure Type Categories',
                propertyGroupId: null,
                dictionaryValueId: 10,
                value: 'Miscellaneous',
                abbrev: 'MISC',
                effectiveDate: 0,
                endDate: null
              }
            ],
            codeMaps: []
          }
        ]
      },
      {
        id: 20,
        propertyGroupId: null,
        dictionaryValuesId: 20,
        effectiveDate: '1970-01-01',
        endDate: null,
        columns: [
          {
            id: 20,
            propertyGroupValueId: null,
            dictionaryPropertyName: 'Procedure Types',
            value: 'Computed Radiography',
            abbrev: 'CR',
            dictionaryPropertyId: 3,
            dictionaryValueId: 20,
            mappedValues: [
              {
                id: null,
                dictionaryId: 2,
                dictionaryName: 'Procedure Type Categories',
                dictionaryPropertyId: 2,
                dictionaryPropertyName: 'Procedure Type Categories',
                propertyGroupId: null,
                dictionaryValueId: 17,
                value: 'Computed Radiography',
                abbrev: 'XRAY',
                effectiveDate: 0,
                endDate: null
              }
            ],
            codeMaps: []
          }
        ]
      },
      {
        id: 21,
        propertyGroupId: null,
        dictionaryValuesId: 21,
        effectiveDate: '1970-01-01',
        endDate: null,
        columns: [
          {
            id: 21,
            propertyGroupValueId: null,
            dictionaryPropertyName: 'Procedure Types',
            value: 'Computed Tomographic Angiography',
            abbrev: 'CTA',
            dictionaryPropertyId: 3,
            dictionaryValueId: 21,
            mappedValues: [
              {
                id: null,
                dictionaryId: 2,
                dictionaryName: 'Procedure Type Categories',
                dictionaryPropertyId: 2,
                dictionaryPropertyName: 'Procedure Type Categories',
                propertyGroupId: null,
                dictionaryValueId: 7,
                value: 'Computed Tomography',
                abbrev: 'CT',
                effectiveDate: 0,
                endDate: null
              }
            ],
            codeMaps: []
          }
        ]
      },
      {
        id: 22,
        propertyGroupId: null,
        dictionaryValuesId: 22,
        effectiveDate: '1970-01-01',
        endDate: '2020-09-08',
        columns: [
          {
            id: 22,
            propertyGroupValueId: null,
            dictionaryPropertyName: 'Procedure Types',
            value: 'Computed Tomography',
            abbrev: 'CT',
            dictionaryPropertyId: 3,
            dictionaryValueId: 22,
            mappedValues: [
              {
                id: null,
                dictionaryId: 2,
                dictionaryName: 'Procedure Type Categories',
                dictionaryPropertyId: 2,
                dictionaryPropertyName: 'Procedure Type Categories',
                propertyGroupId: null,
                dictionaryValueId: 7,
                value: 'Computed Tomography',
                abbrev: 'CT',
                effectiveDate: 0,
                endDate: null
              }
            ],
            codeMaps: []
          }
        ]
      }
    ],
    pageable: {
      sort: {
        unsorted: true,
        sorted: false,
        empty: true
      },
      offset: 0,
      pageSize: 25,
      pageNumber: 0,
      paged: true,
      unpaged: false
    },
    last: false,
    totalPages: 2,
    totalElements: 27,
    size: 25,
    number: 0,
    numberOfElements: 25,
    first: true,
    sort: {
      unsorted: true,
      sorted: false,
      empty: true
    },
    empty: false
  },
  sortableOptions: [],
  filterableOptions: {
    filters: []
  }
};

export const filteredSortablePageable = {
  pageableDefinition: {
    content: [
      {
        id: 3906,
        propertyGroupId: null,
        dictionaryValuesId: 3906,
        effectiveDate: '2020-09-08',
        endDate: null,
        columns: [
          {
            id: 3906,
            propertyGroupValueId: null,
            dictionaryPropertyName: 'Procedure Types',
            value: 'Computed Tomography 2',
            abbrev: 'CTT',
            dictionaryPropertyId: 3,
            dictionaryValueId: 3906,
            mappedValues: [],
            codeMaps: []
          }
        ]
      },
      {
        id: 3901,
        propertyGroupId: null,
        dictionaryValuesId: 3901,
        effectiveDate: '2020-09-08',
        endDate: null,
        columns: [
          {
            id: 3901,
            propertyGroupValueId: null,
            dictionaryPropertyName: 'Procedure Types',
            value: 'Computed Tomography',
            abbrev: 'CTT',
            dictionaryPropertyId: 3,
            dictionaryValueId: 3901,
            mappedValues: [],
            codeMaps: []
          }
        ]
      },
      {
        id: 19,
        propertyGroupId: null,
        dictionaryValuesId: 19,
        effectiveDate: '1970-01-01',
        endDate: null,
        columns: [
          {
            id: 19,
            propertyGroupValueId: null,
            dictionaryPropertyName: 'Procedure Types',
            value: 'Bone Density',
            abbrev: 'DXA',
            dictionaryPropertyId: 3,
            dictionaryValueId: 19,
            mappedValues: [
              {
                id: null,
                dictionaryId: 2,
                dictionaryName: 'Procedure Type Categories',
                dictionaryPropertyId: 2,
                dictionaryPropertyName: 'Procedure Type Categories',
                propertyGroupId: null,
                dictionaryValueId: 10,
                value: 'Miscellaneous',
                abbrev: 'MISC',
                effectiveDate: 0,
                endDate: null
              }
            ],
            codeMaps: []
          }
        ]
      },
      {
        id: 20,
        propertyGroupId: null,
        dictionaryValuesId: 20,
        effectiveDate: '1970-01-01',
        endDate: null,
        columns: [
          {
            id: 20,
            propertyGroupValueId: null,
            dictionaryPropertyName: 'Procedure Types',
            value: 'Computed Radiography',
            abbrev: 'CR',
            dictionaryPropertyId: 3,
            dictionaryValueId: 20,
            mappedValues: [
              {
                id: null,
                dictionaryId: 2,
                dictionaryName: 'Procedure Type Categories',
                dictionaryPropertyId: 2,
                dictionaryPropertyName: 'Procedure Type Categories',
                propertyGroupId: null,
                dictionaryValueId: 17,
                value: 'Computed Radiography',
                abbrev: 'XRAY',
                effectiveDate: 0,
                endDate: null
              }
            ],
            codeMaps: []
          }
        ]
      },
      {
        id: 21,
        propertyGroupId: null,
        dictionaryValuesId: 21,
        effectiveDate: '1970-01-01',
        endDate: null,
        columns: [
          {
            id: 21,
            propertyGroupValueId: null,
            dictionaryPropertyName: 'Procedure Types',
            value: 'Computed Tomographic Angiography',
            abbrev: 'CTA',
            dictionaryPropertyId: 3,
            dictionaryValueId: 21,
            mappedValues: [
              {
                id: null,
                dictionaryId: 2,
                dictionaryName: 'Procedure Type Categories',
                dictionaryPropertyId: 2,
                dictionaryPropertyName: 'Procedure Type Categories',
                propertyGroupId: null,
                dictionaryValueId: 7,
                value: 'Computed Tomography',
                abbrev: 'CT',
                effectiveDate: 0,
                endDate: null
              }
            ],
            codeMaps: []
          }
        ]
      },
      {
        id: 22,
        propertyGroupId: null,
        dictionaryValuesId: 22,
        effectiveDate: '1970-01-01',
        endDate: '2020-09-08',
        columns: [
          {
            id: 22,
            propertyGroupValueId: null,
            dictionaryPropertyName: 'Procedure Types',
            value: 'Computed Tomography',
            abbrev: 'CT',
            dictionaryPropertyId: 3,
            dictionaryValueId: 22,
            mappedValues: [
              {
                id: null,
                dictionaryId: 2,
                dictionaryName: 'Procedure Type Categories',
                dictionaryPropertyId: 2,
                dictionaryPropertyName: 'Procedure Type Categories',
                propertyGroupId: null,
                dictionaryValueId: 7,
                value: 'Computed Tomography',
                abbrev: 'CT',
                effectiveDate: 0,
                endDate: null
              }
            ],
            codeMaps: []
          }
        ]
      }
    ],
    pageable: {
      sort: {
        unsorted: true,
        sorted: false,
        empty: true
      },
      offset: 0,
      pageSize: 25,
      pageNumber: 0,
      paged: true,
      unpaged: false
    },
    last: false,
    totalPages: 2,
    totalElements: 27,
    size: 25,
    number: 0,
    numberOfElements: 25,
    first: true,
    sort: {
      unsorted: true,
      sorted: false,
      empty: true
    },
    empty: false
  },
  sortableOptions: [],
  filterableOptions: {
    filters: [{ key: 'status', terms: ['draft'], type: 'IN' }]
  }
};

export const publishFilteredSortablePageable = {
  pageableDefinition: {
    content: [
      {
        id: 3906,
        propertyGroupId: null,
        dictionaryValuesId: 3906,
        effectiveDate: '2020-09-08',
        endDate: null,
        columns: [
          {
            id: 3906,
            propertyGroupValueId: null,
            dictionaryPropertyName: 'Procedure Types',
            value: 'Computed Tomography 2',
            abbrev: 'CTT',
            dictionaryPropertyId: 3,
            dictionaryValueId: 3906,
            mappedValues: [],
            codeMaps: []
          }
        ]
      }
    ],
    pageable: {
      sort: {
        unsorted: true,
        sorted: false,
        empty: true
      },
      offset: 0,
      pageSize: 25,
      pageNumber: 0,
      paged: true,
      unpaged: false
    },
    last: false,
    totalPages: 2,
    totalElements: 27,
    size: 25,
    number: 0,
    numberOfElements: 25,
    first: true,
    sort: {
      unsorted: true,
      sorted: false,
      empty: true
    },
    empty: false
  },
  sortableOptions: [],
  filterableOptions: {
    filters: [{ key: 'status', terms: ['draft'], type: 'IN' }]
  }
};

export const criterialFilter = {
  filters: [],
  filterForms: [
    {
      name: 'Status',
      key: 'status',
      isDate: false,
      formDefinition: {
        fieldGroups: [
          {
            label: 'Filter Status',
            fields: [
              {
                key: 'PUBLISHED',
                label: 'Published',
                type: 'checkbox'
              },
              {
                key: 'ARCHIVED',
                label: 'Archived',
                type: 'checkbox'
              }
            ]
          }
        ]
      }
    }
  ],
  searchForms: [
    {
      label: 'Procedure Types',
      key: 'Procedure Types',
      type: 'EQ'
    }
  ]
};

export const pathToValueList = [
  {
    displayName: 'CodaMetrix',
    display: true,
    link: true,
    data: '/cmx/admin/orgs/1/dictionaries/dictionary/'
  },
  {
    displayName: 'Patient Class',
    display: true,
    link: false,
    data: '/cmx/admin/orgs/1/dictionaries/dictionary/1'
  }
];

export const pathToForm = [
  {
    displayName: 'CodaMetrix',
    display: true,
    link: true,
    data: '/cmx/admin/orgs/1/dictionaries/dictionary/'
  },
  {
    displayName: 'Patient Class',
    display: true,
    link: true,
    data: '/cmx/admin/orgs/1/dictionaries/dictionary/1'
  },
  {
    displayName: 'Edit',
    display: true,
    link: false,
    data: ''
  }
];

export const ProcTypeCategoryDictionary = {
  id: 2,
  version: 0,
  tenantId: {
    uuid: 'BB8-TENANT-R2D2'
  },
  dictionaryVersionMajor: 1,
  dictionaryVersionMinor: 3,
  name: 'Procedure Type Categories',
  crossMapIndicator: false,
  status: 'PUBLISHED',
  editStatus: null,
  effectiveDate: '1970-01-01',
  endDate: null,
  dictionaryMetadata: [],
  columnDefinitions: [
    {
      id: 3,
      tenantId: {
        uuid: 'BB8-TENANT-R2D2'
      },
      name: 'Procedure Type Categories',
      status: 'PUBLISHED',
      editStatus: null,
      columnIndex: 1,
      dictionaryId: 2,
      dictionaryName: 'Procedure Type Categories',
      dictionary: {
        id: 2,
        version: 0,
        tenantId: {
          uuid: 'BB8-TENANT-R2D2'
        },
        dictionaryVersionMajor: 1,
        dictionaryVersionMinor: 3,
        name: 'Procedure Type Categories',
        crossMapIndicator: false,
        status: 'PUBLISHED',
        editStatus: null,
        effectiveDate: '1970-01-01',
        endDate: null,
        dictionaryMetadata: [],
        columnDefinitions: [],
        rows: []
      },
      effectiveDate: '1970-01-01',
      endDate: null,
      cells: [
        {
          id: 18,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'Mammography',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 3,
          rowId: 12,
          columnName: 'Procedure Type Categories',
          rowIndex: 6,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 3,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Procedure Type Categories',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 1,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 12,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 6,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        },
        {
          id: 20,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'Not Applicable',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 3,
          rowId: 14,
          columnName: 'Procedure Type Categories',
          rowIndex: 8,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 3,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Procedure Type Categories',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 1,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 14,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 8,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        },
        {
          id: 22,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'Obstetrics',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 3,
          rowId: 16,
          columnName: 'Procedure Type Categories',
          rowIndex: 10,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 3,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Procedure Type Categories',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 1,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 16,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 10,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        },
        {
          id: 19,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'Miscellaneous',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 3,
          rowId: 13,
          columnName: 'Procedure Type Categories',
          rowIndex: 7,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 3,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Procedure Type Categories',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 1,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 13,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 7,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        },
        {
          id: 23,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'Positron emission tomography',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 3,
          rowId: 17,
          columnName: 'Procedure Type Categories',
          rowIndex: 11,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 3,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Procedure Type Categories',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 1,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 17,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 11,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        },
        {
          id: 21,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'Nuclear Medicine',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 3,
          rowId: 15,
          columnName: 'Procedure Type Categories',
          rowIndex: 9,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 3,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Procedure Type Categories',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 1,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 15,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 9,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        },
        {
          id: 14,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'Computed Radiography',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 3,
          rowId: 8,
          columnName: 'Procedure Type Categories',
          rowIndex: 2,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 3,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Procedure Type Categories',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 1,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 8,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 2,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        },
        {
          id: 17,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'Magnetic Resonance',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 3,
          rowId: 11,
          columnName: 'Procedure Type Categories',
          rowIndex: 5,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 3,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Procedure Type Categories',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 1,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 11,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 5,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        },
        {
          id: 13,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'Administrative',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 3,
          rowId: 7,
          columnName: 'Procedure Type Categories',
          rowIndex: 1,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 3,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Procedure Type Categories',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 1,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 7,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 1,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        },
        {
          id: 16,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'Interventional Radiology',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 3,
          rowId: 10,
          columnName: 'Procedure Type Categories',
          rowIndex: 4,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 3,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Procedure Type Categories',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 1,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 10,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 4,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        },
        {
          id: 24,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'Ultrasound',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 3,
          rowId: 18,
          columnName: 'Procedure Type Categories',
          rowIndex: 12,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 3,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Procedure Type Categories',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 1,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 18,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 12,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        },
        {
          id: 15,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'Computed Tomography',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 3,
          rowId: 9,
          columnName: 'Procedure Type Categories',
          rowIndex: 3,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 3,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Procedure Type Categories',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 1,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 9,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 3,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        }
      ]
    },
    {
      id: 4,
      tenantId: {
        uuid: 'BB8-TENANT-R2D2'
      },
      name: 'Abbreviation',
      status: 'PUBLISHED',
      editStatus: null,
      columnIndex: 2,
      dictionaryId: 2,
      dictionaryName: 'Procedure Type Categories',
      dictionary: {
        id: 2,
        version: 0,
        tenantId: {
          uuid: 'BB8-TENANT-R2D2'
        },
        dictionaryVersionMajor: 1,
        dictionaryVersionMinor: 3,
        name: 'Procedure Type Categories',
        crossMapIndicator: false,
        status: 'PUBLISHED',
        editStatus: null,
        effectiveDate: '1970-01-01',
        endDate: null,
        dictionaryMetadata: [],
        columnDefinitions: [],
        rows: []
      },
      effectiveDate: '1970-01-01',
      endDate: null,
      cells: [
        {
          id: 28,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'IR',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 4,
          rowId: 10,
          columnName: 'Abbreviation',
          rowIndex: 4,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 4,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Abbreviation',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 2,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 10,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 4,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        },
        {
          id: 29,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'MR',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 4,
          rowId: 11,
          columnName: 'Abbreviation',
          rowIndex: 5,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 4,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Abbreviation',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 2,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 11,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 5,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        },
        {
          id: 31,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'MISC',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 4,
          rowId: 13,
          columnName: 'Abbreviation',
          rowIndex: 7,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 4,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Abbreviation',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 2,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 13,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 7,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        },
        {
          id: 32,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'NA',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 4,
          rowId: 14,
          columnName: 'Abbreviation',
          rowIndex: 8,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 4,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Abbreviation',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 2,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 14,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 8,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        },
        {
          id: 33,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'NUCLEAR',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 4,
          rowId: 15,
          columnName: 'Abbreviation',
          rowIndex: 9,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 4,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Abbreviation',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 2,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 15,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 9,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        },
        {
          id: 27,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'CT',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 4,
          rowId: 9,
          columnName: 'Abbreviation',
          rowIndex: 3,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 4,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Abbreviation',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 2,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 9,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 3,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        },
        {
          id: 34,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'OB',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 4,
          rowId: 16,
          columnName: 'Abbreviation',
          rowIndex: 10,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 4,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Abbreviation',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 2,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 16,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 10,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        },
        {
          id: 30,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'MAMMO',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 4,
          rowId: 12,
          columnName: 'Abbreviation',
          rowIndex: 6,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 4,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Abbreviation',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 2,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 12,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 6,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        },
        {
          id: 36,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'US',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 4,
          rowId: 18,
          columnName: 'Abbreviation',
          rowIndex: 12,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 4,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Abbreviation',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 2,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 18,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 12,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        },
        {
          id: 25,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'ADMIN',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 4,
          rowId: 7,
          columnName: 'Abbreviation',
          rowIndex: 1,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 4,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Abbreviation',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 2,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 7,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 1,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        },
        {
          id: 26,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'XRAY',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 4,
          rowId: 8,
          columnName: 'Abbreviation',
          rowIndex: 2,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 4,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Abbreviation',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 2,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 8,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 2,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        },
        {
          id: 35,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'PET',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 4,
          rowId: 17,
          columnName: 'Abbreviation',
          rowIndex: 11,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 4,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Abbreviation',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 2,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 17,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 11,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        }
      ]
    }
  ],
  rows: [
    {
      id: 9,
      tenantId: {
        uuid: 'BB8-TENANT-R2D2'
      },
      rowIndex: 3,
      status: 'PUBLISHED',
      editStatus: null,
      effectiveDate: '1970-01-01',
      endDate: null,
      dictionaryId: 2,
      dictionaryName: 'Procedure Type Categories',
      dictionary: {
        id: 2,
        version: 0,
        tenantId: {
          uuid: 'BB8-TENANT-R2D2'
        },
        dictionaryVersionMajor: 1,
        dictionaryVersionMinor: 3,
        name: 'Procedure Type Categories',
        crossMapIndicator: false,
        status: 'PUBLISHED',
        editStatus: null,
        effectiveDate: '1970-01-01',
        endDate: null,
        dictionaryMetadata: [],
        columnDefinitions: [],
        rows: []
      },
      cells: [
        {
          id: 27,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'CT',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 4,
          rowId: 9,
          columnName: 'Abbreviation',
          rowIndex: 3,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 4,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Abbreviation',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 2,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 9,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 3,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        },
        {
          id: 15,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'Computed Tomography',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 3,
          rowId: 9,
          columnName: 'Procedure Type Categories',
          rowIndex: 3,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 3,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Procedure Type Categories',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 1,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 9,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 3,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        }
      ]
    },
    {
      id: 16,
      tenantId: {
        uuid: 'BB8-TENANT-R2D2'
      },
      rowIndex: 10,
      status: 'PUBLISHED',
      editStatus: null,
      effectiveDate: '1970-01-01',
      endDate: null,
      dictionaryId: 2,
      dictionaryName: 'Procedure Type Categories',
      dictionary: {
        id: 2,
        version: 0,
        tenantId: {
          uuid: 'BB8-TENANT-R2D2'
        },
        dictionaryVersionMajor: 1,
        dictionaryVersionMinor: 3,
        name: 'Procedure Type Categories',
        crossMapIndicator: false,
        status: 'PUBLISHED',
        editStatus: null,
        effectiveDate: '1970-01-01',
        endDate: null,
        dictionaryMetadata: [],
        columnDefinitions: [],
        rows: []
      },
      cells: [
        {
          id: 22,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'Obstetrics',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 3,
          rowId: 16,
          columnName: 'Procedure Type Categories',
          rowIndex: 10,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 3,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Procedure Type Categories',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 1,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 16,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 10,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        },
        {
          id: 34,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'OB',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 4,
          rowId: 16,
          columnName: 'Abbreviation',
          rowIndex: 10,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 4,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Abbreviation',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 2,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 16,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 10,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        }
      ]
    },
    {
      id: 12,
      tenantId: {
        uuid: 'BB8-TENANT-R2D2'
      },
      rowIndex: 6,
      status: 'PUBLISHED',
      editStatus: null,
      effectiveDate: '1970-01-01',
      endDate: null,
      dictionaryId: 2,
      dictionaryName: 'Procedure Type Categories',
      dictionary: {
        id: 2,
        version: 0,
        tenantId: {
          uuid: 'BB8-TENANT-R2D2'
        },
        dictionaryVersionMajor: 1,
        dictionaryVersionMinor: 3,
        name: 'Procedure Type Categories',
        crossMapIndicator: false,
        status: 'PUBLISHED',
        editStatus: null,
        effectiveDate: '1970-01-01',
        endDate: null,
        dictionaryMetadata: [],
        columnDefinitions: [],
        rows: []
      },
      cells: [
        {
          id: 18,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'Mammography',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 3,
          rowId: 12,
          columnName: 'Procedure Type Categories',
          rowIndex: 6,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 3,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Procedure Type Categories',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 1,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 12,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 6,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        },
        {
          id: 30,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'MAMMO',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 4,
          rowId: 12,
          columnName: 'Abbreviation',
          rowIndex: 6,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 4,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Abbreviation',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 2,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 12,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 6,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        }
      ]
    },
    {
      id: 18,
      tenantId: {
        uuid: 'BB8-TENANT-R2D2'
      },
      rowIndex: 12,
      status: 'PUBLISHED',
      editStatus: null,
      effectiveDate: '1970-01-01',
      endDate: null,
      dictionaryId: 2,
      dictionaryName: 'Procedure Type Categories',
      dictionary: {
        id: 2,
        version: 0,
        tenantId: {
          uuid: 'BB8-TENANT-R2D2'
        },
        dictionaryVersionMajor: 1,
        dictionaryVersionMinor: 3,
        name: 'Procedure Type Categories',
        crossMapIndicator: false,
        status: 'PUBLISHED',
        editStatus: null,
        effectiveDate: '1970-01-01',
        endDate: null,
        dictionaryMetadata: [],
        columnDefinitions: [],
        rows: []
      },
      cells: [
        {
          id: 36,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'US',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 4,
          rowId: 18,
          columnName: 'Abbreviation',
          rowIndex: 12,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 4,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Abbreviation',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 2,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 18,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 12,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        },
        {
          id: 24,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'Ultrasound',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 3,
          rowId: 18,
          columnName: 'Procedure Type Categories',
          rowIndex: 12,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 3,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Procedure Type Categories',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 1,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 18,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 12,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        }
      ]
    },
    {
      id: 14,
      tenantId: {
        uuid: 'BB8-TENANT-R2D2'
      },
      rowIndex: 8,
      status: 'PUBLISHED',
      editStatus: null,
      effectiveDate: '1970-01-01',
      endDate: null,
      dictionaryId: 2,
      dictionaryName: 'Procedure Type Categories',
      dictionary: {
        id: 2,
        version: 0,
        tenantId: {
          uuid: 'BB8-TENANT-R2D2'
        },
        dictionaryVersionMajor: 1,
        dictionaryVersionMinor: 3,
        name: 'Procedure Type Categories',
        crossMapIndicator: false,
        status: 'PUBLISHED',
        editStatus: null,
        effectiveDate: '1970-01-01',
        endDate: null,
        dictionaryMetadata: [],
        columnDefinitions: [],
        rows: []
      },
      cells: [
        {
          id: 20,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'Not Applicable',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 3,
          rowId: 14,
          columnName: 'Procedure Type Categories',
          rowIndex: 8,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 3,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Procedure Type Categories',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 1,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 14,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 8,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        },
        {
          id: 32,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'NA',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 4,
          rowId: 14,
          columnName: 'Abbreviation',
          rowIndex: 8,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 4,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Abbreviation',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 2,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 14,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 8,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        }
      ]
    },
    {
      id: 15,
      tenantId: {
        uuid: 'BB8-TENANT-R2D2'
      },
      rowIndex: 9,
      status: 'PUBLISHED',
      editStatus: null,
      effectiveDate: '1970-01-01',
      endDate: null,
      dictionaryId: 2,
      dictionaryName: 'Procedure Type Categories',
      dictionary: {
        id: 2,
        version: 0,
        tenantId: {
          uuid: 'BB8-TENANT-R2D2'
        },
        dictionaryVersionMajor: 1,
        dictionaryVersionMinor: 3,
        name: 'Procedure Type Categories',
        crossMapIndicator: false,
        status: 'PUBLISHED',
        editStatus: null,
        effectiveDate: '1970-01-01',
        endDate: null,
        dictionaryMetadata: [],
        columnDefinitions: [],
        rows: []
      },
      cells: [
        {
          id: 33,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'NUCLEAR',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 4,
          rowId: 15,
          columnName: 'Abbreviation',
          rowIndex: 9,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 4,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Abbreviation',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 2,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 15,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 9,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        },
        {
          id: 21,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'Nuclear Medicine',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 3,
          rowId: 15,
          columnName: 'Procedure Type Categories',
          rowIndex: 9,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 3,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Procedure Type Categories',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 1,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 15,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 9,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        }
      ]
    },
    {
      id: 7,
      tenantId: {
        uuid: 'BB8-TENANT-R2D2'
      },
      rowIndex: 1,
      status: 'PUBLISHED',
      editStatus: null,
      effectiveDate: '1970-01-01',
      endDate: null,
      dictionaryId: 2,
      dictionaryName: 'Procedure Type Categories',
      dictionary: {
        id: 2,
        version: 0,
        tenantId: {
          uuid: 'BB8-TENANT-R2D2'
        },
        dictionaryVersionMajor: 1,
        dictionaryVersionMinor: 3,
        name: 'Procedure Type Categories',
        crossMapIndicator: false,
        status: 'PUBLISHED',
        editStatus: null,
        effectiveDate: '1970-01-01',
        endDate: null,
        dictionaryMetadata: [],
        columnDefinitions: [],
        rows: []
      },
      cells: [
        {
          id: 13,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'Administrative',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 3,
          rowId: 7,
          columnName: 'Procedure Type Categories',
          rowIndex: 1,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 3,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Procedure Type Categories',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 1,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 7,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 1,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        },
        {
          id: 25,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'ADMIN',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 4,
          rowId: 7,
          columnName: 'Abbreviation',
          rowIndex: 1,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 4,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Abbreviation',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 2,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 7,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 1,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        }
      ]
    },
    {
      id: 8,
      tenantId: {
        uuid: 'BB8-TENANT-R2D2'
      },
      rowIndex: 2,
      status: 'PUBLISHED',
      editStatus: null,
      effectiveDate: '1970-01-01',
      endDate: null,
      dictionaryId: 2,
      dictionaryName: 'Procedure Type Categories',
      dictionary: {
        id: 2,
        version: 0,
        tenantId: {
          uuid: 'BB8-TENANT-R2D2'
        },
        dictionaryVersionMajor: 1,
        dictionaryVersionMinor: 3,
        name: 'Procedure Type Categories',
        crossMapIndicator: false,
        status: 'PUBLISHED',
        editStatus: null,
        effectiveDate: '1970-01-01',
        endDate: null,
        dictionaryMetadata: [],
        columnDefinitions: [],
        rows: []
      },
      cells: [
        {
          id: 14,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'Computed Radiography',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 3,
          rowId: 8,
          columnName: 'Procedure Type Categories',
          rowIndex: 2,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 3,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Procedure Type Categories',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 1,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 8,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 2,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        },
        {
          id: 26,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'XRAY',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 4,
          rowId: 8,
          columnName: 'Abbreviation',
          rowIndex: 2,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 4,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Abbreviation',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 2,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 8,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 2,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        }
      ]
    },
    {
      id: 17,
      tenantId: {
        uuid: 'BB8-TENANT-R2D2'
      },
      rowIndex: 11,
      status: 'PUBLISHED',
      editStatus: null,
      effectiveDate: '1970-01-01',
      endDate: null,
      dictionaryId: 2,
      dictionaryName: 'Procedure Type Categories',
      dictionary: {
        id: 2,
        version: 0,
        tenantId: {
          uuid: 'BB8-TENANT-R2D2'
        },
        dictionaryVersionMajor: 1,
        dictionaryVersionMinor: 3,
        name: 'Procedure Type Categories',
        crossMapIndicator: false,
        status: 'PUBLISHED',
        editStatus: null,
        effectiveDate: '1970-01-01',
        endDate: null,
        dictionaryMetadata: [],
        columnDefinitions: [],
        rows: []
      },
      cells: [
        {
          id: 23,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'Positron emission tomography',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 3,
          rowId: 17,
          columnName: 'Procedure Type Categories',
          rowIndex: 11,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 3,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Procedure Type Categories',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 1,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 17,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 11,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        },
        {
          id: 35,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'PET',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 4,
          rowId: 17,
          columnName: 'Abbreviation',
          rowIndex: 11,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 4,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Abbreviation',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 2,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 17,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 11,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        }
      ]
    },
    {
      id: 11,
      tenantId: {
        uuid: 'BB8-TENANT-R2D2'
      },
      rowIndex: 5,
      status: 'PUBLISHED',
      editStatus: null,
      effectiveDate: '1970-01-01',
      endDate: null,
      dictionaryId: 2,
      dictionaryName: 'Procedure Type Categories',
      dictionary: {
        id: 2,
        version: 0,
        tenantId: {
          uuid: 'BB8-TENANT-R2D2'
        },
        dictionaryVersionMajor: 1,
        dictionaryVersionMinor: 3,
        name: 'Procedure Type Categories',
        crossMapIndicator: false,
        status: 'PUBLISHED',
        editStatus: null,
        effectiveDate: '1970-01-01',
        endDate: null,
        dictionaryMetadata: [],
        columnDefinitions: [],
        rows: []
      },
      cells: [
        {
          id: 29,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'MR',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 4,
          rowId: 11,
          columnName: 'Abbreviation',
          rowIndex: 5,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 4,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Abbreviation',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 2,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 11,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 5,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        },
        {
          id: 17,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'Magnetic Resonance',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 3,
          rowId: 11,
          columnName: 'Procedure Type Categories',
          rowIndex: 5,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 3,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Procedure Type Categories',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 1,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 11,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 5,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        }
      ]
    },
    {
      id: 10,
      tenantId: {
        uuid: 'BB8-TENANT-R2D2'
      },
      rowIndex: 4,
      status: 'PUBLISHED',
      editStatus: null,
      effectiveDate: '1970-01-01',
      endDate: null,
      dictionaryId: 2,
      dictionaryName: 'Procedure Type Categories',
      dictionary: {
        id: 2,
        version: 0,
        tenantId: {
          uuid: 'BB8-TENANT-R2D2'
        },
        dictionaryVersionMajor: 1,
        dictionaryVersionMinor: 3,
        name: 'Procedure Type Categories',
        crossMapIndicator: false,
        status: 'PUBLISHED',
        editStatus: null,
        effectiveDate: '1970-01-01',
        endDate: null,
        dictionaryMetadata: [],
        columnDefinitions: [],
        rows: []
      },
      cells: [
        {
          id: 28,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'IR',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 4,
          rowId: 10,
          columnName: 'Abbreviation',
          rowIndex: 4,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 4,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Abbreviation',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 2,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 10,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 4,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        },
        {
          id: 16,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'Interventional Radiology',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 3,
          rowId: 10,
          columnName: 'Procedure Type Categories',
          rowIndex: 4,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 3,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Procedure Type Categories',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 1,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 10,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 4,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        }
      ]
    },
    {
      id: 13,
      tenantId: {
        uuid: 'BB8-TENANT-R2D2'
      },
      rowIndex: 7,
      status: 'PUBLISHED',
      editStatus: null,
      effectiveDate: '1970-01-01',
      endDate: null,
      dictionaryId: 2,
      dictionaryName: 'Procedure Type Categories',
      dictionary: {
        id: 2,
        version: 0,
        tenantId: {
          uuid: 'BB8-TENANT-R2D2'
        },
        dictionaryVersionMajor: 1,
        dictionaryVersionMinor: 3,
        name: 'Procedure Type Categories',
        crossMapIndicator: false,
        status: 'PUBLISHED',
        editStatus: null,
        effectiveDate: '1970-01-01',
        endDate: null,
        dictionaryMetadata: [],
        columnDefinitions: [],
        rows: []
      },
      cells: [
        {
          id: 31,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'MISC',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 4,
          rowId: 13,
          columnName: 'Abbreviation',
          rowIndex: 7,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 4,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Abbreviation',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 2,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 13,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 7,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        },
        {
          id: 19,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'Miscellaneous',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 3,
          rowId: 13,
          columnName: 'Procedure Type Categories',
          rowIndex: 7,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 3,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Procedure Type Categories',
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 1,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: []
          },
          row: {
            id: 13,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 7,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: []
          },
          dictionaryMetadata: []
        }
      ]
    }
  ]
};

export const procedureTypeCategoryDictionary: any = {
  id: 2,
  version: 0,
  tenantId: {
    uuid: 'BB8-TENANT-R2D2'
  },
  dictionaryVersionMajor: 1,
  dictionaryVersionMinor: 3,
  name: 'Procedure Type Categories',
  crossMapIndicator: false,
  status: 'PUBLISHED',
  editStatus: null,
  effectiveDate: '1970-01-01',
  endDate: null,
  columnDefinitions: [
    {
      id: 4,
      tenantId: {
        uuid: 'BB8-TENANT-R2D2'
      },
      name: 'Abbreviation',
      keyColumnIndicator: false,
      status: 'PUBLISHED',
      editStatus: null,
      columnIndex: 2,
      dictionaryId: 2,
      dictionaryName: 'Procedure Type Categories',
      dictionary: {
        id: 2,
        version: 0,
        tenantId: {
          uuid: 'BB8-TENANT-R2D2'
        },
        dictionaryVersionMajor: 1,
        dictionaryVersionMinor: 3,
        name: 'Procedure Type Categories',
        crossMapIndicator: false,
        status: 'PUBLISHED',
        editStatus: null,
        effectiveDate: '1970-01-01',
        endDate: null,
        columnDefinitions: [],
        rows: []
      },
      effectiveDate: '1970-01-01',
      endDate: null,
      cells: [
        {
          id: 25,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'ADMIN',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 4,
          rowId: 7,
          columnName: 'Abbreviation',
          rowIndex: 1,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 4,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Abbreviation',
            keyColumnIndicator: false,
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 2,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: {
              id: 2,
              version: 0,
              tenantId: {
                uuid: 'BB8-TENANT-R2D2'
              },
              dictionaryVersionMajor: 1,
              dictionaryVersionMinor: 3,
              name: 'Procedure Type Categories',
              crossMapIndicator: false,
              status: 'PUBLISHED',
              editStatus: null,
              effectiveDate: '1970-01-01',
              endDate: null,
              columnDefinitions: [],
              rows: []
            },
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: [],
            cellReferences: []
          },
          row: {
            id: 7,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 1,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: [],
            crossmapColumns: [],
            keyCell: null
          },
          dictionaryMetadata: []
        },
        {
          id: 32,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'NA',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 4,
          rowId: 14,
          columnName: 'Abbreviation',
          rowIndex: 8,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 4,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Abbreviation',
            keyColumnIndicator: false,
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 2,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: {
              id: 2,
              version: 0,
              tenantId: {
                uuid: 'BB8-TENANT-R2D2'
              },
              dictionaryVersionMajor: 1,
              dictionaryVersionMinor: 3,
              name: 'Procedure Type Categories',
              crossMapIndicator: false,
              status: 'PUBLISHED',
              editStatus: null,
              effectiveDate: '1970-01-01',
              endDate: null,
              columnDefinitions: [],
              rows: []
            },
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: [],
            cellReferences: []
          },
          row: {
            id: 14,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 8,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: [],
            crossmapColumns: [],
            keyCell: null
          },
          dictionaryMetadata: []
        },
        {
          id: 34,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'OB',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 4,
          rowId: 16,
          columnName: 'Abbreviation',
          rowIndex: 10,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 4,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Abbreviation',
            keyColumnIndicator: false,
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 2,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: {
              id: 2,
              version: 0,
              tenantId: {
                uuid: 'BB8-TENANT-R2D2'
              },
              dictionaryVersionMajor: 1,
              dictionaryVersionMinor: 3,
              name: 'Procedure Type Categories',
              crossMapIndicator: false,
              status: 'PUBLISHED',
              editStatus: null,
              effectiveDate: '1970-01-01',
              endDate: null,
              columnDefinitions: [],
              rows: []
            },
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: [],
            cellReferences: []
          },
          row: {
            id: 16,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 10,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: [],
            crossmapColumns: [],
            keyCell: null
          },
          dictionaryMetadata: []
        },
        {
          id: 36,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'US',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 4,
          rowId: 18,
          columnName: 'Abbreviation',
          rowIndex: 12,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 4,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Abbreviation',
            keyColumnIndicator: false,
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 2,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: {
              id: 2,
              version: 0,
              tenantId: {
                uuid: 'BB8-TENANT-R2D2'
              },
              dictionaryVersionMajor: 1,
              dictionaryVersionMinor: 3,
              name: 'Procedure Type Categories',
              crossMapIndicator: false,
              status: 'PUBLISHED',
              editStatus: null,
              effectiveDate: '1970-01-01',
              endDate: null,
              columnDefinitions: [],
              rows: []
            },
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: [],
            cellReferences: []
          },
          row: {
            id: 18,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 12,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: [],
            crossmapColumns: [],
            keyCell: null
          },
          dictionaryMetadata: []
        },
        {
          id: 35,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'PET',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 4,
          rowId: 17,
          columnName: 'Abbreviation',
          rowIndex: 11,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 4,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Abbreviation',
            keyColumnIndicator: false,
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 2,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: {
              id: 2,
              version: 0,
              tenantId: {
                uuid: 'BB8-TENANT-R2D2'
              },
              dictionaryVersionMajor: 1,
              dictionaryVersionMinor: 3,
              name: 'Procedure Type Categories',
              crossMapIndicator: false,
              status: 'PUBLISHED',
              editStatus: null,
              effectiveDate: '1970-01-01',
              endDate: null,
              columnDefinitions: [],
              rows: []
            },
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: [],
            cellReferences: []
          },
          row: {
            id: 17,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 11,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: [],
            crossmapColumns: [],
            keyCell: null
          },
          dictionaryMetadata: []
        },
        {
          id: 27,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'CT',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 4,
          rowId: 9,
          columnName: 'Abbreviation',
          rowIndex: 3,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 4,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Abbreviation',
            keyColumnIndicator: false,
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 2,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: {
              id: 2,
              version: 0,
              tenantId: {
                uuid: 'BB8-TENANT-R2D2'
              },
              dictionaryVersionMajor: 1,
              dictionaryVersionMinor: 3,
              name: 'Procedure Type Categories',
              crossMapIndicator: false,
              status: 'PUBLISHED',
              editStatus: null,
              effectiveDate: '1970-01-01',
              endDate: null,
              columnDefinitions: [],
              rows: []
            },
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: [],
            cellReferences: []
          },
          row: {
            id: 9,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 3,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: [],
            crossmapColumns: [],
            keyCell: null
          },
          dictionaryMetadata: []
        },
        {
          id: 30,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'MAMMO',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 4,
          rowId: 12,
          columnName: 'Abbreviation',
          rowIndex: 6,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 4,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Abbreviation',
            keyColumnIndicator: false,
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 2,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: {
              id: 2,
              version: 0,
              tenantId: {
                uuid: 'BB8-TENANT-R2D2'
              },
              dictionaryVersionMajor: 1,
              dictionaryVersionMinor: 3,
              name: 'Procedure Type Categories',
              crossMapIndicator: false,
              status: 'PUBLISHED',
              editStatus: null,
              effectiveDate: '1970-01-01',
              endDate: null,
              columnDefinitions: [],
              rows: []
            },
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: [],
            cellReferences: []
          },
          row: {
            id: 12,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 6,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: [],
            crossmapColumns: [],
            keyCell: null
          },
          dictionaryMetadata: []
        },
        {
          id: 33,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'NUCLEAR',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 4,
          rowId: 15,
          columnName: 'Abbreviation',
          rowIndex: 9,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 4,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Abbreviation',
            keyColumnIndicator: false,
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 2,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: {
              id: 2,
              version: 0,
              tenantId: {
                uuid: 'BB8-TENANT-R2D2'
              },
              dictionaryVersionMajor: 1,
              dictionaryVersionMinor: 3,
              name: 'Procedure Type Categories',
              crossMapIndicator: false,
              status: 'PUBLISHED',
              editStatus: null,
              effectiveDate: '1970-01-01',
              endDate: null,
              columnDefinitions: [],
              rows: []
            },
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: [],
            cellReferences: []
          },
          row: {
            id: 15,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 9,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: [],
            crossmapColumns: [],
            keyCell: null
          },
          dictionaryMetadata: []
        },
        {
          id: 31,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'MISC',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 4,
          rowId: 13,
          columnName: 'Abbreviation',
          rowIndex: 7,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 4,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Abbreviation',
            keyColumnIndicator: false,
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 2,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: {
              id: 2,
              version: 0,
              tenantId: {
                uuid: 'BB8-TENANT-R2D2'
              },
              dictionaryVersionMajor: 1,
              dictionaryVersionMinor: 3,
              name: 'Procedure Type Categories',
              crossMapIndicator: false,
              status: 'PUBLISHED',
              editStatus: null,
              effectiveDate: '1970-01-01',
              endDate: null,
              columnDefinitions: [],
              rows: []
            },
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: [],
            cellReferences: []
          },
          row: {
            id: 13,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 7,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: [],
            crossmapColumns: [],
            keyCell: null
          },
          dictionaryMetadata: []
        },
        {
          id: 28,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'IR',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 4,
          rowId: 10,
          columnName: 'Abbreviation',
          rowIndex: 4,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 4,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Abbreviation',
            keyColumnIndicator: false,
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 2,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: {
              id: 2,
              version: 0,
              tenantId: {
                uuid: 'BB8-TENANT-R2D2'
              },
              dictionaryVersionMajor: 1,
              dictionaryVersionMinor: 3,
              name: 'Procedure Type Categories',
              crossMapIndicator: false,
              status: 'PUBLISHED',
              editStatus: null,
              effectiveDate: '1970-01-01',
              endDate: null,
              columnDefinitions: [],
              rows: []
            },
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: [],
            cellReferences: []
          },
          row: {
            id: 10,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 4,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: [],
            crossmapColumns: [],
            keyCell: null
          },
          dictionaryMetadata: []
        },
        {
          id: 29,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'MR',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 4,
          rowId: 11,
          columnName: 'Abbreviation',
          rowIndex: 5,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 4,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Abbreviation',
            keyColumnIndicator: false,
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 2,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: {
              id: 2,
              version: 0,
              tenantId: {
                uuid: 'BB8-TENANT-R2D2'
              },
              dictionaryVersionMajor: 1,
              dictionaryVersionMinor: 3,
              name: 'Procedure Type Categories',
              crossMapIndicator: false,
              status: 'PUBLISHED',
              editStatus: null,
              effectiveDate: '1970-01-01',
              endDate: null,
              columnDefinitions: [],
              rows: []
            },
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: [],
            cellReferences: []
          },
          row: {
            id: 11,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 5,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: [],
            crossmapColumns: [],
            keyCell: null
          },
          dictionaryMetadata: []
        },
        {
          id: 26,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'XRAY',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 4,
          rowId: 8,
          columnName: 'Abbreviation',
          rowIndex: 2,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 4,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Abbreviation',
            keyColumnIndicator: false,
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 2,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: {
              id: 2,
              version: 0,
              tenantId: {
                uuid: 'BB8-TENANT-R2D2'
              },
              dictionaryVersionMajor: 1,
              dictionaryVersionMinor: 3,
              name: 'Procedure Type Categories',
              crossMapIndicator: false,
              status: 'PUBLISHED',
              editStatus: null,
              effectiveDate: '1970-01-01',
              endDate: null,
              columnDefinitions: [],
              rows: []
            },
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: [],
            cellReferences: []
          },
          row: {
            id: 8,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 2,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: [],
            crossmapColumns: [],
            keyCell: null
          },
          dictionaryMetadata: []
        }
      ],
      cellReferences: []
    },
    {
      id: 3,
      tenantId: {
        uuid: 'BB8-TENANT-R2D2'
      },
      name: 'Procedure Type Categories',
      keyColumnIndicator: true,
      status: 'PUBLISHED',
      editStatus: null,
      columnIndex: 1,
      dictionaryId: 2,
      dictionaryName: 'Procedure Type Categories',
      dictionary: {
        id: 2,
        version: 0,
        tenantId: {
          uuid: 'BB8-TENANT-R2D2'
        },
        dictionaryVersionMajor: 1,
        dictionaryVersionMinor: 3,
        name: 'Procedure Type Categories',
        crossMapIndicator: false,
        status: 'PUBLISHED',
        editStatus: null,
        effectiveDate: '1970-01-01',
        endDate: null,
        columnDefinitions: [],
        rows: []
      },
      effectiveDate: '1970-01-01',
      endDate: null,
      cells: [
        {
          id: 23,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'Positron emission tomography',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 3,
          rowId: 17,
          columnName: 'Procedure Type Categories',
          rowIndex: 11,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 3,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Procedure Type Categories',
            keyColumnIndicator: true,
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 1,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: {
              id: 2,
              version: 0,
              tenantId: {
                uuid: 'BB8-TENANT-R2D2'
              },
              dictionaryVersionMajor: 1,
              dictionaryVersionMinor: 3,
              name: 'Procedure Type Categories',
              crossMapIndicator: false,
              status: 'PUBLISHED',
              editStatus: null,
              effectiveDate: '1970-01-01',
              endDate: null,
              columnDefinitions: [],
              rows: []
            },
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: [],
            cellReferences: []
          },
          row: {
            id: 17,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 11,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: [],
            crossmapColumns: [],
            keyCell: null
          },
          dictionaryMetadata: []
        },
        {
          id: 21,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'Nuclear Medicine',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 3,
          rowId: 15,
          columnName: 'Procedure Type Categories',
          rowIndex: 9,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 3,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Procedure Type Categories',
            keyColumnIndicator: true,
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 1,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: {
              id: 2,
              version: 0,
              tenantId: {
                uuid: 'BB8-TENANT-R2D2'
              },
              dictionaryVersionMajor: 1,
              dictionaryVersionMinor: 3,
              name: 'Procedure Type Categories',
              crossMapIndicator: false,
              status: 'PUBLISHED',
              editStatus: null,
              effectiveDate: '1970-01-01',
              endDate: null,
              columnDefinitions: [],
              rows: []
            },
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: [],
            cellReferences: []
          },
          row: {
            id: 15,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 9,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: [],
            crossmapColumns: [],
            keyCell: null
          },
          dictionaryMetadata: []
        },
        {
          id: 20,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'Not Applicable',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 3,
          rowId: 14,
          columnName: 'Procedure Type Categories',
          rowIndex: 8,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 3,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Procedure Type Categories',
            keyColumnIndicator: true,
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 1,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: {
              id: 2,
              version: 0,
              tenantId: {
                uuid: 'BB8-TENANT-R2D2'
              },
              dictionaryVersionMajor: 1,
              dictionaryVersionMinor: 3,
              name: 'Procedure Type Categories',
              crossMapIndicator: false,
              status: 'PUBLISHED',
              editStatus: null,
              effectiveDate: '1970-01-01',
              endDate: null,
              columnDefinitions: [],
              rows: []
            },
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: [],
            cellReferences: []
          },
          row: {
            id: 14,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 8,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: [],
            crossmapColumns: [],
            keyCell: null
          },
          dictionaryMetadata: []
        },
        {
          id: 16,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'Interventional Radiology',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 3,
          rowId: 10,
          columnName: 'Procedure Type Categories',
          rowIndex: 4,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 3,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Procedure Type Categories',
            keyColumnIndicator: true,
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 1,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: {
              id: 2,
              version: 0,
              tenantId: {
                uuid: 'BB8-TENANT-R2D2'
              },
              dictionaryVersionMajor: 1,
              dictionaryVersionMinor: 3,
              name: 'Procedure Type Categories',
              crossMapIndicator: false,
              status: 'PUBLISHED',
              editStatus: null,
              effectiveDate: '1970-01-01',
              endDate: null,
              columnDefinitions: [],
              rows: []
            },
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: [],
            cellReferences: []
          },
          row: {
            id: 10,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 4,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: [],
            crossmapColumns: [],
            keyCell: null
          },
          dictionaryMetadata: []
        },
        {
          id: 14,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'Computed Radiography',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 3,
          rowId: 8,
          columnName: 'Procedure Type Categories',
          rowIndex: 2,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 3,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Procedure Type Categories',
            keyColumnIndicator: true,
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 1,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: {
              id: 2,
              version: 0,
              tenantId: {
                uuid: 'BB8-TENANT-R2D2'
              },
              dictionaryVersionMajor: 1,
              dictionaryVersionMinor: 3,
              name: 'Procedure Type Categories',
              crossMapIndicator: false,
              status: 'PUBLISHED',
              editStatus: null,
              effectiveDate: '1970-01-01',
              endDate: null,
              columnDefinitions: [],
              rows: []
            },
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: [],
            cellReferences: []
          },
          row: {
            id: 8,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 2,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: [],
            crossmapColumns: [],
            keyCell: null
          },
          dictionaryMetadata: []
        },
        {
          id: 18,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'Mammography',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 3,
          rowId: 12,
          columnName: 'Procedure Type Categories',
          rowIndex: 6,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 3,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Procedure Type Categories',
            keyColumnIndicator: true,
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 1,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: {
              id: 2,
              version: 0,
              tenantId: {
                uuid: 'BB8-TENANT-R2D2'
              },
              dictionaryVersionMajor: 1,
              dictionaryVersionMinor: 3,
              name: 'Procedure Type Categories',
              crossMapIndicator: false,
              status: 'PUBLISHED',
              editStatus: null,
              effectiveDate: '1970-01-01',
              endDate: null,
              columnDefinitions: [],
              rows: []
            },
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: [],
            cellReferences: []
          },
          row: {
            id: 12,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 6,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: [],
            crossmapColumns: [],
            keyCell: null
          },
          dictionaryMetadata: []
        },
        {
          id: 13,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'Administrative',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 3,
          rowId: 7,
          columnName: 'Procedure Type Categories',
          rowIndex: 1,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 3,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Procedure Type Categories',
            keyColumnIndicator: true,
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 1,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: {
              id: 2,
              version: 0,
              tenantId: {
                uuid: 'BB8-TENANT-R2D2'
              },
              dictionaryVersionMajor: 1,
              dictionaryVersionMinor: 3,
              name: 'Procedure Type Categories',
              crossMapIndicator: false,
              status: 'PUBLISHED',
              editStatus: null,
              effectiveDate: '1970-01-01',
              endDate: null,
              columnDefinitions: [],
              rows: []
            },
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: [],
            cellReferences: []
          },
          row: {
            id: 7,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 1,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: [],
            crossmapColumns: [],
            keyCell: null
          },
          dictionaryMetadata: []
        },
        {
          id: 17,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'Magnetic Resonance',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 3,
          rowId: 11,
          columnName: 'Procedure Type Categories',
          rowIndex: 5,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 3,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Procedure Type Categories',
            keyColumnIndicator: true,
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 1,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: {
              id: 2,
              version: 0,
              tenantId: {
                uuid: 'BB8-TENANT-R2D2'
              },
              dictionaryVersionMajor: 1,
              dictionaryVersionMinor: 3,
              name: 'Procedure Type Categories',
              crossMapIndicator: false,
              status: 'PUBLISHED',
              editStatus: null,
              effectiveDate: '1970-01-01',
              endDate: null,
              columnDefinitions: [],
              rows: []
            },
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: [],
            cellReferences: []
          },
          row: {
            id: 11,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 5,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: [],
            crossmapColumns: [],
            keyCell: null
          },
          dictionaryMetadata: []
        },
        {
          id: 15,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'Computed Tomography',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 3,
          rowId: 9,
          columnName: 'Procedure Type Categories',
          rowIndex: 3,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 3,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Procedure Type Categories',
            keyColumnIndicator: true,
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 1,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: {
              id: 2,
              version: 0,
              tenantId: {
                uuid: 'BB8-TENANT-R2D2'
              },
              dictionaryVersionMajor: 1,
              dictionaryVersionMinor: 3,
              name: 'Procedure Type Categories',
              crossMapIndicator: false,
              status: 'PUBLISHED',
              editStatus: null,
              effectiveDate: '1970-01-01',
              endDate: null,
              columnDefinitions: [],
              rows: []
            },
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: [],
            cellReferences: []
          },
          row: {
            id: 9,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 3,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: [],
            crossmapColumns: [],
            keyCell: null
          },
          dictionaryMetadata: []
        },
        {
          id: 24,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'Ultrasound',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 3,
          rowId: 18,
          columnName: 'Procedure Type Categories',
          rowIndex: 12,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 3,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Procedure Type Categories',
            keyColumnIndicator: true,
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 1,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: {
              id: 2,
              version: 0,
              tenantId: {
                uuid: 'BB8-TENANT-R2D2'
              },
              dictionaryVersionMajor: 1,
              dictionaryVersionMinor: 3,
              name: 'Procedure Type Categories',
              crossMapIndicator: false,
              status: 'PUBLISHED',
              editStatus: null,
              effectiveDate: '1970-01-01',
              endDate: null,
              columnDefinitions: [],
              rows: []
            },
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: [],
            cellReferences: []
          },
          row: {
            id: 18,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 12,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: [],
            crossmapColumns: [],
            keyCell: null
          },
          dictionaryMetadata: []
        },
        {
          id: 19,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'Miscellaneous',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 3,
          rowId: 13,
          columnName: 'Procedure Type Categories',
          rowIndex: 7,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 3,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Procedure Type Categories',
            keyColumnIndicator: true,
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 1,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: {
              id: 2,
              version: 0,
              tenantId: {
                uuid: 'BB8-TENANT-R2D2'
              },
              dictionaryVersionMajor: 1,
              dictionaryVersionMinor: 3,
              name: 'Procedure Type Categories',
              crossMapIndicator: false,
              status: 'PUBLISHED',
              editStatus: null,
              effectiveDate: '1970-01-01',
              endDate: null,
              columnDefinitions: [],
              rows: []
            },
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: [],
            cellReferences: []
          },
          row: {
            id: 13,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 7,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: [],
            crossmapColumns: [],
            keyCell: null
          },
          dictionaryMetadata: []
        },
        {
          id: 22,
          tenantId: {
            uuid: 'BB8-TENANT-R2D2'
          },
          value: 'Obstetrics',
          codeType: null,
          status: 'PUBLISHED',
          editStatus: null,
          columnDefinitionId: 3,
          rowId: 16,
          columnName: 'Procedure Type Categories',
          rowIndex: 10,
          effectiveDate: '1970-01-01',
          endDate: null,
          columnDefinition: {
            id: 3,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            name: 'Procedure Type Categories',
            keyColumnIndicator: true,
            status: 'PUBLISHED',
            editStatus: null,
            columnIndex: 1,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: {
              id: 2,
              version: 0,
              tenantId: {
                uuid: 'BB8-TENANT-R2D2'
              },
              dictionaryVersionMajor: 1,
              dictionaryVersionMinor: 3,
              name: 'Procedure Type Categories',
              crossMapIndicator: false,
              status: 'PUBLISHED',
              editStatus: null,
              effectiveDate: '1970-01-01',
              endDate: null,
              columnDefinitions: [],
              rows: []
            },
            effectiveDate: '1970-01-01',
            endDate: null,
            cells: [],
            cellReferences: []
          },
          row: {
            id: 16,
            tenantId: {
              uuid: 'BB8-TENANT-R2D2'
            },
            rowIndex: 10,
            status: 'PUBLISHED',
            editStatus: null,
            effectiveDate: '1970-01-01',
            endDate: null,
            dictionaryId: 2,
            dictionaryName: 'Procedure Type Categories',
            dictionary: null,
            cells: [],
            crossmapColumns: [],
            keyCell: null
          },
          dictionaryMetadata: []
        }
      ],
      cellReferences: []
    }
  ],
  rows: []
};
