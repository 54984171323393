/// <reference path="../types.d.ts" />
import { Login } from '../actions/action-types';
import InitialState from '../reducers/initial-state';
import {
  syncActions,
  logoutApp,
  handleContext,
  toggleEmailConfirmationModal,
  authenticate,
  loginViaSSO,
  postAuthNavigate,
  getGlobalConfig
} from '../actions/login.action';
import { inProgress } from '../actions/common';
import { selectContext } from '../actions/contexts';
import { IS_PRODUCTION } from '../core/environment';
import { cmxReducerFactory } from './reducer-utils';
import { formErrors } from '../stubs/form-errors';
const reducer = cmxReducerFactory(InitialState.login)
  .case(inProgress, state => ({
    ...state,
    submitting: true,
    status: Login.IN_PROGRESS,
    error: false
  }))
  .case(syncActions.loginInitial, state => ({
    ...state,
    status: Login.INITIAL,
    error: false,
    formErrors: formErrors.noErrors
  }))
  .case(syncActions.loginFailed, (state, message) => ({
    ...state,
    submitting: false,
    status: Login.FAILED,
    error: true,
    message: JSON.stringify(message) === '{}' ? undefined : message
  }))
  .case(logoutApp.async.done, state => ({
    ...InitialState.login
  }))
  .case(syncActions.displayContexts, (state, contexts) => {
    return {
      ...state,
      submitting: false,
      contexts
    };
  })
  .case(selectContext.async.done, (state, { result: context }) => {
    return { ...state, context };
  })
  .case(syncActions.loginSuccess, (state, token) => {
    if (!IS_PRODUCTION) {
      // @ts-ignore
      (window as any).sessionStorage.setItem('authToken', token.token);
    }
    return {
      ...state,
      submitting: false,
      status: Login.SUCCESS,
      error: false,
      auth: token
    };
  })

  .case(syncActions.changePassword, state => {
    return {
      ...state,
      forcePasswordChange: true
    };
  })

  .case(syncActions.changePasswordInitial, state => {
    return {
      ...state,
      error: false,
      submitting: true,
      formErrors: formErrors.noErrors
    };
  })

  .case(syncActions.changePasswordSuccess, state => {
    return {
      ...state,
      error: false,
      submitting: false,
      forcePasswordChange: false
    };
  })
  .case(syncActions.changePasswordFailed, (state, errors) => {
    return {
      ...state,
      error: true,
      submitting: false,
      forcePasswordChange: true,
      message: undefined,
      formErrors: errors ?? formErrors.noErrors
    };
  })
  .case(toggleEmailConfirmationModal, (state, isOpen) => {
    return {
      ...state,
      emailConfirmationModalOpen: isOpen
    };
  })
  .case(authenticate.async.started, (state, loginData: CMx.LoginData) => {
    return {
      ...state,
      username: loginData.username
    };
  })
  .case(handleContext.async.done, state => {
    return {
      ...state,
      forcePasswordChange: undefined,
      loading: false
    };
  })
  .case(loginViaSSO.async.started, state => {
    return {
      ...state,
      loading: true
    };
  })
  .case(postAuthNavigate.async.done, state => {
    return {
      ...state,
      loading: false
    };
  })
  .case(getGlobalConfig.async.done, (state, { result }) => {
    return {
      ...state,
      config: result
    };
  });

export default reducer;
