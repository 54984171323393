// when we have a /new endpoint, this code will no longer
// be relevant.
const STUB_ORG: CMxAPI.Organization = {
  id: 0,
  version: 0,
  createdDate: 0,
  updateDate: null,
  organizationName: '',
  organizationCode: '',
  customerName: '',
  displayName: null,
  shortDisplayName: null,
  tenantId: '',
  organizationType: '',
  address1: null,
  address2: null,
  city: '',
  state: '',
  zipCode: null,
  phone: null,
  contactName: null,
  parentTenantId: '',
  parentLevel: 0,
  requireDedicatedSiteHeader: null,
  dedicatedSiteHeader: null,
  children: [],
  timeZoneFullName: '',
  awsCognitoIndicator: false,
  ssoIndicator: false,
  active: false
};

// TOREFACTOR
// this will no longer be necessary when we have a bootstrap call.
export const CODAMETRIX = {
  children: [],
  id: 1,
  version: 0,
  createdDate: 1562168858387,
  updateDate: null,
  organizationName: 'CodaMetrix',
  organizationCode: 'CMX',
  displayName: 'CodaMetrix',
  shortDisplayName: null,
  customerName: 'CodaMetrix',
  tenantId: 'BB4D9AA3-3047-4542-8FB1-8C9BD6E4D75C',
  organizationType: 'BUSINESS',
  address1: null,
  address2: null,
  city: 'Boston',
  state: 'MA',
  zipCode: null,
  phone: null,
  contactName: null,
  parentTenantId: null,
  parentLevel: 0,
  requireDedicatedSiteHeader: null,
  dedicatedSiteHeader: null,
  timeZoneFullName: 'America/New_York',
  awsCognitoIndicator: false,
  ssoIndicator: false,
  active: true
};

export const STUB_ORG_RELATIONSHIP: CMxAPI.OrganizationRelationship = {
  id: null,
  version: 0,
  createdDate: null,
  updateDate: null,
  effectiveDate: null,
  endDate: null,
  serviceLineId: null,
  fromOrganizationId: null,
  toOrganizationId: null,
  organizationRelationshipType: ''
};

export default STUB_ORG;
