const mlFunction: CMxCommonApp.FieldDefinition = {
  label: 'ML Function',
  key: 'ml_function',
  name: 'ml_function',
  required: false,
  type: 'select',
  options: [],
  labelField: 'label',
  valueField: 'value',
  helpText: 'ml_function'
};

const mlAlgorithm: CMxCommonApp.FieldDefinition = {
  label: 'ML Algorithm',
  key: 'algorithm',
  name: 'algorithm',
  required: false,
  type: 'select',
  options: [],
  labelField: 'label',
  valueField: 'value',
  helpText: 'algorithm'
};

const mlVersion: CMxCommonApp.FieldDefinition = {
  label: 'ML Version',
  key: 'ml_version',
  name: 'ml_version',
  required: false,
  type: 'select',
  options: [],
  labelField: 'label',
  valueField: 'value',
  helpText: 'ml_version'
};

const automationFields: CMxCommonApp.FieldDefinition[] = [
  mlFunction,
  mlAlgorithm,
  mlVersion
];

export default automationFields;
