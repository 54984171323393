import theme from '../../theme';

export const styles = () => ({
  legend: {
    marginLeft: theme.space[12],
    padding: `0 ${theme.space[8]}`
  },
  fieldset: {
    border: `${theme.borders['1px']} ${theme.colors.foundation[200]}`,
    borderRadius: theme.space[4],
    marginBottom: theme.space[16]
  },
  fieldsets: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.radii[3],
    border: `${theme.borders['1px']} ${theme.colors.foundation[200]}`,
    height: '100%',
    overflowY: 'auto',
    marginTop: theme.space[20],
    padding: `${theme.space[15]} ${theme.space[15]} 0 ${theme.space[20]}`
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  accountinformation: {
    width: '33%',
    flexDirection: 'column',
    rowGap: theme.space[16],
    margin: theme.space[20]
  },
  passwordoptions: {
    flexDirection: 'row',
    columnGap: theme.space[4],
    margin: theme.space[20]
  },
  roles: {
    margin: theme.space[20]
  },
  checkBox: {
    columnGap: theme.space[12]
  },
  checkBoxWidth: {
    width: '15%'
  },
  text: {
    ...theme.textStyles['h4'],
    color: theme.colors.text['highEmphasis']
  },
  span: {
    color: `${theme.colors.chips.green[500]}`,
    paddingLeft: theme.space[4]
  }
});
