/// <reference path="../types.d.ts" />
import React from 'react';
// @ts-ignore
import GitInfo from 'react-git-info/macro';
export const gitInfo = GitInfo();

export const GitTag = () => {
  return (
    <div style={{ display: 'none' }}>
      <div>Branch: {gitInfo.branch}</div>
      <div>Tag: {gitInfo.commit.shortHash}</div>
    </div>
  );
};
