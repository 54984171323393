/** converts date to yyyy-mm-dd format */
export const convertToDateFormat = (_date: Date) => {
  const date = new Date(_date),
    mnth = ('0' + (date.getMonth() + 1)).slice(-2),
    day = ('0' + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join('-');
};

/** returns the years range that is shown in the year picker*/
export const getYearRange = (date: Date) => {
  const dateYear = date.getFullYear();
  let rangeStart = dateYear;
  let count = 0;
  while (!Number.isInteger((dateYear - count) / 12)) {
    count++;
  }
  if (Number.isInteger(dateYear / 12)) {
    count = 12;
  }
  rangeStart = dateYear - count + 1;
  return `${rangeStart} - ${rangeStart + 11}`;
};

export const FORMATS = {
  DATE: 'yyyy-mm-dd'
};
