import { CMxComponents } from '@codametrix/ui-components/src/types';

type OrgTypes = {
  [key: number]: string;
};

export const ORG_TIERS: OrgTypes = {
  0: 'Business',
  1: `Health Systems`,
  2: `Hospitals`
};

export const OrgNames: OrgTypes = {
  0: 'BUSINESS',
  1: 'HEALTH_SYSTEM',
  2: 'HOSPITAL'
};

interface OrgDef {
  [key: string]: any;
}

const buildServiceMaps = (relationships: CMxAPI.OrgServiceLine[]) => {
  const map = new Map<string, Set<number>>();
  const now = Date.now();

  relationships.forEach((relationship: CMxAPI.OrgServiceLine) => {
    const ids = relationship.organizationRelationships
      .filter(orgRel => {
        return (
          (orgRel.endDate === null || orgRel.endDate > now) &&
          orgRel.id !== null
        );
      })
      .map(orgRel => orgRel.toOrganizationId);
    const set = new Set<number>(ids as number[]);
    map.set(relationship.name, set);
  });

  return map;
};

const StandardSystemColumns: CMxComponents.ColumnDefintion[] = [
  {
    key: 'displayName',
    displayName: 'Name'
  }
];

const POColumns = [
  ...StandardSystemColumns,
  {
    key: 'serviceLine',
    displayName: 'Service Lines',
    transform: (org: any) => {
      // I've overloaded the organization here for convenience purposes
      //@ts-ignore
      const parent = org.parent as CMxAPI.Organization;
      const activeServiceLines: string[] = [];
      if (parent) {
        const serviceMaps = buildServiceMaps(parent.serviceLines ?? []);
        const keys = [...serviceMaps.keys()];
        keys.forEach(key => {
          const set = serviceMaps.get(key);
          if (set?.has(org.id)) {
            activeServiceLines.push(key);
          }
        });
      }

      return activeServiceLines.join(', ');
    }
  }
];

export const OrgDefs: OrgDef = {
  HOSPITAL: {
    title: 'Hospitals',
    colDefs: StandardSystemColumns
  },
  HEALTH_SYSTEM: {
    title: 'Health Systems',
    colDefs: StandardSystemColumns
  },
  BILLING_ORGANIZATION: {
    title: 'Billing Organizations',
    colDefs: StandardSystemColumns
  },
  PHYSICIAN_ORGANIZATION: {
    title: 'Physician Organization',
    colDefs: POColumns
  }
};
