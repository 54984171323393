import { cmxDateTime, HttpMethods, objectUtils } from '@codametrix/ui-common';
import actionCreatorFactory from 'typescript-fsa';
import asyncFactory from 'typescript-fsa-redux-thunk';
import { api } from '../core/net';
import {
  caseConfigFieldsBySection,
  sectionDictionaries
} from '../reducers/state/case-config/case-config';
import { CaseConfig } from './action-types';
import { showFeedback } from './ui';

const actionCreator = actionCreatorFactory();
const createAsync = asyncFactory<CMxAPI.CaseConfiguration>(actionCreator);

const selectConfigSection = actionCreator<string>(
  CaseConfig.SELECT_CASE_CONFIG_SECTION
);

const reduxSaveCaseConfigs = actionCreator<CMxAPI.CaseConfiguration[]>(
  CaseConfig.REDUX_SAVE_CASE_CONFIGS
);

interface loadCaseConfigPayload {
  activeTenantId: string;
  section: string;
}

const loadCaseConfigOptions = createAsync<
  loadCaseConfigPayload,
  { [key: string]: { label: string; value: string }[] },
  CMxCommonApp.SubmitError
>(CaseConfig.LOAD_CASE_CONFIG_OPTIONS, async (payload, dispatch: any) => {
  const { section, activeTenantId } = payload;
  const dictionaryNames = sectionDictionaries[section];

  let configOptions: { [key: string]: any } = {};
  if (dictionaryNames) {
    for (const [fieldKey, dictionaryInfo] of Object.entries(dictionaryNames)) {
      const { dictionaryName, columnName } = dictionaryInfo as any;

      const searchParams = new URLSearchParams({
        tenantId: activeTenantId,
        derivedIndicator: 'true',
        effectiveDate: cmxDateTime.format(new Date(), cmxDateTime.FORMATS.DATE),
        dictionaryName: dictionaryName as string,
        columnName: columnName as string
      });

      const config = await api<CMxAPI.RowData>({
        endpoint: `/dictionary/row/v2?${searchParams.toString()}`,
        init: {
          method: HttpMethods.GET
        }
      });

      const fieldOptions = config.rowValues.map(row => {
        return {
          label: row[columnName],
          value: row[columnName]
        };
      });

      configOptions[fieldKey] = objectUtils.uniqWith(
        fieldOptions,
        (a: any, b: any) => a.value === b.value
      );
    }
  }

  return configOptions;
});
const listCaseConfigurations = createAsync<
  CMx.ListCaseConfigPayload,
  any,
  CMxCommonApp.SubmitError
>(CaseConfig.LIST_CASE_CONFIGURATIONS, async (payload, dispatch: any) => {
  await dispatch(loadCaseConfigOptions({ ...payload }));

  const searchParams = new URLSearchParams({
    tenantId: payload.tenantId,
    serviceLineName: payload.serviceLine.name
      ? payload.serviceLine.name
      : 'Radiology'
  });

  const configs = await api<CMxAPI.CaseConfiguration[]>({
    endpoint: `/caseconfig?${searchParams.toString()}`,
    init: {
      method: HttpMethods.GET
    }
  });

  dispatch(reduxSaveCaseConfigs(configs));

  let dataObject: {
    [key: string]: string | number;
  } = {};

  const fields = caseConfigFieldsBySection[payload.section];

  fields.forEach(field => {
    const matchingConfig = configs.find(
      config => config.configurationKey === field.key
    );

    if (matchingConfig) {
      dataObject[matchingConfig.configurationKey] =
        matchingConfig.configurationValues[0].configurationValue || '';
    }
  });

  return dataObject;
});

const saveCaseConfiguration = createAsync<
  CMx.SaveCaseConfigPayload,
  CMxAPI.CaseConfiguration,
  CMxCommonApp.SubmitError
>(CaseConfig.SAVE_CASE_CONFIGURATION, async (payload, dispatch: any) => {
  const searchParams = new URLSearchParams({
    tenantId: payload.tenantId,
    serviceLineName: payload.serviceLine.name
      ? payload.serviceLine.name
      : 'Radiology'
  });

  const config = await api<CMxAPI.CaseConfiguration>({
    endpoint: `/caseconfig?${searchParams.toString()}`,
    init: {
      method: HttpMethods.PUT
    },
    body: payload.caseConfigs
  });

  dispatch(
    showFeedback({
      id: Date.now(),
      message: `Case Config Saved.`,
      dismissable: true,
      className: 'feedback-working'
    })
  );

  return config;
});

const syncActions = { selectConfigSection, reduxSaveCaseConfigs };
export {
  listCaseConfigurations,
  saveCaseConfiguration,
  syncActions,
  loadCaseConfigOptions
};
