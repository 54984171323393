import React from 'react';
import { useSelector } from 'react-redux';
import { CMXIcon, Conditional } from '@codametrix/shared-views';
import { createPortal } from 'react-dom';
import { LoadingSlugs } from '@codametrix/ui-components';
import './provider-details.scss';
import { Avatar } from '@chakra-ui/react';
import { objectUtils } from '@codametrix/ui-common';
import * as userUtils from '../../core/utils/user-utils';

const fallbackElement = document.createElement('div');

const ProviderDetails = () => {
  const {
    isOpen,
    providerInfo,
    providerInfoInput,
    isSingleTenantData,
    servicesLoading
  } = useSelector((state: CMx.ApplicationState) => {
    return state.providerDetails;
  });

  const colorCodes = [
    '#346EA3',
    '#F6844E',
    '#5BA37A',
    '#F1C54B',
    '#688D99',
    '#F24646',
    '#867775',
    '#E15A6A',
    '#BB529E',
    '#B97350'
  ];

  const employerStatus = providerInfo?.specialties?.[0]?.report_grouper_six;
  const specialtiesByLoc = objectUtils.groupBy(
    providerInfo?.specialties ?? [],
    'location'
  );
  const specialtiesView = Object.entries(specialtiesByLoc)?.map(
    ([location, locationGroup]) => {
      const specialtiesByRole = objectUtils.groupBy(
        locationGroup ?? [],
        'name'
      );
      return Object.entries(specialtiesByRole)?.map(([name, specialties]) => {
        const isRoleBillable = (specialties as Array<any>)?.some(
          (specialty: any) => specialty?.billable
        );
        return (
          <div key={location} className="vertical-info specialty-row">
            {location && location !== 'null' && (
              <div
                className="provider-location"
                data-testid="provider-location">
                {isSingleTenantData ? name : location}
              </div>
            )}
            <div className="flex-box">
              {!isSingleTenantData && (
                <div
                  className="provider-role vertical-info "
                  data-testid="provider-role">
                  <div className="info-header">ROLE</div>
                  <div className="info-cell">{name}</div>
                </div>
              )}

              <div className="provider-specialties vertical-info">
                <div className="info-header">SPECIALTY</div>
                <div className="info-cell">
                  {(specialties as Array<any>)?.map((specialty: any) => {
                    return (
                      specialty?.specialty && (
                        <div
                          className="specialty-name chip label"
                          data-testid="specialty-name">
                          {specialty?.specialty}
                        </div>
                      )
                    );
                  })}
                </div>
              </div>
              <div className="provider-specialties vertical-info">
                <div className="info-header">SPECIALTY STATUS</div>
                <div className="info-cell sepcialty_status">
                  {(specialties as Array<any>)?.map(specialty => {
                    return (
                      specialty?.specialty_flag && (
                        <div className="specialty-flag">
                          {specialty?.specialty_flag}
                        </div>
                      )
                    );
                  })}
                </div>
              </div>
              <div className="vertical-info billable">
                <div className="info-header">BILLABLE?</div>
                <div
                  className="info-cell billable_flag"
                  data-testid="billable-flag">
                  <Conditional on={isRoleBillable}>
                    <CMXIcon
                      svgTitleText="Billable"
                      svgIconName="BiomeCheckCircle"
                      svgIconClass="billing-icon"
                      svgIconSize="16"
                    />
                  </Conditional>
                  <Conditional on={!isRoleBillable}>
                    <CMXIcon
                      svgTitleText="Not billable"
                      svgIconName="BiomeNoCircle"
                      svgIconClass="no-billing-icon"
                      svgIconSize="16"
                    />
                  </Conditional>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
  );

  return (
    <Conditional on={isOpen}>
      {servicesLoading ? (
        <>
          {createPortal(
            <>
              <LoadingSlugs width="25" key="loading-slugs" numberItems={1} />
              <LoadingSlugs width="25" key="loading-slugs" numberItems={1} />
              <LoadingSlugs width="80" key="loading-slugs" numberItems={1} />
            </>,
            document.getElementById('cmx-drawer-header') || fallbackElement
          )}

          {createPortal(
            <>
              <LoadingSlugs key="loading-slugs" numberItems={3} />
              <br />
              <LoadingSlugs width="60" key="loading-slugs" numberItems={5} />
              <br />
              <LoadingSlugs width="60" key="loading-slugs" numberItems={5} />
              <br />
              <LoadingSlugs width="60" key="loading-slugs" numberItems={5} />
            </>,
            document.getElementById('cmx-drawer-body') || fallbackElement
          )}
        </>
      ) : (
        <>
          <Conditional on={!!document.getElementById('cmx-drawer-header')}>
            <>
              {createPortal(
                <div className="provider-header-section provider-details">
                  {/* @ts-ignore */}
                  <Avatar
                    name={userUtils.getAvatarInitialsByFullName(
                      providerInfo?.provider_name ??
                        providerInfoInput?.providerName ??
                        ''
                    )}
                    bgColor={
                      colorCodes[Math.floor(Math.random() * colorCodes.length)]
                    }
                  />
                  <div>
                    <div className="provider-name" data-testid="provider-name">
                      {providerInfo?.provider_name ??
                        providerInfoInput?.providerName}{' '}
                      {providerInfo?.prov_cred}
                    </div>
                    <div className="employee-info">
                      <div className="vertical-info">
                        <span className="info-header">EHR ID</span>
                        <span className="info-cell ehr-id" data-testid="ehr-id">
                          {providerInfo?.provider_id ??
                            providerInfoInput?.providerNumber}
                        </span>
                      </div>
                      <div className="vertical-info ">
                        <span className="info-header">EMPLOYER</span>
                        <Conditional every={[!!employerStatus]}>
                          <span
                            className={`info-cell employer-status chip label ${
                              employerStatus?.toUpperCase() ===
                              'EXTERNAL PROVIDER'
                                ? 'external-provider'
                                : ''
                            }`}>
                            {employerStatus}
                          </span>
                        </Conditional>
                      </div>
                      <Conditional on={isSingleTenantData}>
                        <div className="vertical-info">
                          <span className="info-header">FACILITY</span>
                          <span
                            className="info-cell facility"
                            data-testid="facility">
                            {providerInfoInput?.tenantCode}
                          </span>
                        </div>
                      </Conditional>
                    </div>
                  </div>
                </div>,
                document.getElementById('cmx-drawer-header') || fallbackElement
              )}
            </>
          </Conditional>
          <Conditional on={!!document.getElementById('cmx-drawer-body')}>
            <>
              {createPortal(
                <div className="provider-body-section provider-details">
                  {/* @ts-ignore */}
                  <Conditional on={!!!providerInfo}>
                    <span className="empty-message" data-testid="empty-message">
                      No roles available for this provider.
                    </span>
                  </Conditional>
                  <Conditional on={!!providerInfo}>
                    <>{specialtiesView}</>
                  </Conditional>
                </div>,

                document.getElementById('cmx-drawer-body') || fallbackElement
              )}
            </>
          </Conditional>
        </>
      )}
    </Conditional>
  );
};
ProviderDetails.displayName = 'ProviderDetails';

export { ProviderDetails };
