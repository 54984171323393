import actionCreatorFactory from 'typescript-fsa';
import asyncFactory from 'typescript-fsa-redux-thunk';
import { ForgotPassword } from './action-types';
import { HttpMethods } from '@codametrix/ui-common';
import { ANALYTICS_ACTION_METADATA } from './action-constants';

/** factories for actions */
const actionCreator = actionCreatorFactory();
const createAsync = asyncFactory<CMx.ForgotPasswordState>(actionCreator);

const initializeForgotPassword = actionCreator<void>(ForgotPassword.INIT);

const reduxSavePasswordDto = actionCreator<CMxAPI.PasswordReset>(
  ForgotPassword.SAVE_PASSWORD_DTO
);

const _submitNewPassword = actionCreator<CMxAPI.PasswordReset>(
  ForgotPassword._SUBMIT_NEW_PASSWORD,
  ANALYTICS_ACTION_METADATA
);
const submitForgotPassword = actionCreator<string>(
  ForgotPassword.SUBMIT_FORGOT_PASSWORD,
  ANALYTICS_ACTION_METADATA
);
const handleForgotPassword = createAsync<
  string,
  void,
  CMxCommonApp.SubmitError
>(
  ForgotPassword.FORGOT_PASSWORD,
  async (username, dispatch): Promise<void> => {
    dispatch(submitForgotPassword(username));
    const url = encodeURIComponent(username);
    await fetch(`/users/resetPassword/v1?username=${url}`, {
      method: HttpMethods.POST,
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }
);

const submitNewPassword = createAsync<
  CMxAPI.PasswordReset,
  CMxAPI.PasswordReset,
  CMxCommonApp.FormErrors
>(
  ForgotPassword.SUBMIT_NEW_PASSWORD,
  async (passwordReset, dispatch): Promise<CMxAPI.PasswordReset> => {
    dispatch(_submitNewPassword(passwordReset));
    const res = await await fetch(`/users/savePassword/v1`, {
      method: HttpMethods.POST,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(passwordReset)
    });
    const { status } = res;
    if (status !== 200) {
      throw await res.json();
    }
    return await res.json();
  }
);

export {
  handleForgotPassword,
  submitNewPassword,
  reduxSavePasswordDto,
  initializeForgotPassword
};
