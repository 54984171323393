import React, { useCallback, useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  VStack,
  Text,
  Grid,
  HStack,
  Divider,
  GridItem,
  Flex,
  Spacer,
  PopoverTrigger,
  Popover,
  Tooltip
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { setBackToWorklistPath } from '../../actions/ui';
import theme from '../../theme';
import { ReactComponent as CMXIcon } from '../../assets/images/cmxlogo.svg';
import './primary-global-navigation.scss';
import { styles } from './primary-global-navigation.styles';
import { navComponents } from './nav-icons';
import { OrganizationServiceline } from '../org-serviceline/org-serviceline';
import { ReactComponent as ChevronRightIcon } from '../../assets/images/chevron-right.svg';
import { UserProfile } from '../user-profile/user-profile';
import DashboardSelection from '../dashboard-selection/dashboard-selection-component';
import { SubNav } from './sub-nav';

export interface PrimaryGlobalNavigationProps {
  onClick?: (e?: React.MouseEvent<HTMLElement>) => void;
  className?: string;
  disabled?: boolean;
  name?: string;
  profile_src?: string;
  tabColor?: string;
  context: CMx.Context;
  actionGroups: CMxAPI.NavActionGroup[];
  handleOrganizationChoice: (orgChoice: CMx.OrganizationFormChoice) => void;
  handleServiceLineChoice: (
    serviceLineChoice: CMx.ServiceLine,
    context: CMx.Context,
    actionGroups: CMxAPI.NavActionGroup[]
  ) => void;
  onSelection: (selction: CMxAPI.NavAction) => void;
  onLogout: () => void;
  activeOrg: string;
  activeUser: CMxAPI.User;
  serviceLine?: CMx.ServiceLine;
  openAccountSettings: () => void;
  openCapturedTime: () => void;
  listStatus: (activeTenantId: string) => void;
  updateStatus: (
    status: string,
    user: CMxAPI.User,
    context: CMx.Context
  ) => void;
  showDashboardOptions: boolean;
}

export const PrimaryGlobalNavigation: React.FC<PrimaryGlobalNavigationProps> = ({
  className,
  disabled,
  name,
  profile_src,
  tabColor,
  actionGroups,
  context: cmxContext,
  activeOrg: activeOrgDisplay,
  activeUser,
  serviceLine,
  context,
  showDashboardOptions,
  onSelection,
  handleOrganizationChoice,
  handleServiceLineChoice,
  openAccountSettings,
  openCapturedTime,
  listStatus,
  updateStatus,
  onLogout,
  ...props
}) => {
  const classes = styles(tabColor);
  const dispatch = useDispatch();

  const getActiveServiceLine = useCallback(() => {
    let serviceLineName = serviceLine?.name ?? '';
    return serviceLineName;
  }, [serviceLine]);

  const [activeServiceLineName, setActiveServiceLineName] = useState<string>(
    getActiveServiceLine()
  );

  useEffect(() => {
    setActiveServiceLineName(getActiveServiceLine());
  }, [getActiveServiceLine, context]);

  const selectionWrapper = (actionGroupOption: CMxAPI.NavAction) => {
    dispatch(setBackToWorklistPath(actionGroupOption));
    onSelection(actionGroupOption);
  };

  const [switcherOpened, setSwitcherOpened] = useState<boolean>(false);
  const [userProfileOpened, setUserProfileOpened] = useState<boolean>(false);
  const [dashboardSelectionOpened, setDashboardSelectionOpened] = useState<
    boolean
  >(false);

  const userInitials =
    activeUser?.firstName && activeUser?.lastName
      ? activeUser.firstName?.charAt(0) + ' ' + activeUser.lastName?.charAt(0)
      : '🧑‍⚕️';

  function handleSwitcherClick(): void {
    setSwitcherOpened(!switcherOpened);
  }

  function handleUserProfileClick(): void {
    setUserProfileOpened(!userProfileOpened);
  }

  const navItemComponentMap: Record<string, React.ComponentType<any>> = {
    Insights: DashboardSelection
  };

  const getComponentForNavItem = (navItemLabel: string) => {
    return navItemComponentMap[navItemLabel]; //|| DefaultComponent;
  };

  return (
    <Grid sx={classes['.mainVStack']} gridTemplateRows="min-content">
      <Box sx={classes['.mainGrid']}>
        <Popover
          closeOnBlur={true}
          placement="right"
          isOpen={switcherOpened}
          onClose={() => {
            setSwitcherOpened(false);
          }}>
          {({ isOpen, onClose }) => (
            <>
              <PopoverTrigger>
                <Flex
                  sx={classes['.orgBox']}
                  className={`hoverCursor ${
                    switcherOpened ? 'popOverSelected' : ''
                  }`}
                  onClick={() => handleSwitcherClick()}>
                  <Box>
                    <Tooltip label={activeOrgDisplay} placement="left">
                      <Text sx={classes['.orgServiceText']}>
                        {activeOrgDisplay}
                      </Text>
                    </Tooltip>
                    <Tooltip label={serviceLine?.name} placement="left">
                      <Text sx={classes['.orgServiceText']}>
                        {activeServiceLineName}
                      </Text>
                    </Tooltip>
                  </Box>
                  <Spacer />
                  <ChevronRightIcon
                    width={theme.space[8]}
                    height={theme.space[12]}
                  />
                </Flex>
              </PopoverTrigger>
              <OrganizationServiceline
                context={context}
                actionGroups={actionGroups}
                handleOrganizationChoice={handleOrganizationChoice}
                handleServiceLineChoice={handleServiceLineChoice}
                onSelection={onSelection}
                activeOrg={activeOrgDisplay}
                activeUser={activeUser}
                serviceLine={serviceLine}
                openAccountSettings={openAccountSettings}
                setSwitcherOpened={setSwitcherOpened}
              />
            </>
          )}
        </Popover>
      </Box>
      <VStack
        textStyle="Subtitle 2"
        sx={classes['.secondVStack']}
        spacing={theme.space[10]}>
        <VStack sx={classes['.avatarVstack']}>
          <Popover
            closeOnBlur={true}
            placement="right"
            isOpen={userProfileOpened}
            onClose={() => {
              setUserProfileOpened(false);
            }}>
            <PopoverTrigger>
              <Flex sx={classes['.avatarBox']}>
                <Avatar
                  name={userInitials}
                  src={profile_src}
                  sx={
                    userProfileOpened
                      ? classes['.selectedUserNameAvatar']
                      : classes['.usernNameAvatar']
                  }
                  onClick={() => {
                    handleUserProfileClick();
                  }}
                  data-testid={'userAvatar'}
                  className={`hoverCursor`}
                />
              </Flex>
            </PopoverTrigger>
            <UserProfile
              activeUser={activeUser}
              onLogout={onLogout}
              openAccountSettings={openAccountSettings}
              openCapturedTime={openCapturedTime}
              userInitials={userInitials}
              profile_src={profile_src}
              listStatus={listStatus}
              updateStatus={updateStatus}
            />
          </Popover>
        </VStack>
        {actionGroups
          ?.filter(actionGroup =>
            actionGroup.options.some(action => action.enabled)
          )
          .map(actionGroup =>
            actionGroup?.options?.map(actionGroupOption => (
              <HStack
                width="100%"
                onClick={() => {
                  selectionWrapper(actionGroupOption);
                }}
                className={`hoverCursor ${
                  actionGroupOption?.active ? 'navItem' : ''
                }`}
                data-testid={`${actionGroupOption?.label}tab`}>
                <VStack spacing={theme.space[2]} sx={classes['.navItemVStack']}>
                  {actionGroupOption.navSubResources &&
                  context.activeContext?.resources.some(resource => {
                    return (
                      actionGroupOption?.navSubResources?.includes(
                        resource.resourceName
                      ) ?? false
                    );
                  }) ? (
                    <SubNav
                      tabColor={tabColor}
                      selectionOpened={dashboardSelectionOpened}
                      setSelectionOpened={setDashboardSelectionOpened}
                      actionGroupOptionLabel={actionGroupOption?.label ?? ''}
                      childComponent={getComponentForNavItem(
                        actionGroupOption?.label ?? ''
                      )}></SubNav>
                  ) : (
                    <>
                      <Grid sx={classes['.iconWrapper']}>
                        {actionGroupOption?.icon
                          ? navComponents[actionGroupOption?.label ?? '']
                          : actionGroupOption?.icon}
                      </Grid>
                      <Text className="action-group-spacing" align={'center'}>
                        {actionGroupOption?.label}
                      </Text>
                    </>
                  )}
                </VStack>
                <Divider
                  orientation="vertical"
                  sx={classes['.dividerVertical']}
                />
              </HStack>
            ))
          )}
      </VStack>
      <GridItem sx={classes['.navLogo']}>
        <CMXIcon />
      </GridItem>
    </Grid>
  );
};

PrimaryGlobalNavigation.defaultProps = {
  className: '',
  disabled: false
};

PrimaryGlobalNavigation.displayName = 'PrimaryGlobalNavigation';
