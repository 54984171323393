// react imports
import React from 'react';

// import components
import { ModalComponent } from '../Modal/modal';
import { PatientEncounterDashboardColumns } from '@codametrix/ui-common';
import {
  PatientEncounter,
  Conditional,
  Notes,
  EmptyNote
} from '@codametrix/shared-views';
import { LoadingSlugs } from '@codametrix/ui-components';

// import styles
import './generic-related-modal.scss';

const fieldEditorInfo = {
  caseEdits: {
    caseEdits: {}
  },
  updateValue: (val: any) => console.log(val),
  updateCase: (val: any) => console.log(val)
};

type ModalProps = {
  modalHeader?: React.ReactChild;
  modalContent?: React.ReactChild;
  modalFooter?: React.ReactChild;
  size?: string;
  isOpen: boolean;
  showCloseIcon?: boolean;
  handleClose: () => void;
  width?: string;
  className?: string;
  notes?: any[];
  htmlNotes?: string[];
  activeCaseRun?: CMxAPI.CaseRun;
  includePatientEncounter?: boolean;
  loadingNotes?: boolean;
};

/**
 * GenericRelatedModal component
 * @param {ModalProps} modalProps - Props for GenericRelatedModal component
 * @returns {React.FC} - React functional component
 */
export const GenericRelatedModal: React.FC<ModalProps> = (
  modalProps: ModalProps
) => {
  return (
    <>
      <ModalComponent
        data-test-id="generic-cases-modal"
        className={`generic-cases-modal ${modalProps?.className} `}
        width="95vw"
        isOpen={modalProps.isOpen}
        handleClose={modalProps.handleClose}
        showCloseIcon={true}
        modalHeader={modalProps.modalHeader}
        modalContent={
          <>
            <div className="main-view">
              <div className="merge-actions-section">
                <div className="encounter-content">
                  <Conditional
                    every={[
                      !!modalProps?.includePatientEncounter,
                      !!modalProps?.activeCaseRun
                    ]}>
                    <PatientEncounter
                      propertyConfig={
                        PatientEncounterDashboardColumns.patientTimeline
                      }
                      fieldEditorInfo={fieldEditorInfo}
                      activeItem={
                        modalProps?.activeCaseRun ?? ({} as CMxAPI.CaseRun)
                      }
                    />
                  </Conditional>
                </div>
                <div className="main-content">{modalProps.modalContent}</div>
              </div>
              <div className="note-section">
                <Conditional every={[modalProps.loadingNotes === true]}>
                  <LoadingSlugs numberItems={7} />
                </Conditional>

                <Conditional
                  every={[
                    !modalProps?.htmlNotes?.length,
                    !modalProps.notes?.length,
                    modalProps.loadingNotes !== true
                  ]}>
                  <>
                    <Conditional
                      every={[
                        !!modalProps?.activeCaseRun?.note_unavailable_reason
                      ]}>
                      <div className="note-unavaialable">
                        {modalProps?.activeCaseRun?.note_unavailable_reason ??
                          'No documentation available.'}
                      </div>
                    </Conditional>
                    <Conditional
                      every={[
                        !modalProps?.activeCaseRun?.note_unavailable_reason
                      ]}>
                      <>
                        <EmptyNote />
                      </>
                    </Conditional>
                  </>
                </Conditional>

                <Conditional
                  every={[
                    !!modalProps.notes?.length,
                    modalProps.loadingNotes !== true
                  ]}>
                  <Notes notes={modalProps.notes} />
                </Conditional>
              </div>
            </div>
          </>
        }
        modalFooter={modalProps?.modalFooter}
      />
    </>
  );
};
