/// <reference path="../types.d.ts" />
import { CMxComponents } from '@codametrix/ui-components/src/types';
import InitialState from '../reducers/initial-state';
import { actions } from '../actions/users';
import { clearAllFilters } from '../actions/cases.action';
import { cmxReducerFactory } from './reducer-utils';
import { LOAD_STATES } from './common';
import { uiActions, commonEnums as enums } from '@codametrix/ui-common';
import { formErrors } from '../stubs/form-errors';
const { FilterTypes } = enums;

function flattenAndSortOrganizations(
  org: CMxAPI.Organization,
  orgs: CMxAPI.Organization[]
): CMxAPI.Organization[] {
  org.children.forEach(child => {
    // don't add dupes
    if (!orgs.find(org => org.organizationName === child.organizationName)) {
      orgs.push(child);
    }
    flattenAndSortOrganizations(child, orgs);
  });
  orgs.forEach(org => {
    if (!org.displayName) {
      org.displayName = org.organizationName;
    }
  });
  return orgs.sort((orgA, orgB) =>
    orgA.organizationName < orgB.organizationName ? -1 : 1
  );
}

function formatOrganizations(
  state: CMx.UserState,
  orgDef: CMxAPI.Organization
) {
  return {
    ...state,
    availableOrganizations: flattenAndSortOrganizations(orgDef, [orgDef])
  };
}

function hasEditingPermission(userContext: CMx.Context) {
  const activeContextId = userContext.activeContextId;
  const activeContext = userContext.contexts.find(cont => {
    return cont.organizationId === activeContextId;
  });
  const activeRoles = userContext.user.roles.find(org => {
    return org.tenantId === activeContext?.tenantId;
  });

  return (
    activeRoles?.roles.some(role => {
      return role.roleName === 'super_admin';
    }) ?? false
  );
}

const restActions = [actions.save.async];
const startedActions = restActions.map(action => action.started);

const defaultFilter: CMxCommonApp.Filter[] = [
  { key: 'role', terms: ['true'], type: FilterTypes.IN }
];

const wipeErrors = (state: CMx.UserState) => {
  return {
    ...state,
    formErrors: formErrors.noErrors
  };
};

const reducer = cmxReducerFactory(InitialState.users)
  .cases(startedActions, wipeErrors)
  .case(actions.addUser.async.started, wipeErrors)
  .cases(
    [actions.save.async.failed, actions.addUser.async.failed],
    (state, { error }) => {
      return {
        ...state,
        formErrors: error.errors,
        errors: error.errors
          ? Object.keys(error.errors?.fieldErrors).includes('roles')
          : false
      };
    }
  )
  .case(actions.userSelected, (state, selectedUser) => {
    const setselectedUser = {
      ...state,
      selectedUser
    };
    return wipeErrors(setselectedUser);
  })
  .case(actions.updateRole, (state, selectedUser) => ({
    ...state,
    selectedUser
  }))
  .case(actions.userList, (state, users) => {
    const { sortablePageable, criteriaFilter } = state;
    const users_content = users.content;

    const sp = {
      ...sortablePageable,
      pageableDefinition: {
        ...users,
        items: users_content
      }
    };

    const cFilter = {
      ...criteriaFilter,
      filters: sp.filterableOptions.filters ?? criteriaFilter.filters
    };

    return {
      ...state,
      sortablePageable: sp,
      items: users_content,
      formErrors: InitialState.users.formErrors,
      users: users_content,
      criteriaFilter: cFilter
    };
  })
  .case(actions.availableOrgs, formatOrganizations)
  .case(actions.availableRoles, (state, availableRoles) => ({
    ...state,
    availableRoles
  }))
  .case(actions.addUserDetails, (state, details) => {
    return { ...state, selectedUser: details };
  })
  .case(actions.toggleForcePasswordOption, (state, isEnabled) => {
    return {
      ...state,
      passwordChangeEnabled: isEnabled
    };
  })
  .case(actions.togglePasswordChangeModal, (state, isEnabled) => {
    return {
      ...state,
      passwordModalOpen: isEnabled,
      passwordChangeStatus: LOAD_STATES.initial,
      formErrors: formErrors.noErrors
    };
  })
  .case(actions.changeUsersPassword.async.started, (state, params) => {
    return {
      ...state,
      passwordChangeStatus: LOAD_STATES.started
    };
  })
  .case(actions.changeUsersPassword.async.failed, (state, passedErrors) => {
    return {
      ...state,
      formErrors: passedErrors.error.errors ?? formErrors.noErrors,
      passwordChangeStatus: LOAD_STATES.failed
    };
  })
  .case(actions.changeUsersPassword.async.done, (state, params) => {
    return {
      ...state,
      passwordChangeStatus: LOAD_STATES.done
    };
  })
  .case(actions.toggleFilterOn, (state, filterOn) => {
    return {
      ...state,
      filterOn: filterOn
    };
  })
  .case(clearAllFilters, (state, params) => {
    return {
      ...state,
      criteriaFilter: {
        ...state.criteriaFilter,
        filters: defaultFilter
      },
      sortablePageable: {
        ...state.sortablePageable,
        filterableOptions: {
          filters: defaultFilter
        },
        filters: defaultFilter
      }
    };
  })
  .case(uiActions.chooseContext, (state, context) => {
    const editPermission = hasEditingPermission(context);
    let { criteriaFilter } = state;
    if (editPermission) {
      const filterUsersFields = [
        {
          key: 'role',
          helpText: 'Hide External Users',
          required: false,
          type: 'checkbox',
          label: 'Hide External Users',
          value: 'true',
          filterType: FilterTypes.IN
        },
        {
          key: 'role',
          helpText: 'All Users',
          required: false,
          type: 'checkbox',
          label: 'All Users',
          value: 'false',
          filterType: FilterTypes.IN
        }
      ];

      const formDefinitionVersion: CMxComponents.FormDefintion = {
        fieldGroups: [
          {
            label: 'Filter Users',
            fields: filterUsersFields
          }
        ]
      };

      const versionCriteria: AppProps.CriteriaForm = {
        name: 'User Filters',
        key: 'role',
        formDefinition: { ...formDefinitionVersion },
        isSelect: true
      };

      criteriaFilter = {
        ...criteriaFilter,
        filterForms: [versionCriteria]
      };
    }

    return {
      ...state,
      editPermission: editPermission,
      criteriaFilter: criteriaFilter
    };
  })
  .case(actions.toggleShowErrors, (state, errors) => {
    return {
      ...state,
      errors: errors
    };
  });

export default reducer;
