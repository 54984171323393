import { CSSProperties } from 'react';
import theme from '../../theme';

export const styles = (): Record<string, CSSProperties> => ({
  divider: {
    borderColor: theme.colors.foundation[100],
    borderLeftWidth: theme.space[2],
    margin: `0 ${theme.space[12]}`
  }
});
