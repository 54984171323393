import type { ComponentStyleConfig } from '@chakra-ui/theme'; //This is the way Chakra wants it but gets stuck with commit hook.
import { theme } from '@codametrix/ui-common';

//Multipart Example
const messageParts = [
  'container', //the whole container
  'spacing',
  'contents',
  'header', //the top row
  'type',
  'connector',
  'bar',
  'description',
  'headSpacer',
  'chevron',
  'body', //the middle row
  'list',
  'listItem',
  'field',
  'value',
  'commentField',
  'commentValue',
  'commentDivider',
  'footer', //the bottom row
  'datetime',
  'footSpacer',
  'encounter'
];

export const Messages: ComponentStyleConfig = {
  // The parts of the component
  parts: messageParts,
  // The base styles for each part
  baseStyle: {
    container: {
      display: 'flex',
      flexDirection: 'row'
    },
    spacing: {
      p: theme.space[12],
      borderRight: `${theme.colors.stroke[200]} ${theme.borders['1px']}` //This border item has solid style included
    },
    contents: {
      pt: theme.space[4],
      pb: theme.space[4],
      flexDirection: 'column',
      w: '100%',
      ml: `-${theme.space[12]}`
    },
    header: {
      color: theme.colors.text['light'],
      w: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    type: {
      w: theme.space[24],
      h: theme.space[24],
      maxW: theme.space[24],
      maxH: theme.space[24],
      minW: theme.space[24],
      minH: theme.space[24],
      borderRadius: theme.radii[18],
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    },
    connector: {
      w: theme.space[12],
      h: theme.space[1]
    },
    bar: {
      pl: theme.space[20],
      pr: theme.space[8],
      w: '100%',
      minH: theme.space[24],
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderTopLeftRadius: theme.radii[18],
      borderTopRightRadius: theme.radii[3],
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: theme.radii[18]
    },
    description: {},
    chevron: {
      //w: theme.space[20],
      h: theme.space[20]
    },
    body: {
      pt: theme.space[4],
      pb: theme.space[4],
      pr: theme.space[8],
      pl: theme.space[8],
      bg: theme.colors.structural[0],
      flexDirection: 'row',
      ml: theme.space[52],
      mt: '-1px',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomRightRadius: theme.radii[3],
      borderBottomLeftRadius: theme.radii[3],
      border: theme.borders['1px'],
      borderTopColor: 'transparent',
      borderRightColor: 'transparent',
      borderBottomColor: 'transparent',
      borderLeftColor: 'transparent'
    },
    list: {
      display: 'flex',
      flexDirection: 'column'
    },
    listItem: {
      mt: 0
    },
    field: {
      color: theme.colors.text['mediumEmphasis']
    },
    value: {
      color: theme.colors.text['highEmphasis']
    },
    commentField: {
      color: theme.colors.text['mediumEmphasis']
    },
    commentValue: {
      color: theme.colors.text['mediumEmphasis']
    },
    commentDivider: {
      mt: theme.space[4],
      mb: theme.space[4]
    },
    footer: {
      p: theme.space[4],
      bg: theme.colors.structural[0],
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      ml: theme.space[52]
    },
    datetime: {
      color: theme.colors.text['mediumEmphasis']
    },
    encounter: {
      color: theme.colors.text['mediumEmphasis']
    }
  },
  // The size styles for each part
  sizes: {
    md: {
      header: {
        ...theme.textStyles['Overline 1']
      },
      field: {
        ...theme.textStyles['Overline 2'],
        textTransform: 'uppercase'
      },
      value: {
        ...theme.textStyles['Body 2']
      },
      commentField: {
        ...theme.textStyles['Overline 2'],
        textTransform: 'uppercase'
      },
      commentValue: {
        ...theme.textStyles['Body 2'],
        fontStyle: 'italic'
      },
      datetime: {
        ...theme.textStyles['Overline 2']
      },
      encounter: {
        ...theme.textStyles['Overline 2']
      }
    }
  },
  // The variant styles for each part
  variants: {
    charge: {
      type: {
        bg: theme.colors.chips.brown[500]
      },
      connector: {
        bg: theme.colors.chips.brown[500]
      },
      bar: {
        bg: theme.colors.chips.brown[500]
      },
      body: {
        borderRightColor: theme.colors.chips.brown[500],
        borderBottomColor: theme.colors.chips.brown[500],
        borderLeftColor: theme.colors.chips.brown[500]
      }
    },
    chargeEncounter: {
      type: {
        bg: theme.colors.chips.brown[500]
      },
      connector: {
        bg: theme.colors.chips.brown[500]
      },
      bar: {
        bg: theme.colors.chips.brown[500]
      },
      body: {
        borderRightColor: theme.colors.chips.brown[500],
        borderBottomColor: theme.colors.chips.brown[500],
        borderLeftColor: theme.colors.chips.brown[500],
        bg: theme.colors.structural[200]
      },
      encounter: {
        //bg: theme.colors.warning[300]
      }
    },
    registration: {
      type: {
        bg: theme.colors.chips.plum[500]
      },
      connector: {
        bg: theme.colors.chips.plum[500]
      },
      bar: {
        bg: theme.colors.chips.plum[500]
      },
      body: {
        borderRightColor: theme.colors.chips.plum[500],
        borderBottomColor: theme.colors.chips.plum[500],
        borderLeftColor: theme.colors.chips.plum[500]
      }
    },
    registrationEncounter: {
      type: {
        bg: theme.colors.chips.plum[500]
      },
      connector: {
        bg: theme.colors.chips.plum[500]
      },
      bar: {
        bg: theme.colors.chips.plum[500]
      },
      body: {
        borderRightColor: theme.colors.chips.plum[500],
        borderBottomColor: theme.colors.chips.plum[500],
        borderLeftColor: theme.colors.chips.plum[500],
        bg: theme.colors.structural[200]
      },
      encounter: {
        //bg: theme.colors.warning[300]
      }
    },
    order: {
      type: {
        bg: theme.colors.chips.green[500]
      },
      connector: {
        bg: theme.colors.chips.green[500]
      },
      bar: {
        bg: theme.colors.chips.green[500]
      },
      body: {
        borderRightColor: theme.colors.chips.green[500],
        borderBottomColor: theme.colors.chips.green[500],
        borderLeftColor: theme.colors.chips.green[500]
      }
    },
    orderEncounter: {
      type: {
        bg: theme.colors.chips.green[500]
      },
      connector: {
        bg: theme.colors.chips.green[500]
      },
      bar: {
        bg: theme.colors.chips.green[500]
      },
      body: {
        borderRightColor: theme.colors.chips.green[500],
        borderBottomColor: theme.colors.chips.green[500],
        borderLeftColor: theme.colors.chips.green[500],
        bg: theme.colors.structural[200]
      },
      encounter: {
        //bg: theme.colors.warning[300]
      }
    },
    note: {
      type: {
        bg: theme.colors.chips.grey[500]
      },
      connector: {
        bg: theme.colors.chips.grey[500]
      },
      bar: {
        bg: theme.colors.chips.grey[500]
      },
      body: {
        borderRightColor: theme.colors.chips.grey[500],
        borderBottomColor: theme.colors.chips.grey[500],
        borderLeftColor: theme.colors.chips.grey[500]
      }
    },
    noteEncounter: {
      type: {
        bg: theme.colors.chips.grey[500]
      },
      connector: {
        bg: theme.colors.chips.grey[500]
      },
      bar: {
        bg: theme.colors.chips.grey[500]
      },
      body: {
        borderRightColor: theme.colors.chips.grey[500],
        borderBottomColor: theme.colors.chips.grey[500],
        borderLeftColor: theme.colors.chips.grey[500],
        bg: theme.colors.structural[200]
      },
      encounter: {
        //bg: theme.colors.warning[300]
      }
    },
    surgery: {
      type: {
        bg: theme.colors.chips.blue[500]
      },
      connector: {
        bg: theme.colors.chips.blue[500]
      },
      bar: {
        bg: theme.colors.chips.blue[500]
      },
      body: {
        borderRightColor: theme.colors.chips.blue[500],
        borderBottomColor: theme.colors.chips.blue[500],
        borderLeftColor: theme.colors.chips.blue[500]
      }
    },
    surgeryEncounter: {
      type: {
        bg: theme.colors.chips.blue[500]
      },
      connector: {
        bg: theme.colors.chips.blue[500]
      },
      bar: {
        bg: theme.colors.chips.blue[500]
      },
      body: {
        borderRightColor: theme.colors.chips.blue[500],
        borderBottomColor: theme.colors.chips.blue[500],
        borderLeftColor: theme.colors.chips.blue[500],
        bg: theme.colors.structural[200]
      },
      encounter: {
        //bg: theme.colors.warning[300]
      }
    },
    medication: {
      type: {
        bg: theme.colors.chips.yellow[500]
      },
      connector: {
        bg: theme.colors.chips.yellow[500]
      },
      bar: {
        bg: theme.colors.chips.yellow[500]
      },
      body: {
        borderRightColor: theme.colors.chips.yellow[500],
        borderBottomColor: theme.colors.chips.yellow[500],
        borderLeftColor: theme.colors.chips.yellow[500]
      }
    },
    medicationEncounter: {
      type: {
        bg: theme.colors.chips.yellow[500]
      },
      connector: {
        bg: theme.colors.chips.yellow[500]
      },
      bar: {
        bg: theme.colors.chips.yellow[500]
      },
      body: {
        borderRightColor: theme.colors.chips.yellow[500],
        borderBottomColor: theme.colors.chips.yellow[500],
        borderLeftColor: theme.colors.chips.yellow[500],
        bg: theme.colors.structural[200]
      },
      encounter: {
        //bg: theme.colors.warning[300]
      }
    }
  },
  // The default `size` or `variant` values
  defaultProps: {
    size: 'md'
  }
};

//End Multipart Example

export const styles = () => ({
  btnHeader: {
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'right',
    flexDirection: 'row',
    columnGap: theme.space[20],
    p: 0
  },
  stickyInfo: {
    position: 'sticky',
    top: '-2px',
    backgroundColor: theme.colors.structural[0]
  },
  patientBlock: {
    mb: theme.space[20]
  },
  entriesSection: {},
  buttonToggle: {},
  notFoundMessage: {
    margin: theme.space[32] + ' ' + 0
  },
  notFoundMessageError: {
    color: theme.colors.alert[500]
  },
  caseComparison: {
    border: theme.borders['1px'],
    borderRadius: theme.radii[3] + ' ' + 0,
    backgroundColor: theme.colors.structural[100],
    borderColor: theme.colors.stroke[100],
    padding: `${theme.space[20]} ${theme.space[20]}`,
    margin: `0 0 ${theme.space[20]} 0`
  },
  caseComparisonFields: {
    display: 'flex'
  },
  filtersBox: {
    padding: `0 0 ${theme.space[20]} 0`
  },
  filtersSection: {
    display: 'flex',
    alignItems: 'center'
  },
  filterType: {},
  filterDate: {},
  filterShowAll: {},
  checkCircle: {}
});