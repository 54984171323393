import { Modal, RoundedButton } from '@codametrix/ui-components';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'redux';
import { acceptEula, cancelEula } from '../../actions/eula';
import gfm from 'remark-gfm';
import './acceptable-eula.scss';
import { cmxDateTime } from '@codametrix/ui-common';

const AcceptableEula = () => {
  const eula = useSelector((state: CMx.ApplicationState) => {
    return state.eula;
  });

  const { context, homepagePreference } = useSelector(
    (state: CMx.ApplicationState) => {
      return state.ui;
    }
  );

  const dispatch = useDispatch();

  const acceptEulaClick = () => {
    const datedEula: CMxAPI.Eula = {
      ...eula.eula,
      acceptedDate: cmxDateTime.format(
        new Date(),
        cmxDateTime.FORMATS.DATE_TIME_EULA
      )
    };
    dispatch(
      (acceptEula({
        eula: datedEula,
        context,
        homepagePreference
      }) as any) as Action
    );
  };
  const footerChildren = (
    <div className={'columns'}>
      <RoundedButton
        className={'btn btn-primary qa-submit-button  column '}
        onClick={() => {
          acceptEulaClick();
        }}>
        {' '}
        Accept
      </RoundedButton>
      <div className={'column col-1'} />
      <RoundedButton
        className={'btn btn-secondary qa-cancel-button column '}
        onClick={() => {
          dispatch(cancelEula(true));
        }}>
        {' '}
        Cancel
      </RoundedButton>
    </div>
  );

  return (
    <div className={'qa-acceptable-eula acceptable-eula'}>
      <Modal
        footerChildren={footerChildren}
        closable={false}
        title={'CodaMetrix End User License Agreement'}>
        <ReactMarkdown
          plugins={[gfm]}
          children={eula.eula.text}></ReactMarkdown>
      </Modal>
    </div>
  );
};

export default AcceptableEula;
