/// <reference path="../../../src/types.d.ts" />

import { formErrors } from '../../stubs/form-errors';
import { PasswordResetDTO } from '../../tests/fixtures/stubs/password-reset-dto';
import { LOAD_STATES } from '../common';

export const forgotPassword: CMx.ForgotPasswordState = {
  loadState: LOAD_STATES.initial,
  passwordResetDto: PasswordResetDTO,
  formErrors: formErrors.noErrors,
  isValidToken: false
};
