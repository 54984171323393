import React, { SyntheticEvent } from 'react';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css

import { DateRange } from 'react-date-range';
import { RoundedButton } from '@codametrix/ui-components';

import './datepicker.scss';

type DatePickerProps = {
  onSelection: (event: CustomEvent<any>) => void;
  startDate?: Date;
  endDate?: Date;
  options?: { id: string; name: string; isSelected?: boolean }[];
};

type DateSelection = {
  selection: DatePickerRange;
};
type DatePickerRange = {
  startDate: Date;
  endDate: Date;
  key: string;
  showDateDisplay: boolean;
};

type DatePickerState = {
  ranges: DatePickerRange[];
  dateOption: string;
};

const defaultRange: DatePickerRange = {
  startDate: new Date(),
  endDate: new Date(),
  key: 'selection',
  showDateDisplay: false
};

export class DatePicker extends React.Component<
  DatePickerProps,
  DatePickerState
> {
  constructor(props: DatePickerProps) {
    super(props);

    const dateOption = props.options?.find(option => {
      return option.isSelected;
    });
    this.state = {
      ranges: [
        {
          startDate: props.startDate ? props.startDate : new Date(),
          endDate: props.endDate ? props.endDate : new Date(),
          key: 'selection',
          showDateDisplay: false
        }
      ],
      dateOption: dateOption?.id ?? 'date_of_service'
    };
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(ranges: DateSelection, clear: boolean = false) {
    this.setState({
      ranges: [ranges.selection]
    });

    const customEvent = new CustomEvent('change', {
      detail: {
        value: {
          range: [ranges.selection],
          clear,
          dateOption: this.state.dateOption
        }
      }
    });

    this.props.onSelection(customEvent);
  }

  _onDateOptionSelect(e: SyntheticEvent) {
    const target = e.target;
    const index = (target as HTMLSelectElement).selectedIndex;
    var optionElement = (e.target as HTMLSelectElement).childNodes[index];
    var optionId = (optionElement as HTMLOptionElement).getAttribute('id');
    if (optionId) {
      this.setState({ dateOption: optionId });
    }

    const customEvent = new CustomEvent('change', {
      detail: {
        value: {
          range: this.state.ranges,
          clear: false,
          dateOption: optionId
        }
      }
    });

    this.props.onSelection(customEvent);
  }

  render() {
    const { options } = this.props;

    var dateOptions;
    if (options) {
      dateOptions = options.map(option => {
        return (
          <option id={option.id} key={option.id} selected={option.isSelected}>
            {' '}
            {option.name}
          </option>
        );
      });
    }

    return (
      <div className="calendar-container">
        <div className="calendar-options">
          {options ? (
            <select
              className="form-select qa-date-form-select"
              onChange={e => {
                this._onDateOptionSelect(e);
              }}>
              {dateOptions}
            </select>
          ) : null}
          <span style={{ marginRight: '5px' }} />
          <RoundedButton
            className={'btn-secondary qa-date-filter-cancel'}
            onClick={() => {
              this.handleSelect({ selection: defaultRange }, true);
            }}>
            Clear
          </RoundedButton>
        </div>

        <DateRange
          editableDateInputs={true}
          moveRangeOnFirstSelection={false}
          ranges={this.state.ranges}
          onChange={this.handleSelect}
        />
      </div>
    );
  }
}
