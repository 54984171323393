import theme from '../../theme';

export const styles = () => ({
  text: {
    ...theme.textStyles['h4'],
    color: theme.colors.text['highEmphasis']
  },
  btnHeader: {
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'row',
    columnGap: theme.space[12]
  },
  menuBtn: {
    backgroundColor: theme.colors.structural[0],
    color: theme.colors.accent[100],
    borderRadius: theme.radii[3],
    border: `${theme.borders['1px']} ${theme.colors.accent[100]}`,
    paddingLeft: theme.space[12],
    ...theme.textStyles['Body 1'],
    height: theme.space[40],
    _active: {
      backgroundColor: theme.colors.structural[0],
      color: theme.colors.accent[100]
    },
    _focus: {
      boxShadow: 'none'
    },
    _hover: {
      backgroundColor: theme.colors.structural[0],
      color: theme.colors.accent[100],
      boxShadow: 'none'
    },
    _expanded: {
      backgroundColor: theme.colors.structural[0],
      color: theme.colors.accent[100],
      boxShadow: 'none'
    },
    '.chakra-button__icon': {
      marginLeft: 0
    }
  },
  icon: {
    color: theme.colors.accent[100],
    fontSize: theme.space[24]
  },
  columnIcon: {
    marginTop: theme.space[4],
    marginLeft: theme.space[12]
  },
  legend: {
    marginLeft: theme.space[12],
    padding: `0 ${theme.space[8]}`
  },
  fieldset: {
    border: `${theme.borders['1px']} ${theme.colors.foundation[200]}`,
    borderRadius: theme.radii[3],
    marginBottom: theme.space[16]
  },
  grid: {
    padding: `0 ${theme.space[16]}`
  },
  datagrid: {
    padding: `0 ${theme.space[16]}`,
    marginBottom: theme.space[16]
  },
  fieldsets: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.radii[3],
    border: `${theme.borders['1px']} ${theme.colors.foundation[200]}`,
    marginTop: theme.space[20],
    padding: `${theme.space[16]} ${theme.space[20]} 0 ${theme.space[20]}`
  },
  input: {
    marginBottom: theme.space[16],
    width: '42%'
  },
  helperText: {
    paddingTop: theme.space[4],
    color: theme.colors.text['mediumEmphasis'],
    ...theme.textStyles['Body 2']
  },
  errorGrid: {
    marginLeft: theme.space[12]
  },
  errorText: {
    marginBottom: theme.space[12],
    color: theme.colors.red[500]
  },
  errorList: {
    overflowY: 'auto',
    maxHeight: theme.space[400],
    minHeight: theme.space[100]
  },
  orgName: {
    width: theme.space[100],
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginLeft: theme.space[4]
  },
  breadCrumbGrid: {
    pointerEvents: 'none'
  },
  divider: {
    borderColor: theme.colors.stroke[200]
  },
  datepickerInput: {
    border: `${theme.borders['1px']} ${theme.colors.foundation[200]}`,
    height: theme.space[40],
    color: theme.colors.text['highEmphasis'],
    ...theme.textStyles['Caption 2'],
    paddingLeft: theme.space[12],
    caretColor: 'transparent',
    cursor: 'pointer'
  },
  selectField: {
    height: theme.space[40],
    width: '100%',
    textAlign: 'left',
    color: theme.colors.text['highEmphasis'],
    ...theme.textStyles['Body 2'],
    background: theme.colors.text['light'],
    border: `1px solid ${theme.colors.stroke[200]}`,
    backgroundColor: theme.colors.text['light'],
    paddingLeft: theme.space[16]
  },
  inputField: {
    height: theme.space[40],
    zIndex: 0
  },
  label: {
    color: theme.colors.text['highEmphasis'],
    ...theme.textStyles['Body 2'],
    marginBottom: theme.space[4]
  },
  formContent: {
    marginTop: theme.space[4]
  },
  title: {
    height: theme.space[20],
    ...theme.textStyles['h1'],
    color: theme.colors.text['highEmphasis']
  },
  errorMessage: {
    color: theme.colors.alert[500],
    ...theme.textStyles['Body 2']
  },
  loaderWrapper: {
    position: 'absolute',
    left: `calc(50% - ${theme.space[96]})`,
    top: '42%'
  },
  unrestrictedDictionary: {
    color: 'gray',
    margin: '10px 0px 0px 5px'
  },
  duplicateKeyErrorHeader: {
    ...theme.textStyles.h2,
    color: theme.colors.alert[500]
  }
});
