/// <reference path="../../types.d.ts" />
import classnames from 'classnames';
import React from 'react';
import './lifeline.scss';

type LifelineProps = {
  message: string;
  onAction: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

export const Lifeline = (props: LifelineProps) => {
  const { children, className, onAction, ...spreadable } = props;
  const backMessageStyles = classnames(props, `cmx-lifeline`, 'qa-lifeline');

  console.log('here', window.history.length);
  const backAvailable = window.history.length > 1;

  const backUp = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (backAvailable) {
      window.history.back();
    } else {
      window.location.reload();
    }

    onAction();
  };

  return (
    <div {...spreadable} className={classnames(backMessageStyles)}>
      {props.message}{' '}
      <a href="#back" className="qa-action-link" onClick={backUp}>
        {backAvailable ? 'Go back' : 'Refresh'}
      </a>
    </div>
  );
};
