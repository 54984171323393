export const colDefs = [
  {
    headerName: 'Name',
    field: 'displayName',
    cellClass: 'cellStyle'
  },
  { headerName: 'Username', field: 'userName' },
  { headerName: 'Organizations', field: 'organizationName' }
];

export const options = [
  { value: 'Username', label: 'Username' },
  { value: 'Last Name', label: 'Last Name' }
];
export const filterOptions = [
  { value: 'All Users', label: 'All Users' },
  { value: 'Hide External Users', label: 'Hide External Users' }
];

export const HIDE_EXTERNAL_USERS = { key: 'role', terms: ['true'], type: 'IN' };

export const ALL_USERS = { key: 'role', terms: ['false'], type: 'IN' };

export type GridRowData = {
  displayName: string;
  userName: string;
  organizationName: string;
  user: CMxAPI.User;
};
