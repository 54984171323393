export const defaultExternalApplication = {
  externalApplicationName: '',
  isActive: false,
  organizationId: -1,
  organizationName: ''
};

export const defaultExternalApplicationUser = {
  alias: '',
  userId: undefined,
  username: '',
  externalApplicationId: undefined,
  externalApplicationName: ''
};

export const externalApplications = {
  defaultExternalApplication,
  defaultExternalApplicationUser
};
