import { connect } from 'react-redux';
import { buildDispatchable } from '../../../actions/_action-utilities';
import externalApplicationActions, {
  syncActions
} from '../../../actions/external-applications';
import { push } from 'connected-react-router';
import { ExternalAppUserList } from './external-app-user-list';
import { runtimeOverrides } from '../../../core/runtime-overrides';
import { NEW_DESIGN_SUFFIX } from '../../../core/utils/newDesign';

type ExternalApplicationListDispatches = Pick<
  AppProps.ExternalApplicationUserListProps,
  'list' | 'onSelection' | 'onAddUser' | 'setStateToDefault'
>;

type ExternalApplicationListProps = Pick<
  AppProps.ExternalApplicationUserListProps,
  'sortablePageable' | 'items' | 'selectedAppName'
>;

const isNewDesign = runtimeOverrides?.newAdminDesign;

const mapStateToProps = function(
  state: CMx.ApplicationState
): ExternalApplicationListProps {
  const { externalApplications } = state;

  const items =
    externalApplications.externalApplicationUserList.sortablePageable
      .pageableDefinition.content;

  return {
    ...externalApplications.externalApplicationUserList,
    items,
    selectedAppName:
      externalApplications.selectedExternalApplication.externalApplicationName
  };
};

const mapDispatchToProps = (
  dispatch: any
): ExternalApplicationListDispatches => {
  const runDispatchable = buildDispatchable(dispatch);

  return {
    list: (params: AppProps.listParams) => {
      runDispatchable(() => {
        return dispatch(
          externalApplicationActions.listExternalAppUsers(params)
        );
      });
    },

    onSelection: (item: CMxAPI.ExternalApplicationUser, path: string) => {
      runDispatchable(() => {
        dispatch(syncActions.selectExternalAppUser(item));
        return dispatch(
          push(`${path}/users/edit${isNewDesign ? NEW_DESIGN_SUFFIX : ''}`)
        );
      });
    },

    onAddUser: (path: string) => {
      runDispatchable(() => {
        return dispatch(
          push(`${path}/users/edit${isNewDesign ? NEW_DESIGN_SUFFIX : ''}`)
        );
      });
    },

    setStateToDefault: (defaultUser: any) => {
      runDispatchable(() => {
        return dispatch(syncActions.setStateToDefault(defaultUser));
      });
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExternalAppUserList);
