import React, { useCallback } from 'react';
import { Box, Grid, Text } from '@chakra-ui/react';
import theme from '../../theme';
import { styles } from './datepicker-component.styles';
import './datepicker-component.scss';
import { Button } from '../button/button';
import { ButtonVariant } from '../../core/enums';
import { ReactComponent as LeftArrow } from '../../assets/images/less-than.svg';
import { ReactComponent as RightArrow } from '../../assets/images/greater-than.svg';

export type DatePickerHeaderProps = {
  dayPicker?: boolean;
  handleClearDate?: () => void;
  isClearBtnDisabled?: boolean;
  monthDate?: any;
  monthPicker?: boolean;
  onMonthClick?: () => void;
  onMonthPickerYearClick?: () => void;
  onLeftArrowClick?: () => void;
  onRightArrowClick?: () => void;
  onYearClick?: () => void;
  yearPicker?: boolean;
  selectedYear?: string;
  yearRange?: string;
};

const DatePickerHeader: React.FC<DatePickerHeaderProps> = props => {
  const {
    onMonthClick,
    onMonthPickerYearClick,
    onYearClick,
    onLeftArrowClick,
    onRightArrowClick,
    handleClearDate,
    monthDate,
    dayPicker,
    monthPicker,
    yearPicker,
    yearRange,
    selectedYear,
    isClearBtnDisabled
  } = props;

  const classes = styles();

  const getMonthAndYear = useCallback(() => {
    return (
      monthDate
        ?.toLocaleString('en-US', {
          month: 'long',
          year: 'numeric'
        })
        .split(' ') ?? null
    );
  }, [monthDate]);

  const month = getMonthAndYear() && getMonthAndYear()[0];
  const year = getMonthAndYear() && getMonthAndYear()[1];
  return (
    <div className="datepicker-component">
      <Box sx={classes.root}>
        <Grid sx={classes.header}>
          <LeftArrow
            width={theme.space[12]}
            height={theme.space[8]}
            onClick={onLeftArrowClick}
            style={classes.icon}
          />
          <Grid sx={classes.title}>
            {dayPicker && (
              <>
                <Text
                  className="react-datepicker__current-month"
                  style={classes.icon}
                  onClick={onMonthClick}>
                  {month}
                </Text>
                <Text
                  className="react-datepicker__current-month"
                  style={classes.icon}
                  onClick={onYearClick}>
                  {year}
                </Text>
              </>
            )}
            {yearPicker && (
              <Text className="react-datepicker__current-month">
                {yearRange}
              </Text>
            )}
            {monthPicker && (
              <Text
                className="react-datepicker__current-month"
                onClick={onMonthPickerYearClick}
                style={classes.icon}>
                {selectedYear}
              </Text>
            )}
          </Grid>
          <RightArrow
            width={theme.space[12]}
            height={theme.space[8]}
            onClick={onRightArrowClick}
            style={classes.icon}
          />
        </Grid>
        <div>
          <Button
            label={'Clear'}
            variant={ButtonVariant.LINKS}
            onClick={handleClearDate}
            sx={isClearBtnDisabled ? classes.disabledBtn : classes.clearBtn}
          />
        </div>
      </Box>
    </div>
  );
};

DatePickerHeader.displayName = 'DatePickerHeader';
export { DatePickerHeader };
