/// <reference path="../../types.d.ts" />

import { connect } from 'react-redux';

import { buildDispatchable } from '../../actions/_action-utilities';
import { listAuditLogs, clearAllFilters } from '../../actions/audit-log';
import { AuditLog } from './audit-log';

const mapStateToProps = function(state: CMx.ApplicationState) {
  const { auditLog } = state;
  const loadState = auditLog.loadState;
  const auditLogs = auditLog.auditLogs;

  return {
    ...auditLog,
    auditLogs,
    loadState
  };
};

const mapDispatchToProps = (dispatch: any) => {
  const runDispatchable = buildDispatchable(dispatch);

  return {
    getList: (
      sp: CMxCommonApp.SortablePageable<
        CMxAPI.DictionaryAction | CMxAPI.OrganizationAction
      >
    ) => {
      runDispatchable(() => {
        //@ts-ignore
        return dispatch(listAuditLogs(sp));
      });
    },
    clearFilters: () => {
      runDispatchable(() => {
        return dispatch(clearAllFilters());
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditLog);
