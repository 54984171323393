import { extendTheme } from '@chakra-ui/react';
import { theme } from '@codametrix/ui-common';
import { Messages } from './views/patient-timeline/patient-timeline-styles';
import { CMXIconStyles } from './views/cmx-icon/cmx-icon-styles';
import { Drawer } from './views/cmx-drawer/cmx-drawer-styles';

export default extendTheme(theme, {
  useSystemColorMode: false,
  components: {
    Messages,
    CMXIconStyles,
    Drawer
  }
});
