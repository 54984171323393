/// <reference path="../types.d.ts" />

import { push } from 'connected-react-router';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';

import { HttpMethods, commonEnums } from '@codametrix/ui-common';
import { api } from '../core/net';
import { showFeedback } from './ui';
import { Dictionary, DictionaryValues } from './action-types';
import { CMxComponents } from '@codametrix/ui-components/src/types';
import { USER_ACTION_METADATA } from './action-constants';
import { syncActions as dictionaryValuesSyncActions } from './dictionary-values';
import { AmplifyCore } from '@codametrix/ui-common';

const { paginationUtils } = AmplifyCore;

const actionCreator = actionCreatorFactory();
const createAsync = asyncFactory<CMxAPI.Dictionary>(actionCreator);
const { DictionaryStatus } = commonEnums;

const chooseOrg = actionCreator<void>(Dictionary.CHOOSE_ORG);
const chooseDictionary = actionCreator<number>(Dictionary.CHOOSE_DICTIONARY);
const pushPath = actionCreator<CMxComponents.Breadcrumb>(Dictionary.PUSH_PATH);
const saveAssociatedDictionaries = actionCreator<CMxAPI.Dictionary[]>(
  Dictionary.SAVE_ASSOCIATED_DICTIONARIES
);

const loadDictionaries = createAsync<any, any, CMxCommonApp.SubmitError>(
  Dictionary.TENANT_SELECTED,
  async (item, dispatch: any) => {
    const url = `/cmx/admin/orgs/${item.id}/dictionaries`;
    dispatch(chooseOrg(item));
    dispatch(push(url));
    return item;
  }
);

const loadDictionary = createAsync<
  AppProps.actionParams<CMxAPI.Dictionary>,
  CMxAPI.Dictionary,
  CMxCommonApp.SubmitError
>(Dictionary.SELECTED, async (params, dispatch: any) => {
  const { data: dictionary, path } = params;

  const searchParms = new URLSearchParams();
  searchParms.set('tenantId', dictionary.tenantId.uuid);
  const dictionaries =
    (await api<CMxAPI.Dictionary[]>({
      endpoint: `/dictionary/${
        dictionary.name
      }/list/v2?${searchParms.toString()}`
    })) || [];

  dispatch(saveAssociatedDictionaries(dictionaries));

  const status =
    dictionary.editStatus === 'UPDATE'
      ? DictionaryStatus.DRAFT
      : dictionary.status.toLowerCase();

  dispatch(dictionaryValuesSyncActions.setStatusFilter(status));
  dispatch(chooseDictionary(dictionary.id));
  dispatch(push(path));

  return dictionary;
});

const getDetail = createAsync<
  string | number | null,
  CMxAPI.Dictionary,
  CMxCommonApp.SubmitError
>(
  Dictionary.FETCH_DETAIL,
  async (id, dispatch) => {
    if (id === undefined || id === null) {
      // TO REFACTOR - REMOVE ME.
      return {} as CMxAPI.Dictionary;
    }

    const detail = await api<CMxAPI.Dictionary>({
      endpoint: `/dictionary/${id}/dictionaryvalues/v2`
    });
    return detail;
  },
  { ...USER_ACTION_METADATA }
);

const saveItem = createAsync<
  CMxAPI.Dictionary,
  CMxAPI.Dictionary,
  CMxCommonApp.SubmitError
>(Dictionary.SAVE, async (item, dispatch) => {
  const isUpdate = item.id !== null;
  let method: string = isUpdate ? HttpMethods.PUT : HttpMethods.POST;

  const saved = await api<CMxAPI.Dictionary>({
    endpoint: `/dictionary/v2`,
    init: {
      method
    },
    body: item
  });

  return saved;
});

type updateMetadataParams = {
  dictionary: CMxAPI.Dictionary;
  successPath: string;
};

const updatePublishedMetadata = createAsync<
  updateMetadataParams,
  CMxAPI.Dictionary,
  CMxCommonApp.SubmitError
>(Dictionary.UPDATE_PUBLISHED_METADATA, async (params, dispatch) => {
  const { dictionary, successPath } = params;

  const result = await dispatch(saveItem(dictionary));
  // route back to dictionary value list of new draft w/ updated metadata
  dispatch(loadDictionary({ data: result, path: successPath }));

  if (result) {
    dispatch(
      showFeedback({
        id: Date.now(),
        message: `Metadata updated. Publish to finalize changes.`,
        dismissable: true
      })
    );
  }

  return result;
});

const listItems = createAsync<
  AppProps.listParams,
  CMxAPI.PageableList<CMxAPI.Dictionary>,
  CMxCommonApp.SubmitError
>(Dictionary.LIST, async params => {
  const searchParams = paginationUtils.searchParamsFactory(
    params.sortablePageable
  );
  const pageableList = await api<CMxAPI.PageableList<CMxAPI.Dictionary>>({
    endpoint: `/dictionary/v2?${searchParams.toString()}&tenantId=${
      params.id
    }&derivedIndicator=false&uniqueByName=true`,
    init: {
      method: HttpMethods.GET
    }
  });

  return pageableList;
});

interface tenantListItem {
  tenantName: string;
  key: string;
  id: number;
  organizationCode: string;
}
const createTenantList = (org: CMxAPI.Organization): tenantListItem[] => {
  if (org === undefined) {
    return [];
  }

  const piece: tenantListItem = {
    tenantName: org.organizationName,
    key: org.tenantId,
    id: org.id,
    organizationCode: org.organizationCode
  };

  var children: tenantListItem[] = [];
  if (org.parentLevel !== 2) {
    org.children?.forEach(child => {
      children.push(...createTenantList(child));
    });
  }

  if (children !== undefined && children.length > 0) {
    return [piece, ...children];
  }

  return [piece];
};

const getTenantList = createAsync<
  number,
  CMxCommonApp.FieldDefinition[],
  CMxCommonApp.SubmitError
>(DictionaryValues.TENANT, async (id: number) => {
  const endpoint = isNaN(id as number)
    ? `/organization/tenant/v1?tenantId=${id}`
    : `/organization/id/v1?organizationId=${id}`;
  const orgOpts = {
    endpoint: endpoint
  };
  const organization = await api<CMxAPI.Organization>(orgOpts);

  const tenantList = createTenantList(organization).sort((a, b) => {
    return a.tenantName.toUpperCase().localeCompare(b.tenantName.toUpperCase());
  });

  return tenantList;
});

export const syncActions = {
  chooseOrg,
  chooseDictionary,
  saveAssociatedDictionaries,
  pushPath
};

export {
  listItems,
  getDetail,
  saveItem,
  loadDictionary,
  getTenantList,
  loadDictionaries,
  updatePublishedMetadata
};
