/// <reference path="../../types.d.ts"" />

import { push } from 'connected-react-router';
import { connect } from 'react-redux';

import { Admin } from './Admin';
import { buildDispatchable } from '../../actions/_action-utilities';
import { changeAdminTab, navigate } from '../../actions/ui';
import { getFeaturesBySection } from '../../actions/organization';
import { CMxComponents } from '@codametrix/ui-components/src/types';
import { syncActions as externalAppSyncActions } from '../../actions/external-applications';
import { loadDictionaries } from '../../actions/dictionary';

const mapStateToProps = function(state: any): AppProps.AdminScreenProps {
  const { organization, ui } = state;
  return {
    ...organization,
    auth: state.login.auth,
    actionGroups: ui.actionGroups,
    adminTabs: ui.AdminTabs,
    serviceLine: ui.serviceLine
  };
};

const mapDispatchToProps = (dispatch: any) => {
  const runDispatchable = buildDispatchable(dispatch);

  return {
    selectTab: (
      navPath: string,
      activeSubAction: CMxComponents.TabDef,
      adminTabs: CMxComponents.TabDef[]
    ) => {
      runDispatchable(() => {
        dispatch(push('/cmx/' + navPath));
        dispatch(navigate(activeSubAction));
        return dispatch(changeAdminTab(adminTabs));
      });
    },
    navigateExternalApplication: (orgId: number) => {
      runDispatchable(() => {
        dispatch(externalAppSyncActions.selectOrgnization(orgId));
        return dispatch(push(`/cmx/admin/orgs/${orgId}/external-applications`));
      });
    },
    navigateCaseConfig: (orgId: number) => {
      runDispatchable(() => {
        return dispatch(push(`/cmx/admin/orgs/${orgId}/case-config`));
      });
    },
    navigateDictionaries: (org: any) => {
      runDispatchable(() => {
        //@ts-ignore
        return dispatch(loadDictionaries(org));
      });
    },
    navigateGroups: (orgId: number) => {
      runDispatchable(() => {
        //@ts-ignore
        return dispatch(push(`/cmx/admin/orgs/${orgId}/groups`));
      });
    },
    navigateFeatures: (section: string, orgId: number) => {
      runDispatchable(() => {
        dispatch(push(`/cmx/admin/orgs/${orgId}/features`));
        return dispatch(getFeaturesBySection(section));
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
