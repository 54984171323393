export const normalTime: CMx.NormalTimeState = {
  savedHours: {
    Sunday: 0,
    Monday: 0,
    Tuesday: 0,
    Wednesday: 0,
    Thursday: 0,
    Friday: 0,
    Saturday: 0
  }
};
