/// <reference path="../types.d.ts" />
import { api } from '../core/net';
import { UserContext, Auth } from './action-types';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { destroyToken, fetchConfig } from './_action-utilities';
import {
  navigateContext,
  loading,
  configAvailable,
  setActiveUser,
  loadingAction
} from './ui';
import { getEula, saveEula } from './eula';
import { push } from 'connected-react-router';
import {
  authenticationContext,
  cmxLocation,
  HttpMethods,
  commonActionUtils,
  uiActions,
  launchPageActions
} from '@codametrix/ui-common';
import { saveServiceLine } from './service-lines';
import {
  getDefaultServiceLine,
  getHomepagePreference
} from '../core/preferences/preferences';
import { loadPreferences } from './user-preferences';
import { setHomepagePreference } from './ui';
const { shimContext } = commonActionUtils;

const actionCreator = actionCreatorFactory();
const createAsync = asyncFactory<CMx.ContextState>(actionCreator);

const displayContexts = actionCreator<CMxAPI.OrganizationContext[]>(
  UserContext.DISPLAY_CONTEXTS
);

const tokenAvailable = actionCreator<CMxCommonApp.BearerToken>(
  Auth.TOKEN_AVAILABLE,
  {
    broadcast: false
  }
);

const shouldRefresh = actionCreator<void>(Auth.SHOULD_REFRESH, {
  broadcast: true
});

const _getConfigs = async (dispatch: any) => {
  const config = await fetchConfig();

  dispatch(configAvailable(config));
};

const fetchContexts = async (dispatch: any) => {
  const bootstrap = await api<CMxAPI.ContextAPI>({
    endpoint: `/contexts/v1`
  });
  await _getConfigs(dispatch);
  return bootstrap;
};

const postContextChoiceNavigation = async (
  context: CMx.Context,
  dispatch: any,
  acceptDefaultServiceLine: boolean = false,
  contextChoice?: CMx.JumpArgs
) => {
  const serviceLines = context.activeContext?.serviceLines || [];

  const userDefaults = await api<CMxAPI.Preference[]>({
    endpoint: `/userpreferences/${context.user.id}/v1`
  });

  const defaultServiceLineId = getDefaultServiceLine(userDefaults);
  const defaultServiceLine = serviceLines.find(
    sl => sl.id === defaultServiceLineId
  );

  const selectedServiceLine = serviceLines.find(
    sl => sl.id === contextChoice?.servicelineId
  );
  const homepagePreference = getHomepagePreference(userDefaults);
  dispatch(setHomepagePreference(homepagePreference));

  dispatch(
    saveServiceLine(
      selectedServiceLine ?? defaultServiceLine ?? serviceLines[0]
    )
  );
  const location = cmxLocation.getLocation();

  if (
    serviceLines.length > 1 &&
    !acceptDefaultServiceLine &&
    !defaultServiceLine
  ) {
    dispatch(push('/service-lines/'));
  } else if (location === '/eula/') {
    const eulaUpToDate = await getEula(
      context.user.id || -1,
      context.activeContext?.organizationId || -1
    );
    dispatch(saveEula(eulaUpToDate));
    dispatch(push(location));
  } else {
    dispatch(navigateContext({ context, homepagePreference }));
  }
};

// there are two actions here that have very similar names
// selectContext and chooseContext.  chooseContext informs the ui
// that a context choice has been made.
// selectContext is the api sequence to tell the server that a context
// has been made.
// This has been split into two calls so that context choice can be made
// *prior* to further api calls being made.
const selectContext = createAsync<
  CMx.OrganizationFormChoice,
  CMx.Context,
  CMxCommonApp.SubmitError
>(UserContext.CHOOSE_CONTEXT, async (contextChoice, dispatch) => {
  dispatch(loading(true));
  dispatch(loadingAction({ isLoading: true }));
  const oldToken = authenticationContext.getToken();
  const contexts = await api<CMxAPI.ContextAPI>({
    endpoint: `/contexts/v1`,
    init: {
      method: HttpMethods.PUT
    },
    body: contextChoice
  });

  const shimmed = shimContext(contexts);

  if (
    shimmed.activeContext &&
    (shimmed.activeContext.displayName === null ||
      shimmed.activeContext?.displayName === undefined)
  ) {
    shimmed.activeContext.displayName = shimmed.activeContext?.organizationName;
  }

  const token: CMxCommonApp.BearerToken = {
    token: `${shimmed.jwtToken}`,
    expiryTimeToLive: shimmed.expiryTimeToLive
  };
  dispatch(tokenAvailable(token));
  await destroyToken(oldToken);
  await dispatch(uiActions.chooseContext(shimmed));

  await postContextChoiceNavigation(shimmed, dispatch);

  dispatch(loading(false));
  dispatch(loadingAction({ isLoading: false }));
  return shimmed;
});

export const onlyRefreshContext = createAsync<
  CMx.JumpArgs,
  void,
  CMxCommonApp.SubmitError
>(UserContext.ONLY_REFRESH_CONTEXT, async (contextChoice, dispatch) => {
  const freshContexts = await api<CMxAPI.ContextAPI>({
    endpoint: `/contexts/v1`,
    init: {
      method: HttpMethods.PUT
    },
    body: { organizationId: contextChoice.organizationId }
  });

  const shimmed = shimContext(freshContexts);
  const token: CMxCommonApp.BearerToken = {
    token: `${shimmed.jwtToken}`,
    expiryTimeToLive: shimmed.expiryTimeToLive
  };

  dispatch(tokenAvailable(token));

  return shimmed;
});

const putContext = async (
  contextChoice: CMx.JumpArgs,
  dispatch: any
): Promise<CMx.Context> => {
  const oldToken = authenticationContext.getToken();
  const freshContexts = await api<CMxAPI.ContextAPI>({
    endpoint: `/contexts/v1`,
    init: {
      method: HttpMethods.PUT
    },
    body: { organizationId: contextChoice.organizationId }
  });

  dispatch(setActiveUser(freshContexts.user));
  dispatch(launchPageActions.userDetailsAvailable(freshContexts));

  const shimmed = shimContext(freshContexts);
  const token: CMxCommonApp.BearerToken = {
    token: `${shimmed.jwtToken}`,
    expiryTimeToLive: shimmed.expiryTimeToLive
  };

  dispatch(tokenAvailable(token));
  await destroyToken(oldToken);
  await _getConfigs(dispatch);
  await dispatch(uiActions.chooseContext(shimmed));

  return shimmed;
};

const refreshContext = createAsync<
  CMx.JumpArgs,
  void,
  CMxCommonApp.SubmitError
>(UserContext.REFRESH_CONTEXT, async (contextChoice, dispatch) => {
  const context = await putContext(contextChoice, dispatch);
  return context;
});

const jumpContext = createAsync<
  CMx.JumpArgs,
  CMx.Context,
  CMxCommonApp.SubmitError
>(UserContext.JUMP_CONTEXT, async (contextChoice, dispatch) => {
  dispatch(loading(true));
  dispatch(loadingAction({ isLoading: true }));
  const shimmed = await putContext(contextChoice, dispatch);

  if (shimmed.user.id) {
    dispatch(loadPreferences(shimmed.user.id));
  }
  await postContextChoiceNavigation(shimmed, dispatch, true, contextChoice);
  dispatch(loading(false));
  dispatch(loadingAction({ isLoading: false }));

  return shimmed;
});

export {
  fetchContexts,
  displayContexts,
  selectContext,
  putContext,
  shouldRefresh,
  refreshContext,
  jumpContext,
  tokenAvailable
};
