import React from 'react';

import logo from './../../assets/images/cmx-logo.svg';
import './loading-indicator.scss';

type LoadingIndicatorProps = {
  text?: string;
};

const optValues = {
  text: 'Starting...'
};

const indicator = (props: LoadingIndicatorProps) => {
  const values = { ...optValues, ...props };

  return (
    <div className="loading-indicator qa-loading-indicator">
      <div className="loading-indicator-inner upper-loading-container">
        <svg
          className="loading-graphic"
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="45" />
        </svg>
        <p className="empty-title h6">{values.text}</p>
      </div>
      <div className="logo-loading-container loading-indicator-inner">
        <img alt="logo" src={logo} className="logo" />
      </div>
    </div>
  );
};
export default indicator;
