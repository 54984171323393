/// <reference path="../types.d.ts" />
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { LocationChangePayload, push, replace } from 'connected-react-router';
import { Loading, UserConfig } from './action-types';
import { CMxComponents } from '@codametrix/ui-components/src/types';
import { retrieveGroups } from '../core/navigation';
import { displayContexts, onlyRefreshContext, shouldRefresh } from './contexts';
import { jumpURL } from './jump';
import { applyDefaultFilters, clearAllFilters } from './cases.action';
import { fetchContexts as contextApi } from './contexts';
import {
  ANALYTICS_ACTION_METADATA,
  BROADCAST_ACTION_METADATA
} from './action-constants';
import {
  authenticationContext,
  commonActions,
  commonActionUtils,
  xcaliberActions,
  uiActions,
  sessionManagement
} from '@codametrix/ui-common';
import { getContextHomepage } from '../core/preferences/preferences';
import { saveFormData } from './account-settings';
import { NEW_DESIGN_SUFFIX } from '../core/utils/newDesign';
import { runtimeOverrides } from '../core/runtime-overrides';
import { ToastVariants } from '../core/enums';
const { shimContext } = commonActionUtils;
const { activityMonitor } = sessionManagement;

const UNDEFINED_PREF_CHOICE = undefined;

const actionCreator = actionCreatorFactory();
const createAsync = asyncFactory<CMx.InterfaceState>(actionCreator);

const { CMxApplication, ParentWindow, Router, UserInterface } = commonActions;

const dismissModal = actionCreator<void>(UserInterface.DISMISS_MODAL_MESSAGE, {
  ...BROADCAST_ACTION_METADATA
});
// showModalMessage can not be broadcast atm bc there are functions as part of the payload
// and this runs into structured cloning issues.
const showModalMessage = actionCreator<CMxComponents.ModalProps>(
  UserInterface.SHOW_MODAL_MESSAGE
);

const logout = actionCreator<void>(UserInterface.LOGOUT, {
  ...ANALYTICS_ACTION_METADATA,
  ...BROADCAST_ACTION_METADATA
});

const changeAdminTab = actionCreator<CMxComponents.TabDef[]>(
  UserInterface.ADMIN_TAB_CHANGE
);

const onLocationChange = actionCreator<LocationChangePayload>(
  Router.LOCATION_CHANGE
);
const navigate = actionCreator<CMxAPI.NavAction | CMxComponents.TabDef>(
  UserInterface.NAVIGATE,
  { user: true }
);

const parentDidNavigate = actionCreator<boolean>(
  ParentWindow.PARENT_NAVIGATION
);

const configAvailable = actionCreator<CMxAPI.UserConfig>(
  UserConfig.CONFIG_AVAILABLE
);

const dismissFeedback = actionCreator<boolean>(CMxApplication.DISMISS_FEEDBACK);

const loading = actionCreator<boolean>(CMxApplication.LOADING);

const replaceUIState = actionCreator<CMx.InterfaceState>(
  UserInterface.REPLACE_UI_STATE
);

const loadingAction = actionCreator<CMx.LoadingPayload>(Loading.LOADING_ACTION);

const setActiveUser = actionCreator<CMxAPI.User>(
  UserInterface.SET_ACTIVE_USER,
  ANALYTICS_ACTION_METADATA
);

const showFeedback = actionCreator<CMxCommonApp.Feedback>(
  CMxApplication.FEEDBACK
);
const detailTogglePHI = actionCreator<CMx.PHIMask>(
  UserInterface.DETAIL_TOGGLE_PHI
);

const setHomepagePreference = actionCreator<CMxAPI.Preference | undefined>(
  UserInterface.SET_DEFAULT_HOMEPAGE
);

const setBackToWorklistPath = actionCreator<CMxAPI.NavAction>(
  UserInterface.SET_BACK_TO_WORKLIST_PATH
);

const showFeedbackError = (props: Partial<CMxCommonApp.Feedback>) => {
  const arg = {
    ...props,
    className: `${props.className} toast-error`,
    status: ToastVariants.ERROR,
    id: Date.now()
  } as CMxCommonApp.Feedback;
  return showFeedback(arg);
};

const showFeedbackSuccess = (props: Partial<CMxCommonApp.Feedback>) => {
  const arg = {
    ...props,
    className: `${props.className} toast-success`,
    status: ToastVariants.SUCCESS,
    id: Date.now()
  } as CMxCommonApp.Feedback;
  return showFeedback(arg);
};

const showFeedbackWorking = (props: Partial<CMxCommonApp.Feedback>) => {
  const arg = {
    ...props,
    className: `${props.className} feedback-working toast-success`,
    status: ToastVariants.INFO,
    id: Date.now()
  } as CMxCommonApp.Feedback;
  return showFeedback(arg);
};

const hasEnabled = (option: CMxAPI.NavAction) => option.enabled;
const showFeedbackWarning = (props: Partial<CMxCommonApp.Feedback>) => {
  const arg = {
    ...props,
    className: `${props.className} feedback-warning toast-warning`,
    status: ToastVariants.WARNING,
    id: Date.now()
  } as CMxCommonApp.Feedback;
  return showFeedback(arg);
};

const firstGroup = (context: CMx.Context) => {
  if (context === null || context.activeContext === null) {
    return false;
  } else {
    const groups = retrieveGroups(context.activeContext);
    const firstGroup = groups.find(
      group => group.key !== 'search' && group.options.some(hasEnabled)
    );
    if (!firstGroup) {
      return false;
    }
    return firstGroup.options.find(hasEnabled);
  }
};

const toggleDrawer = actionCreator<boolean>(CMxApplication.DRAWER);

const toggleDrawerContent = actionCreator<boolean>(
  CMxApplication.DRAWER_CONTENT
);

const navigateContext = createAsync<
  CMx.NavData,
  void,
  CMxCommonApp.SubmitError
>(UserInterface.NAVIGATE_CONTEXT, async (navFields, dispatch) => {
  const { context, homepagePreference } = navFields;
  const isNewDesign = runtimeOverrides?.newAdminDesign;
  let defaultHomepage;
  if (context.activeContext)
    defaultHomepage = getContextHomepage(
      context.activeContext?.tenantId,
      homepagePreference
    );

  const resources = context.activeContext?.resources ?? [];
  const xcaliberResources = new Set(['PROVIDER_DIRECTORY']);
  const hasProviderDirectory = resources.some(resource =>
    xcaliberResources.has(resource.resourceName)
  );

  if (hasProviderDirectory) {
    xcaliberActions.loadXCaliber(dispatch);
  }

  dispatch(clearAllFilters());
  dispatch(applyDefaultFilters(context));
  const option = firstGroup(context);
  if (!option) {
    dispatch(replace(`/cmx/`));
  } else if (defaultHomepage) {
    dispatch(
      saveFormData({
        defaultOrg: UNDEFINED_PREF_CHOICE,
        defaultServiceLine: UNDEFINED_PREF_CHOICE,
        defaultHomepage
      })
    );
    dispatch(
      replace(
        `/cmx/${defaultHomepage.path}${isNewDesign ? NEW_DESIGN_SUFFIX : ''}`
      )
    );
  } else {
    dispatch(navigate(option));
    dispatch(setBackToWorklistPath(option));
    dispatch(
      replace(`/cmx/${option.path}${isNewDesign ? NEW_DESIGN_SUFFIX : ''}`)
    );
  }
});

const openWindow = actionCreator<CMxCommonApp.WindowOpenArguments>(
  UserInterface.OPEN_CHILD_WINDOW
);

const navigateAndReload = (context: CMx.Context, dispatch: any) => {
  const option = firstGroup(context);
  if (option) {
    window.location.replace(`/cmx/${option.path}`);
  }
};

const fetchContexts = createAsync<
  CMx.NavData | null,
  CMx.Context,
  CMxCommonApp.SubmitError
>(
  UserInterface.BOOTSTRAP,
  async (navData, dispatch): Promise<CMx.Context> => {
    const { context: optContext, homepagePreference } = navData ?? {};
    // in some cases the activeContext will already have been
    // determined.  if this is the case, then simply return
    // the context.  if the user needs to select the context
    // later we should provision this in the UI.
    if (optContext && optContext.activeContext !== null) {
      authenticationContext.setContextId(optContext.activeContextId);
      return optContext;
    } else {
      const userContexts = await contextApi(dispatch);

      const shimmed = shimContext(userContexts);
      const { contexts, user } = userContexts;

      dispatch(setActiveUser(user));

      if (shimmed.activeContext === null && contexts.length > 1) {
        dispatch(displayContexts(contexts));
      } else {
        const jumped = jumpURL(shimmed, dispatch);
        if (!jumped) {
          dispatch(uiActions.chooseContext(shimmed));
          dispatch(navigateContext({ context: shimmed, homepagePreference }));
          dispatch(loading(false));
        }
      }

      return shimmed;
    }
  }
);

type submitArgs = {
  name: string;
  params?: { [key: string]: any };
};

const modalSubmit = createAsync<submitArgs, void, CMxCommonApp.SubmitError>(
  UserInterface.MODAL_SUBMIT,
  async (args, dispatch) => {
    const { name, params } = args;

    switch (name) {
      case UserInterface.LOGOUT:
        dispatch(logout());
        if (params?.isSSOUser && params?.orgCode?.length) {
          dispatch(replace(`/sso/${params?.orgCode}`));
        } else {
          dispatch(push(`/`));
        }
        break;
      case UserInterface.REFRESH:
        // signal to other windows to start the refresh process
        // get new token.
        dispatch(shouldRefresh());
        dispatch(dismissModal());
        const context = await dispatch(
          onlyRefreshContext({
            organizationId: `${authenticationContext.getContextId()}`
          })
        );
        activityMonitor.shouldRefresh(context);
        break;
      default:
        break;
    }
  }
);

export {
  replaceUIState,
  fetchContexts,
  configAvailable,
  toggleDrawer,
  toggleDrawerContent,
  logout,
  loading,
  loadingAction,
  navigate,
  navigateContext,
  navigateAndReload,
  detailTogglePHI,
  showModalMessage,
  onLocationChange,
  dismissModal,
  dismissFeedback,
  showFeedback,
  showFeedbackError,
  showFeedbackSuccess,
  showFeedbackWorking,
  showFeedbackWarning,
  setBackToWorklistPath,
  changeAdminTab,
  openWindow,
  parentDidNavigate,
  setActiveUser,
  setHomepagePreference,
  modalSubmit
};
