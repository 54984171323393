import { Grid, SystemStyleObject, Text } from '@chakra-ui/react';
import React from 'react';
import theme from '../../theme';
import { MenuItemType, Select } from '../select/select';
import { defaultRowsPerPageOptions } from './rows-per-page-constants';
import { styles } from './rows-per-page.styles';

export type RowsPerPageProps = {
  pageSize: number;
  handlePageSize: (pageSize: number) => void;
  rowsPerPageOptions?: MenuItemType[];
  className?: SystemStyleObject;
};

const RowsPerPage: React.FC<RowsPerPageProps> = props => {
  const {
    pageSize,
    rowsPerPageOptions = defaultRowsPerPageOptions,
    handlePageSize,
    className
  } = props;

  const handleRowsPerPage = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.currentTarget as HTMLButtonElement;
    handlePageSize(Number(target.value));
  };

  return (
    <Grid
      display="flex"
      flexDirection="row"
      alignItems="center"
      gridGap={theme.space[12]}>
      <Text
        textStyle={theme.textStyles['h1']}
        color={theme.colors.text['mediumEmphasis']}>
        Rows
      </Text>
      <Select
        items={rowsPerPageOptions}
        value={pageSize.toString()}
        dataTestId="rowsPerPage"
        onChange={handleRowsPerPage}
        classes={{ button: className ?? styles.select }}
      />
    </Grid>
  );
};

RowsPerPage.displayName = 'RowsPerPage';
export { RowsPerPage };
