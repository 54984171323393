import type { ComponentStyleConfig } from '@chakra-ui/theme';
import { theme } from '@codametrix/ui-common';

export const CMXIconStyles: ComponentStyleConfig = {
  // Styles for the base style
  baseStyle: {
    color: theme.colors.foundation[300]
  },
  // Styles for the size variations
  sizes: {
    '3x': {
      w: theme.space[12],
      h: theme.space[12]
    },
    '4x': {
      w: theme.space[16],
      h: theme.space[16]
    },
    '5x': {
      w: theme.space[20],
      h: theme.space[20]
    },
    '6x': {
      w: theme.space[24],
      h: theme.space[24]
    },
    '7x': {
      w: theme.space[28],
      h: theme.space[28]
    },
    '10x': {
      w: theme.space[40],
      h: theme.space[40]
    },
    '14x': {
      w: theme.space[56],
      h: theme.space[56]
    }
  },
  // Styles for the visual style variations
  variants: {
    light: {
      color: theme.colors.text['light']
    },
    interactive: {
      color: theme.colors.text['interactive']
    },
    grey: {
      color: theme.colors.chips.grey[500]
    },
    brown: {
      color: theme.colors.chips.brown[500]
    },
    green: {
      color: theme.colors.chips.green[500]
    },
    yellow: {
      color: theme.colors.chips.yellow[500]
    },
    blue: {
      color: theme.colors.chips.blue[500]
    },
    teal: {
      color: theme.colors.chips.teal[500]
    },
    red: {
      color: theme.colors.chips.red[500]
    },
    plum: {
      color: theme.colors.chips.plum[500]
    }
  },
  // The default `size` or `variant` values
  defaultProps: {
    size: '5x'
  }
};
